
import { useState } from 'react';
import { Layout } from '../Layout';
import { BarGraph } from './reportsCode/components/BarGraph';
import { DateDropdown } from './reportsCode/components/DateDropdown';
import { DummyData } from './reportsCode/components/LineChartDummyData';
import { LineGraph } from './reportsCode/components/LineGraph';
import { MainDropdown } from './reportsCode/components/MainDropdown';
import { PieChartContainer } from './reportsCode/components/PieChartContainer';
import { RecommendationsBox } from './reportsCode/components/RecommendationsBox';
import DateEnum from './reportsCode/models/DateEnum';
import PieChartSavingsModel from './reportsCode/models/PieChartModels/PieChartSavingsModel';
import calculatePercentageSaved from './reportsCode/utils/CalculatePercentageSaved';

import "./reportsCode/index.css"
import "./reportsCode/custom.css"

export const ProfitLossReportsPage: React.FC = () => {


  const [selectedDate, setSelectedDate] = useState<DateEnum>(DateEnum['Last Month']);

  const barGraphData = {
    ReportData: [
      {
        originalCost: 150,
        reducedCost: 80,
      }
    ]
  }

  return (
    <>
      <Layout>

        <div className="page-container" style={{ marginTop: '1rem' }}>
          <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
            {/* Section label and main dashboard */}
            <b>
              <h3>Profit/Loss Reports</h3>
            </b>
            <div className="dropdown-row">
              {/* Date dashboard */}
              {/*
              <DateDropdown
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                dateRange={DummyData.dateString}
              />

              */}
            </div>
          </div>
          <div style={{ borderBottom: '2px solid #e5e7eb' }}>
            {/* 
           
           <BarGraph barGraphData={barGraphData} />
           */}
          </div>

          <div style={{ borderTop: '2px solid #e5e7eb', paddingTop: "2rem" }}>

            <LineGraph lineGraphData={DummyData} />
          </div>
        </div>

      </Layout>
    </>
  );
};
