import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useState } from 'react';
import AdminPanelAuditLog from '../../components/AdminPanelFilterSortModalRenderer/AdminPanelAuditLog';
import {
    canUserAccessAccountingSecurityWithGivenRoles,
    canUserAccessAdminSecurityWithGivenRoles,
    RoleContext,
} from '../../components/RoleContext/RoleContextComp';
import SimpleAccordion from '../../components/SimpleAccordion/SimpleAccordion';
import { Layout } from '../Layout';
import CustomerManager from './components/CustomerManager/CustomerManager';
import { ImportHistoryManager } from './components/ImportHistoryManager';
import MetricDashboardEditor from './components/MetricDashboardEditor/MetricDashboardEditor';
import { TypeManager } from './components/TypeManager';
import { YardMapHistoryManager } from './components/YardMapHistoryManager';
import './SettingsPanel.module.css';

type CollapsibleSectionsAdminPanelIdentifier =
    | 'Types Management Section'
    | 'Validation Section'
    | 'Import History'
    | 'Yard Map History'
    | 'Metrics Dashboard Editor'
    | 'Customer Manager'
    | 'Audit Log';

type CollapsibleSectionsAdminPanel = {
    identifier: CollapsibleSectionsAdminPanelIdentifier;
    componentToRender: JSX.Element;
};

const SettingsPanel = () => {
    const roles = useContext(RoleContext);

    const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

    type AccordionType = {
        identifier: string;
        componentToRender: React.ReactElement;
    };

    const canAccessAdmin = canUserAccessAdminSecurityWithGivenRoles(roles);

    const adminLinks = canAccessAdmin
        ? [
              {
                  identifier: 'Types Management Section',
                  // componentToRender: <NonPermTypeCodeWrapper />,
                  componentToRender: <TypeManager />,
              },

              {
                  identifier: 'Import History',
                  componentToRender: <ImportHistoryManager />,
              },
              {
                  identifier: 'Yard Map History',
                  componentToRender: <YardMapHistoryManager />,
              },

              {
                  identifier: 'Metrics Dashboard Editor',
                  componentToRender: <MetricDashboardEditor />,
              },
              {
                  identifier: 'Audit Log',
                  componentToRender: <AdminPanelAuditLog />,
              },
          ]
        : [];

    const canAccessAccounting = canUserAccessAccountingSecurityWithGivenRoles(roles);

    const accountingLinks =
        canAccessAccounting || canAccessAdmin
            ? [
                  {
                      identifier: 'Customer Manager',
                      componentToRender: <CustomerManager />,
                  },
              ]
            : [];

    return (
        <Layout>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Settings Panel</IonTitle>
                </IonToolbar>
            </IonHeader>

            <SimpleAccordion<AccordionType>
                generateInnerContent={(value, index) => {
                    return value.componentToRender;
                }}
                generateLabel={(value, index) => {
                    return <span>{`${value.identifier}`}</span>;
                }}
                dataList={[...adminLinks, ...accountingLinks]}
                identifierKey={'identifier'}></SimpleAccordion>
        </Layout>
    );
};

export default SettingsPanel;
