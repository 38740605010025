import { IonButton, IonIcon } from '@ionic/react';

import { copyOutline } from 'ionicons/icons';
import { forwardRef, useRef } from 'react';
import ConditionalLinkWrapper from '../../components/ConditionalLinkWrapper/ConditionalLinkWrapper';
import EasyToCopyAllContentNoteWrapper from '../../components/EasyToCopyAllContentNoteWrapper/EasyToCopyAllContentNoteWrapper';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { InventoryCondition, InventoryStatus, InventorySubType, PhotoStatus } from '../../models/Global';
import { convertToInputDateFormat } from '../../utils/DateUtils';
import { IdentifierType } from '../../utils/identifierType';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNotNullNorUndefinedNorEmptyString, isNullOrUndefined } from '../../utils/NullOrUndefined';
import styles from './InvCondensedView.module.scss';

interface InvCondensedViewProps {
  inventory?: InventoryDto;
  testStyle: string;
}

const InvCondensedView = forwardRef<HTMLDivElement, InvCondensedViewProps>(function InvCondensedView(
  { inventory, testStyle },
  containerDiv
) {
  // if (inventory == undefined) {
  //     inventory = new InventoryDto();p
  // }

  // https://dev.to/wojciechmatuszewski/mutable-and-immutable-useref-semantics-with-react-typescript-30c9
  // need to declare the ref this way for typescript to be happy
  const ionRow = useRef<HTMLIonRowElement | null>(null);

  const importantContainerRef = useRef<HTMLDivElement>(null);

  const copyImportantElements = () => {
    const targetRange = new Range();

    const importantContainer = importantContainerRef.current;

    if (isNullOrUndefined(importantContainer)) {
      console.error('Container is null or undefined!!!');
      makeSimpleToast(
        'Internal Error:',
        'We apologize but an unexpected error occured. Please contact the web team.'
      );
      return;
    }
    // targetRange.setStartBefore(importantContainer);
    // targetRange.setEndAfter(importantContainer);

    // const selection = document.getSelection();

    // if (isNullOrUndefined(selection)) {
    //     console.error('Selection is null or undefined!!!');
    //     makeSimpleToast(
    //         'Internal Error:',
    //         'We apologize but an unexpected error occured. Please contact the web team.'
    //     );
    //     return;
    // }
    // selection.removeAllRanges();

    // selection.addRange(targetRange);

    // Copy the selected content to the clipboard using Clipboard API
    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([importantContainer.outerHTML], { type: 'text/html' }),
        }),
      ])
      .then(() => {
        console.log('Page copied to clipboard successfully!');

        makeSimpleToast('Success!', "You've copied this page to your clipboard");
      })
      .catch((err) => {
        console.error('Unable to copy page to clipboard:', err);
        makeSimpleToast(
          'Error while trying to copy styles.',
          'Page not focused. Please try again and try to keep the page focused for at least a second.'
        );
      });
  };

  const booleanValueProcessor = (value: string | undefined) => {
    if (isNullOrUndefined(value)) {
      return 'False';
    }

    if (value.toLowerCase() === 'true') {
      return 'True';
    }

    if (value.toLowerCase() === 'false') {
      return 'False';
    }

    console.error(`Provided string ${value} isn't true, false, or undef. Returning false`);
    return false;
  };

  const ifEmptyBreak = (value: unknown) => (value === '' || isNullOrUndefined(value) ? <br /> : value + '');

  const commonStyles = {
    containerDiv: {
      flexBasis: '50%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignContent: 'center',
      marginBottom: '.25rem',
    },
    strongLabelContainer: {
      backgroundColor: '#f2f2f2',
    },
    label: {
      height: '1.5rem',
      minHeight: '1.5rem',
      maxHeight: '1.5rem',
      padding: '0.25rem 0.5rem 0.25rem 0.5rem',
      marginRight: '1rem',
      fontSize: '1rem',
      fontWeight: 'bold',
      width: '200px',
      backgroundColor: '#f2f2f2',
      // verticalAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    valueWrapperDiv: {
      fontSize: '1rem',
      marginBottom: 0,
      display: 'flex',
      alignItems: 'middle',
      marginLeft: '1rem',
    },
    header: {
      fontSize: '1.25rem',
      // backgroundColor: '#e6e6e6',
      marginBottom: '1.5rem',
      padding: '.5rem 0.25rem',
      // textTransform: 'uppercase',
      // border: '2px solid black',
      width: '100%',
      fontFamily: 'Open Sans',
      // marginLeft: '-0.5rem',
    },
    headerContainer: {
      padding: '0.5rem',
    },
  } as const;

  const css = `
    @media print {
        .InvCondensedViewContainer {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }
    }
    
      `;

  return (
    <div
      style={{
        paddingBottom: '1rem',
        // , marginLeft: '2.5rem', marginTop: '2.5rem'
      }}
      id="testcondensedview"
      data-testid="InvCondensedView"
      ref={containerDiv}>
      <div className="InvCondensedViewContainer">
        <style>{css}</style>
        <IonButton onClick={copyImportantElements}>
          <IonIcon icon={copyOutline} />
          Copy
        </IonButton>
        <div ref={importantContainerRef} style={{ display: 'flex', height: '100%' }}>
          <div
            style={{
              flexBasis: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              flexGrow: 1,
              flexShrink: 1,
            }}>
            <div style={commonStyles.headerContainer}>
              <h1 style={commonStyles.header}>Trailer Identifiers</h1>
              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Stock</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Stock)}</div>
              </div>

              <div
                style={commonStyles.containerDiv}
              // style={{ backgroundColor: 'red' }}
              >
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Condition</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(
                    inventory == undefined ? '' : InventoryCondition[inventory?.Condition]
                  )}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <ConditionalLinkWrapper
                  to={`/sales-order/edit/${IdentifierType.Stock}/${inventory?.Stock}`}
                  conditionToRedirectOnClick={inventory?.Status === InventoryStatus.Sold}>
                  <>
                    <strong style={commonStyles.strongLabelContainer}>
                      <label style={commonStyles.label}>Status</label>
                    </strong>

                    <div style={commonStyles.valueWrapperDiv}>
                      {ifEmptyBreak(
                        inventory === undefined ? '' : InventoryStatus[inventory.Status]
                      )}
                    </div>
                  </>
                </ConditionalLinkWrapper>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Type</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Type)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Sub Type</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(
                    inventory == undefined ? '' : InventorySubType[inventory?.['Sub Type']]
                  )}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>VIN</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.VIN)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Spec</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Spec)}</div>
              </div>
              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Number</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Number)}</div>
              </div>
              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Color</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Color)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Price</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>${ifEmptyBreak(inventory?.Price)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Cost</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{`$${ifEmptyBreak(inventory?.Cost)}`}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Inventory Title</label>
                </strong>

                <p style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.['Inventory Title'])}</p>
              </div>

            </div>
            <div style={commonStyles.headerContainer}>
              <h1 style={commonStyles.header}>Trailer Tracking</h1>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Check Number</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Check Number'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Ordered Date</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(convertToInputDateFormat(inventory?.['Ordered Date']))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Comp Date</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(convertToInputDateFormat(inventory?.['Comp Date']))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Inspection Date</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(convertToInputDateFormat(inventory?.['Inspection Date']))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Received Date</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(convertToInputDateFormat(inventory?.['Received Date']))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Misc Date</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(convertToInputDateFormat(inventory?.['Misc Date']))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>City Location</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['City Location'])}
                </div>
              </div>

              <ConditionalLinkWrapper
                to={`/yard-location/edit/${IdentifierType.YardStall}/${inventory?.['Yard Stall']}`}
                conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(
                  inventory?.['Yard Stall']
                )}
                className={styles.linkWrapper}
                maxWidth={true}>
                <div style={commonStyles.containerDiv}>
                  <strong style={commonStyles.strongLabelContainer}>
                    <label style={commonStyles.label}>Yard Stall</label>
                  </strong>

                  <div style={commonStyles.valueWrapperDiv}>
                    {ifEmptyBreak(inventory?.['Yard Stall'])}
                  </div>
                </div>
              </ConditionalLinkWrapper>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Decal Id</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.['Decal Id'])}</div>
              </div>

              {/*DOT REPAIR COMPLETE AND DOT REPAIR STATUS SHOULD BE TOGETHER */}

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>DOT Status</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['DOT Status'])}
                </div>
              </div>
              {/*DOT REPAIR COMPLETE AND DOT REPAIR STATUS SHOULD BE TOGETHER */}

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Entered In CDK</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(convertToInputDateFormat(inventory?.['Entered In CDK']))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Advertise</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(booleanValueProcessor(inventory?.['Advertise'] + ''))}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Photo Status</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory == undefined ? '' : PhotoStatus[inventory['Photo Status']])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Has Photos</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Has Photos'])}
                </div>
              </div>
              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Split Deal</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Split Deal'])}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              flexBasis: '50%',
              flexShrink: '1',
              flexGrow: '1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          // size="6"
          >
            <div style={commonStyles.headerContainer}>
              <h1 style={commonStyles.header}>Trailer Spec&apos;s</h1>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Year</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Year)}</div>
              </div>
              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Make</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Make)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Model</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Model)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Length</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.L)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Width</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.W)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Height</label>
                </strong>
                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.H)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Wheel Sz</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.['Wheel Sz'])}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Wheel Type</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Wheel Type'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Axles</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Axles)}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Suspension Type</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Suspension Type'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Suspension Style</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Suspension Style'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>King Pin</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.['KP'])}</div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Floor Type</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Floor Type'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Rear Door Type</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Rear Door Type'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Side Doors</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Side Doors'])}
                </div>
              </div>
              <h1 style={{ backgroundColor: '#999999', marginTop: '1rem' }}>Notes</h1>
              <EasyToCopyAllContentNoteWrapper
                divStyles={commonStyles.valueWrapperDiv}
                value={inventory?.Notes}
              />
            </div>
            <div style={commonStyles.headerContainer}>
              <h1 style={commonStyles.header}>Unit Spec&apos;s</h1>
              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Reefer Year</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Reefer Year'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Reefer Make</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Reefer Make'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Reefer Model</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Reefer Model'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Reefer Serial Number</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Reefer Serial Number'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Unit Hours</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Unit Hours'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Reefer Engine Year</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Reefer Engine Year'])}
                </div>
              </div>

              <div style={commonStyles.containerDiv}>
                <strong style={commonStyles.strongLabelContainer}>
                  <label style={commonStyles.label}>Reefer Description</label>
                </strong>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Reefer Desc'])}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={commonStyles.headerContainer}>
          <h1 style={commonStyles.header}>Sale Details</h1>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <div style={commonStyles.containerDiv}>
              <strong>
                <label style={commonStyles.label}>Deposit</label>
              </strong>

              <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.['Deposit'])}</div>
            </div>

            <ConditionalLinkWrapper
              to={`/sales-order/edit/${IdentifierType.Stock}/${inventory?.Stock}`}
              conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(
                inventory?.['Invoice Number']
              )}
              className={styles.linkWrapper}
              maxWidth={true}>
              <div style={commonStyles.containerDiv}>
                <label style={commonStyles.label}>Invoice Number</label>

                <div style={commonStyles.valueWrapperDiv}>
                  {ifEmptyBreak(inventory?.['Invoice Number'])}
                </div>
              </div>
            </ConditionalLinkWrapper>
            <div style={commonStyles.containerDiv}>
              <strong>
                <label style={commonStyles.label}>Order Date</label>
              </strong>

              <div style={commonStyles.valueWrapperDiv}>
                {ifEmptyBreak(convertToInputDateFormat(inventory?.['Order Date']))}
              </div>
            </div>

            <div style={commonStyles.containerDiv}>
              <strong>
                <label style={commonStyles.label}>Customer</label>
              </strong>
              <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.Customer)}</div>
            </div>
            <div style={commonStyles.containerDiv}>
              <strong>
                <label style={commonStyles.label}>Delivered Date</label>
              </strong>

              <div style={commonStyles.valueWrapperDiv}>
                {ifEmptyBreak(convertToInputDateFormat(inventory?.['Delivered Date']))}
              </div>
            </div>

            <div style={commonStyles.containerDiv}>
              <strong>
                <label style={commonStyles.label}>Sales Person</label>
              </strong>

              <p style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(inventory?.['Sales Person'])}</p>
            </div>


            <div style={commonStyles.containerDiv}>
              <strong>
                <label style={commonStyles.label}>Temp Tag Expiration</label>
              </strong>

              <p style={commonStyles.valueWrapperDiv}>
                {ifEmptyBreak(convertToInputDateFormat(inventory?.['Temp Tag Expiration']))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default InvCondensedView;
