import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import {
    AccountingStatus,
    InventoryCondition,
    InventoryStatus,
    InventorySubType,
    PhotoStatus,
    SalesOrderStatus,
} from '../../models/Global';

export const getEnumFromDeliveryDtoPropertyName = (propertyName: keyof DeliveryDto) => {
    switch (propertyName) {
        case 'Sub Type': {
            return InventorySubType;
        }
        case 'Condition': {
            return InventoryCondition;
        }
        case 'Status - I': {
            return InventoryStatus;
        }
        case 'Photo Status': {
            return PhotoStatus;
        }
        case 'Status - S': {
            return SalesOrderStatus;
        }
        case 'Accounting Status': {
            return AccountingStatus;
        }

        default: {
            return undefined;
        }
    }
};

export const EnumDeliveryProperties = ['Accounting Status'] as (keyof DeliveryDto)[];
