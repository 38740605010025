import makeSimpleToast from './MakeSimpleToast';

// ConvertToBoolean
export const cTB = (item: string | boolean) => {
    if (item === true) {
        return true;
    }

    if (item === false) {
        return false;
    }

    const toLower = item.toLowerCase();

    if (toLower === 'false') {
        return false;
    }

    if (toLower === 'true') {
        return true;
    }
    makeSimpleToast('Error', `Error occured while converting string with value: ${item} to boolean.`);

    console.error(`Error occured while converting string with value: ${item} to boolean.`);
    return false;
};
