import { AccountingTodoQueriedTable } from '../AccountingTodo/AccountingTodoQueriedTable';
import { DeliveryPage } from '../DeliveryAndTransfer';
import SalesOrderQueriedTablePage from '../SalesOrder/SalesOrdersQueriedTable';

export enum InvolvedRecordsSwitcher {
    'Sales Order' = '/sales-order',
    'Delivery' = '/delivery',
    'Accounting Todo' = '/accountingtodo',
}

interface SalesOrderSwitcherProps {
    selected: InvolvedRecordsSwitcher;
    scrollTop: number;
}

const SalesOrderSwitcher = ({ selected, scrollTop }: SalesOrderSwitcherProps) => {
    const updatedSelected = selected ?? InvolvedRecordsSwitcher['Sales Order'];

    return (
        <>
            {updatedSelected === InvolvedRecordsSwitcher['Sales Order'] && (
                <SalesOrderQueriedTablePage scrollTop={scrollTop} />
            )}
            {updatedSelected === InvolvedRecordsSwitcher['Delivery'] && <DeliveryPage scrollTop={scrollTop} />}
            {updatedSelected === InvolvedRecordsSwitcher['Accounting Todo'] && (
                <AccountingTodoQueriedTable scrollTop={scrollTop} />
            )}
        </>
    );
};

export default SalesOrderSwitcher;
