import React, { useMemo } from 'react';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { isNullOrUndefined } from '../../../../utils/NullOrUndefined';
import styles from './QueriedTablePaginationOptions.module.scss';

type QueriedTablePaginationOptionsProps = {
    updateCurrentPageNumber: (pageNumber: number) => void;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const QueriedTablePaginationOptions: React.FC<QueriedTablePaginationOptionsProps> = ({
    updateCurrentPageNumber,
    computedCombinedFilters,
    setIsLoading,
}) => {
    const totalPages = computedCombinedFilters.sortOptions.totalPages;

    const lastPage = isNullOrUndefined(totalPages) ? 9 : totalPages;
    const currentPage = computedCombinedFilters.sortOptions.pageNumber;

    const displayedPageNumbers = useMemo(() => {
        let displayedNumbers = [];
        if (lastPage === 1) {
            displayedNumbers = [1];
        } else if (lastPage === 2) {
            displayedNumbers = [1, 2];
        } else if (lastPage === 3) {
            displayedNumbers = [1, 2, 3];
        } else if (lastPage === 4) {
            displayedNumbers = [1, 2, 3, 4];
        } else if (currentPage <= 3) {
            // In the case that the number is 3 or lower,
            // We will keep it in this order:
            displayedNumbers = [1, 2, 3, 4, 5];
        } else if (currentPage >= lastPage - 2) {
            // If 10 was max,
            // If selected 8, 9, or 10, will display
            // 6 7 8 9 10
            // It counts back from the maximum number
            displayedNumbers = [lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
        } else {
            // Default case with no edge cases
            // Will calculate numbers based on
            // incrementing or decrementing current page

            displayedNumbers = [currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2];
        }
        return displayedNumbers;
    }, [currentPage, lastPage]);

    return (
        <div data-testid="queried-table-pagination-options" className={styles.pagination_container}>
            <button
                onClick={() => {
                    updateCurrentPageNumber(currentPage - 1);
                    setIsLoading(true);
                    // Noticed that you need to prefix the variable instead of suffixing it.=
                    // If you put the ++ on the end I believe it uses the variable at it's current state before incrementing it.
                    // But with the ++ at the beginning of the variable name, it increments the variable first and then uses it.
                }}
                disabled={currentPage <= 1}
                className={`btn ${styles.button} ${styles.back_button}`}>
                Back
            </button>
            {currentPage > 3 && (
                <button
                    onClick={() => {
                        updateCurrentPageNumber(1);
                        setIsLoading(true);
                    }}
                    className={`btn ${styles.button} ${styles.first_page_button}`}>
                    1
                </button>
            )}
            {displayedPageNumbers.map((pageNumber) => {
                return (
                    <button
                        key={pageNumber}
                        onClick={() => {
                            updateCurrentPageNumber(pageNumber);
                            setIsLoading(true);
                        }}
                        className={`btn ${styles.button} ${pageNumber === currentPage ? styles.current : ''}`}>
                        {pageNumber}
                    </button>
                );
            })}
            {currentPage < lastPage && (
                <button
                    onClick={() => {
                        updateCurrentPageNumber(lastPage);
                        setIsLoading(true);
                    }}
                    className={`btn ${styles.button} ${styles.last_page_button}`}>
                    {lastPage}
                </button>
            )}
            {/* Debug test code. Remove later
            <button
                onClick={() => {
                    updateCurrentPageNumber(14);
                }}
                className={`btn ${styles.button} ${styles.last_page_button}`}>
                {14}
            </button>
            <button
                onClick={() => {
                    updateCurrentPageNumber(15);
                }}
                className={`btn ${styles.button} ${styles.last_page_button}`}>
                {15}
            </button> */}
            <button
                onClick={() => {
                    updateCurrentPageNumber(currentPage + 1);
                    setIsLoading(true);
                    // Noticed that you need to prefix the variable instead of suffixing it.=
                    // If you put the ++ on the end I believe it uses the variable at it's current state before incrementing it.
                    // But with the ++ at the beginning of the variable name, it increments the variable first and then uses it.
                }}
                disabled={currentPage >= lastPage}
                className={`btn ${styles.button} ${styles.next_button}`}>
                Next
            </button>
            <span className={styles.totalItemsSpan}>Total Items: {computedCombinedFilters.sortOptions.totalItems}</span>
        </div>
    );
};
export default QueriedTablePaginationOptions;
