import { utils, WorkBook, writeFile } from 'xlsx';

export const createExcelReport = <T>(data: T[], filename: string, sheetName?: string, date?: string): void => {
    writeExcelFile(createWorkbook(data, sheetName), filename, date);
};
export const writeExcelFile = (workbook: WorkBook, filename: string, date?: string): void => {
    writeFile(workbook, filename + (date ? ' - ' + date : '') + '.xlsx');
};
export const createWorkbook = <T>(data: T[], sheetName?: string): WorkBook => {
    const wb = utils.book_new();

    const ws = utils.json_to_sheet(data);
    utils.book_append_sheet(wb, ws, sheetName);
    return wb;
};
