import { InventoryDto } from '../models/Dtos/InventoryDtos/InventoryDto';
import { DeliveryDto } from '../models/Dtos/SalesOrderDtos/DeliveryDto';
import { InventoryCondition, InventoryStatus, InventorySubType, PhotoStatus } from '../models/Global';

export const DeliveryDtoToInventoryDto = (deliveryDto: DeliveryDto) => {
    const inventory: Omit<InventoryDto, 'Inventory Title'> = {
        Id: deliveryDto.Id,

        Stock: deliveryDto.Stock,

        VIN: deliveryDto.VIN,

        Number: deliveryDto['Inventory Number'],

        Color: deliveryDto['Color'],

        'Sales Person': deliveryDto['Sales Person'],

        Deposit: deliveryDto['Deposit'] + '',

        Spec: deliveryDto.Spec,

        Customer: deliveryDto['Customer Name'],

        Price: deliveryDto.Price,

        'Comp Date': deliveryDto['Comp Date'],

        'Inspection Date': deliveryDto['Inspection Date'],

        'Received Date': deliveryDto['Received Date'],

        Advertise: deliveryDto['Advertise'],

        'DOT Status': deliveryDto['DOT Status'],

        'Decal Id': deliveryDto['Decal Id'],

        'Check Number': deliveryDto['Check Number - I'],

        'City Location': deliveryDto['City Location'],

        Cost: deliveryDto.Cost,

        Type: deliveryDto['Inventory Type'],

        'Sub Type': deliveryDto['Sub Type'] ?? InventorySubType.Construction,

        Year: deliveryDto['Year'] ?? 0,

        Make: deliveryDto['Make'],

        Model: deliveryDto['Model'],

        Condition: deliveryDto['Condition'] ?? InventoryCondition.New,

        Status: deliveryDto['Status - I'] ?? InventoryStatus.Available,

        L: deliveryDto.L,

        W: deliveryDto.W,

        H: deliveryDto.H,

        'Wheel Sz': deliveryDto['Wheel Sz'],

        'Wheel Type': deliveryDto['Wheel Type'],

        Axles: deliveryDto.Axles,

        'Suspension Type': deliveryDto['Suspension Type'],

        'Suspension Style': deliveryDto['Suspension Style'],

        KP: deliveryDto['KP'],

        'Unit Hours': deliveryDto['Unit Hours'] ?? 0,

        'Entered In CDK': deliveryDto['Entered In CDK'],

        'Floor Type': deliveryDto['Floor Type'],

        'Rear Door Type': deliveryDto['Rear Door Type'],

        'Temp Tag Expiration': deliveryDto['Temp Tag Expiration'],

        'Side Doors': deliveryDto['Side Doors'],

        'Photo Status': deliveryDto['Photo Status'] ?? PhotoStatus.Missing,

        'Ordered Date': deliveryDto['Ordered Date - I'],

        'Order Date': deliveryDto['Ordered Date - S'],

        'Reefer Engine Year': deliveryDto['Reefer Engine Year'] ?? 0,

        'Reefer Make': deliveryDto['Reefer Make'],

        'Reefer Model': deliveryDto['Reefer Model'],

        'Reefer Desc': deliveryDto['Reefer Desc'],

        'Reefer Serial Number': deliveryDto['Reefer Serial Number'],

        'Reefer Year': deliveryDto['Reefer Year'] ?? 0,

        Notes: deliveryDto['Notes - I'],

        'Split Deal': deliveryDto['Split Deal - I'],

        'Invoice Number': deliveryDto['Invoice Number'],

        'Yard Stall': deliveryDto['Yard Loc'] ?? '',

        'Misc Date': deliveryDto['Misc Date'],

        'Delivered Date': deliveryDto['Delivered Date'],
        'Has Photos': false,
        markForValidation: false,
    };

    return inventory;
};
