import { ResponsiveLine } from '@nivo/line';
import { ReactComponent as Point } from '../images/tooltipPoint.svg';
import ComputeLineGraphData, { objProperties } from '../models/ComputeUpdatedLineGraphData';

interface LineGraphProps {
    lineGraphData: ComputeLineGraphData;
}

export const LineGraph = ({ lineGraphData }: LineGraphProps) => {
    const getData = (category: string) => {
        const dataForGraph: object[] = [];
        if (lineGraphData.hasOwnProperty('data')) {
            const target = lineGraphData['data'];

            Object.entries(target).forEach(([key, value]) => {
                const t = {
                    x: key,
                    y: value[category as keyof objProperties],
                };
                dataForGraph.push(t);
            });
        } else {
            console.log(`Dictionary not found for key: ${'data'}`);
        }

        return dataForGraph;
    };

    const graphData = [
        {
            id: 'Savings',
            color: '#1DB89A',
            data: getData('savings'),
        },
        {
            id: 'Reduced Cost',
            color: 'hsl(196, 100%, 39%)',
            data: getData('reducedCost'),
        },
        {
            id: 'Original Cost',
            color: 'hsl(215, 57%, 91%)',
            data: getData('originalCost'),
        },
    ];

    const ThemeObj = {
        grid: {
            line: {
                stroke: '#DBE6F5',
                strokeWidth: 1,
                strokeDasharray: '3 4',
            },
            y: {
                tick: {
                    // Set display to 'none' for every nth tick to remove them
                    // eslint-disable-next-line no-empty-pattern
                    display: ({}, index: number) => (index % 2 !== 0 ? 'none' : 'block'),
                },
            },
        },
        lineHeight: 22,
        fontFamily: 'Rubik',
        fontSize: 14,
        textColor: '#a2a3a5',
        fontWeight: 400,
        legends: {
            text: {
                fontSize: '12',
                fill: 'rgba(17, 38, 60, 0.7)',
            },
        },
        axis: {
            domain: {
                line: {
                    stroke: '#DBE6F5',
                    strokeWidth: 2,
                },
            },
        },
    };

    return (
        <div className="graph-container">
            <ResponsiveLine
                enableCrosshair={true}
                crosshairType="bottom"
                colors={(d) => d.color}
                theme={ThemeObj}
                data={graphData}
                margin={{ top: 20, right: 100, bottom: 100, left: 100 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 0,
                    max: 'auto',
                    stacked: false,
                    reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                // axisRight={{
                //   tickValues: 5,
                //   tickSize: 0,
                //   tickPadding: 15,
                //   format: v => `${v}%`,

                //  }}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    ticksPosition: 'before',
                }}
                axisLeft={{
                    tickValues: 5,
                    tickSize: 0,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    format: (v) => `${v}k`,
                }}
                pointSize={0}
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                useMesh={true}
                enableGridX={false}
                legends={[
                    {
                        anchor: 'bottom-left',
                        direction: 'row',
                        justify: false,
                        translateX: -50,
                        translateY: 80,
                        itemsSpacing: 50,
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 20,
                        itemOpacity: 1,
                        symbolSize: 20,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    },
                ]}
                tooltip={(input) => {
                    return (
                        <div>
                            <div className="toolTip">
                                <label className="text-[color:#90949A]">{input.point.serieId}</label>
                                <label>{input.point.data.xFormatted} </label>
                                <b>
                                    <h3>${input.point.data.y.toString()},000</h3>
                                </b>
                            </div>
                            <Point className="point" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
