import { YardMapDto } from '../../models/Dtos/YardMapDtos/YardMapDto';
import { DataType } from '../../models/Global';

export const getDataTypeFromYardMapPropertyName = (property: keyof YardMapDto): DataType => {
    switch (property) {
        case 'Yard Stall': {
            return DataType.String;
        }
        case 'Stock #': {
            return DataType.String;
        }
        case 'Service Notes': {
            return DataType.String;
        }
        case 'VIN': {
            return DataType.String;
        }
        case 'Inventory Number': {
            return DataType.String;
        }
        case 'Customer': {
            return DataType.String;
        }
    }

    return DataType.String;
};
