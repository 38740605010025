import { IonChip, IonCol, IonIcon, IonModal, IonRow } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { DynamicFilter } from '../../../../../models/Dtos/OptionsDtos/DynamicFilter';
import { DynamicSortWithoutSortPriority } from '../../../../../models/Dtos/OptionsDtos/DynamicSort';
import {
    GeneralOptions,
    GeneralOptionsColumnsToShowAndColumnOrderAsArray,
} from '../../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { DtoType } from '../../../../../models/Global';
import GeneralToolTip from '../../../../GeneralToolTip/GeneralToolTip';
import ModalCloseButton from '../../../../ModalCloseButton/ModalCloseButton';
import { canUserAccessAdminSecurityWithGivenRoles, RoleContext } from '../../../../RoleContext/RoleContextComp';
import { AsyncSelectLoadOptionsReturnType } from '../../../../Search/SearchUtils';
import GlobalUserFilterManager from '../../GlobalUserFilterModal/GlobalUserFilterManager';
import ColumnVisibilityManager from './ColumnVisibilityManager/ColumnVisibilityManager';
import FilterManager from './FilterManager/FilterManager';
import styles from './FilterSortControlModal.module.scss';
import SortManager from './SortManager/SortManager';

interface Option {
    label: string;
    value: string;
}

interface FilterSortControlModalProps<T extends object> {
    dtoType: DtoType;
    showFilterModal: boolean;
    setShowFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleSetCustomFiltersValueFilters: (dynamicFilter: DynamicFilter[]) => void;
    model: object;
    closeModal: () => void;
    setSortFilters: (dynamicSorts: DynamicSortWithoutSortPriority[]) => void;
    handleCreateUpdateUserFilter: (userFilterName: string, updating: boolean) => void;
    handleCreateUpdateGlobalFilter: (globalFilterName: string, updating: boolean) => void;
    handleDeleteUserFilter: (userFilterString: string) => void;
    handleDeleteGlobalFilter: (globalFilterString: string) => void;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    unhidableColumns: string[];
    setColumnVisibility: (updatedColumnsToShowList: string[]) => void;
    fieldsThatAreCustomTypes: {
        [key in keyof T]?: { label: string; value: string; title: string }[];
    };
    data: T[] | undefined;
    getAsyncSelectGetMethodFromPropertyName: (
        property: keyof T
    ) => ((name: string) => Promise<AsyncSelectLoadOptionsReturnType<Option>>) | undefined;

    filterKeyArrayByExcludedColumns: (array: (keyof T)[]) => (keyof T)[];

    selectedUserFilterNames: string[];
    selectedGlobalFilterNames: string[];
    computedSelectedGlobalFilters: UserGlobalFilterSpecificGeneralOption[];
    computedSelectedUserFilters: UserGlobalFilterSpecificGeneralOption[];
    customFilters: GeneralOptions;
    unselectGlobalFilter: (globalFilterName: string) => boolean;
    unselectUserFilter: (userFilterName: string) => boolean;
    ignoreFilter: (filterId: string) => boolean;
    unignoreFilter: (filterId: string) => boolean;

    isFilterIgnored: (filterId: string) => boolean;
    combinedFilterTargetProperties: string[];

    globalFilterStorage: UserGlobalFilterSpecificGeneralOption[];
    userFilterStorage: UserGlobalFilterSpecificGeneralOption[];

    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterSortControlModal = <T extends object>({
    setSortFilters,
    showFilterModal,
    setShowFilterModal,
    handleSetCustomFiltersValueFilters,
    model,
    closeModal,
    handleCreateUpdateUserFilter,
    handleCreateUpdateGlobalFilter,
    handleDeleteUserFilter,
    handleDeleteGlobalFilter,
    dtoType,
    computedCombinedFilters,
    unhidableColumns,
    setColumnVisibility,
    fieldsThatAreCustomTypes,
    data,
    getAsyncSelectGetMethodFromPropertyName,

    filterKeyArrayByExcludedColumns,

    selectedUserFilterNames,
    selectedGlobalFilterNames,
    computedSelectedGlobalFilters,
    computedSelectedUserFilters,
    customFilters,
    unselectGlobalFilter,
    unselectUserFilter,
    ignoreFilter,
    unignoreFilter,
    isFilterIgnored,

    combinedFilterTargetProperties,
    globalFilterStorage,
    userFilterStorage,
    setIsLoading,
}: FilterSortControlModalProps<T>) => {
    const roles = useContext(RoleContext);

    const [managerToShowString, setManagerToShowString] = useState<'Filter' | 'Sort' | 'Visibility' | 'User/Global'>(
        'Filter'
    );

    const [showGlobalUserFilterModal, setGlobalUserFilterModal] = useState<boolean>(false);

    return (
        <IonModal isOpen={showFilterModal} onDidDismiss={() => setShowFilterModal(false)}>
            <ModalCloseButton
                setModalHideFunction={() => setShowFilterModal(false)}
                container={styles.modalCloseContainer}
                button={styles.modalCloseButton}
            />
            <IonRow style={{ backgroundColor: '#002b70', marginLeft: '0px', marginRight: '0px' }}>
                <IonCol style={{ position: 'relative', zIndex: '101' }}>
                    <GeneralToolTip content={['Opens filter control', 'Create and Remove Temporary Filters']}>
                        <IonChip
                            className={`${styles.firstChip} ${
                                managerToShowString === 'Filter' ? styles.selectedChip : ''
                            }`}
                            onClick={() => {
                                setManagerToShowString('Filter');
                            }}>
                            Filters
                            <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                        </IonChip>
                    </GeneralToolTip>
                </IonCol>
                <IonCol style={{ position: 'relative', zIndex: '100' }}>
                    <GeneralToolTip content={['Opens sorting control', 'Sort columns and set sorting priority']}>
                        <IonChip
                            className={`${styles.firstChip} ${
                                managerToShowString === 'Sort' ? styles.selectedChip : ''
                            }`}
                            onClick={() => {
                                setManagerToShowString('Sort');
                            }}>
                            Sorting
                            <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                        </IonChip>
                    </GeneralToolTip>
                </IonCol>
                <IonCol style={{ position: 'relative', zIndex: '99' }}>
                    <GeneralToolTip content={['Allows you to hide and show columns']}>
                        <IonChip
                            className={`${styles.firstChip} ${
                                managerToShowString === 'Visibility' ? styles.selectedChip : ''
                            }`}
                            onClick={() => {
                                setManagerToShowString('Visibility');
                            }}>
                            Visibility
                            <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                        </IonChip>
                    </GeneralToolTip>
                </IonCol>
                <IonCol style={{ position: 'relative', zIndex: '99' }}>
                    <GeneralToolTip
                        content={
                            canUserAccessAdminSecurityWithGivenRoles(roles)
                                ? ['Allows you to create user and global filters']
                                : ['Allows you to create personalized user filters']
                        }>
                        <IonChip
                            className={`${styles.firstChip} ${
                                managerToShowString === 'User/Global' ? styles.selectedChip : ''
                            }`}
                            onClick={() => {
                                setManagerToShowString('User/Global');
                            }}>
                            User{canUserAccessAdminSecurityWithGivenRoles(roles) ? '/Global Filters' : ' Filters'}
                            <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                        </IonChip>
                    </GeneralToolTip>
                </IonCol>
            </IonRow>
            {managerToShowString === 'Filter' ? (
                <FilterManager<T>
                    handleSetCustomFiltersValueFilters={handleSetCustomFiltersValueFilters}
                    model={model}
                    dtoType={dtoType}
                    closeModal={closeModal}
                    computedCombinedFilters={computedCombinedFilters}
                    fieldsThatAreCustomTypes={fieldsThatAreCustomTypes}
                    getAsyncSelectGetMethodFromPropertyName={getAsyncSelectGetMethodFromPropertyName}
                    filterKeyArrayByExcludedColumns={filterKeyArrayByExcludedColumns}
                    computedSelectedGlobalFilters={computedSelectedGlobalFilters}
                    computedSelectedUserFilters={computedSelectedUserFilters}
                    customFilters={customFilters}
                    unselectGlobalFilter={unselectGlobalFilter}
                    unselectUserFilter={unselectUserFilter}
                    ignoreFilter={ignoreFilter}
                    unignoreFilter={unignoreFilter}
                    isFilterIgnored={isFilterIgnored}
                    combinedFilterTargetProperties={combinedFilterTargetProperties}
                    setIsLoading={setIsLoading}
                />
            ) : managerToShowString === 'Sort' ? (
                <SortManager
                    setSortFilters={setSortFilters}
                    closeModal={closeModal}
                    computedCombinedFilters={computedCombinedFilters}
                    setIsLoading={setIsLoading}
                />
            ) : managerToShowString === 'Visibility' ? (
                <ColumnVisibilityManager<T>
                    setColumnVisibility={setColumnVisibility}
                    computedCombinedFilters={computedCombinedFilters}
                    unhidableColumns={unhidableColumns}
                    closeModal={closeModal}
                    data={data}
                    setIsLoading={setIsLoading}
                />
            ) : (
                <GlobalUserFilterManager
                    handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
                    handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
                    handleDeleteUserFilter={handleDeleteUserFilter}
                    handleDeleteGlobalFilter={handleDeleteGlobalFilter}
                    closeModal={closeModal}
                    model={model}
                    dtoType={dtoType}
                    selectedUserFilterNames={selectedUserFilterNames}
                    selectedGlobalFilterNames={selectedGlobalFilterNames}
                    globalFiltersStorage={globalFilterStorage}
                    userFiltersStorage={userFilterStorage}
                    setIsLoading={setIsLoading}
                />
            )}
        </IonModal>
    );
};

export default FilterSortControlModal;
