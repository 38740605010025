export class GetGlobalFilterDto {
    name: string;
    globalFilterRow: GetGlobalFilterType;

    constructor(obj?: GetGlobalFilterDto) {
        this.name = obj?.name ?? '';
        this.globalFilterRow = obj?.globalFilterRow ?? GetGlobalFilterType.Row2;
    }
}

export enum GetGlobalFilterType {
    Row1,
    Row2,
}
