import { FilterType, RecordType, SlotNumber } from '../../Global';

export class MetricOptionsUpdateDto {
    filterType: FilterType = FilterType.User;
    filterName: string = '';
    slotNumber: SlotNumber = SlotNumber.One;
    recordType: RecordType = RecordType.Inventory;
    description: string = '';

    constructor(objOrSlot?: MetricOptionsUpdateDto | number) {
        if (typeof objOrSlot === 'number') {
            this.slotNumber = objOrSlot;
            return;
        }

        this.filterType = objOrSlot?.filterType ?? FilterType.User;
        this.filterName = objOrSlot?.filterName ?? '';
        this.recordType = objOrSlot?.recordType ?? RecordType.Inventory;
        this.description = objOrSlot?.description ?? '';
    }
}
