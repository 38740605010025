/* MSAL STUFF */
import {
  AuthenticationResult,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import {
  loadingController,
  modalController,
  pickerController,
  toastController,
} from "@ionic/core";
/* Core CSS required for Ionic components to work properly */
import "@ionic/core/css/core.css";
import "@ionic/core/css/display.css";
import "@ionic/core/css/flex-utils.css";
import "@ionic/core/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/core/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/core/css/padding.css";
import "@ionic/core/css/structure.css";
import "@ionic/core/css/text-alignment.css";
import "@ionic/core/css/text-transformation.css";
import "@ionic/core/css/typography.css";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import ActiveAccountSetter from "./components/MsalRedirector/ActiveAccountSetter/ActiveAccountSetter";
import EmployeeEndpointCaller from "./components/MsalRedirector/EmployeeEndpointCaller/EmployeeEndpointCaller";
import MsalRedirector from "./components/MsalRedirector/MsalRedirector";
import { ReturnMessageOnlyDto } from "./models/Dtos/GeneralDtos/ReturnMessageOnlyDto";
import { ReturnModelListWithMessageDto } from "./models/Dtos/GeneralDtos/ReturnModelListWithMessageDto";
import { ReturnModelWithMessageDto } from "./models/Dtos/GeneralDtos/ReturnModelWithMessageDto";
import registerServiceWorker from "./registerServiceWorker";
/* Theme variables */
import "./theme/variables.css";
import { loginApiRequest, msalConfig } from "./utils/AuthConfig";
import makeSimpleToast from "./utils/MakeSimpleToast";
import ensureAllRequiredEnvironmentVariablesAreNotUndefined from "./utils/RequiredEnvironmentVariables";

const enableMocking = async () => {
  if (process.env.NODE_ENV != "development") {
    return;
  }

  // const { worker } = await import('./mocks/BrowserMocks/browser');

  // `worker.start()` returns a Promise that rolves
  // once the Service Worker is up and ready to intercept requests.
  // return worker.start();
};

export const NAME_OF_ACCESS_TOKEN_COOKIE = "UtilityTrailer_Access_Token";

if (!ensureAllRequiredEnvironmentVariablesAreNotUndefined()) {
  // Going to not run any code additional code besides showing env error page.
} else {
  const msalInstance = new PublicClientApplication(msalConfig);

  defineCustomElements();

  (window as any).loadingController = loadingController;
  (window as any).modalController = modalController;
  (window as any).pickerController = pickerController;
  (window as any).toastController = toastController;

  axios.interceptors.request.use(
    async (response) => {
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#how-do-i-handle-the-redirect-flow-in-a-react-app
      // Need to make sure user is Authenticated
      // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3034
      // Additionally because we're using aquireTokenSilent we don't need to do any checking of inProgress

      //Exceptions
      if (response.url?.includes("salesapppictures.blob.core.windows.net")) {
        return response;
      }

      const isAuthenticated = msalInstance.getAllAccounts().length >= 1;

      if (!isAuthenticated) {
        alert(
          "Please send message to web developers: Is Authenticated is null in index.tsx"
        );
        return response;
      }

      const activeAccount = msalInstance.getActiveAccount();

      if (activeAccount == null) {
        alert(
          "Please send message to web developers: Active ACcount is null in index.tsx"
        );
        return response;
      }
      let msalResponse: AuthenticationResult | null = null;

      try {
        msalResponse = await msalInstance.acquireTokenSilent({
          ...loginApiRequest,
          account: activeAccount,
        });
      } catch (err) {
        console.error(err);
      }

      if (msalResponse == null) {
        return;
      }

      // @ts-ignore
      response.headers.common.Authorization = `Bearer ${msalResponse.accessToken}`;
      return response;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  /* MSAL STUFF */

  axios.defaults.baseURL = process.env.REACT_APP_WEB_API_URL as string;

  axios.interceptors.response.use(
    (response) => {
      // return response;

      if (
        Object.getOwnPropertyNames(response.data).find(
          (propName) => propName.toLowerCase() === "showtoast"
        ) === undefined
      ) {
        // Ignore responses that don't have a showToast property
        return response;
      }
      const { isSuccessful, messageForUser, devMessage, showToast } =
        response.data as
          | ReturnModelListWithMessageDto<unknown>
          | ReturnModelWithMessageDto<unknown>
          | ReturnMessageOnlyDto;

      if (!isSuccessful) {
        console.error(`Interceptor Error:\n${devMessage}`);
      } else {
        console.info(`Interceptor Log:\n${devMessage}`);
      }

      if (showToast) {
        makeSimpleToast(isSuccessful ? "Success!" : "Error!", messageForUser);

        // (window as any).toastController
        //     .create({
        //         header: isSuccessful ? 'Success!' : 'Error!',
        //         message: messageForUser,
        //         duration: 7000,
        //         position: 'bottom',
        //     })
        //     //@ts-ignore
        //     .then((toast) => {
        //         toast.present();
        //     });
      }
      return response;
    },
    (err) => {
      console.error(err);
      return Promise.reject(err);
    }
  );

  enableMocking().then(() => {
    const container = document.getElementById("root")!;

    const root = createRoot(container);

    root.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <MsalRedirector>
            <ActiveAccountSetter>
              <EmployeeEndpointCaller>
                {/* <AuthRedirector> */}
                <App />
                {/* </AuthRedirector> */}
              </EmployeeEndpointCaller>
            </ActiveAccountSetter>
          </MsalRedirector>
        </MsalProvider>
      </React.StrictMode>
    );

    registerServiceWorker();
  });
}
