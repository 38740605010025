import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { DynamicSort } from '../../models/Dtos/OptionsDtos/DynamicSort';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import { SortFiltersType } from '../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import styles from './SortManagerHookComp.module.scss';

interface SortManagerHookCompProps {
    propertyNames: string[];
    setSortFilters: (sortFilters: DynamicSort[]) => void;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

const SortManagerHookComp = ({ propertyNames, setSortFilters, setShowModal }: SortManagerHookCompProps) => {
    const [sortList, setSortList] = useState<SortFiltersType[]>([]);

    const [columnToAdd] = useState<string>('');

    const removeColumnFromSort = (columnName: string) => {
        setSortList((oldList) => {
            return oldList.filter(([propretyName]) => propretyName !== columnName);
        });
    };

    const handleMoveColumnDown = (columnName: string) => {
        const indexOf = sortList.findIndex(([propertyName]) => propertyName === columnName);

        if (indexOf === -1) {
            throw new Error("Attempting to move column down but column isn't inside of internal sort list!!");
        }

        const targetStatus = sortList[indexOf];

        if (indexOf >= sortList.length - 1) {
            console.warn('Attempting to move column below nothing!');
            return;
        }

        setSortList((oldList) => {
            return [...oldList.slice(0, indexOf), oldList[indexOf + 1], targetStatus, ...oldList.slice(indexOf + 2)];
        });
    };
    const handleMoveColumnUp = (columnName: string) => {
        const indexOf = sortList.findIndex(([propertyName]) => propertyName === columnName);

        if (indexOf === -1) {
            throw new Error("Attempting to move column up but column isn't inside of internal sort list!!");
        }

        const targetStatus = sortList[indexOf];

        if (indexOf === 0) {
            console.warn('Attempting to move column above nothing!');
            return;
        }

        setSortList((oldList) => {
            return [
                ...oldList.slice(0, indexOf - 1),
                targetStatus,
                oldList[indexOf - 1],
                ...oldList.slice(indexOf + 1),
            ];

            // return [...oldList.slice(0, indexOf), oldList[indexOf + 1], targetStatus, ...oldList.slice(indexOf + 2)];
        });
    };

    const handleAddColumnToSorting = (columnName: string | undefined) => {
        if (columnName === '') {
            return;
        }
        if (isNullOrUndefined(columnName)) {
            alert('Please select a Column Name to add!');
            return;
        }

        const targetSortFilter = sortList.find(([propertyName]) => propertyName === columnName);

        if (isNotNullNorUndefined(targetSortFilter)) {
            // already exists
            alert(`Column ${columnName} already exists! Please choose a column that doesn't exist.`);
            return;
        }

        const newSortFilterType: SortFiltersType = [columnName, true];

        setSortList((oldSortList) => {
            return [...oldSortList, newSortFilterType];
        });
    };

    const handleSetAscending = (columnName: string, ascending: boolean) => {
        const updatedSortFilterType: SortFiltersType = [columnName, ascending];

        setSortList((oldSortList) => {
            return oldSortList.map((sortFilter) => {
                if (sortFilter[0] !== columnName) {
                    return sortFilter;
                }
                return updatedSortFilterType;
            });
        });
    };

    const submitChangesSortButton = () => {
        const dynamicSortVersion: DynamicSort[] = sortList.map(([propertyName, isAscending], index) => {
            return {
                sortPriority: index + 1,
                isAscending: isAscending,
                propertyName: propertyName,
            };
        });

        setSortFilters(dynamicSortVersion);
        setShowModal(false);
    };

    return (
        <div className={styles.SortManagerHookCompContainer} data-testid="SortManagerHookComp">
            <IonGrid>
                {sortList.map(([propertyName, ascending], index) => (
                    <IonRow key={propertyName}>
                        <IonCol>
                            <IonLabel>{index}</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonLabel>{propertyName}</IonLabel>
                        </IonCol>
                        <IonCol>
                            <IonLabel>Is Ascending</IonLabel>
                            <IonCheckbox
                                checked={ascending}
                                onIonChange={(e) => handleSetAscending(propertyName, e.detail.checked)}
                            />
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => handleMoveColumnUp(propertyName)}>Up</IonButton>
                            <IonButton onClick={() => handleMoveColumnDown(propertyName)}>Down</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton color="danger" onClick={() => removeColumnFromSort(propertyName)}>
                                Delete
                            </IonButton>
                        </IonCol>
                    </IonRow>
                ))}
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Proprety To Sort</IonLabel>
                            <IonSelect
                                value={columnToAdd}
                                onIonChange={(e) => {
                                    const value = e.detail.value ?? '';
                                    handleAddColumnToSorting(value);
                                }}>
                                {propertyNames
                                    .filter(
                                        (propertyName) => !sortList.find(([columnName]) => columnName === propertyName)
                                    )
                                    .map((propertyName) => (
                                        <IonSelectOption key={propertyName} value={propertyName}>
                                            {propertyName}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {/* <IonRow>
                    <IonCol>
                        <IonButton onClick={() => }>
                            Add Column To Sorting
                        </IonButton>
                    </IonCol>
                </IonRow> */}
            </IonGrid>
            <IonRow>
                <IonCol>
                    <IonButton onClick={submitChangesSortButton}>Submit</IonButton>
                </IonCol>
            </IonRow>
        </div>
    );
};

export default SortManagerHookComp;
