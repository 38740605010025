import React from 'react';
import PrintTableController from '../PrintTableController/PrintTableController';
import styles from './PrintQueriedTable.module.scss';

interface PrintQueriedTableProps<T extends object> {
    data: T[];
    identifierProperty: keyof T;
    triggerJSX: React.ReactElement;
    columnsToShow?: (keyof T)[] | string;
    exportEnumProcessor: (dto: T) => T;
    enumProperties: (keyof T)[];

    printProcessor: (inventoryDto: T) => T;
}

const PrintQueriedTable = <T extends object>({
    data,
    identifierProperty,
    triggerJSX,
    columnsToShow,
    exportEnumProcessor,
    enumProperties,
    printProcessor,
}: PrintQueriedTableProps<T>) => {
    return (
        <div className={styles.PrintQueriedTableContainer} data-testid="PrintQueriedTable">
            <PrintTableController<T>
                data={data}
                identifierProperty={identifierProperty}
                triggerJSX={triggerJSX}
                columnsToShow={columnsToShow}
                hiddenContent={true}
                exportEnumProcessor={exportEnumProcessor}
                enumProperties={enumProperties}
                printProcessor={printProcessor}
            />
        </div>
    );
};

export default PrintQueriedTable;
