import { useEffect, useMemo, useRef } from 'react';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { YardMapDto } from '../../models/Dtos/YardMapDtos/YardMapDto';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import {
    SpecializedTableStandardHeaderStyle,
    SpecializedTableStandardIdentifierColumnStyle,
    SpecializedTableStandardTableDataStyle,
    SpecializedTableStandardTableTagStyle,
    SpecializedTableStandardTextWrapper,
} from '../../utils/SpecializedTableStandardStylesAndElements';
import { CH_OFFSET, MIN_WIDTH_INPUT } from '../NewTableApproach/GeneralReference';
import styles from './YardMapSpecializedTableRender.module.scss';

// Idea go through each value of the column and determine the biggest width. and then perhaps
// set the inputs to width 100% unless they match the biggest width. I'm not sure.

const generateInputStylesAndUpdateBiggestInputWidth = (valueToLookAt: string, columnName: string) => {
    let width = 0;
    if (isNotNullNorUndefined(valueToLookAt) && valueToLookAt !== '' && valueToLookAt !== 'null') {
        width = valueToLookAt.length;
    }
    return { width: width + CH_OFFSET + 'ch', minWidth: MIN_WIDTH_INPUT };
};

const savedFunctionsToUpdateData = {
    'Yard Stall': (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        YardStall: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td
                style={{
                    ...SpecializedTableStandardTableDataStyle(index),
                    ...SpecializedTableStandardIdentifierColumnStyle,
                }}
                key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{YardStall}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Stock #': (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        StockNumber: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{StockNumber}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Service Notes': (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        ServiceNotes: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            {ServiceNotes}
        </td>
    ),
    VIN: (yardMapDto: YardMapDto, property: keyof YardMapDto, VIN: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <SpecializedTableStandardTextWrapper>{VIN}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Inventory Number': (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        InventoryNumber: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <SpecializedTableStandardTextWrapper>{InventoryNumber}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    Customer: (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        Customer: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <SpecializedTableStandardTextWrapper>{Customer}</SpecializedTableStandardTextWrapper>
        </td>
    ),
};

interface YardMapSpecalizedTableRenderProps {
    data: YardMapDto[];
    selectedRecords: string[];
    invisibleContainerDiv: HTMLDivElement;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
}

const YardMapSpecalizedTableRender = ({
    data,
    selectedRecords,
    invisibleContainerDiv,
    computedCombinedFilters,
}: YardMapSpecalizedTableRenderProps) => {
    const filteredData = useMemo(() => {
        return data.filter((dto) => selectedRecords.includes(dto['Yard Stall']));
    }, [data, selectedRecords]);

    const tableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        const handleCopy = async () => {
            if (isNullOrUndefined(tableRef.current)) {
                makeSimpleToast('Error while copying styles', 'Table div is undefined or null');
                console.error('Error while copying styles, table div is undefined or null');
                return;
            }

            try {
                // Copy the selected content to the clipboard using Clipboard API
                navigator.clipboard
                    .write([
                        new ClipboardItem({
                            'text/html': new Blob([tableRef.current.outerHTML], { type: 'text/html' }),
                        }),
                    ])
                    .then(() => {
                        console.log('Table copied to clipboard successfully!');

                        makeSimpleToast(
                            'Successfully copied specialized table!',
                            "You've copied the specialized version of the table for email."
                        );
                    })
                    .catch((err) => {
                        console.error('Unable to copy table to clipboard:', err);
                        makeSimpleToast(
                            'Error while trying to copy styles.',
                            'Page not focused. Please try again and try to keep the page focused for at least a second.'
                        );
                    });
            } catch (error) {
                console.error('Clipboard API is not supported in this browser:', error);
            }

            invisibleContainerDiv.remove();
        };
        handleCopy();
    }, [invisibleContainerDiv]);

    return (
        <div className={styles.YardMapSpecializedTableRenderContainer} data-testid="YardMapSpecializedTableRender">
            <table style={SpecializedTableStandardTableTagStyle} ref={tableRef}>
                <thead>
                    <tr>
                        {computedCombinedFilters.orderedColumnsToShow.map((column) => (
                            <th
                                style={{
                                    ...SpecializedTableStandardHeaderStyle,
                                    ...(column.toLowerCase() === 'yard stall'
                                        ? SpecializedTableStandardIdentifierColumnStyle
                                        : {}),
                                }}
                                key={column}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((dataDto, rowIndex) => {
                        return (
                            <tr key={dataDto['Yard Stall']}>
                                {computedCombinedFilters.orderedColumnsToShow.map((columnName) => {
                                    return savedFunctionsToUpdateData[
                                        columnName as keyof typeof savedFunctionsToUpdateData
                                    ](
                                        dataDto,
                                        columnName as keyof YardMapDto,
                                        // Might need to revisit
                                        dataDto[columnName as keyof YardMapDto] as any,
                                        dataDto['Yard Stall'],
                                        rowIndex
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default YardMapSpecalizedTableRender;
