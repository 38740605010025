import { ReturnAccountingToDoDto } from '../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { InventoryDto } from '../models/Dtos/InventoryDtos/InventoryDto';
import { DeliveryDto } from '../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { YardMapDto } from '../models/Dtos/YardMapDtos/YardMapDto';
import { DtoType, RecordType } from '../models/Global';
import { getDataTypeFromAccountingTodoPropertyName } from './DataTypeMappersByDtoType/AccountingTodoDataMapper';
import { getDataTypeFromDeliveryPropertyName } from './DataTypeMappersByDtoType/DeliveryDtoDataTypeMapper';
import { getDataTypeFromInventoryDtoPropertyName } from './DataTypeMappersByDtoType/InventoryDtoDataTypeMapper';
import { getDataTypeFromSalesOrderPropertyName } from './DataTypeMappersByDtoType/SalesOrderDtoDataTypeMapper';
import { getDataTypeFromYardMapPropertyName } from './DataTypeMappersByDtoType/YardMapDtoMapper';

export const getDataTypeByRecordTypeTypeAndPropertyName = (
    recordOrDtoType: RecordType | DtoType,
    propertyName: string
) => {
    switch (recordOrDtoType) {
        case RecordType.Inventory: {
            return getDataTypeFromInventoryDtoPropertyName(propertyName as keyof InventoryDto);
        }
        case RecordType.SalesOrder: {
            return getDataTypeFromSalesOrderPropertyName(propertyName as keyof SalesOrderDto);
        }
        case RecordType.Delivery: {
            return getDataTypeFromDeliveryPropertyName(propertyName as keyof DeliveryDto);
        }
        case RecordType.AccountingTodo: {
            return getDataTypeFromAccountingTodoPropertyName(propertyName as keyof ReturnAccountingToDoDto);
        }
        case RecordType.YardMap: {
            return getDataTypeFromYardMapPropertyName(propertyName as keyof YardMapDto);
        }
        default: {
            return getDataTypeFromInventoryDtoPropertyName(propertyName as keyof InventoryDto);
        }
    }
};
