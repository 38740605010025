import { Link } from 'react-router-dom';
import { isNotNullNorUndefined } from '../../utils/NullOrUndefined';

interface ConditionalLinkWrapperProps {
    to: string;
    conditionToRedirectOnClick: boolean;
    children: JSX.Element;
    className?: string;
    maxWidth?: boolean;
}

const ConditionalLinkWrapper = ({
    to,
    conditionToRedirectOnClick,
    children,
    className,
    maxWidth,
}: ConditionalLinkWrapperProps) => {
    if (conditionToRedirectOnClick) {
        return (
            <Link
                className={className}
                style={{ width: isNotNullNorUndefined(maxWidth) && maxWidth ? '100%' : '' }}
                to={to}>
                {children}
            </Link>
        );
    }
    return children;
};

export default ConditionalLinkWrapper;
