import { CSSProperties } from 'react';

interface SpecializedTableStandardTextWrapperProps {
  children: JSX.Element | string | number | boolean;
}

export const SpecializedTableStandardTextWrapperStyle: CSSProperties = {
  backgroundColor: 'red',
  color: 'red',
  whiteSpace: 'nowrap',
};

export const SpecializedTableStandardTextWrapper = ({ children }: SpecializedTableStandardTextWrapperProps) => (
  <p
    // style={SpecializedTableStandardTextWrapperStyle}
    style={{ whiteSpace: 'nowrap' }}
  // style={{ width: '500px' }}
  // style={{ textWrap: 'noWrap' }}
  >
    {children}
  </p>
);


export const SpecializedTableStandardTextAreaWrapper = ({ children }: SpecializedTableStandardTextWrapperProps) => (
  <textarea
    // style={SpecializedTableStandardTextWrapperStyle}
    style={{ whiteSpace: 'nowrap' }}
  // style={{ width: '500px' }}
  // style={{ textWrap: 'noWrap' }}
  >
    {children}
  </textarea>
);

export const SpecializedTableStandardHeaderStyle: CSSProperties = {
  backgroundColor: '#eff1f3',
  whiteSpace: 'nowrap',
  border: '1px solid black',
  borderCollapse: 'collapse',
};

export const SpecializedTableStandardIdentifierColumnStyle: CSSProperties = {
  backgroundColor: '#cccccc',
};

export const SpecializedTableStandardTableTagStyle: CSSProperties = {
  rowGap: '0',
  columnGap: '0',
  border: '1px solid black',
  borderCollapse: 'collapse',
};

export const SpecializedTableStandardTableDataStyle: (index: number) => CSSProperties = (index) => {
  return index % 2 === 0
    ? { backgroundColor: '#fafafa', border: '1px solid black', borderCollapse: 'collapse' }
    : { backgroundColor: 'white', border: '1px solid black', borderCollapse: 'collapse' };
};
