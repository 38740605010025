import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';

const excludeFromColumnsToShow: readonly (keyof SalesOrderDto)[] = ['Id', 'Deliveries'] as const;

type KeyOfSalesOrderArray = (keyof SalesOrderDto)[];

export const filterSalesOrderKeyArrayByExcludedColumns = (array: KeyOfSalesOrderArray) => {
    const filteredArray = array.filter(filterSalesOrderColumnMethod);
    return filteredArray;
};

export const isSalesOrderPropertyExcluded = (property: keyof SalesOrderDto) => {
    return excludeFromColumnsToShow.includes(property);
};

export const filterSalesOrderColumnMethod = (column: string) => {
    return !excludeFromColumnsToShow.includes(column as keyof SalesOrderDto);
};
