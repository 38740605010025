import { IonRouterOutlet } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Redirect, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { InventoryPageQueriedTable, LoginPage, SalesOrderPage, YardMapQueriedTable } from '../../../pages';
import SettingsPanel from '../../../pages/AdminPanel/SettingsPanel';
import InvCondensedView from '../../../pages/InvCondensedView/InvCondensedView';
import { InventoryPage } from '../../../pages/Inventory';
import { OrderedDeliveredReports } from '../../../pages/Reports/OrderedDeliveredReports';
import { ProfitLossReportsPage } from '../../../pages/Reports/ProfitLossReportsPage';
import { SalesReportsPage } from '../../../pages/Reports/SalesReportPage';
import SalesOrderSwitcher, { InvolvedRecordsSwitcher } from '../../../pages/SalesOrderSwitcher.tsx/SalesOrderSwitcher';
import { YardMapPage } from '../../../pages/YardMap/YardMapPage';
import { IdentifierType } from '../../../utils/identifierType';
import { isNullOrUndefined } from '../../../utils/NullOrUndefined';
import TestPage from '../../TestPage/TestPage';
import styles from './IonSplitPaneStateContainer.module.scss';

export const ID_PARAMETER_NAME = 'id';
export const ID_TYPE_PARAMETER_NAME = 'idType';

export type UseParamsObject = {
  [ID_PARAMETER_NAME]: string;
  [ID_TYPE_PARAMETER_NAME]: IdentifierType;
};

export const ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID = 'main-ion-router-outlet';

export const IonSplitPaneStateContainer = () => {
  const [shown] = useState<boolean>(true);

  const location = useLocation();

  const idTypeAndIdUrlParameters = `:${ID_TYPE_PARAMETER_NAME}/:${ID_PARAMETER_NAME}`;

  const [scrollTop, setScrollTop] = useState<number>(0);

  const routerOutetRef = useRef<HTMLIonRouterOutletElement | null>(null);

  const debounce = (method: Function, delay: number) => {
    clearTimeout((method as any)._tId);
    (method as any)._tId = setTimeout(function() {
      method();
    }, delay);
  };

  const [printScrollInfo, setPrintScrollInfo] = useState<boolean>(false);

  const onScroll = useCallback(
    (e: Event) => {
      if (isNullOrUndefined(routerOutetRef.current)) {
        console.error('Router Outlet not found for scrolling updater.');
        return;
      }

      const target = routerOutetRef.current;

      debounce(() => {
        if (location.pathname.includes('edit')) {
          // Don't want to run this code on non-table pages
          return;
        }

        setScrollTop(target.scrollTop);
      }, 50);
    },
    [location, printScrollInfo]
  );

  useEffect(() => {
    // setTimeout(() => {
    const t = routerOutetRef.current;
    t?.click();
    // }, 2000);
  }, []);

  useEffect(() => {
    const lowerVersion = window.location.pathname.toLowerCase();

    if (
      isNullOrUndefined(routerOutetRef.current) ||
      lowerVersion.includes('create') ||
      lowerVersion.includes('edit')
    ) {
      return;
    }

    const target = routerOutetRef.current;

    (target as HTMLIonRouterOutletElement).addEventListener('scroll', onScroll);
    return () => {
      (target as HTMLIonRouterOutletElement).removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return (
    <>
      <IonRouterOutlet
        id={ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID}
        className={styles.test}
        ref={routerOutetRef}>
        <Route path="/" exact render={() => <Redirect to="/inventory" />} />
        <Route path={`/inventory`} exact>
          <InventoryPageQueriedTable scrollTop={scrollTop} />
        </Route>
        <Route path={`/inventory/create`} component={InventoryPage} exact />
        <Route path={`/inventory/edit/${idTypeAndIdUrlParameters}`} component={InventoryPage} exact />

        <Route path={`/yard-location`} component={YardMapQueriedTable} exact />
        <Route path={`/yard-location/edit/${idTypeAndIdUrlParameters}`} component={YardMapPage} exact />

        <Route path={`/sales-order`} exact>
          <SalesOrderSwitcher selected={InvolvedRecordsSwitcher['Sales Order']} scrollTop={scrollTop} />
        </Route>
        <Route path={`/sales-order/create`} component={SalesOrderPage} exact />
        <Route path={`/sales-order/edit/${idTypeAndIdUrlParameters}`} component={SalesOrderPage}></Route>

        <Route path={`/delivery`} exact>
          <SalesOrderSwitcher selected={InvolvedRecordsSwitcher.Delivery} scrollTop={scrollTop} />
        </Route>

        <Route path={`/accountingtodo`} exact>
          <SalesOrderSwitcher selected={InvolvedRecordsSwitcher['Accounting Todo']} scrollTop={scrollTop} />
        </Route>

        <Route path="/settings-panel" component={SettingsPanel} exact />
        <Route path="/login" component={LoginPage} exact />
        <Route path="/invcondensedview" component={InvCondensedView} exact />
        <Route path="/test" component={TestPage} exact />

        <Route path="/reports" component={SalesReportsPage} exact />
        <Route path="/reports/sales-reports" component={SalesReportsPage} exact />
        <Route path="/reports/profit-loss-reports" component={ProfitLossReportsPage} exact />
        <Route path="/reports/ordered-delivered-reports" component={OrderedDeliveredReports} exact />
      </IonRouterOutlet>
    </>
  );
};
