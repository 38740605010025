import axios, { AxiosInstance } from 'axios';
import { PhotoCreationResponseDto } from '../models/Dtos/PhotoCreationResponseDto';
import { UrlPhotoIdWrapper } from '../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
import { RecordType } from '../models/Global';
import { PhotoService } from './apiServices';
import makeSimpleToast from './MakeSimpleToast';
import { isNullOrUndefined } from './NullOrUndefined';

const getCorrectFolderStringPathToSaveToAzureBasedOffOfPhotoTypeAndStock = (
    recordType: RecordType,
    stockNumber: string
) => {
    switch (recordType) {
        case RecordType.Delivery:
            return `inventory/${stockNumber}/inventoryDelivered`;
        case RecordType.Inventory:
            return `inventory/${stockNumber}/inventory`;
        case RecordType.RecordOfTransfer:
            return `inventory/${stockNumber}/transfer`;
        default:
            console.error(`Couldn't find matching Record Type for: ${recordType}! Using Inventory type for now`);
            return `inventory/${stockNumber}/inventory`;
    }
};

const deleteFromAzureAfterWebApiUploadError = async (
    instance: AxiosInstance,
    url: string,
    sasToken: string,
    currentDateString: string
) => {
    await instance
        .delete(url + sasToken, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
                // 'Content-Type': 'image/jpeg',
                'x-ms-date': currentDateString,
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-version': '2021-06-08',
            },
        })
        .catch((e) => {
            makeSimpleToast(
                `Uploading picture to web api failed. Tried to delete photo from azure and also failed!: ${e}`
            );
            console.log(`Uploading picture to web api failed. Tried to delete photo from azure and also failed!: ${e}`);
            console.error(`Uploading picture to web api failed. Tried to delete photo from azure and also failed!`, e);
        });
};

export function handleUploadPhoto(
    fileListOrSingleBlob: FileList,
    fileName: null,
    stock: string,
    recordType: RecordType,

    IsDriversLicense: boolean,
    callback: (photos: PhotoCreationResponseDto) => void
    // callbackFunction: () => {}`
): Promise<UrlPhotoIdWrapper[] | null>;
export function handleUploadPhoto(
    fileListOrSingleBlob: Blob,
    fileName: string,
    stock: string,
    recordType: RecordType,
    IsDriversLicense: boolean,
    callback: (photos: PhotoCreationResponseDto) => void
): Promise<UrlPhotoIdWrapper[] | null>;

export async function handleUploadPhoto(
    fileListOrSingleBlob: Blob | FileList,
    fileName: string | null,
    stock: string,
    recordType: RecordType,
    IsDriversLicense: boolean,
    callback: (photoResults: PhotoCreationResponseDto) => void
): // Returning list or null. Null indicates it was unsuccessful. List indicates successfullly create urls
Promise<UrlPhotoIdWrapper[] | null> {
    // Make sure when uploading photos, if either fail (azure or webapi), need to remove data from the other
    if (isNullOrUndefined(fileListOrSingleBlob)) {
        console.warn('Attempting to upload with no photos provided!');
        return null;
    }

    // const folderToUploadTo = getCorrectFolderStringPathToSaveToAzureBasedOffOfPhotoTypeAndStock(recordType, stock);
    // // const folderToUploadTo = 'inventory';

    // const sasToken: string = process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN as string;

    // const currentDateObject = new Date();
    // const currentDateString =
    //     currentDateObject.getDay() + '-' + currentDateObject.getMonth() + '-' + currentDateObject.getFullYear();

    const instance = axios.create();
    delete instance.defaults.headers.common['Authorization'];

    const fileNamesThatWereNotSuccessfullyUploaded: { [fileName: string]: string } = {};

    const urlPhotoIdWrappersThatWereSuccessfullyUploaded: UrlPhotoIdWrapper[] = [];

    const promises: Promise<unknown>[] = [];

    // console.log('TEST - Going to go through for loop');

    if (fileListOrSingleBlob instanceof Blob) {
        // Since in typescript if you pass in a blob for fileListOrSingleBlob
        // you must pass in a string, this filename will be a valid string.
        const blobFileName = fileName as string;
        const blob = fileListOrSingleBlob as Blob;
        const fileNameIndexOfOrder = blobFileName.search(/-[0-9]+$/g);

        let parsedFileName: string = blobFileName;

        if (fileNameIndexOfOrder !== -1) {
            // already provided

            parsedFileName = blobFileName.substring(0, fileNameIndexOfOrder);
        }

        const formData = new FormData();

        formData.append(parsedFileName, blob);

        const result = await PhotoService.createMultiple(formData, stock, recordType, IsDriversLicense);

        if (result.data.isSuccessful && (result.status + '').startsWith('2')) {
            const model = result.data.model;
            callback(model);
        }

        console.log(result);
    }

    if (fileListOrSingleBlob instanceof FileList) {
        const fileList = fileListOrSingleBlob;

        const formData = new FormData();

        Array.from(fileList).forEach((file) => {
            formData.append(file.name, file);
        });

        const result = await PhotoService.createMultiple(formData, stock, recordType, IsDriversLicense);

        if (result.data.isSuccessful && (result.status + '').startsWith('2')) {
            const model = result.data.model;
            callback(model);
        }

        console.log(result);
    }

    // Telling the code to wait for the promises to settle before doing logic and returning

    await Promise.allSettled(promises);

    const headerMessage =
        Object.keys(fileNamesThatWereNotSuccessfullyUploaded).length <= 0
            ? 'Upload Completed without issues!'
            : 'Upload Completed with issues for files:';
    let body = '';

    const notSuccessfulEntries = Object.entries(fileNamesThatWereNotSuccessfullyUploaded);

    if (notSuccessfulEntries.length > 0) {
        const failedMessage = notSuccessfulEntries.reduce(
            (accu, [fileName, errorMessage]) => (accu += `${fileName}: ${errorMessage}`),
            ''
        );

        body = failedMessage;
    }

    console.log(`Header: ${headerMessage}`);
    console.log(`Body: ${body}`);

    // console.log('TEST - Before normal Toast');
    makeSimpleToast(headerMessage, body);

    // console.log('TEST - AFter normal toast.');
    // console.log('Made Simple Toast');

    return urlPhotoIdWrappersThatWereSuccessfullyUploaded === undefined ||
        urlPhotoIdWrappersThatWereSuccessfullyUploaded.length > 0
        ? urlPhotoIdWrappersThatWereSuccessfullyUploaded
        : null;
}
