import { FilterType } from '../../Global';
import { DynamicFilter } from './DynamicFilter';
import { GeneralOptions } from './GeneralOptions';
import { SortOptions } from './SortOptions';

export class ImportOptions {
    filterOptions: DynamicFilter[];
    sortOptions: SortOptions;
    columnsToShow: string;
    columnOrder: string;
    filterType: FilterType;
    isFilterUpdate: boolean;
    filterName: string;
    getAll: boolean;
    useItemsPerPage: boolean;
    searchTerm: string;

    constructor(obj: GeneralOptions, getAll: boolean, useItemsPerPage: boolean) {
        this.filterOptions = obj.filterOptions;
        this.sortOptions = obj.sortOptions;
        this.columnsToShow = obj.columnsToShow;
        this.columnOrder = obj.columnOrder;
        this.filterType = obj.filterType;
        this.isFilterUpdate = obj.isFilterUpdate;
        this.filterName = obj.filterName;
        this.getAll = getAll;
        this.useItemsPerPage = useItemsPerPage;
        this.searchTerm = obj.searchTerm;
    }
}
