import { CustomerDto } from '../../models/Dtos/CustomerDto';
import { StringDto } from '../../models/Dtos/GeneralDtos/StringDto';
import { CustomerService } from '../../utils/apiServices';
import { AsyncSelectLoadOptionsReturnType } from './SearchUtils';
interface Option {
    label: string;
    value: string;
    title: string;
}

export const SearchForCustomer = async (name: string): Promise<AsyncSelectLoadOptionsReturnType<Option>> => {
    const response = await CustomerService.SearchByName(new StringDto(name));
    const messageDto = response.data;
    const data = messageDto.model;

    const customerNameArray = data.map<Option>((x: CustomerDto) => {
        return { label: x.name, value: x.name, title: x.name };
    });
    return customerNameArray;
};
