import { InventoryDto } from '../InventoryDtos/InventoryDto';

export class YardLocationDetailsWithInventoryDto {
    'Location Name' = '';
    'Last Updated' = '';
    Inventory: InventoryDto[] = [];
}

export class TrailerInfo {
    stockNumber: string;
    notes: string;
    vIN: string;
    customer: string;
}
