import { AccountInfo, EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { ReactNode, useEffect, useState } from 'react';
import { EmployeeService } from '../../utils/apiServices';
import { loginApiRequest, TENANT_ID } from '../../utils/AuthConfig';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import RoleContextComp from '../RoleContext/RoleContextComp';
import MsalRedirectorErrorComponent from './MsalRedirectorErrorComponent/MsalRedirectorErrorComponent';
import MsalRedirectorLoadingComponent from './MsalRedirectorLoadingComponent/MsalRedirectorLoadingComponent';
import MsalRedirectorNoEnvComp from './MsalRedirectorNoEnvComp/MsalRedirectorNoEnvComp';

interface MsalRedirectorProps {
    children: ReactNode;
}

const MsalRedirector = ({ children }: MsalRedirectorProps) => {
    // Will need to do a approach that gives us more control of knowing when we've successfully logged in.
    // This appraoch isn't the normal so if you see other approaches online we're using this appraoch
    // beacuse we need more information. Read more about the recommended steps in our case down here:
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#how-do-i-handle-the-redirect-flow-in-a-react-app

    /*

    TODO later:
     Follow this process

    If you need direct access to the response object or error returned by a redirect operation there are 3 ways you can do this:

Use the event API to register a callback that will be invoked with the response when you return to your app. Make sure this is registered in a code path that is run after the redirect as any callbacks registered before the redirect will be lost.
Use the useMsalAuthentication hook to login. This hook will return the result or error when you are returned to your app.
Call handleRedirectPromise which will resolve with the result or reject with an error if the page was loaded as a result of a redirect.

    
    
     */

    useMsalAuthentication(InteractionType.Redirect, loginApiRequest ?? { scopes: [''] });
    const { instance, inProgress, accounts } = useMsal();

    const [errorMessage, setErrorMessage] = useState<EventMessage>();

    const [showChildren, setShowChildren] = useState<boolean>(
        isNotNullNorUndefined(instance.getActiveAccount()) ||
            isNotNullNorUndefined(accounts.find((account) => account.tenantId === TENANT_ID))
    );

    useEffect(() => {
        if (isNullOrUndefined(loginApiRequest)) {
            return;
        }

        // handleAuth();
        let callbackId: string | null = null;

        callbackId = instance.addEventCallback((message: EventMessage) => {
            // This will be run every time an event is emitted after registering this callback

            if (message.eventType === EventType.LOGIN_FAILURE) {
                setErrorMessage(message);
            }

            if (message.eventType !== EventType.LOGIN_SUCCESS) {
                return;
            }

            const accountInfo = message.payload as AccountInfo;

            instance.setActiveAccount(accountInfo);

            EmployeeService.UpdateEmployeeOnLogin().then(() => {
                setShowChildren(true);
            });
        });

        return () => {
            // This will be run on component unmount
            if (isNotNullNorUndefined(callbackId)) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance, inProgress, accounts]);

    if (isNotNullNorUndefined(errorMessage)) {
        return <MsalRedirectorErrorComponent errorMessage={errorMessage} />;
    }

    if (isNullOrUndefined(loginApiRequest)) {
        return <MsalRedirectorNoEnvComp />;
    }

    if (showChildren) {
        return <RoleContextComp>{children}</RoleContextComp>;
    } else {
        return <MsalRedirectorLoadingComponent />;
    }

    // return <div className={styles.MsalRedirectorContainer} data-testid="MsalRedirector"></div>;
};

export default MsalRedirector;
