/*
 * May be obselete
 */

import { IonButton, IonItem, IonLabel, IonModal } from '@ionic/react';
import React, { SetStateAction, useState } from 'react';
import AdminAuditDto from '../../models/AuditModels/AdminAuditDto';
import AuditDetail from '../../models/AuditModels/AuditDetail';
import { DynamicSortWithoutSortPriority } from '../../models/Dtos/OptionsDtos/DynamicSort';
import {
    createNewGeneralOptionsColumnsToShowAndColumnOrderAsArray,
    GeneralOptions,
} from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { DtoType } from '../../models/Global';
import { GeneratedIdType, KEYED_TYPE_IDENTIFIER_PROPERTY_NAME } from '../../utils/KeyIdUtils';
import AuditLogCollapsibleContent from '../AuditLogQueriedTable/AuditLogCollapsibleContent/AuditLogCollapsibleContent';
import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import FilterSortControlModal from '../Theme/QueriedTable/QueriedTableToolbar/FilterSortControlModal/FilterSortControlModal';
import styles from './AdminAuditLog.module.scss';

interface AdminAuditLogProps<T> {
    logItems: GeneratedIdType<AdminAuditDto>[];
    showModal: boolean;
    selectedRecord: GeneratedIdType<AdminAuditDto>;
    handleShowAuditDetails: (record: GeneratedIdType<AdminAuditDto>) => void;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    filterKeyArrayByExcludedColumns: (array: (keyof T)[]) => (keyof T)[];
}

const AdminAuditLog = <T extends object>({
    logItems,
    showModal,
    selectedRecord,
    handleShowAuditDetails,
    setShowModal,
    filterKeyArrayByExcludedColumns,
}: AdminAuditLogProps<T>) => {
    const [focusedEntry, setFocusedEntry] = useState<string | null>(null);
    return (
        <div className={styles.AdminAuditLogContainer} data-testid="AdminAuditLog">
            <h1 className={styles.header}>Audit Log</h1>
            <FilterSortControlModal<AdminAuditDto>
                showFilterModal={false}
                setShowFilterModal={function (value: SetStateAction<boolean>): void {
                    throw new Error('Function not implemented.');
                }}
                handleSetCustomFiltersValueFilters={async () => undefined}
                model={new AdminAuditDto()}
                closeModal={function (): void {
                    throw new Error('Function not implemented.');
                }}
                setSortFilters={function (value: SetStateAction<DynamicSortWithoutSortPriority[]>): void {
                    throw new Error('Function not implemented.');
                }}
                handleCreateUpdateUserFilter={function (userFilterName: string, updating: boolean): void {
                    throw new Error('Function not implemented.');
                }}
                handleCreateUpdateGlobalFilter={function (globalFilterName: string, updating: boolean): void {
                    throw new Error('Function not implemented.');
                }}
                handleDeleteUserFilter={function (): void {
                    throw new Error('Function not implemented.');
                }}
                handleDeleteGlobalFilter={function (): void {
                    throw new Error('Function not implemented.');
                }}
                dtoType={DtoType.Inventory}
                computedCombinedFilters={createNewGeneralOptionsColumnsToShowAndColumnOrderAsArray()}
                unhidableColumns={[]}
                setColumnVisibility={() => undefined}
                fieldsThatAreCustomTypes={{}}
                data={logItems}
                getAsyncSelectGetMethodFromPropertyName={() => undefined}
                filterKeyArrayByExcludedColumns={() => []}
                selectedUserFilterNames={[]}
                selectedGlobalFilterNames={[]}
                computedSelectedGlobalFilters={[]}
                computedSelectedUserFilters={[]}
                customFilters={new GeneralOptions()}
                unselectGlobalFilter={() => true}
                unselectUserFilter={() => true}
                ignoreFilter={() => true}
                unignoreFilter={() => true}
                isFilterIgnored={() => false}
                combinedFilterTargetProperties={[]}
                globalFilterStorage={[]}
                userFilterStorage={[]}
                setIsLoading={() => undefined}
            />
            {logItems.map((logItem) => {
                return (
                    <div className={styles.row} key={logItem[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]}>
                        <div>
                            <IonItem>
                                <IonLabel>Changed Date</IonLabel>
                                <IonLabel>{logItem.changedDate}</IonLabel>
                            </IonItem>
                        </div>
                        <div>
                            <IonButton onClick={() => handleShowAuditDetails(logItem)}>Show Audit Details</IonButton>
                        </div>
                    </div>
                );
            })}
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                {selectedRecord !== undefined && selectedRecord !== null ? (
                    <CollapsibleSection<AuditDetail>
                        records={selectedRecord.details}
                        identifier={'columnName'}
                        labelStrip={(record) => <div>{record.columnName}</div>}
                        // This props for this element will be provided later
                        // Adding generated default props to satisfy typescript
                        content={() => <AuditLogCollapsibleContent record={new AuditDetail()} index={0} />}
                        startingValue={undefined}
                        focusedEntry={focusedEntry}
                        setFocusedEntry={setFocusedEntry}
                    />
                ) : (
                    <div></div>
                )}
            </IonModal>
        </div>
    );
};

export default AdminAuditLog;
