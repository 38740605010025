import { useEffect, useState } from 'react';
import { Layout } from '../Layout';
import { BarGraph } from './reportsCode/components/BarGraph';
import { DateDropdown } from './reportsCode/components/DateDropdown';
import { DummyData } from './reportsCode/components/LineChartDummyData';
import { LineGraph } from './reportsCode/components/LineGraph';
import { MainDropdown } from './reportsCode/components/MainDropdown';
import { PieChartContainer } from './reportsCode/components/PieChartContainer';
import { RecommendationsBox } from './reportsCode/components/RecommendationsBox';
import DateEnum from './reportsCode/models/DateEnum';
import PieChartSavingsModel from './reportsCode/models/PieChartModels/PieChartSavingsModel';
import calculatePercentageSaved from './reportsCode/utils/CalculatePercentageSaved';

import "./reportsCode/index.css"
import "./reportsCode/custom.css"
import axios from 'axios';
import { GraphDataService } from '../../utils/apiServices';
import NewUsedReportsMonthlyReportData from '../../models/Reports/NewUsedReportsMonthlyReportData';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';


export const SalesReportsPage: React.FC = () => {


  const [selectedDate, setSelectedDate] = useState<DateEnum>(DateEnum['Last Month']);

  const [newUsedData, setNewUsedData] = useState<NewUsedReportsMonthlyReportData[]>()

  const [loaderOverlayVisible, setLoaderOverlayVisible] = useState<boolean>(true);
  const [loaderOverlayText, setLoaderOverlayText] = useState<string>("Loading...");

  useEffect(() => {
    const updateNewUsedDataState = async () => {


      try {
        const newUsedDataResponse = await GraphDataService.GetNewUsedReportsData(selectedDate);

        setNewUsedData(newUsedDataResponse.data.model);
      } finally {
        console.log("Setting overlay to false and \"\"")
        setLoaderOverlayText("");
        setLoaderOverlayVisible(false);
      }
    }
    updateNewUsedDataState();
  }, [selectedDate])

  const updateDateRange = (date: DateEnum) => {
    setSelectedDate(date);
    setLoaderOverlayText("Loading...");
    setLoaderOverlayVisible(true);
  }

  //  const barGraphData = new BarGraphData(
  //    {
  //      originalCost: 150,
  //      reducedCost: 80,
  //    },
  //    // Databases category
  //    {
  //      originalCost: 100,
  //      reducedCost: 60,
  //    },
  //    // Storage category
  //    {
  //      originalCost: 90,
  //      reducedCost: 11,
  //    },
  //    // Cleanup category
  //    {
  //      originalCost: 40,
  //      reducedCost: 30,
  //    }
  //  );


  return (
    <>
      <Layout>

        <div className="page-container" style={{ marginTop: '1rem' }}>
          <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
            {/* Section label and main dashboard */}
            <b>
              <h3>Sales Reports</h3>
            </b>
            <div className="dropdown-row">
              {/* Date dashboard */}
              <DateDropdown
                setSelectedDate={updateDateRange}
                selectedDate={selectedDate}

              />
            </div>
          </div>
          <div style={{ borderBottom: '2px solid #e5e7eb' }}>
            <BarGraph barGraphData={newUsedData} />
          </div>
          <div style={{ borderTop: '2px solid #e5e7eb', paddingTop: "2rem" }}>

            <LineGraph lineGraphData={DummyData} />
          </div>
        </div>

      </Layout>

      <LoaderOverlay isVisible={loaderOverlayVisible} progressText={loaderOverlayText} />
    </>
  );
};
