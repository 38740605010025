import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
interface EasyToCopyAllContentTableWrapperProps<T extends object> {
    tableStyles: CSSProperties;
    headers: (keyof T)[];
    data: { [key: string]: string }[] | undefined | null;
    // May need to improve this interface in the future
    identifierProperty: keyof T;
    editLinkOptions?: {
        cellValue: string;
        clickedCallBack: (identifierValue: string) => void;
        columnName: string;
    };
    stylesOfTableHeader?: CSSProperties;
    stylesOfTableData?: CSSProperties;
}
const ifEmptyBreak = (value: unknown) => (value === '' || isNullOrUndefined(value) ? <br /> : value + '');

const EasyToCopyAllContentTableWrapper = <T extends object>({
    tableStyles,
    headers,
    data,
    identifierProperty,
    stylesOfTableHeader,
    stylesOfTableData,
    editLinkOptions,
}: EasyToCopyAllContentTableWrapperProps<T>) => {
    const history = useHistory();

    const tableRef = useRef<HTMLTableElement>(null);

    const handleSelectEvent = useCallback((e: Event) => {
        const selection = document.getSelection();

        if (isNullOrUndefined(selection)) {
            return;
        }

        if (selection.rangeCount <= 0) {
            // No ranges
            // No need to handle it
            return;
        }

        const range = selection.getRangeAt(0);

        const currentDivRef = tableRef.current;

        if (isNullOrUndefined(currentDivRef)) {
            return;
        }

        if (
            !currentDivRef.contains(range.startContainer) &&
            (currentDivRef.contains(range.endContainer) || currentDivRef === range.endContainer)
        ) {
            range.setEndAfter(currentDivRef);

            selection.removeAllRanges();

            selection.addRange(range);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', handleSelectEvent);
        document.addEventListener('touchend', handleSelectEvent);

        return () => {
            document.removeEventListener('mouseup', handleSelectEvent);
            document.removeEventListener('touchend', handleSelectEvent);
        };
    }, [handleSelectEvent]);

    const generateHeaders = () => {
        const normalHeaders = headers.map((header) => (
            <th style={stylesOfTableHeader ?? {}} key={header}>
                {header}
            </th>
        ));
        if (isNullOrUndefined(editLinkOptions)) {
            return normalHeaders;
        }
        normalHeaders.push(
            <th style={stylesOfTableHeader ?? {}} key={'edit-column-header'}>
                {editLinkOptions.columnName}
            </th>
        );
        return normalHeaders;
    };

    const generateTableDataEntries = (dataObject: { [key: string]: string }) => {
        const resultedHeaders = headers.map((key) => (
            <td style={stylesOfTableData ?? {}} key={key}>
                {dataObject[key]}
            </td>
        ));

        if (isNullOrUndefined(editLinkOptions)) {
            return resultedHeaders;
        }

        resultedHeaders.push(
            <td
                style={{ ...stylesOfTableData, color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                    editLinkOptions.clickedCallBack(dataObject[identifierProperty]);
                }}
                key="edit-column-data">
                {editLinkOptions.cellValue}
            </td>
        );

        return resultedHeaders;
    };

    return (
        <table ref={tableRef} style={tableStyles}>
            <thead>
                <tr>{generateHeaders()}</tr>
            </thead>
            <tbody>
                {isNullOrUndefined(data) ? (
                    <tr key="null-data">
                        <td style={stylesOfTableData ?? {}}>No Data...</td>
                    </tr>
                ) : (
                    data.map((dataObject) => (
                        <tr key={dataObject[identifierProperty]}>{generateTableDataEntries(dataObject)}</tr>
                    ))
                )}
            </tbody>
        </table>
    );
};

export default EasyToCopyAllContentTableWrapper;
