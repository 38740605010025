import { IonButton, IonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useObject } from '../../hooks/ObjectHook';
import AdminAuditDto from '../../models/AuditModels/AdminAuditDto';
import GetAuditDto from '../../models/AuditModels/GetAuditDto';
import RecordAuditDto from '../../models/AuditModels/RecordAuditDto';
import { SortOptions } from '../../models/Dtos/OptionsDtos/SortOptions';
import { AuditService } from '../../utils/apiServices';
import { POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE } from '../../utils/AuditLogTableNameUtil';
import { addGeneratedIdIfNoIdOrAddSpecifiedId, GeneratedIdType } from '../../utils/KeyIdUtils';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import AdminAuditLog from '../AdminAuditLog/AdminAuditLog';
import AuditLogQueriedTable from '../AuditLogQueriedTable/AuditLogQueriedTable';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './AuditLogContainer.module.scss';

export const DEFAULT_ITEMS_PER_PAGE = 5;

interface AuditLogContainerProps<T> {
    //Using this record to make the audit log resend after record is updated
    booleanToggleToIndicateToUpdateAuditLog: boolean;
    tableName: POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE;
    primaryKey: string;
    itemsPerPage?: number;
    isAdmin: boolean;
    useModal?: boolean;

    filterKeyArrayByExcludedColumns: (array: (keyof T)[]) => (keyof T)[];
}

/**
 * This is a container component for Admin Audit Log and Audit Log Queried Table
 * Because both of these components would have very similar state and logic rather than
 * duplicate code I have made this container that will handle props and state and then pass
 * them down into the components for usage
 *
 * @param
 * @returns
 */
const AuditLogContainer = <T,>({
    //Using this record to make the audit log resend after record is updated
    booleanToggleToIndicateToUpdateAuditLog,
    tableName,
    primaryKey,
    itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
    isAdmin,
    useModal = false,
    filterKeyArrayByExcludedColumns,
}: AuditLogContainerProps<T>) => {
    /*
     * Current Table Names:
     *
     * Inventory
     * InventoryValidations
     * Delivery
     * SalesOrder
     * UserFilters
     * YardLocation
     * YardMapHistory
     *
     *
     */

    // Initialize GetAuditDto with provided tableName and also items per page
    // Using object destructuring to achieve updating parts of the object
    // CUrrently table name and items per page are being set for the default value
    const { value: getAuditDto } = useObject<GetAuditDto>({
        ...new GetAuditDto(),
        tableName: tableName,
        primaryKey: primaryKey,
        options: {
            ...new SortOptions(),
            itemsPerPage: itemsPerPage,
        },
    });

    // Since this handles the logic for normal record and admin we will have to type this accordingly
    const [logItems, setLogItems] = useState<GeneratedIdType<AdminAuditDto>[] | GeneratedIdType<RecordAuditDto>[]>([]);

    useEffect(() => {
        const handleCallEndpoint = async () => {
            if (isNullOrUndefined(tableName)) {
                alert("Please send message to web developers: 'Table Name is undefined in AuditLogContainer'");
                return;
            }

            const {
                data: { model },
            } = await (isAdmin
                ? AuditService.GetAuditForAdmin(getAuditDto)
                : AuditService.GetAuditForRecord(getAuditDto));
            const castedModel = model as RecordAuditDto[] | AdminAuditDto[];

            /* Since The RecordAuditDto doesn't have a unique identifier
             * I will use a appraoch I discovered to add a key to the objects
             * in order to have a valid "key" parameter to pass into React
             * when I interate over the items.
             * I will have to write a custom type to let typescript know
             * that's what I'm going to do
             *
             * And then when I read the contents of logItems and map them
             * I will need to keep in mind that they wil have an additional property
             * for the 'key' prop
             *
             * Even though the AdminAuditDto does have a unique identifier because
             * RecordAuditDto doesn't I'll let the logic below apply to both DTO cases
             */

            const modelWithAddedKeyProperties = castedModel.map((model) => {
                return addGeneratedIdIfNoIdOrAddSpecifiedId(model);
            }) as GeneratedIdType<RecordAuditDto>[] | GeneratedIdType<AdminAuditDto>[];

            setLogItems(modelWithAddedKeyProperties);
        };
        handleCallEndpoint();
    }, [getAuditDto, isAdmin, tableName]);

    const [showAuditLogContainerModal, setShowAuditLogContainerModal] = useState<boolean>(false);

    const [showAuditDetailsModal, setShowAuditDetailsModal] = useState<boolean>(false);

    const [selectedRecord, setSelectedRecord] = useState<
        GeneratedIdType<RecordAuditDto> | GeneratedIdType<AdminAuditDto> | null
    >(null);

    const handleShowAuditDetails = (record: GeneratedIdType<RecordAuditDto> | GeneratedIdType<AdminAuditDto>) => {
        setSelectedRecord(record);

        setShowAuditDetailsModal(true);
    };

    return (
        <div className={styles.AuditLogContainerContainer} data-testid="AuditLogContainer">
            {isAdmin ? (
                useModal ? (
                    <AdminAuditLog
                        logItems={logItems as GeneratedIdType<AdminAuditDto>[]}
                        showModal={showAuditDetailsModal}
                        selectedRecord={selectedRecord as GeneratedIdType<AdminAuditDto>}
                        handleShowAuditDetails={handleShowAuditDetails}
                        setShowModal={setShowAuditDetailsModal}
                        filterKeyArrayByExcludedColumns={() => []}
                    />
                ) : (
                    <>
                        <IonModal
                            isOpen={showAuditLogContainerModal}
                            onDidDismiss={() => {
                                setShowAuditLogContainerModal(false);
                            }}>
                            <ModalCloseButton setModalHideFunction={() => setShowAuditLogContainerModal(false)} />
                            <AdminAuditLog
                                logItems={logItems as GeneratedIdType<AdminAuditDto>[]}
                                showModal={showAuditDetailsModal}
                                selectedRecord={selectedRecord as GeneratedIdType<AdminAuditDto>}
                                handleShowAuditDetails={handleShowAuditDetails}
                                setShowModal={setShowAuditDetailsModal}
                                filterKeyArrayByExcludedColumns={() => []}
                            />
                        </IonModal>
                        <IonButton
                            onClick={() => {
                                setShowAuditLogContainerModal(true);
                            }}>
                            Show Audit Log
                        </IonButton>
                    </>
                )
            ) : useModal ? (
                <>
                    <IonModal
                        id="Sales-Order-Modal"
                        isOpen={showAuditLogContainerModal}
                        onDidDismiss={() => {
                            setShowAuditLogContainerModal(false);
                        }}>
                        <ModalCloseButton setModalHideFunction={() => setShowAuditLogContainerModal(false)} />
                        <AuditLogQueriedTable
                            logItems={logItems}
                            showAuditDetailsModal={showAuditDetailsModal}
                            selectedRecord={selectedRecord as GeneratedIdType<RecordAuditDto>}
                            handleShowAuditDetails={handleShowAuditDetails}
                            setShowAuditDetailsModal={setShowAuditDetailsModal}
                        />
                    </IonModal>
                    <IonButton
                        onClick={() => {
                            setShowAuditLogContainerModal(true);
                        }}>
                        Show Audit Log
                    </IonButton>
                </>
            ) : (
                <AuditLogQueriedTable
                    logItems={logItems}
                    showAuditDetailsModal={showAuditDetailsModal}
                    selectedRecord={selectedRecord as GeneratedIdType<RecordAuditDto>}
                    handleShowAuditDetails={handleShowAuditDetails}
                    setShowAuditDetailsModal={setShowAuditDetailsModal}
                />
            )}
        </div>
    );
};

export default AuditLogContainer;
