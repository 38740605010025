import { IonButton, IonIcon } from '@ionic/react';
import { copyOutline } from 'ionicons/icons';
import { forwardRef, useRef } from 'react';
import EasyToCopyAllContentTableWrapper from '../../components/EasyToCopyAllContentTableWrapper/EasyToCopyAllContentNoteWrapper';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { YardLocationDetailsWithInventoryDto } from '../../models/Dtos/YardMapDtos/YardLocationDetailsWithInventoryDto';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import { RemoveMethods } from '../../utils/TypeUtils';

interface YardMapCondensedViewProps {
    setIsEditing: (value: boolean) => void;
    yardMap?: RemoveMethods<YardLocationDetailsWithInventoryDto>;
    testStyle?: string;
}

const YardMapCondensedView = forwardRef<HTMLDivElement, YardMapCondensedViewProps>(function YardMapCondensedView(
    { yardMap, testStyle, setIsEditing },
    containerDiv
) {
    // https://dev.to/wojciechmatuszewski/mutable-and-immutable-useref-semantics-with-react-typescript-30c9
    // need to declare the ref this way for typescript to be happy
    const ionRow = useRef<HTMLIonRowElement | null>(null);

    const importantContainerRef = useRef<HTMLDivElement>(null);

    const copyImportantElements = () => {
        const targetRange = new Range();

        const importantContainer = importantContainerRef.current;

        if (isNullOrUndefined(importantContainer)) {
            console.error('Container is null or undefined!!!');
            makeSimpleToast(
                'Internal Error:',
                'We apologize but an unexpected error occured. Please contact the web team.'
            );
            return;
        }
        // targetRange.setStartBefore(importantContainer);
        // targetRange.setEndAfter(importantContainer);

        // const selection = document.getSelection();

        // if (isNullOrUndefined(selection)) {
        //     console.error('Selection is null or undefined!!!');
        //     makeSimpleToast(
        //         'Internal Error:',
        //         'We apologize but an unexpected error occured. Please contact the web team.'
        //     );
        //     return;
        // }
        // selection.removeAllRanges();

        // selection.addRange(targetRange);

        // Copy the selected content to the clipboard using Clipboard API
        navigator.clipboard
            .write([
                new ClipboardItem({
                    'text/html': new Blob([importantContainer.outerHTML], { type: 'text/html' }),
                }),
            ])
            .then(() => {
                console.log('Page copied to clipboard successfully!');

                makeSimpleToast('Success!', "You've copied this page to your clipboard");
            })
            .catch((err) => {
                console.error('Unable to copy page to clipboard:', err);
                makeSimpleToast(
                    'Error while trying to copy styles.',
                    'Page not focused. Please try again and try to keep the page focused for at least a second.'
                );
            });
    };

    const booleanValueProcessor = (value: string | undefined) => {
        if (isNullOrUndefined(value)) {
            return 'False';
        }

        if (value.toLowerCase() === 'true') {
            return 'True';
        }

        if (value.toLowerCase() === 'false') {
            return 'False';
        }

        console.error(`Provided string ${value} isn't true, false, or undef. Returning false`);
        return false;
    };

    const ifEmptyBreak = (value: unknown) => (value === '' || isNullOrUndefined(value) ? <br /> : value + '');

    const commonStyles = {
        containerDiv: {
            flexBasis: '50%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: 'center',
            marginBottom: '.25rem',
        },
        strongLabelContainer: {
            backgroundColor: '#f2f2f2',
        },
        label: {
            height: '1.5rem',
            minHeight: '1.5rem',
            maxHeight: '1.5rem',
            padding: '0.25rem 0.5rem 0.25rem 0.5rem',
            marginRight: '1rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            width: '200px',
            backgroundColor: '#f2f2f2',
            // verticalAlign: 'center',
            display: 'flex',
            alignItems: 'center',
        },
        longerLabel: {
            height: '1.5rem',
            minHeight: '1.5rem',
            maxHeight: '1.5rem',
            padding: '0.25rem 0.5rem 0.25rem 0.5rem',
            marginRight: '1rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            width: '300px',
            backgroundColor: '#f2f2f2',
            // verticalAlign: 'center',
            display: 'flex',
            alignItems: 'center',
        },
        valueWrapperDiv: {
            fontSize: '1rem',
            marginBottom: 0,
            display: 'flex',
            alignItems: 'middle',
            marginLeft: '1rem',
        },
        header: {
            fontSize: '1.25rem',
            // backgroundColor: '#e6e6e6',
            marginBottom: '1.5rem',
            padding: '.5rem 0.25rem',
            // textTransform: 'uppercase',
            // border: '2px solid black',
            width: '100%',
            fontFamily: 'Open Sans',
            // marginLeft: '-0.5rem',
        },
        headerContainer: {
            padding: '0.5rem',
        },
    } as const;

    const tableCommonStyles = {
        identifierColumn: {
            backgroundColor: '#cccccc',
        },

        tableHeader: {
            padding: '1rem',
            backgroundColor: '#eff1f3',
            border: '1px solid #808080',
        },
        tableData: {
            padding: '1rem',
            border: '1px solid #808080',
        },
    } as const;

    const css = `
    @media print {
        .YardMapCondensedViewContainer {
            padding-top: 2.5rem;
            padding-left: 2.5rem;
        }
    }
    
      `;

    return (
        <>
            <div style={{ marginBottom: '1rem' }}>
                <IonButton onClick={copyImportantElements}>
                    <IonIcon icon={copyOutline} />
                    Copy
                </IonButton>
            </div>
            <div ref={importantContainerRef}>
                <div>
                    <div
                        style={{ paddingBottom: '1rem' }}
                        id="testcondensedview"
                        data-testid="YardMapCondensedView"
                        ref={containerDiv}
                        className="YardMapCondensedViewContainer">
                        <div
                            style={{
                                // flexBasis: '50%',
                                // display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                marginBottom: '1rem',
                                // flexGrow: 1,
                                // flexShrink: 1,
                            }}>
                            <style>{css}</style>
                            <div>
                                <div style={commonStyles.containerDiv}>
                                    <strong style={commonStyles.strongLabelContainer}>
                                        <label style={commonStyles.label}>Location Name</label>
                                    </strong>
                                    <div style={commonStyles.valueWrapperDiv}>
                                        {ifEmptyBreak(yardMap?.['Location Name'])}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={commonStyles.containerDiv}>
                                    <strong style={commonStyles.strongLabelContainer}>
                                        <label style={commonStyles.label}>Last Updated</label>
                                    </strong>
                                    <div style={commonStyles.valueWrapperDiv}>
                                        {ifEmptyBreak(yardMap?.['Last Updated'])}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(() => {
                            const headers: (keyof InventoryDto)[] = ['Stock', 'Make', 'Model', 'Year', 'VIN'];

                            return (
                                <div>
                                    <EasyToCopyAllContentTableWrapper<InventoryDto>
                                        tableStyles={{}}
                                        headers={headers}
                                        data={yardMap?.Inventory.map((inventoryDto) => {
                                            const { Stock, Make, Model, Year, VIN } = inventoryDto;

                                            const t = {
                                                Stock: Stock + '',
                                                Make: Make + '',
                                                Model: Model + '',
                                                Year: Year + '',
                                                VIN: VIN + '',
                                            };

                                            return t;
                                        })}
                                        identifierProperty={'Stock'}
                                        editLinkOptions={{
                                            cellValue: 'Edit',
                                            columnName: 'Edit',
                                            clickedCallBack: () => {
                                                setIsEditing(true);
                                            },
                                        }}
                                        stylesOfTableHeader={tableCommonStyles.tableHeader}
                                        stylesOfTableData={tableCommonStyles.tableData}
                                    />
                                </div>
                            );
                        })()}

                        {/* <table>
                    <thead>
                        <tr>
                            <th style={{ ...tableCommonStyles.tableHeader, backgroundColor: '#cccccc' }}>Stock #</th>
                            <th style={tableCommonStyles.tableHeader}>Make</th>
                            <th style={tableCommonStyles.tableHeader}>Year</th>
                            <th style={tableCommonStyles.tableHeader}>VIN</th>
                            <th style={tableCommonStyles.tableHeader}>Delivered Date</th>
                            <th style={tableCommonStyles.tableHeader}>Acct. Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {YardMap?.Deliveries.map((delivery) => {
                            return (
                                <tr key={delivery.Stock}>
                                    <td style={{ ...tableCommonStyles.tableData, backgroundColor: '#cccccc' }}>
                                        {delivery.Stock}
                                    </td>
                                    <td style={tableCommonStyles.tableData}>{delivery.Make}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery.Year}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery.VIN}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery['Delivered Date']}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery['Accounting Date']}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table> */}

                        {/* <div style={commonStyles.headerContainer}>
                    <h1 style={commonStyles.header}>Sale Details</h1>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Deposit</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(YardMap?.['Deposit'])}</div>
                        </div>

                        <ConditionalLinkWrapper
                            to={`/sales-order/edit/${IdentifierType.Stock}/${YardMap?.Stock}`}
                            conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(
                                YardMap?.['Invoice Number']
                            )}
                            className={styles.linkWrapper}
                            maxWidth={true}>
                            <div style={commonStyles.containerDiv}>
                                <label style={commonStyles.label}>Invoice Number</label>

                                <div style={commonStyles.valueWrapperDiv}>
                                    {ifEmptyBreak(YardMap?.['Invoice Number'])}
                                </div>
                            </div>
                        </ConditionalLinkWrapper>
                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Check Number</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(YardMap?.['Check Number'])}</div>
                        </div>

                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Customer</label>
                            </strong>
                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(YardMap?.Customer)}</div>
                        </div>
                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Delivered Date</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>
                                {ifEmptyBreak(
                                    convertBackendDateStringToDateInputDateString(YardMap?.['Delivered Date'])
                                )}
                            </div>
                        </div>

                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Sales Person</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(YardMap?.['Sales Person'])}</div>
                        </div>

                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Temp Tag Expiration</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>
                                {ifEmptyBreak(
                                    convertBackendDateStringToDateInputDateString(YardMap?.['Temp Tag Expiration'])
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                    </div>
                </div>
            </div>
        </>
    );
});

export default YardMapCondensedView;
