import ComputeLineGraphData from '../models/ComputeUpdatedLineGraphData';

export const DummyData: ComputeLineGraphData = {
    data: {
        January: {
            originalCost: 170,
            reducedCost: 40,
            savings: 130,
        },
        February: {
            originalCost: 190,
            reducedCost: 40,
            savings: 150,
        },
        March: {
            originalCost: 200,
            reducedCost: 40,
            savings: 160,
        },
        April: {
            originalCost: 180,
            reducedCost: 50,
            savings: 130,
        },
        May: {
            originalCost: 195,
            reducedCost: 40,
            savings: 155,
        },
        June: {
            originalCost: 185,
            reducedCost: 45,
            savings: 140,
        },
    },
    dateString: 'Jan 01 - June 24 2023',
};
