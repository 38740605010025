import axios, { AxiosRequestConfig } from 'axios';
import { Employee, Stock } from '../models';
import AddsGlobalFilterRowAttributeWrapper from '../models/AddsGlobalFilterRowAttributeWrapper';
import AdminAuditDto from '../models/AuditModels/AdminAuditDto';
import GetAuditDto from '../models/AuditModels/GetAuditDto';
import RecordAuditDto from '../models/AuditModels/RecordAuditDto';
import { ReturnAccountingToDoDto } from '../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { AdvertisementEmailerDto } from '../models/Dtos/AdvertisementEmailerDto';
import { CustomerDto } from '../models/Dtos/CustomerDto';
import { MetricOptionsUpdateDto } from '../models/Dtos/GeneralDtos/MetricOptionsUpdateDto';
import { ReturnListWithOptionsDto } from '../models/Dtos/GeneralDtos/ReturnListWithOptionsDto';
import { ReturnListWithOptionsForExportDto } from '../models/Dtos/GeneralDtos/ReturnListWithOptionsForExportDto';
import { ReturnMessageOnlyDto } from '../models/Dtos/GeneralDtos/ReturnMessageOnlyDto';
import { ReturnModelListWithMessageDto } from '../models/Dtos/GeneralDtos/ReturnModelListWithMessageDto';
import { ReturnModelWithMessageDto } from '../models/Dtos/GeneralDtos/ReturnModelWithMessageDto';
import { StringDto } from '../models/Dtos/GeneralDtos/StringDto';
import { GetAuditLogDto } from '../models/Dtos/GetAuditLogDto';
import { GetGlobalFilterDto, GetGlobalFilterType } from '../models/Dtos/GetGlobalFilterDto';
import { ImportHistoryDto, ImportOverview } from '../models/Dtos/importDto';
import { ImportDto } from '../models/Dtos/InventoryDtos/ImportDto';
import { ImportSheetDto } from '../models/Dtos/InventoryDtos/ImportSheetDto';
import { InventoryDto } from '../models/Dtos/InventoryDtos/InventoryDto';
import { ValidationDto } from '../models/Dtos/InventoryValidationDtos/ValidationDto';
import { ValidationReasonDto } from '../models/Dtos/InventoryValidationDtos/ValidationReasonDto';
import { MetricCardDto } from '../models/Dtos/MetricsDto/MetricCardDto';
import { MetricOptionsDto } from '../models/Dtos/MetricsDto/MetricOptionsDto';
import { GeneralOptions } from '../models/Dtos/OptionsDtos/GeneralOptions';
import { ImportOptions } from '../models/Dtos/OptionsDtos/ImportOptions';
import { PhotoCreationResponseDto } from '../models/Dtos/PhotoCreationResponseDto';
import { DeliveryPhotoDto } from '../models/Dtos/PhotoDtos/DeliveryPhotoDto';
import { UrlPhotoIdWrapper } from '../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
import { RecordOfTransferDetailDto } from '../models/Dtos/RecordOfTransferDtos/RecordOfTransferDetailDto';
import { RecordOfTransferDto } from '../models/Dtos/RecordOfTransferDtos/RecordOfTransferDto';
import { ReturnSalesOrderArchiveDto } from '../models/Dtos/SalesOrderArchiveDtos/ReturnSalesOrderArchiveDto';
import { DeliveryDto } from '../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { TypeInteractionDto } from '../models/Dtos/TypeInteractionDto';
import { UpdateYardLocationDto } from '../models/Dtos/YardMapDtos/UpdateYardLocationDto';
import { YardLocationDetailsWithInventoryDto } from '../models/Dtos/YardMapDtos/YardLocationDetailsWithInventoryDto';
import { YardMapDto } from '../models/Dtos/YardMapDtos/YardMapDto';
import { YardMapHistoryDto } from '../models/Dtos/YardMapDtos/YardMapHistoryDto';
import { YardMapUpdateConfirmationDto } from '../models/Dtos/YardMapDtos/YardMapUpdateConfirmationDto';
import { InventorySubType, RecordType, SlotNumber } from '../models/Global';
import { PhotoScanResult } from '../models/PhotoScanResult';
import NewUsedReportsMonthlyReportData from '../models/Reports/NewUsedReportsMonthlyReportData';
import SearchInput from '../models/SearchInput';
import SearchResult from '../models/SearchResult';
import DateEnum from '../pages/Reports/reportsCode/models/DateEnum';
import { InventoryDisplay } from '../pages/SalesOrder/components/DisplayInventoryOnRecord';
import makeSimpleToast from './MakeSimpleToast';
import { isNullOrUndefined } from './NullOrUndefined';
import { MakeMethodsOptional } from './TypeUtils';

// Frontend endpoints go here.
let root = '';
if (process.env.REACT_APP_IIS_EXPRESS_LOCAL_DEV === 'true') {
  root = 'https://192.168.1.15:44341/';
} else {
  root = process.env.REACT_APP_WEB_API_URL as string;
}
if (isNullOrUndefined(root)) {
  root = 'https://localhost:5001/';
}

// const root = 'https://localhost:5001';
// const root = 'https://192.168.1.15:5000';

const makeUnAuthorizedToast = () => {
  makeSimpleToast('Unauthorized', 'You do not have the required role to perform this action.');
};

const post = <T>(url: string, object?: unknown, options?: AxiosRequestConfig<T>) => {
  // Creates a Promise. Promises can change together.
  const promise = axios.post<T>(url, object, options);
  // const promise = fetch(url, { method: 'POST', body: object as any });

  // Add a catch handler on this promise.
  promise
    .then((result) => {
      if (result.status === 403) {
        makeUnAuthorizedToast();
      }
    })
    .catch((error) => {
      if (error.response.status === 403) {
        makeUnAuthorizedToast();
      }
    });

  // Can still directly return the same promise as my .catch doesn't affect anyone else's use of the promise. That's why they can be chained.
  return promise;
};

const put = <T>(url: string, object?: unknown) => {
  // Creates a Promise. Promises can change together.
  const promise = axios.put<T>(url, object);

  // Add a catch handler on this promise.
  promise
    .then((result) => {
      if (result.status === 403) {
        makeUnAuthorizedToast();
      }
    })
    .catch((result) => {
      if (result.response.status === 403) {
        makeUnAuthorizedToast();
      }
    });

  // Can still directly return the same promise as my .catch doesn't affect anyone else's use of the promise. That's why they can be chained.
  return promise;
};

const deleteHttp = <T>(url: string) => {
  // Creates a Promise. Promises can change together.
  const promise = axios.delete<T>(url);

  // Add a catch handler on this promise.
  promise
    .then((result) => {
      if (result.status === 403) {
        makeUnAuthorizedToast();
      }
    })
    .catch((result) => {
      if (result.response.status === 403) {
        makeUnAuthorizedToast();
      }
    });

  // Can still directly return the same promise as my .catch doesn't affect anyone else's use of the promise. That's why they can be chained.
  return promise;
};

const get = <T>(url: string) => {
  // Creates a Promise. Promises can change together.
  const promise = axios.get<T>(url);

  // Add a catch handler on this promise.
  promise
    .then((result) => {
      if (result.status === 403) {
        makeUnAuthorizedToast();
      }
    })
    .catch((result) => {
      if (result.response.status === 403) {
        makeUnAuthorizedToast();
      }
    });

  // Can still directly return the same promise as my .catch doesn't affect anyone else's use of the promise. That's why they can be chained.
  return promise;
};
export type GlobalFilterNamesData = string[];

export const DataTransferService = {
  transfer: () => post(root + 'api/datatransfer'),
};

export const AuthService = {
  auth: () => get(root + 'api/auth'),
  logout: () => get(root + 'api/aad/logout'),
  getSessionInfo: () => get(root + 'api/auth/getsessioninfo'),
};

export const AuditService = {
  GetTableNames: () => get<string[]>(root + 'api/Audit'),
  GetAuditTrail: (dto: GetAuditLogDto) => post(root + 'api/Audit', dto),
  GetAuditForAdmin: (dto: GetAuditDto) =>
    post<ReturnModelListWithMessageDto<AdminAuditDto>>(root + 'api/audit/GetAuditForAdmin', dto),
  GetAuditForRecord: (dto: GetAuditDto) =>
    post<ReturnModelListWithMessageDto<RecordAuditDto>>(root + 'api/audit/GetAuditForRecord', dto),
};

export const InventoryService = {
  Testing: () => get(root + 'api/Inventory/testing'),
  DeleteInventoryByStock: (stockNumber: string) =>
    deleteHttp<ReturnModelListWithMessageDto<InventoryDto>>(
      root + 'api/Inventory/DeleteInventoryByStock/' + stockNumber
    ),
  GetInventoryDisplay: (stockNumber: string) =>
    get<InventoryDisplay>(root + 'api/Inventory/GetInventoryDisplay/' + stockNumber),
  getInventory: (options: GeneralOptions) => {
    return post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<InventoryDto>>>(
      root + 'api/Inventory/GetInventory',
      options
    );
  },
  createInventory: (inventoryDto: InventoryDto) =>
    post<ReturnModelWithMessageDto<InventoryDto>>(root + 'api/inventory/createinventory', inventoryDto),
  update: (inventoryDto: InventoryDto) => {
    return put<ReturnModelWithMessageDto<InventoryDto>>(root + 'api/inventory', inventoryDto);
  },
  getInventoryDetails: <T>(stockNumber: string) => {
    return get<ReturnModelWithMessageDto<T>>(`${root}api/inventory/getinventorydetails/${stockNumber + ''}`);
  },
  getGlobalFilterNames: async (recordType: RecordType) => {
    const result = await get<ReturnModelListWithMessageDto<GetGlobalFilterDto>>(
      `api/inventory/getglobalfilternames/${recordType}`
    );

    const model = result.data.model as GetGlobalFilterDto[] | string[];

    if (model.length > 0 && typeof model[0] === 'string') {
      // Is string array
      const updatedModelList = model.map(
        (filterName, index) =>
          new GetGlobalFilterDto({
            name: filterName as string,
            globalFilterRow: GetGlobalFilterType.Row2,
            // filterName === 'Vin Visible'
            //     ? GlobalFilterType.Row2
            //     : filterName === 'Vin Hidden'
            //     ? GlobalFilterType.Row1
            //     : index > (model.length - 1) / 2
            //     ? GlobalFilterType.Row2
            //     : GlobalFilterType.Row1,
          })
      );

      return { ...result, data: { ...result.data, model: updatedModelList } };
    }
    if (model.length > 0 && typeof model[0] === 'object') {
      return result;
      // is object array
    }

    return result;
  },

  getGlobalFilters: (recordType: RecordType) => {
    return get<ReturnModelListWithMessageDto<AddsGlobalFilterRowAttributeWrapper<GeneralOptions>>>(
      root + `api/inventory/getglobalfilters/${recordType}`
    );
  },

  getUserFilterNames: (recordType: RecordType) => {
    return get<ReturnModelListWithMessageDto<string>>(root + `api/inventory/getuserfilternames/${recordType}`);
  },

  getUserFilters: (recordType: RecordType) => {
    return get<ReturnModelListWithMessageDto<GeneralOptions>>(root + `api/inventory/getuserfilters/${recordType}`);
  },
  ExportInventory: (options: ImportOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsForExportDto<InventoryDto>>>(
      root + 'api/inventory/exportinventory',
      options
    ),
  GetImportSheet: () => post<ImportSheetDto[]>(root + 'api/inventory/GetImportSheet'),
  ImportInventory: (dto: ImportDto) => post<ReturnMessageOnlyDto>(root + `api/inventory/ImportInventory`, dto),
  ReverseImport: (id: string | number) => get<ReturnMessageOnlyDto>(`${root}api/inventory/ReverseImport/${id + ''}`),
  GetHistory: (options: GeneralOptions) =>
    post<ReturnModelListWithMessageDto<ImportHistoryDto>>(root + 'api/inventory/GetHistory/', options),
  GetImportReport: (id: string | number) =>
    get<ReturnModelWithMessageDto<ImportOverview>>(`${root}api/inventory/GetImportReport/${id + ''}`),
  CustomEndpoint: (options: GeneralOptions) => post(root + 'api/inventory/GetInventoryTest', options),
  Search: (stringDto: StringDto) =>
    post<ReturnModelListWithMessageDto<string[]>>(`${root}api/inventory/SearchBar/`, stringDto),
  SearchByStockForYardLocation: (stringDto: StringDto) =>
    post<ReturnModelListWithMessageDto<string>>(`${root}api/inventory/SearchByStockForYardLocation`, stringDto),
  SearchByStockForDelivery: (stringDto: StringDto) =>
    post<ReturnModelListWithMessageDto<string>>(root + 'api/inventory/SearchByStockForDelivery', stringDto),
  DeleteInventoryUserFilter: (generalOptions: GeneralOptions) =>
    post<ReturnMessageOnlyDto>(root + 'api/inventory/DeleteUserFilter', generalOptions),
  DeleteInventoryGlobalFilter: (generalOptions: GeneralOptions) =>
    post<ReturnMessageOnlyDto>(root + 'api/inventory/DeleteGlobalFilter', generalOptions),
  MainSearch: (searchInputDto: SearchInput) => post<SearchResult>(root + 'api/inventory/MainSearch', searchInputDto),
  DeleteInventory: (inventoryId: string) =>
    deleteHttp<ReturnMessageOnlyDto>(root + `api/inventory/DeleteInventory/${inventoryId}`),
  ResetDummyData: (stockNumber: string, shouldContinue: boolean) =>
    put<ReturnModelWithMessageDto<InventoryDto>>(`api/inventory/resetdummystock/${stockNumber}/${shouldContinue}`),
};

export const InventoryValidationService = {
  createValidation: (createValidationDto: ValidationDto) =>
    post(root + 'api/inventoryvalidation/createvalidation', createValidationDto),
  getInventoryProperties: () => get<(keyof InventoryDto)[]>(root + 'api/inventoryvalidation/getinventoryproperties'),
  update: (updateValidationDto: ValidationDto) =>
    put<ValidationDto>(root + 'api/inventoryvalidation', updateValidationDto),
  getValidationSettings: () => get<ValidationDto[]>(root + 'api/inventoryvalidation/getvalidationsettings'),
  getReasonForValidation: (item: InventoryDto) =>
    post<ReturnModelListWithMessageDto<ValidationReasonDto>>(
      root + 'api/inventoryvalidation/getreasonforvalidation',
      item
    ),
};

export const MetricService = {
  // New metric endpoints
  getMetrics: (recordType: RecordType) => {
    return get<ReturnModelListWithMessageDto<MetricCardDto>>(root + 'api/metrics/getmetrics/' + recordType);
  },
  getSettingsForSlot: (recordType: RecordType, slotNumber: SlotNumber) =>
    get<ReturnModelWithMessageDto<MetricOptionsDto>>(
      root + 'api/metrics/getsettingsforslot/' + recordType + root + '/' + slotNumber
    ),
  updateSlotFromFilter: (metricOptionsDto: MetricOptionsUpdateDto) =>
    put<ReturnMessageOnlyDto>(root + 'api/metrics/UpdateSlotFromFilter', metricOptionsDto),
};

export const NotificationService = {
  snooze: (id: number) => put<ReturnMessageOnlyDto>(root + 'api/notification/snooze/' + id),
  delete: (id: number) => put<ReturnMessageOnlyDto>(root + 'api/notification/delete/' + id),
  get: () => get<ReturnMessageOnlyDto[]>(root + 'api/notification'),
};

// May need to look at later
export const DeliveryAndTransferService = {
  // getDeliveryAndTransferList: (options: SortOptions) =>
  //     post<ReturnDeliveryAndTransfer>(root + 'api/deliveryandtransfer/getpage', options),
  getDeliveryOrTransferDetails: (id: number) => get<DeliveryDto>(root + 'api/deliveryandtransfer/getsingle/' + id),
  createDeliveryOrTransfer: (dto: DeliveryDto) =>
    post<ReturnModelWithMessageDto<DeliveryDto>>(root + 'api/deliveryandtransfer/create', dto),
  updateDeliveryOrTransfer: (dto: DeliveryDto) =>
    post<ReturnModelWithMessageDto<DeliveryDto>>(root + 'api/deliveryandtransfer/update', dto),
  // getAccountingToDo: () => get<ReturnAccountingToDoDto[]>(root + 'api/deliveryandtransfer/getaccountingtodo'),
};

export const DeliveryService = {
  getDeliveryTable: (options: GeneralOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<DeliveryDto>>>(
      root + 'api/salesorder/GetDeliveryTable',
      options
    ),
  updateDeliveryForTable: (item: DeliveryDto) => {
    return put<ReturnMessageOnlyDto>(root + 'api/salesorder/updatedeliveryfortable', item);
  },
  getStocksWithoutDelivery: () =>
    get<ReturnModelListWithMessageDto<string>>(root + 'api/salesorder/GetStocksWithoutDelivery'),
  addMultipleByList: (salesOrderId: string | number, stockNumbers: string[]) =>
    post<ReturnModelListWithMessageDto<DeliveryDto>>(
      `api/salesorder/AddMultipleByList/${salesOrderId + ''}`,
      stockNumbers
    ),
  deliverRecord: (deliveryId: string | number) =>
    get<ReturnModelWithMessageDto<DeliveryDto>>(`api/salesorder/DeliverRecord/${deliveryId + ''}`),
  ExportDelivery: (importOptions: ImportOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsForExportDto<DeliveryDto>>>(
      root + 'api/salesorder/ExportDelivery',
      importOptions
    ),

  removeDelivery: (id: string) => deleteHttp<ReturnMessageOnlyDto>(`${root}api/salesorder/removedelivery/${id}`),
  DeliverAll: (invoiceNumber: string) =>
    put<ReturnModelWithMessageDto<SalesOrderDto>>(root + `api/salesorder/DeliverAll/${invoiceNumber}`),
};

export const SalesOrderArchiveService = {
  getList: (options: GeneralOptions) => post<ReturnSalesOrderArchiveDto[]>(root + 'api/salesorderarchive', options),
  getOne: (id: number) => get<ReturnSalesOrderArchiveDto[]>(root + 'api/salesorderarchive/' + id),
};

export const SalesOrderService = {
  getSalesOrderList: (options: GeneralOptions) => {
    return post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<SalesOrderDto>>>(
      root + 'api/salesorder/getmultiple',
      options
    );
  },
  getSalesOrderDetails: (invoiceNumber: number | string) =>
    get<ReturnModelWithMessageDto<SalesOrderDto>>(root + 'api/salesorder/getsingle/' + invoiceNumber),
  createSalesOrder: (dto: MakeMethodsOptional<SalesOrderDto>) => {
    return post<ReturnModelWithMessageDto<SalesOrderDto>>(root + 'api/salesorder/create', dto);
  },
  updateSalesOrder: (dto: MakeMethodsOptional<SalesOrderDto>) =>
    put<ReturnModelWithMessageDto<SalesOrderDto>>(root + 'api/salesorder/false', dto),
  updateSalesOrderFromQueriedTable: (dto: SalesOrderDto) =>
    put<ReturnModelWithMessageDto<SalesOrderDto>>(root + 'api/salesorder/true', dto),
  exportSalesOrder: (options: ImportOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsForExportDto<SalesOrderDto>>>(
      root + 'api/salesorder/exportsalesorder',
      options
    ),
  getByStockNumber: (stockNumber: string) =>
    get<ReturnModelWithMessageDto<SalesOrderDto>>(`${root}api/salesorder/getbystocknumber/${stockNumber + ''}`),
  addMultipleSalesOrders: (
    salesOrderId: string | number,
    startingStockNumber: string,
    numberToAdd: string | number
  ) =>
    get<ReturnModelListWithMessageDto<DeliveryDto>>(
      `api/salesorder/addmultiple/${salesOrderId + ''}/${startingStockNumber + ''}/${numberToAdd + ''}`
    ),

  complete: (stockNumber: string) => {
    return put<ReturnMessageOnlyDto>(`${root}api/salesorder/complete/${stockNumber + ''}`);
  },

  deactivate: (invoiceNumber: string) => deleteHttp<ReturnMessageOnlyDto>(`${root}api/salesorder/${invoiceNumber}`),

  getUserFilter: (generalOptions: GeneralOptions, recordType: RecordType) =>
    put(`${root}api/salesorder/GetUserFilter/`, recordType),
};

export const AccountingService = {
  updateSalesOrderFromAccountingToDo: (dto: ReturnAccountingToDoDto) =>
    put<ReturnModelWithMessageDto<ReturnAccountingToDoDto>>(
      `${root}api/salesorder/UpdateSalesOrderFromAccountingToDo/`,
      dto
    ),

  completeAccountingToDo: (id: number) => put<ReturnMessageOnlyDto>(root + 'api/deliveryandtransfer/complete/' + id),

  getAccountingToDo: (options: GeneralOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<ReturnAccountingToDoDto>>>(
      root + 'api/salesorder/getaccountingtodo',
      options
    ),
};

export const RecordOfTransferService = {
  getRecordOfTransferList: (options: GeneralOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<RecordOfTransferDto>>>(
      root + 'api/recordoftransfer/getmultiple',
      options
    ),
  getRecordOfTransferDetails: (id: number | string) =>
    get<ReturnModelWithMessageDto<RecordOfTransferDto>>(`${root}api/recordoftransfer/Get/${id + ''}`),
  addUpdateRecordOfTransferDetails: (dto: RecordOfTransferDto) =>
    post<ReturnModelWithMessageDto<RecordOfTransferDto>>(root + 'api/recordoftransfer/AddUpdate', dto),
  updateFromQueriedTable: (dto: RecordOfTransferDetailDto) =>
    put<ReturnMessageOnlyDto>(root + 'api/recordoftransfer/UpdateFromQueriedTable', dto),
};

export const StockService = {
  createNewStocks: (numberOfStocksToCreate: number) =>
    get<ReturnMessageOnlyDto>(`api/stock/${numberOfStocksToCreate}`),
  SearchStock: (stock: string) => get<ReturnModelListWithMessageDto<Stock>>(root + 'api/stock/Search/' + stock),
  // SearchNewForInventory: (stock: string) =>
  //     get<ReturnModelListWithMessageDto<Stock[]>>(root + 'api/stock/SearchNewForInventory/' + stock),
  // SearchUsedForInventory: (stock: string) =>
  //     get<ReturnModelListWithMessageDto<Stock[]>>(root + 'api/stock/SearchUsedForInventory/' + stock),

  SearchForInventory: (stock: string) =>
    get<ReturnModelListWithMessageDto<Stock>>(root + 'api/stock/SearchForInventory/' + stock),
};
export const EmployeeService = {
  // <ReturnModelListWithMessageDto<Employee>>
  SearchByName: (name: StringDto) => {
    return post<ReturnModelListWithMessageDto<Employee>>(root + 'api/employee/', name);
  },
  UpdateEmployeeOnLogin: () => {
    return put<ReturnMessageOnlyDto>(root + 'api/employee');
  },
};
export const CustomerService = {
  // <ReturnModelListWithMessageDto<Customer>>
  SearchByName: (name: StringDto) =>
    post<ReturnModelListWithMessageDto<CustomerDto>>(root + 'api/customer/SearchByName', name),
  CreateCustomer: (customerDto: CustomerDto) =>
    post<ReturnModelWithMessageDto<CustomerDto>>(root + 'api/customer', customerDto),
  UpdateName: (id: string | number, name: string) =>
    put<ReturnMessageOnlyDto>(`${root}api/customer/updatename/${id + ''}`, new StringDto(name)),
  UpdateIdentifier: (id: string | number, identifier: string) =>
    put<ReturnMessageOnlyDto>(`${root}api/customer/UpdateIdentifier/${id + ''}`, new StringDto(identifier)),
  ChangeAndDelete: (typeInteractionDto: TypeInteractionDto) =>
    post<ReturnMessageOnlyDto>(root + 'api/customer/ChangeCustomerAndDelete', typeInteractionDto),
  Delete: (id: string | number) => deleteHttp<ReturnMessageOnlyDto>(`${root}api/customer/${id + ''}`),
};

export const TypeService = {
  GetInventorySubType: (InventoryTypeName: StringDto) =>
    post<InventorySubType>(root + 'api/type/getinventorysubtype/', InventoryTypeName),
  CreatePermanent: (dto: TypeInteractionDto) => post<ReturnMessageOnlyDto>(root + 'api/type/CreatePermanent', dto),
  CreateNonPermanent: (dto: TypeInteractionDto) =>
    post<ReturnMessageOnlyDto>(root + 'api/type/CreateNonPermanent', dto),
  ChangeAndDelete: (dto: TypeInteractionDto) => post<ReturnMessageOnlyDto>(root + 'api/type/ChangeAndDelete', dto),
  ChangeToPermanent: (dto: TypeInteractionDto) =>
    post<ReturnMessageOnlyDto>(root + 'api/type/ChangeToPermanent', dto),
  GetPermanentTypes: (dto: TypeInteractionDto) =>
    post<ReturnModelListWithMessageDto<string>>(root + 'api/type/GetPermanentTypes', dto),
  GetNonPermanentTypes: (dto: TypeInteractionDto) =>
    post<ReturnModelListWithMessageDto<string>>(root + 'api/type/GetNonPermanentTypes', dto),
  GetNameList: (dto: TypeInteractionDto) => {
    return post<string[]>(root + 'api/type/GetNameList', dto);
  },
  UpdateName: (dto: TypeInteractionDto) => post<ReturnMessageOnlyDto>(root + 'api/type/UpdateName', dto),
};

export const YardMapService = {
  getYardLocations: (options: GeneralOptions) => {
    return post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<YardMapDto>>>(root + 'api/yardmap', options);
  },
  getYardLocationAndInventory: (stall: StringDto) =>
    post<ReturnModelWithMessageDto<YardLocationDetailsWithInventoryDto>>(root + 'api/yardmap/GetLocation', stall),
  updateDetails: (updateDto: YardLocationDetailsWithInventoryDto) =>
    put<ReturnModelWithMessageDto<YardLocationDetailsWithInventoryDto>>(
      root + 'api/yardmap/UpdateDetails',
      updateDto
    ),
  updateMap: (updateDto: YardMapDto) =>
    put<ReturnModelWithMessageDto<YardMapDto>>(root + 'api/yardmap/updatemap', updateDto),
  getHistory: (options: GeneralOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsDto<YardMapHistoryDto>>>(
      root + 'api/yardmap/gethistory',
      options
    ),
  ExportYardMap: (importOptions: ImportOptions) =>
    post<ReturnModelWithMessageDto<ReturnListWithOptionsForExportDto<YardMapDto>>>(
      root + 'api/yardmap/ExportYardMap',
      importOptions
    ),
  GetStockNumbers: (yardStallName: string) =>
    post<ReturnModelListWithMessageDto<string>>(root + 'api/yardmap/GetStockNumbers', new StringDto(yardStallName)),
  UpdateWithStockNumbers: (updateYardLocationDto: UpdateYardLocationDto) =>
    put<ReturnModelWithMessageDto<YardMapUpdateConfirmationDto>>(
      root + 'api/yardmap/UpdateWithStockNumbers',
      updateYardLocationDto
    ),
  GetYardLocationNames: () => get<ReturnModelListWithMessageDto<string>>(root + 'api/yardmap/GetLocationNames'),
  ImportYardMap: (yardMapNames: string[]) =>
    post<ReturnMessageOnlyDto>(root + 'api/yardmap/ImportYardMap', yardMapNames),
  SearchByYardStallForYardLocation: (yardStall: StringDto) =>
    post<ReturnModelListWithMessageDto<string>>(root + 'api/inventory/SearchByYardStallForYardLocation', yardStall),
};

export const PhotoService = {
  delete: (photoId: string) => {
    return deleteHttp<ReturnMessageOnlyDto>(encodeURI(root + 'api/photo/' + photoId));
  },
  changePhotoOrderIndexAndUpdateAccordingly: (
    photoId: string,
    targetOrderToChangeTo: number,
    insertBeforeTarget: boolean
  ) => {
    return put<ReturnModelListWithMessageDto<UrlPhotoIdWrapper>>(
      `${root}api/photo/UpdatePhotoOrderAndUpdateAccordingly?photoId=${photoId}&targetOrder=${targetOrderToChangeTo}&insertBeforeTarget=${insertBeforeTarget}`
    );
  },

  createMultiple: (formData: FormData, stock: string, recordType: RecordType, isDriverLicense: boolean) =>
    post<ReturnModelWithMessageDto<PhotoCreationResponseDto>>(
      root +
      `api/photo/createmultiple/?stock=${encodeURIComponent(stock)}&recordType=${RecordType[recordType]
      }&isDriversLicense=${isDriverLicense}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    ),
  get: (singleStockNumberOrStockNumberArray: string | number | string[] | number[], recordType: RecordType) => {
    if (Array.isArray(singleStockNumberOrStockNumberArray)) {
      const stockNumberArray = singleStockNumberOrStockNumberArray as string[] | number[];
      const t = post<ReturnModelListWithMessageDto<DeliveryPhotoDto>>(
        `${root}api/photo/${recordType}`,
        stockNumberArray
      );
      return t;
    }

    const singleStockNumber = singleStockNumberOrStockNumberArray as string | number;

    const t = get<ReturnModelListWithMessageDto<UrlPhotoIdWrapper>>(
      `${root}api/photo/${singleStockNumber + ''}/${recordType}`
    );
    return t;
  },
};

export const CharacterAnalysisService = {
  submitPhoto: (formData: FormData) => {

    const response = post<PhotoScanResult>(root + 'api/PhotoScan', formData)

    return response;
  }
}

export const GraphDataService = {
  GetNewUsedReportsData: (dateEnum: DateEnum) => {
    const response = get<ReturnModelListWithMessageDto<NewUsedReportsMonthlyReportData>>(root + `api/GraphData/GetNewUsedReportsData/${dateEnum}`)
    return response;
  }
}

export const AdvertisementEmailerService = {
  SendEmail: (advertisementEmailerDto: AdvertisementEmailerDto) => {
    const response = post<ReturnMessageOnlyDto>(root + `api/AdvertisementEmailer/SendEmail`, advertisementEmailerDto)

    return response;
  }
}
