import styles from './DeliverySpecializedTableRender.module.scss';

import { useEffect, useMemo, useRef } from 'react';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { AccountingStatus } from '../../models/Global';
import { convertToInputDateFormat } from '../../utils/DateUtils';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import {
    SpecializedTableStandardHeaderStyle,
    SpecializedTableStandardIdentifierColumnStyle,
    SpecializedTableStandardTableDataStyle,
    SpecializedTableStandardTableTagStyle,
} from '../../utils/SpecializedTableStandardStylesAndElements';
import { CH_OFFSET, MIN_WIDTH_INPUT } from '../NewTableApproach/GeneralReference';

// Idea go through each value of the column and determine the biggest width. and then perhaps
// set the inputs to width 100% unless they match the biggest width. I'm not sure.

const generateInputStylesAndUpdateBiggestInputWidth = (valueToLookAt: string, columnName: string, index: number) => {
    let width = 0;
    if (isNotNullNorUndefined(valueToLookAt) && valueToLookAt !== '' && valueToLookAt !== 'null') {
        width = valueToLookAt.length;
    }
    return { width: width + CH_OFFSET + 'ch', minWidth: MIN_WIDTH_INPUT };
};

const savedFunctionsToUpdateData = {
    Stock: (property: keyof DeliveryDto, Stock: string, stock: string, index: number) => (
        <td
            style={
                {
                    ...SpecializedTableStandardTableDataStyle(index),
                    ...SpecializedTableStandardIdentifierColumnStyle,
                }
                // { backgroundColor: 'red' }
            }
            key={`${stock}-${property}`}>
            <p>{Stock}</p>
        </td>
    ),
    'Delivered Date': (property: keyof DeliveryDto, DeliveredDate: string, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{convertToInputDateFormat(DeliveredDate)}</p>
        </td>
    ),
    'Permit Number': (property: keyof DeliveryDto, PermitNumber: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{PermitNumber}</p>
        </td>
    ),
    Make: (property: keyof DeliveryDto, Make: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Make}</p>
        </td>
    ),
    'Inventory Type': (property: keyof DeliveryDto, InventoryType: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{InventoryType}</p>
        </td>
    ),
    Year: (property: keyof DeliveryDto, Year: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Year}</p>
        </td>
    ),
    VIN: (property: keyof DeliveryDto, VIN: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{VIN}</p>
        </td>
    ),

    'Inventory Title': (property: keyof DeliveryDto, InventoryTitle: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{InventoryTitle}</p>
        </td>
    ),
    'Temp Tag Expiration': (property: keyof DeliveryDto, TempTagExpiration: string, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{convertToInputDateFormat(TempTagExpiration)}</p>
        </td>
    ),
    'Carrier Phone': (property: keyof DeliveryDto, CarrierPhone: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{CarrierPhone}</p>
        </td>
    ),
    Address: (property: keyof DeliveryDto, Address: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Address}</p>
        </td>
    ),
    City: (property: keyof DeliveryDto, City: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{City}</p>
        </td>
    ),
    State: (property: keyof DeliveryDto, State: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{State}</p>
        </td>
    ),
    Zip: (property: keyof DeliveryDto, Zip: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Zip}</p>
        </td>
    ),
    'Carrier Name': (property: keyof DeliveryDto, CarrierName: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{CarrierName}</p>
        </td>
    ),
    'Carrier Truck Number': (
        property: keyof DeliveryDto,
        CarrierTruckNumber: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{CarrierTruckNumber}</p>
        </td>
    ),
    'Driver First Name': (
        property: keyof DeliveryDto,
        DriverFirstName: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{DriverFirstName}</p>
        </td>
    ),
    'Driver Last Name': (
        property: keyof DeliveryDto,
        DriverLastName: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{DriverLastName}</p>
        </td>
    ),
    Notes: (property: keyof DeliveryDto, Notes: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Notes}</p>
        </td>
    ),
    'Accounting Status': (property: keyof DeliveryDto, AccountingS: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{AccountingStatus[AccountingS as number]}</p>
        </td>
    ),
    'Accounting Date': (property: keyof DeliveryDto, AccountingDate: string, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{convertToInputDateFormat(AccountingDate)}</p>
        </td>
    ),
    'Receipt Number': (property: keyof DeliveryDto, ReceiptNumber: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{ReceiptNumber}</p>
        </td>
    ),

    'Split Deal - I': (property: keyof DeliveryDto, SplitDeal: string | boolean, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{SplitDeal === true ? 'True' : 'False'}</p>
        </td>
    ),

    'Split Deal - S': (property: keyof DeliveryDto, SplitDeal: string | boolean, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{SplitDeal === true ? 'True' : 'False'}</p>
        </td>
    ),
    'Invoice Number': (property: keyof DeliveryDto, InvoiceNumber: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{InvoiceNumber}</p>
        </td>
    ),
    'Yard Loc': (property: keyof DeliveryDto, YardLoc: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{YardLoc}</p>
        </td>
    ),
    'Yard Loc ID': (property: keyof DeliveryDto, YardLocID: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{YardLocID}</p>
        </td>
    ),
};

interface DeliverySpecalizedTableRenderProps {
    data: DeliveryDto[];
    selectedRecords: string[];
    invisibleContainerDiv: HTMLDivElement;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
}

const DeliverySpecalizedTableRender = ({
    data,
    selectedRecords,
    invisibleContainerDiv,
    computedCombinedFilters,
}: DeliverySpecalizedTableRenderProps) => {
    const filteredData = useMemo(() => {
        return data.filter((dto) => selectedRecords.includes(dto.Stock));
    }, [data, selectedRecords]);

    const tableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        const handleCopy = async () => {
            if (isNullOrUndefined(tableRef.current)) {
                makeSimpleToast('Error while copying styles', 'Table div is undefined or null');
                console.error('Error while copying styles, table div is undefined or null');
                return;
            }

            try {
                // Copy the selected content to the clipboard using Clipboard API
                navigator.clipboard
                    .write([
                        new ClipboardItem({
                            'text/html': new Blob([tableRef.current.outerHTML], { type: 'text/html' }),
                        }),
                    ])

                    .then(() => {
                        console.log('Table copied to clipboard successfully!');

                        makeSimpleToast(
                            'Successfully copied specialized table!',
                            "You've copied the specialized version of the table for email."
                        );
                    })
                    .catch((err) => {
                        console.error('Unable to copy table to clipboard:', err);
                        makeSimpleToast(
                            'Error while trying to copy styles.',
                            'Page not focused. Please try again and try to keep the page focused for at least a second.'
                        );
                    });
            } catch (error) {
                console.error('Clipboard API is not supported in this browser:', error);
            }

            invisibleContainerDiv.remove();
        };
        handleCopy();
    }, [invisibleContainerDiv]);

    return (
        <div className={styles.DeliverySpecializedTableRenderContainer} data-testid="InventorySpecalizedTableRender">
            <table style={SpecializedTableStandardTableTagStyle} ref={tableRef}>
                <thead>
                    <tr>
                        {computedCombinedFilters.orderedColumnsToShow.map((column) => (
                            <th
                                style={{
                                    ...SpecializedTableStandardHeaderStyle,
                                    ...(column.toLowerCase() === 'stock'
                                        ? SpecializedTableStandardIdentifierColumnStyle
                                        : {}),
                                }}
                                key={column}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((dataDto, rowIndex) => {
                        return (
                            <tr key={dataDto.Stock}>
                                {computedCombinedFilters.orderedColumnsToShow.map((columnName) => {
                                    return savedFunctionsToUpdateData[
                                        columnName as keyof typeof savedFunctionsToUpdateData
                                    ](
                                        columnName as keyof DeliveryDto,
                                        // Might need to revisit
                                        dataDto[columnName as keyof DeliveryDto] as any,
                                        dataDto.Stock,
                                        rowIndex
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DeliverySpecalizedTableRender;
