import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { UserGlobalFilterStorageType } from '../../../../models/UserGlobalFIlterStorageType';
import QueriedTableFilterList from '../QueriedTableFilterList/QueriedTableFilterList';
import './QueriedTableFilterSortContainer.module.css';

type QueriedTableFilterSortContainerProps = {
    handleUserFilterButtonClick: (userFilterName: string) => Promise<void>;

    isFilterSelected: ({ name, filterType }: Omit<UserGlobalFilterStorageType, 'globalFilterRow'>) => boolean;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    userFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
};

const QueriedTableFilterSortContainer = ({
    handleUserFilterButtonClick,
    isFilterSelected,
    computedCombinedFilters,
    userFiltersStorage,
}: QueriedTableFilterSortContainerProps) => {
    return (
        <div data-testid="queried-table-filter-sort-container">
            <QueriedTableFilterList
                data-testid="queried-table-toolbar-filters-container"
                handleUserFilterButtonClick={handleUserFilterButtonClick}
                isFilterSelected={isFilterSelected}
                computedCombinedFilters={computedCombinedFilters}
                userFiltersStorage={userFiltersStorage}
            />
        </div>
    );
};
export default QueriedTableFilterSortContainer;
