export enum EmployeeType {
    Admin,
    Finance,
    Sales,
}

export enum PhotoStatus {
    Missing,
    Offline,
    Decals,
    Online,
    Retake,
}

export enum InventoryCondition {
    Used,
    New,
}

export enum DeliveryType {
    Transfer,
    Delivery,
    Unknown,
}

export enum InventoryType {
    Used,
    New,
}

export enum StockType {
    Used,
    New,
}

export enum InventorySubType {
    Tractor,
    Reefer,
    Dry,
    Drop,
    Flatbed,
    Construction,
    Other,
}

export enum SalesOrderStatus {
    Active,
    Closed,
    Archived,
    Unknown,
    Inactive,
}

export enum AccountingStatus {
    InProgress,
    ToDo,
    Completed,
}

export enum FilterType {
    User,
    Global,
    Custom,
}

export enum OperatorType {
    GreaterThan,
    LessThan,
    LessThanOrEqual,
    GreaterThanOrEqual,
    Equal,
}

export enum ReceivedStatus {
    NewlyDelivered,
    UsedTransfered,
    Na,
}

export enum DocumentStatus {
    Paid,
    DocumentsSigned,
    Title,
    Na,
}

export enum DOTRepairStatus {
    Requested,
    Completed,
    Na,
}

export enum DataType {
    String,
    Int,
    Decimal,
    DateTime,
    Bool,
    Enum,
}

export enum NotificationType {
    Inventory,
    SalesOrder,
    Delivery,
}

export enum ValidationTrigger {
    IsMissing,
    IsNotMissing,
}

export enum AuditType {
    None,
    Create,
    Update,
    Delete,
}

export enum InventoryStatus {
    Available,
    Sold,
    Hold,
    Rental,
    Review,
    Service,
    Cancelled,
}

export enum DtoType {
    RecordOfTransfer,
    SalesOrder,
    Delivery,
    Inventory,
    YardMap,
    AccountingTodo,
    AdminAuditDto,
    RecordAuditDto,
}

export enum RecordType {
    RecordOfTransfer,
    SalesOrder,
    Delivery,
    Inventory,
    YardMap,
    AccountingTodo,
}

export enum SlotNumber {
    Archive,
    One,
    Two,
    Three,
    Four,
}
