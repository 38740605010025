import { IonButton, IonCol, IonGrid, IonHeader, IonItem, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { addMinutes } from 'date-fns';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    ComparisonOperatorEnumValueBased,
    getAllComparisonOperatorInfosThatIncludeDataTypes,
    ParameterizedCategory,
} from '../../../models/ComparisonOperator';
import { InventoryDto } from '../../../models/Dtos/InventoryDtos/InventoryDto';
import { ComparisonRange } from '../../../models/Dtos/OptionsDtos/ComparisonRange';
import { DynamicFilter } from '../../../models/Dtos/OptionsDtos/DynamicFilter';
import { DataType } from '../../../models/Global';
import { getDataTypeFromInventoryDtoPropertyName } from '../../../utils/DataTypeMappersByDtoType/InventoryDtoDataTypeMapper';
import { getEnumFromInventoryDtoPropertyName } from '../../../utils/EnumMappersByDtoType/InventoryDtoEnumMapper';
import { getAllStringValuesFromEnum } from '../../../utils/EnumUtil';
import {
    addGeneratedIdIfNoIdOrAddSpecifiedId,
    GeneratedIdType,
    KEYED_TYPE_IDENTIFIER_PROPERTY_NAME,
} from '../../../utils/KeyIdUtils';
import { isNullOrUndefined } from '../../../utils/NullOrUndefined';
//@ts-ignore
import styles from './InventoryFilterManagerHookComp.module.scss';

interface InventoryFilterManagerHookCompProps {
    model: InventoryDto;
    valueFilters: GeneratedIdType<DynamicFilter>[];
    handleSetCustomFiltersValueFilters: (dynamicFilters: GeneratedIdType<DynamicFilter>[]) => void;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}

const InventoryFilterManagerHookComp = ({
    model,
    valueFilters,
    handleSetCustomFiltersValueFilters,
    setShowModal,
}: InventoryFilterManagerHookCompProps) => {
    const propertyNames = Object.keys(model);

    const [internalValueFilters, setInternalValueFilters] = useState<GeneratedIdType<DynamicFilter>[]>(valueFilters);

    const handleCreatingNewFilter = () => {
        // handleSetCustomFiltersValueFilters(addGeneratedIdIfNoIdOrAddSpecifiedId(new DynamicFilter()));
        setInternalValueFilters((oldValueFilters) => {
            return [...oldValueFilters, addGeneratedIdIfNoIdOrAddSpecifiedId(new DynamicFilter())];
        });
    };

    const handleUpdateFilter = (dynamicFilter: GeneratedIdType<DynamicFilter>) => {
        // handleSetCustomFiltersValueFilters(dynamicFilter);

        setInternalValueFilters((oldValueFilters) => {
            return oldValueFilters.map((dFilter) => {
                if (
                    dFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] !== dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]
                ) {
                    return dFilter;
                }

                return dynamicFilter;
            });
        });
    };

    const handleUpdateFilterProprety = (
        dynamicFilter: GeneratedIdType<DynamicFilter>,
        propertyName: keyof DynamicFilter,
        value: any
    ) => {
        setInternalValueFilters((oldValueFilters) => {
            return oldValueFilters.map((dFilter) => {
                if (
                    dFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] !== dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]
                ) {
                    return dFilter;
                }

                return { ...dFilter, [propertyName]: value };
            });
        });
    };

    const generateSelectForOperator = (dynamicFilter: GeneratedIdType<DynamicFilter>) => {
        const isPreExisting = dynamicFilter.propertyName !== '' && dynamicFilter.propertyName != null;

        const dataType = getDataTypeFromInventoryDtoPropertyName(dynamicFilter.propertyName as keyof InventoryDto);

        if (isNullOrUndefined(dataType)) {
            console.warn(`Couldn't find data type for property: ${dynamicFilter.propertyName}`);
            return <></>;
        }

        const comparisonOperatorWrapper = ComparisonOperatorEnumValueBased[dynamicFilter.comparisonOperator];

        const parameterizedCategory = comparisonOperatorWrapper.parametizedCategory;

        return (
            <>
                <select
                    disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                    onChange={(e) => {
                        handleUpdateFilterProprety(dynamicFilter, 'comparisonOperator', +e.target.value); // Converts string to number. string should be a number indicating ValueOperator
                    }}
                    value={dynamicFilter.comparisonOperator}>
                    {getAllComparisonOperatorInfosThatIncludeDataTypes(dataType).map(({ enumValue, displayName }) => {
                        return (
                            <React.Fragment key={enumValue}>
                                <option key={enumValue} value={enumValue}>
                                    {displayName}
                                </option>
                            </React.Fragment>
                        );
                    })}
                    {/* <option value={ComparisonOperatorClassWrapper.getEnumNumber(ComparisonOperatorClassWrapper.Equal)}>
                    =
                </option>{' '}
                // Equal to // Equal to
                <option value={ComparisonOperatorClassWrapper.getEnumNumber(ComparisonOperatorClassWrapper.NotEqual)}>
                    !=
                </option>{' '}
                // Equal to // Equal to
                <option
                    value={ComparisonOperatorClassWrapper.getEnumNumber(ComparisonOperatorClassWrapper.GreaterThan)}>
                    &#62;
                </option>{' '}
                // Greater than // Greater than
                <option
                    value={ComparisonOperatorClassWrapper.getEnumNumber(
                        ComparisonOperatorClassWrapper.GreaterThanOrEqual
                    )}>
                    &#8805;
                </option>{' '}
                // Greater than // Greater than or equal
                <option value={ComparisonOperatorClassWrapper.getEnumNumber(ComparisonOperatorClassWrapper.LessThan)}>
                    &#60;
                </option>{' '}
                // Less than // Less than
                <option
                    value={ComparisonOperatorClassWrapper.getEnumNumber(
                        ComparisonOperatorClassWrapper.LessThanOrEqual
                    )}>
                    &#8804;
                </option>{' '}
                // Less than or // Less than or equal */}
                </select>
                {parameterizedCategory === ParameterizedCategory.Value && dataType === DataType.Bool ? (
                    <select
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={
                            dynamicFilter.value === undefined
                                ? undefined
                                : typeof dynamicFilter.value === 'boolean'
                                ? new Boolean(dynamicFilter.value).toString()
                                : dynamicFilter.value
                        }
                        defaultValue={undefined}
                        onChange={(e) => {
                            handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                        }}>
                        <option value={undefined}></option>
                        <option value={new Boolean(true).toString()}>True</option>
                        <option value={new Boolean(false).toString()}>False</option>
                    </select>
                ) : parameterizedCategory === ParameterizedCategory.Value && dataType === DataType.Enum ? (
                    <select
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        //@ts-ignore
                        value={dynamicFilter.value == undefined ? '' : dynamicFilter.value}
                        defaultValue={undefined}
                        onChange={(e) => {
                            handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                        }}>
                        <option value={undefined}></option>
                        {getAllStringValuesFromEnum(
                            getEnumFromInventoryDtoPropertyName(dynamicFilter.propertyName as keyof InventoryDto)
                        ).map((enumValue, index) => {
                            return (
                                <option key={enumValue} value={enumValue}>
                                    {enumValue}
                                </option>
                            );
                        })}
                    </select>
                ) : (
                    parameterizedCategory === ParameterizedCategory.Value &&
                    typeof dynamicFilter.value !== 'boolean' && (
                        <input
                            disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                            value={
                                dynamicFilter.value == undefined
                                    ? ''
                                    : dataType === DataType.DateTime
                                    ? moment(dynamicFilter.value).format('YYYY-MM-DD')
                                    : dynamicFilter.value
                            }
                            placeholder="Test"
                            type={dataType !== DataType.DateTime ? 'text' : 'date'}
                            data-parameter="value"
                            onChange={(e) => {
                                if (dataType === DataType.DateTime) {
                                    const newDate = new Date(e.target.value);
                                    if (newDate.toString() === 'Invalid Date') {
                                        handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                                        return;
                                    }
                                    const convertedDate = addMinutes(newDate, newDate.getTimezoneOffset());

                                    try {
                                        const convertedISO = convertedDate.toISOString();

                                        const toSet = convertedISO.substring(0, convertedISO.length - 14);

                                        if (toSet.charAt(0) === '0') {
                                            handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                                        } else {
                                            handleUpdateFilterProprety(dynamicFilter, 'value', toSet);
                                        }
                                    } catch {
                                        handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                                    }
                                } else {
                                    handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                                }

                                // const year = addLeadingZeros(date.getFullYear(), 4);
                                // const month = addLeadingZeros(date.getMonth() + 1, 2); // Adding to month because it starts at 0. Additinally, the 2 means to make it take up 2 characters
                                // const day = addLeadingZeros(date.getDate(), 2);

                                // const result = `${year}-${month}-${day}`;

                                // setFilterValue(result);
                            }}
                        />
                    )
                )}

                {parameterizedCategory === ParameterizedCategory.StringValue && (
                    <input
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.value == undefined ? '' : (dynamicFilter.value as string)}
                        type={'text'}
                        data-parameter="string-value"
                        onChange={(e) => {
                            handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                        }}
                    />
                )}

                {parameterizedCategory === ParameterizedCategory.DateTypeAndValue && (
                    <input
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.value == undefined ? '' : (dynamicFilter.value as string)}
                        type="number"
                        data-parameter="dateTypeAndValue-value"
                        onChange={(e) => {
                            handleUpdateFilterProprety(dynamicFilter, 'value', e.target.value);
                        }}
                    />
                )}

                {(parameterizedCategory === ParameterizedCategory.DateType ||
                    parameterizedCategory === ParameterizedCategory.DateTypeAndValue) && (
                    <select
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.comparisonRange == undefined ? '' : dynamicFilter.comparisonRange}
                        placeholder="Date Time Type"
                        data-parameter="dateType"
                        defaultValue={undefined}
                        onChange={(e) => {
                            handleUpdateFilterProprety(dynamicFilter, 'comparisonRange', +e.target.value);
                        }}>
                        <option value={undefined}></option>
                        <option value={ComparisonRange.Year}>Year</option>
                        <option value={ComparisonRange.RollingYear}>Rolling Year</option>
                        <option value={ComparisonRange.Quarter}>Quarter</option>
                        <option value={ComparisonRange.Month}>Month</option>
                        <option value={ComparisonRange.Week}>Week</option>
                        <option value={ComparisonRange.Day}>Day</option>
                    </select>
                )}
            </>
        );
    };

    const handleUpdateValueFilters = () => {
        // setShowFilterModal(false);
        handleSetCustomFiltersValueFilters(internalValueFilters);
        setShowModal(false);
    };

    // https://github.com/facebook/react/issues/24391#issuecomment-1107796997
    // Needs to show up after all hooks
    // if (!showFilterModal) {
    //     // Makes it so nothing renders if show filter modal is false
    //     return <></>;
    // }

    const handleManualFilterDelete = (dynamicFilter: GeneratedIdType<DynamicFilter>) => {
        setInternalValueFilters((oldValueFilters) => {
            return oldValueFilters.filter((dFitler) => {
                return (
                    dFitler[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] !== dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]
                );
            });
        });
    };

    return (
        <div className={styles.FilterManagerHookCompContainer} data-testid="FilterManagerHookComp">
            <div className={styles.container}>
                <div>
                    <IonGrid>
                        <>
                            <IonRow>
                                <IonCol>
                                    <IonHeader>Add Filters</IonHeader>
                                </IonCol>
                            </IonRow>

                            {internalValueFilters.map((dynamicFilter) => {
                                return (
                                    <IonRow key={dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]}>
                                        <IonCol>
                                            <IonItem>
                                                <IonSelect
                                                    value={dynamicFilter.propertyName}
                                                    onIonChange={(e) => {
                                                        const updatedDataType = getDataTypeFromInventoryDtoPropertyName(
                                                            e.detail.value
                                                        );
                                                        handleUpdateFilterProprety(
                                                            dynamicFilter,
                                                            'propertyName',
                                                            e.detail.value
                                                        );
                                                        handleUpdateFilterProprety(
                                                            dynamicFilter,
                                                            'comparisonType',
                                                            updatedDataType
                                                        );
                                                    }}>
                                                    {propertyNames.map((shownColumn) => (
                                                        <IonSelectOption key={shownColumn} value={shownColumn}>
                                                            {shownColumn}
                                                        </IonSelectOption>
                                                    ))}
                                                </IonSelect>
                                            </IonItem>
                                        </IonCol>
                                        {/* <IonCol>
                                        <select>
                                            {ComparisonOperatorClassWrapper.ComparisonOperatorArray.filter(
                                                (comparisonOperator) => {
                                                    const matchingDataTypes =
                                                        getTargetedDataTypeFromPropertyName(comparisonOperator);

                                                    return matchingDataTypes.includes(dataType);
                                                }
                                            ).map((comparisonOperator: string) => {
                                                const enumNumber =
                                                    ComparisonOperatorClassWrapper.getEnumNumber(comparisonOperator);
                                                return (
                                                    <React.Fragment key={enumNumber}>
                                                        <option key={enumNumber} value={enumNumber}>
                                                            {comparisonOperator}
                                                        </option>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </select>
                                    </IonCol> */}
                                        <IonCol>{generateSelectForOperator(dynamicFilter)}</IonCol>
                                        <IonCol>
                                            <IonButton
                                                color={'danger'}
                                                onClick={() => handleManualFilterDelete(dynamicFilter)}>
                                                Delete
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                );
                            })}
                            <IonRow>
                                <IonCol>
                                    <IonButton>
                                        <IonButton onClick={handleCreatingNewFilter}>Add Filter</IonButton>
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    </IonGrid>
                </div>
                <div>
                    <IonRow>
                        <IonCol>
                            <IonButton onClick={() => handleUpdateValueFilters()}>Submit</IonButton>
                        </IonCol>
                    </IonRow>
                </div>
            </div>
        </div>
    );
};

export default InventoryFilterManagerHookComp;
