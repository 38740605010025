// https://medium.com/with-orus/the-5-commandments-of-clean-error-handling-in-typescript-93a9cbdf1af5

export type Jsonable =
    | string
    | number
    | boolean
    | null
    | undefined
    | readonly Jsonable[]
    | { readonly [key: string]: Jsonable }
    | { toJSON(): Jsonable };

export enum ErrorType {
    Base,
    ObjectRelatedError,
}

class UTGenericErrorBase extends Error {
    public errorTypeDiscriminator: ErrorType = ErrorType.Base;

    constructor(message: string, errorTypeDiscriminator: ErrorType = ErrorType.Base) {
        super(message);

        this.errorTypeDiscriminator = errorTypeDiscriminator;
    }
}

// This is a list of pre-defined error messages. This is encouraged to have the error messages consistent and then provide information through error object

export const PRE_DEFINED_ERROR_MESSAGES_DICT = {
    containsObjAlreadyWhileAdding: "Object already in list. Couldn't add!",
    objDoesntExistWithId: 'Object not found with id!',
    passedInObjectNeedsAnId: 'Passed in object needs an id!',
    parameterIsNull: 'Passed parameter is null!',
    parameterIsUndefined: 'Passed parameter is null!',
    mustPassAnObjectParameter: 'Must pass an object parameter!',
    mustPassAnObjectOrArrayParameter: 'Must pass an object or array parameter!',
    itemInArrayDoesNotHaveId: 'Item in array does not have id',
    oneOfProvidedParametersIdsWasNotFoundInList: 'One of provided parameters ids was not found in list',
    mayNotPassNonObjectNonStringValues: 'May not pass non object non string values.',
    itemIsAlreadyAsHighAsPossible: 'Item is already as high as possible',
    itemIsAlreadyAsLowAsPossible: 'Item is already as low as possible',
} as const;

export default UTGenericErrorBase;
