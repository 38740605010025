import { IonButton, IonIcon } from '@ionic/react';
import { copyOutline } from 'ionicons/icons';
import { forwardRef, useRef } from 'react';
import EasyToCopyAllContentNoteWrapper from '../../components/EasyToCopyAllContentNoteWrapper/EasyToCopyAllContentNoteWrapper';
import EasyToCopyAllContentTableWrapper from '../../components/EasyToCopyAllContentTableWrapper/EasyToCopyAllContentNoteWrapper';
import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { SalesOrderStatus } from '../../models/Global';
import { convertToInputDateFormat } from '../../utils/DateUtils';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';

interface SalesOrderCondensedViewProps {
    setFocusedEntry: React.Dispatch<React.SetStateAction<string | null>>;
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;

    salesOrder?: SalesOrderDto;
    testStyle: string;
}

const SalesOrderCondensedView = forwardRef<HTMLDivElement, SalesOrderCondensedViewProps>(
    function SalesOrderCondensedView({ salesOrder, testStyle, setFocusedEntry, setIsEditing }, containerDiv) {
        // https://dev.to/wojciechmatuszewski/mutable-and-immutable-useref-semantics-with-react-typescript-30c9
        // need to declare the ref this way for typescript to be happy
        const ionRow = useRef<HTMLIonRowElement | null>(null);

        const importantContainerRef = useRef<HTMLDivElement>(null);

        const copyImportantElements = () => {
            const targetRange = new Range();

            const importantContainer = importantContainerRef.current;

            if (isNullOrUndefined(importantContainer)) {
                console.error('Container is null or undefined!!!');
                makeSimpleToast(
                    'Internal Error:',
                    'We apologize but an unexpected error occured. Please contact the web team.'
                );
                return;
            }
            // targetRange.setStartBefore(importantContainer);
            // targetRange.setEndAfter(importantContainer);

            // const selection = document.getSelection();

            // if (isNullOrUndefined(selection)) {
            //     console.error('Selection is null or undefined!!!');
            //     makeSimpleToast(
            //         'Internal Error:',
            //         'We apologize but an unexpected error occured. Please contact the web team.'
            //     );
            //     return;
            // }
            // selection.removeAllRanges();

            // selection.addRange(targetRange);

            // Copy the selected content to the clipboard using Clipboard API
            navigator.clipboard
                .write([
                    new ClipboardItem({
                        'text/html': new Blob([importantContainer.outerHTML], { type: 'text/html' }),
                    }),
                ])
                .then(() => {
                    console.log('Page copied to clipboard successfully!');

                    makeSimpleToast('Success!', "You've copied this page to your clipboard");
                })
                .catch((err) => {
                    console.error('Unable to copy page to clipboard:', err);
                    makeSimpleToast(
                        'Error while trying to copy styles.',
                        'Page not focused. Please try again and try to keep the page focused for at least a second.'
                    );
                });
        };

        const booleanValueProcessor = (value: string | undefined) => {
            if (isNullOrUndefined(value)) {
                return 'False';
            }

            if (value.toLowerCase() === 'true') {
                return 'True';
            }

            if (value.toLowerCase() === 'false') {
                return 'False';
            }

            console.error(`Provided string ${value} isn't true, false, or undef. Returning false`);
            return false;
        };

        const ifEmptyBreak = (value: unknown) => (value === '' || isNullOrUndefined(value) ? <br /> : value + '');

        const commonStyles = {
            containerDiv: {
                flexBasis: '50%',
                display: 'flex',
                justifyContent: 'flex-start',
                alignContent: 'center',
                marginBottom: '.25rem',
            },
            strongLabelContainer: {
                backgroundColor: '#f2f2f2',
            },
            label: {
                height: '1.5rem',
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                padding: '0.25rem 0.5rem 0.25rem 0.5rem',
                marginRight: '1rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                width: '200px',
                backgroundColor: '#f2f2f2',
                // verticalAlign: 'center',
                display: 'flex',
                alignItems: 'center',
            },
            longerLabel: {
                height: '1.5rem',
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                padding: '0.25rem 0.5rem 0.25rem 0.5rem',
                marginRight: '1rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                width: '300px',
                backgroundColor: '#f2f2f2',
                // verticalAlign: 'center',
                display: 'flex',
                alignItems: 'center',
            },
            valueWrapperDiv: {
                fontSize: '1rem',
                marginBottom: 0,
                display: 'flex',
                alignItems: 'middle',
                marginLeft: '1rem',
            },
            header: {
                fontSize: '1.25rem',
                // backgroundColor: '#e6e6e6',
                marginBottom: '1.5rem',
                padding: '.5rem 0.25rem',
                // textTransform: 'uppercase',
                // border: '2px solid black',
                width: '100%',
                fontFamily: 'Open Sans',
                // marginLeft: '-0.5rem',
            },
            headerContainer: {
                padding: '0.5rem',
            },
        } as const;

        const tableCommonStyles = {
            identifierColumn: {
                backgroundColor: '#cccccc',
            },

            tableHeader: {
                padding: '1rem',
                backgroundColor: '#eff1f3',
                border: '1px solid #808080',
            },
            tableData: {
                padding: '1rem',
                border: '1px solid #808080',
            },
        } as const;

        // paddingBottom: '1rem',
        // marginLeft: '2.5rem',
        // marginTop: '2.5rem',
        // border: '1px solid black',

        const css = `
        @media print {
            .SalesOrderCondensedViewContainer {
                paddingLeft: 20rem;
                paddingTop: 20rem;
                border: 2.5rem solid white;

            }
        }

          `;

        return (
            <>
                <div style={{ marginBottom: '1rem' }}>
                    <IonButton onClick={copyImportantElements}>
                        <IonIcon icon={copyOutline} />
                        Copy
                    </IonButton>
                </div>
                <div ref={importantContainerRef}>
                    <div>
                        {/*    <style>{css}</style> */}
                        <div
                            // style={{
                            //     paddingBottom: '1rem',
                            //     marginLeft: '2.5rem',
                            //     marginTop: '2.5rem',
                            //     border: '1px solid black',

                            //     // border: '10px solid black',
                            //     //  marginLeft: '2.5rem', marginTop: '2.5rem'
                            // }}
                            id="testcondensedview"
                            data-testid="SalesOrderCondensedView"
                            className="SalesOrderCondensedViewContainer"
                            ref={containerDiv}>
                            <style>{css}</style>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div
                                    style={{
                                        flexBasis: '50%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        flexGrow: 1,
                                        flexShrink: 1,
                                    }}>
                                    <div>
                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Invoice</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Invoice Number'])}
                                            </div>
                                        </div>

                                        <div
                                            style={commonStyles.containerDiv}
                                            // style={{ backgroundColor: 'red' }}
                                        >
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Status</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(
                                                    salesOrder == undefined ? '' : SalesOrderStatus[salesOrder.Status]
                                                )}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <>
                                                <strong style={commonStyles.strongLabelContainer}>
                                                    <label style={commonStyles.label}>Misc Tracking</label>
                                                </strong>

                                                <div style={commonStyles.valueWrapperDiv}>
                                                    {ifEmptyBreak(
                                                        salesOrder === undefined ? '' : salesOrder['Misc Tracking']
                                                    )}
                                                </div>
                                            </>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Check Number</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Check Number'])}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Deposit</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {`$${ifEmptyBreak(salesOrder?.['Deposit'])}`}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Split Deal</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Split Deal'] === true ? 'True' : 'False')}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Sales Person</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Sales Person'])}
                                            </div>
                                        </div>
                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Customer Name</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Customer Name'])}
                                            </div>
                                        </div>
                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>Customer #</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Customer #'])}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.label}>All Delivered</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(
                                                    salesOrder?.['All Delivered'] === true ? 'True' : 'False'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        flexBasis: '50%',
                                        flexShrink: '1',
                                        flexGrow: '1',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                    }}
                                    // size="6"
                                >
                                    <div>
                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>Lienholder</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.Lienholder)}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>Ordered Date</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(convertToInputDateFormat(salesOrder?.['Ordered Date']))}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>Docs Signed</label>
                                            </strong>
                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(convertToInputDateFormat(salesOrder?.['Docs Signed']))}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>
                                                    Proof of Payment Received
                                                </label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(
                                                    convertToInputDateFormat(salesOrder?.['Proof Of Payment Received'])
                                                )}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>Title Work Completed</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(
                                                    convertToInputDateFormat(salesOrder?.['Title Work Completed'])
                                                )}
                                            </div>
                                        </div>

                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>NAPP</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.NAPP === true ? 'True' : 'False')}
                                            </div>
                                        </div>
                                        <div style={commonStyles.containerDiv}>
                                            <strong style={commonStyles.strongLabelContainer}>
                                                <label style={commonStyles.longerLabel}>Delivered Count</label>
                                            </strong>

                                            <div style={commonStyles.valueWrapperDiv}>
                                                {ifEmptyBreak(salesOrder?.['Delivered Count'])}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...commonStyles.headerContainer, marginTop: '1rem', marginBottom: '1rem' }}>
                                <h1 style={{ backgroundColor: '#999999' }}>Sales Order Notes</h1>
                                <EasyToCopyAllContentNoteWrapper
                                    divStyles={commonStyles.valueWrapperDiv}
                                    value={salesOrder?.Notes}
                                />
                            </div>

                            {(() => {
                                const headers: (keyof DeliveryDto)[] = [
                                    'Stock',
                                    'Make',
                                    'Year',
                                    'VIN',
                                    'Spec',
                                    'Delivered Date',
                                    'Accounting Date',
                                ];

                                return (
                                    <div>
                                        <EasyToCopyAllContentTableWrapper<DeliveryDto>
                                            tableStyles={{}}
                                            headers={headers}
                                            data={salesOrder?.Deliveries.map((deliveryDto) => {
                                                const {
                                                    Stock,
                                                    Make,
                                                    Year,
                                                    VIN,
                                                    Spec,
                                                    'Delivered Date': DeliveredDate,
                                                    'Accounting Date': AccountingDate,
                                                } = deliveryDto;

                                                const t = {
                                                    Stock: Stock + '',
                                                    Make: Make + '',
                                                    Year: Year + '',
                                                    VIN: VIN + '',
                                                    Spec: Spec + '',
                                                    'Delivered Date': convertToInputDateFormat(DeliveredDate) + '',
                                                    'Accounting Date': convertToInputDateFormat(AccountingDate) + '',
                                                };

                                                return t;
                                            })}
                                            editLinkOptions={{
                                                cellValue: 'Edit',
                                                clickedCallBack: (identifierValue: string) => {
                                                    setFocusedEntry(identifierValue);
                                                    setIsEditing(true);
                                                },
                                                columnName: 'Edit',
                                            }}
                                            identifierProperty={'Stock'}
                                            stylesOfTableHeader={tableCommonStyles.tableHeader}
                                            stylesOfTableData={tableCommonStyles.tableData}
                                        />
                                    </div>
                                );
                            })()}

                            {/* <table>
                    <thead>
                        <tr>
                            <th style={{ ...tableCommonStyles.tableHeader, backgroundColor: '#cccccc' }}>Stock #</th>
                            <th style={tableCommonStyles.tableHeader}>Make</th>
                            <th style={tableCommonStyles.tableHeader}>Year</th>
                            <th style={tableCommonStyles.tableHeader}>VIN</th>
                            <th style={tableCommonStyles.tableHeader}>Delivered Date</th>
                            <th style={tableCommonStyles.tableHeader}>Acct. Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesOrder?.Deliveries.map((delivery) => {
                            return (
                                <tr key={delivery.Stock}>
                                    <td style={{ ...tableCommonStyles.tableData, backgroundColor: '#cccccc' }}>
                                        {delivery.Stock}
                                    </td>
                                    <td style={tableCommonStyles.tableData}>{delivery.Make}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery.Year}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery.VIN}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery['Delivered Date']}</td>
                                    <td style={tableCommonStyles.tableData}>{delivery['Accounting Date']}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table> */}

                            {/* <div style={commonStyles.headerContainer}>
                    <h1 style={commonStyles.header}>Sale Details</h1>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Deposit</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(salesOrder?.['Deposit'])}</div>
                        </div>

                        <ConditionalLinkWrapper
                            to={`/sales-order/edit/${IdentifierType.Stock}/${salesOrder?.Stock}`}
                            conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(
                                salesOrder?.['Invoice Number']
                            )}
                            className={styles.linkWrapper}
                            maxWidth={true}>
                            <div style={commonStyles.containerDiv}>
                                <label style={commonStyles.label}>Invoice Number</label>

                                <div style={commonStyles.valueWrapperDiv}>
                                    {ifEmptyBreak(salesOrder?.['Invoice Number'])}
                                </div>
                            </div>
                        </ConditionalLinkWrapper>
                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Check Number</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(salesOrder?.['Check Number'])}</div>
                        </div>

                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Customer</label>
                            </strong>
                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(salesOrder?.Customer)}</div>
                        </div>
                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Delivered Date</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>
                                {ifEmptyBreak(
                                    convertDefaultDate(salesOrder?.['Delivered Date'])
                                )}
                            </div>
                        </div>

                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Sales Person</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>{ifEmptyBreak(salesOrder?.['Sales Person'])}</div>
                        </div>

                        <div style={commonStyles.containerDiv}>
                            <strong style={commonStyles.strongLabelContainer}>
                                <label style={commonStyles.label}>Temp Tag Expiration</label>
                            </strong>

                            <div style={commonStyles.valueWrapperDiv}>
                                {ifEmptyBreak(
                                    convertDefaultDate(salesOrder?.['Temp Tag Expiration'])
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

export default SalesOrderCondensedView;
