import { GeneralOptions } from '../models/Dtos/OptionsDtos/GeneralOptions';
import { FilterType } from '../models/Global';

export const getUnfilteredGeneralOptions = (filterType: FilterType, filterName: string): GeneralOptions => {
    return {
        filterOptions: [],
        sortOptions: {
            pageNumber: 1,
            itemsPerPage: 50,
            sortBy: [],
        },
        columnsToShow: '',
        columnOrder: '',
        // If user has hidden columns will ignore hidden columns from preset filters. If user doesn't have hidden columns will use the preset filter columns
        filterName: filterName,
        isFilterUpdate: false,
        filterType: filterType,
        searchTerm: '',
    };
};
