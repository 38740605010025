import { IonIcon } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import styles from './InfoIonIconToolTip.module.scss';

const DEFAULT_WIDTH = '1.85rem';
const DEFAULT_HEIGHT = '1.85rem';
const DEFAULT_PLACE = 'top';

interface InfoIonIconToolTipProps {
    htmlContent: string;
    place?: 'top' | 'right' | 'left' | 'bottom';
    width?: string;
    height?: string;
    align?: 'left' | 'right' | 'center';
    id: string;
    SHOW_DELAY_IN_MILISECONDS: number;
    HIDE_DELAY_IN_MILISECONDS: number;
}

/**
 * This component is not to be used by itself. The proper way to use it
 * is to use the GeneralToolTip component which will render this component
 * @param param0
 * @returns
 */
const InfoIonIconToolTip = ({
    htmlContent,
    width,
    height,
    place,
    id,
    HIDE_DELAY_IN_MILISECONDS,
    SHOW_DELAY_IN_MILISECONDS,
    align,
}: InfoIonIconToolTipProps) => {
    return (
        <div
            data-testid="InfoIonIconToolTip"
            className={styles.InfoIonIconToolTip}
            style={{ justifyContent: isNullOrUndefined(align) ? 'center' : align }}>
            <IonIcon
                id={id}
                icon={informationCircleOutline}
                style={{
                    width: width !== '' && isNotNullNorUndefined(width) ? width : DEFAULT_WIDTH,
                    height: height !== '' && isNotNullNorUndefined(height) ? height : DEFAULT_HEIGHT,
                }}
            />
            <Tooltip
                anchorId={id}
                html={htmlContent}
                place={isNotNullNorUndefined(place) ? place : DEFAULT_PLACE}
                delayShow={SHOW_DELAY_IN_MILISECONDS}
                delayHide={HIDE_DELAY_IN_MILISECONDS}
            />
        </div>
    );
};

export default InfoIonIconToolTip;
