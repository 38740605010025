import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  alertCircleOutline,
  cameraOutline,
  chevronBackOutline,
  createOutline,
  eyeOutline,
} from "ionicons/icons";
import cloneDeep from "lodash.clonedeep";
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { GroupBase, StylesConfig } from "react-select";
import AsyncSelect from "react-select/async";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import AuditLogContainer from "../../components/AuditLogContainer/AuditLogContainer";
import ConditionalLinkWrapper from "../../components/ConditionalLinkWrapper/ConditionalLinkWrapper";
import LoaderOverlay from "../../components/LoaderOverlay/LoaderOverlay";
import PhotoGallery from "../../components/PhotoGallery/PhotoGallery";
import {
  canUserAccessAdminSecurityWithGivenRoles,
  canUserAccessSalesSecurityWithGivenRoles,
  RoleContext,
} from "../../components/RoleContext/RoleContextComp";
import { getFactoryFunctionForDBManagedType } from "../../components/Search/SearchGeneralType";
import { GetYardLocationNames } from "../../components/Search/SearchYardStallByName";
import {
  ID_PARAMETER_NAME,
  ID_TYPE_PARAMETER_NAME,
  UseParamsObject,
} from "../../components/Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer";
import {
  LocationType,
  WrappedLocationType,
} from "../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import { useObject } from "../../hooks/ObjectHook";
import useCamera from "../../hooks/useCamera";
import { InventoryDto } from "../../models/Dtos/InventoryDtos/InventoryDto";
import { UrlPhotoIdWrapper } from "../../models/Dtos/PhotoDtos/UrlPhotoIdWrapper";
import {
  InventoryCondition,
  InventoryStatus,
  InventorySubType,
  PhotoStatus,
  RecordType,
} from "../../models/Global";
import {
  CharacterAnalysisService,
  InventoryService,
  InventoryValidationService,
} from "../../utils/apiServices";
import { filterInventoryKeyArrayByExcludedColumns } from "../../utils/ColumnsToShowFilterByDtoType/InventoryColumnsToShowFilter";
import { handleUploadPhoto } from "../../utils/handleUploadPhoto";
import { IdentifierType } from "../../utils/identifierType";
import makeSimpleToast from "../../utils/MakeSimpleToast";
import { minMax } from "../../utils/MinMaxUtil";
import {
  isNotNullNorUndefined,
  isNotNullNorUndefinedNorEmptyString,
  isNullOrUndefined,
} from "../../utils/NullOrUndefined";
import PageNameGenerator from "../../utils/PageNameGenerator";
import { DbManagedTypes } from "../AdminPanel/components/TypeManager";
import InvCondensedView from "../InvCondensedView/InvCondensedView";
import { Layout } from "../Layout";
import styles from "./InventoryPage.module.scss";

export const InventoryPage: React.FC = () => {
  // For Types0
  // const [inventoryTypesUpdated, setInventoryTypesUpdated] = useState<boolean>(false);
  // const [wheelTypesUpdated, setSheelTypesUpdated] = useState<boolean>(false);
  // const [suspensionTypesUpdated, setSuspensionTypesUpdated] = useState<boolean>(false);
  // const [suspensionStyleTypesUpdated, setSuspensionStyleTypesUpdated] = useState<boolean>(false);
  // const [floorTypesUpdated, setFloorTypesUpdated] = useState<boolean>(false);
  // const [rearDoorTypesUpdated, setRearDoorTypesUpdated] = useState<boolean>(false);
  // const [heightTypesUpdated, setHeightTypesUpdated] = useState<boolean>(false);
  // const [lengthTypesUpdated, setLengthTypesUpdated] = useState<boolean>(false);
  // const [widthTypesUpdated, setWidthTypesUpdated] = useState<boolean>(false);
  // const [wheelSizeTypesUpdated, setWheelSizeTypesUpdated] = useState<boolean>(false);
  // const [makeTypesUpdated, setMakeTypesUpdated] = useState<boolean>(false);
  // const [modelTypesUpdated, setModelTypesUpdated] = useState<boolean>(false);
  // const [reeferMakeTypesUpdated, setReeferMakeTypesUpdated] = useState<boolean>(false);
  // const [reeferModelTypesUpdated, setReeferModelTypesUpdated] = useState<boolean>(false);

  const history = useHistory();

  const locationObj = useLocation<LocationType | undefined>();
  const {
    [ID_PARAMETER_NAME]: identifierNumber,
    [ID_TYPE_PARAMETER_NAME]: identifierType,
  } = useParams<UseParamsObject>();

  const {
    value: inventory,
    setValue: setInventory,
    bindIonSelectProperty: bindIonInventorySelect,
    bindIonInputProperty: bindIonInventory,
    bindReactAsyncSelect: bindInventoryReactAsyncSelect,
    // bindIonInputPropertyDecimals: bindIonInventoryDecimal,
    bindIonPropertyCheck: bindIonInventoryCheck,
    bindIonPropertyDate: bindIonInventoryDate,
    bindIonPropertyCurrency: bindIonInventoryCurrency,
    updateProperty: updateInventoryProperty,
  } = useObject<InventoryDto>(new InventoryDto());

  const roles = useContext(RoleContext);

  const INVENTORY_DETAIL_PAGE_TITLE = PageNameGenerator("Inventory Detail");

  useEffect(() => {
    if (document.title !== INVENTORY_DETAIL_PAGE_TITLE)
      document.title = INVENTORY_DETAIL_PAGE_TITLE;
  }, []);

  const [isEditing, setIsEditing] = useState<boolean>(
    identifierNumber == undefined
  );

  const { getInventoryContainerPhotoUrlsWithSasTokens } = useCamera(
    RecordType.Inventory
  );

  useEffect(() => {
    handleDeterminingIsCreating();
  }, []);

  const { pathname, state: locationState } = useLocation<WrappedLocationType>();
  const isUpdating = pathname.toLowerCase().includes("edit");

  const [invalidStock, setInvalidStock] = useState<boolean>(false);

  const handleDeterminingIsCreating = () => {
    if (!isEditing) {
      return;
    }

    if (identifierNumber == undefined || identifierNumber == "") {
      if (
        typeof identifierNumber === "string" &&
        isNaN(parseInt(identifierNumber))
      ) {
        throw new Error(
          "IDENTIFIER VALUE IS NOT A VALID NUMBER IN SALES ORDERS!"
        );
      }
    }

    switch (identifierType) {
    }

    inventory.Stock = identifierNumber;

    setInventory(inventory);
  };

  const formRef = useRef<HTMLFormElement>(null);

  const [showAddTrailer, setShowAddTrailer] = useState<boolean>(false);

  const [passedSelectedStockNumberUE, setPassedSelectedStockNumberUE] =
    useState<boolean>();

  const [hasCreatedObj, setHasCreatedObj] = useState<boolean>(false);

  const uploadPhotosRef = useRef<HTMLInputElement | null>(null);

  // State for types

  // const updateMethods: (() => Promise<void>)[] = [];

  // //Inventory Type
  // const [inventoryTypes, setInventoryTypes] = useState<string[]>([]);

  // const updateInventoryTypes = async () => {
  //     if (inventoryTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.InventoryType));
  //     setInventoryTypes(response.data);
  //     setInventoryTypesUpdated(true);
  // };
  // updateMethods.push(updateInventoryTypes);

  // // Wheel Type

  // const [wheelTypes, setWheelTypes] = useState<string[]>([]);

  // const updateWheelTypes = async () => {
  //     if (wheelTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.WheelType));
  //     setWheelTypes(response.data);
  //     setSheelTypesUpdated(true);
  // };
  // updateMethods.push(updateWheelTypes);

  // // Suspension Type

  // const [suspensionTypes, setSuspensionTypes] = useState<string[]>([]);

  // const updateSuspensionTypes = async () => {
  //     if (suspensionTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.SuspensionType));
  //     setSuspensionTypes(response.data);
  //     setSuspensionTypesUpdated(true);
  // };
  // updateMethods.push(updateSuspensionTypes);

  // // Suspension Style

  // const [suspensionStyleTypes, setSuspensionStyleTypes] = useState<string[]>([]);

  // const updateSuspensionStyleTypes = async () => {
  //     if (suspensionStyleTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.SuspensionStyle));
  //     setSuspensionStyleTypes(response.data);
  //     setSuspensionStyleTypesUpdated(true);
  // };
  // updateMethods.push(updateSuspensionStyleTypes);

  // // Floor Type

  // const [floorTypes, setFloorTypes] = useState<string[]>([]);

  // const updateFloorTypes = async () => {
  //     if (floorTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.FloorType));
  //     setFloorTypes(response.data);
  //     setFloorTypesUpdated(true);
  // };
  // updateMethods.push(updateFloorTypes);

  // // Rear Door Type

  // const [rearDoorTypes, setRearDoorTypes] = useState<string[]>([]);

  // const updateRearDoorTypes = async () => {
  //     if (rearDoorTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.RearDoorType));
  //     setRearDoorTypes(response.data);
  //     setRearDoorTypesUpdated(true);
  // };
  // updateMethods.push(updateRearDoorTypes);

  // // height Types
  // const [heightTypes, setHeightTypes] = useState<string[]>([]);
  // const updateHeightTypes = async () => {
  //     if (heightTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Height));
  //     setHeightTypes(response.data);
  //     setHeightTypesUpdated(true);
  // };
  // updateMethods.push(updateHeightTypes);

  // // length types
  // const [lengthTypes, setLengthTypes] = useState<string[]>([]);
  // const updateLengthTypes = async () => {
  //     if (lengthTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Length));
  //     setLengthTypes(response.data);
  //     setLengthTypesUpdated(true);
  // };
  // updateMethods.push(updateLengthTypes);

  // // width types
  // const [widthTypes, setWidthTypes] = useState<string[]>([]);
  // const updateWidthTypes = async () => {
  //     if (widthTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Width));
  //     setWidthTypes(response.data);
  //     setWidthTypesUpdated(true);
  // };
  // updateMethods.push(updateWidthTypes);

  // // width types
  // const [wheelSizeTypes, setWheelSizeTypes] = useState<string[]>([]);
  // const updateWheelSizeTypes = async () => {
  //     if (wheelSizeTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.WheelSize));
  //     setWheelSizeTypes(response.data);
  //     setWheelSizeTypesUpdated(true);
  // };
  // updateMethods.push(updateWheelSizeTypes);

  // // make types
  // const [makeTypes, setMakeTypes] = useState<string[]>([]);
  // const updateMakeTypes = async () => {
  //     if (makeTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Make));
  //     setMakeTypes(response.data);
  //     setMakeTypesUpdated(true);
  // };
  // updateMethods.push(updateMakeTypes);

  // // model types
  // const [modelTypes, setModelTypes] = useState<string[]>([]);
  // const updateModelTypes = async () => {
  //     if (modelTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Model));
  //     setModelTypes(response.data);
  //     setModelTypesUpdated(true);
  // };
  // updateMethods.push(updateModelTypes);

  // // Reefer make types
  // const [reeferMakeTypes, setReeferMakeTypes] = useState<string[]>([]);
  // const updateReeferMakeTypes = async () => {
  //     if (reeferMakeTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.ReeferMake));
  //     setReeferMakeTypes(response.data);
  //     setReeferMakeTypesUpdated(true);
  // };
  // updateMethods.push(updateReeferMakeTypes);

  // // Reefer model types
  // const [reeferModelTypes, setReeferModelTypes] = useState<string[]>([]);
  // const updateReeferModelTypes = async () => {
  //     if (reeferModelTypesUpdated) {
  //         // Doesn't call again if already set
  //         return;
  //     }

  //     const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.ReeferModel));
  //     setReeferModelTypes(response.data);
  //     setReeferModelTypesUpdated(true);
  // };
  // updateMethods.push(updateReeferModelTypes);

  // useEffect(() => {
  //     updateMethods.forEach((updateMethod) => {
  //         updateMethod();
  //     });
  // }, []);

  /* To Do List:
  handle Response DTO - Shawn
  Set required tags - Should be completed
  Set validation messages - Should be completed
  Test get, create, update
  Set edit permissions based off logged in user role using disabled={}
  CSS - Shawn
  Finish Stock Number logic - Notes in UseEffect below
  */

  // useEffect(() => {
  //     if (!passedSelectedStockNumberUE) {
  //         setPassedSelectedStockNumberUE(true);
  //         return;
  //     }
  //     // verify it is only sending back whole stock numbers
  //     // Then call database to fetch inventory and add another DeliveryDto --- may not need to call backend, ignore until after testing.
  //     if (selectedStockNumberOption != undefined) {
  //         const previousDeliveries = inventory['Deliveries'];

  //         const newDelivery = new DeliveryDto();
  //         newDelivery.Stock = +selectedStockNumberOption.value;

  //         updateInventoryProperty('Deliveries')([...previousDeliveries, newDelivery]);
  //         setShowAddTrailer(false);
  //     } else {
  //         alert('Unhandled Error in UseEffect for SelectedStockNumber: ' + { selectedStockNumberOption });
  //     }

  //     // Need to also setShowAddTrailer(false);
  // }, [selectedStockNumberOption]);

  const getRecord = useCallback(() => {
    if (identifierNumber == undefined) {
      return;
    }

    InventoryService.getInventoryDetails<InventoryDto>(identifierNumber)
      .then(({ data }) => {
        if (data.isSuccessful) setInventory(data.model);
      })
      .catch((err) => console.error(err));
  }, [identifierNumber, setInventory]);

  useEffect(() => {
    // getting Sales Order if edit/view mode
    if (identifierNumber != undefined) getRecord();
  }, [getRecord, identifierNumber]);

  useEffect(() => {
    console.log("Test-ReRender");
  });

  useEffect(() => {
    getRecord();
  }, [getRecord]);

  const [
    booleanToggleToIndicateToUpdateAuditLog,
    setBooleanToggleToIndicateToUpdateAuditLog,
  ] = useState<boolean>(false);

  const updateRecord = async (
    modifiedInventory: any,
    pressingCtrl: boolean
  ) => {
    // Making it any because it's a modified version of Sales Order and Idk how to make this currently in typescript
    try {
      const data = (await InventoryService.update(modifiedInventory)).data;

      // if (data.isSuccessful) setInventory(data);
      // else handleMessagePlaceholder();

      if (data.isSuccessful) {
        // Undoing update audit log and inventory state. Instead will redirect to Inventory Queried Table Page
        // setInventory(data.model);
        // setBooleanToggleToIndicateToUpdateAuditLog(!booleanToggleToIndicateToUpdateAuditLog);

        if (!pressingCtrl) {
          // Not pressing Ctrl

          const pathname = "/inventory";

          const state = {};

          history.push(pathname, state);
          return;
        }
        // Pressing Ctrl

        setInventory(data.model);

        setIsEditing(false);
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const createRecord = (modifiedInventory: any, pressingCtrl: boolean) => {
    InventoryService.createInventory(modifiedInventory)
      .then(({ data }) => {
        if (data.isSuccessful) {
          if (!pressingCtrl) {
            // Not pressing Ctrl
            const pathname = "/inventory";

            const state = {};

            history.push(pathname, state);
            return;
          }

          // Pressing Ctrl

          setInventory(data.model);

          setIsEditing(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const keyDownState = useRef<{ [key: string]: boolean | undefined }>({});

  useEffect(() => {
    const onKeyUpHandler = (e: KeyboardEvent) => {
      const keyDownStateCopy = cloneDeep(keyDownState.current);
      keyDownStateCopy[e.key] = false;
      keyDownState.current = keyDownStateCopy;
    };
    const onKeyDownHandler = (e: KeyboardEvent) => {
      const keyDownStateCopy = cloneDeep(keyDownState.current);
      keyDownStateCopy[e.key] = true;
      keyDownState.current = keyDownStateCopy;
    };

    window.addEventListener("keyup", onKeyUpHandler);

    window.addEventListener("keydown", onKeyDownHandler);

    return () => {
      window.removeEventListener("keyup", onKeyUpHandler);
      window.removeEventListener("keydown", onKeyDownHandler);
    };
  }, []);

  const handleSubmit = (e: FormEvent): void => {
    const pressingCtrl = keyDownState.current?.["Control" as string] ?? false;
    e.preventDefault();

    // const modifiedInventory = convertOptionTypesToStrings();

    if (!formRef.current?.checkValidity()) {
      formRef.current?.setAttribute("class", "was-validated");
    } else {
      if (identifierNumber != undefined || hasCreatedObj)
        updateRecord(inventory, pressingCtrl);
      else {
        createRecord(inventory, pressingCtrl);
        setHasCreatedObj(true);
      }
    }
  };

  // Stocks: string; ***************************** Mopre work needed
  // NAPP: string; ********* Review

  // const handleSubmission = async () => {
  //     inventory['Sales Person'] = (inventory['Sales Person'] as unknown as OptionsType).label;
  //     inventory['Customer Name'] = (inventory['Customer Name'] as unknown as OptionsType).label;

  //     const response = await InventoryService.updateInventory(inventory);

  //     const data = response.data;
  //     // const model = data.model;
  // };

  const handleDebug = () => {
    if (inventory == undefined) return <></>;

    const entries = Object.entries(inventory);

    const finalArray = entries.map(([key, value]) => {
      return (
        <div key={key}>
          <h1>{key}</h1>
          <h2>{value}</h2>
        </div>
      );
    });

    return finalArray;
  };

  const isStockSearchDisabled = (): boolean => {
    // add check later
    const isAdmin = true;

    if (isAdmin) return false;

    if (+inventory.Stock > 0) return true;
    // if (inventory['Inventory Type'] == InventoryType.New) return false;
    // if (inventory['Inventory Type'] == InventoryType.Used) return false;

    return false;
  };

  const handleShowReasonForMarkedForValidation = async () => {
    const response = await InventoryValidationService.getReasonForValidation(
      inventory
    );
    const modelListWithMessageDto = response.data;
    const validationDtoList = modelListWithMessageDto.model;
  };

  /* onst handleInventoryPictureButtonClick = async (stock: string) => {
      try {
          const urls = await takeInventoryPhotoAndSaveToBackend(stock);

          const photosOrNewArray = photos ?? [];

          setPhotos((photos) => [...photosOrNewArray, urls]);
      } catch (error) {
          console.error(error);
      }
  }; */

  const changeToEdit = () => {
    if (!canUserAccessSalesSecurityWithGivenRoles(roles)) {
      alert("You must be of the sales or admin role to edit Inventories!");
      return;
    }
    setIsEditing(true);
  };

  const changeToView = () => {
    setIsEditing(false);
  };

  const [
    passedFirstHandleCheckingStateUE,
    setPassedFirstHandleCheckingStateUE,
  ] = useState<boolean>();

  useEffect(() => {
    if (!passedFirstHandleCheckingStateUE) {
      setPassedFirstHandleCheckingStateUE(true);
      return;
    }

    const handleCheckingState = async () => {
      if (+inventory.Stock === 0 || Number.isNaN(inventory.Stock)) {
        return;
      }
      const response = await InventoryService.getInventoryDetails(
        inventory.Stock
      );
      if (response.data.isSuccessful) {
        // setInvalidStock(true);
        setInvalidStock(true);
        return;
      }
      setInvalidStock(false);
    };

    if (identifierNumber != undefined) {
      // Don't need to check stock if updating instead of creating
      return;
    }

    handleCheckingState();
  }, [identifierNumber, inventory.Stock, passedFirstHandleCheckingStateUE]);

  const handleRedirectToYardStall = (yardStall: string) => {
    history.push({
      pathname: `/yard-location/edit/${IdentifierType.YardStall}/${yardStall}`,
    });
  };

  const handleRedirectToSalesOrder = (invoiceNumber: string) => {
    history.push({
      pathname: `/sales-order/edit/${IdentifierType.Invoice}/${invoiceNumber}`,
    });
  };

  const [photos, setPhotos] = useState<UrlPhotoIdWrapper[]>([]);

  // Gathers Photos
  const [shouldGatherPhotos, setShouldGatherPhotos] = useState<boolean>(true);

  const handleUpdatingPhotosFromPhotoGallery = (
    photos: UrlPhotoIdWrapper[],
    stock: string
  ) => {
    setPhotos(photos);
  };

  const handleGatheringPhotos = () => {
    if (identifierNumber == undefined || identifierNumber == "") {
      return;
    }
    setShouldGatherPhotos(false);

    getInventoryContainerPhotoUrlsWithSasTokens(identifierNumber)
      .then((urlPhotoIdWrappers) => {
        setPhotos(urlPhotoIdWrappers);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (shouldGatherPhotos) {
    handleGatheringPhotos();
  }

  const removingUrlFromState = useCallback(
    (url: string) => {
      if (isNullOrUndefined(photos)) {
        return;
      }

      const indexOfUrl = photos.findIndex(
        (wrapper) => wrapper.url.toLowerCase() === url.toLowerCase()
      );

      if (indexOfUrl !== -1) {
        // found it

        const toBeSpliced = cloneDeep(photos);

        toBeSpliced.splice(indexOfUrl, 1);

        setPhotos(toBeSpliced);
      }
    },
    [photos]
  );

  /// Print related state
  // const [needToPrint, setNeedToPrint] = useState<boolean>(false);
  // const setNeedToPrintToFalse = () => {
  //     setNeedToPrint(false);
  // };

  const invCondensedViewPrintComponent = useRef(null);

  const loadedInvCondesnedComp = useRef<boolean>(false);

  useEffect(() => {
    if (isNullOrUndefined(invCondensedViewPrintComponent.current)) {
      return;
    }

    loadedInvCondesnedComp.current = true;
  }, []);

  const getReactToPrintContent = useCallback(() => {
    return invCondensedViewPrintComponent.current;
  }, []);

  /* Handle the case where the user is a person who doesn't have permission to create a inventory */

  const handlePrint = useReactToPrint({
    content: getReactToPrintContent,
  });

  const [loading, setLoading] = useState<boolean>(false);

  if (
    isEditing &&
    !isUpdating &&
    !canUserAccessSalesSecurityWithGivenRoles(roles)
  ) {
    return (
      <Layout>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Create Inventory</IonTitle>
          </IonToolbar>
        </IonHeader>

        <h1 className={styles.noPermissionHeader}>
          You do not have permission to create an new Inventory
        </h1>
        <p>If you believe this is in error please contact UtilityTrailer</p>
      </Layout>
    );
  }

  const handleResetDummyData = async () => {
    const reallyWantsToResetData = window.confirm(
      "Do you really want to reset the data?"
    );

    if (!reallyWantsToResetData) {
      return;
    }
    if (isNullOrUndefined(inventory?.Stock)) {
      alert("There is no current inventory to reset!");
      return;
    }

    const response = await InventoryService.ResetDummyData(
      inventory.Stock,
      false
    );

    const messageForUser = response.data.messageForUser.toLowerCase();

    if (messageForUser.includes("wish to continue")) {
      // has delivery relationship

      // Need to ask user if they want to continue.
      const doesUserWantToDeleteInventoryAndRecordOfDelivery = window.confirm(
        `Inventory with Stock Number ${inventory.Stock} has a Delivery. If you wish to continue the Delivery will also be deleted.\nDo you wish to continue?`
      );

      if (!doesUserWantToDeleteInventoryAndRecordOfDelivery) {
        // User Doesn't Want to Delete Inventory
        alert("You have canceled the operation to reset this dummy stock.");
      }

      const responseFromCallingWithShouldContinueTrue =
        await InventoryService.ResetDummyData(inventory.Stock, true);

      if (responseFromCallingWithShouldContinueTrue.data.isSuccessful) {
        alert(
          `You have succesfully reset dummy inventory stock ${inventory.Stock} and deleted the delivery associated with it!`
        );
      }
    } else if (messageForUser.includes("success")) {
      // doesn't have any delivery relationships.
      // Don't do anything will be handled by axios interceptor
      // Succeeded
    } else if (messageForUser.includes("does not exist")) {
      // Stock doesn't exist
      // Don't do anything will be handled by axios interceptor
    }
  };

  const asyncSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
    control: (styles, state) => ({
      ...styles,

      "backgroundColor": "#F2F2F2",
      "&:hover": {
        backgroundColor: "#e6e6e6",
      },
    }),
    option: (styles, state) => ({
      ...styles,
      "backgroundColor": state.isFocused ? "#e6e6e6" : "#F2F2F2",
      "color": "black",

      "&:hover": {
        backgroundColor: "#e6e6e6",
      },
    }),
  };

  let decrementingZIndex = 50;

  const handleDelete = async (
    e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const confirmed = window.confirm(
      `Are you sure you want to delete Inventory ${inventory.Stock} and remove associations?`
    );

    if (!confirmed) {
      return;
    }

    setLoading(true);

    const result = await InventoryService.DeleteInventoryByStock(
      inventory.Stock
    );
    setLoading(false);

    if (result.data.isSuccessful) {
      // Go to Inventory Page
      history.push("/inventory");
    }
  };

  const handleVinPhotoUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = (e.target as HTMLInputElement)?.files;

    if (isNullOrUndefined(files)) {
      makeSimpleToast("Error:", "Please provide a file!");

      return;
    }

    const file = files[0];

    const formData = new FormData();

    formData.append("file", file);

    try {
      const result = await CharacterAnalysisService.submitPhoto(formData);

      console.log("Result");
      console.log(result);

      if (result.status === 400) {
        makeSimpleToast("Error!", "Error occured while attemping to scan vin!");
        return;
      }

      const makesFunction = getFactoryFunctionForDBManagedType(
        DbManagedTypes.Make
      );
      let makes: { label: string; value: string }[];

      if (isNotNullNorUndefined(makesFunction)) {
        makes = await makesFunction("");

        console.log("Makes");
        console.log(makes);
      }

      const modelsFunction = getFactoryFunctionForDBManagedType(
        DbManagedTypes.Model
      );
      let models: { label: string; value: string }[];

      if (isNotNullNorUndefined(modelsFunction)) {
        models = await modelsFunction("");

        console.log("Models");
        console.log(models);
      }

      setInventory((oldInv) => {
        const clone = cloneDeep(oldInv);

        const photoScanResults = result.data;

        console.log("Result.data");
        console.log(photoScanResults);

        console.log("VIN = " + photoScanResults.vin);
        clone.VIN = photoScanResults.vin;

        console.log("Make = " + photoScanResults.make);

        if (isNullOrUndefined(photoScanResults)) {
          makeSimpleToast(
            "Error!",
            "PhotoScanResults is null while updating inventory state!"
          );
          return oldInv;
        }

        if (isNotNullNorUndefined(photoScanResults.make)) {
          const matchingMakeIndex = makes.findIndex(
            (m) =>
              m.label.toLowerCase().trim() ===
              (photoScanResults.make as string).toLowerCase().trim()
          );

          console.log("Matching Index");
          console.log(matchingMakeIndex);
          if (matchingMakeIndex !== -1) {
            const matchingMake = makes[matchingMakeIndex];
            clone.Make = matchingMake.label;
          }
        }

        console.log("Model = " + photoScanResults.model);

        if (isNotNullNorUndefined(photoScanResults.model)) {
          const matchingModelIndex = models.findIndex(
            (m) =>
              m.label.toLowerCase().trim() ===
              (photoScanResults.model as string).toLowerCase().trim()
          );

          if (matchingModelIndex !== -1) {
            const matchingModel = models[matchingModelIndex];

            clone.Model = matchingModel.label;
          }
        }

        return clone;
      });

      makeSimpleToast(
        "Success!",
        "We have updated the VIN value based off of the image"
      );
    } catch (e) {
      makeSimpleToast("Error!", "Error occured while attemping to scan vin!");
    }
  };

  return (
    <>
      <LoaderOverlay
        isVisible={loading}
        progressText={"Deleting Inventory..."}
      />
      <Layout>
        <IonHeader>
          <IonToolbar>
            {isNotNullNorUndefined(locationObj) &&
              isNotNullNorUndefined(locationObj.state) &&
              (() => {
                return (
                  <NavLink
                    to={{
                      pathname: locationObj.state?.fromDelivery
                        ? "/delivery"
                        : locationObj.state?.fromAccountingTodo
                        ? "/accountingtodo"
                        : "/inventory",
                      state: locationObj.state,
                    }}
                    className={styles.backToSearchResultsLink}
                  >
                    <IonIcon
                      className={styles.backToSearchResultsLinkIcon}
                      icon={chevronBackOutline}
                    />
                    Back to search results
                  </NavLink>
                );
              })()}
            <IonTitle size="large">
              {!isEditing ? "Viewing" : isUpdating ? "Update" : "Create"}{" "}
              Inventory
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {/* <IonList>
                {photos != undefined &&
                    photos.map((photo) => (
                        <IonItem key={photo}>
                            <IonThumbnail slot="start">
                                <IonImg src={photo} />
                            </IonThumbnail>
                        </IonItem>
                    ))}
            </IonList> */}
        {/* Should make it so that transfer button only shows on development builds */}

        {isEditing ? (
          <div className={styles.gridContainer}>
            <IonGrid>
              <form onSubmit={handleSubmit} ref={formRef}>
                {/* <IonHeader>General Info</IonHeader> */}
                {inventory.markForValidation === true && (
                  <>
                    <IonRow>
                      <IonCol>
                        <IonItem fill="solid">
                          <IonIcon color="danger" icon={alertCircleOutline} />
                          <IonButton
                            color="danger"
                            onClick={handleShowReasonForMarkedForValidation}
                          >
                            This Record Is Marked For Validation!
                          </IonButton>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </>
                )}
                <IonRow>
                  {isEditing && (
                    <>
                      {!Number.isNaN(+inventory.Stock) &&
                        +inventory.Stock <= 1000 &&
                        canUserAccessAdminSecurityWithGivenRoles(roles) && (
                          <IonCol>
                            <IonButton onClick={() => handleResetDummyData()}>
                              Reset Dummy Data
                            </IonButton>
                          </IonCol>
                        )}
                      <IonCol>
                        {/* <IonButton
                                                onClick={() => handleInventoryPictureButtonClick(inventory.Stock)}>
                                                <IonIcon icon={cameraOutline} />
                                                Inventory */}
                        {/* </IonButton> */}
                        {/* <label> */}
                        {/* Image To Upload */}
                        <input
                          type="file"
                          title=" "
                          multiple
                          onChange={(e) => {
                            if (isNullOrUndefined(e.target.files)) {
                              // Shouldn't really happen.
                              makeSimpleToast("Please provide photos!");
                              return;
                            }

                            handleUploadPhoto(
                              e.target.files,
                              null, // Setting fileName to null since it will pull from each file's filename
                              inventory.Stock,
                              RecordType.Inventory,
                              false,

                              (photoCreationDto) => {
                                setPhotos(photoCreationDto.currentPhotosList);
                                makeSimpleToast(
                                  `Photos that were unable to be uploaded. ${photoCreationDto.unsuccessfulPhotoUploadAttempts.map(
                                    (x) => x.attemptedUrl
                                  )}`
                                );
                              }
                            )
                              .then((photos) => {
                                if (isNullOrUndefined(photos)) {
                                  return;
                                }
                                setPhotos(photos);
                              })
                              .catch((e) => {
                                console.error(e);
                              });

                            e.target.value = "";
                          }}
                          ref={uploadPhotosRef}
                          style={{ display: "none" }}
                        />
                        <IonButton
                          onClick={() => uploadPhotosRef.current?.click()}
                        >
                          <IonIcon icon={cameraOutline} />
                          Inventory
                        </IonButton>

                        {/* </label> */}
                      </IonCol>

                      <IonCol>
                        <IonButton onClick={changeToView}>
                          <IonIcon icon={eyeOutline} />
                          View
                        </IonButton>
                      </IonCol>
                      {photos != undefined && (
                        <IonCol>
                          {(() => {
                            return (
                              <PhotoGallery
                                handleUpdatingPhotosFromPhotoGallery={
                                  handleUpdatingPhotosFromPhotoGallery
                                }
                                photoUrls={photos}
                                galleryTitle={"Inventory Photos"}
                                removingUrlFromState={removingUrlFromState}
                                stock={inventory.Stock}
                                recordType={RecordType.Inventory}
                              />
                            );
                          })()}
                        </IonCol>
                      )}
                    </>
                  )}
                </IonRow>

                <IonRow>
                  <IonCol
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ margin: "1rem" }}>
                      <h1
                        style={{
                          fontWeight: "500",
                          fontSize: "1.25rem",
                          marginBottom: "1rem",
                          marginLeft: "1.rem",
                          marginRight: "1rem",
                        }}
                      >
                        Trailer Identifiers
                      </h1>
                      {/* Start of Inventory Identifiers */}
                      {/* <IonHeader>Inventory Identifiers</IonHeader> */}
                      <div style={{ position: "relative", zIndex: "50" }}>
                        <IonItem
                          fill="solid"
                          className={`${invalidStock ? "ion-invalid" : ""}`}
                        >
                          <IonLabel position="stacked">Stock</IonLabel>
                          <IonInput
                            disabled={!isEditing || isUpdating}
                            clearOnEdit
                            value={inventory.Stock}
                            onIonBlur={(e) =>
                              updateInventoryProperty("Stock")(
                                e.target.value == undefined
                                  ? ""
                                  : e.target.value + ""
                              )
                            }
                          />
                          <IonNote slot="error">
                            Stock is already in use!
                          </IonNote>
                        </IonItem>
                      </div>
                      <div className="invalid-feedback">
                        Stock Number is required
                      </div>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Condition</IonLabel>
                        <IonSelect {...bindIonInventorySelect("Condition")}>
                          {Object.entries(InventoryCondition)
                            .filter(([key, value]) => isNaN(parseInt(key)))
                            .map(([key, value]) => (
                              <IonSelectOption value={value} key={value}>
                                {key}
                              </IonSelectOption>
                            ))}
                        </IonSelect>
                      </IonItem>
                      {/* ONLy make this readonly for non-admin users */}
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Status</IonLabel>
                        <IonSelect
                          disabled={
                            !canUserAccessAdminSecurityWithGivenRoles(roles) &&
                            inventory.Status === InventoryStatus.Sold
                          }
                          {...bindIonInventorySelect("Status")}
                        >
                          {Object.entries(InventoryStatus)
                            .filter(([key, value]) => isNaN(parseInt(key)))
                            .map(([key, value]) => (
                              <IonSelectOption
                                disabled={
                                  !canUserAccessAdminSecurityWithGivenRoles(
                                    roles
                                  ) && key === "Sold"
                                }
                                value={value}
                                key={value}
                              >
                                {key}
                              </IonSelectOption>
                            ))}
                        </IonSelect>
                      </IonItem>

                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Type</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Type")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.InventoryType
                          )}
                        />
                      </div>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Sub Type</IonLabel>
                        <IonInput
                          type="text"
                          value={
                            inventory == undefined
                              ? ""
                              : inventory["Sub Type"] == undefined
                              ? ""
                              : InventorySubType[inventory["Sub Type"]]
                          }
                          disabled={true}
                        />
                      </IonItem>
                      <input
                        type="file"
                        id="vinphotoupload"
                        style={{ display: "none" }}
                        onChange={(e) => handleVinPhotoUpload(e)}
                      />
                      <IonItem fill="solid">
                        <IonLabel position="stacked">VIN</IonLabel>
                        <IonInput type="text" {...bindIonInventory("VIN")} />
                        <IonButton
                          size="small"
                          onClick={() => {
                            const vinUploadInput = document.getElementById(
                              "vinphotoupload"
                            ) as HTMLInputElement;

                            vinUploadInput.click();

                            console.log("clicked");
                          }}
                        >
                          <IonIcon
                            slot="icon-only"
                            ios={cameraOutline}
                          ></IonIcon>
                        </IonButton>
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Spec</IonLabel>
                        <IonInput type="text" {...bindIonInventory("Spec")} />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Number</IonLabel>
                        <IonInput type="text" {...bindIonInventory("Number")} />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Color</IonLabel>
                        <IonInput type="text" {...bindIonInventory("Color")} />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Price</IonLabel>
                        <IonInput type="text" {...bindIonInventory("Price")} />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Cost</IonLabel>
                        <IonInput type="text" {...bindIonInventory("Cost")} />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Inventory Title</IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("Inventory Title")}
                        />
                      </IonItem>
                    </div>

                    <div style={{ margin: "1rem" }}>
                      <h1
                        style={{
                          fontWeight: "500",
                          fontSize: "1.25rem",
                          marginBottom: "1rem",
                          marginLeft: "1.rem",
                          marginRight: "1rem",
                        }}
                      >
                        Trailer Tracking
                      </h1>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Check Number</IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("Check Number")}
                        />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Ordered Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Ordered Date"]) ||
                            inventory["Ordered Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Ordered Date")}
                        />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Comp Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Comp Date"]) ||
                            inventory["Comp Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Comp Date")}
                          {...minMax}
                        />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Inspection Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Inspection Date"]) ||
                            inventory["Inspection Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Inspection Date")}
                          {...minMax}
                        />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Received Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Received Date"]) ||
                            inventory["Received Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Received Date")}
                        />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Misc Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Misc Date"]) ||
                            inventory["Misc Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Misc Date")}
                          {...minMax}
                        />
                      </IonItem>

                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>City Location</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("City Location")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.CityLocation
                          )}
                        />
                      </div>

                      <div
                        className={styles.asyncSelectInputContainer}
                        style={{
                          position: "relative",
                          zIndex: decrementingZIndex--,
                        }}
                      >
                        <IonLabel position="stacked">Yard Stall</IonLabel>
                        {(() => {
                          const processedStyles: StylesConfig<
                            any,
                            false,
                            GroupBase<any>
                          > = {
                            ...asyncSelectStyles,
                            singleValue: (styles, state) => {
                              return {
                                ...styles,
                                color: "blue",
                              };
                            },
                          };

                          return (
                            <ConditionalLinkWrapper
                              to={`/yard-location/edit/${IdentifierType.YardStall}/${inventory["Yard Stall"]}`}
                              conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(
                                inventory["Yard Stall"]
                              )}
                            >
                              <div>
                                <AsyncSelect
                                  styles={processedStyles}
                                  loadOptions={GetYardLocationNames}
                                  filterOption={(option, search) =>
                                    option.value
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  }
                                  // cacheOptions
                                  closeMenuOnSelect
                                  isDisabled={isUpdating}
                                  placeholder={"Search by Yard Stall"}
                                  className={styles.asyncSelectCustom}
                                  {...bindInventoryReactAsyncSelect(
                                    "Yard Stall"
                                  )}
                                />
                              </div>
                            </ConditionalLinkWrapper>
                          );
                        })()}
                      </div>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Decal Id</IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("Decal Id")}
                        />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">DOT Status</IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("DOT Status")}
                        />
                      </IonItem>
                      {/* <IonItem fill="solid">
                                        <IonLabel position="stacked">Yard Stall</IonLabel>
                                        <IonText color={'primary'}>
                                            <IonInput
                                                type="text"
                                                readonly
                                                onClick={() =>
                                                    inventory['Yard Stall'] !== '' &&
                                                    isNotNullNorUndefined(inventory['Yard Stall']) &&
                                                    handleRedirectToYardStall(inventory['Yard Stall'])
                                                }
                                                {...bindIonInventory('Yard Stall')}
                                            />
                                        </IonText>
                                    </IonItem> */}

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Entered In CDK</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Entered In CDK"]) ||
                            inventory["Entered In CDK"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Entered In CDK")}
                        />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Advertise</IonLabel>
                        <IonCheckbox {...bindIonInventoryCheck("Advertise")} />
                      </IonItem>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Photo Status</IonLabel>
                        <IonSelect {...bindIonInventorySelect("Photo Status")}>
                          {Object.entries(PhotoStatus)
                            .filter(([key, value]) => isNaN(parseInt(key)))
                            .map(([key, value]) => (
                              <IonSelectOption value={value} key={value}>
                                {key}
                              </IonSelectOption>
                            ))}
                        </IonSelect>
                      </IonItem>

                      {(!isUpdating ||
                        canUserAccessAdminSecurityWithGivenRoles(roles)) && (
                        <IonItem fill="solid">
                          <IonLabel position="stacked">Split Deal</IonLabel>
                          <IonCheckbox
                            {...bindIonInventoryCheck("Split Deal")}
                          />
                        </IonItem>
                      )}
                    </div>
                  </IonCol>
                  <IonCol
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ margin: "1rem" }}>
                      <h1
                        style={{
                          fontWeight: "500",
                          fontSize: "1.25rem",
                          marginBottom: "1rem",
                          marginLeft: "1.rem",
                          marginRight: "1rem",
                        }}
                      >
                        Trailer's Spec's
                      </h1>

                      <IonItem fill="solid">
                        <IonLabel position="stacked">Year</IonLabel>
                        <IonInput
                          type="number"
                          clearOnEdit
                          {...bindIonInventory("Year")}
                        />
                      </IonItem>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Make</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          value={{
                            label: inventory.Make,
                            value: inventory.Make,
                          }}
                          onChange={(newValue) => {
                            console.log("new Value");

                            console.log(newValue);

                            setInventory((oldInv) => {
                              console.log("setting Inv");

                              const clone = cloneDeep(oldInv);

                              clone.Make = newValue.label;

                              console.log("newValue.label");

                              console.log(newValue.label);

                              return clone;
                            });
                          }}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.Make
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Model</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Model")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.Model
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Length</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("L")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.Length
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Width</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("W")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.Width
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Height</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("H")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.Height
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Wheel Sz</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Wheel Sz")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.WheelSize
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Wheel Type</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Wheel Type")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.WheelType
                          )}
                        />
                      </div>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Axles</IonLabel>
                        <IonInput
                          type="number"
                          clearOnEdit
                          {...bindIonInventory("Axles")}
                        />
                      </IonItem>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Suspension Type</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Suspension Type")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.SuspensionType
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Suspension Style</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Suspension Style")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.SuspensionStyle
                          )}
                        />
                      </div>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">King Pin</IonLabel>
                        <IonInput type="text" {...bindIonInventory("KP")} />
                      </IonItem>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Floor Type</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Floor Type")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.FloorType
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Rear Door Type</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          // cacheOptions={true}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Rear Door Type")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.RearDoorType
                          )}
                        />
                      </div>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Side Doors</IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("Side Doors")}
                        />
                      </IonItem>
                    </div>
                    <IonItem fill="solid">
                      <IonLabel position="stacked">Notes</IonLabel>
                      <IonTextarea autoGrow {...bindIonInventory("Notes")} />
                    </IonItem>

                    <div style={{ margin: "1rem" }}>
                      <h1
                        style={{
                          fontWeight: "500",
                          fontSize: "1.25rem",
                          marginBottom: "1rem",
                          marginLeft: "1.rem",
                          marginRight: "1rem",
                        }}
                      >
                        Unit Spec's
                      </h1>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Reefer Year</IonLabel>
                        <IonInput
                          type="number"
                          {...bindIonInventory("Reefer Year")}
                        />
                      </IonItem>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Reefer Make</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          // cacheOptions={true}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Reefer Make")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.ReeferMake
                          )}
                        />
                      </div>
                      <div
                        style={{ zIndex: decrementingZIndex-- }}
                        className={styles.asyncSelectInputContainer}
                      >
                        <IonLabel>Reefer Model</IonLabel>
                        <AsyncSelect
                          styles={asyncSelectStyles}
                          cacheOptions={true}
                          filterOption={(filterOption, inputValue) => {
                            return filterOption.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase());
                          }}
                          // cacheOptions={true}
                          isSearchable={true}
                          defaultOptions={true}
                          {...bindInventoryReactAsyncSelect("Reefer Model")}
                          loadOptions={getFactoryFunctionForDBManagedType(
                            DbManagedTypes.ReeferModel
                          )}
                        />
                      </div>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">
                          Reefer Serial Number
                        </IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("Reefer Serial Number")}
                        />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">Unit Hours</IonLabel>
                        <IonInput
                          type="number"
                          clearOnEdit
                          {...bindIonInventory("Unit Hours")}
                        />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">
                          Reefer Engine Year
                        </IonLabel>
                        <IonInput
                          type="number"
                          clearOnEdit
                          {...bindIonInventory("Reefer Engine Year")}
                        />
                      </IonItem>
                      <IonItem fill="solid">
                        <IonLabel position="stacked">
                          Reefer Description
                        </IonLabel>
                        <IonInput
                          type="text"
                          {...bindIonInventory("Reefer Desc")}
                        />
                      </IonItem>
                    </div>

                    {/* End of Inventory Tracking Group */}
                    {/* Start of Inventory Sepcs */}
                  </IonCol>
                </IonRow>

                <h1
                  style={{
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    marginBottom: "1rem",
                    marginLeft: "1rem",
                    marginRight: "1rem",
                  }}
                >
                  Sale Details
                </h1>
                <div style={{ margin: "1rem" }}>
                  <IonRow>
                    <IonCol
                      style={{ padding: "unset", paddingRight: "1.5rem" }}
                      size={"6"}
                    >
                      <IonItem fill="solid" style={{ width: "100%" }}>
                        <IonLabel position="stacked">Deposit</IonLabel>
                        <IonInput
                          type="number"
                          clearOnEdit
                          {...bindIonInventory("Deposit")}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol
                      style={{ padding: "unset", paddingLeft: "1rem" }}
                      size={"6"}
                    >
                      {isUpdating && (
                        <IonItem fill="solid" style={{ width: "100%" }}>
                          <IonLabel position="stacked">Invoice Number</IonLabel>
                          <IonText color={"primary"}>
                            <ConditionalLinkWrapper
                              to={`/sales-order/edit/${IdentifierType.Stock}/${inventory["Stock"]}`}
                              conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(
                                inventory["Invoice Number"]
                              )}
                            >
                              <IonInput
                                className={styles.redirectInput}
                                type="text"
                                style={{ color: "blue" }}
                                readonly
                                {...bindIonInventory("Invoice Number")}
                              />
                            </ConditionalLinkWrapper>
                          </IonText>
                        </IonItem>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{ padding: "unset", paddingRight: "1.5rem" }}
                      size={"6"}
                    >
                      <IonItem fill="solid" style={{ width: "100%" }}>
                        <IonLabel position="stacked">Order Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Order Date"]) ||
                            inventory["Order Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Order Date")}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol
                      style={{ padding: "unset", paddingLeft: "1rem" }}
                      size={"6"}
                      no-padding
                    >
                      <IonItem fill="solid" style={{ width: "100%" }}>
                        <IonLabel position="stacked">Customer</IonLabel>
                        <IonInput
                          type="text"
                          clearOnEdit
                          {...bindIonInventory("Customer")}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{ padding: "unset", paddingRight: "1.5rem" }}
                      size={"6"}
                      no-padding
                    >
                      <IonItem fill="solid" style={{ width: "100%" }}>
                        <IonLabel position="stacked">Delivered Date</IonLabel>
                        <IonInput
                          className={`${
                            isNullOrUndefined(inventory["Delivered Date"]) ||
                            inventory["Delivered Date"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Delivered Date")}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol
                      style={{ padding: "unset", paddingLeft: "1rem" }}
                      no-padding
                      size={"6"}
                    >
                      <IonItem fill="solid" style={{ width: "100%" }}>
                        <IonLabel position="stacked">Sales Person</IonLabel>
                        <IonInput
                          type="text"
                          clearOnEdit
                          {...bindIonInventory("Sales Person")}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol
                      style={{ padding: "unset", paddingRight: "1.5rem" }}
                      size={"6"}
                      no-padding
                    >
                      <IonItem fill="solid" style={{ width: "100%" }}>
                        <IonLabel position="stacked">
                          Temp Tag Expiration
                        </IonLabel>

                        <IonInput
                          className={`${
                            isNullOrUndefined(
                              inventory["Temp Tag Expiration"]
                            ) || inventory["Temp Tag Expiration"].trim() === ""
                              ? styles.dateInputEmpty
                              : ""
                          }`}
                          onIonFocus={(e) => (e.target.style.opacity = "1")}
                          type="date"
                          {...bindIonInventoryDate("Temp Tag Expiration")}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow>
                  <IonCol>
                    <IonButton type="submit">Submit</IonButton>
                  </IonCol>
                  {isUpdating &&
                    canUserAccessAdminSecurityWithGivenRoles(roles) && (
                      <IonCol>
                        <IonButton
                          color="danger"
                          type="button"
                          onClick={handleDelete}
                        >
                          Delete
                        </IonButton>
                      </IonCol>
                    )}
                </IonRow>
              </form>

              {inventory != undefined &&
                inventory.Id != undefined &&
                +inventory.Id != 0 && (
                  <>
                    <AuditLogContainer<InventoryDto>
                      tableName="Inventory"
                      primaryKey={inventory.Stock + ""}
                      isAdmin={false}
                      booleanToggleToIndicateToUpdateAuditLog={
                        booleanToggleToIndicateToUpdateAuditLog
                      }
                      filterKeyArrayByExcludedColumns={
                        filterInventoryKeyArrayByExcludedColumns
                      }
                    />
                  </>
                )}
            </IonGrid>
          </div>
        ) : (
          <>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton
                    onClick={changeToEdit}
                    disabled={!canUserAccessSalesSecurityWithGivenRoles(roles)}
                  >
                    <IonIcon icon={createOutline} />
                    Edit
                  </IonButton>
                </IonCol>
                {photos != undefined && (
                  <IonCol>
                    {(() => {
                      return (
                        <PhotoGallery
                          handleUpdatingPhotosFromPhotoGallery={
                            handleUpdatingPhotosFromPhotoGallery
                          }
                          recordType={RecordType.Inventory}
                          stock={inventory.Stock}
                          photoUrls={photos}
                          galleryTitle={"Inventory Photos"}
                          removingUrlFromState={removingUrlFromState}
                        />
                      );
                    })()}
                  </IonCol>
                )}
                <IonCol>
                  <div>
                    <ReactToPrint
                      bodyClass={"test"}
                      copyStyles={false}
                      trigger={() => <IonButton>Print</IonButton>}
                      content={() => {
                        const element = invCondensedViewPrintComponent.current;

                        if (isNotNullNorUndefined(element)) {
                          return element;
                        }
                        const el = document.createElement("p");
                        el.innerText = "Test taco";
                        el.style.color = "black";
                        return el;
                      }}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <InvCondensedView
              inventory={inventory}
              ref={invCondensedViewPrintComponent}
              testStyle={styles.test}
            />
          </>
        )}
      </Layout>
    </>
  );
};
