import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react';
import React, { createRef, useRef, useState } from 'react';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';

type CollapsibleSectionProps<T> = {
    records: T[];
    identifier: keyof T;
    labelStrip: (record: T, index: number) => JSX.Element;
    content: (records: T) => JSX.Element;
    startingValue: number | undefined | string;
    focusedEntry: string | null;
    setFocusedEntry: React.Dispatch<React.SetStateAction<string | null>>;
};

let countDownZIndex = 1000;

const CollapsibleSection = <T,>({
    records,
    identifier,
    labelStrip,
    content,
    startingValue,
    focusedEntry,
    setFocusedEntry: setFocusedEntryInternal,
}: CollapsibleSectionProps<T>) => {
    const accordionGroup = useRef<HTMLIonAccordionGroupElement>(null);
    const [accordionGroupValue, setAccordionGroupValue] = useState<string | undefined>(
        isNullOrUndefined(startingValue) ? undefined : startingValue + ''
    );

    // const setAccordionGroupValue = (value: string | undefined, onMouseDown = false) => {
    //     setAccordionGroupValueInternal(value);
    // };

    const ignoreNextClick = useRef<boolean>(false);

    const setFocusedEntry = (value: string | null) => {
        setFocusedEntryInternal(value);
    };

    const resetFocusToggle = () => {
        setFocusedEntry(null);
    };

    const ignoreNextClickOnCollapsibleSectionButton = () => {
        ignoreNextClick.current = true;
    };

    const addFocusToggleAndRecordToContent = (index: number, record: T) => {
        const clone = React.cloneElement(content(record), {
            focusToggle: focusedEntry,
            record: records[index],
            resetFocusToggle: resetFocusToggle,
            index: index,
        });
        return clone;
    };

    const t = createRef<HTMLIonIconElement>();

    const handleIncrementingZIndex = () => {
        countDownZIndex--;
        return { zIndex: countDownZIndex + '' };
    };

    if (isNullOrUndefined(records)) {
        // Covers null condition as well
        return <></>;
    }

    return (
        <div
            // ?style={handleIncrementingZIndex()}
            style={{ border: '1px solid black', position: 'relative', ...handleIncrementingZIndex() }}>
            <IonAccordionGroup
                ref={accordionGroup}
                value={focusedEntry}
                onIonChange={(e) => {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    if (isNullOrUndefined(e.currentTarget)) {
                        return;
                    }

                    const targetValue = (e.currentTarget as HTMLIonAccordionGroupElement).value;

                    if (targetValue === accordionGroupValue) {
                        return;
                    }
                    setFocusedEntry(targetValue as string);

                    const value = (e.currentTarget as HTMLIonAccordionGroupElement).value;

                    setFocusedEntry(typeof value !== 'string' ? null : value);
                    // setFocusToggle(null); // The other classes are meant to ignore null. This resets the value
                }}>
                {records.map((record, index) => (
                    <IonAccordion
                        key={record[identifier] + ''}
                        value={record[identifier] + ''}
                        id={record[identifier] + ''}
                        onMouseDown={(e) => {
                            const targetElement = e.currentTarget as HTMLIonAccordionElement;
                            // if (document.activeElement === targetElement) {

                            targetElement.dataset['mdown'] = 'true';
                            if (isNullOrUndefined(accordionGroup.current)) {
                                return;
                            }

                            if (accordionGroupValue === record[identifier] + '') {
                                // accordionGroup.current.dataset[''];
                                // setAccordionGroupValue(undefined);
                            }
                            // setAccordionGroupValue(record[identifier] + '', true);
                            e.preventDefault();
                        }}
                        onFocus={(e) => {
                            if (!accordionGroup.current) {
                                return;
                            }
                            if (e.currentTarget.dataset['mdown'] === 'true') {
                                e.currentTarget.dataset['mdown'] = undefined;
                                return;
                            }
                            if (e.currentTarget.contains(e.relatedTarget as Node)) {
                                return;
                            }
                            setAccordionGroupValue(record[identifier] + '');
                            // e.stopPropagation();
                            e.preventDefault();
                        }}
                        onBlur={(e) => {
                            if (!accordionGroup.current || isNullOrUndefined(e)) {
                                return;
                            }
                            if (e.currentTarget.dataset['mdown'] === 'true') {
                                // e.currentTarget.dataset['mdown'] = undefined;
                                return;
                            }
                            if (e.currentTarget.contains(e.relatedTarget as Node)) {
                                return;
                            }
                            // if (accordionGroupValue === record[identifier] + '') {
                            //     setAccordionGroupValue(undefined);
                            // }

                            setFocusedEntry(null);
                            // setAccordionGroupValue(undefined);
                            e.preventDefault();
                        }}>
                        <IonItem slot="header">
                            <IonLabel>{labelStrip(record, index)}</IonLabel>
                        </IonItem>
                        <div slot="content">{addFocusToggleAndRecordToContent(index, record)}</div>
                    </IonAccordion>
                ))}
            </IonAccordionGroup>
        </div>
    );
};

export default CollapsibleSection;
