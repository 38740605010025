/* eslint-disable jsx-a11y/anchor-is-valid */
import { IonCol } from '@ionic/react';
import React from 'react';
import { GetGlobalFilterType } from '../../../../models/Dtos/GetGlobalFilterDto';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { FilterType } from '../../../../models/Global';
import { UserGlobalFilterStorageType } from '../../../../models/UserGlobalFIlterStorageType';
import {
    isNotNullNorUndefined,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
} from '../../../../utils/NullOrUndefined';
import QueriedTableFilterSortContainer from '../QueriedTableFilterSortContainer/QueriedTableFilterSortContainer';
import styles from './QueriedTableGlobalUserFilterListType.module.scss';

type QueriedTableGlobalUserFilterListType = {
    ['data-testid']?: string;
    handleGlobalFilterButtonClick: (globalFilterName: string) => Promise<void>;

    isFilterSelected: ({ name, filterType }: Omit<UserGlobalFilterStorageType, 'globalFilterRow'>) => boolean;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    handleUserFilterButtonClick: (userFilterName: string) => Promise<void>;
    userFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
    globalFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
};

const QueriedTableGlobalUserFilterList: React.FC<QueriedTableGlobalUserFilterListType> = ({
    'data-testid': dataTestId,
    handleGlobalFilterButtonClick,
    isFilterSelected,
    computedCombinedFilters,
    handleUserFilterButtonClick,
    userFiltersStorage,
    globalFiltersStorage,
}) => {
    const generateTabList = (targetFilterType: GetGlobalFilterType) => {
        const globalFilters = globalFiltersStorage.filter(
            (item) =>
                item.filterType === FilterType.Global &&
                item.filterName.toLowerCase() !== 'main' &&
                item.filterName.toLowerCase() !== 'all'
        );
        return (
            <>
                <ul className={styles.tab_list}>
                    {isNotNullNorUndefined(globalFilters) &&
                        globalFilters
                            .filter(
                                ({ filterName, globalFilterRow }) =>
                                    isNotNullNorUndefined(filterName) && globalFilterRow === targetFilterType
                            )
                            .map((globalFilter, index) => {
                                const isSelected = isFilterSelected({
                                    name: globalFilter.filterName,
                                    filterType: FilterType.Global,
                                });

                                return (
                                    <li
                                        key={globalFilter.filterName}
                                        className={styles.list_item}
                                        // style={
                                        //     waitingForGlobalFilterButtonClickToFinish.current
                                        //         ? { cursor: 'no-drop' }
                                        //         : {}
                                        // }
                                    >
                                        <a
                                            href="#"
                                            // style={
                                            //     waitingForGlobalFilterButtonClickToFinish.current
                                            //         ? { cursor: 'no-drop' }
                                            //         : {}
                                            // }
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleGlobalFilterButtonClick(globalFilter.filterName);
                                            }}
                                            className={`${styles.tab_link} ${isSelected ? styles.activeText : ''}`}>
                                            {isNullOrUndefinedOrEmptyString(globalFilter.filterName)
                                                ? '<Empty>'
                                                : globalFilter.filterName}
                                        </a>
                                        <div className={isSelected ? styles.active : styles.inactive} />
                                    </li>
                                );
                            })}
                </ul>
            </>
        );
    };

    const generateMainAllTabs = () => {
        const mainFilter = globalFiltersStorage.find((x) => x?.filterName?.toLowerCase() === 'main');
        const allFilter = globalFiltersStorage.find((x) => x?.filterName?.toLowerCase() === 'all');

        if (isNullOrUndefined(mainFilter) && isNullOrUndefined(allFilter)) {
            // If they are both null, reutrn <></>; Otherwise return conditional list
            return <></>;
        }
        // if ((isNullOrUndefined(mainFilter) && (isNullOrUndefined(allFilter))))) {
        //     return <></>;
        // }

        return (
            <ul className={styles.tab_list}>
                {isNotNullNorUndefined(mainFilter) && (
                    <li
                        key={mainFilter.filterName}
                        className={styles.list_item}
                        // style={waitingForGlobalFilterButtonClickToFinish.current ? { cursor: 'no-drop' } : {}}
                    >
                        <a
                            href="#"
                            // style={waitingForGlobalFilterButtonClickToFinish.current ? { cursor: 'no-drop' } : {}}
                            onClick={(event) => {
                                event.preventDefault();
                                handleGlobalFilterButtonClick(mainFilter.filterName);
                            }}
                            className={`${styles.tab_link} ${
                                isFilterSelected({ name: mainFilter.filterName, filterType: FilterType.Global })
                                    ? styles.activeText
                                    : ''
                            }`}>
                            {mainFilter.filterName}
                        </a>
                        <div
                            className={
                                isFilterSelected({ name: mainFilter.filterName, filterType: FilterType.Global })
                                    ? styles.active
                                    : styles.inactive
                            }
                        />
                    </li>
                )}
                {isNotNullNorUndefined(allFilter) && (
                    <li
                        key={allFilter.filterName}
                        className={styles.list_item}
                        // style={waitingForGlobalFilterButtonClickToFinish.current ? { cursor: 'no-drop' } : {}}
                    >
                        <a
                            href="#"
                            // style={waitingForGlobalFilterButtonClickToFinish.current ? { cursor: 'no-drop' } : {}}
                            onClick={(event) => {
                                event.preventDefault();
                                handleGlobalFilterButtonClick(allFilter.filterName);
                            }}
                            className={`${styles.tab_link} ${
                                isFilterSelected({ name: allFilter.filterName, filterType: FilterType.Global })
                                    ? styles.activeText
                                    : ''
                            }`}>
                            {allFilter.filterName}
                        </a>
                        <div
                            className={
                                isFilterSelected({ name: allFilter.filterName, filterType: FilterType.Global })
                                    ? styles.active
                                    : styles.inactive
                            }
                        />
                    </li>
                )}
            </ul>
        );
    };

    return (
        <div data-testid="queried-table-tab-container-tab-list">
            {generateMainAllTabs()}
            {generateTabList(GetGlobalFilterType.Row1)}
            {generateTabList(GetGlobalFilterType.Row2)}
            <IonCol>
                <QueriedTableFilterSortContainer
                    computedCombinedFilters={computedCombinedFilters}
                    handleUserFilterButtonClick={handleUserFilterButtonClick}
                    isFilterSelected={isFilterSelected}
                    userFiltersStorage={userFiltersStorage}
                />
            </IonCol>
        </div>
    );
};

export default QueriedTableGlobalUserFilterList;
