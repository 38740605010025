import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';

interface EasyToCopyAllContentNoteWrapperProps {
    divStyles: CSSProperties;
    value: unknown;
    maxHeight?: number;
}
const ifEmptyBreak = (value: unknown) => (value === '' || isNullOrUndefined(value) ? <br /> : value + '');

const EasyToCopyAllContentNoteWrapper = ({ divStyles, value, maxHeight }: EasyToCopyAllContentNoteWrapperProps) => {
    const divRef = useRef<HTMLDivElement>(null);

    const handleSelectEvent = useCallback((e: Event) => {
        const selection = document.getSelection();

        if (isNullOrUndefined(selection)) {
            return;
        }
        if (selection.rangeCount <= 0) {
            // No ranges
            // No need to handle it
            return;
        }

        const range = selection.getRangeAt(0);

        const currentDivRef = divRef.current;

        if (isNullOrUndefined(currentDivRef)) {
            return;
        }

        if (
            !currentDivRef.contains(range.startContainer) &&
            (currentDivRef.contains(range.endContainer) || currentDivRef === range.endContainer)
        ) {
            range.setEndAfter(currentDivRef);

            selection.removeAllRanges();

            selection.addRange(range);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', handleSelectEvent);
        document.addEventListener('touchend', handleSelectEvent);

        return () => {
            document.removeEventListener('mouseup', handleSelectEvent);
            document.removeEventListener('touchend', handleSelectEvent);
        };
    }, [handleSelectEvent]);
    return (
        <>
            <div style={{ ...divStyles, overflowY: 'scroll', maxHeight: maxHeight ?? '6rem' }} ref={divRef}>
                {ifEmptyBreak(value)}
            </div>
        </>
    );
};

export default EasyToCopyAllContentNoteWrapper;
