import { ReturnAccountingToDoDto } from '../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { InventoryCondition } from '../../models/Global';

export const getEnumFromAccountingTodoDtoPropertyName = (propertyName: keyof ReturnAccountingToDoDto) => {
    switch (propertyName) {
        case 'Inventory Condition': {
            return InventoryCondition;
        }

        default: {
            return InventoryCondition;
        }
    }
};
