import cloneDeep from 'lodash.clonedeep';
import {
    GeneralOptions,
    GeneralOptionsColumnsToShowAndColumnOrderAsArray,
} from '../models/Dtos/OptionsDtos/GeneralOptions';
import { splitByComma } from './ColumnsToShowUtils';

const convertStringArrayGeneralOptionsToNormal = (
    withStringArray: GeneralOptionsColumnsToShowAndColumnOrderAsArray
): GeneralOptions => {
    const cloned = cloneDeep(withStringArray);

    const updated: GeneralOptions = {
        ...cloned,
        columnsToShow: withStringArray.orderedColumnsToShow.join(','),
        columnOrder: withStringArray.columnOrder.join(','),
    };

    return updated;
};

export const convertNormalGeneralOptionsToStringArray = (
    generalOptions: GeneralOptions
): GeneralOptionsColumnsToShowAndColumnOrderAsArray => {
    const cloned = cloneDeep(generalOptions);

    const updated: GeneralOptionsColumnsToShowAndColumnOrderAsArray = {
        ...cloned,
        orderedColumnsToShow: splitByComma(generalOptions.columnsToShow),
        columnOrder: splitByComma(generalOptions.columnOrder),
    };

    return updated;
};

export default convertStringArrayGeneralOptionsToNormal;
