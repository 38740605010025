import {
    IonButton,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonRow,
    IonSearchbar,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { AxiosResponse } from 'axios';
import { informationCircleOutline } from 'ionicons/icons';
import cloneDeep from 'lodash.clonedeep';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { read, utils } from 'xlsx';
import { ReturnAccountingToDoDto } from '../../../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { ReturnListWithOptionsForExportDto } from '../../../../models/Dtos/GeneralDtos/ReturnListWithOptionsForExportDto';
import { ReturnModelWithMessageDto } from '../../../../models/Dtos/GeneralDtos/ReturnModelWithMessageDto';
import { ImportDto } from '../../../../models/Dtos/InventoryDtos/ImportDto';
import { ImportSheetDto } from '../../../../models/Dtos/InventoryDtos/ImportSheetDto';
import { InventoryDto } from '../../../../models/Dtos/InventoryDtos/InventoryDto';
import { DynamicFilter } from '../../../../models/Dtos/OptionsDtos/DynamicFilter';
import { DynamicSortWithoutSortPriority } from '../../../../models/Dtos/OptionsDtos/DynamicSort';
import {
    GeneralOptions,
    GeneralOptionsColumnsToShowAndColumnOrderAsArray,
} from '../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { ImportOptions } from '../../../../models/Dtos/OptionsDtos/ImportOptions';
import { SortOptions } from '../../../../models/Dtos/OptionsDtos/SortOptions';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { RecordOfTransferDto } from '../../../../models/Dtos/RecordOfTransferDtos/RecordOfTransferDto';
import { SalesOrderDto } from '../../../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { YardMapDto } from '../../../../models/Dtos/YardMapDtos/YardMapDto';
import { DtoType, FilterType, RecordType } from '../../../../models/Global';
import { UserGlobalFilterStorageType } from '../../../../models/UserGlobalFIlterStorageType';
import { DeliveryService, InventoryService, SalesOrderService, YardMapService } from '../../../../utils/apiServices';
import convertStringArrayGeneralOptionsToNormal from '../../../../utils/ConvertStringArrayGeneralOptionsToNormal';
import makeSimpleToast from '../../../../utils/MakeSimpleToast';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../../../utils/NullOrUndefined';
import {
    AllPossibleReturnTypes,
    EndpointResponseType,
    getIdentifierPropertyFromDtoType,
} from '../../../../utils/RecordTypeUtils';
import { createExcelReport } from '../../../../utils/SpreadsheetUtils/export';
import GeneralToolTip from '../../../GeneralToolTip/GeneralToolTip';
import LoaderOverlay from '../../../LoaderOverlay/LoaderOverlay';
import PrintQueriedTable from '../../../PrintQueriedTable/PrintQueriedTable';
import { canUserAccessAdminSecurityWithGivenRoles, RoleContext } from '../../../RoleContext/RoleContextComp';
import { AsyncSelectLoadOptionsReturnType } from '../../../Search/SearchUtils';
import FilterSortControlModal from './FilterSortControlModal/FilterSortControlModal';
import styles from './QueriedTableToolbar.module.scss';

type ExportFunctionType = (importOptions: ImportOptions) => Promise<
    AxiosResponse<
        ReturnModelWithMessageDto<
            // ReturnListWithOptionsForExportDto<SpreadsheetDto | DeliveryDto | SalesOrderDto | YardMapDto>
            ReturnListWithOptionsForExportDto<any>
        >,
        any
    >
>;

// let exportFunction: <T>(
//     importOptions: ImportOptions
// ) => Promise<AxiosResponse<ReturnModelWithMessageDto<ReturnListWithOptionsForExportDto<T>>, any>>;

interface Option {
    label: string;
    value: string;
}

type QueriedTableToolbarProps<T extends object> = {
    endpoint: (options: GeneralOptions | SortOptions) => Promise<EndpointResponseType>;
    handleCreateUpdateUserFilter: (userFilterName: string, updating: boolean) => void;
    handleUserFilterButtonClick: (userFilterName: string) => Promise<void>;
    useCustomEndpoint: boolean;
    setUseCustomEndpoint: (value: boolean) => void;
    recordType?: RecordType;
    dtoType: DtoType;
    model: object;
    handleClear: () => void;
    isFilterSelected: (item: UserGlobalFilterStorageType) => boolean;
    handleCreateUpdateGlobalFilter: (globalFilterName: string, updating: boolean) => void;
    handleDeleteGlobalFilter: (globalFilterName: string) => void;
    handleDeleteUserFilter: (userFilterName: string) => void;

    setOverwritingProvidedData: Dispatch<
        SetStateAction<
            InventoryDto[] | SalesOrderDto[] | RecordOfTransferDto[] | YardMapDto[] | ReturnAccountingToDoDto[]
        >
    >;
    data: T[] | undefined;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    handleUpdatingItemsPerPage: (itemsPerPage: number) => void;
    setSortFilters: (dynamicSorts: DynamicSortWithoutSortPriority[]) => void;
    handleSetCustomFiltersValueFilters: (dynamicFilter: DynamicFilter[]) => void;
    unhidableColumns: string[];
    overridingColumnOrderOnReset?: string;
    setColumnVisibility: (updatedColumnsToShowList: string[]) => void;
    fieldsThatAreCustomTypes: {
        [key in keyof T]?: { label: string; value: string; title: string }[];
    };

    exportEnumProcessor: (dto: T) => T;
    enumProperties: (keyof T)[];

    printProcessor: (inventoryDto: T) => T;
    getAsyncSelectGetMethodFromPropertyName: (
        property: keyof T
    ) => ((name: string) => Promise<AsyncSelectLoadOptionsReturnType<Option>>) | undefined;

    filterKeyArrayByExcludedColumns: (array: (keyof T)[]) => (keyof T)[];

    selectedUserFilterNames: string[];
    selectedGlobalFilterNames: string[];
    computedSelectedGlobalFilters: UserGlobalFilterSpecificGeneralOption[];
    computedSelectedUserFilters: UserGlobalFilterSpecificGeneralOption[];
    customFilters: GeneralOptions;
    unselectGlobalFilter: (globalFilterName: string) => boolean;
    unselectUserFilter: (userFilterName: string) => boolean;
    ignoreFilter: (filterId: string) => boolean;
    unignoreFilter: (filterId: string) => boolean;
    isFilterIgnored: (filterId: string) => boolean;
    combinedFilterTargetProperties: string[];
    updateSearchTerm: (searchTerm: string) => void;

    globalFilterStorage: UserGlobalFilterSpecificGeneralOption[];
    userFilterStorage: UserGlobalFilterSpecificGeneralOption[];
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const QueriedTableToolbar = <T extends object>({
    handleCreateUpdateUserFilter,
    handleUserFilterButtonClick,
    useCustomEndpoint,
    setUseCustomEndpoint,
    recordType,
    model,
    handleClear,
    isFilterSelected,
    handleCreateUpdateGlobalFilter,
    handleDeleteUserFilter,
    handleDeleteGlobalFilter,
    setOverwritingProvidedData,
    data,
    dtoType,

    computedCombinedFilters,
    endpoint,
    handleUpdatingItemsPerPage,
    setSortFilters,
    handleSetCustomFiltersValueFilters,
    unhidableColumns,
    overridingColumnOrderOnReset,
    setColumnVisibility,
    fieldsThatAreCustomTypes,
    exportEnumProcessor,
    enumProperties,
    printProcessor,
    getAsyncSelectGetMethodFromPropertyName,
    filterKeyArrayByExcludedColumns,

    selectedUserFilterNames,
    selectedGlobalFilterNames,
    computedSelectedGlobalFilters,
    computedSelectedUserFilters,
    customFilters,
    unselectGlobalFilter,
    unselectUserFilter,

    ignoreFilter,
    unignoreFilter,
    isFilterIgnored,
    combinedFilterTargetProperties,
    updateSearchTerm,
    globalFilterStorage,
    userFilterStorage,
    isLoading,
    setIsLoading,
}: QueriedTableToolbarProps<T>) => {
    const [showGlobalUserFilterModal, setShowGlobalUserFilterModal] = useState<boolean>(false);
    const updateShowGlobalUserFilterModal = (value: boolean) => {
        setShowGlobalUserFilterModal(value);
    };

    const roles = useContext(RoleContext);

    const [getAllExport, setGetAllExport] = useState<boolean>(false);
    const [exportBasedOffOfItemsPerPage, setExportBasedOffOfItemsPerPage] = useState<boolean>(false);

    const [adding, setAdding] = useState<boolean>(false);

    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

    // const [jsonDataState, setJsonDataState] = useState<unknown[] | null>(null);

    // const [processedEntityChangesFromBackend, setProcessedEntityChangesFromBackend] = useState<string>();

    // const [showConfirmImportAlert, setShowConfirmImportAlert] = useState<boolean>(false);

    const closeModal = () => {
        setShowFilterModal(false);
    };

    const handleManageCustomFiltersClick = () => {
        setShowFilterModal(true);
    };

    const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);

        //@ts-ignore
        const file = e.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = read(data);

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const listOfCols = Object.keys(worksheet)
            .reduce<string[]>((accumulator, x) => {
                const match = x.match(/^[A-Z]*1$/);

                if (isNullOrUndefined(match)) {
                    // ignore from returned list
                    // accomplish this by simply returning 'accumulator' without adding x to it.
                    return accumulator;
                }

                // I think if it matches and isn't null that means it must be something like 'A1' or 'B1' so I
                // will add the key and return the updated accumuatlor array.

                const columnName = worksheet[x].v;

                accumulator.push(columnName);

                return accumulator;
            }, [])
            .sort();

        const t = worksheet['!cols'];

        const p = worksheet['!rows'];

        const jsonData = utils.sheet_to_json(worksheet, { rawNumbers: false });

        const test = jsonData.map((jsonP) => new ImportSheetDto(jsonP as ImportSheetDto));

        if (jsonData.length <= 0 || test.length <= 0) {
            makeSimpleToast('Error!', 'No data from spreadsheet!');
            return;
        }

        const importSheetDtoKeys = Object.keys(test[0]);

        const missingColumns: string[] = [];

        const missingSomeKeys = importSheetDtoKeys.reduce<boolean>((accu, item) => {
            const target = listOfCols.find((n) => n.toLowerCase() === item.toLowerCase());

            const missingKey = target === undefined;

            if (missingKey) {
                missingColumns.push(item);
            }

            if (accu === true) {
                // If we've found a missing key just keep the result passed through.
                return true;
            }

            return missingKey;
        }, false);

        if (missingSomeKeys) {
            makeSimpleToast(
                'Error!',
                'You need to make sure all columns are specified in your import sheet!\nMissing Columns:\n' +
                    missingColumns.join(', ')
            );

            setIsLoading(false);

            const t = importFileInputRef.current;

            if (isNullOrUndefined(t)) {
                console.error('Import Input is not defined');
                return;
            }
            t.value = '';
            return;
        }
        // const test = jsonData as ImportSheetDto[];
        const stuff = new ImportDto(test);

        console.log('');

        e.target.value = '';

        await InventoryService.ImportInventory(stuff)
            .then((processeedChanges) => {
                // handleEndpointCall({ endpoint, generalOptions });
                const t = importFileInputRef.current;

                setIsLoading(false);

                if (isNullOrUndefined(t)) {
                    console.error('Import Input is not defined');
                    return;
                }
                t.value = '';
            })
            .catch((err: any) => {
                console.error(err);
                alert(`Error while attepmting to import: ${JSON.stringify(err)}`);

                setIsLoading(false);
            });
    };

    const exportLoadingSpinnerShown = useRef<boolean>(false);

    const addLoadingSpinnerDomElement = useCallback(() => {
        const div = document.createElement('div');
        div.style.position = 'fixed';
        div.style.top = '0';
        div.style.right = '0';
        div.style.bottom = '0';
        div.style.left = '0';
        div.style.visibility = exportLoadingSpinnerShown ? 'visible' : 'hidden';
        div.style.backgroundColor = 'red';
        div.style.zIndex = '999999';

        document.body.appendChild(div);
    }, [exportLoadingSpinnerShown]);

    // Perhaps have a loading container tha tpasses props down like in the video I watched. ANd then down here we can provide the Loader with information

    // useEffect(() => {
    //     console.log('CHANGED TACO!');
    //     addLoadingSpinnerDomElement();
    // }, [exportLoadingSpinnerShown]);

    const [loadingOverlayVisible, setLoadingOverlayVisible] = useState<boolean>(false);

    const [loadingOverlayProgressText, setLoadingOverlayProgressText] = useState<string | undefined>(undefined);

    const handleExport = () => {
        let filter = new GeneralOptions();

        // addLoadingSpinnerDomElement();
        setLoadingOverlayProgressText('Requesting records... ');
        setLoadingOverlayVisible(true);

        if (!getAllExport) {
            const options = cloneDeep(computedCombinedFilters);

            options.filterType = FilterType.Custom;
            options.filterName = '';

            filter = convertStringArrayGeneralOptionsToNormal(options);
        }

        let exportFunction: ExportFunctionType;

        switch (recordType) {
            case RecordType.Inventory:
                exportFunction = InventoryService.ExportInventory;
                break;
            case RecordType.SalesOrder:
                exportFunction = SalesOrderService.exportSalesOrder;
                break;
            case RecordType.Delivery:
                exportFunction = DeliveryService.ExportDelivery;
                break;
            case RecordType.YardMap:
                exportFunction = YardMapService.ExportYardMap;
                break;
            default:
                exportFunction = InventoryService.ExportInventory;
                break;
        }

        const t = exportFunction(new ImportOptions(filter, getAllExport, exportBasedOffOfItemsPerPage))
            .then(({ data }) => {
                setLoadingOverlayProgressText('Processing records... ');
                setTimeout(() => {
                    const dataWithoutProperties = data.model.model.map(exportEnumProcessor).map((invObj) => {
                        const t: any = {};

                        computedCombinedFilters.orderedColumnsToShow
                            // .map((columnName) => columnName.trim())
                            .forEach((columnName) => (t[columnName] = invObj[columnName as keyof T] ?? ''));
                        return t;
                    });

                    setLoadingOverlayProgressText('Downloading... ');
                    setTimeout(() => {
                        createExcelReport(dataWithoutProperties, `TestExport`);
                        setLoadingOverlayVisible(false);
                    }, 0);
                }, 0);
            })
            .catch((err) => {
                setLoadingOverlayProgressText('');
                setLoadingOverlayVisible(false);
                console.error(err);
            });
    };
    const fetchImportSheet = () => {
        InventoryService.GetImportSheet()
            .then(({ data }) => {
                createExcelReport(data, 'TestInventoryImportSheet');
            })
            .catch((err) => {
                if (err?.response?.status === 403) {
                    makeSimpleToast('Unauthorized', 'You do not have the requird role to perform this action.');
                }
                console.error(err);
            });
    };

    const handleToolBarSubmit = useCallback(
        (e?: React.FormEvent<HTMLFormElement>) => {
            if (isNotNullNorUndefined(e)) {
                e.preventDefault();
            }

            const currentValue = ionSearchBar.current?.value;

            if (isNullOrUndefined(currentValue)) {
                alert('No value submitted on searchBar');
                return;
            }

            updateSearchTerm(currentValue);
        },
        [updateSearchTerm]
    );

    const ionSearchBar = useRef<HTMLIonSearchbarElement | null>(null);

    useEffect(() => {
        if (isNullOrUndefined(ionSearchBar.current)) {
            console.warn('ion search bar is null or undefined');
            return;
        }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Tab' || e.key === 'Enter') {
                e.preventDefault();
                handleToolBarSubmit();

                setIsLoading(true);
            }
        };

        ionSearchBar.current.addEventListener('keydown', handleKeyDown);

        const ionSearchBarValue = ionSearchBar.current;

        return () => {
            ionSearchBarValue.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleToolBarSubmit, setIsLoading]);

    let zIndexCountdown = 100;

    const importFileInputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {/* <QueriedTableToolbarSearchBar zIndexToSetWhenFocused={999} /> */}
            {/* <form onSubmit={(e) => handleToolBarSubmit(e)}> */}

            <IonSearchbar
                ref={ionSearchBar}
                // onIonChange={(e) => {
                //     if (isNullOrUndefined(e.detail.value)) {
                //         // If it's null ignore.

                //         return;
                //     }

                //     updateSearchTerm(e.detail.value);
                // }}
                value={customFilters.searchTerm}
                // onIonChange={(e) => handleIonSearchBarUpdate(e)}
                inputMode="text"
                type="text"
            />

            <IonLoading isOpen={isLoading} message="Loading..." />
            {/* <IonButton onClick={() => setIsLoading(true)}>Activate Loading</IonButton> */}
            {/* </form> */}
            <IonRow
                data-testid="queried-table-toolbar"
                // className={styles.toolbarContainer}
            >
                <IonCol style={{ position: 'relative', zIndex: zIndexCountdown-- }}>
                    <GeneralToolTip
                        content={[
                            'This button resets all custom filters and custom sorting.',
                            'Also grabs records with no filters',
                            'Does not delete user filters',
                        ]}>
                        <IonButton
                            onClick={() => {
                                handleClear();
                            }}>
                            Reset<IonIcon icon={informationCircleOutline} style={{ color: 'white' }}></IonIcon>
                        </IonButton>
                    </GeneralToolTip>
                </IonCol>
                <IonCol
                    // className={styles.button_container} data-testid="queried-table-toolbar-add-new-button-container"
                    style={{ position: 'relative', zIndex: zIndexCountdown-- }}>
                    <GeneralToolTip
                        content={[
                            'Create temporary filters that only show resluts that match certain criteria you choose.',
                            'Unlimitied filters.',
                            'Control sorting and priority of columns.',
                            'These Filters are removed when you leave the page.',
                            'If you would like to save a filter or group of filters',
                            'click this button and choose the options you want.',
                            'and then hover over the Create User Filter button.',
                        ]}>
                        <div
                            //  style={{border: '10rem solid red' }}
                            style={
                                customFilters.filterOptions.length > 0
                                    ? {
                                          backgroundImage: 'linear-gradient(red,#002b70)',
                                          paddingLeft: '.25rem',
                                          paddingRight: '.25rem',
                                          paddingBottom: '.25rem',

                                          paddingTop: '.25rem',
                                          display: 'flex',
                                      }
                                    : {}
                            }>
                            <IonButton
                                size="small"
                                className={`${styles.button} ${styles.addNewButton}`}
                                // base #44c898
                                color="add-new-button"
                                onClick={() => handleManageCustomFiltersClick()}>
                                <IonIcon
                                    slot="end"
                                    style={{ color: 'white' }}
                                    icon={informationCircleOutline}></IonIcon>
                                Manage Custom Filters
                            </IonButton>
                        </div>
                    </GeneralToolTip>
                </IonCol>
                <IonCol style={{ position: 'relative', zIndex: zIndexCountdown-- }}>
                    <IonItem>
                        <IonLabel>Items Per Page</IonLabel>
                        <IonSelect
                            value={computedCombinedFilters.sortOptions.itemsPerPage}
                            onIonChange={(e) => handleUpdatingItemsPerPage(e.detail.value)}>
                            <IonSelectOption value={10}>10</IonSelectOption>
                            <IonSelectOption value={25}>25</IonSelectOption>
                            <IonSelectOption value={50}>50</IonSelectOption>
                            <IonSelectOption value={100}>100</IonSelectOption>
                            <IonSelectOption value={250}>250</IonSelectOption>
                            <IonSelectOption value={500}>500</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                </IonCol>
                {isNotNullNorUndefined(data) &&
                    (() => {
                        return (
                            <IonCol>
                                <PrintQueriedTable<T>
                                    data={data}
                                    identifierProperty={
                                        getIdentifierPropertyFromDtoType(dtoType) as keyof AllPossibleReturnTypes
                                    }
                                    triggerJSX={<IonButton onClick={() => alert('Test')}>Print</IonButton>}
                                    columnsToShow={
                                        computedCombinedFilters.orderedColumnsToShow as (keyof AllPossibleReturnTypes)[]
                                    }
                                    exportEnumProcessor={exportEnumProcessor}
                                    enumProperties={enumProperties}
                                    printProcessor={printProcessor}
                                />
                            </IonCol>
                        );
                    })()}
                {recordType !== RecordType.RecordOfTransfer && recordType !== RecordType.AccountingTodo && (
                    <IonCol style={{ position: 'relative', zIndex: zIndexCountdown-- }}>
                        <GeneralToolTip
                            content={'Downloads a .xlsx file of all records matching the current filter options.'}>
                            <IonButton
                                size="small"
                                onClick={handleExport}
                                className={styles.button}
                                data-testid="queried-table-toolbar-export-import-button"
                                color="export-button">
                                <IonIcon
                                    icon={informationCircleOutline}
                                    slot="end"
                                    style={{ color: 'white' }}></IonIcon>
                                Export
                            </IonButton>
                        </GeneralToolTip>
                    </IonCol>
                )}
                {recordType === RecordType.Inventory && canUserAccessAdminSecurityWithGivenRoles(roles) && (
                    <>
                        <IonCol
                            style={{
                                position: 'relative',
                                zIndex: zIndexCountdown--,
                                flexDirection: 'column',
                            }}>
                            <div className={styles.test}>
                                <GeneralToolTip content={'Uploads and processes a populated .xlsx Import Sheet.'}>
                                    <IonLabel>
                                        Import File
                                        <IonIcon icon={informationCircleOutline}></IonIcon>
                                    </IonLabel>
                                </GeneralToolTip>
                                <GeneralToolTip content={'Uploads and processes a populated .xlsx Import Sheet.'}>
                                    <input ref={importFileInputRef} type="file" onChange={(e) => handleFile(e)} />
                                </GeneralToolTip>
                            </div>
                        </IonCol>
                    </>
                )}

                {/*Disable for now because Parker wanted me to wait on adding columns show functionality to the manage custom filters modal */}
                {false && (
                    <IonCol style={{ position: 'relative', zIndex: zIndexCountdown-- }}>
                        <GeneralToolTip
                            content={[
                                'This button removes all stored options.',
                                'New Defaults will be used on page refresh',
                            ]}>
                            <IonButton
                                onClick={() => {
                                    const confirmationMessage = `Are you sure you really want to reset all stored settings?\n\nYou will keep your saved filters, but any currently applied filters not saved will be removed`;
                                    const reallyWantsToDeleteLocalStorageItems = window.confirm(confirmationMessage);
                                    if (!reallyWantsToDeleteLocalStorageItems) {
                                        return;
                                    }
                                }}>
                                Reset Stored Settings
                                <IonIcon icon={informationCircleOutline} style={{ color: 'white' }}></IonIcon>
                            </IonButton>
                        </GeneralToolTip>
                    </IonCol>
                )}
            </IonRow>
            <div style={{ position: 'relative', zIndex: 9999999 }}>
                <FilterSortControlModal<T>
                    setColumnVisibility={setColumnVisibility}
                    setShowFilterModal={setShowFilterModal}
                    showFilterModal={showFilterModal}
                    handleSetCustomFiltersValueFilters={handleSetCustomFiltersValueFilters}
                    model={model}
                    closeModal={closeModal}
                    setSortFilters={setSortFilters}
                    handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
                    handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
                    handleDeleteUserFilter={handleDeleteUserFilter}
                    handleDeleteGlobalFilter={handleDeleteGlobalFilter}
                    dtoType={dtoType}
                    computedCombinedFilters={computedCombinedFilters}
                    unhidableColumns={unhidableColumns}
                    fieldsThatAreCustomTypes={fieldsThatAreCustomTypes}
                    data={data}
                    getAsyncSelectGetMethodFromPropertyName={getAsyncSelectGetMethodFromPropertyName}
                    filterKeyArrayByExcludedColumns={filterKeyArrayByExcludedColumns}
                    selectedUserFilterNames={selectedUserFilterNames}
                    selectedGlobalFilterNames={selectedGlobalFilterNames}
                    computedSelectedGlobalFilters={computedSelectedGlobalFilters}
                    computedSelectedUserFilters={computedSelectedUserFilters}
                    customFilters={customFilters}
                    unselectGlobalFilter={unselectGlobalFilter}
                    unselectUserFilter={unselectUserFilter}
                    ignoreFilter={ignoreFilter}
                    unignoreFilter={unignoreFilter}
                    isFilterIgnored={isFilterIgnored}
                    combinedFilterTargetProperties={combinedFilterTargetProperties}
                    globalFilterStorage={globalFilterStorage}
                    userFilterStorage={userFilterStorage}
                    setIsLoading={setIsLoading}
                />
            </div>
            <LoaderOverlay isVisible={loadingOverlayVisible} progressText={loadingOverlayProgressText} />
        </>
    );
};

export default QueriedTableToolbar;
