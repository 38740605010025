import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    // clientId: 'client-id-test-string',
    authority: process.env.REACT_APP_AUTHORITY as string,
    redirectUri: (() => {
      console.log("Redirect URL:");
      console.log(process.env.REACT_APP_REDIRECT_URL as string);
      return process.env.REACT_APP_REDIRECT_URL as string;
    })(),
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
// 755b9df7-232b-4206-adf5-c04912e3dab1
// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [process.env.REACT_APP_DEFAULT_SCOPES_URL as string],
};

export const TENANT_ID = process.env.REACT_APP_TENANT_ID as string;
