enum DateEnum {
  'Last Month',
  'Last 3 Months',
  'Last 6 Months',
  'Last 9 Months',
  'Last Year',
  'Last 2 Years',
}

export type DateEnumUnion = keyof typeof DateEnum;

export default DateEnum;
