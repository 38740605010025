import { InventoryCondition } from '../Global';

export class ReturnAccountingToDoDto {
    Select = '';
    'Invoice Number' = '';
    Stock = '';
    'Customer Number' = '';
    'Customer Name' = '';
    'Sales Order Notes' = '';
    'Delivery Notes' = '';
    'Inventory Notes' = '';
    'VIN' = '';
    // Date \/
    'Title Work Completed' = '';
    // Date \/
    'Delivered Date' = '';
    'Sales Person' = '';
    Make = '';
    'Inventory Type' = '';
    'Inventory Condition': InventoryCondition;
    Complete: '';
    'Split Deal - I': boolean;
    'Split Deal - S': boolean;

    constructor(obj?: ReturnAccountingToDoDto) {
        this.Select = obj?.Select ?? '';
        this['Invoice Number'] = obj?.['Invoice Number'] ?? '';
        this.Stock = obj?.Stock ?? '';
        this['Customer Number'] = obj?.['Customer Number'] ?? '';
        this['Customer Name'] = obj?.['Customer Name'] ?? '';
        this['Sales Order Notes'] = obj?.['Sales Order Notes'] ?? '';
        this['Title Work Completed'] = obj?.['Title Work Completed'] ?? '';
        this['Delivered Date'] = obj?.['Delivered Date'] ?? '';
        this['Sales Person'] = obj?.['Sales Person'] ?? '';
        this['Inventory Type'] = obj?.['Inventory Type'] ?? '';
        this['Inventory Condition'] = obj?.['Inventory Condition'] ?? InventoryCondition.New;

        this['Delivery Notes'] = obj?.['Delivery Notes'] ?? '';
        this.VIN = obj?.VIN ?? '';
        this['Inventory Notes'] = obj?.['Inventory Notes'] ?? '';
        this['Split Deal - I'] = obj?.['Split Deal - I'] ?? false;
        this['Split Deal - S'] = obj?.['Split Deal - S'] ?? false;
    }
}

export const AllAccountingTodoKeysArray = [
    'Select',
    'Complete',
    'Invoice Number',
    'Stock',
    'Customer Number',
    'Customer Name',
    'Sales Person',

    'Split Deal - I',
    'Split Deal - S',

    'Title Work Completed',

    'Delivered Date',

    'Inventory Condition',

    'Inventory Type',

    'Make',
    'Sales Order Notes',
    'Delivery Notes',
    'Inventory Notes',
    'VIN',
];
