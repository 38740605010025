import YardMapTable from '../../components/NewTableApproach/YardMapTable/YardMapTable';
import QueriedTableSortFilterStateManager, {
    QueriedTableSortFilterStateManagerExportProps,
} from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { GeneralOptions } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { SortOptions } from '../../models/Dtos/OptionsDtos/SortOptions';
import { YardMapDto } from '../../models/Dtos/YardMapDtos/YardMapDto';
import { DtoType, RecordType } from '../../models/Global';
import { YardMapService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { EndpointResponseType } from '../../utils/RecordTypeUtils';

interface YardMapQueriedTableProps {
    scrollTop: number;
}

export const YardMapQueriedTable = ({ scrollTop }: YardMapQueriedTableProps) => {
    return (
        <QueriedTableSortFilterStateManager<YardMapDto>
            scrollTop={scrollTop}
            identifier={'Yard Stall'}
            identifierType={IdentifierType.YardStall}
            toolbar={true}
            endpoint={
                YardMapService.getYardLocations as (
                    options: GeneralOptions | SortOptions
                ) => Promise<EndpointResponseType>
            }
            tabbed={true}
            justSortOptions={false}
            model={new YardMapDto()}
            recordType={RecordType.YardMap}
            dtoType={DtoType.YardMap}>
            {(exportProps: QueriedTableSortFilterStateManagerExportProps) => <YardMapTable {...exportProps} />}
        </QueriedTableSortFilterStateManager>
    );
};
