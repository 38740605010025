import { isNullOrUndefined } from '../utils/NullOrUndefined';
import { DataType } from './Global';

export enum ComparisonOperatorEnum {
    Equal,
    NotEqual,
    GreaterThan,
    GreaterThanOrEqual,
    LessThan,
    LessThanOrEqual,
    DuringTheCurrent,
    DuringThePrevious,
    DuringTheNext,
    BeforeThePrevious,
    AfterTheNext,
    Before,
    After,
    Today,
    TodayOrBefore,
    TodayOrAfter,
    BeforeToday,
    AfterToday,
    BeforeCurrentTime,
    AfterCurrentTime,
    IsEmpty,
    IsNotEmpty,
    Contains,
    DoesNotContain,
    StartsWith,
    EndsWith,
}

export enum ParameterizedCategory {
    NoParameters,
    DateTypeAndValue,
    Value, // Includes text and date values
    StringValue,
    DateType,
}

export interface ComparisonOperatorInfo {
    displayName: string;
    appliesToDataTypes: DataType[];
    parametizedCategory: ParameterizedCategory;
    enumValue: number;
}

type ComparisonOperatorObjectType = {
    [key in keyof typeof ComparisonOperatorEnum]: ComparisonOperatorInfo;
};

type ComparisonOperatorByEnumValueObjectType = {
    [enumValue: string]: ComparisonOperatorInfo & { name: string };
};

export const ComparisonOperator: ComparisonOperatorObjectType = {
    Equal: {
        displayName: 'Equal',
        appliesToDataTypes: [
            DataType.Bool,
            DataType.DateTime,
            DataType.Decimal,
            DataType.Enum,
            DataType.Int,
            DataType.String,
        ],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 0,
    },
    NotEqual: {
        displayName: 'Not Equal',
        appliesToDataTypes: [
            DataType.Bool,
            DataType.DateTime,
            DataType.Decimal,
            DataType.Enum,
            DataType.Int,
            DataType.String,
        ],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 1,
    },
    GreaterThan: {
        displayName: 'Greater Than',
        appliesToDataTypes: [DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 2,
    },
    GreaterThanOrEqual: {
        displayName: 'Greater Than Or Equal',
        appliesToDataTypes: [DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 3,
    },
    LessThan: {
        displayName: 'Less Than',
        appliesToDataTypes: [DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 4,
    },
    LessThanOrEqual: {
        displayName: 'Less Than Or Equal',
        appliesToDataTypes: [DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 5,
    },
    DuringTheCurrent: {
        displayName: 'During The Current',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateType,
        enumValue: 6,
    },
    DuringThePrevious: {
        displayName: 'During The Previous',

        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 7,
    },
    DuringTheNext: {
        displayName: 'During The Next',

        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 8,
    },
    BeforeThePrevious: {
        displayName: 'Before The Previous',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 9,
    },
    AfterTheNext: {
        displayName: 'After The Next',

        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 10,
    },
    Before: {
        displayName: 'Before',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 11,
    },
    After: {
        displayName: 'After',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 12,
    },
    Today: {
        displayName: 'Today',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 13,
    },
    TodayOrBefore: {
        displayName: 'Today Or Before',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 14,
    },
    TodayOrAfter: {
        displayName: 'Today Or After',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 15,
    },
    BeforeToday: {
        displayName: 'Before Today',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 16,
    },
    AfterToday: {
        displayName: 'After Today',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 17,
    },
    BeforeCurrentTime: {
        displayName: 'Before Current Time',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 18,
    },
    AfterCurrentTime: {
        displayName: 'After Current Time',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 19,
    },
    IsEmpty: {
        displayName: 'Is Empty',

        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int, DataType.String, DataType.Enum],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 20,
    },
    IsNotEmpty: {
        displayName: 'Is Not Empty',
        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int, DataType.String, DataType.Enum],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 21,
    },
    Contains: {
        displayName: 'Contains',
        appliesToDataTypes: [DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 22,
    },
    DoesNotContain: {
        displayName: 'Does Not Contain',

        appliesToDataTypes: [DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 23,
    },
    StartsWith: {
        displayName: 'Starts With',

        appliesToDataTypes: [DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 24,
    },
    EndsWith: {
        displayName: 'Ends With',
        appliesToDataTypes: [DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 25,
    },
};

export const ComparisonOperatorEnumValueBased: ComparisonOperatorByEnumValueObjectType = {
    '0': {
        name: 'Equal',
        displayName: 'Equal',
        appliesToDataTypes: [
            DataType.Bool,
            DataType.DateTime,
            DataType.Decimal,
            DataType.Enum,
            DataType.Int,
            DataType.String,
        ],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 0,
    },
    '1': {
        name: 'NotEqual',
        displayName: 'Not Equal',
        appliesToDataTypes: [
            DataType.Bool,
            DataType.DateTime,
            DataType.Decimal,
            DataType.Enum,
            DataType.Int,
            DataType.String,
        ],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 1,
    },
    '2': {
        name: 'GreaterThan',
        displayName: 'Greater Than',

        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 2,
    },
    '3': {
        name: 'GreaterThanOrEqual',

        displayName: 'Greater Than Or Equal',
        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 3,
    },
    '4': {
        name: 'LessThan',
        displayName: 'Less Than',
        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 4,
    },
    '5': {
        name: 'LessThanOrEqual',
        displayName: 'Less Than Or Equal',
        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 5,
    },
    '6': {
        name: 'DuringTheCurrent',
        displayName: 'During The Current',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateType,
        enumValue: 6,
    },
    '7': {
        name: 'DuringThePrevious',
        displayName: 'During The Previous',

        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 7,
    },
    '8': {
        name: 'DuringTheNext',
        displayName: 'During The Next',

        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 8,
    },
    '9': {
        name: 'BeforeThePrevious',
        displayName: 'Before The Previous',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 9,
    },
    '10': {
        name: 'AfterTheNext',
        displayName: 'After The Next',

        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.DateTypeAndValue,
        enumValue: 10,
    },
    '11': {
        name: 'Before',
        displayName: 'Before',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 11,
    },
    '12': {
        name: 'After',
        displayName: 'After',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.Value,
        enumValue: 12,
    },
    '13': {
        name: 'Today',
        displayName: 'Today',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 13,
    },
    '14': {
        name: 'TodayOrBefore',
        displayName: 'Today Or Before',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 14,
    },
    '15': {
        name: 'TodayOrAfter',
        displayName: 'Today Or After',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 15,
    },
    '16': {
        name: 'BeforeToday',
        displayName: 'Before Today',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 16,
    },
    '17': {
        name: 'AferToday',
        displayName: 'After Today',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 17,
    },
    '18': {
        name: 'BeforeCurrentTime',
        displayName: 'Before Current Time',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 18,
    },
    '19': {
        name: 'AfterCurrentTime',
        displayName: 'After Current Time',
        appliesToDataTypes: [DataType.DateTime],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 19,
    },
    '20': {
        name: 'IsEmpty',
        displayName: 'Is Empty',

        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int, DataType.String],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 20,
    },
    '21': {
        name: 'IsNotEmpty',
        displayName: 'Is Not Empty',
        appliesToDataTypes: [DataType.DateTime, DataType.Decimal, DataType.Int, DataType.String],
        parametizedCategory: ParameterizedCategory.NoParameters,
        enumValue: 21,
    },
    '22': {
        name: 'Contains',
        displayName: 'Contains',
        appliesToDataTypes: [DataType.Enum, DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 22,
    },
    '23': {
        name: 'DoesNotContain',
        displayName: 'Does Not Contain',

        appliesToDataTypes: [DataType.Enum, DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 23,
    },
    '24': {
        name: 'StartsWith',
        displayName: 'Starts With',

        appliesToDataTypes: [DataType.Enum, DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 24,
    },
    '25': {
        name: 'EndsWith',
        displayName: 'Ends With',
        appliesToDataTypes: [DataType.Enum, DataType.String],
        parametizedCategory: ParameterizedCategory.StringValue,
        enumValue: 25,
    },
};

export const getComparisonOpeartorFromEnumValue = (enumValue: number) => {
    return ComparisonOperatorEnumValueBased[enumValue + ''];
};

export const getAllComparisonOperatorInfosThatIncludeDataTypes = (dataType: DataType | undefined) => {
    if (isNullOrUndefined(dataType)) {
        return [];
    }
    const keys = Object.entries(ComparisonOperator)
        .filter(([key, { appliesToDataTypes }]) => {
            return appliesToDataTypes.includes(dataType);
        })
        .map(([key]) => key);

    return keys.map((key) => ComparisonOperator[key as keyof typeof ComparisonOperator]);
};
