import { EventMessage } from '@azure/msal-browser';
import React from 'react';
import styles from './MsalRedirectorErrorComponent.module.scss';

interface MsalRedirectorErrorComponentProps {
    errorMessage: EventMessage;
}

const MsalRedirectorErrorComponent = ({ errorMessage }: MsalRedirectorErrorComponentProps) => {
    return (
        <div className={styles.MsalRedirectorErrorComponentContainer} data-testid="MsalRedirectorErrorComponent">
            <h1>There has been an error logging in</h1>
            <h2>Please take a screen shot of this message and send it to developers:</h2>
            <pre>{JSON.stringify(errorMessage, undefined, 4)}</pre>
        </div>
    );
};

export default MsalRedirectorErrorComponent;
