import AuditDetail from './AuditDetail';

class AdminAuditDto {
    employee = '';

    changedDate = '';

    identifier = '';

    identifierName = '';

    details: AuditDetail[] = [];

    constructor(obj?: AdminAuditDto) {
        this.employee = obj?.employee ?? '';
        this.changedDate = obj?.changedDate ?? '';
        this.identifier = obj?.identifier ?? '';
        this.identifierName = obj?.identifierName ?? '';
        this.details = obj?.details ?? [];
    }
}

export default AdminAuditDto;
