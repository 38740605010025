import { IonContent, IonIcon, IonList, IonMenu } from '@ionic/react';
//<ion-icon name="chevron-down-circle-outline"></ion-icon>
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.module.css';
import styles from './SideNav.module.scss';

import inventory from '../../../icons/svg/inventory.svg'
import yardLocationIcon from "../../../icons/svg/yard_location.svg"

// Sales Orders
import salesOrderIcon from "../../../icons/svg/sales_order.svg"
import deliveryIcon from "../../../icons/svg/delivery.svg"
import accountingTodoIcon from "../../../icons/svg/task_alt_24dp_536920_FILL0_wght400_GRAD0_opsz24.svg"

import settingsPanelIcon from "../../../icons/svg/settings.svg"
import reportsIcon from "../../../icons/svg/reports.svg"
import howTosIcon from "../../../icons/svg/help.svg"

// Reports
import salesReportIcon from "../../../icons/svg/sales.svg";
import profitLossReportIcon from "../../../icons/svg/profitlossreport.svg"
import orderedDeliveredReportIcon from "../../../icons/svg/ordered-delivered-report.svg"

import giftCardIcon from "../../../icons/svg/gift_card.svg";


export const SideNav = () => {
  const [salesOrderDropDownVisible, setSalesOrderDropDownVisible] = useState<boolean>(false);

  const [reportsDropDownVisible, setReportsDropDownVisible] = useState<boolean>(false);

  return (
    <>
      <IonMenu
        contentId="main"
        type="overlay"
        menuId="main"
        menu-id="main"
        data-testid="side-nav"
        swipeGesture={false}
        className={`${styles.nonTablePart} nonTableParts ${styles.customStuff}`}>
        <IonContent className={styles.ionContent}>
          <div className={styles.menuContainer}>
            <h1 className={styles.menuHeader}>Menu</h1>
            <div className={styles.menuItems}>
              <IonList id="nav-list" className={styles.navList}>
                <div className={styles.navLinkDivContainer} >
                  <img src={inventory} alt="Inventory Icon" className={styles.navItemIcon} />
                  <NavLink
                    data-testid="inventory"
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    style={{}}
                    to={'/inventory'}>
                    Inventory
                  </NavLink>
                </div>
                <div className={styles.navLinkDivContainer} >
                  <img src={yardLocationIcon} alt="Yard Location Icon" className={styles.navItemIcon} />

                  <NavLink
                    data-testid="yard-location"
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    style={{}}
                    to={'/yard-location'}>
                    Yard Locations
                  </NavLink>
                </div>
                <div
                  className={`${styles.navLinkDivContainer}`} >
                  <img src={salesOrderIcon} alt="Sales Order Icon" className={styles.navItemIcon} />
                  <NavLink
                    data-testid="sales-order"
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    to={'/sales-order'}>
                    Sales Orders
                  </NavLink>
                  <a
                    href="#"
                    className={styles.iconContainer}
                    onClick={() => setSalesOrderDropDownVisible((visible) => !visible)}>
                    <IonIcon icon={chevronDownCircleOutline} className={`${styles.dropdownIcon}`} />
                  </a>
                </div>
                <div
                  // style={{ height: salesOrderDropDownVisible ? '' : '0' }}
                  className={`${styles.subLinkContainer} ${salesOrderDropDownVisible ? styles.visibleDropDown : styles.invisibleDropDown
                    }`}>
                  <div className={styles.subLinkItem} >
                    <img src={salesOrderIcon} alt="Sales Order Icon " className={styles.navItemIcon} />
                    <NavLink
                      className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline} `}
                      to={'/sales-order'}>
                      Sales Order
                    </NavLink>
                  </div>
                  <div className={styles.subLinkItem} >
                    <img src={deliveryIcon} alt="Delivery Icon" className={styles.navItemIcon} />
                    <NavLink
                      className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                      to={'/delivery'}>
                      Delivery
                    </NavLink>
                  </div>
                  <div className={styles.subLinkItem} >
                    <img src={accountingTodoIcon} alt="Accounting Todo Icon" className={styles.navItemIcon} />
                    <NavLink
                      className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                      to={'/accountingtodo'}>
                      Accounting Todo
                    </NavLink>
                  </div>
                </div>

                <div
                  className={`${styles.navLinkDivContainer} ${styles.salesOrderDropDownDivContainer}`} >
                  <img src={reportsIcon} alt="ReportsIcon" className={styles.navItemIcon} />
                  <NavLink
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    to={'/reports'}>
                    Reports
                  </NavLink>
                  <a
                    href="#"
                    className={styles.iconContainer}
                    onClick={() => setReportsDropDownVisible((visible) => !visible)}>
                    <IonIcon icon={chevronDownCircleOutline} className={`${styles.dropdownIcon}`} />
                  </a>
                </div>


                <div
                  className={`${styles.subLinkContainer} ${reportsDropDownVisible ? styles.visibleDropDown : styles.invisibleDropDown
                    }`}>
                  <div className={styles.subLinkItem} >
                    <img src={salesReportIcon} alt="Sales Report Icon" className={styles.navItemIcon} />
                    <NavLink
                      className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline} `}
                      to={'/reports/sales-reports'}>
                      Sales Reports
                    </NavLink>
                  </div>
                  <div className={styles.subLinkItem} >
                    <img src={profitLossReportIcon} alt="Profit Loss Report Icon" className={styles.navItemIcon} />
                    <NavLink
                      className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                      to={'/reports/profit-loss-reports'}>
                      Profit/Loss Reports
                    </NavLink>
                  </div>
                  <div className={styles.subLinkItem} >
                    <img src={orderedDeliveredReportIcon} alt="Ordered Delivered Report Icon" className={styles.navItemIcon} />
                    <NavLink
                      className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                      to={'/reports/ordered-delivered-reports'}>

                      Ordered/Delivered Reports
                    </NavLink>

                  </div>
                </div>

                <div className={`${styles.navLinkDivContainer}`} >
                  <img src={settingsPanelIcon} alt="Settings Panel Icon" className={styles.navItemIcon} />
                  <NavLink
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    to={'/settings-panel'}>
                    Settings Panel
                  </NavLink>
                </div>

                <div className={`${styles.navLinkDivContainer}`} >
                  <img src={howTosIcon} alt="How To's Icon" className={styles.navItemIcon} />
                  <a
                    // window.open('https://loom.com/share/folder/1c7f128840d142bbbf862770967fae6d', '_blank');
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    target="_blank"
                    href={'https://loom.com/share/folder/1c7f128840d142bbbf862770967fae6d'}>
                    How To's
                  </a>

                </div>

                <div className={`${styles.navLinkDivContainer}`} >
                  <img src={giftCardIcon} alt="Refer a friend and get a 100$ Amazon gift card" className={styles.navItemIcon} />
                  <a
                    // window.open('https://loom.com/share/folder/1c7f128840d142bbbf862770967fae6d', '_blank');
                    className={`${styles.button} ${styles.menuItem} ${styles.overwriteUnderline}`}
                    target="_blank"
                    href={'https://www.trailerlistpro.com/contact'}>
                    Referal $100 Amazon Gift Card
                  </a>

                </div>


              </IonList>
            </div>
          </div>
        </IonContent>
      </IonMenu >
    </>
  );
};
