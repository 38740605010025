import { IonIcon } from "@ionic/react";

import debounce from "debounce";
import {
  caretDownOutline,
  caretUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { TypeInteractionDto } from "../../../models/Dtos/TypeInteractionDto";
import { YardMapDto } from "../../../models/Dtos/YardMapDtos/YardMapDto";
import { DataType, RecordType } from "../../../models/Global";
import { DbManagedTypes } from "../../../pages/AdminPanel/components/TypeManager";
import { Layout } from "../../../pages/Layout";
import { TypeService, YardMapService } from "../../../utils/apiServices";
import {
  filterYardMapColumnMethod,
  filterYardMapKeyArrayByExcludedColumns,
  isYardMapPropertyExcluded,
} from "../../../utils/ColumnsToShowFilterByDtoType/YardMapColumnToShowFilters";
import confirmingTableUpdate from "../../../utils/ConfirmingTableUpdate";
import { convertAllNullToEmptyStringFromObject } from "../../../utils/ConvertAllNullToEmptyStringUtil";
import { convertFromStringIfBooleanOrNumber } from "../../../utils/DataTypeConversionFromString/DataTypeConversionFromString";
import { getDataTypeFromYardMapPropertyName } from "../../../utils/DataTypeMappersByDtoType/YardMapDtoMapper";
import { IdentifierType } from "../../../utils/identifierType";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../../../utils/NullOrUndefined";
import PageNameGenerator from "../../../utils/PageNameGenerator";
import { AllPossibleReturnTypes } from "../../../utils/RecordTypeUtils";
import CopyTableStylesButtons from "../../CopyTableStylesButtons/CopyTableStylesButtons";
import { ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID } from "../../Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer";
import QueriedTablePaginationOptions from "../../Theme/QueriedTable/QueriedTablePaginationOptions/QueriedTablePaginationOptions";
import {
  LocationType,
  QueriedTableSortFilterStateManagerExportProps,
} from "../../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import QueriedTableGlobalUserFilterList from "../../Theme/QueriedTable/QueriedTableTabList/QueriedTableGlobalUserFilterList";
import QueriedTableToolbar from "../../Theme/QueriedTable/QueriedTableToolbar/QueriedTableToolbar";
import {
  CH_OFFSET,
  LARGE_COLS_SIZE,
  NORMAL_COLS_SIZE,
} from "../GeneralReference";
import YardLocationStockModificationModal from "./YardLocationStockModificationModal/YardLocationStockModificationModal";
import styles from "./YardMapTable.module.scss";

type YardMapTableProps = QueriedTableSortFilterStateManagerExportProps;

const YardMapTable = ({
  endpoint,
  identifier,
  identifierType,
  handleSetCustomFiltersValueFilters,
  handleCreateUpdateUserFilter,
  handleUserFilterButtonClick,
  justSortOptions,
  overwritingProvidedData,
  model,
  useCustomEndpoint,
  setUseCustomEndpoint,
  recordType,
  handleClearCancel,
  isFilterSelected,
  handleCreateUpdateGlobalFilter,
  handleDeleteGlobalFilter,
  handleDeleteUserFilter,
  handleUpdatingItemsPerPage,
  setOverwritingProvidedData,
  setSortFilters,
  dtoType,
  tabbed,
  handleGlobalFilterButtonClick,
  data,
  setData,
  computedCombinedFilters,
  setItemsPerPage,
  DEBOUNCE_WAIT_TIME,
  handleChangeSorting,
  handleOrderColumnToLeft,
  handleOrderColumnToRight,
  updateCurrentPageNumber,
  setColumnVisibility,
  selectingRowMode,
  unselectAllRows,
  selectAllRows,
  cancelSelecting,
  stopSelectingAndCopyTableWithStyles,
  stopSelectingAndEmailAdvertisements,
  startSelecting,
  isRowIdSelected,
  unselectRow,
  registerSelection,
  userFiltersStorage,
  globalFiltersStorage,
  selectedGlobalFilterNames,
  selectedUserFilterNames,
  computedSelectedGlobalFilters,
  computedSelectedUserFilters,
  customFilters,
  unselectGlobalFilter,
  unselectUserFilter,
  ignoreFilter,
  unignoreFilter,
  isFilterIgnored,
  combinedFilterTargetProperties,
  updateSearchTerm,
  handleNewUpdaterFunction,
  ignoredFilterIds,
  callEndpointAgain,
  isLoading,
  setIsLoading,
}: YardMapTableProps) => {
  const history = useHistory();

  const YARD_MAP_PAGE_TITLE = PageNameGenerator("Yard Map");

  useEffect(() => {
    if (document.title !== YARD_MAP_PAGE_TITLE)
      document.title = YARD_MAP_PAGE_TITLE;
  }, []);

  const generateTableHeaders = useCallback(
    (dto: YardMapDto) => {
      if (isNullOrUndefined(dto)) {
        return null;
      }

      let headers: React.ReactElement[];
      const getSortForColumn = (property: string) => {
        const targetSort = computedCombinedFilters.sortOptions.sortBy.find(
          (item) => item.propertyName === property
        );

        if (isNullOrUndefined(targetSort)) {
          return undefined;
        }

        return targetSort.isAscending;
      };

      const generateThMapMethod = (property: string, index: number) => (
        <th key={property}>
          <div className={styles.tableHeaderButtonWrapper}>
            <span className={styles.headerSpanContainer}>
              <div style={{ display: "block" }}>
                <button
                  onClick={(e) => handleChangeSorting(property)}
                  className={styles.headerButton}
                >
                  {property}
                  {isNullOrUndefined(getSortForColumn(property)) ? (
                    <></>
                  ) : getSortForColumn(property) ? (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretUpOutline}
                    />
                  ) : (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretDownOutline}
                    />
                  )}
                </button>
              </div>
              {index > 0 && (
                <button
                  onClick={(e) =>
                    handleOrderColumnToLeft(
                      property as keyof AllPossibleReturnTypes
                    )
                  }
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.leftColumnButton}`}
                    icon={chevronBackOutline}
                  />
                </button>
              )}
              {index <
                computedCombinedFilters.orderedColumnsToShow.length - 1 && (
                  <button
                    onClick={(e) =>
                      handleOrderColumnToRight(
                        property as keyof AllPossibleReturnTypes
                      )
                    }
                    className={`${styles.headerButton} ${styles.changeOrderButton}`}
                  >
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.rightColumnButton}`}
                      icon={chevronForwardOutline}
                    />
                  </button>
                )}
            </span>
          </div>
        </th>
      );

      const selectionColumn = (
        <th
          className={
            selectingRowMode === "unselected"
              ? styles.selectionColumnHidden
              : ""
          }
          style={{ backgroundColor: "white" }}
          key="selection-column"
        >
          To Copy
        </th>
      );

      if (
        isNullOrUndefined(computedCombinedFilters) ||
        !Array.isArray(computedCombinedFilters.orderedColumnsToShow) ||
        computedCombinedFilters.orderedColumnsToShow[0] === ""
      ) {
        // Get keys  of first entry to get headers.
        headers = [
          selectionColumn,
          ...Object.keys(dto)
            .filter(filterYardMapColumnMethod)
            .map(generateThMapMethod),
        ];
      } else {
        // Use columnsToShow from savedGeneratedGeneralOptions to get headers.

        // REAPLCE HERE WITH STUF

        headers = [
          selectionColumn,
          ...computedCombinedFilters.orderedColumnsToShow
            .filter(filterYardMapColumnMethod)
            .map(generateThMapMethod),
        ];
      }
      return headers;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      selectingRowMode,
      computedCombinedFilters,
      handleChangeSorting,
      handleOrderColumnToLeft,
      handleOrderColumnToRight,
    ]
  );

  const handleGenerateTableParts = () => {
    let tableHead: React.ReactElement | null = null;

    const rows = (data as YardMapDto[]).map((dto, index) => {
      // Doing it this way to limit the amount of iteration done
      let headers: React.ReactElement[] | null = null;
      if (index === 0) {
        headers = generateTableHeaders(dto);
        tableHead = (
          <thead className={styles.tHead}>
            <tr>{headers}</tr>
          </thead>
        );
      }

      return (
        <tr
          key={dto["Yard Stall"]}
          className={`${index % 2 === 0 ? styles.highlighted : styles.notHighlighted
            } ${styles.tRow}`}
        >
          {runner(dto)}
          {/* <InventoryTableRow
                        dtoData={dto}
                        orderedColumnsToShow={
                            savedGeneratedGeneralOptions.current?.columnsToShow as (keyof InventoryDto)[] | undefined
                        }
                    /> */}
        </tr>
      );
    });

    return (
      <>
        {tableHead}
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleInputUpdateValueProvider = (
    event: ChangeEvent<HTMLInputElement>,
    columnName: keyof YardMapDto,
    stock: string
  ) => {
    const isCheckbox = event.target.type === "checkbox";
    const value = isCheckbox ? event.target.checked : event.target.value;

    if (isCheckbox) {
      handleUnDebouncedUpdate(value, event, columnName, stock);
    } else {
      handleInputUpdate(value, event, columnName, stock);
    }
  };

  const handleTextareaUpdateValueProvider = (
    event: ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof YardMapDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleInputUpdate(value, event, columnName, stock);
  };

  const handleSelectOrTextAreaUpdateValueProvider = (
    event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof YardMapDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleUnDebouncedUpdate(value, event, columnName, stock);
  };

  const getTargetRowOrConsoleError = (stock: string): [number, YardMapDto] => {
    const targetRowIndex = (data as YardMapDto[]).findIndex(
      (dto) => dto["Yard Stall"] === stock
    );
    if (targetRowIndex === -1) {
      const errorMessage =
        "Target row of invoice number" + stock + " can't be found!!";

      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    const targetRow = data[targetRowIndex] as YardMapDto;
    return [targetRowIndex, targetRow];
  };

  const handleInputUpdate = debounce(
    async (
      value: string | boolean,
      event:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>,
      columnName: keyof YardMapDto,
      stock: string
    ) => {
      if (!confirmingTableUpdate(event)) {
        return;
      }
      const isCheckbox = event.target.type === "checkbox";

      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

      const copiedTargetRow = { ...targetRow };

      const convertedValue = isCheckbox
        ? value
        : convertFromStringIfBooleanOrNumber(
          value as string,
          getDataTypeFromYardMapPropertyName(columnName as keyof YardMapDto)
        );

      //@ts-ignore
      copiedTargetRow[columnName] = convertedValue;

      // prop = ;

      // Is debounce 1000ms

      const response = await YardMapService.updateMap(
        convertAllNullToEmptyStringFromObject(copiedTargetRow)
      );

      if (response.data.isSuccessful) {
        setData((oldData) => {
          const toUpdate = [...oldData];
          toUpdate.splice(targetRowIndex, 1, response.data.model);
          return toUpdate;
        });
      } else {
        setData((oldData) => oldData);
      }
    },
    DEBOUNCE_WAIT_TIME
  );

  const [resetFieldsToggle, setResetFieldsToggle] = useState<boolean>(false);

  const handleUnDebouncedUpdate = async (
    value: string | boolean,
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof YardMapDto,
    stock: string
  ) => {
    if (!confirmingTableUpdate(event)) {
      return;
    }
    const isCheckbox = event.target.type === "checkbox";

    const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

    const copiedTargetRow = { ...targetRow };

    const convertedValue = isCheckbox
      ? value
      : convertFromStringIfBooleanOrNumber(
        value as string,
        getDataTypeFromYardMapPropertyName(columnName as keyof YardMapDto)
      );

    //@ts-ignore
    copiedTargetRow[columnName] = convertedValue;

    // prop = ;

    // Is debounce 1000ms

    const response = await YardMapService.updateMap(
      convertAllNullToEmptyStringFromObject(copiedTargetRow)
    );

    if (response.data.isSuccessful) {
      setData((oldData) => {
        const toUpdate = [...oldData];
        toUpdate.splice(targetRowIndex, 1, response.data.model);
        return toUpdate;
      });
    } else {
      setData((oldData) => oldData);
    }
  };

  const convertDefaultDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  const convertDefaultCheckbox = (checkboxStringValue: string | boolean) =>
    typeof checkboxStringValue === "boolean"
      ? checkboxStringValue
      : checkboxStringValue.toLowerCase() === "true";

  type testtest = { label: string; value: string };
  type OptionType = {
    label: string;
    value: string;
    title: string;
  };

  const [typeTypes, setTypeTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    const handleUpdatingType = async (
      dbManagedType: DbManagedTypes,
      updateFunc: (types: OptionType[]) => unknown
    ) => {
      const typeInteractionDto: TypeInteractionDto = {
        originalName: "",
        newName: "",
        dbManagedType: dbManagedType,
      };

      const response = await TypeService.GetNameList(typeInteractionDto);

      const types = response.data.map((type) => ({
        label: type,
        value: type,
        title: type,
      }));

      updateFunc(types);
    };

    handleUpdatingType(DbManagedTypes.InventoryType, setTypeTypes);
  }, []);

  const handleUpdateType = async (
    value: string | undefined,
    column: keyof YardMapDto,
    dbManagedType: DbManagedTypes,
    stock: string
  ) => {
    if (isNullOrUndefined(value)) {
      return;
    }

    const [, targetDto] = getTargetRowOrConsoleError(stock);

    const copiedTargetDto = { ...targetDto };

    //@ts-ignore
    copiedTargetDto[column] = value;

    const result = await YardMapService.updateMap(
      convertAllNullToEmptyStringFromObject(copiedTargetDto)
    );

    if (result.data.isSuccessful) {
      setData((oldData) => {
        const targetIndex = oldData.findIndex(
          (dto) => (dto as YardMapDto)["Yard Stall"] === stock
        );
        if (targetIndex === -1) {
          return oldData;
        }

        const duplicate = [...oldData];
        duplicate.splice(targetIndex, 1, result.data.model);
        return duplicate;
      });
    } else {
      setData((oldData) => oldData);
    }
  };

  const tableDivContainerRef = useRef<HTMLDivElement>(null);

  const INPUT_CLASS_NAME = "inventory-table-input-class-name";

  // useEffect(() => {
  //     if (isNullOrUndefined(tableDivContainerRef.current)) {
  //         console.log('table div element is undefined');
  //         return;
  //     }

  //     const tableDiv = tableDivContainerRef.current;

  //     Array.from(tableDiv.getElementsByClassName(INPUT_CLASS_NAME)).forEach((inputEl) => {
  //         // not null bc of filter above.
  //         const notNullInput = inputEl as HTMLInputElement | HTMLSelectElement;
  //         const [invoiceNumber, name] = notNullInput.name.split(':');

  //         const target = (data as YardMapDto[]).find((dto) => dto['Yard Stall'] === invoiceNumber);

  //         if (isNullOrUndefined(target)) {
  //             const errorMessage = `Attempting to find row with invoice number ${invoiceNumber} but can't be found! Please alert web developers`;
  //             console.error(errorMessage);
  //             alert(errorMessage);
  //             return;
  //         }

  //         let value = target[name as keyof YardMapDto];
  //         if (isNullOrUndefined(value) || value === '0.00') {
  //             value = '';
  //         }

  //         const dataType = getDataTypeFromYardMapPropertyName(name as keyof YardMapDto);
  //         if (dataType === DataType.DateTime) {
  //             if (value === '') {
  //                 // Will look like dd/mm/yyyy. Want it to be empty. So we'll set color to transparent when not focused
  //                 (inputEl as HTMLInputElement).classList.add(styles.dateInputEmpty);
  //             } else {
  //                 (inputEl as HTMLInputElement).classList.remove(styles.dateInputEmpty);

  //                 value = convertDefaultDate(value as string);
  //             }
  //         }
  //         if (dataType === DataType.Bool) {
  //             value = convertDefaultCheckbox(value as string | boolean);
  //         }

  //         notNullInput.value = value + '';
  //     });
  // }, [data]);

  const [stockForCurrentlyOpenNoteModal, setStockForCurrentlyOpenNoteModal] =
    useState<string>();

  const [
    confirmationModalShownStockNumber,
    setConfirmationModalShownStockNumber,
  ] = useState<string>();

  const handleSelectOnChangeUpdatingRows = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    colSize: number
  ) => {
    const textArea = e.target as HTMLTextAreaElement;
    const value = e.target.value;

    const quotient = value.length / colSize;

    const rowsToSet = Math.floor(quotient) + 1;

    if (textArea.rows !== rowsToSet) {
      textArea.rows = rowsToSet;
    }
  };

  const stringifyNumberOption = 4;

  const handleCopyFilterDebugInfoToClipboard = () => {
    let stringToShow = "";

    const dictOfThingsToPrint = {
      "General Options": JSON.stringify(
        computedCombinedFilters,
        null,
        stringifyNumberOption
      ),
      "Global Filter Storage": JSON.stringify(
        globalFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "User Filter Storage": JSON.stringify(
        userFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "Selected Global Filter Names": JSON.stringify(
        selectedGlobalFilterNames,
        null,
        stringifyNumberOption
      ),
      "Selected  User Filter Names": JSON.stringify(
        selectedUserFilterNames,
        null,
        stringifyNumberOption
      ),
      "Computed Selected Global Filters": JSON.stringify(
        computedSelectedGlobalFilters,
        null,
        stringifyNumberOption
      ),
      "Computed Selected  User Filters": JSON.stringify(
        computedSelectedUserFilters,
        null,
        stringifyNumberOption
      ),
      "Local Stroage": JSON.stringify(
        { ...localStorage },
        null,
        stringifyNumberOption
      ),
    };

    Object.entries(dictOfThingsToPrint).forEach(([key, value]) => {
      stringToShow += "\n";
      stringToShow += `~~~~${key}~~~~\n`;
      stringToShow += value;
    });

    window.navigator.clipboard.writeText(stringToShow);
  };

  const savedFunctionsToUpdateData = useMemo(() => {
    return {
      "Yard Stall": (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        YardStall: string | number,
        stock: string
      ) => {
        return (
          <td
            className={styles.tData}
            // style={{ color: 'black', width: '8rem' }}
            key={`${stock}-${property}`}
            onMouseUp={(e) => {
              if (e.button === 1) {
                if ((e.target as Element).tagName === "A") {
                  // should be handled by the anchor tag itself
                  return;
                }
                e.preventDefault();
                const link = `/yard-location/edit/${IdentifierType.YardStall}/${YardStall}`;

                window.open(link, "_blank");
                e.stopPropagation();
                return;
              }
            }}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const link = `/yard-location/edit/${IdentifierType.YardStall}/${YardStall}`;

              if (e.ctrlKey) {
                window.open(link, "_blank");
                e.stopPropagation();
                return;
              }

              if (e.shiftKey) {
                window.open(link);
                e.stopPropagation();
                return;
              }

              history.push({
                pathname: `/yard-location/edit/${IdentifierType.YardStall}/${YardStall}`,

                state: {
                  selectedGlobalFilterNames,
                  selectedUserFilterNames,
                  globalFiltersStorage,
                  userFiltersStorage,
                  customFilters,
                  ignoredFilterIds,
                } as LocationType,
              });
            }}
          >
            <NavLink
              to={{
                pathname: `/yard-location/edit/${IdentifierType.YardStall}/${YardStall}`,

                state: {
                  selectedGlobalFilterNames,
                  selectedUserFilterNames,
                  globalFiltersStorage,
                  userFiltersStorage,
                  customFilters,
                  ignoredFilterIds,
                } as LocationType,
              }}
              style={{ margin: "0 1rem" }}
            >
              {YardStall}
            </NavLink>
          </td>
        );
      },
      "Stock #": (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        StockNumber: string | number,
        stock: string
      ) => {
        const isValueInvalid =
          isNullOrUndefined(StockNumber) || StockNumber === "";
        return (
          <td
            className={styles.tData}
            style={{ color: "black" }}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              setConfirmationModalShownStockNumber(stock);
            }}
          >
            <input
              style={{
                width: isValueInvalid
                  ? ""
                  : (StockNumber + "").length + CH_OFFSET + "ch",
              }}
              value={isValueInvalid ? "None" : StockNumber}
              className={`${styles.stockNumberInput}`}
              type="text"
              readOnly={true}
              onClick={() => {
                setConfirmationModalShownStockNumber(stock);
              }}
            />
            <YardLocationStockModificationModal
              isShown={confirmationModalShownStockNumber === stock}
              hideConfirmationModal={() =>
                setConfirmationModalShownStockNumber(undefined)
              }
              yardLocationStall={yardMapDto["Yard Stall"]}
              yardStallId={yardMapDto.Id}
              endpoint={endpoint}
              callEndpointAgain={callEndpointAgain}
            />
          </td>
        );
      },
      "Service Notes": (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        ServiceNotes: string | number,
        stock: string
      ) => (
        <td
          className={styles.tData}
          style={{ color: "black" }}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <textarea
            name={`${stock}:${property}`}
            cols={LARGE_COLS_SIZE}
            rows={(ServiceNotes + "").length < LARGE_COLS_SIZE ? 1 : 2}
            className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
            onChange={(e) =>
              handleSelectOnChangeUpdatingRows(e, LARGE_COLS_SIZE)
            }
            defaultValue={ServiceNotes}
            onBlur={(e) => {
              const d = data;
              handleNewUpdaterFunction(
                RecordType.YardMap,
                e.target.value,
                property,
                stock,
                ServiceNotes,
                () => (e.target.value = ServiceNotes + "")
              );
            }}
          />
        </td>
      ),
      "VIN": (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        VIN: string | number,
        stock: string
      ) => (
        <td
          className={styles.tData}
          style={{ color: "black" }}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            style={{ width: (VIN + "").length + CH_OFFSET + "ch" }}
            readOnly={true}
            className={INPUT_CLASS_NAME}
            name={`${stock}:${property}`}
            defaultValue={VIN}
          />
        </td>
      ),
      "Inventory Number": (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        InventoryNumber: string | number,
        stock: string
      ) => (
        <td
          className={styles.tData}
          style={{ color: "black" }}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <textarea
            readOnly={true}
            name={`${stock}:${property}`}
            defaultValue={InventoryNumber}
            cols={10}
            rows={(InventoryNumber + "").length < 10 ? 1 : 2}
            className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          />
        </td>
      ),
      "Customer": (
        yardMapDto: YardMapDto,
        property: keyof YardMapDto,
        Customer: string | number,
        stock: string
      ) => (
        <td
          className={styles.tData}
          style={{ color: "black" }}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <textarea
            readOnly={true}
            name={`${stock}:${property}`}
            defaultValue={Customer}
            cols={NORMAL_COLS_SIZE}
            rows={(Customer + "").length < NORMAL_COLS_SIZE ? 1 : 2}
            className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          />
        </td>
      ),
    };
  }, [
    confirmationModalShownStockNumber,
    data,
    endpoint,
    handleNewUpdaterFunction,
  ]);
  const runner = (dtoData: YardMapDto): React.ReactElement[] => {
    if (isNullOrUndefined(computedCombinedFilters)) {
      return [<></>];
    }

    const updatedValues = computedCombinedFilters.orderedColumnsToShow
      .filter(
        (column) =>
          !isYardMapPropertyExcluded(column as keyof YardMapDto) &&
          isNotNullNorUndefined(column) &&
          column !== ""
      )
      .filter(filterYardMapColumnMethod)
      .map((column) => {
        let value = dtoData[column as keyof YardMapDto];
        if (isNullOrUndefined(value)) {
          value = "";
        }
        const target =
          savedFunctionsToUpdateData[
          column as keyof typeof savedFunctionsToUpdateData
          ];

        if (typeof target !== "function" || isNullOrUndefined(target)) {
          console.error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
          throw new Error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
        }

        if (typeof value === "boolean") {
          // if boolean value converting to string
          value = value + "";
        }

        const result = target(
          dtoData,
          column as keyof YardMapDto,
          value as string,
          dtoData["Yard Stall"]
        );
        return result;
      })
      .filter((column) => isNotNullNorUndefined(column));

    const withSelectionColumn = [
      <td
        className={`${selectingRowMode === "unselected" ? styles.selectionColumnHidden : ""
          } ${styles.tdSelectionColumn}`}
        key="selection-column"
        onClick={(e) => {
          if ((e.target as Element).tagName !== "TD") {
            return;
          }
          const tdElement = e.target as HTMLTableCellElement;

          const inputBelow = tdElement.childNodes[0] as HTMLInputElement;
          if (isRowIdSelected(dtoData["Yard Stall"])) {
            unselectRow(dtoData["Yard Stall"]);
          } else {
            registerSelection(dtoData["Yard Stall"]);
          }
          inputBelow.focus();
        }}
      >
        <input
          type="checkbox"
          style={{ transform: "scale(1.5)" }}
          checked={isRowIdSelected(dtoData["Yard Stall"])}
          onChange={(e) => {
            if (e.target.checked) {
              registerSelection(dtoData["Yard Stall"]);
            } else {
              unselectRow(dtoData["Yard Stall"]);
            }
          }}
        />
      </td>,
      ...updatedValues,
    ];

    return withSelectionColumn as JSX.Element[];
  };

  useEffect(() => {
    const ionElement = document.getElementById(
      ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID
    );

    if (isNullOrUndefined(ionElement)) {
      console.error("ION ELEENT IS NULL RETURNING");
      return;
    }

    const handler = function() {
      const nonTableParts = Array.from(
        document.getElementsByClassName("nonTableParts")
      );
      if (isNullOrUndefined(nonTableParts) || !Array.isArray(nonTableParts)) {
        console.error("Non table part IS NULL! RETURNING");
        return;
      }
      nonTableParts.forEach((part) => {
        (part as HTMLDivElement).style.left = ionElement.scrollLeft + "px";
      });
    };

    ionElement.addEventListener("scroll", () => {
      window.requestAnimationFrame(handler);
    });
  }, []);

  const nonTablePart = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // calls the endpoint initially
    // handleEndpointCall({
    //     endpoint: endpoint,
    //     generalOptions: generalOptions,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedOnce = useRef<boolean>(false);

  const convertLongDateToShortDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const printProcessor = (inventoryDto: YardMapDto) => {
    const updatedObj: any = { ...inventoryDto };

    Object.entries(inventoryDto).forEach(([property, value]) => {
      const dataType = getDataTypeFromYardMapPropertyName(
        property as keyof YardMapDto
      );

      switch (dataType) {
        case DataType.DateTime: {
          updatedObj[property as keyof YardMapDto] =
            convertLongDateToShortDate(value);
          break;
        }
      }
    });
    return updatedObj as YardMapDto;
  };

  return (
    <div className={styles.YardMapTableContainer} data-testid="YardMapTable">
      <Layout className={`${styles.nonTablePart} nonTableParts`}>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          // id="non-table-part-of-page"
          id="header"
          ref={nonTablePart}
        >
          {tabbed && (
            <QueriedTableGlobalUserFilterList
              handleGlobalFilterButtonClick={handleGlobalFilterButtonClick}
              isFilterSelected={isFilterSelected}
              computedCombinedFilters={computedCombinedFilters}
              handleUserFilterButtonClick={handleUserFilterButtonClick}
              userFiltersStorage={userFiltersStorage}
              globalFiltersStorage={globalFiltersStorage}
            />
          )}
          <QueriedTableToolbar<YardMapDto>
            endpoint={endpoint}
            handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
            handleUserFilterButtonClick={handleUserFilterButtonClick}
            model={model}
            useCustomEndpoint={useCustomEndpoint}
            setUseCustomEndpoint={setUseCustomEndpoint}
            recordType={recordType}
            isFilterSelected={isFilterSelected}
            handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
            handleDeleteGlobalFilter={handleDeleteGlobalFilter}
            handleDeleteUserFilter={handleDeleteUserFilter}
            setOverwritingProvidedData={setOverwritingProvidedData}
            dtoType={dtoType}
            handleClear={handleClearCancel}
            data={data as YardMapDto[]}
            computedCombinedFilters={computedCombinedFilters}
            handleUpdatingItemsPerPage={handleUpdatingItemsPerPage}
            setSortFilters={setSortFilters}
            handleSetCustomFiltersValueFilters={
              handleSetCustomFiltersValueFilters
            }
            unhidableColumns={["Yard Stall", "Stock #"]}
            setColumnVisibility={setColumnVisibility}
            fieldsThatAreCustomTypes={{}}
            exportEnumProcessor={(dto) => dto}
            enumProperties={[]}
            printProcessor={printProcessor}
            getAsyncSelectGetMethodFromPropertyName={() => undefined}
            filterKeyArrayByExcludedColumns={
              filterYardMapKeyArrayByExcludedColumns
            }
            selectedUserFilterNames={selectedUserFilterNames}
            selectedGlobalFilterNames={selectedGlobalFilterNames}
            computedSelectedGlobalFilters={computedSelectedGlobalFilters}
            computedSelectedUserFilters={computedSelectedUserFilters}
            customFilters={customFilters}
            unselectGlobalFilter={unselectGlobalFilter}
            unselectUserFilter={unselectUserFilter}
            ignoreFilter={ignoreFilter}
            unignoreFilter={unignoreFilter}
            isFilterIgnored={isFilterIgnored}
            combinedFilterTargetProperties={combinedFilterTargetProperties}
            updateSearchTerm={updateSearchTerm}
            globalFilterStorage={globalFiltersStorage}
            userFilterStorage={userFiltersStorage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>

        <div
          className={`${styles.itemsPerPageAndCopyTableContainer} ${styles.nonTablePart} nonTableParts`}
        >
          <div className={`${styles.totalItemsSpan} `}>
            <span>
              Total Items: {computedCombinedFilters.sortOptions.totalItems}
            </span>
          </div>
          <CopyTableStylesButtons<YardMapDto>
            selectingRowMode={selectingRowMode}
            unselectAllRows={unselectAllRows}
            selectAllRows={selectAllRows}
            cancelSelecting={cancelSelecting}
            stopSelectingAndCopyTableWithStyles={
              stopSelectingAndCopyTableWithStyles
            }
            stopSelectingAndEmailAdvertisements={
              stopSelectingAndEmailAdvertisements
            }
            startSelecting={startSelecting}
            data={data as YardMapDto[]}
            identifierProperty={"Yard Stall"}
            inventoryPage={false}
          />
        </div>

        <div ref={tableDivContainerRef} style={{ marginTop: "1rem" }}>
          <table className={styles.table}>{handleGenerateTableParts()}</table>
        </div>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          style={{ marginBottom: "1rem" }}
        >
          <QueriedTablePaginationOptions
            computedCombinedFilters={computedCombinedFilters}
            updateCurrentPageNumber={updateCurrentPageNumber}
            setIsLoading={setIsLoading}
          />
        </div>
        {/*
                <Card
                    endpoint={() => MetricService.getMetrics(RecordType.SalesOrder)}
                    icon={CardIconTypes.Square}
                    hideOnTabletSize={true}
                />
                */}
      </Layout>
    </div>
  );
};

export default YardMapTable;
