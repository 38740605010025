import { YardMapDto } from '../../models/Dtos/YardMapDtos/YardMapDto';

const excludeFromColumnsToShow: readonly (keyof YardMapDto)[] = ['Id', 'removePrevious'] as const;

type KeyOfDeliveryArray = (keyof YardMapDto)[];

export const filterYardMapKeyArrayByExcludedColumns = (array: KeyOfDeliveryArray) => {
    const filteredArray = array.filter(filterYardMapColumnMethod);
    return filteredArray;
};

export const isYardMapPropertyExcluded = (property: keyof YardMapDto) => {
    return excludeFromColumnsToShow.includes(property);
};

export const filterYardMapColumnMethod = (column: string) => {
    return !excludeFromColumnsToShow.includes(column as keyof YardMapDto);
};
