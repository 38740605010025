import { AxiosResponse } from 'axios';
import { TypeInteractionDto } from '../../models/Dtos/TypeInteractionDto';
import { DbManagedTypes } from '../../pages/AdminPanel/components/TypeManager';
import { TypeService } from '../../utils/apiServices';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';

type FunctionParameter = (name: TypeInteractionDto) => Promise<AxiosResponse<string[], unknown>>;

export type NumberToString<N extends number> = `${N}`;

type Option = {
    label: string;
    value: string;
};

const searchGeneralType = async (
    endpointToCall: FunctionParameter,
    managedType: NumberToString<DbManagedTypes>,
    searchTerm: string
) => {
    const response = await endpointToCall(new TypeInteractionDto('', '', managedType as unknown as DbManagedTypes));
    const typeNames = response.data;

    // const filteredResults = typeNames.filter((typeName) => typeName.toLowerCase().includes(searchTerm.toLowerCase()));

    const mappedTypeNames = typeNames.map((employee) => {
        return { label: employee, value: employee };
    });
    // update for type specific later
    return mappedTypeNames;
};

export const createSearchGeneralTypeFromFactoryWithProvidedEndpointFunctionAndName = (
    managedType: NumberToString<DbManagedTypes>,
    endpointToCall: FunctionParameter
) => {
    return searchGeneralType.bind(null, endpointToCall, managedType);
};

type MultipleFactoryGeneratorListParameter = {
    [key in NumberToString<DbManagedTypes>]?: FunctionParameter;
};

type MultipleFactoryGenerationReturnType = {
    [key in NumberToString<DbManagedTypes>]?: (searchTerm: string) => Promise<Option[]>;
};

export const createMultipleSearchGeneralTypeFromFactoryFromTypeList = (
    typeMap: MultipleFactoryGeneratorListParameter
): MultipleFactoryGenerationReturnType => {
    if (isNullOrUndefined(typeMap)) {
        console.warn('Attempting to create multiple search general type but provided type map is empty!');
        throw new Error('Attempting to create multiple search general type but provided type map is empty!');
    }

    const mapWithFactoryFunctionForEach: MultipleFactoryGenerationReturnType = {};

    Object.entries(typeMap).forEach(([key, endpoint]) => {
        mapWithFactoryFunctionForEach[key as NumberToString<DbManagedTypes>] =
            createSearchGeneralTypeFromFactoryWithProvidedEndpointFunctionAndName(
                key as NumberToString<DbManagedTypes>,
                endpoint
            );
    });

    return mapWithFactoryFunctionForEach;
};

export const listOfInventoryFactoryFunctionsForTypes = createMultipleSearchGeneralTypeFromFactoryFromTypeList({
    [DbManagedTypes.InventoryType + '']: TypeService.GetNameList,
    [DbManagedTypes.WheelType + '']: TypeService.GetNameList,
    [DbManagedTypes.SuspensionType + '']: TypeService.GetNameList,
    [DbManagedTypes.SuspensionStyle + '']: TypeService.GetNameList,
    [DbManagedTypes.FloorType + '']: TypeService.GetNameList,
    [DbManagedTypes.RearDoorType + '']: TypeService.GetNameList,
    [DbManagedTypes.Height + '']: TypeService.GetNameList,
    [DbManagedTypes.Length + '']: TypeService.GetNameList,
    [DbManagedTypes.Width + '']: TypeService.GetNameList,
    [DbManagedTypes.WheelSize + '']: TypeService.GetNameList,
    [DbManagedTypes.Make + '']: TypeService.GetNameList,
    [DbManagedTypes.Model + '']: TypeService.GetNameList,
    [DbManagedTypes.ReeferMake + '']: TypeService.GetNameList,
    [DbManagedTypes.ReeferModel + '']: TypeService.GetNameList,
    [DbManagedTypes.CityLocation + '']: TypeService.GetNameList,
});

export const getFactoryFunctionForDBManagedType = (dbManagedType: DbManagedTypes) => {
    if (isNullOrUndefined(listOfInventoryFactoryFunctionsForTypes)) {
        return undefined;
    }

    const targetFactoryMethod =
        listOfInventoryFactoryFunctionsForTypes[(dbManagedType + '') as NumberToString<DbManagedTypes>];

    return targetFactoryMethod;
};
