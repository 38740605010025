import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { DataTransferService } from '../../../utils/apiServices';
import styles from './MsalRedirectorLoadingComponent.module.scss';

const MsalRedirectorLoadingComponent = () => {
    const startTransfer = () => {
        DataTransferService.transfer();
    };
    return (
        <div className={styles.MsalRedirectorLoadingComponentContainer} data-testid="MsalRedirectorLoadingComponent">
            {/* <IonItem>
                <IonLabel>Loading</IonLabel>
                <IonSpinner color="primary"></IonSpinner>
            </IonItem>
            <h1>hi</h1> */}

            {!process.env.NODE_ENV ||
                (process.env.NODE_ENV === 'development' && <button onClick={startTransfer}>transfer</button>)}

            <IonGrid>
                <IonRow>
                    <IonCol>
                        <h1>Loading</h1>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <ClipLoader></ClipLoader>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};

export default MsalRedirectorLoadingComponent;
