import { InventoryCondition, InventoryStatus, InventorySubType, PhotoStatus } from '../../Global';
import ValueWrapperModel from '../../ValueWrapperModel';

export type InventoryDtoValueWrapperDto = {
    [key in keyof InventoryDto]: ValueWrapperModel<InventoryDto[key]>;
};

export class InventoryDto {
    Id = '';

    Stock = '';

    VIN = '';

    'Number' = '';

    'Color' = '';

    'Sales Person' = '';

    'Deposit' = '';

    Spec = '';

    Customer = '';

    Price = 0;

    'Comp Date': string | null = null;

    'Inspection Date': string | null = null;

    'Received Date': string | null = null;

    'Advertise' = false;

    'DOT Status' = '';

    'Decal Id' = '';

    'Check Number' = '';

    'City Location' = '';

    Cost = 0;

    'Type' = '';

    'Sub Type': InventorySubType = InventorySubType.Construction;

    'Year' = 0;

    'Make' = '';

    'Model' = '';

    'Condition': InventoryCondition = InventoryCondition.New;

    'Status': InventoryStatus = InventoryStatus.Available;

    L = '';

    W = '';

    H = '';

    'Wheel Sz' = '';

    'Wheel Type' = '';

    Axles = 2;

    'Suspension Type' = '';

    'Suspension Style' = '';

    'KP' = '';

    'Unit Hours' = 0;

    'Entered In CDK': string | null = null;

    'Floor Type' = '';

    'Rear Door Type' = '';

    'Inventory Title' = '';

    'Temp Tag Expiration': string | null = null;

    'Side Doors' = '';

    'Photo Status': PhotoStatus = PhotoStatus.Missing;

    'Ordered Date': string | null = null;

    'Order Date': string | null = null;

    'Reefer Engine Year' = 0;

    'Reefer Make' = '';

    'Reefer Model' = '';

    'Reefer Desc' = '';

    'Reefer Serial Number' = '';

    'Reefer Year' = 0;

    Notes = '';

    'Split Deal' = false;

    'Invoice Number' = '';

    'Yard Stall' = '';

    'Misc Date': string | null = null;

    'Delivered Date': string | null = null;
    'Has Photos' = false;

    markForValidation = false;

    constructor(obj?: InventoryDto) {
        this.Id = obj?.Id ?? '';

        this.Stock = obj?.Stock ?? '';

        this.VIN = obj?.VIN ?? '';

        this.Number = obj?.Number ?? '';

        this.Color = obj?.Color ?? '';

        this['Sales Person'] = obj?.['Sales Person'] ?? '';

        this.Deposit = obj?.Deposit ?? '';

        this.Spec = obj?.Spec ?? '';

        this.Customer = obj?.Customer ?? '';

        this.Price = obj?.Price ?? 0;

        this['Comp Date'] = obj?.['Comp Date'] ?? null;

        this['Inspection Date'] = obj?.['Inspection Date'] ?? null;

        this['Received Date'] = obj?.['Received Date'] ?? null;

        this['Advertise'] = obj?.['Advertise'] ?? false;

        this['DOT Status'] = obj?.['DOT Status'] ?? '';

        this['Decal Id'] = obj?.['Decal Id'] ?? '';

        this['Check Number'] = obj?.['Check Number'] ?? '';

        this['City Location'] = obj?.['City Location'] ?? '';

        this.Cost = obj?.Cost ?? 0;

        this['Type'] = obj?.['Type'] ?? '';

        this['Sub Type'] = obj?.['Sub Type'] ?? InventorySubType.Construction;

        this['Year'] = obj?.['Year'] ?? 0;

        this['Make'] = obj?.['Make'] ?? '';

        this['Model'] = obj?.['Model'] ?? '';

        this['Condition'] = obj?.['Condition'] ?? InventoryCondition.New;

        this['Status'] = obj?.['Status'] ?? InventoryStatus.Available;

        this.L = obj?.L ?? '';

        this.W = obj?.W ?? '';

        this.H = obj?.H ?? '';

        this['Wheel Sz'] = obj?.['Wheel Sz'] ?? '';

        this['Wheel Type'] = obj?.['Wheel Type'] ?? '';

        this.Axles = obj?.Axles ?? 2;

        this['Suspension Type'] = obj?.['Suspension Type'] ?? '';

        this['Suspension Style'] = obj?.['Suspension Style'] ?? '';

        this['KP'] = obj?.['KP'] ?? '';

        this['Unit Hours'] = obj?.['Unit Hours'] ?? 0;

        this['Entered In CDK'] = obj?.['Entered In CDK'] ?? null;

        this['Floor Type'] = obj?.['Floor Type'] ?? '';

        this['Rear Door Type'] = obj?.['Rear Door Type'] ?? '';

        this['Inventory Title'] = obj?.['Inventory Title'] ?? '';

        this['Temp Tag Expiration'] = obj?.['Temp Tag Expiration'] ?? null;

        this['Side Doors'] = obj?.['Side Doors'] ?? '';

        this['Photo Status'] = obj?.['Photo Status'] ?? PhotoStatus.Missing;

        this['Ordered Date'] = obj?.['Ordered Date'] ?? null;

        this['Reefer Engine Year'] = obj?.['Reefer Engine Year'] ?? 0;

        this['Reefer Make'] = obj?.['Reefer Make'] ?? '';

        this['Reefer Model'] = obj?.['Reefer Model'] ?? '';

        this['Reefer Desc'] = obj?.['Reefer Desc'] ?? '';

        this['Reefer Serial Number'] = obj?.['Reefer Serial Number'] ?? '';

        this['Reefer Year'] = obj?.['Reefer Year'] ?? 0;

        this.Notes = obj?.Notes ?? '';

        this['Split Deal'] = obj?.['Split Deal'] ?? false;

        this['Invoice Number'] = obj?.['Invoice Number'] ?? '';

        this['Yard Stall'] = obj?.['Yard Stall'] ?? '';

        this['Misc Date'] = obj?.['Misc Date'] ?? null;
        this['Delivered Date'] = obj?.['Delivered Date'] ?? null;
        this['Has Photos'] = obj?.['Has Photos'] ?? false;

        this.markForValidation = obj?.markForValidation ?? false;
    }
}

export const AllInventoryKeysArray: string[] = [
    'Stock',

    'VIN',

    'Number',

    'Color',

    'Sales Person',

    'Deposit',

    'Spec',

    'Customer',

    'Price',

    'Comp Date',

    'Inspection Date',

    'Received Date',

    'Advertise',

    'DOT Status',

    'Decal Id',

    'Check Number',

    'City Location',

    'Cost',

    'Type',

    'Sub Type',

    'Year',

    'Make',

    'Model',

    'Condition',

    'Status',

    'L',

    'W',

    'H',

    'Wheel Sz',

    'Wheel Type',

    'Axles',

    'Suspension Type',

    'Suspension Style',

    'KP',

    'Unit Hours',

    'Entered In CDK',

    'Floor Type',

    'Rear Door Type',

    'Inventory Title',

    'Temp Tag Expiration',

    'Side Doors',

    'Photo Status',

    'Ordered Date',

    'Reefer Engine Year',

    'Reefer Make',

    'Reefer Model',

    'Reefer Desc',

    'Reefer Serial Number',

    'Reefer Year',

    'Notes',

    'Split Deal',

    'Invoice Number',

    'Yard Stall',

    'Misc Date',

    'Delivered Date',
    'Has Photos',
];
