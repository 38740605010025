import DeliveryTable from '../../components/NewTableApproach/DeliveryTable/DeliveryTable';
import QueriedTableSortFilterStateManager, {
    QueriedTableSortFilterStateManagerExportProps,
} from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { GeneralOptions } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { SortOptions } from '../../models/Dtos/OptionsDtos/SortOptions';
import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { DtoType, RecordType } from '../../models/Global';
import { DeliveryService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { EndpointResponseType } from '../../utils/RecordTypeUtils';

interface DeliveryPageProps {
    scrollTop: number;
}

export const DeliveryPage = ({ scrollTop }: DeliveryPageProps) => {
    return (
        <QueriedTableSortFilterStateManager<DeliveryDto>
            scrollTop={scrollTop}
            identifier={'Stock'}
            identifierType={IdentifierType.Stock}
            toolbar={true}
            endpoint={
                DeliveryService.getDeliveryTable as (
                    options: GeneralOptions | SortOptions
                ) => Promise<EndpointResponseType>
            }
            tabbed={true}
            justSortOptions={false}
            model={new DeliveryDto()}
            recordType={RecordType.Delivery}
            dtoType={DtoType.Delivery}>
            {(exportProps: QueriedTableSortFilterStateManagerExportProps) => <DeliveryTable {...exportProps} />}
        </QueriedTableSortFilterStateManager>
    );
};
