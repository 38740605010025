import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { DataType } from '../../models/Global';

export const getDataTypeFromInventoryDtoPropertyName = (property: keyof InventoryDto): DataType => {
    switch (property) {
        case 'Id': {
            return DataType.String;
        }
        case 'Stock': {
            return DataType.String;
        }
        case 'VIN': {
            return DataType.String;
        }
        case 'Number': {
            return DataType.String;
        }
        case 'Color': {
            return DataType.String;
        }
        case 'Sales Person': {
            return DataType.String;
        }
        case 'Deposit': {
            return DataType.String;
        }
        case 'Spec': {
            return DataType.String;
        }
        case 'Customer': {
            return DataType.String;
        }
        case 'Price': {
            return DataType.Int;
        }
        case 'Comp Date': {
            return DataType.DateTime;
        }
        case 'Inspection Date': {
            return DataType.DateTime;
        }
        case 'Received Date': {
            return DataType.DateTime;
        }
        case 'Advertise': {
            return DataType.Bool;
        }
        case 'DOT Status': {
            return DataType.String;
        }
        case 'Decal Id': {
            return DataType.String;
        }
        case 'Check Number': {
            return DataType.String;
        }
        case 'City Location': {
            return DataType.String;
        }
        case 'Cost': {
            return DataType.Int;
        }
        case 'Type': {
            return DataType.String;
        }
        case 'Sub Type': {
            return DataType.Enum;
        }
        case 'Year': {
            return DataType.Int;
        }
        case 'Make': {
            return DataType.String;
        }
        case 'Model': {
            return DataType.String;
        }
        case 'Condition': {
            return DataType.Enum;
        }
        case 'Status': {
            return DataType.Enum;
        }
        case 'L': {
            return DataType.String;
        }
        case 'W': {
            return DataType.String;
        }
        case 'H': {
            return DataType.String;
        }
        case 'Wheel Sz': {
            return DataType.String;
        }
        case 'Wheel Type': {
            return DataType.String;
        }
        case 'Axles': {
            return DataType.Int;
        }
        case 'Suspension Type': {
            return DataType.String;
        }
        case 'Suspension Style': {
            return DataType.String;
        }
        case 'KP': {
            return DataType.String;
        }
        case 'Unit Hours': {
            return DataType.Int;
        }
        case 'Entered In CDK': {
            return DataType.DateTime;
        }
        case 'Floor Type': {
            return DataType.String;
        }
        case 'Rear Door Type': {
            return DataType.String;
        }
        case 'Inventory Title': {
            return DataType.String;
        }
        case 'Temp Tag Expiration': {
            return DataType.DateTime;
        }
        case 'Side Doors': {
            return DataType.String;
        }
        case 'Photo Status': {
            return DataType.Enum;
        }
        case 'Ordered Date': {
            return DataType.DateTime;
        }
        case 'Reefer Engine Year': {
            return DataType.Int;
        }
        case 'Reefer Make': {
            return DataType.String;
        }
        case 'Reefer Model': {
            return DataType.String;
        }
        case 'Reefer Desc': {
            return DataType.String;
        }
        case 'Reefer Serial Number': {
            return DataType.String;
        }
        case 'Reefer Year': {
            return DataType.Int;
        }
        case 'Notes': {
            return DataType.String;
        }
        case 'Split Deal': {
            return DataType.Bool;
        }
        case 'Invoice Number': {
            return DataType.String;
        }
        case 'Yard Stall': {
            return DataType.String;
        }
        case 'Misc Date': {
            return DataType.DateTime;
        }
        case 'Delivered Date': {
            return DataType.DateTime;
        }
        case 'Has Photos': {
            return DataType.Bool;
        }
    }
    return DataType.String;
};
