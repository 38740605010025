/*
 * Current Table Names:
 *
 * Inventory
 * Delivery
 * SalesOrder
 * YardLocation
 *
 *
 */

export const POSSIBLE_AUDIT_LOG_TABLE_NAMES = ['Inventory', 'Deliveries', 'SalesOrder', 'YardLocation'] as const;

export type POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE = typeof POSSIBLE_AUDIT_LOG_TABLE_NAMES[number];
