import { useMsal } from '@azure/msal-react';
import React, { ReactNode } from 'react';
import { TENANT_ID } from '../../../utils/AuthConfig';
import { isNullOrUndefined } from '../../../utils/NullOrUndefined';
import styles from './ActiveAccountSetter.module.scss';

interface ActiveAccountSetterProps {
    children: ReactNode;
}

const ActiveAccountSetter = ({ children }: ActiveAccountSetterProps) => {
    /* At this point there should be at least one account beacause
     * this component should only be rendered once the user is
     * for sure logged in.
     * 
     * I will want to find the account that matches some type of 
     * information for our website

     */

    const { instance } = useMsal();

    const accounts = instance.getAllAccounts();

    const targetAccount = accounts.find((account) => account.tenantId === TENANT_ID);

    if (isNullOrUndefined(targetAccount)) {
        alert(
            'Please reach out to web developers with message of: Target Account is null inside of ACtive Account Setter'
        );
        console.error('Target account is null inside of active account setter');
        return <></>;
    }

    instance.setActiveAccount(targetAccount);

    return (
        <div className={styles.ActiveAccountSetterContainer} data-testid="ActiveAccountSetter">
            {children}
        </div>
    );
};

export default ActiveAccountSetter;
