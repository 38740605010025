import { Camera, GalleryPhoto } from '@capacitor/camera';
import axios from 'axios';
import { RecordType } from '../models/Global';
import { PhotoService } from '../utils/apiServices';

import { DeliveryPhotoDto } from '../models/Dtos/PhotoDtos/DeliveryPhotoDto';
import { UrlPhotoIdWrapper } from '../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
const PHOTO_STORAGE = 'photos';

export const addSASToImageUrl = (url: string) => {
    const SAS = process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN as string;

    return url + SAS;
};

export function useCamera(recordType: RecordType) {
    const takeAndReturnPhotoBlob = async () => {
        // const photo = await Camera.getPhoto({
        //     resultType: CameraResultType.Uri,
        //     source: CameraSource.Camera,
        //     quality: 100,
        // });

        const photo = await Camera.pickImages({});
        return photo;
    };

    const getCorrectFolderStringPathToSaveToAzureBasedOffOfPhotoTypeAndStock = (
        recordType: RecordType,
        stockNumber: string
    ) => {
        switch (recordType) {
            case RecordType.Delivery:
                return `inventory/${stockNumber}/inventoryDelivered`;
            case RecordType.Inventory:
                return `inventory/${stockNumber}/inventory`;
            case RecordType.RecordOfTransfer:
                return `inventory/${stockNumber}/transfer`;
            default:
                console.error(`Couldn't find matching Record Type for: ${recordType}! Using Inventory type for now`);
                return `inventory/${stockNumber}/inventory`;
        }
    };

    const convertBlobToJpeg = async (savedFileImage: GalleryPhoto) => {
        const blobFile = await fetch(savedFileImage.webPath!).then((r) => r.blob());
        const jpegFile = new File([blobFile], savedFileImage.path!, { type: 'image/jpeg' });
        return jpegFile;
    };

    const saveJpegPhotoToAzure = async (url: string, sasToken: string, jpegImageFile: File) => {
        const currentDateObject = new Date();
        const currentDateString =
            currentDateObject.getDay() + '-' + currentDateObject.getMonth() + '-' + currentDateObject.getFullYear();

        const azureResponse = await axios.put(url + sasToken, jpegImageFile, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
                'Content-Type': 'image/jpeg',
                'x-ms-date': currentDateString,
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-version': '2021-06-08',
            },
        });
    };

    type GetPhotoWithSasTokenConditionalReturnType<T> = T extends unknown[]
        ? { [stockNumberProperty: string]: UrlPhotoIdWrapper[] }
        : UrlPhotoIdWrapper[];

    const getInventoryContainerPhotoUrlsWithSasTokens = async <T extends string | number | string[] | number[]>(
        singleStockNumberOrStockNumberArray: T
    ): Promise<GetPhotoWithSasTokenConditionalReturnType<T>> => {
        const {
            data: { model },
        } = await PhotoService.get(singleStockNumberOrStockNumberArray, recordType);
        const sasToken = process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN as string;

        if (Array.isArray(singleStockNumberOrStockNumberArray)) {
            const castedPhotoDtoListModel = model as DeliveryPhotoDto[];
            // This is a result from multiple stock
            const urlsWithSasTokenStoredByStockNumberDictionary: {
                [stockNumberProperty: string]: UrlPhotoIdWrapper[];
            } = {};

            castedPhotoDtoListModel.forEach(({ stockNumber, urlsWithIds: imageUrlsWithoutSasToken }) => {
                const updatedUrlPhotoIdWrapper = imageUrlsWithoutSasToken.map(({ url, photoId }) => ({
                    // url: `${url}${sasToken}`,
                    url: `${url}`,
                    photoId: photoId,
                }));
                urlsWithSasTokenStoredByStockNumberDictionary[stockNumber] =
                    updatedUrlPhotoIdWrapper as UrlPhotoIdWrapper[];
            });

            return urlsWithSasTokenStoredByStockNumberDictionary as GetPhotoWithSasTokenConditionalReturnType<T>;
        }

        //This is result from single stock:

        // if stockNumberProperty isn't there, means it's a normal dto one.

        const urlPhotoIdWrappers = model as UrlPhotoIdWrapper[];

        const urlsWithSasTokens: UrlPhotoIdWrapper[] = urlPhotoIdWrappers.map(({ url, photoId }) => ({
            // url: `${url}${sasToken}`,

            url: `${url}`,
            photoId,
        })) as UrlPhotoIdWrapper[];

        return urlsWithSasTokens as GetPhotoWithSasTokenConditionalReturnType<T>;
    };

    return { getInventoryContainerPhotoUrlsWithSasTokens };
}

export interface UserPhoto {
    filepath: string;
    webviewPath?: string;
}

export default useCamera;
