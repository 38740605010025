import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';

const excludeFromColumnsToShow: readonly (keyof InventoryDto)[] = ['Id', 'markForValidation'] as const;

type KeyOfInventoryArray = (keyof InventoryDto)[];

export const filterInventoryKeyArrayByExcludedColumns = (array: KeyOfInventoryArray) => {
    const filteredArray = array.filter(filterInventoryColumnMethod);
    return filteredArray;
};

export const filterInventoryColumnMethod = (column: string) => {
    return !excludeFromColumnsToShow.includes(column as keyof InventoryDto);
};
