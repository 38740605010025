import { StringDto } from '../../models/Dtos/GeneralDtos/StringDto';
import { InventoryService } from '../../utils/apiServices';
import { AsyncSelectLoadOptionsReturnType } from './SearchUtils';

export type SearchForStocksOption = {
    label: string;
    value: string;
};

export const searchForStocksForDelivery = async (
    stockNumber: string
): Promise<AsyncSelectLoadOptionsReturnType<SearchForStocksOption>> => {
    const response = await InventoryService.SearchByStockForDelivery(new StringDto(stockNumber));
    const messageDto = response.data;
    const data = messageDto.model;

    const stockNumberArray = data.map<SearchForStocksOption>((x) => {
        return { label: x + '', value: x };
    });

    return stockNumberArray;
};
