import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import styles from './ModalCloseButton.module.scss';

interface ModalCloseButtonProps<T extends () => unknown> {
    setModalHideFunction: T;
    container?: string;
    button?: string;
    content?: JSX.Element;
}

const ModalCloseButton = <T extends () => unknown>({
    setModalHideFunction,
    container,
    button,
    content,
}: ModalCloseButtonProps<T>) => {
    return (
        <div className={`${styles.ModalCloseButtonContainer} ${container ?? ''}`} data-testid="ModalCloseButton">
            <div className={styles.audit_details_modal_header}>
                <div></div>
                <div>{content}</div>
                <IonIcon
                    onClick={() => setModalHideFunction()}
                    icon={close}
                    size={'large'}
                    className={`${styles.audit_details_modal_close_button} ${button ?? ''}`}></IonIcon>
            </div>
        </div>
    );
};

export default ModalCloseButton;
