import {
    AccountingStatus,
    InventoryCondition,
    InventoryStatus,
    InventorySubType,
    PhotoStatus,
    SalesOrderStatus,
} from '../../Global';

export class DeliveryDto {
    Stock: string;
    'Inventory Number': string;
    VIN: string;
    Spec: string;
    Price: number;
    'Comp Date': string | null;
    'Inspection Date': string | null;
    'Received Date': string | null;
    'Advertise': boolean;
    'DOT Status': string;
    'Decal Id': string;
    'City Location': string;
    'Misc Date': string | null;
    Cost: number;
    'Sub Type': InventorySubType | null;
    'Inventory Type': string;
    Year: number | null;
    Make: string;
    Model: string;
    Color: string;
    Condition: InventoryCondition | null;
    'Status - I': InventoryStatus | null;
    L: string;
    W: string;
    H: string;
    'Wheel Sz': string;
    'Wheel Type': string;
    Axles: number;
    'Suspension Type': string;
    'Suspension Style': string;
    KP: string;
    'Floor Type': string;
    'Rear Door Type': string;
    'Side Doors': string;
    'Reefer Serial Number': string;
    'Reefer Engine Year': number | null;
    'Reefer Make': string;
    'Reefer Model': string;
    'Unit Hours': number | null;
    'Reefer Year': number | null;
    'Reefer Desc': string;
    'Entered In CDK': string | null;
    'Temp Tag Expiration': string | null;
    'Ordered Date - I': string | null;
    'Notes - I': string;
    'Photo Status': PhotoStatus | null;
    'Split Deal - I': boolean;
    'Invoice Number': string;
    'Ordered Date - S': string | null;
    'Customer Name': string;
    'Sales Person': string;
    'Delivered Count': string;
    'Notes - S': string;
    Deposit: number;
    'Customer #': string;
    Lienholder: string;
    'Proof Of Payment Received': string | null;
    'Check Number - I': string;
    'Check Number - S': string;
    'Title Work Completed': string | null;
    NAPP: boolean;
    'Docs Signed': string | null;
    'Misc Tracking': string;
    'All Delivered': boolean;
    'Split Deal - S': boolean;
    'Split Sales Person': string;
    'Status - S': SalesOrderStatus | null;
    'Back Office Notes': string;
    Id: string;
    'Delivered Date': string | null;
    'Permit Number': string;
    'Carrier Phone': string | null;
    Address: string | null;
    City: string | null;
    State: string | null;
    Zip: string | null;
    'Carrier Name': string | null;
    'Carrier Truck Number': string | null;
    'Driver First Name': string | null;
    'Driver Last Name': string | null;
    'Notes - D': string | null;
    'Accounting Status': AccountingStatus | null;
    'Accounting Date': string | null;
    'Receipt Number': string;
    'Yard Loc': string | null;
    'Yard Loc ID': string | null;
    'Created Date': string | null;

    constructor(obj?: DeliveryDto) {
        this.Stock = obj?.Stock ?? '';
        this['Inventory Number'] = obj?.['Inventory Number'] ?? '';
        this.VIN = obj?.VIN ?? '';
        this.Spec = obj?.Spec ?? '';
        this.Price = obj?.Price ?? 0;
        this['Comp Date'] = obj?.['Comp Date'] ?? '';
        this['Inspection Date'] = obj?.['Inspection Date'] ?? '';
        this['Received Date'] = obj?.['Received Date'] ?? '';
        this['Advertise'] = obj?.['Advertise'] ?? false;
        this['DOT Status'] = obj?.['DOT Status'] ?? '';
        this['Decal Id'] = obj?.['Decal Id'] ?? '';
        this['City Location'] = obj?.['City Location'] ?? '';
        this['Misc Date'] = obj?.['Misc Date'] ?? '';
        this.Cost = obj?.Cost ?? 0;
        this['Sub Type'] = obj?.['Sub Type'] ?? null;
        this['Inventory Type'] = obj?.['Inventory Type'] ?? '';
        this.Year = obj?.Year ?? 0;
        this.Make = obj?.Make ?? '';
        this.Model = obj?.Model ?? '';
        this.Color = obj?.Color ?? '';
        this.Condition = obj?.Condition ?? null;
        this['Status - I'] = obj?.['Status - I'] ?? null;
        this.L = obj?.L ?? '';
        this.W = obj?.W ?? '';
        this.H = obj?.H ?? '';
        this['Wheel Sz'] = obj?.['Wheel Sz'] ?? '';
        this['Wheel Type'] = obj?.['Wheel Type'] ?? '';
        this.Axles = obj?.Axles ?? 2;
        this['Suspension Type'] = obj?.['Suspension Type'] ?? '';
        this['Suspension Style'] = obj?.['Suspension Style'] ?? '';
        this.KP = obj?.KP ?? '';
        this['Floor Type'] = obj?.['Floor Type'] ?? '';
        this['Rear Door Type'] = obj?.['Rear Door Type'] ?? '';
        this['Side Doors'] = obj?.['Side Doors'] ?? '';
        this['Reefer Serial Number'] = obj?.['Reefer Serial Number'] ?? '';
        this['Reefer Engine Year'] = obj?.['Reefer Engine Year'] ?? 0;
        this['Reefer Make'] = obj?.['Reefer Make'] ?? '';
        this['Reefer Model'] = obj?.['Reefer Model'] ?? '';
        this['Unit Hours'] = obj?.['Unit Hours'] ?? this['Unit Hours'];
        this['Reefer Year'] = obj?.['Reefer Year'] ?? null;
        this['Reefer Desc'] = obj?.['Reefer Desc'] ?? '';
        this['Entered In CDK'] = obj?.['Entered In CDK'] ?? '';
        this['Temp Tag Expiration'] = obj?.['Temp Tag Expiration'] ?? '';
        this['Ordered Date - I'] = obj?.['Ordered Date - I'] ?? '';
        this['Notes - I'] = obj?.['Notes - I'] ?? '';
        this['Photo Status'] = obj?.['Photo Status'] ?? null;
        this['Split Deal - I'] = obj?.['Split Deal - I'] ?? false;
        this['Invoice Number'] = obj?.['Invoice Number'] ?? '';
        this['Ordered Date - S'] = obj?.['Ordered Date - S'] ?? '';
        this['Customer Name'] = obj?.['Customer Name'] ?? '';
        this['Sales Person'] = obj?.['Sales Person'] ?? '';
        this['Delivered Count'] = obj?.['Delivered Count'] ?? '';
        this['Notes - S'] = obj?.['Notes - S'] ?? '';
        this.Deposit = obj?.Deposit ?? 0;
        this['Customer #'] = obj?.['Customer #'] ?? '';
        this.Lienholder = obj?.Lienholder ?? '';
        this['Proof Of Payment Received'] = obj?.['Proof Of Payment Received'] ?? '';
        this['Check Number - I'] = obj?.['Check Number - I'] ?? '';
        this['Check Number - S'] = obj?.['Check Number - S'] ?? '';
        this['Title Work Completed'] = obj?.['Title Work Completed'] ?? '';
        this.NAPP = obj?.NAPP ?? false;
        this['Docs Signed'] = obj?.['Docs Signed'] ?? '';
        this['Misc Tracking'] = obj?.['Misc Tracking'] ?? '';
        this['All Delivered'] = obj?.['All Delivered'] ?? false;
        this['Split Deal - S'] = obj?.['Split Deal - S'] ?? false;
        this['Split Sales Person'] = obj?.['Split Sales Person'] ?? '';
        this['Status - S'] = obj?.['Status - S'] ?? null;
        this['Back Office Notes'] = obj?.['Back Office Notes'] ?? '';
        this.Id = obj?.Id ?? '';
        this['Delivered Date'] = obj?.['Delivered Date'] ?? '';
        this['Permit Number'] = obj?.['Permit Number'] ?? '';
        this['Carrier Phone'] = obj?.['Carrier Phone'] ?? '';
        this.Address = obj?.Address ?? '';
        this.City = obj?.City ?? '';
        this.State = obj?.State ?? '';
        this.Zip = obj?.Zip ?? '';
        this['Carrier Name'] = obj?.['Carrier Name'] ?? '';
        this['Carrier Truck Number'] = obj?.['Carrier Truck Number'] ?? '';
        this['Driver First Name'] = obj?.['Driver First Name'] ?? '';
        this['Driver Last Name'] = obj?.['Driver Last Name'] ?? '';
        this['Notes - D'] = obj?.['Notes - D'] ?? '';
        this['Accounting Status'] = obj?.['Accounting Status'] ?? null;
        this['Accounting Date'] = obj?.['Accounting Date'] ?? '';
        this['Receipt Number'] = obj?.['Receipt Number'] ?? '';
        this['Yard Loc'] = obj?.['Yard Loc'] ?? '';
        this['Yard Loc ID'] = obj?.['Yard Loc ID'] ?? '';
        this['Created Date'] = obj?.['Created Date'] ?? '';
    }
}

export const AllDeliveryKeysArray = [
    'Stock',
    'Inventory Number',
    'VIN',
    'Spec',
    'Price',
    'Comp Date',
    'Inspection Date',
    'Received Date',
    'Advertise',
    'DOT Status',
    'Decal Id',
    'City Location',
    'Misc Date',
    'Cost',
    'Sub Type',
    'Inventory Type',
    'Year',
    'Make',
    'Model',
    'Color',
    'Condition',
    'Status - I',
    'L',
    'W',
    'H',
    'Wheel Sz',
    'Wheel Type',
    'Axles',
    'Suspension Type',
    'Suspension Style',
    'KP',
    'Floor Type',
    'Rear Door Type',
    'Side Doors',
    'Reefer Serial Number',
    'Reefer Engine Year',
    'Reefer Make',
    'Reefer Model',
    'Unit Hours',
    'Reefer Year',
    'Reefer Desc',
    'Entered In CDK',
    'Temp Tag Expiration',
    'Ordered Date - I',
    'Notes - I',
    'Photo Status',
    'Split Deal - I',
    'Invoice Number',
    'Ordered Date - S',
    'Customer Name',
    'Sales Person',
    'Delivered Count',
    'Notes - S',
    'Deposit',
    'Customer #',
    'Lienholder',
    'Proof Of Payment Received',
    'Check Number - I',
    'Check Number - S',
    'Title Work Completed',
    'NAPP',
    'Docs Signed',
    'Misc Tracking',
    'All Delivered',
    'Split Deal - S',
    'Split Sales Person',
    'Status - S',
    'Back Office Notes',
    'Id',
    'Delivered Date',
    'Permit Number',
    'Carrier Phone',
    'Address',
    'City',
    'State',
    'Zip',
    'Carrier Name',
    'Carrier Truck Number',
    'Driver First Name',
    'Driver Last Name',
    'Notes - D',
    'Accounting Status',
    'Accounting Date',
    'Receipt Number',
    'Yard Loc',
    'Yard Loc ID',
    'Created Date',
];
