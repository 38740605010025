import { IonButton, IonCol, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonRow } from '@ionic/react';
import { close } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { GroupBase, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import Select from 'react-select/dist/declarations/src/Select';
import { StringDto } from '../../../../models/Dtos/GeneralDtos/StringDto';
import { GeneralOptions } from '../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { SortOptions } from '../../../../models/Dtos/OptionsDtos/SortOptions';
import { UpdateYardLocationDto } from '../../../../models/Dtos/YardMapDtos/UpdateYardLocationDto';
import { YardMapService } from '../../../../utils/apiServices';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../utils/NullOrUndefined';
import { EndpointResponseType } from '../../../../utils/RecordTypeUtils';
import { searchForStocks } from '../../../Search/SearchStockByNumber';
import { SearchForStocksOption } from '../../../Search/SearchStockByNumberForDelivery';
import styles from './YardLocationStockModificationModal.module.scss';

interface YardLocationStockModificationModalProps {
    hideConfirmationModal: () => void;
    yardLocationStall: string;
    yardStallId: number | string;
    endpoint: (options: GeneralOptions | SortOptions) => Promise<EndpointResponseType>;
    isShown: boolean;
    callEndpointAgain: () => void;
}

const YardLocationStockModificationModal = ({
    hideConfirmationModal,
    yardLocationStall,
    yardStallId,
    endpoint,
    isShown,
    callEndpointAgain,
}: YardLocationStockModificationModalProps) => {
    // Tempoary in memory database source until we setup the endpoints.
    const [stockWrappers, setStockWrappers] = useState<{ stock: string; new: boolean }[]>([]);

    const [passedFirstCallConfirmationModalShownUE, setPassedFirstCallConfirmationModalShownUE] =
        useState<boolean>(false);

    useEffect(() => {
        if (!passedFirstCallConfirmationModalShownUE) {
            setPassedFirstCallConfirmationModalShownUE(true);
            return;
        }

        if (!isShown) {
            return;
        }

        const callFunction = async () => {
            const response = await YardMapService.SearchByYardStallForYardLocation(
                new StringDto(yardLocationStall.split('-')[0])
            );

            if (response.data.isSuccessful) {
                setStockWrappers(
                    response.data.model
                        .filter((stock) => !isNullOrUndefinedOrEmptyString(stock))
                        .map((stock) => {
                            return { stock, new: false };
                        })
                );
            }
        };

        callFunction();
    }, [passedFirstCallConfirmationModalShownUE, yardLocationStall, isShown]);

    const handleChange = async (selectedOption: SingleValue<SearchForStocksOption>) => {
        // verify it is only sending back whole stock numbers
        // Then call database to fetch inventory and add another DeliveryDto --- may not need to call backend, ignore until after testing.
        if (selectedOption == undefined) {
            alert('Unhandled Error in UseEffect for SelectedStockNumber: ' + { selectedOption });
            return;
        }

        const addedStock = selectedOption.label;

        if (Number.isNaN(addedStock)) {
            throw new Error(`Provided Stock ${addedStock} is not a number!`);
        }

        const result = stockWrappers.find((stockWrapper) => stockWrapper.stock === addedStock);

        if (result != undefined) {
            alert(
                `Cannot use a stock number that has already been previously added! Stock number ${addedStock} has already been added!`
            );
            return;
        }
        setStockWrappers((oldStocks) => {
            return [...oldStocks, { stock: addedStock + '', new: true }];
        });
    };

    const handleSubmit = async () => {
        const dtoConverted: UpdateYardLocationDto = {
            locationId: yardStallId + '',
            stockNumbers: stockWrappers.map((stockWrapper) => stockWrapper.stock),
            removePrevious: false,
        };
        const response = await YardMapService.UpdateWithStockNumbers(dtoConverted);
        // handleEndpointCall({
        //     endpoint,
        //     generalOptions,
        // });

        // if (response.data.isSuccessful) {
        //     setStockWrappers(
        //         response.data.model.linkedInventories.map((inventory) => ({ stock: inventory['Stock #'], new: false }))
        //     );
        // }

        // setStockWrappers([]);
        hideConfirmationModal();

        callEndpointAgain();
    };

    const handleDelete = (stockNumber: string) => {
        if (isNullOrUndefined(stockWrappers.find((stockWrapper) => stockWrapper.stock))) {
            throw new Error('Attempting to delete stock number but it no longer exists in in memory databse!');
        }

        setStockWrappers((oldStocks) => {
            return oldStocks.filter((stockWrapper) => stockWrapper.stock !== stockNumber);
        });
    };

    const stockSearchInputRef =
        useRef<Select<{ label: string; value: string }, false, GroupBase<SearchForStocksOption>>>(null);

    const focusAsyncSelect = () => {
        const inputElement = stockSearchInputRef.current;

        if (isNullOrUndefined(inputElement)) {
            return;
        }
        inputElement?.focus();

        // const colElement = document.getElementsByClassName('ion-col-async-container')[0] as HTMLIonColElement;

        // if (isNullOrUndefined(colElement)) {
        //     return;
        // }
        // const inputElement = colElement.querySelector('input');

        // console.log('inputElement');
        // console.log(inputElement);
        // if (isNullOrUndefined(inputElement)) {
        //     return;
        // }
        // console.log('FOCUSING');
        // inputElement?.focus();
    };

    useEffect(() => {
        setTimeout(() => {
            const inputElement = stockSearchInputRef.current;

            if (isNullOrUndefined(inputElement)) {
                return;
            }
            inputElement?.focus();
        }, 100);
    }, [stockSearchInputRef]);

    return (
        <div className={styles.YardLocationStockModificationModal} data-testid="YardLocationStockModificationModal">
            <IonModal className={'fullscreen'} isOpen={isShown} onIonModalDidDismiss={(e) => hideConfirmationModal()}>
                <div className={styles.innerModal}>
                    {/*// Need to do reverse logic here*/}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <AsyncSelect
                            ref={stockSearchInputRef}
                            // autoFocus={true}
                            loadOptions={searchForStocks}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: '5rem',
                                    minHeight: '5rem',
                                    fontSize: '50px',
                                    border: '1px solid black',
                                }),
                                menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    fontSize: '36px',
                                }),
                            }}
                            autoFocus={true}
                            value={{ label: '', value: '' }}
                            className={`${styles.asyncSelect} ${styles.minimumWidthAsyncSelect} ${styles.customAsyncSelect}`}
                            /*
                                    Having this value word here basically locks the input to not save any
                                    values. This makes it so that when the user tries to add another delivery
                                    after adding a previous one the input field will be blank. In contrast without 
                                    this value property in this case the input field will have the stock number previously 
                                    put into it. But what does work is on the onchange function. Basically if we
                                    try to change the value the handleChange will run. But the actual input value
                                    will stay the same.
                                    When the handleChange runs it takes the value it tried to change and sets it as the
                                    stock number for the new delivery.

                                    Typescript doesn't seem to like this so I've added @ts-ignore to the onchange and load
                                    options that otherwise would've been showing errors

                                    TL;DR

                                    The benefit of having this value here is that after the user has added one record of
                                    delivery, they will show this input and it will already be blank instead of having
                                    the stock number from the previous delivery creation.

                                    */

                            filterOption={(option) =>
                                stockWrappers.find((stockWrapper) => stockWrapper.stock === option.value) === undefined
                            }
                            onChange={handleChange}
                            // cacheOptions
                            closeMenuOnSelect
                            placeholder={'Search by Stock Number'}
                        />
                        <div
                            style={{
                                // position: 'absolute',
                                // right: '0',
                                // top: '0',
                                // zIndex: '100',
                                // padding: '1rem',
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '-1rem',
                                height: '5rem',
                                width: '5rem',
                            }}
                            onClick={hideConfirmationModal}>
                            <IonIcon icon={close} className={styles.ionIconCloseModal} />
                        </div>
                    </div>

                    <IonHeader style={{ textAlign: 'center' }}>
                        <div style={{ position: 'relative' }}>
                            <span className={styles.yardLocationStallText}>{yardLocationStall}</span>
                        </div>
                    </IonHeader>
                    <div
                        className={
                            stockWrappers.length >= 5 ? styles.forceHorizontalScroll : styles.forceNoHorizontalScroll
                        }>
                        {stockWrappers.map((stockWrapper) => {
                            return (
                                <React.Fragment key={stockWrapper.stock}>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol size="6">
                                                <IonItem>
                                                    <IonLabel className={`${stockWrapper.new ? styles.newStock : ''} `}>
                                                        <span className={`${styles.stockLabel}`}>
                                                            {stockWrapper.stock}
                                                        </span>
                                                    </IonLabel>
                                                </IonItem>
                                            </IonCol>
                                            <IonCol size="6">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <IonButton
                                                        size="large"
                                                        className={styles.deleteButton}
                                                        onClick={() => handleDelete(stockWrapper.stock)}>
                                                        Delete
                                                    </IonButton>
                                                </div>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className={styles.gridWithSmallBorder}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={handleSubmit}>Submit</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </div>
            </IonModal>
        </div>
    );
};

export default YardLocationStockModificationModal;
