import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { FC, useState } from 'react';
import { useDynamicallyGeneratedUniqueIdArrayState } from '../../../../../../hooks/useDynamicallyGeneratedUniqueIdArrayState/useDynamicallyGeneratedUniqueIdArrayState';
import { DynamicSort, DynamicSortWithoutSortPriority } from '../../../../../../models/Dtos/OptionsDtos/DynamicSort';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { GeneratedIdType, KEYED_TYPE_IDENTIFIER_PROPERTY_NAME } from '../../../../../../utils/KeyIdUtils';
import makeSimpleToast from '../../../../../../utils/MakeSimpleToast';
import { isNullOrUndefined } from '../../../../../../utils/NullOrUndefined';
import styles from './SortManager.module.scss';

interface SortManagerProps {
    setSortFilters: (dynamicSorts: DynamicSortWithoutSortPriority[]) => void;
    closeModal: () => void;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SortManager: FC<SortManagerProps> = ({ setSortFilters, closeModal, computedCombinedFilters, setIsLoading }) => {
    const {
        arrayState: internalSortList,
        addObj: addObjToInternalList,
        removeObj: removeObjFromInternalList,
        updateObj: updateObjInInternalList,
        shiftItemUp: shiftObjInInternalListUp,
        shiftItemDown: shiftObjInInternalListDown,
    } = useDynamicallyGeneratedUniqueIdArrayState<DynamicSortWithoutSortPriority>([
        ...computedCombinedFilters.sortOptions.sortBy,
    ]);

    const [columnToAdd, setColumnToAdd] = useState<string>('');

    const removeColumnFromSort = (generatedId: string) => {
        removeObjFromInternalList(generatedId).then((result) => {
            if (result.success) {
                return;
            }

            console.error(result.error.message);
            makeSimpleToast('Error while removing column:', result.error.message);
        });
    };

    const handleMoveColumnDown = (idOrObject: string | GeneratedIdType<DynamicSort>) => {
        shiftObjInInternalListDown(idOrObject).then((result) => {
            if (result.success) {
                return;
            }
            console.error(result.error.message);
            makeSimpleToast('Error while moving item down:', result.error.message);
        });
    };

    const handleMoveColumnUp = (idOrObject: string | GeneratedIdType<DynamicSort>) => {
        shiftObjInInternalListUp(idOrObject).then((result) => {
            if (result.success) {
                return;
            }
            console.error(result.error.message);
            makeSimpleToast('Error while moving item up:', result.error.message);
        });
    };

    const handleAddColumnToSorting = (columnName: string | undefined) => {
        if (columnName === '') {
            return;
        }
        if (isNullOrUndefined(columnName)) {
            alert('Please select a Column Name to add!');
            return;
        }

        addObjToInternalList({
            propertyName: columnName,
            isAscending: true,
        }).then((result) => {
            if (result.success) {
                return;
            }

            console.error(result.error.message);
            makeSimpleToast('Error while adding sorting item:', result.error.message);
        });
    };

    const handleSetAscending = (id: string, ascending: boolean) => {
        updateObjInInternalList([
            id,
            (sort) => {
                return { ...sort, isAscending: ascending };
            },
        ]).then((result) => {
            if (result.success) {
                return;
            }

            console.error(result.error.message);
            makeSimpleToast(
                `Error while setting sort to ${ascending ? 'ascending' : 'decending'}:`,
                result.error.message
            );
        });
    };

    const submitChangesSortButton = () => {
        setSortFilters(internalSortList);
        closeModal();
        setIsLoading(true);
    };

    return (
        <div className={styles.SortManager} data-testid="SortManager">
            <IonGrid>
                {internalSortList.map(
                    ({ propertyName, isAscending, [KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]: id }, index) => (
                        <IonRow key={propertyName}>
                            <IonCol>
                                <IonLabel>{index + 1}</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>{propertyName}</IonLabel>
                            </IonCol>
                            <IonCol>
                                <IonLabel>Is Ascending</IonLabel>
                                <IonCheckbox
                                    checked={isAscending}
                                    onIonChange={(e) => handleSetAscending(id, e.detail.checked)}
                                />
                            </IonCol>
                            <IonCol>
                                <IonButton onClick={() => handleMoveColumnUp(id)}>Up</IonButton>
                                <IonButton onClick={() => handleMoveColumnDown(id)}>Down</IonButton>
                            </IonCol>
                            <IonCol>
                                <IonButton color="danger" onClick={() => removeColumnFromSort(id)}>
                                    Delete
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    )
                )}
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Property To Sort</IonLabel>
                            <IonSelect
                                value={columnToAdd}
                                onIonChange={(e) => {
                                    const value = e.detail.value ?? '';
                                    handleAddColumnToSorting(value);
                                }}>
                                <IonSelectOption disabled={true} className={styles.selectHeader}>
                                    Visible Columns:
                                </IonSelectOption>
                                {computedCombinedFilters.orderedColumnsToShow
                                    .filter(
                                        (shownColumnColumnName) =>
                                            !internalSortList.find(
                                                ({ propertyName }) => propertyName === shownColumnColumnName
                                            )
                                    )
                                    .sort()
                                    .map((columnName) => (
                                        <IonSelectOption key={columnName} value={columnName}>
                                            {columnName}
                                        </IonSelectOption>
                                    ))}

                                <IonSelectOption disabled={true} className={styles.selecteHeader}>
                                    Invisible Columns:
                                </IonSelectOption>

                                {computedCombinedFilters.columnOrder
                                    .filter(
                                        (column) =>
                                            !computedCombinedFilters.orderedColumnsToShow.find(
                                                (propertyName) => propertyName === column
                                            )
                                    )
                                    .sort()
                                    .map((columnName) => (
                                        <IonSelectOption key={columnName} value={columnName}>
                                            {columnName}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {/* <IonRow>
                    <IonCol>
                        <IonButton onClick={() => }>
                            Add Column To Sorting
                        </IonButton>
                    </IonCol>
                </IonRow> */}
            </IonGrid>
            <IonRow>
                <IonCol>
                    <IonButton onClick={submitChangesSortButton}>Submit</IonButton>
                </IonCol>
            </IonRow>
        </div>
    );
};

export default SortManager;
