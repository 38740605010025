import { DynamicSort } from './DynamicSort';
import { SortOptions } from './SortOptions';

export class SortOptionsWithoutPaginationItems {
    sortBy: DynamicSort[] = [];

    constructor(obj?: SortOptions) {
        this.sortBy = obj?.sortBy ?? [];
    }
}
