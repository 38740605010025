import { DbManagedTypes } from '../../pages/AdminPanel/components/TypeManager';
export class TypeInteractionDto {
    originalName = '';
    newName = '';
    dbManagedType: DbManagedTypes = DbManagedTypes.FloorType;

    constructor(originalName?: string, newName?: string, dbManagedType?: DbManagedTypes) {
        this.originalName = originalName ?? '';
        this.newName = newName ?? '';
        this.dbManagedType = dbManagedType ?? DbManagedTypes.FloorType;
    }
}
