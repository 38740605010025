import { RecordOfTransferDetailDto } from './RecordOfTransferDetailDto';

export class RecordOfTransferDto {
    Id: string;

    'Sales Person': string;

    Customer: string;

    CustomerNumber: string;

    Stocks: string[] = [];

    Notes: string;

    Rental: boolean;

    recordsOfTransfer: RecordOfTransferDetailDto[] = [];
}

export const AllRecordOfTransferKeysArray: string[] = [
    'Sales Person',

    'Customer',

    'CustomerNumber',

    'Stocks',

    'Notes',

    'Rental',
];
