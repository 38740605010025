import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import { useRef } from 'react';
import './App.css';
import styles from './App.module.scss';
import { IonSplitPaneStateContainer } from './components/Theme';
import './custom.css';
import './global.scss';
import './theme/addNewButton.css';
import './theme/alerts.css';
import './theme/deleteIconColor.css';
import './theme/exportButton.css';
import './theme/filters.css';
import './theme/filterSelected.css';
import './theme/selectedStockNumber.css';
import './theme/variables.css';
import './theme/white.css';

import './zhackyResolutionCantSelectText.css';

setupIonicReact({
  mode: 'md',
});

// MSAL configuratino
// const configuration: Configuration = {
//     auth: {
//         clientId: process.env.REACT_APP_CLIENT_ID as string,
//     },
// };

// const pca = new PublicClientApplication(configuration);

function App() {
  // registerEventListenerToPreventInputScrolling();
  // registerEventHandlerToSelectInputValueWhenClicked();

  const scrollContainerRef = useRef;

  return (
    // <DndProvider backend={HTML5Backend}>
    // <MsalProvider instance={pca}>
    <IonApp>
      <div className={styles.app} id="scollable-div">
        <IonReactRouter>
          <IonSplitPaneStateContainer />
        </IonReactRouter>
      </div>
    </IonApp>
  );
}

export default App;
