import { IonButton, IonItem, IonLabel, IonModal } from '@ionic/react';
import React, { useState } from 'react';
import AuditDetail from '../../models/AuditModels/AuditDetail';
import RecordAuditDto from '../../models/AuditModels/RecordAuditDto';
import { GeneratedIdType, KEYED_TYPE_IDENTIFIER_PROPERTY_NAME } from '../../utils/KeyIdUtils';
import { isNotNullNorUndefined } from '../../utils/NullOrUndefined';
import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import AuditLogCollapsibleContent from './AuditLogCollapsibleContent/AuditLogCollapsibleContent';
import styles from './AuditLogQueriedTable.module.scss';

/**
 * Represents RecordAuditDto
 */

interface AuditLogQueriedTableProps {
    logItems: GeneratedIdType<RecordAuditDto>[];
    showAuditDetailsModal: boolean;
    selectedRecord: GeneratedIdType<RecordAuditDto>;
    handleShowAuditDetails: (record: GeneratedIdType<RecordAuditDto>) => void;
    setShowAuditDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuditLogQueriedTable = ({
    logItems,
    showAuditDetailsModal: showModal,
    selectedRecord,
    handleShowAuditDetails,
    setShowAuditDetailsModal: setShowModal,
}: AuditLogQueriedTableProps) => {
    const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

    return (
        <div className={styles.AuditLogQueriedTableContainer} data-testid="AuditLogQueriedTable">
            <h1 className={styles.header}>Audit Log</h1>
            {logItems.map((logItem) => {
                return (
                    <div className={styles.row} key={logItem[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]}>
                        <div>
                            <IonItem>
                                <IonLabel>Changed Date</IonLabel>
                                <IonLabel>{logItem.changedDate}</IonLabel>
                            </IonItem>
                        </div>
                        <div>
                            <IonButton onClick={() => handleShowAuditDetails(logItem)}>Show Audit Details</IonButton>
                        </div>
                    </div>
                );
            })}
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <ModalCloseButton setModalHideFunction={() => setShowModal(false)} />
                {isNotNullNorUndefined(selectedRecord) ? (
                    <CollapsibleSection<AuditDetail>
                        records={selectedRecord.details}
                        identifier={'columnName'}
                        labelStrip={(record) => <div>{record.columnName}</div>}
                        // This props for this element will be provided later
                        content={() => <AuditLogCollapsibleContent record={new AuditDetail()} index={0} />}
                        startingValue={undefined}
                        focusedEntry={focusedEntry}
                        setFocusedEntry={setFocusedEntry}
                    />
                ) : (
                    <div></div>
                )}
            </IonModal>
        </div>
    );
};

export default AuditLogQueriedTable;
