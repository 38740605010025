import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { format } from 'date-fns';
import { chevronBackOutline, createOutline, eyeOutline } from 'ionicons/icons';
import cloneDeep from 'lodash.clonedeep';
import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactToPrint from 'react-to-print';
import CollapsibleSection from '../../components/CollapsibleSection/CollapsibleSection';
import { canUserAccessSalesSecurityWithGivenRoles, RoleContext } from '../../components/RoleContext/RoleContextComp';
import { searchForStocks } from '../../components/Search/SearchStockByNumber';
import { SearchForStocksOption } from '../../components/Search/SearchStockByNumberForDelivery';
import {
  ID_PARAMETER_NAME,
  ID_TYPE_PARAMETER_NAME,
  UseParamsObject,
} from '../../components/Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer';
import { WrappedLocationType } from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { useObject } from '../../hooks/ObjectHook';
import { StringDto } from '../../models/Dtos/GeneralDtos/StringDto';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { YardLocationDetailsWithInventoryDto } from '../../models/Dtos/YardMapDtos/YardLocationDetailsWithInventoryDto';
import { YardMapService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { isNotNullNorUndefined } from '../../utils/NullOrUndefined';
import PageNameGenerator from '../../utils/PageNameGenerator';
import { MakeMethodsOptional, RemoveMethods } from '../../utils/TypeUtils';
import InventoryDetails from '../InventoryDetails/InventoryDetails';
import { Layout } from '../Layout';
import YardMapCondensedView from '../YardMapCondensedView/YardMapCondensedView';
import styles from './YardLocations.module.scss';

export const YardMapPage: React.FC = () => {
  const location = useLocation<WrappedLocationType>();

  const { [ID_PARAMETER_NAME]: identifierNumber, [ID_TYPE_PARAMETER_NAME]: identifierType } =
    useParams<UseParamsObject>();

  const {
    value: yardMap,
    setValue: setYardMap,
    bindIonSelectProperty: bindIonYardMapSelect,
    bindIonInputProperty: bindIonYardMapProperty,
    bindReactAsyncSelect: bindYardMapAsyncSelect,
    // bindIonInputPropertyDecimals: bindIonSalesOrderDecimal,
    bindIonPropertyDate: bindIonYardMapDate,
    bindIonPropertyCheck: bindIonYardMapCheck,
    bindIonPropertyCurrency: bindIonYardMapCurrency,
    updateProperty: updateYardMapProperty,
  } = useObject<RemoveMethods<YardLocationDetailsWithInventoryDto>>(new YardLocationDetailsWithInventoryDto());

  const YARD_MAP_DETAIL_PAGE_TITLE = PageNameGenerator('Yard Map Detail');

  useEffect(() => {
    if (document.title !== YARD_MAP_DETAIL_PAGE_TITLE) document.title = YARD_MAP_DETAIL_PAGE_TITLE;
  }, []);

  const history = useHistory();

  const [toggle, setToggle] = useState<boolean>(false);

  const [customerIdentifier, setCustomerIdentifier] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();

  const [ignoreNextCall, setIgnoreNextCall] = useState<boolean>(false);

  const [usingEnteringStockNumberAppraoch, setUsingEnteringStockNumberAppraoch] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const accordionGroup = useRef<HTMLIonAccordionGroupElement>(null);

  const [focusToggle, setFocusToggle] = useState<string | null>(null);

  const [showAddTrailer, setShowAddTrailer] = useState<boolean>(false);

  const [passedSelectedStockNumberUE, setPassedSelectedStockNumberUE] = useState<boolean>();

  const [addedInventories, setAddedDeliveries] = useState<string[]>([]);

  const [accordionGroupValue, setAccordionGroupValue] = useState<string | undefined>(undefined);

  const targetDiv = useRef(null);

  const [createdNewROS, setCreatedNewRos] = useState<boolean>(false);

  const zIndexRegistry = 999; // This will get reset on each render.

  const [isEditing, setIsEditing] = useState<boolean>(identifierNumber == undefined);
  const yardMapCondensedViewPrintComponent = useRef(null);

  /* To Do List:
  handle Response DTO - Shawn
  Set required tags - Should be completed
  Set validation messages - Should be completed
  Test get, create, update
  Set edit permissions based off logged in user role using disabled={}
  CSS - Shawn
  Finish Stock Number logic - Notes in UseEffect below
  */

  const changeToEdit = () => {
    setIsEditing(true);
  };

  const roles = useContext(RoleContext);

  const changeToView = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (identifierType === IdentifierType.YardStall) {
      getRecord();
    }
  }, []);

  const activate = () => {
    if (identifierType == IdentifierType.Stock && identifierNumber != undefined)
      document.getElementById(identifierNumber + '')?.scrollIntoView(true);
  };

  useEffect(() => {
    if (identifierType == IdentifierType.Stock && identifierNumber != undefined) {
      document.getElementById(identifierNumber + '')?.scrollIntoView(true);
    }
  });

  const updateInventory = (inventory: InventoryDto) => {
    if (inventory == undefined) {
      throw new Error('inventory is undefined on attempt update inventory!');
    }
    const stock = inventory.Stock;

    setYardMap((yardMap) => {
      const currentArray = yardMap.Inventory;
      const copy = { ...yardMap };

      const copyOfInventory = yardMap.Inventory;

      let updatedAray: InventoryDto[];

      if (currentArray.find((inventory) => inventory.Stock == inventory.Stock)) {
        updatedAray = copyOfInventory.map((inv) => {
          if (inv.Stock === inventory.Stock) {
            return inventory;
          }
          return inv;
        });
      } else {
        updatedAray = [...copyOfInventory, inventory];
      }

      copy.Inventory = updatedAray;

      return copy;
    });
  };

  const addInventory = (inventory: InventoryDto) => {
    const result = addedInventories.find((recordStockNumber) => recordStockNumber === inventory.Stock + '');

    if (result != undefined) {
      alert('Cannot use a stock number that has already been previously added!');
      return;
    }

    const previousInventories = yardMap.Inventory;

    setYardMap((ros) => {
      const copy = { ...ros };

      const copiesOfInventories = ros.Inventory;

      let updatedAray: InventoryDto[];

      if (previousInventories.find((inv) => inv.Stock == inventory.Stock)) {
        updatedAray = copiesOfInventories.map((inv) => {
          if (inv.Stock === inventory.Stock) {
            return inventory;
          }

          return inv;
        });
      } else {
        updatedAray = [...copiesOfInventories, inventory];
      }

      copy.Inventory = updatedAray;

      return copy;
    });

    setAddedDeliveries((addedROD) => {
      return [...addedROD, inventory.Stock + ''];
    });

    setShowAddTrailer(false);
  };

  const keyDownState = useRef<{ [key: string]: boolean | undefined }>({});

  useEffect(() => {
    const onKeyUpHandler = (e: KeyboardEvent) => {
      const keyDownStateCopy = cloneDeep(keyDownState.current);
      keyDownStateCopy[e.key] = false;
      keyDownState.current = keyDownStateCopy;
    };
    const onKeyDownHandler = (e: KeyboardEvent) => {
      const keyDownStateCopy = cloneDeep(keyDownState.current);
      keyDownStateCopy[e.key] = true;
      keyDownState.current = keyDownStateCopy;
    };

    window.addEventListener('keyup', onKeyUpHandler);

    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('keyup', onKeyUpHandler);
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, []);

  const handleChange = async (selectedOption: SingleValue<SearchForStocksOption>) => {
    // verify it is only sending back whole stock numbers
    // Then call database to fetch inventory and add another DeliveryDto --- may not need to call backend, ignore until after testing.
    if (selectedOption != undefined) {
      const result = addedInventories.find(
        (recordStockNumber) => recordStockNumber + '' === selectedOption?.label
      );

      //TODO: REview this to allow for changing location of existing stock number.

      if (result != undefined) {
        alert(
          `Cannot use a stock number that has already been previously added! Stock number ${selectedOption.label} is already inside of a yardmap`
        );
        return;
      }

      const newInvnetory = new InventoryDto();
      newInvnetory.Stock = selectedOption.value;

      // updateSalesOrderProperty('Deliveries')([...previousDeliveries, newDelivery]);

      setYardMap((oldYardMap) => {
        const previousInventories = oldYardMap.Inventory;
        const updatedInventories = [...previousInventories, newInvnetory];

        const updatedYardMap = { ...oldYardMap };
        updatedYardMap.Inventory = updatedInventories;

        //Unsure if this can be improved upon
        //Extra updating with basically the same information.
        // If there's a way to update based off of the endpoint response
        // Rather than returning updatedROS, that'd be great.

        // Calls the update one
        YardMapService.updateDetails(updatedYardMap).then((response) => {
          if (response.data.isSuccessful) {
            setAddedDeliveries([...addedInventories, selectedOption.value]);
            setYardMap(response.data.model);
          }
        });

        return updatedYardMap;
      });

      setShowAddTrailer(false);
    } else {
      alert('Unhandled Error in UseEffect for SelectedStockNumber: ' + { selectedOption });
    }
  };

  const handleMessagePlaceholder = () => {
    // alert('test');
  };

  const getRecord = useCallback(() => {
    if (identifierNumber == undefined) {
      throw new Error('Identifier Number is undefined!');
    }

    YardMapService.getYardLocationAndInventory(new StringDto(identifierNumber + ''))
      .then(({ data }) => {
        if (data) {
          setYardMap(data.model);
        } else handleMessagePlaceholder();
      })
      .catch((err) => console.error(err));
  }, [identifierNumber, setYardMap]);

  useEffect(() => {
    getRecord();
  }, [identifierNumber, identifierType, getRecord]);

  const updateRecord = async (
    modifiedYardLocation: MakeMethodsOptional<YardLocationDetailsWithInventoryDto>,
    pressingCtrl: boolean,
    wantToAvoidRedirect = false,
    wantToAvoidEditSwapping = false
  ) => {
    // Making it any because it's a modified version of Sales Order and Idk how to make this currently in typescript
    try {
      const data = (await YardMapService.updateDetails(modifiedYardLocation)).data;

      if (data.isSuccessful) {
        if (!wantToAvoidRedirect && !pressingCtrl) {
          history.push(`/yard-location`);
          return;
        }

        setYardMap(data.model);
        if (!wantToAvoidEditSwapping) {
          setIsEditing(false);
        }
      }
    } catch (err: any) {
      console.error(err);
    }
  };

  const handleSubmit = (e?: FormEvent): void => {
    if (e != undefined) {
      e.preventDefault();
    }

    processSubmit(yardMap);
  };

  const processSubmit = (toUpdate: YardLocationDetailsWithInventoryDto, wantToAvoidRedirect = false,
    wantToAvoidEditSwapping = false) => {
    // const modifiedSalesOrder = convertOptionTypesToStrings();

    const pressingCtrl = keyDownState.current?.['Control' as string] ?? false;

    if (!formRef.current?.checkValidity()) {
      formRef.current?.setAttribute('class', 'was-validated');
    } else {
      updateRecord(toUpdate, pressingCtrl, wantToAvoidRedirect, wantToAvoidEditSwapping);
    }
  };

  const handleDebug = () => {
    if (yardMap == undefined) return <></>;

    const entries = Object.entries(yardMap);

    const finalArray = entries.map(([key, value]) => {
      // Removes methods from the keys
      if (value instanceof Function || Array.isArray(value)) {
        return false;
      }

      return (
        <div key={key}>
          <h1>{key}</h1>
          <h2>{value}</h2>
        </div>
      );
    });

    return finalArray;
  };

  const handleInventoryLabelStrip = (record: InventoryDto) => {
    const stockNumber = record.Stock != null && record.Stock !== '' ? `Stock Number ${record.Stock}` : '';
    const make = record.Make != null && record.Make != '' ? `Make ${record.Make}` : '';
    const model = record.Model != null && record.Model != '' ? `Model ${record.Model}` : '';
    const year = record.Year != null && record.Year != 0 ? `Year ${record.Year}` : '';
    const vin = record.VIN != null && record.VIN != '' ? `Vin ${record.VIN}` : '';

    format(new Date(2014, 1, 11), 'MM/dd/yyyy');

    return (
      <>
        <span
          style={{
            marginRight: '1rem',
          }}>{`ROD ${stockNumber}  ${make}  ${model}  ${year} ${vin}`}</span>
      </>
    );
  };

  const removeInventoryFromList = (stockNumber: string) => {
    const targetInvToDelete = yardMap.Inventory.find((inv) => inv.Stock === stockNumber);

    if (targetInvToDelete == undefined) {
      throw new Error(
        `Attempting to delete Inv Item from Yard Map Inventories but doesn't contain the inventory with stock: ${stockNumber}`
      );
    }

    setYardMap((oldYardMap) => {
      const duplicated = { ...oldYardMap };

      duplicated.Inventory = oldYardMap.Inventory.filter((inv) => inv.Stock !== stockNumber);

      processSubmit(duplicated, true, true);

      return duplicated;
    });
  };

  const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

  return (
    <Layout>
      <IonHeader>
        <IonToolbar>
          {isNotNullNorUndefined(location) && isNotNullNorUndefined(location.state) && (
            <NavLink
              to={{ pathname: '/yard-location', state: location.state }}
              className={styles.backToSearchResultsLink}>
              <IonIcon className={styles.backToSearchResultsLinkIcon} icon={chevronBackOutline} />
              Back to search results
            </NavLink>
          )}
          <IonTitle>Update Yard Map</IonTitle>
        </IonToolbar>
      </IonHeader>

      {isEditing ? (
        <IonGrid>
          <form onSubmit={handleSubmit} ref={formRef} data-group="form">
            <IonRow>
              <IonCol>
                <IonButton
                  onClick={changeToView}
                  disabled={!canUserAccessSalesSecurityWithGivenRoles(roles)}>
                  <IonIcon icon={eyeOutline} />
                  View
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow
              style={{ position: 'relative', zIndex: '900' }}
            // sytle={{ position: 'relative', zIndex: '500' }}
            >
              <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                <IonItem fill="solid">
                  <IonLabel>Location Name</IonLabel>
                  <IonInput disabled={true} {...bindIonYardMapProperty('Location Name')}></IonInput>
                </IonItem>
              </IonCol>
            </IonRow>
            <hr />
            <>
              {yardMap.Inventory != undefined && yardMap.Inventory.length > 0 ? (
                <CollapsibleSection
                  records={yardMap.Inventory.sort((a, b) => +b.Stock - +a.Stock)}
                  identifier={'Stock'}
                  labelStrip={handleInventoryLabelStrip}
                  content={(inv) => (
                    //@ts-ignore
                    <InventoryDetails
                      inventory={inv}
                      updateInventory={updateInventory}
                      removeInventoryFromList={removeInventoryFromList}
                      bindIonYardMapCheck={bindIonYardMapCheck}
                      bindIonYardMapProperty={bindIonYardMapProperty}
                      bindIonYardMapDate={bindIonYardMapDate}
                      bindIonYardMapSelect={bindIonYardMapSelect}
                      bindIonYardMapCurrency={bindIonYardMapCurrency}
                      bindYardMapAsyncSelect={bindYardMapAsyncSelect}
                    />
                  )}
                  startingValue={identifierNumber}
                  focusedEntry={focusedEntry}
                  setFocusedEntry={setFocusedEntry}
                />
              ) : (
                <></>
              )}
            </>

            <IonRow>
              <IonLabel>Add Inventory</IonLabel>
              <IonCol
              // hidden={!showAddTrailer}
              >
                {/*// Need to do reverse logic here*/}
                <AsyncSelect
                  //@ts-ignore
                  loadOptions={searchForStocks}
                  value={''}
                  className={styles.asyncSelect}
                  /*
                  Having this value word here basically locks the input to not save any
                  values. This makes it so that when the user tries to add another delivery
                  after adding a previous one the input field will be blank. In contrast without 
                  this value property in this case the input field will have the stock number previously 
                  put into it. But what does work is on the onchange function. Basically if we
                  try to change the value the handleChange will run. But the actual input value
                  will stay the same.
                  When the handleChange runs it takes the value it tried to change and sets it as the
                  stock number for the new delivery.

                  Typescript doesn't seem to like this so I've added @ts-ignore to the onchange and load
                  options that otherwise would've been showing errors

                  TL;DR

                  The benefit of having this value here is that after the user has added one record of
                  delivery, they will show this input and it will already be blank instead of having
                  the stock number from the previous delivery creation.

                  */
                  //@ts-ignore
                  onChange={handleChange}
                  // cacheOptions
                  closeMenuOnSelect
                  placeholder={'Search by Stock Number'}
                />
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonButton type="submit">Submit</IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      ) : (
        <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  onClick={changeToEdit}
                  disabled={!canUserAccessSalesSecurityWithGivenRoles(roles)}>
                  <IonIcon icon={createOutline} />
                  Edit
                </IonButton>
              </IonCol>
              <IonCol>
                <div>
                  <ReactToPrint
                    bodyClass={'test'}
                    copyStyles={false}
                    trigger={() => <IonButton>Print</IonButton>}
                    content={() => {
                      const element = yardMapCondensedViewPrintComponent.current;

                      if (isNotNullNorUndefined(element)) {
                        return element;
                      }
                      const el = document.createElement('p');
                      el.innerText = 'Test taco';
                      el.style.color = 'black';
                      return el;
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <YardMapCondensedView
            yardMap={yardMap}
            ref={yardMapCondensedViewPrintComponent}
            setIsEditing={setIsEditing}
          />
        </>
      )}
    </Layout>
  );
};
