import { AxiosResponse } from 'axios';
import { AsyncSelectLoadOptionsReturnType, OptionType } from '../components/Search/SearchUtils';
import { ReturnAccountingToDoDto } from '../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { ReturnListWithOptionsDto } from '../models/Dtos/GeneralDtos/ReturnListWithOptionsDto';
import { ReturnModelWithMessageDto } from '../models/Dtos/GeneralDtos/ReturnModelWithMessageDto';
import { InventoryDto } from '../models/Dtos/InventoryDtos/InventoryDto';
import { GeneralOptions } from '../models/Dtos/OptionsDtos/GeneralOptions';
import { RecordOfTransferDto } from '../models/Dtos/RecordOfTransferDtos/RecordOfTransferDto';
import { DeliveryDto } from '../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { YardLocationDetailsWithInventoryDto } from '../models/Dtos/YardMapDtos/YardLocationDetailsWithInventoryDto';
import { YardMapDto } from '../models/Dtos/YardMapDtos/YardMapDto';
import { DtoType, RecordType } from '../models/Global';
import {
    AccountingService,
    DeliveryService,
    InventoryService,
    RecordOfTransferService,
    SalesOrderService,
    YardMapService,
} from './apiServices';

export const getCorrespondingQueriedTableMethod = (recordType: RecordType | DtoType) => {
    let functionToCall: (options: GeneralOptions) => Promise<EndpointResponseType>;
    switch (recordType) {
        case RecordType.Inventory:
            functionToCall = InventoryService.getInventory;
            break;
        case RecordType.Delivery:
            functionToCall = DeliveryService.getDeliveryTable;
            break;
        case RecordType.SalesOrder:
            functionToCall = SalesOrderService.getSalesOrderList;
            break;
        case RecordType.RecordOfTransfer:
            functionToCall = RecordOfTransferService.getRecordOfTransferList;
            break;
        case RecordType.YardMap:
            functionToCall = YardMapService.getYardLocations;
            break;
        case RecordType.AccountingTodo:
            functionToCall = AccountingService.getAccountingToDo;
            break;
        default:
            functionToCall = InventoryService.getInventory;
    }
    return functionToCall;
};

export const getViewPageUrlForDtoType = (dtoType: DtoType) => {
    switch (dtoType) {
        case DtoType.Inventory:
            return '/inventory';
        case DtoType.SalesOrder:
            return '/sales-order';
        case DtoType.Delivery:
            return '/sales-order';
        case DtoType.YardMap:
            return '/yard-location';
    }
    throw new Error('Unhandled Dto Type! Dto  Type: ' + dtoType);

    // Only implements Inventory page right now
};

export const dtoTypeToQueriedTableEndpoint = (dtoType: DtoType) => {
    let endpointToCall: (
        options: GeneralOptions
    ) => Promise<
        | ResponseUtilityType<InventoryDto>
        | ResponseUtilityType<SalesOrderDto>
        | ResponseUtilityType<YardMapDto>
        | ResponseUtilityType<RecordOfTransferDto>
        | ResponseUtilityType<ReturnAccountingToDoDto>
        | ResponseUtilityType<DeliveryDto>
    >;
    switch (dtoType) {
        case DtoType.Inventory: {
            endpointToCall = InventoryService.getInventory;
            break;
        }
        case DtoType.RecordOfTransfer: {
            endpointToCall = RecordOfTransferService.getRecordOfTransferList;
            break;
        }
        case DtoType.SalesOrder: {
            endpointToCall = SalesOrderService.getSalesOrderList;
            break;
        }
        // case ModelType.AccoutingTodo: {
        //     endpointToCall = SalesOrderService.getAccountingToDo;
        // }
        case DtoType.Delivery: {
            endpointToCall = DeliveryService.getDeliveryTable;
            break;
        }

        case DtoType.YardMap: {
            endpointToCall = YardMapService.getYardLocations;
            break;
        }

        case DtoType.AccountingTodo: {
            endpointToCall = AccountingService.getAccountingToDo;
            break;
        }

        default: {
            endpointToCall = InventoryService.getInventory;
            break;
        }
    }
    return endpointToCall;
};

export const getIdentifierPropertyFromDtoType = (dtoType: DtoType) => {
    switch (dtoType) {
        case DtoType.AccountingTodo:
            return 'Stock';
        case DtoType.AdminAuditDto:
            return 'identifier';
        case DtoType.Delivery:
            return 'Stock';
        case DtoType.Inventory:
            return 'Stock';
        case DtoType.RecordAuditDto:
            return 'changedDate';

        case DtoType.RecordOfTransfer:
            return 'Id';

        case DtoType.SalesOrder:
            return 'Invoice Number';
        case DtoType.YardMap:
            return 'Yard Stall';
    }
};

export type PropertyNameToOptionsFunctionMapType = {
    inventory: {
        [property: string]: ((searchTerm: string) => AsyncSelectLoadOptionsReturnType<OptionType>) | undefined;
    };
    salesorder: {
        [property: string]: ((searchTerm: string) => AsyncSelectLoadOptionsReturnType<OptionType>) | undefined;
    };
};

export type AllPossibleReturnTypes =
    | InventoryDto
    | SalesOrderDto
    | YardMapDto
    | RecordOfTransferDto
    | DeliveryDto
    | ReturnAccountingToDoDto
    | YardLocationDetailsWithInventoryDto;

export type AllPossibleReturnTypesArray = AllPossibleReturnTypes[];

export type EndpointResponseType = ResponseUtilityType<AllPossibleReturnTypes>;

export type ResponseUtilityType<T> = AxiosResponse<ReturnModelWithMessageDto<ReturnListWithOptionsDto<T>>, unknown>;
