import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import './Dropdown.css';
import DateEnum from '../models/DateEnum';

interface DateDropdownProps {
  setSelectedDate: (date: DateEnum) => void;
  selectedDate: DateEnum;
}

export const DateDropdown = ({ setSelectedDate, selectedDate }: DateDropdownProps) => {
  // State
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  // Functions

  const getClassName = (index: number, isSelected: boolean) => {
    let className = '';
    if (isSelected) {
      className += 'selected-item ';
    }

    if (index === 0) {
      className += 'top-item ';
    } else if (index === -1) {
      className += 'bottom-item ';
    }

    className += 'dropdown-date date-text';
    return className;
  };

  return (
    <Dropdown
      className="dropdown flex justify-end items-center w-[100%]"
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen((dropdownOpen) => !dropdownOpen)}>
      <DropdownToggle className="dropdown-toggle date-text date">{DateEnum[selectedDate]}</DropdownToggle>
      <DropdownMenu className="dropdown-menu-main-date date-text">
        {Object.entries(DateEnum)
          .filter(([key]) => Number(key) >= 0)
          .map(([enumValue, dateString], index: number) => {
            return (
              <DropdownItem
                key={dateString}
                className={
                  DateEnum[selectedDate] === dateString
                    ? getClassName(index, true)
                    : getClassName(index, false)
                }
                onClick={() => setSelectedDate(Number(enumValue))}>
                {dateString}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    </Dropdown>
  );
};
