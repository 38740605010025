/* Keeping the Model type here to ensure it can be deleted from local storage */

export enum ModelType {
    Inventory,
    SalesOrder,
    RecordOfTransfer,
    Delivery,
    YardMap,
    AccoutingTodo,
    AdminAuditDto,
    RecordAuditDto,
}
