import {
    IonButton,
    IonChip,
    IonCol,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { DtoType, FilterType } from '../../../../models/Global';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../../../utils/NullOrUndefined';
import GeneralToolTip from '../../../GeneralToolTip/GeneralToolTip';
import { canUserAccessAdminSecurityWithGivenRoles, RoleContext } from '../../../RoleContext/RoleContextComp';
import styles from './GlobalUserFilterManager.module.scss';

type GlobalUserFilterModalPropTypes = {
    handleCreateUpdateUserFilter: (userFilterName: string, updating: boolean) => void;
    handleCreateUpdateGlobalFilter: (globalFilterName: string, updating: boolean) => void;

    handleDeleteUserFilter: (userFilterName: string) => void;
    handleDeleteGlobalFilter: (globalFilterName: string) => void;
    model: object;
    closeModal: () => void;
    dtoType: DtoType;

    selectedUserFilterNames: string[];
    selectedGlobalFilterNames: string[];
    globalFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
    userFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalUserFilterManager = ({
    handleCreateUpdateUserFilter,
    handleCreateUpdateGlobalFilter,
    handleDeleteUserFilter,
    handleDeleteGlobalFilter,
    model,
    closeModal,
    dtoType,
    selectedUserFilterNames,
    selectedGlobalFilterNames,
    globalFiltersStorage,
    userFiltersStorage,
    setIsLoading,
}: GlobalUserFilterModalPropTypes) => {
    // TODO: JUST GRABBING THE FIRST ONE NEED TO FIX THIS LATER
    // NEED TO REWORK THIS SELECTION!

    // FOR ADDING

    const [newFilterName, setNewFilterName] = useState<string>();

    // FOR EDITING
    const [renameFilterWith] = useState<string>();

    const [targetFilterToUpdate, setTargetFilterToUpdate] = useState<string | null>();

    const roles = useContext(RoleContext);

    const [isTargetingUserFilter, setIsTargetingUserFilter] = useState<boolean>(
        !canUserAccessAdminSecurityWithGivenRoles(roles) ? true : selectedGlobalFilterNames.length > 0 ? false : true
    );

    const [isCreating, setIsCreating] = useState<boolean>(true);

    const handleAddingSubmit = () => {
        if (isNullOrUndefined(newFilterName) || newFilterName.trim() === '' || newFilterName.trim() === ' ') {
            alert('Please provide a name for the new filter!');
            return;
        }

        setNewFilterName('');

        if (isTargetingUserFilter) {
            handleCreateUpdateUserFilter(newFilterName, false);
            closeModal();
        } else {
            handleCreateUpdateGlobalFilter(newFilterName, false);
            closeModal();
        }

        setIsLoading(true);
    };

    const getAddingTSX = () => {
        return (
            <>
                <IonRow style={{ position: 'relative', zIndex: '500' }}>
                    <IonCol style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <p>Global Vs User Filters</p>
                        <GeneralToolTip
                            content={[
                                'Global Filters are the same for all users.',
                                'Global Filters will show at the top of a table above the search bar',
                                'User Filters are filters that you create yourself that are personal to you.',
                                'User Filters will be shown inbetween the serach bar and the table itself',
                            ]}
                        />
                    </IonCol>
                    <IonCol>
                        <IonCol style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <p>How to Create Filter</p>
                            <GeneralToolTip
                                content={[
                                    'Ensure you have all of the filter options you want to save.',
                                    "If you haven't already, click on the Manage Custom Filters button",
                                    'and choose filter options you want to save',
                                    "Once you've done that simply type in a name and submit. ",
                                ]}
                            />
                        </IonCol>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem fill="solid">
                            <IonLabel position="stacked" style={{ fontWeight: '700' }}>
                                Name Of New Filter
                            </IonLabel>
                            <IonInput
                                type="text"
                                value={newFilterName}
                                onIonInput={(e) => {
                                    const t = e.target.value;
                                    setNewFilterName(isNotNullNorUndefined(t + '') ? t + '' : undefined);
                                }}></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Submit</IonLabel>
                            <IonButton onClick={handleAddingSubmit}>Submit</IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </>
        );
    };

    const handleEditingSubmit = () => {
        if (isNullOrUndefined(targetFilterToUpdate)) {
            alert("You must have a filter selected if you'd like to edit something!");
            return;
        }

        if (!isTargetingUserFilter) {
            //TODO: ADD IMPLEMENTATION FOR RENAME FILTER WITH
            handleCreateUpdateGlobalFilter(targetFilterToUpdate, true);
            closeModal();
        } else {
            //TODO: ADD IMPLEMENTATION FOR RENAME FILTER WITH
            handleCreateUpdateUserFilter(targetFilterToUpdate, true);
            closeModal();
        }
        setIsLoading(true);
    };

    const handleDeleteButton = () => {
        if (isNullOrUndefined(targetFilterToUpdate)) {
            alert("You must have a filter selected if you'd like to delete something!");
            return;
        }

        setIsTargetingUserFilter(true);
        if (isTargetingUserFilter) {
            handleDeleteUserFilter(targetFilterToUpdate);
            closeModal();
        } else {
            handleDeleteGlobalFilter(targetFilterToUpdate);
            closeModal();
        }
    };

    const getEditingTSX = (isAdmin: boolean) => {
        return (
            <>
                {/* 

                ******************************************************
                Disabling this feature as it's currently not supported
                ******************************************************
                
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Rename Filter With</IonLabel>
                            <IonInput
                                type="text"
                                value={renameFilterWith}
                                onIonChange={(e) =>
                                    isNotNullNorUndefined(e.detail.value) ? e.detail.value : undefined
                                }></IonInput>
                        </IonItem>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <IonItem fill="solid">
                            <IonLabel position="stacked" style={{ fontWeight: '700' }}>
                                Please choose a filter to edit.
                            </IonLabel>
                            {(() => {
                                const mappedSelectedGlobalFilters = selectedGlobalFilterNames.map((x) => ({
                                    name: x,
                                    filterType: FilterType.Global,
                                    selected: true,
                                }));
                                const mappedSelectedUserFilters = selectedUserFilterNames.map((x) => ({
                                    name: x,
                                    filterType: FilterType.User,
                                    selected: true,
                                }));

                                const mappedUnselectedGlobalFilters = globalFiltersStorage
                                    .filter((x) => !selectedGlobalFilterNames.includes(x.filterName))
                                    .map((x) => ({
                                        name: x.filterName,
                                        filterType: FilterType.Global,
                                        selected: false,
                                    }));
                                const mappedUnselectedUserFilters = userFiltersStorage
                                    .filter((x) => !selectedGlobalFilterNames.includes(x.filterName))
                                    .map((x) => ({
                                        name: x.filterName,
                                        filterType: FilterType.User,
                                        selected: false,
                                    }));

                                // const filtersToShow = canUserAccessAdminSecurityWithGivenRoles(roles)
                                //     ? [...mappedUserFilters, ...mappedGlobalFilters]
                                //     : [...mappedUserFilters];

                                let selectedFitlers: {
                                    name: string;
                                    filterType: FilterType;
                                    selected: boolean;
                                }[] = [];
                                if (isTargetingUserFilter) {
                                    // Targeting user filters. only show user filers
                                    selectedFitlers = mappedSelectedUserFilters;
                                } else {
                                    if (canUserAccessAdminSecurityWithGivenRoles(roles)) {
                                        selectedFitlers = mappedSelectedGlobalFilters;
                                    }
                                }

                                let unselectedFitlers: {
                                    name: string;
                                    filterType: FilterType;
                                    selected: boolean;
                                }[] = [];
                                if (isTargetingUserFilter) {
                                    // Targeting user filters. only show user filers
                                    unselectedFitlers = mappedUnselectedUserFilters;
                                } else {
                                    if (canUserAccessAdminSecurityWithGivenRoles(roles)) {
                                        unselectedFitlers = mappedUnselectedGlobalFilters;
                                    }
                                }

                                return (
                                    <IonSelect
                                        value={targetFilterToUpdate}
                                        onIonChange={(e) => setTargetFilterToUpdate(e.detail.value)}>
                                        <IonSelectOption disabled={true} className={styles.selectHeader}>
                                            Selected Filters:
                                        </IonSelectOption>
                                        {selectedFitlers.length > 0 ? (
                                            selectedFitlers.map(({ name, filterType }) => {
                                                return (
                                                    <IonSelectOption
                                                        class={
                                                            filterType === FilterType.User
                                                                ? styles.userFilter
                                                                : styles.globalFilter
                                                        }
                                                        // className={styles.globalFilter}
                                                        key={`name-${name}-filterType-${FilterType[filterType]}`}
                                                        value={name}>
                                                        {/* 
                                                        Contemplating adding logic here to ensure it's using title case.
                                                        However, the names of Global/User Filters will be direcetly from the user.
                                                        So we'll just have it as they want it.
                                                        */}
                                                        {name} - {filterType === FilterType.Global ? 'G' : 'U'}
                                                    </IonSelectOption>
                                                );
                                            })
                                        ) : (
                                            <IonSelectOption disabled={true} className={styles.selectItemMessage}>
                                                No Selected Filters
                                            </IonSelectOption>
                                        )}

                                        <IonSelectOption disabled={true} className={styles.selectHeader}>
                                            Unselected Filters:
                                        </IonSelectOption>

                                        {unselectedFitlers.length > 0 ? (
                                            unselectedFitlers.map(({ name, filterType }) => {
                                                return (
                                                    <IonSelectOption
                                                        class={
                                                            filterType === FilterType.User
                                                                ? styles.userFilter
                                                                : styles.globalFilter
                                                        }
                                                        // className={styles.globalFilter}
                                                        key={`name-${name}-filterType-${FilterType[filterType]}`}
                                                        value={name}>
                                                        {/* 
                                                        Contemplating adding logic here to ensure it's using title case.
                                                        However, the names of Global/User Filters will be direcetly from the user.
                                                        So we'll just have it as they want it.
                                                        */}
                                                        {name} - {filterType === FilterType.Global ? 'G' : 'U'}
                                                    </IonSelectOption>
                                                );
                                            })
                                        ) : (
                                            <IonSelectOption disabled={true} className={styles.selectItemMessage}>
                                                No Unselected Filters
                                            </IonSelectOption>
                                        )}
                                    </IonSelect>
                                );
                            })()}
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Submit</IonLabel>
                            <IonButton
                                disabled={
                                    isNotNullNorUndefined(targetFilterToUpdate) &&
                                    !isTargetingUserFilter &&
                                    !canUserAccessAdminSecurityWithGivenRoles(roles)
                                }
                                onClick={handleEditingSubmit}>
                                Submit
                            </IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
                {(isTargetingUserFilter || (!isTargetingUserFilter && isAdmin)) && (
                    <>
                        <hr />
                        <IonRow>
                            <IonCol>
                                <IonItem>
                                    <IonButton
                                        disabled={
                                            (isNotNullNorUndefined(targetFilterToUpdate) &&
                                                !isTargetingUserFilter &&
                                                !canUserAccessAdminSecurityWithGivenRoles(roles)) ||
                                            // (!isTargetingUserFilter &&
                                            //     targetFilterToUpdate?.toLowerCase() === 'main') ||
                                            (!isTargetingUserFilter && targetFilterToUpdate?.toLowerCase() === 'all')
                                        }
                                        onClick={handleDeleteButton}
                                        color={'danger'}>
                                        Delete
                                    </IonButton>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <>
                {/*Chose if adding or editing*/}
                <IonRow style={{ backgroundColor: '#002b70', marginLeft: '0px', marginRight: '0px' }}>
                    <IonCol style={{ position: 'relative', zIndex: '82' }}>
                        <GeneralToolTip
                            content={['Shows the Creating utility form', 'This allows you to create new filters']}>
                            <IonChip
                                className={`${styles.creatingChip} ${isCreating ? styles.selectedChip : ''}`}
                                onClick={() => {
                                    setIsCreating(true);
                                }}>
                                Creating
                                <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                            </IonChip>
                        </GeneralToolTip>
                    </IonCol>
                    <IonCol style={{ position: 'relative', zIndex: '83' }}>
                        <GeneralToolTip content={['Shows the Editing utility form', 'This allows you to edit filters']}>
                            <IonChip
                                className={`${styles.editingChip} ${!isCreating ? styles.selectedChip : ''}`}
                                onClick={() => {
                                    setIsCreating(false);
                                    if (isNullOrUndefined(targetFilterToUpdate)) {
                                        return;
                                    }

                                    if (canUserAccessAdminSecurityWithGivenRoles(roles)) {
                                        if (!isTargetingUserFilter) {
                                            setIsTargetingUserFilter(false);
                                        } else {
                                            setIsTargetingUserFilter(true);
                                        }
                                    } else {
                                        setIsTargetingUserFilter(true);
                                    }
                                }}>
                                Editing
                                <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                            </IonChip>
                        </GeneralToolTip>
                    </IonCol>
                </IonRow>
            </>
            {/*If admin choose if want to work with Global or User Filters*/}
            <IonRow style={{ backgroundColor: '#002b70', marginLeft: '0px', marginRight: '0px' }}>
                {dtoType !== DtoType.RecordOfTransfer && (
                    <IonCol style={{ position: 'relative', zIndex: '80' }}>
                        <GeneralToolTip content={['Create/Edit User Filters', 'These are filters personal to you.']}>
                            <IonChip
                                className={`${styles.userFiltersChip} ${
                                    isTargetingUserFilter ? styles.selectedChip : ''
                                }`}
                                onClick={() => {
                                    setIsTargetingUserFilter(true);
                                }}>
                                UserFilters
                                <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                            </IonChip>
                        </GeneralToolTip>
                    </IonCol>
                )}
                {canUserAccessAdminSecurityWithGivenRoles(roles) && (
                    <IonCol style={{ position: 'relative', zIndex: '81' }}>
                        <GeneralToolTip
                            content={['Create/Edit Global Filters', 'These are filters that are shown for all users']}>
                            <IonChip
                                className={`${styles.globalFiltersChip} ${
                                    !isTargetingUserFilter ? styles.selectedChip : ''
                                }`}
                                onClick={() => {
                                    setIsTargetingUserFilter(false);
                                }}>
                                Global Filters
                                <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                            </IonChip>
                        </GeneralToolTip>
                    </IonCol>
                )}
            </IonRow>
            <IonGrid className={styles.ionGrid}>
                {/* {!isCreating && isNullOrUndefined(targetFilterToUpdate) ? (
                    <IonRow>
                        <IonCol>
                            <h1 className={styles.selecteFilterHeader}>Please select a filter to continue</h1>
                        </IonCol>
                    </IonRow>
                ) : ( */}
                <>{isCreating ? getAddingTSX() : getEditingTSX(canUserAccessAdminSecurityWithGivenRoles(roles))}</>
                {/* )} */}
            </IonGrid>
        </>
    );
};

export default GlobalUserFilterManager;
