export type SelectingMode = "copy" | "email" | "unselected";

import { AxiosResponse } from "axios";
import cloneDeep from "lodash.clonedeep";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createRoot } from "react-dom/client";
import { useHistory, useLocation } from "react-router";
import { useCustomLogicOnEdit } from "../../../../hooks/useCustomLogicOnEdit";
import { ComparisonOperatorEnum } from "../../../../models/ComparisonOperator";
import { ReturnAccountingToDoDto } from "../../../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto";
import { AdvertisementEmailerDto } from "../../../../models/Dtos/AdvertisementEmailerDto";
import { ReturnMessageOnlyDto } from "../../../../models/Dtos/GeneralDtos/ReturnMessageOnlyDto";
import { ReturnModelWithMessageDto } from "../../../../models/Dtos/GeneralDtos/ReturnModelWithMessageDto";
import { GetGlobalFilterType } from "../../../../models/Dtos/GetGlobalFilterDto";
import { InventoryDto } from "../../../../models/Dtos/InventoryDtos/InventoryDto";
import { DynamicFilter } from "../../../../models/Dtos/OptionsDtos/DynamicFilter";
import { DynamicFilterWithSourceInfo } from "../../../../models/Dtos/OptionsDtos/DynamicFilterWithSourceInfo";
import {
  DynamicSort,
  DynamicSortWithoutSortPriority,
} from "../../../../models/Dtos/OptionsDtos/DynamicSort";
import {
  createNewGeneralOptionsColumnsToShowAndColumnOrderAsArray,
  GeneralOptions,
  GeneralOptionsColumnsToShowAndColumnOrderAsArray,
} from "../../../../models/Dtos/OptionsDtos/GeneralOptions";
import { SortOptions } from "../../../../models/Dtos/OptionsDtos/SortOptions";
import { UserGlobalFilterSpecificGeneralOption } from "../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption";
import { RecordOfTransferDto } from "../../../../models/Dtos/RecordOfTransferDtos/RecordOfTransferDto";
import { DeliveryDto } from "../../../../models/Dtos/SalesOrderDtos/DeliveryDto";
import { SalesOrderDto } from "../../../../models/Dtos/SalesOrderDtos/SalesOrderDto";
import { YardMapDto } from "../../../../models/Dtos/YardMapDtos/YardMapDto";
import {
  DataType,
  DtoType,
  FilterType,
  RecordType,
} from "../../../../models/Global";
import { UserGlobalFilterStorageType } from "../../../../models/UserGlobalFIlterStorageType";
import AllColumnsRecordTypeMapper, {
  MakeANewArrayEnsuringAllColumnsArePresentForProvidedRecordType,
} from "../../../../utils/AllColumnsRecordTypeMapper";
import {
  AccountingService,
  AdvertisementEmailerService,
  DeliveryService,
  InventoryService,
  SalesOrderService,
  YardMapService,
} from "../../../../utils/apiServices";
import { getColumnsToIgnoreFromRecordType } from "../../../../utils/ColumnsToIgnoreMapper";
import { splitByComma } from "../../../../utils/ColumnsToShowUtils";
import confirmingTableUpdate from "../../../../utils/ConfirmingTableUpdate";
import { convertAllNullToEmptyStringFromObject } from "../../../../utils/ConvertAllNullToEmptyStringUtil";
import convertStringArrayGeneralOptionsToNormal from "../../../../utils/ConvertStringArrayGeneralOptionsToNormal";
import { convertFromStringIfBooleanOrNumber } from "../../../../utils/DataTypeConversionFromString/DataTypeConversionFromString";
import { getDataTypeByRecordTypeTypeAndPropertyName } from "../../../../utils/DataTypeMapperUtil";
import { convertToInputDateFormat } from "../../../../utils/DateUtils";
import { IdentifierType } from "../../../../utils/identifierType";
import { getUnfilteredGeneralOptions } from "../../../../utils/InventoryOptionsFactory";
import {
  addGeneratedIdIfNoIdOrAddSpecifiedId,
  doesObjectHaveGeneratedId,
  GeneratedIdType,
  getIdIfItExistsOrReturnsNull,
  removeGeneratedId,
} from "../../../../utils/KeyIdUtils";
import makeSimpleToast from "../../../../utils/MakeSimpleToast";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
  isNullOrUndefinedOrEmptyString,
} from "../../../../utils/NullOrUndefined";
import {
  AllPossibleReturnTypes,
  AllPossibleReturnTypesArray,
  EndpointResponseType,
  getCorrespondingQueriedTableMethod,
} from "../../../../utils/RecordTypeUtils";
import AccountingTodoSpecializedTableRender from "../../../AccountingTodoSpecializedTableRender/AccountingTodoSpecializedTableRender";
import DeliverySpecializedTableRender from "../../../DeliverySpecializedTableRender/DeliverySpecializedTableRender";
import InventorySpecalizedTableRender from "../../../InventorySpecalizedTableRender/InventorySpecializedTableRender";
import SalesOrderSpecializedTableRender from "../../../SalesOrderSpecializedTableRender/SalesOrderSpecializedTableRender";
import YardMapSpecializedTableRender from "../../../YardMapSpecializedTableRender/YardMapSpecializedTableRender";
import styles from "./QueriedTableSortFilterStateManager.module.scss";

export type LocationType = {
  selectedGlobalFilterNames: string[];
  selectedUserFilterNames: string[];
  customFilters: GeneralOptions;
  ignoredFilterIds: string[];
  fromDelivery?: boolean;
  fromAccountingTodo?: boolean;
  fromInventory?: boolean;
  fromSalesOrder?: boolean;
};

export type WrappedLocationType = {
  pathname: string;
  state: LocationType;
};

// export type LocationDataType = {
//     filterInfoStorage: UserGlobalFilterStorageType[];
//     generalOptions: GeneralOptions;
//     data: AllPossibleReturnTypesArray;
// };

const DEBOUNCE_WAIT_TIME = 1000;

export type HandleEndPointCallParameters = {
  endpoint: (
    options: GeneralOptions | SortOptions
  ) => Promise<EndpointResponseType>;
  generalOptionsToUse: GeneralOptions;
  callback?: () => unknown;
};

export type GlobalFilterGenearlOptionsWrapper = {
  generalOptions: GeneralOptions;
  info: UserGlobalFilterStorageType;
};
export type GlobalFilterReferenceType = {
  [filterName: string]: GlobalFilterGenearlOptionsWrapper;
};

export interface QueriedTableSortFilterStateManagerExportProps {
  endpoint: (
    options: GeneralOptions | SortOptions
  ) => Promise<EndpointResponseType>;
  toolbar?: boolean;
  identifier: string;
  identifierType: IdentifierType;
  handleSetCustomFiltersValueFilters: (dynamicFilter: DynamicFilter[]) => void;
  handleCreateUpdateUserFilter: (
    userFilterName: string,
    updating: boolean
  ) => void;
  selectedUserFilterNames: string[];
  selectedGlobalFilterNames: string[];
  justSortOptions: boolean;
  overwritingProvidedData:
    | InventoryDto[]
    | SalesOrderDto[]
    | RecordOfTransferDto[]
    | YardMapDto[]
    | ReturnAccountingToDoDto[];
  model: object;
  useCustomEndpoint: boolean;
  setUseCustomEndpoint(value: boolean): void;
  recordType: RecordType;
  handleClearCancel: () => void;
  isFilterSelected: ({
    name,
    filterType,
  }: Omit<UserGlobalFilterStorageType, "globalFilterRow">) => boolean;

  handleCreateUpdateGlobalFilter: (
    globalFilterName: string,
    updating: boolean
  ) => void;
  handleDeleteGlobalFilter: (globalFilterName: string) => void;
  handleDeleteUserFilter: (userFilterName: string) => void;
  handleUpdatingItemsPerPage: (itemsPerPage: number) => void;
  setOverwritingProvidedData: React.Dispatch<
    React.SetStateAction<
      | InventoryDto[]
      | SalesOrderDto[]
      | RecordOfTransferDto[]
      | YardMapDto[]
      | ReturnAccountingToDoDto[]
    >
  >;

  setSortFilters: (dynamicSorts: DynamicSortWithoutSortPriority[]) => void;
  dtoType: DtoType;
  tabbed: boolean;
  handleGlobalFilterButtonClick: (globalFilterName: string) => Promise<void>;
  data: AllPossibleReturnTypesArray;
  setData: React.Dispatch<React.SetStateAction<AllPossibleReturnTypesArray>>;
  computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
  handleUserFilterButtonClick: (userFilterName: string) => Promise<void>;
  setItemsPerPage: (itemsPerPage: number) => void;
  // filterInfoStorage: UserGlobalFilterStorageType[];
  userFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
  globalFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
  computedSelectedUserFilters: UserGlobalFilterSpecificGeneralOption[];
  computedSelectedGlobalFilters: UserGlobalFilterSpecificGeneralOption[];
  DEBOUNCE_WAIT_TIME: number;
  handleChangeSorting: (columnName: string) => void;
  handleOrderColumnToLeft: (columnName: keyof AllPossibleReturnTypes) => void;
  handleOrderColumnToRight: (columnName: keyof AllPossibleReturnTypes) => void;
  updateCurrentPageNumber: (pageNumber: number) => void;
  setColumnVisibility: (updatedColumnsToShowList: string[]) => void;
  selectingRowMode: SelectingMode;
  startSelecting: (
    selectingMode: Exclude<SelectingMode, "unselected">,
    ids?: string[]
  ) => void;
  stopSelectingAndCopyTableWithStyles: () => void;
  stopSelectingAndEmailAdvertisements: (email: string) => void;
  registerSelection: (identifierValue: string) => void;
  unselectRow: (identifierValue: string) => void;
  isRowIdSelected: (identifierValue: string) => boolean;
  selectAllRows: (ids: string[]) => void;
  unselectAllRows: () => void;
  cancelSelecting: () => void;
  scrollTop: number;
  validLocationState: boolean;
  setColumnOrder: (updatedColumnOrder: string[]) => void;
  customFilters: GeneralOptions;
  unselectGlobalFilter: (globalfilterName: string) => boolean;
  unselectUserFilter: (globalfilterName: string) => boolean;
  ignoreFilter: (filterId: string) => boolean;
  unignoreFilter: (filterId: string) => boolean;
  isFilterIgnored: (filterId: string) => boolean;
  combinedFilterTargetProperties: string[];
  updateSearchTerm: (searchTerm: string) => void;
  handleNewUpdaterFunction: <T extends AllPossibleReturnTypes>(
    recordType: RecordType,
    newValueOrChecked: string | number | boolean | undefined,
    propertyName: keyof T,
    value: string,
    oldValue: unknown,
    onCancelledCallback?: () => void,

    onSuccess?: (value: string) => void
  ) => Promise<boolean>;
  ignoredFilterIds: string[];
  callEndpointAgain: () => void;
  isLoading: boolean;

  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QueriedTableSortFilterStateManagerExportPropsDefaultValues: {
  [key in keyof QueriedTableSortFilterStateManagerExportProps]: QueriedTableSortFilterStateManagerExportProps[key];
} = {
  endpoint: (options: GeneralOptions | SortOptions) =>
    InventoryService.getInventory(
      getUnfilteredGeneralOptions(FilterType.Custom, "")
    ),
  identifier: "Stock",
  identifierType: IdentifierType.Stock,
  handleSetCustomFiltersValueFilters: (dynamicFilter: DynamicFilter[]): void =>
    undefined,
  handleCreateUpdateUserFilter: (
    userFilterName: string,
    updating: boolean
  ): void => undefined,
  handleUserFilterButtonClick: async (userFilter: string): Promise<void> =>
    undefined,
  justSortOptions: false,
  overwritingProvidedData: [],
  model: { test: "test" },
  useCustomEndpoint: false,
  setUseCustomEndpoint: (value: boolean): void => undefined,
  recordType: RecordType.Inventory,
  handleClearCancel: (): void => undefined,
  isFilterSelected: ({
    name,
    filterType,
  }: Omit<UserGlobalFilterStorageType, "globalFilterRow">) => false,
  handleCreateUpdateGlobalFilter: (
    globalFilterName: string,
    updating: boolean
  ): void => undefined,
  handleDeleteGlobalFilter: (): void => undefined,
  handleDeleteUserFilter: (): void => undefined,
  handleUpdatingItemsPerPage: (itemsPerPage: number): void => undefined,
  setOverwritingProvidedData: (
    value: SetStateAction<
      | InventoryDto[]
      | SalesOrderDto[]
      | RecordOfTransferDto[]
      | YardMapDto[]
      | ReturnAccountingToDoDto[]
    >
  ): void => undefined,
  setSortFilters: (dynamicSorts: DynamicSortWithoutSortPriority[]): void =>
    undefined,
  dtoType: DtoType.Inventory,
  tabbed: true,
  handleGlobalFilterButtonClick: async (globalFilter: string): Promise<void> =>
    undefined,
  data: [],
  setData: () => undefined,
  computedCombinedFilters:
    createNewGeneralOptionsColumnsToShowAndColumnOrderAsArray(),
  setItemsPerPage: (itemsPerPage: number) => undefined,
  DEBOUNCE_WAIT_TIME: DEBOUNCE_WAIT_TIME,
  handleChangeSorting: (columnName: string) => undefined,
  handleOrderColumnToLeft: () => undefined,
  handleOrderColumnToRight: (columnName: keyof AllPossibleReturnTypes) =>
    undefined,
  updateCurrentPageNumber: () => undefined,
  setColumnVisibility: () => undefined,
  selectingRowMode: "unselected",
  startSelecting: (
    selectingMode: Exclude<SelectingMode, "unselected">,
    ids?: string[]
  ) => undefined,
  stopSelectingAndCopyTableWithStyles: () => undefined,
  stopSelectingAndEmailAdvertisements: (email: string) => undefined,
  registerSelection: (identifierValue: string) => undefined,
  unselectRow: (identifierValue: string) => undefined,
  isRowIdSelected: (identifierValue: string) => false,
  selectAllRows: (ids: string[]) => undefined,
  unselectAllRows: () => undefined,
  cancelSelecting: () => undefined,
  scrollTop: 0,

  validLocationState: false,
  userFiltersStorage: [],
  globalFiltersStorage: [],
  computedSelectedUserFilters: [],
  computedSelectedGlobalFilters: [],
  selectedUserFilterNames: [],
  selectedGlobalFilterNames: [],

  setColumnOrder: (updatedColumnOrder: string[]) => undefined,
  customFilters: new GeneralOptions(),

  unselectGlobalFilter: () => true,
  unselectUserFilter: () => true,
  ignoreFilter: (filterId: string) => true,
  unignoreFilter: (filterId: string) => true,
  isFilterIgnored: (filterId: string) => false,
  combinedFilterTargetProperties: [],

  updateSearchTerm: (searchTerm: string) => undefined,
  handleNewUpdaterFunction: <T extends AllPossibleReturnTypes>(
    recordType: RecordType,
    newValueOrChecked: string | number | boolean | undefined,
    propertyName: keyof T,
    value: string,
    oldValue: unknown
  ) =>
    new Promise((resolve, reject) => {
      resolve(true);
    }),

  ignoredFilterIds: [],
  callEndpointAgain: () => undefined,

  isLoading: false,
  setIsLoading: () => undefined,
};

type QueriedTableSortFilterStateManagerProps<T> = {
  endpoint: (
    options: GeneralOptions | SortOptions
  ) => Promise<EndpointResponseType>;
  toolbar: boolean;
  identifier: string;
  identifierType: IdentifierType;
  justSortOptions: boolean;
  model: object;
  recordType: RecordType;
  tabbed: boolean;
  children: (
    exportProps: QueriedTableSortFilterStateManagerExportProps
  ) => React.ReactElement;
  dtoType: DtoType;
  scrollTop: number;
};

export type GenericObjectDictionary<T> = {
  [key: string]: T;
};
export type SortFiltersType = [propertyName: string, ascending: boolean];

export enum ColumnsVisibilityStatusEnum {
  UserSetHidden,
  UserSetShown,
  FilterSavedAsShown,
  FilterSavedAsHidden,
}

export const getOnlyShownColumns = (
  columnsVisibilityStatus: ColumnsVisibilityStatusWrapper[]
) => {
  const shownColumns = columnsVisibilityStatus.filter((s) =>
    shouldColumnBeVisible(s.status)
  );
  return shownColumns;
};

export const sortColumnsVisibillityItemsByOrder = (
  columnsVisibilityStatus: ColumnsVisibilityStatusWrapper[]
) => {
  const duplicatedArrayForInPlaceSort = [...columnsVisibilityStatus];
  // Duplicated array beacuse .sort modifies array inplace. Do not want to modify the columnsVIsibilityStatus array

  duplicatedArrayForInPlaceSort.sort((a, b) => {
    if (a.order < b.order) {
      return -1; // Return a negative because first value is less than second value;
    }

    if (a.order === b.order) {
      return 0; // Return 0 because both values are the same
    }

    if (a.order > b.order) {
      return 1; // Return 1 because first argument is greater than second argument
    }

    throw new Error("Comparison wasn't caught inside 'sortItemsByOrder'!");
  });

  return duplicatedArrayForInPlaceSort;
};

export const getOnlyHiddenColumns = (
  columnsVisibilityStatus: ColumnsVisibilityStatusWrapper[]
) => {
  const hiddenProperties = columnsVisibilityStatus.filter((s) => {
    return !shouldColumnBeVisible(s.status);
  });
  return hiddenProperties;
};

export const shouldColumnBeVisible = (status: ColumnsVisibilityStatusEnum) => {
  switch (status) {
    case ColumnsVisibilityStatusEnum.FilterSavedAsHidden:
      return false;
    case ColumnsVisibilityStatusEnum.FilterSavedAsShown:
      return true;
    case ColumnsVisibilityStatusEnum.UserSetHidden:
      return false;
    case ColumnsVisibilityStatusEnum.UserSetShown:
      return true;
  }
};

export const getStringVersionOfVisibleColumnsVisibilhtyStatusColumnName = (
  columnsVisibilityStatus: ColumnsVisibilityStatusWrapper[]
) => {
  const sortedColumnsVisibilityStatus = sortColumnsVisibillityItemsByOrder(
    columnsVisibilityStatus
  );

  const filteredString = sortedColumnsVisibilityStatus.reduce(
    (previous, current) => {
      const status = current.status;

      if (shouldColumnBeVisible(status)) {
        return `${previous},${current.columnName}`;
      }
      return previous;
    },
    ""
  );

  return filteredString.substring(1); // Removes first comma ,
};
export const getArrayVersionOfColumnsVisibilityStatusPropertyNames = (
  columnsVisibilityStatus: ColumnsVisibilityStatusWrapper[]
) => {
  const array = columnsVisibilityStatus.map((s) => s.columnName);
  return array;
};

export type ColumnsVisibilityStatusWrapper = {
  columnName: string;
  status: ColumnsVisibilityStatusEnum;
  order: number;
};

export type ObjectWithStoredDefaultColumnsToShowAndPropertyNames = {
  columnsToShow: string;
  propertyNames: string[];
};

const QueriedTableSortFilterStateManager = <T extends AllPossibleReturnTypes>({
  endpoint,
  justSortOptions,
  toolbar,
  identifier,
  identifierType,
  model,
  recordType,
  tabbed,
  children,
  dtoType,
  scrollTop,
}: QueriedTableSortFilterStateManagerProps<T>) => {
  const history = useHistory();
  const { pathname, state: locationState } = useLocation() as {
    pathname: string;
    state: LocationType | undefined;
  };

  const locationInitiallyUndefined = useRef<boolean>(
    isNullOrUndefined(locationState)
  );

  /* START -- General Options Related State -- START */

  // Need to come back to and implement below.

  // const { pathname, state: locationState } = useLocation() as {
  //     pathname: string;
  //     state: LocationDataType | undefined;
  // };

  // console.log('FROM STATE MANAGER STATE LOCATION: ' + JSON.stringify(locationState));

  const [globalFiltersStorage, setGlobalFiltersStorage] = useState<
    UserGlobalFilterSpecificGeneralOption[]
  >([]);

  const [selectedGlobalFilterNames, setSelectedGlobalFilterNames] = useState<
    string[]
  >(locationState?.selectedGlobalFilterNames ?? ["Main"]);

  const computedSelectedGlobalFilters = useMemo(() => {
    const result =
      isNotNullNorUndefined(globalFiltersStorage) &&
      isNotNullNorUndefined(selectedGlobalFilterNames)
        ? globalFiltersStorage.filter((filter) =>
            isNotNullNorUndefined(
              selectedGlobalFilterNames.find(
                (name) => name.toLowerCase() === filter.filterName.toLowerCase()
              )
            )
          )
        : [];
    return result;
  }, [globalFiltersStorage, selectedGlobalFilterNames]);

  const [userFiltersStorage, setUserFiltersStorage] = useState<
    UserGlobalFilterSpecificGeneralOption[]
  >([]);

  const [selectedUserFilterNames, setSelectedUserFilterNames] = useState<
    string[]
  >(locationState?.selectedUserFilterNames ?? []);

  const computedSelectedUserFilters = useMemo(() => {
    const result =
      isNotNullNorUndefined(userFiltersStorage) &&
      isNotNullNorUndefined(selectedUserFilterNames)
        ? userFiltersStorage.filter((filter) =>
            isNotNullNorUndefined(
              selectedUserFilterNames.find(
                (name) => name.toLowerCase() === filter.filterName.toLowerCase()
              )
            )
          )
        : [];
    return result;
  }, [selectedUserFilterNames, userFiltersStorage]);

  const getStoredCustomFiltersWithGeneratedIds = useCallback(() => {
    const storedState = locationState?.customFilters;

    if (isNullOrUndefined(storedState)) {
      return undefined;
    }

    const storedCustomFiltersClone = cloneDeep(storedState);

    storedCustomFiltersClone.filterOptions =
      storedCustomFiltersClone.filterOptions.map((filter) =>
        addGeneratedIdIfNoIdOrAddSpecifiedId(filter)
      );

    return storedCustomFiltersClone;
  }, [locationState?.customFilters]);

  const [customFilters, setCustomFilters] =
    useCustomLogicOnEdit<GeneralOptions>({
      initialValue:
        getStoredCustomFiltersWithGeneratedIds() ?? new GeneralOptions(),
      callbackToRun: useCallback(
        (value: GeneralOptions) => {
          const cloned = cloneDeep(value);

          cloned.filterOptions = cloned.filterOptions.map((x) =>
            addGeneratedIdIfNoIdOrAddSpecifiedId(x)
          );

          const columnOrderArray =
            value.columnOrder.length <= 0
              ? []
              : splitByComma(cloned.columnOrder);

          cloned.columnOrder =
            MakeANewArrayEnsuringAllColumnsArePresentForProvidedRecordType(
              columnOrderArray,
              recordType
            ).join(", ");

          return cloned;
        },
        [recordType]
      ),
    });

  const [ignoredFilterIds, setIgnoredFilterIds] = useState<string[]>(
    locationState?.ignoredFilterIds ?? []
  );

  // reomves any location info after initalizing default.

  useEffect(() => {
    //@ts-ignore
    //https://stackoverflow.com/questions/40099431/how-do-i-clear-location-state-in-react-router-on-page-reload#:~:text=If%20you%27re%20using%20react,cause%20your%20component%20to%20rerender.
    history.replace();
  }, [history]);

  const computeHelperFilterOptionsFilterMethod = useCallback(
    (obj: object) => {
      const id = getIdIfItExistsOrReturnsNull(obj);

      if (isNullOrUndefined(id)) {
        makeSimpleToast(
          "Error",
          `Provided filter doesn't have generated id in computeHelperFilterOptionsFilterMethod.`
        );

        console.error(
          `Provided filter doesn't have generated id in computeHelperFilterOptionsFilterMethod.`
        );
        return true;
      }

      const result = !ignoredFilterIds.includes(id);
      return result;
    },
    [ignoredFilterIds]
  );

  const computeHelperFilterOptions = useCallback(
    (
      computedSelectedGlobalFilters: UserGlobalFilterSpecificGeneralOption[],
      computedSelectedUserFilters: UserGlobalFilterSpecificGeneralOption[],
      customFilters: GeneralOptions
    ): GeneratedIdType<DynamicFilterWithSourceInfo>[] => {
      // Initializing Filter list
      const filterList: GeneratedIdType<DynamicFilterWithSourceInfo>[] = [];

      // Populating the (Dynamic) Filters
      computedSelectedGlobalFilters.forEach((globalFilter) => {
        const withSourceGlobalFilters = globalFilter.filterOptions
          .filter(computeHelperFilterOptionsFilterMethod)
          .map<GeneratedIdType<DynamicFilterWithSourceInfo>>((filter) => {
            return addGeneratedIdIfNoIdOrAddSpecifiedId({
              ...filter,
              sourceFilterType: FilterType.Global,
            });
          });

        filterList.push(...withSourceGlobalFilters);
      });

      computedSelectedUserFilters.forEach((userFilter) => {
        const withSourceUserFilters = userFilter.filterOptions
          .filter(computeHelperFilterOptionsFilterMethod)
          .map<GeneratedIdType<DynamicFilterWithSourceInfo>>((filter) => {
            return addGeneratedIdIfNoIdOrAddSpecifiedId({
              ...filter,
              sourceFilterType: FilterType.User,
            });
          });

        filterList.push(...withSourceUserFilters);
      });

      const withSourceCustomFilters = customFilters.filterOptions.map<
        GeneratedIdType<DynamicFilterWithSourceInfo>
      >((filter) => {
        return addGeneratedIdIfNoIdOrAddSpecifiedId({
          ...filter,
          sourceFilterType: FilterType.Custom,
        });
      });

      filterList.push(...withSourceCustomFilters);
      return filterList;
    },
    [computeHelperFilterOptionsFilterMethod]
  );

  const sortColumnVisibilityAndOrderSourceFinder =
    useCallback((): UserGlobalFilterSpecificGeneralOption => {
      if (computedSelectedUserFilters.length > 0) {
        // at least one user filter active
        return computedSelectedUserFilters[0];
      }

      const secondRowSelectedGlobalFilters =
        computedSelectedGlobalFilters.filter(
          (filter) => filter.globalFilterRow === GetGlobalFilterType.Row2
        );

      if (secondRowSelectedGlobalFilters.length > 0) {
        // at least one global filter of second row selected.
        return secondRowSelectedGlobalFilters[0];
      }

      const mainGlobalFilter = computedSelectedGlobalFilters.find(
        (filter) => filter.filterName.toLowerCase() === "main"
      );

      if (isNotNullNorUndefined(mainGlobalFilter)) {
        // Main is selected. Use it for source
        return mainGlobalFilter;
      }

      const allGlobalFilter = computedSelectedGlobalFilters.find(
        (filter) => filter.filterName.toLowerCase() === "all"
      );

      if (isNotNullNorUndefined(allGlobalFilter)) {
        // Main is selected. Use it for source
        return allGlobalFilter;
      }

      // If you get here something's wrong.
      return new UserGlobalFilterSpecificGeneralOption(customFilters);
    }, [
      computedSelectedGlobalFilters,
      computedSelectedUserFilters,
      customFilters,
    ]);

  type ComputeHelperColumnsReturnType = {
    orderedColumnsToShow: string[];
    columnOrder: string[];
  };

  const computeHelperColumns = useCallback(
    (
      computedSelectedGlobalFilters: UserGlobalFilterSpecificGeneralOption[],
      computedSelectedUserFilters: UserGlobalFilterSpecificGeneralOption[],
      customFilters: GeneralOptions,
      recordType: RecordType
    ): ComputeHelperColumnsReturnType => {
      let optionsObj: { columnsToShow: string; columnOrder: string } = {
        columnsToShow: "",
        columnOrder: "",
      };

      const source = sortColumnVisibilityAndOrderSourceFinder();
      optionsObj = {
        columnsToShow: source.columnsToShow,
        columnOrder: source.columnOrder,
      };

      // Might Want to review to see if we can get a better performance
      const toReturn: ComputeHelperColumnsReturnType = {
        orderedColumnsToShow: splitByComma(optionsObj.columnsToShow),
        columnOrder: splitByComma(optionsObj.columnOrder),
      };

      if (isNullOrUndefinedOrEmptyString(optionsObj.columnsToShow)) {
        const allColumnsList = AllColumnsRecordTypeMapper(recordType);
        toReturn.orderedColumnsToShow = allColumnsList;
      }

      if (isNullOrUndefinedOrEmptyString(optionsObj.columnOrder)) {
        const allColumnsList = AllColumnsRecordTypeMapper(recordType);
        toReturn.columnOrder = allColumnsList;
      }

      const recordTypeMethodToRemoveIgnoredColumns =
        getColumnsToIgnoreFromRecordType(recordType);

      // Filters by ignored columns
      toReturn.columnOrder = recordTypeMethodToRemoveIgnoredColumns(
        toReturn.columnOrder
      );
      toReturn.orderedColumnsToShow = recordTypeMethodToRemoveIgnoredColumns(
        toReturn.orderedColumnsToShow
      );

      // Orders columns to show.

      toReturn.orderedColumnsToShow = toReturn.orderedColumnsToShow.sort(
        (a, b) =>
          toReturn.columnOrder.indexOf(a) - toReturn.columnOrder.indexOf(b)
      );

      return toReturn;
    },
    [sortColumnVisibilityAndOrderSourceFinder]
  );

  const computeHelperSortOptions = useCallback(
    (customFilters: GeneralOptions): DynamicSort[] => {
      const source = sortColumnVisibilityAndOrderSourceFinder();

      return source.sortOptions.sortBy;
    },
    [sortColumnVisibilityAndOrderSourceFinder]
  );

  // const computeCombinedFilters = ;

  // const [testBool, setTestBool] = useState<boolean>(false);

  const [filterStorageUpdatedCompleted, setFilterStorageUpdatedCompleted] =
    useState<boolean>(false);

  // const [present, dismiss] = useIonLoading();

  // useEffect(() => {
  //     present({ message: 'Loading Global/User Filters...' });
  // }, [present]);

  const [
    useEffectCausedByStoragePopulation,
    setUseEffectCausedByStoragePopulation,
  ] = useState<boolean>(false);

  // Columns To Show Are automatically ordered.

  const combinedFilterTargetProperties = useMemo(() => {
    const customFilterOptionsTargetProperties = customFilters.filterOptions
      .filter(computeHelperFilterOptionsFilterMethod)
      .map((filterOption) => filterOption.propertyName);

    const selectedGlobalFilterOptionsTargetProperties =
      computedSelectedGlobalFilters.reduce<string[]>((result, item) => {
        const combinedPropreties = item.filterOptions.map(
          (x) => x.propertyName
        );

        result.push(...combinedPropreties);
        return result;
      }, []);

    const selectedUserFilterOptionsTargetProperties =
      computedSelectedUserFilters.reduce<string[]>((result, item) => {
        const combinedPropreties = item.filterOptions.map(
          (x) => x.propertyName
        );

        result.push(...combinedPropreties);
        return result;
      }, []);

    const combinedTargetPropreties = [
      ...customFilterOptionsTargetProperties,
      ...selectedGlobalFilterOptionsTargetProperties,
      ...selectedUserFilterOptionsTargetProperties,
    ];

    return combinedTargetPropreties;
  }, [
    computeHelperFilterOptionsFilterMethod,
    computedSelectedGlobalFilters,
    computedSelectedUserFilters,
    customFilters.filterOptions,
  ]);

  const computedCombinedFilters =
    useMemo((): GeneralOptionsColumnsToShowAndColumnOrderAsArray | null => {
      if (!filterStorageUpdatedCompleted) {
        return null;
      }

      // if (!testBool) {
      //     return null;
      // }

      const filterOptions = computeHelperFilterOptions(
        computedSelectedGlobalFilters,
        computedSelectedUserFilters,
        customFilters
      );

      const sortBy = computeHelperSortOptions(customFilters);

      // Columns To show and Column Order need to be handled by state.

      // Decided that 'orderedColumnsToShow' 'columnOrder' and 'sort' info will be provided directly from customFilters.

      // const { orderedColumnsToShow, columnOrder } = computeHelperColumns(
      //     computedSelectedGlobalFilters,
      //     computedSelectedUserFilters,
      //     customFilters,
      //     recordType
      // );

      let columnsToShowStringArray = splitByComma(
        cloneDeep(customFilters.columnsToShow)
      );
      let columnOrderStringArray = splitByComma(
        cloneDeep(customFilters.columnOrder)
      );

      const defaultFromRecordType = AllColumnsRecordTypeMapper(recordType);
      if (customFilters.columnsToShow === "") {
        columnsToShowStringArray = defaultFromRecordType;
      }

      if (customFilters.columnOrder === "") {
        columnOrderStringArray = defaultFromRecordType;
      }

      // Sorts it
      columnsToShowStringArray = columnsToShowStringArray.sort(
        (a, b) =>
          columnOrderStringArray.indexOf(a) - columnOrderStringArray.indexOf(b)
      );

      const combined: GeneralOptionsColumnsToShowAndColumnOrderAsArray = {
        filterOptions,
        sortOptions: customFilters.sortOptions,
        orderedColumnsToShow: columnsToShowStringArray,
        columnOrder: columnOrderStringArray,
        filterType: customFilters.filterType,
        isFilterUpdate: customFilters.isFilterUpdate,
        filterName: customFilters.filterName,
        searchTerm: customFilters.searchTerm,
      };

      return combined;
    }, [
      computeHelperFilterOptions,
      computeHelperSortOptions,
      computedSelectedGlobalFilters,
      computedSelectedUserFilters,
      customFilters,
      filterStorageUpdatedCompleted,
      recordType,
    ]);

  /* END  -- General Options Related State -- END */

  /* START -- Handles Filter Storage-- START */

  /* END -- Handles Filter Storage -- END */

  /* START -- CREATES/MODIFIES/VIEWS Global or Global Filters -- START */

  const unselectGlobalFilter = useCallback(
    (globalfilterName: string) => {
      if (
        isNullOrUndefined(
          selectedGlobalFilterNames.find(
            (x) => x.toLowerCase() === globalfilterName.toLowerCase()
          )
        )
      ) {
        // No matching thing.
        return false;
      }

      setSelectedGlobalFilterNames((oldSelectedGlobalFilterNames) =>
        oldSelectedGlobalFilterNames.filter(
          (x) => x.toLowerCase() !== globalfilterName.toLowerCase()
        )
      );
      return true;
    },
    [selectedGlobalFilterNames]
  );

  const unselectUserFilter = useCallback(
    (userFilterName: string) => {
      if (
        isNullOrUndefined(
          selectedUserFilterNames.find(
            (x) => x.toLowerCase() === userFilterName.toLowerCase()
          )
        )
      ) {
        // No matching thing.
        return false;
      }

      setSelectedUserFilterNames((oldSelectedUserFilterNames) =>
        oldSelectedUserFilterNames.filter(
          (x) => x.toLowerCase() !== userFilterName.toLowerCase()
        )
      );
      return true;
    },
    [selectedUserFilterNames]
  );

  useEffect(() => {
    if (useEffectCausedByStoragePopulation) {
      // TESTING SOMETHING.
      // setTimeout(() => {
      //     dismiss();
      // }, 4000);
      setFilterStorageUpdatedCompleted(true);
      setUseEffectCausedByStoragePopulation(false);
    }
  }, [useEffectCausedByStoragePopulation]);

  //TODO: TEST 'useCallback' combiantion isn't affecting performance
  const handleCompleteUpdateFromUserFilterAndGlobalFilterEndpoints =
    useCallback(
      async (recordType: RecordType) => {
        const {
          data: { model: userFilters },
        } = await InventoryService.getUserFilters(recordType);

        const {
          data: { model: globalFilters },
        } = await InventoryService.getGlobalFilters(recordType);

        const userFiltersWithIds = userFilters.map((userFilter) => {
          const clone = cloneDeep(userFilter);
          const updatedFilterOptions = clone.filterOptions.map((filterOption) =>
            addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
          );
          clone.filterOptions = updatedFilterOptions;
          return clone;
        });

        const correctGlobalFilters: UserGlobalFilterSpecificGeneralOption[] =
          globalFilters.map((filter) => {
            const clone = cloneDeep(filter);
            const updatedFilterOptions = clone.innerModel.filterOptions.map(
              (filterOption) =>
                addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
            );
            clone.innerModel.filterOptions = updatedFilterOptions;

            clone.globalFilterRow = filter.globalFilterRow;

            const obj = {
              ...clone.innerModel,
              globalFilterRow: filter.globalFilterRow,
            };

            return obj;
          });

        setUserFiltersStorage(userFiltersWithIds);
        setGlobalFiltersStorage(correctGlobalFilters);
        setUseEffectCausedByStoragePopulation(true);

        const targetMain = globalFilters.find(
          (x) => x.innerModel.filterName.toLowerCase() === "main"
        );

        if (
          locationInitiallyUndefined.current &&
          isNotNullNorUndefined(targetMain)
        ) {
          // Matching main.

          const updated = cloneDeep(targetMain.innerModel);

          updated.filterOptions = [];

          updated.filterName = "";
          updated.filterType = FilterType.Custom;

          setCustomFilters(updated);
        }
      },
      [setCustomFilters]
    );

  // useEffect(() => {
  //     if (nextRenderUpdateState.current) {
  //         // Need to update state
  //         const t = globalFiltersStorage;
  //         console.log('test');

  //         setTestBool(true);
  //     }
  // }, [globalFiltersStorage, nextRenderUpdateState]);

  useEffect(() => {
    handleCompleteUpdateFromUserFilterAndGlobalFilterEndpoints(recordType);
  }, [
    handleCompleteUpdateFromUserFilterAndGlobalFilterEndpoints,
    recordType,
    locationState,
    setCustomFilters,
  ]);

  const handleSetCustomFiltersValueFilters = (
    dynamicFilters: DynamicFilter[]
  ) => {
    // const options = { ...generalOptions };

    //** NEED TO REVISIT */
    setCustomFilters((oldCustomFitlers) => {
      const updatedFitlers = cloneDeep(oldCustomFitlers);

      // Think the ids will already be added but doing this as a check
      updatedFitlers.filterOptions = dynamicFilters.map((x) => {
        const clone = cloneDeep(x);

        return addGeneratedIdIfNoIdOrAddSpecifiedId(clone);
      });

      return updatedFitlers;
    });
  };

  const handleCreateUpdateUserFilter = useCallback(
    async (userFilterName: string, updating: boolean) => {
      if (isNullOrUndefined(computedCombinedFilters)) {
        makeSimpleToast(
          "Error",
          "ComputedCombinedFilters is null while trying to create/update user filter."
        );
        console.error(
          "ComputedCombinedFilters is null while trying to create/update user filter."
        );
        return;
      }

      // Realizing that using "..." with objects makes a new object but any keys that reference another object
      // The reference of that object is copied instead of the actual object. So when doing "..." with an object and updating it I
      // can accidentally update the original object.
      // Annoying but this cloneDeep library from Lodash seems to be very quick compared to other alternatives.
      const createdFilter = convertStringArrayGeneralOptionsToNormal(
        computedCombinedFilters
      );

      createdFilter.isFilterUpdate = updating;
      createdFilter.filterName = userFilterName;
      createdFilter.filterType = FilterType.User;

      const functionToCall = getCorrespondingQueriedTableMethod(recordType);

      const result = await functionToCall(createdFilter);

      if (!result.data.isSuccessful) {
        makeSimpleToast(
          "Error: ",
          "Unsuccessful attempt to create/edit user filter. Please contact web developers."
        );
        return;
      }

      if (updating) {
        setUserFiltersStorage((oldFilterStorage) => {
          const toUpdate = cloneDeep(oldFilterStorage).map((x) => {
            if (
              x.filterName.toLowerCase() !==
              result.data.model.options.filterName.toLowerCase()
            ) {
              return x;
            }

            const clone = cloneDeep(result.data.model.options);

            clone.filterOptions = clone.filterOptions.map((filterOption) =>
              addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
            );

            return clone;
          });
          return toUpdate;
        });
        return;
      }

      // If creating user, unselected global.
      setSelectedGlobalFilterNames([]);

      setSelectedUserFilterNames([userFilterName]);

      setUserFiltersStorage((oldFilterStorage) => {
        const toUpdate = cloneDeep(oldFilterStorage).map((x) => {
          const clone = cloneDeep(x);

          clone.filterOptions = clone.filterOptions.map((filterOption) =>
            addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
          );

          return clone;
        });

        const idAddedFilterOptions =
          result.data.model.options.filterOptions.map((filterOption) =>
            addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
          );

        const responseClone = cloneDeep(result.data.model.options);
        responseClone.filterOptions = idAddedFilterOptions;

        toUpdate.push(responseClone);

        return toUpdate;
      });
    },
    [computedCombinedFilters, recordType]
  );

  const handleCreateUpdateGlobalFilter = useCallback(
    async (globalFilterName: string, updating: boolean) => {
      if (isNullOrUndefined(computedCombinedFilters)) {
        makeSimpleToast(
          "Error",
          "ComputedCombinedFilters i null while trying to create/update global filter. Please contact developer!"
        );
        console.error(
          "ComputedCombinedFilters i null while trying to create/update global filter. Please contact developer!"
        );
        return;
      }

      const filter = cloneDeep(computedCombinedFilters);

      filter.filterType = FilterType.Global;
      filter.isFilterUpdate = updating;
      filter.filterName = globalFilterName;

      const result = await getCorrespondingQueriedTableMethod(recordType)(
        convertStringArrayGeneralOptionsToNormal(filter)
      );

      if (!result.data.isSuccessful) {
        makeSimpleToast(
          "Error:",
          "Unsuccessful attempt to create/edit user filter. Please contact web developers."
        );
        return;
      }

      if (updating) {
        setGlobalFiltersStorage((oldFilterStorage) => {
          const toUpdate = cloneDeep(oldFilterStorage).map((x) => {
            if (
              x.filterName.toLowerCase() !==
              result.data.model.options.filterName.toLowerCase()
            ) {
              return x;
            }

            const clone = cloneDeep(result.data.model.options);

            clone.filterOptions = clone.filterOptions.map((filterOption) =>
              addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
            );

            return clone;
          });
          return toUpdate;
        });
        return;
      }

      setSelectedGlobalFilterNames([globalFilterName]);
      setSelectedUserFilterNames([]);

      setGlobalFiltersStorage((oldFilterStorage) => {
        const toUpdate = cloneDeep(oldFilterStorage).map((x) => {
          const clone = cloneDeep(x);

          clone.filterOptions = clone.filterOptions.map((filterOption) =>
            addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
          );

          return clone;
        });

        const idAddedFilterOptions =
          result.data.model.options.filterOptions.map((filterOption) =>
            addGeneratedIdIfNoIdOrAddSpecifiedId(filterOption)
          );

        const responseClone = cloneDeep(result.data.model.options);
        responseClone.filterOptions = idAddedFilterOptions;

        toUpdate.push(responseClone);
        return toUpdate;
      });
    },
    [computedCombinedFilters, recordType]
  );

  const ignoreFilter = (filterId: string) => {
    if (isNotNullNorUndefined(ignoredFilterIds.find((x) => x === filterId))) {
      // id already exists.
      return false;
    }

    setIgnoredFilterIds((ignoredFilterIds) => [...ignoredFilterIds, filterId]);
    return true;
  };

  const unignoreFilter = (filterId: string) => {
    if (isNullOrUndefined(ignoredFilterIds.find((x) => x === filterId))) {
      // no id exists
      return false;
    }

    setIgnoredFilterIds((ignoredFilterIds) =>
      ignoredFilterIds.filter((x) => x !== filterId)
    );
    return true;
  };

  const isFilterIgnored = (filterId: string) => {
    return isNotNullNorUndefined(ignoredFilterIds.find((x) => x === filterId));
  };

  const handleDeleteUserFilter = (userFilterNameToDelete: string) => {
    const targetUserFilter = computedSelectedUserFilters.find(
      (userFilter) =>
        userFilter.filterName.toLowerCase() ===
        userFilterNameToDelete.toLowerCase()
    );

    if (isNullOrUndefined(targetUserFilter)) {
      // Couldn't find filter with the given name.
      makeSimpleToast(
        "Error",
        `Couldn't Find User Filter with name: ${userFilterNameToDelete}. Please contact developers.`
      );
      return;
    }

    const generalOptions = getUnfilteredGeneralOptions(
      FilterType.User,
      targetUserFilter.filterName
    );

    InventoryService.DeleteInventoryUserFilter(generalOptions)
      .then((response) => {
        if (!response.data.isSuccessful) {
          makeSimpleToast(
            "Error:",
            `Error Occured while deleting User FIlter: ${response.data.messageForUser}`
          );
          console.error(
            `Error Occured while deleting User FIlter: ${response.data.devMessage}`
          );
        }
        // Removes user filter from selected user filter names
        setSelectedUserFilterNames((oldFilterNames) =>
          oldFilterNames.filter(
            (filterName) =>
              filterName.toLowerCase() !== userFilterNameToDelete.toLowerCase()
          )
        );

        // Removes user filter from user filter storage
        setUserFiltersStorage((oldUserFilterStorage) =>
          oldUserFilterStorage.filter(
            (filter) =>
              filter.filterName.toLowerCase() !==
              userFilterNameToDelete.toLowerCase()
          )
        );
      })
      .catch((error) => {
        makeSimpleToast(
          "Error:",
          `Error Occured while deleting User FIlter: ${error}`
        );
        console.error(`Error Occured while deleting User FIlter: ${error}`);
      });
  };

  const handleDeleteGlobalFilter = (globalFilterNameToDelete: string) => {
    const targetGlobalFilter = computedSelectedGlobalFilters.find(
      (filterInfoStorage) =>
        filterInfoStorage.filterName.toLowerCase() ===
        globalFilterNameToDelete.toLowerCase()
    );

    if (isNullOrUndefined(targetGlobalFilter)) {
      // Couldn't find filter with the given name.
      makeSimpleToast(
        "Error",
        `Couldn't Find Global Filter with name: ${globalFilterNameToDelete}. Please contact developers.`
      );
      return;
    }

    const generalOptions = getUnfilteredGeneralOptions(
      FilterType.Global,
      targetGlobalFilter.filterName
    );

    InventoryService.DeleteInventoryGlobalFilter(generalOptions)
      .then((response) => {
        if (!response.data.isSuccessful) {
          makeSimpleToast(
            "Error:",
            `Error Occured while deleting Global FIlter: ${response.data.messageForUser}`
          );
          console.error(
            `Error Occured while deleting Global FIlter: ${response.data.devMessage}`
          );
          return;
        }
        // Removes global filter from selected user filter names
        setSelectedGlobalFilterNames((oldFilterNames) =>
          oldFilterNames.filter(
            (filterName) =>
              filterName.toLowerCase() !==
              globalFilterNameToDelete.toLowerCase()
          )
        );

        // Removes global filter from user filter storage
        setGlobalFiltersStorage((oldGlobalFilterStorage) =>
          oldGlobalFilterStorage.filter(
            (filter) =>
              filter.filterName.toLowerCase() !==
              globalFilterNameToDelete.toLowerCase()
          )
        );
      })
      .catch((error) => {
        makeSimpleToast(
          "Error:",
          `Error Occured while deleting Global FIlter: ${error}`
        );
        console.error(`Error Occured while deleting Global FIlter: ${error}`);
      });
  };

  const handleGlobalFilterButtonClick = async (globalFilterName: string) => {
    const targetGlobalFilter = globalFiltersStorage.find(
      (filter) =>
        filter.filterName.toLowerCase() === globalFilterName.toLowerCase()
    );

    if (isNullOrUndefined(targetGlobalFilter)) {
      makeSimpleToast(
        "Error",
        `Attempting to Click global Filter of name: ${globalFilterName} but non exists. Please contact developers!`
      );
      console.error(
        `Attempting to Click global Filter of name: ${globalFilterName} but non exists. Please contact developers!`
      );
      return;
    }

    // No longer calling endpoint for global filters every time it's clicked.

    // Instead we will be toggling the 'Selected' state variables to update which global/user filters are selected
    // With this implementation the global filters will not be updated until the user navigates to a different page and comes back or refreshes.
    // I think that type of minor change will be completely fine.

    setSelectedUserFilterNames([]);

    if (
      selectedGlobalFilterNames.find(
        (filterName) =>
          filterName.toLowerCase() === globalFilterName.toLowerCase()
      )
    ) {
      // Already Selected, will remove.

      setSelectedGlobalFilterNames((oldFilterNames) =>
        oldFilterNames.filter(
          (filterName) =>
            filterName.toLowerCase() !== globalFilterName.toLowerCase()
        )
      );
    } else {
      // Not selected yet, will add.

      setSelectedGlobalFilterNames((oldFilterNames) => {
        let filterNamesToReturn = [...oldFilterNames, globalFilterName];

        if (
          isNotNullNorUndefined(
            oldFilterNames.find((filter) => filter.toLowerCase() === "main")
          )
        ) {
          // Has selected something that has not yet been selected. Since 'Main' in this case has already
          // been selected there's no way the user could have clicked main in this case.
          // We want to basically unselect main in this case.

          filterNamesToReturn = filterNamesToReturn.filter(
            (filter) => filter.toLowerCase() !== "main"
          );
        } else if (globalFilterName.toLowerCase() === "main") {
          // Clicekd on a 'Main' Global filter. There is no 'main' selected yet.
          // Will add 'Main' to global filter list and remove other user/global filters ( Done above)

          filterNamesToReturn = ["Main"];
        }

        if (
          isNotNullNorUndefined(
            oldFilterNames.find((filter) => filter.toLowerCase() === "all")
          )
        ) {
          // Has selected something that has not yet been selected. Since 'All' in this case has already
          // been selected there's no way the user could have clicked main in this case.
          // We want to basically unselect main in this case.

          filterNamesToReturn = filterNamesToReturn.filter(
            (filter) => filter.toLowerCase() !== "all"
          );
        } else if (globalFilterName.toLowerCase() === "all") {
          // Clicekd on a 'All' Global filter. There is no 'All' selected yet.
          // Will add 'All' to global filter list and remove other user/global filters

          setSelectedUserFilterNames([]);
          filterNamesToReturn = ["All"];
        }

        if (targetGlobalFilter.globalFilterRow === GetGlobalFilterType.Row2) {
          // Clicked row 2 one. Unselected previous row twos

          filterNamesToReturn = filterNamesToReturn.filter((filterName) => {
            const target = computedSelectedGlobalFilters.find(
              (x) => x.filterName.toLowerCase() === filterName.toLowerCase()
            );

            if (filterName.toLowerCase() === globalFilterName.toLowerCase()) {
              return true;
            }

            if (isNullOrUndefined(target)) {
              makeSimpleToast(
                "Error",
                `Filter name of ${filterName} in selected global filter names that wasn't in computedSelectedGlobalFilters! Please review`
              );
              console.error(
                `Filter name of ${filterName} in selected global filter names that wasn't in computedSelectedGlobalFilters! Please review`
              );
              return true;
            }

            if (
              target.filterName.toLowerCase() === globalFilterName.toLowerCase()
            ) {
              return true;
            }

            if (target.globalFilterRow === GetGlobalFilterType.Row2) {
              // want to filter other second row filters.
              return false;
            }
            return true;
          });
        }

        return filterNamesToReturn;
      });

      // Updates Custom Filters based off of clicked global filters
      setCustomFilters((customFilters) => {
        const clone = cloneDeep(customFilters);

        clone.columnsToShow = targetGlobalFilter.columnsToShow;
        clone.columnOrder = targetGlobalFilter.columnOrder;
        clone.sortOptions.sortBy = targetGlobalFilter.sortOptions.sortBy;

        return clone;
      });
    }
  };

  const handleUserFilterButtonClick = async (userFilterName: string) => {
    const targetUserFilter = userFiltersStorage.find(
      (filter) =>
        filter.filterName.toLowerCase() === userFilterName.toLowerCase()
    );

    if (isNullOrUndefined(targetUserFilter)) {
      makeSimpleToast(
        "Error",
        `Attempting to Click user Filter of name: ${userFilterName} but non exists. Please contact developers!`
      );
      console.error(
        `Attempting to Click user Filter of name: ${userFilterName} but non exists. Please contact developers!`
      );
      return;
    }

    if (
      selectedUserFilterNames.find(
        (filterName) =>
          filterName.toLowerCase() === userFilterName.toLowerCase()
      )
    ) {
      // Already Selected, will remove all user filters.

      setSelectedUserFilterNames([]);
    } else {
      // Not selected yet, will add.
      // Ensure it's the only user filter

      setSelectedUserFilterNames([userFilterName]);

      // Since user enabled user filter, turn off all global filters
      setSelectedGlobalFilterNames([]);
    }

    // Updates Custom Filters based off of clicked global filters
    setCustomFilters((customFilters) => {
      const clone = cloneDeep(customFilters);

      clone.columnsToShow = targetUserFilter.columnsToShow;
      clone.columnOrder = targetUserFilter.columnOrder;
      clone.sortOptions.sortBy = targetUserFilter.sortOptions.sortBy;

      return clone;
    });
  };

  /* END -- CREATES/MODIFIES/VIEWS Global or Global Filters -- END */

  /* START -- General Functions related to General Options -- START */

  const handleClearCancel = useCallback(async () => {
    // if (recordType !== RecordType.Inventory) {
    //     setCustomFilters(new GeneralOptions());

    //     setSelectedGlobalFilterNames([]);
    //     setSelectedUserFilterNames([]);
    //     return;
    // }

    const powerView = globalFiltersStorage.find(
      (filter) => filter.filterName.toLowerCase() === "main"
    );

    if (isNullOrUndefined(powerView)) {
      let columnsToShowStringArray = splitByComma(
        cloneDeep(customFilters.columnsToShow)
      );
      let columnOrderStringArray = splitByComma(
        cloneDeep(customFilters.columnOrder)
      );

      const defaultFromRecordType = AllColumnsRecordTypeMapper(recordType);

      columnsToShowStringArray = defaultFromRecordType;
      columnOrderStringArray = defaultFromRecordType;

      const resetOptions = new GeneralOptions();

      resetOptions.columnsToShow = columnsToShowStringArray.join(",");
      resetOptions.columnOrder = columnOrderStringArray.join(",");

      setCustomFilters(resetOptions);

      return;
    }

    // by default selects Power View as the global filter.

    // Can disable it by clicking on "Power View."

    setCustomFilters({
      ...new GeneralOptions(),
      columnsToShow: powerView.columnsToShow,
      columnOrder: powerView.columnOrder,
    });

    setSelectedGlobalFilterNames([powerView.filterName]);
    setSelectedUserFilterNames([]);
    setIsLoading(true);
  }, [
    customFilters.columnOrder,
    customFilters.columnsToShow,
    globalFiltersStorage,
    recordType,
    setCustomFilters,
  ]);

  const handleUpdatingItemsPerPage = (itemsPerPage: number) => {
    setCustomFilters((oldCustomFilters) => {
      const updated = cloneDeep(oldCustomFilters);

      updated.sortOptions.itemsPerPage = itemsPerPage;

      return updated;
    });
  };

  const setSortFilters = (dynamicSorts: DynamicSortWithoutSortPriority[]) => {
    setCustomFilters((oldCustomFilters) => {
      const updated = cloneDeep(oldCustomFilters);

      // We add a sortPriroitty
      const dynamicSortsWithSortPriorities = dynamicSorts.map<DynamicSort>(
        (item, index) => ({
          ...item,
          sortPriority: index + 1,
        })
      );

      updated.sortOptions.sortBy = dynamicSortsWithSortPriorities;

      return updated;
    });
    // Make sure to set source to custom since we've updated the dynamic sorts.
  };

  type NormalFunction = () => void;
  type ColumnVisibilityOrderSelectedParameterFunction = (
    selected: UserGlobalFilterSpecificGeneralOption
  ) => void;

  const setColumnVisibility = (updatedColumnsToShowList: string[]) => {
    // None Selected - this means custom filters is already being used. Just update it.

    setCustomFilters((oldCustomFilters) => {
      const updated = cloneDeep(oldCustomFilters);

      updated.columnsToShow = updatedColumnsToShowList.join(",");

      return updated;
    });
  };

  const setColumnOrder = (updatedColumnOrder: string[]) => {
    // None Selected - this means custom filters is already being used. Just update it.

    setCustomFilters((oldCustomFilters) => {
      const updated = cloneDeep(oldCustomFilters);

      updated.columnOrder = updatedColumnOrder.join("-");

      return updated;
    });
  };

  const isFilterSelected = ({
    name,
    filterType,
  }: Omit<UserGlobalFilterStorageType, "globalFilterRow">) => {
    if (filterType === FilterType.Global) {
      const matchingGlobalFilterName = selectedGlobalFilterNames.find(
        (filterName) => filterName.toLowerCase() === name.toLowerCase()
      );

      return isNotNullNorUndefined(matchingGlobalFilterName);
    } else if (filterType === FilterType.User) {
      const matchingUserFilterName = selectedUserFilterNames.find(
        (filterName) => filterName.toLowerCase() === name.toLowerCase()
      );

      return isNotNullNorUndefined(matchingUserFilterName);
    } else {
      makeSimpleToast(
        "Error",
        `Send message to web developers: Filter Type passed to isFilterSelected is custom!`
      );

      console.error(
        `Send message to web developers: Filter Type passed to isFilterSelected is custom!`
      );
      return false;
    }
  };

  const updateCurrentPageNumber = (pageNumber: number) => {
    setCustomFilters((oldCustomFilters) => {
      const updated = cloneDeep(oldCustomFilters);
      updated.sortOptions.pageNumber = pageNumber;

      updated.filterType = FilterType.Custom;
      return updated;
    });
  };

  const setItemsPerPage = (itemsPerPage: number) => {
    setCustomFilters((oldCustomFilters) => {
      const updated = cloneDeep(oldCustomFilters);

      updated.sortOptions.itemsPerPage = itemsPerPage;

      return updated;
    });
  };

  const handleChangeSorting = (columnName: string) => {
    // All sorting handled by custom filters. Simply use custom filters

    setCustomFilters((oldCustomFilters) => {
      const clone = cloneDeep(oldCustomFilters);

      const matchingColumnName = clone.sortOptions.sortBy.find(
        (sort) => sort.propertyName.toLowerCase() === columnName.toLowerCase()
      );

      if (isNullOrUndefined(matchingColumnName)) {
        // if not present we'll add and default to is asneding

        clone.sortOptions.sortBy = [
          ...clone.sortOptions.sortBy,
          // May need to come back and verify the '.length' provides the correct result
          // As in need to make sure the 'sort priority' provided by the below code doesn't match with an already
          // exisiting sort
          {
            propertyName: columnName,
            isAscending: true,
            sortPriority: clone.sortOptions.sortBy.length,
          },
        ];
        return clone;
      }

      // This means there is a matching column. We'll simply invert

      clone.sortOptions.sortBy = clone.sortOptions.sortBy.map((sort) => {
        if (sort.propertyName.toLowerCase() !== columnName.toLowerCase()) {
          return sort;
        }
        const sortClone = cloneDeep(sort);

        sortClone.isAscending = !sortClone.isAscending;
        return sortClone;
      });
      return clone;
    });
  };

  const handleOrderColumnToLeft = useCallback(
    (columnName: keyof AllPossibleReturnTypes) => {
      if (isNullOrUndefined(computedCombinedFilters)) {
        makeSimpleToast(
          "Error",
          "computedCombinedFilters is null while trying to move column to the left. Please contact developer."
        );
        console.error(
          "computedCombinedFilters is null while trying to move column to the left. Please contact developer."
        );
        return;
      }

      const indexOfColumnName =
        computedCombinedFilters.orderedColumnsToShow.indexOf(columnName);

      if (indexOfColumnName <= 0) {
        alert("Can not move column farther in that direction.");
        return;
      }

      const leftColumn =
        computedCombinedFilters.orderedColumnsToShow[indexOfColumnName - 1];

      setCustomFilters((oldCustomFilters) => {
        const updatedList = [...splitByComma(oldCustomFilters.columnOrder)];

        const indexOfLeftColumnInsideColumnOrder =
          updatedList.indexOf(leftColumn);

        const indexOfCurrentColumnInsideColumnOrder =
          updatedList.indexOf(columnName);

        updatedList.splice(indexOfCurrentColumnInsideColumnOrder, 1);

        updatedList.splice(indexOfLeftColumnInsideColumnOrder, 0, columnName);

        const result = {
          ...oldCustomFilters,
          columnOrder: updatedList.join(","),
        };

        return result;
      });
    },
    [computedCombinedFilters]
  );

  const handleOrderColumnToRight = useCallback(
    (columnName: keyof AllPossibleReturnTypes) => {
      if (isNullOrUndefined(computedCombinedFilters)) {
        makeSimpleToast(
          "Error",
          "ComputedCombinedFilters is null while ordering columnn to right. Please contact developer"
        );
        console.error(
          "ComputedCombinedFilters is null while ordering columnn to right. Please contact developer"
        );
        return;
      }

      const indexOfColumnName =
        computedCombinedFilters.orderedColumnsToShow.indexOf(columnName);

      if (
        indexOfColumnName >=
        computedCombinedFilters.orderedColumnsToShow.length - 1
      ) {
        alert("Can not move column farther in that direction.");
        return;
      }

      const rightColumn =
        computedCombinedFilters.orderedColumnsToShow[indexOfColumnName + 1];

      setCustomFilters((oldGeneralOptions) => {
        const updatedList = [...splitByComma(oldGeneralOptions.columnOrder)];

        const indexOfRightColumnInsideColumnOrder =
          updatedList.indexOf(rightColumn);

        const indexOfCurrentColumnInsideColumnOrder =
          updatedList.indexOf(columnName);

        updatedList.splice(indexOfCurrentColumnInsideColumnOrder, 1);

        updatedList.splice(indexOfRightColumnInsideColumnOrder, 0, columnName);

        const result = {
          ...oldGeneralOptions,
          columnOrder: updatedList.join(","),
        };

        return result;
      });
    },
    [computedCombinedFilters]
  );

  // Think we need to remove this function.
  // const getFirstSelectedFilterNotOnFirstGlobalFilterRow = () => {
  //     const selectedFilterInfo = selectedFilterInfos.find(
  //         (info) => info.globalFilterRow === GetGlobalFilterType.Row2 || info.globalFilterRow === undefined
  //     );

  //     return selectedFilterInfo;
  // };

  /* END -- General Functions related to General Options -- END */

  // Not used but will refactor later
  const [overwritingProvidedData, setOverwritingProvidedData] = useState<
    | InventoryDto[]
    | SalesOrderDto[]
    | RecordOfTransferDto[]
    | YardMapDto[]
    | ReturnAccountingToDoDto[]
  >([]);

  // Not needed will refactor later
  const [useCustomEndpoint, setUseCustomEndpoint] = useState<boolean>(false);

  if (tabbed && endpoint == undefined) {
    throw Error(
      "ENDPOINT IS UNDEFINED WHILE TABBED IS FALSE QUERIEDTABLESORTFILTERSTATEMANAGEr.TSX"
    );
  }

  const [data, setData] = useState<AllPossibleReturnTypesArray>(
    []
    // locationState?.data ?? []
  );

  const handleEndpointCall = useCallback(
    async ({
      endpoint,
      generalOptionsToUse,
      callback,
    }: HandleEndPointCallParameters) => {
      if (isNullOrUndefined(endpoint)) {
        return;
      }

      // ** Somewhat coupled code to handle specifically Delivered Count Logic
      // ** Spencer wants to be able to do IsEmpty for delivered date and see all
      // ** Sales orders that don't have any delivered stock. And visa versa
      // ** he wants to be able to do "IsNotEmpty" to see all sales orders
      // ** that have a delivered stock.
      const updatedFilterOptionsAfterDoingDeliveredCountLogic =
        generalOptionsToUse.filterOptions
          .map((filterOption) =>
            doesObjectHaveGeneratedId(filterOption)
              ? removeGeneratedId(filterOption)
              : filterOption
          )
          .reduce<DynamicFilter[]>((result, filterOption) => {
            const deliveredCountProp =
              filterOption.propertyName.toLowerCase() === "delivered count" &&
              filterOption.comparisonType === DataType.String;

            if (
              deliveredCountProp &&
              filterOption.comparisonOperator === ComparisonOperatorEnum.IsEmpty
            ) {
              // Want to look for any delivered dates that have '0/' at the beginning since it'll be something
              // like 0/1 or 0/2

              const copiedFilterOption = { ...filterOption };
              copiedFilterOption.value = "0/";
              // Starts With
              copiedFilterOption.comparisonOperator = 24;
              result.push(copiedFilterOption);
              return result;
            } else if (
              deliveredCountProp &&
              filterOption.comparisonOperator ===
                ComparisonOperatorEnum.IsNotEmpty
            ) {
              // Want to look for any delivered dates that *do not* have '0/' at the beginning since it'll be something
              // like 0/1 or 0/2

              const copiedFilterOption = { ...filterOption };
              copiedFilterOption.value = "0/";
              // Does Not Contain
              copiedFilterOption.comparisonOperator = 23;
              result.push(copiedFilterOption);
              return result;
            } else {
              result.push(filterOption);
              return result;
            }
          }, []);

      generalOptionsToUse.filterOptions =
        updatedFilterOptionsAfterDoingDeliveredCountLogic;

      if (isNullOrUndefined(generalOptionsToUse.columnOrder)) {
        generalOptionsToUse.columnOrder = "";
      }
      if (isNullOrUndefined(generalOptionsToUse.columnsToShow)) {
        generalOptionsToUse.columnsToShow = "";
      }
      const response: EndpointResponseType = await endpoint(
        generalOptionsToUse
      );

      let data: AllPossibleReturnTypesArray | undefined;

      // if (model.getModelType() === ModelType.Inventory) {
      const modelWithMessage = response.data;

      if (modelWithMessage.isSuccessful) {
        const returnListWithOptionsDto = modelWithMessage.model;

        if (Array.isArray(returnListWithOptionsDto)) {
          data = returnListWithOptionsDto;
        } else {
          const listOfModels = returnListWithOptionsDto.model;
          data = listOfModels;
        }

        setData(data);

        const options = { ...response.data.model.options };

        // Getting weird stale state issue when using the below setCustomFilters code.
        // For some reason it will pass a general options that doesn't have a filter in it
        // but the 'customFilters' variable does have a custom filter in it.
        // Trying this other approach below
        setCustomFilters((oldCustomFilters) => {
          const updatedCustomFilters = cloneDeep(oldCustomFilters);

          updatedCustomFilters.sortOptions.itemsPerPage =
            options.sortOptions.itemsPerPage;
          updatedCustomFilters.sortOptions.pageNumber =
            options.sortOptions.pageNumber;
          updatedCustomFilters.sortOptions.totalItems =
            options.sortOptions.totalItems;
          updatedCustomFilters.sortOptions.totalPages =
            options.sortOptions.totalPages;

          return updatedCustomFilters;
        });

        // setCustomFilters(() => {
        //     const updatedCustomFilters = cloneDeep(customFilters);

        //     updatedCustomFilters.sortOptions.itemsPerPage = options.sortOptions.itemsPerPage;
        //     updatedCustomFilters.sortOptions.pageNumber = options.sortOptions.pageNumber;
        //     updatedCustomFilters.sortOptions.totalItems = options.sortOptions.totalItems;
        //     updatedCustomFilters.sortOptions.totalPages = options.sortOptions.totalPages;

        //     return updatedCustomFilters;
        // });

        // options.filterName = '';
        // options.filterType = FilterType.Custom;
        // options.isFilterUpdate = false;

        // options.columnOrder = options.columnOrder ?? generalOptions.columnOrder;

        if (isNotNullNorUndefined(callback)) {
          callback();
        }
      }
    },
    [setCustomFilters, customFilters]
  );

  const shouldSkipUseEffect = useRef<boolean>(false);

  useEffect(() => {
    if (shouldSkipUseEffect.current) {
      shouldSkipUseEffect.current = false;
      return;
    }

    if (isNullOrUndefined(computedCombinedFilters)) {
      return;
    }
    handleEndpointCall({
      endpoint,
      generalOptionsToUse: convertStringArrayGeneralOptionsToNormal(
        computedCombinedFilters
      ),
    });

    shouldSkipUseEffect.current = true;

    // return () => {
    //     shouldSkipUseEffect.current = false;
    // };
  }, [computedCombinedFilters, endpoint, handleEndpointCall]);

  /* START -- Table Style Export Functionality -- START */

  // * New stuff for table style export

  const [selectedRowsIdentifierValues, setSelectedRowsIdentifierValues] =
    useState<string[]>([]);

  const registerSelection = (identifierValue: string) => {
    if (
      selectedRowsIdentifierValues.find((id) => id === identifierValue) !==
      undefined
    ) {
      // already exists
      return;
    }
    setSelectedRowsIdentifierValues((array) => [...array, identifierValue]);
  };

  const unselectRow = (identifierValue: string) => {
    setSelectedRowsIdentifierValues((array) =>
      array.filter((id) => id !== identifierValue)
    );
  };

  const isRowIdSelected = (identifierValue: string) => {
    const result =
      selectedRowsIdentifierValues.find((id) => id === identifierValue) !==
      undefined;
    return result;
  };

  const selectAllRows = (ids: string[]) => {
    setSelectedRowsIdentifierValues((array) => [...array, ...ids]);
  };

  const [selectingRowMode, setSelectingRowMode] =
    useState<SelectingMode>("unselected");

  const startSelecting = (
    copyOrEmail: Exclude<SelectingMode, "unselected">,
    ids?: string[]
  ) => {
    setSelectingRowMode(copyOrEmail);

    if (isNullOrUndefined(ids)) {
      return;
    }
    selectAllRows(ids);
  };

  const stopSelectingAndEmailAdvertisements = useCallback(
    async (email: string) => {
      if (isNullOrUndefined(computedCombinedFilters)) {
        makeSimpleToast(
          "Error",
          "ComputedCombinedFilters is null when trying to stop and select tables. Contact Developer!"
        );
        console.error(
          "ComputedCombinedFilters is null when trying to stop and select tables. Contact Developer!"
        );
        return;
      }

      const advertisementEmailerDto: AdvertisementEmailerDto = {
        email,
        stockNumbers: selectedRowsIdentifierValues,
      };

      AdvertisementEmailerService.SendEmail(advertisementEmailerDto);

      makeSimpleToast("Success", `Sent email to email address: ${email}`);

      setSelectingRowMode("unselected");

      // Call code to uitilze the selection.

      // Reset the list
      // Disabling it for now
      setSelectedRowsIdentifierValues([]);
    },
    [computedCombinedFilters, data, dtoType, selectedRowsIdentifierValues]
  );

  const stopSelectingAndCopyTableWithStyles = useCallback(async () => {
    if (isNullOrUndefined(computedCombinedFilters)) {
      makeSimpleToast(
        "Error",
        "ComputedCombinedFilters is null when trying to stop and select tables. Contact Developer!"
      );
      console.error(
        "ComputedCombinedFilters is null when trying to stop and select tables. Contact Developer!"
      );
      return;
    }

    setSelectingRowMode("unselected");

    // Call code to uitilze the selection.

    const invisibleWrapper = document.createElement("div");
    invisibleWrapper.style.visibility = "hidden";
    invisibleWrapper.style.pointerEvents = "none";

    invisibleWrapper.style.zIndex = "9999999";
    invisibleWrapper.style.position = "absolute";
    invisibleWrapper.style.left = "0";
    invisibleWrapper.style.top = "0";
    invisibleWrapper.setAttribute("id", "invisibleWrapper");
    const root = createRoot(invisibleWrapper);

    const invisibleWrapperDiv = document.body.appendChild(invisibleWrapper);

    if (dtoType === DtoType.Inventory) {
      // appears to be synchronous
      root.render(
        <InventorySpecalizedTableRender
          data={data as InventoryDto[]}
          selectedRecords={selectedRowsIdentifierValues}
          invisibleContainerDiv={invisibleWrapperDiv}
          computedCombinedFilters={computedCombinedFilters}
        />
      );
    } else if (dtoType === DtoType.YardMap) {
      root.render(
        <YardMapSpecializedTableRender
          data={data as YardMapDto[]}
          selectedRecords={selectedRowsIdentifierValues}
          invisibleContainerDiv={invisibleWrapperDiv}
          computedCombinedFilters={computedCombinedFilters}
        />
      );
    } else if (dtoType === DtoType.SalesOrder) {
      root.render(
        <SalesOrderSpecializedTableRender
          data={data as SalesOrderDto[]}
          selectedRecords={selectedRowsIdentifierValues}
          invisibleContainerDiv={invisibleWrapperDiv}
          computedCombinedFilters={computedCombinedFilters}
        />
      );
    } else if (dtoType === DtoType.Delivery) {
      root.render(
        <DeliverySpecializedTableRender
          data={data as DeliveryDto[]}
          selectedRecords={selectedRowsIdentifierValues}
          invisibleContainerDiv={invisibleWrapperDiv}
          computedCombinedFilters={computedCombinedFilters}
        />
      );
    } else if (dtoType === DtoType.AccountingTodo) {
      root.render(
        <AccountingTodoSpecializedTableRender
          data={data as ReturnAccountingToDoDto[]}
          selectedRecords={selectedRowsIdentifierValues}
          invisibleContainerDiv={invisibleWrapperDiv}
          computedCombinedFilters={computedCombinedFilters}
        />
      );
    }

    // Reset the list
    // Disabling it for now
    setSelectedRowsIdentifierValues([]);
  }, [computedCombinedFilters, data, dtoType, selectedRowsIdentifierValues]);

  const cancelSelecting = () => {
    setSelectingRowMode("unselected");
    setSelectedRowsIdentifierValues([]);
  };

  const unselectAllRows = () => {
    setSelectedRowsIdentifierValues([]);
  };

  /* END -- Table Style Export Functionality -- END */

  // Discuseed some new design ideas with UT

  // const [testState, setTestState] = useState<boolean>(true);
  //

  const updateSearchTerm = useCallback(
    (searchTerm: string) => {
      setCustomFilters((oldCustomFilters) => {
        const clone = cloneDeep(oldCustomFilters);
        // Removes whitespace
        clone.searchTerm = searchTerm.trim();
        return clone;
      });
    },
    [setCustomFilters]
  );

  const getTargetRowOrConsoleError = useCallback(
    (
      key: keyof AllPossibleReturnTypes,
      value: string
    ): [number, AllPossibleReturnTypes] => {
      const targetRowIndex = (data as AllPossibleReturnTypes[]).findIndex(
        (dto) => {
          return dto[key as keyof AllPossibleReturnTypes] === value + "";
        }
      );
      if (targetRowIndex === -1) {
        const errorMessage = `Target row of ${key} with ${value} can't be found!!`;

        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const targetRow = data[targetRowIndex];
      return [targetRowIndex, targetRow];
    },
    [data]
  );

  // Boolean will return true if the input is the same as the output
  const handleNewUpdaterFunction = useCallback(
    async <T extends AllPossibleReturnTypes>(
      recordType: RecordType,
      newValueOrChecked: string | number | boolean | undefined,
      propertyName: keyof T,
      identifierPropertyValue: string,

      oldValue: unknown,
      onCancelledCallback?: () => void,

      onSuccess?: (value: string) => void
    ): Promise<boolean> => {
      const propertyDataType = getDataTypeByRecordTypeTypeAndPropertyName(
        recordType,
        propertyName
      );
      const dateType = propertyDataType === DataType.DateTime;

      // Handles null old Value and if it's from date value from the backend
      const oldV = isNullOrUndefined(oldValue)
        ? ""
        : dateType
        ? convertToInputDateFormat(oldValue + "")
        : oldValue + "";

      const processedNewValue = isNullOrUndefined(newValueOrChecked)
        ? ""
        : newValueOrChecked + "";

      if (oldV + "" === processedNewValue) {
        return true;
      }
      if (!confirmingTableUpdate()) {
        if (isNotNullNorUndefined(onCancelledCallback)) {
          onCancelledCallback();
        }
        return false;
      }

      let key = "";

      switch (recordType) {
        case RecordType.Inventory:
          key = "Stock";
          break;
        case RecordType.YardMap:
          key = "Yard Stall";
          break;
        case RecordType.SalesOrder:
          key = "Invoice Number";
          break;
        case RecordType.Delivery:
          key = "Stock";
          break;
        case RecordType.AccountingTodo:
          key = "Stock";
          break;
      }

      const d = data;
      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(
        key as keyof AllPossibleReturnTypes,
        identifierPropertyValue
      );

      const copiedTargetRow = cloneDeep(targetRow);

      const convertedValue = convertFromStringIfBooleanOrNumber(
        processedNewValue as string,
        propertyDataType
      );

      //@ts-ignore
      copiedTargetRow[propertyName] = convertedValue;

      // if ('value' in event.target) {
      //     // Has value
      //     event.target.value = convertedValue + '';
      // }
      // if ('checked' in event.target) {
      //     // has checked
      //     event.target.checked = convertedValue;
      // }

      let response:
        | null
        | AxiosResponse<ReturnModelWithMessageDto<any>, any>
        | AxiosResponse<ReturnMessageOnlyDto, any> = null;

      switch (recordType) {
        case RecordType.Inventory:
          response = await InventoryService.update(
            convertAllNullToEmptyStringFromObject(
              copiedTargetRow as InventoryDto
            )
          );
          break;
        case RecordType.YardMap:
          response = await YardMapService.updateMap(
            convertAllNullToEmptyStringFromObject(copiedTargetRow as YardMapDto)
          );
          break;
        case RecordType.SalesOrder:
          response = await SalesOrderService.updateSalesOrderFromQueriedTable(
            convertAllNullToEmptyStringFromObject(
              copiedTargetRow as SalesOrderDto
            )
          );
          break;
        case RecordType.Delivery:
          response = await DeliveryService.updateDeliveryForTable(
            convertAllNullToEmptyStringFromObject(
              copiedTargetRow as DeliveryDto
            )
          );
          break;
        case RecordType.AccountingTodo:
          response = await AccountingService.updateSalesOrderFromAccountingToDo(
            convertAllNullToEmptyStringFromObject(
              copiedTargetRow as ReturnAccountingToDoDto
            )
          );
          break;
      }

      if (isNullOrUndefined(response)) {
        makeSimpleToast(
          "Error",
          "Response has not been updated by according record type! Contact web developer!"
        );
        console.error(
          "Response has not been updated by according record type! Contact web developer!"
        );
        return false;
      }

      if (response.data.isSuccessful) {
        setData((oldData) => {
          if (isNotNullNorUndefined(onSuccess)) {
            //@ts-ignore
            onSuccess(copiedTargetRow[propertyName]);
          }

          //@ts-ignore

          const toUpdate = [...oldData];
          if (isNotNullNorUndefined(response) && "model" in response.data) {
            toUpdate.splice(targetRowIndex, 1, response.data.model);
          } else {
            toUpdate.splice(targetRowIndex, 1, copiedTargetRow);
          }

          return toUpdate;
        });
      } else {
        // setResetFieldsToggle((resetFieldsToggle) => !resetFieldsToggle);
        // Don't think  the above does anything
        if (isNotNullNorUndefined(onCancelledCallback)) {
          onCancelledCallback();
        }
      }

      return false;
    },
    [data, getTargetRowOrConsoleError]
  );

  const callEndpointAgain = useCallback(() => {
    if (isNullOrUndefined(computedCombinedFilters)) {
      return;
    }
    handleEndpointCall({
      endpoint,
      generalOptionsToUse: convertStringArrayGeneralOptionsToNormal(
        computedCombinedFilters
      ),
    });
  }, [computedCombinedFilters, endpoint, handleEndpointCall]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [nextRenderEndLoading, setNextRenderEndLoading] =
    useState<boolean>(false);

  const [t, setT] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    setNextRenderEndLoading(true);
  }, [isLoading]);

  useEffect(() => {
    if (!nextRenderEndLoading) {
      return;
    }

    setT(true);
    setNextRenderEndLoading(false);
  }, [nextRenderEndLoading]);

  useEffect(() => {
    if (!t) {
      return;
    }

    setIsLoading(false);

    setT(false);
  }, [computedCombinedFilters, t]);

  return (
    <div data-testid="queried-table-sort-filter-state-manager">
      <div
        className={`${styles.loaderContainer} ${
          filterStorageUpdatedCompleted &&
          isNotNullNorUndefined(computedCombinedFilters)
            ? styles.hiddenOpacity
            : ""
        }`}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
      {filterStorageUpdatedCompleted &&
        isNotNullNorUndefined(computedCombinedFilters) && (
          <div style={{ display: "relative", zIndex: "1" }}>
            {children({
              endpoint,
              justSortOptions,
              toolbar,
              identifier,
              identifierType,
              handleClearCancel,
              handleSetCustomFiltersValueFilters,
              handleCreateUpdateUserFilter,
              overwritingProvidedData,
              model,
              useCustomEndpoint,
              setUseCustomEndpoint,
              recordType,
              handleCreateUpdateGlobalFilter,
              handleDeleteUserFilter,
              handleDeleteGlobalFilter,
              handleUpdatingItemsPerPage,
              setOverwritingProvidedData,
              setSortFilters,
              dtoType,
              tabbed,
              data,
              selectedUserFilterNames,
              selectedGlobalFilterNames,
              computedCombinedFilters,
              handleUserFilterButtonClick,
              isFilterSelected,
              handleGlobalFilterButtonClick,
              setItemsPerPage,

              computedSelectedUserFilters,
              computedSelectedGlobalFilters,
              DEBOUNCE_WAIT_TIME,
              setData,
              handleChangeSorting,
              handleOrderColumnToLeft,
              handleOrderColumnToRight,
              updateCurrentPageNumber,

              userFiltersStorage,
              globalFiltersStorage,
              setColumnVisibility,
              selectingRowMode,
              startSelecting,
              stopSelectingAndCopyTableWithStyles,
              stopSelectingAndEmailAdvertisements,
              registerSelection,
              unselectRow,
              isRowIdSelected,
              selectAllRows,
              unselectAllRows,
              cancelSelecting,
              scrollTop,
              validLocationState: false, // isNotNullNorUndefined(locationState?.generalOptions)
              setColumnOrder,
              customFilters,

              unselectGlobalFilter,
              unselectUserFilter,
              ignoreFilter,
              unignoreFilter,
              isFilterIgnored,
              combinedFilterTargetProperties,

              updateSearchTerm,
              handleNewUpdaterFunction,
              ignoredFilterIds,
              callEndpointAgain,
              isLoading,

              setIsLoading,
            })}
          </div>
        )}
    </div>
  );
};
export default QueriedTableSortFilterStateManager;
