import { GeneratedIdType } from '../../../utils/KeyIdUtils';
import { Overwrite } from '../../../utils/OverrideTypeUtils';
import { FilterType } from '../../Global';
import { DynamicFilter } from './DynamicFilter';
import { DynamicFilterWithSourceInfo } from './DynamicFilterWithSourceInfo';
import { SortOptions } from './SortOptions';

export class GeneralOptions {
    filterOptions:
        | DynamicFilter[]
        | GeneratedIdType<DynamicFilter>[]
        | DynamicFilterWithSourceInfo[]
        | GeneratedIdType<DynamicFilterWithSourceInfo>[] = [];
    sortOptions: SortOptions = new SortOptions();
    columnsToShow = '';
    columnOrder = '';
    filterType: FilterType = FilterType.Custom;
    isFilterUpdate = false;
    filterName = '';
    searchTerm = '';

    constructor(obj?: GeneralOptions) {
        this.filterOptions = obj?.filterOptions ? obj.filterOptions : [];
        this.sortOptions = obj?.sortOptions ? obj.sortOptions : new SortOptions();
        this.columnOrder = obj?.columnOrder ? obj.columnOrder : '';
        this.columnsToShow = obj?.columnsToShow ? obj.columnsToShow : '';
        this.filterType = obj?.filterType ? obj.filterType : FilterType.Custom;
        this.isFilterUpdate = obj?.isFilterUpdate ? obj.isFilterUpdate : false;
        this.filterName = obj?.filterName ? obj.filterName : '';
        this.searchTerm = obj?.searchTerm ? obj.searchTerm : '';
    }
}
type GeneralOptionsWithoutColumnsToShow = Omit<GeneralOptions, 'columnsToShow'>;
type UpdatesColumnOrderTypeToStringArray = Overwrite<GeneralOptionsWithoutColumnsToShow, { columnOrder: string[] }>;
export type GeneralOptionsColumnsToShowAndColumnOrderAsArray = UpdatesColumnOrderTypeToStringArray & {
    orderedColumnsToShow: string[];
};

export const createNewGeneralOptionsColumnsToShowAndColumnOrderAsArray = () => {
    const newGeneralOptions = new GeneralOptions();
    const options: GeneralOptionsColumnsToShowAndColumnOrderAsArray = {
        ...newGeneralOptions,
        orderedColumnsToShow: [],
        columnOrder: [],
    };
    return options;
};
