import { IonButton } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import TableToPrint from '../PrintTable/TableToPrint';
import styles from './PrintTableController.module.scss';

interface PrintTableControllerProps<T extends object> {
    data: T[];
    identifierProperty: keyof T;
    columnsToShow?: (keyof T)[] | string;
    triggerJSX: React.ReactElement;
    hiddenContent: boolean;
    exportEnumProcessor: (dto: T) => T;
    enumProperties: (keyof T)[];
    printProcessor: (inventoryDto: T) => T;
}

const PrintTableController = <T extends object>({
    data,
    identifierProperty,
    columnsToShow,
    triggerJSX,
    hiddenContent,
    exportEnumProcessor,
    enumProperties,
    printProcessor,
}: PrintTableControllerProps<T>) => {
    const componentRef = useRef(null);

    // taco

    const [renderingPrintableTable, setRenderingPrintableTable] = useState<boolean>(false);

    const filteredData = data.map(isNullOrUndefined(printProcessor) ? (dto) => dto : printProcessor).map((dto) => {
        const updatedDto = { ...dto };

        Object.entries(dto).forEach(([key, value]) => (updatedDto[key as keyof T] = value ?? ''));

        return updatedDto;
    });

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handleAfterPrint = React.useCallback(() => {
        setRenderingPrintableTable(false);
    }, []);

    const reactToPrintFunc = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: 'Table Print',
        onAfterPrint: handleAfterPrint,
        copyStyles: false,
    });

    useEffect(() => {
        if (!renderingPrintableTable) {
            return;
        }

        reactToPrintFunc();
    }, [reactToPrintFunc, renderingPrintableTable]);

    const handlePrint = () => {
        setRenderingPrintableTable(true);
    };

    return (
        <div className={styles.PrintTableControllerContainer} data-testid="PrintTableController">
            <div>
                <>
                    <IonButton onClick={() => handlePrint()}>Print</IonButton>
                    {renderingPrintableTable &&
                        (hiddenContent ? (
                            <div style={{ display: 'none' }}>
                                <TableToPrint
                                    ref={componentRef}
                                    //@ts-ignore
                                    data={filteredData}
                                    identifierProperty={identifierProperty}
                                    columnsToShow={columnsToShow}
                                />
                            </div>
                        ) : (
                            <TableToPrint
                                ref={componentRef}
                                /*@ts-ignore */
                                data={filteredData}
                                identifierProperty={identifierProperty}
                                columnsToShow={columnsToShow}
                            />
                        ))}
                </>
            </div>
        </div>
    );
};

export default PrintTableController;
