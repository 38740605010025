export const CH_OFFSET = 2;

export const MIN_WIDTH_INPUT = '3rem';

export const NORMAL_ROWS_SIZE = 2;
export const LARGE_ROWS_SIZE = 3;

export const SMALL_COLS_SIZE = 15;

export const NORMAL_COLS_SIZE = 25;
export const LARGE_COLS_SIZE = 30;
