import React, { PropsWithChildren } from 'react';
import { Header, SideNav } from '../components/Theme';

export const Layout = (props: PropsWithChildren<any> & { className: string }) => {
    return (
        <>
            <div className={props.className}>
                <Header></Header>
            </div>

            <div>{props.children}</div>
            <div style={{ zIndex: '9999999999', pointerEvents: 'all' }}>
                <SideNav />
            </div>
        </>
    );
};
