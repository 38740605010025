import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ReactNode, useEffect, useState } from 'react';
import { DataTransferService, EmployeeService } from '../../../utils/apiServices';
import styles from './EmployeeEndpointCaller.module.scss';

interface EmployeeEndpointCallerProps {
    children: ReactNode;
}

const EmployeeEndpointCaller = ({ children }: EmployeeEndpointCallerProps) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [canShowContent, setCanShowContent] = useState<boolean>();

    const handleTransfer = () => {
        DataTransferService.transfer();
    };

    useEffect(() => {
        // Need to make sure Employee is logged in before calling employee endpoint

        // if (!isAuthenticated) {
        //     alert('Please send web developers message: isAuthenciated is false in EmployeeEndpointCaller!');
        //     return;
        // }

        if (inProgress !== InteractionStatus.None) {
            console.warn('in progress is not none while attempting to call employee endpoint');
            return;
        }

        EmployeeService.UpdateEmployeeOnLogin().then(() => setCanShowContent(true));
    }, [isAuthenticated, instance, inProgress]);

    return (
        <div className={styles.EmployeeEndpointCallerContainer} data-testid="EmployeeEndpointCaller">
            {canShowContent ? (
                children
            ) : (
                <div>
                    Loading ...
                    {/* {process.env.NODE_ENV === 'development' && <button onClick={handleTransfer}>transfer</button>} */}
                </div>
            )}
        </div>
    );
};

export default EmployeeEndpointCaller;
