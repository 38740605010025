import { isNullOrUndefined } from './NullOrUndefined';

const REQUIRED_ENVIRONMENT_VARIABLES = [
    'REACT_APP_CLIENT_ID',
    'REACT_APP_WEB_API_URL',
    'REACT_APP_REDIRECT_URL',
    'REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN',
    'REACT_APP_AUTHORITY',
    'REACT_APP_TENANT_ID',
    'REACT_APP_DEFAULT_SCOPES_URL',
    'REACT_APP_STORAGE_ACCOUNT_NAME',
];

const ensureAllRequiredEnvironmentVariablesAreNotUndefined = () => {
    for (let i = 0; i < REQUIRED_ENVIRONMENT_VARIABLES.length; i++) {
        const envName = REQUIRED_ENVIRONMENT_VARIABLES[i];

        if (isNullOrUndefined(process.env[envName])) {
            // One of them are undefined

            const root = document.getElementById('root');

            if (isNullOrUndefined(root)) {
                console.error('ROOT IS UNDEFINED!');
            } else {
                root.style.visibility = 'hidden';
            }

            const envErrorPage = document.createElement('div');
            envErrorPage.style.display = 'fixed';
            envErrorPage.style.width = '70vw';
            envErrorPage.style.height = '70vh';

            const envErrorPageHeader = document.createElement('h1');
            envErrorPage.innerHTML = `Please Contact Web Developer - Env Variables Incorrectly Configured for ${envName} `;

            envErrorPage.appendChild(envErrorPageHeader);

            document.body.appendChild(envErrorPage);
            return false;
        }
    }

    return true;
};

export default ensureAllRequiredEnvironmentVariablesAreNotUndefined;
