import { IonIcon } from "@ionic/react";
import debounce from "debounce";
import {
  caretDownOutline,
  caretUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { InventoryDto } from "../../../models/Dtos/InventoryDtos/InventoryDto";
import { TypeInteractionDto } from "../../../models/Dtos/TypeInteractionDto";
import {
  DataType,
  InventoryCondition,
  InventoryStatus,
  InventorySubType,
  PhotoStatus as PhotoStatusEnum,
  RecordType,
} from "../../../models/Global";
import newTableStyles from "../../../newTableStyles.module.scss";
import { DbManagedTypes } from "../../../pages/AdminPanel/components/TypeManager";
import { Layout } from "../../../pages/Layout";
import {
  DataTransferService,
  InventoryService,
  TypeService,
} from "../../../utils/apiServices";
import { cTB } from "../../../utils/BooleanStringHelper";
import {
  filterInventoryColumnMethod,
  filterInventoryKeyArrayByExcludedColumns,
} from "../../../utils/ColumnsToShowFilterByDtoType/InventoryColumnsToShowFilter";
import { CommaAdderToNumber } from "../../../utils/CommaAdderToNumber";
import confirmingTableUpdate from "../../../utils/ConfirmingTableUpdate";
import { convertAllNullToEmptyStringFromObject } from "../../../utils/ConvertAllNullToEmptyStringUtil";
import { convertFromStringIfBooleanOrNumber } from "../../../utils/DataTypeConversionFromString/DataTypeConversionFromString";
import { getDataTypeFromInventoryDtoPropertyName } from "../../../utils/DataTypeMappersByDtoType/InventoryDtoDataTypeMapper";
import { convertToInputDateFormat } from "../../../utils/DateUtils";
import {
  EnumInventoryProperties,
  getEnumFromInventoryDtoPropertyName,
} from "../../../utils/EnumMappersByDtoType/InventoryDtoEnumMapper";
import { IdentifierType } from "../../../utils/identifierType";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../../../utils/NullOrUndefined";
import PageNameGenerator from "../../../utils/PageNameGenerator";
import { reactSelectStylesForTable } from "../../../utils/ReactSelectStylesForTable";
import { AllPossibleReturnTypes } from "../../../utils/RecordTypeUtils";
import CopyTableStylesButtons from "../../CopyTableStylesButtons/CopyTableStylesButtons";
import {
  canUserAccessAdminSecurityWithGivenRoles,
  RoleContext,
} from "../../RoleContext/RoleContextComp";
import { ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID } from "../../Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer";
import QueriedTablePaginationOptions from "../../Theme/QueriedTable/QueriedTablePaginationOptions/QueriedTablePaginationOptions";
import {
  LocationType,
  QueriedTableSortFilterStateManagerExportProps,
} from "../../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import QueriedTableGlobalUserFilterList from "../../Theme/QueriedTable/QueriedTableTabList/QueriedTableGlobalUserFilterList";
import QueriedTableToolbar from "../../Theme/QueriedTable/QueriedTableToolbar/QueriedTableToolbar";
import {
  CH_OFFSET,
  MIN_WIDTH_INPUT,
  NORMAL_COLS_SIZE,
  NORMAL_ROWS_SIZE,
  SMALL_COLS_SIZE,
} from "../GeneralReference";
import styles from "./InventoryTable.module.scss";

type InventoryTableProps = QueriedTableSortFilterStateManagerExportProps;

const InventoryTable = ({
  endpoint,
  toolbar,
  identifier,
  identifierType,
  handleSetCustomFiltersValueFilters,
  handleCreateUpdateUserFilter,
  handleUserFilterButtonClick,
  justSortOptions,
  overwritingProvidedData,
  model,
  useCustomEndpoint,
  setUseCustomEndpoint,
  recordType,
  handleClearCancel,
  isFilterSelected,
  handleCreateUpdateGlobalFilter,
  handleDeleteGlobalFilter,
  handleDeleteUserFilter,
  handleUpdatingItemsPerPage,
  setOverwritingProvidedData,
  setSortFilters,
  dtoType,
  tabbed,
  handleGlobalFilterButtonClick,
  data,
  computedCombinedFilters,
  selectedGlobalFilterNames,
  selectedUserFilterNames,
  userFiltersStorage,
  globalFiltersStorage,
  computedSelectedGlobalFilters,
  computedSelectedUserFilters,

  DEBOUNCE_WAIT_TIME,
  setData,
  handleChangeSorting,
  handleOrderColumnToLeft,
  handleOrderColumnToRight,
  updateCurrentPageNumber,
  setColumnVisibility,
  selectingRowMode,
  startSelecting,
  stopSelectingAndCopyTableWithStyles,
  stopSelectingAndEmailAdvertisements,
  registerSelection,
  unselectRow,
  isRowIdSelected,
  selectAllRows,
  unselectAllRows,
  cancelSelecting,
  scrollTop,
  validLocationState,
  customFilters,
  unselectGlobalFilter,
  unselectUserFilter,
  ignoreFilter,
  unignoreFilter,
  isFilterIgnored,
  combinedFilterTargetProperties,
  updateSearchTerm,
  handleNewUpdaterFunction,
  ignoredFilterIds,
  callEndpointAgain,
  isLoading,

  setIsLoading,
}: InventoryTableProps) => {
  const history = useHistory();
  const location = useLocation();
  const handleTransfer = () => {
    DataTransferService.transfer();
  };

  const tBodyRef = useRef<HTMLTableSectionElement | null>(null);

  const INVENTORY_PAGE_TITLE = PageNameGenerator("Inventory");

  useEffect(() => {
    if (document.title !== INVENTORY_PAGE_TITLE)
      document.title = INVENTORY_PAGE_TITLE;
  }, []);

  const generateTableHeaders = useCallback(
    (dto: InventoryDto) => {
      if (isNullOrUndefined(dto)) {
        return null;
      }

      let headers: React.ReactElement[];

      const getSortForColumn = (property: string) => {
        const targetSort = computedCombinedFilters.sortOptions.sortBy.find(
          (item) => item.propertyName === property
        );

        if (isNullOrUndefined(targetSort)) {
          return undefined;
        }

        return targetSort.isAscending;
      };

      const generateThMapMethod = (property: string, index: number) => (
        <th key={property}>
          <div className={styles.tableHeaderButtonWrapper}>
            <span className={styles.headerSpanContainer}>
              <div style={{ display: "block" }}>
                <button
                  onClick={(e) => handleChangeSorting(property)}
                  className={styles.headerButton}
                >
                  {property}
                  {isNullOrUndefined(getSortForColumn(property)) ? (
                    <></>
                  ) : getSortForColumn(property) ? (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretUpOutline}
                    />
                  ) : (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretDownOutline}
                    />
                  )}
                </button>
              </div>
              {index > 0 && (
                <button
                  onClick={(e) =>
                    handleOrderColumnToLeft(
                      property as keyof AllPossibleReturnTypes
                    )
                  }
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.leftColumnButton}`}
                    icon={chevronBackOutline}
                  />
                </button>
              )}
              {index <
                computedCombinedFilters.orderedColumnsToShow.length - 1 && (
                <button
                  onClick={(e) =>
                    handleOrderColumnToRight(
                      property as keyof AllPossibleReturnTypes
                    )
                  }
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.rightColumnButton}`}
                    icon={chevronForwardOutline}
                  />
                </button>
              )}
            </span>
          </div>
        </th>
      );

      const selectionColumn = (
        <th
          className={
            selectingRowMode === "unselected"
              ? styles.selectionColumnHidden
              : ""
          }
          style={{ backgroundColor: "white" }}
          key="selection-column"
        >
          To Copy
        </th>
      );

      if (
        isNullOrUndefined(computedCombinedFilters) ||
        !Array.isArray(computedCombinedFilters.orderedColumnsToShow) ||
        computedCombinedFilters.orderedColumnsToShow[0] === ""
      ) {
        // Get keys  of first entry to get headers.
        headers = [
          selectionColumn,
          ...Object.keys(dto)
            .filter(filterInventoryColumnMethod)
            .map(generateThMapMethod),
        ];
      } else {
        // Use columnsToShow from savedGeneratedGeneralOptions to get headers.

        // REAPLCE HERE WITH STUF

        headers = [
          selectionColumn,
          ...computedCombinedFilters.orderedColumnsToShow.map(
            generateThMapMethod
          ),
        ];
      }
      return headers;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      computedCombinedFilters,
      handleChangeSorting,
      handleOrderColumnToLeft,
      handleOrderColumnToRight,
      selectingRowMode,
    ]
  );

  const calculateVirtualizedRows = useCallback(() => {
    // const containerStart = 500; // Maybe get tbody starting horizontal position

    let containerStart = 500;

    if (isNotNullNorUndefined(tBodyRef.current)) {
      //    const outlet = (document.getElementById('main-ion-router-outlet') HTMLIonRouterOutletElement)
      containerStart = tBodyRef.current.getBoundingClientRect().top + scrollTop;
    }

    const rowHeight = 65;

    const viewHeight = window.innerHeight;

    const itemsShownAtOnce = Math.ceil(viewHeight / rowHeight);

    // Start Index Inner Logic:
    const differenceBetweenScrollTopAndContainerStart = Math.max(
      scrollTop - containerStart,
      0
    );

    const numberOfRowsBetweenScrollTopAndContainerStart = Math.floor(
      differenceBetweenScrollTopAndContainerStart / rowHeight
    );

    const startIndex = Math.min(
      Math.max(numberOfRowsBetweenScrollTopAndContainerStart, 0),
      data.length - itemsShownAtOnce
    );
    // Unsure if w need the +1 here
    const endIndex = Math.ceil(
      Math.min(startIndex + itemsShownAtOnce + 1, data.length)
    );

    const beforeDivHeight = Math.max(startIndex, 0) * rowHeight;
    const afterDivHeight = Math.max(data.length - endIndex, 0) * rowHeight;

    // const body = Array.from(document.getElementsByTagName('body'))[0] as HTMLBodyElement;
    // const debugInfo = document.createElement('div');

    // debugInfo.style.position = 'fixed';
    // debugInfo.id = 'debug-info';
    // debugInfo.style.right = '0';
    // debugInfo.style.bottom = '0';
    // debugInfo.innerHTML = `

    // <h1>ContainerStart</h1>
    // <p>${containerStart}</p>
    // <h1>Row Height</h1>
    // <p>${rowHeight}</p>
    // <h1>View Height</h1>
    // <p>${viewHeight}</p>

    // <h1>Items Shown At Once</h1>
    // <p>${itemsShownAtOnce}</p>

    // <h1>Scroll Top</h1>
    // <p>${scrollTop}</p>

    // <h1>Start Index</h1>
    // <p>${startIndex}</p>

    // <h1>Start Index A: differenceBetweenScrollTopAndContainerStart </h1>
    // <p>${differenceBetweenScrollTopAndContainerStart}</p>

    // <h1>Start Index B: numberOfRowsBetweenScrollTopAndContainerStart </h1>
    // <p>${numberOfRowsBetweenScrollTopAndContainerStart}</p>

    // <h1>End Index</h1>
    // <p>${endIndex}</p>

    // <h1>BeforeDivHeight</h1>
    // <p>${beforeDivHeight}</p>
    // <h1>AfterDivHeight</h1>
    // <p>${afterDivHeight}</p>
    // `;
    // debugInfo.style.marginRight = '1rem';
    // debugInfo.style.marginBottom = '1rem';
    // debugInfo.style.backgroundColor = 'cyan';

    // body.appendChild(debugInfo);

    return [startIndex, endIndex, rowHeight, beforeDivHeight, afterDivHeight];
  }, [data.length, scrollTop]);

  const calculateVirtualizedColumns = useCallback(() => {
    const containerStart = 500;

    const rowHeight = 65;

    const viewHeight = window.innerHeight;

    const itemsShownAtOnce = Math.ceil(viewHeight / rowHeight);

    const startIndex =
      Math.floor(Math.max(scrollTop - containerStart, 0) / rowHeight) - 1;
    // Unsure if w need the +1 here
    const endIndex = Math.ceil(
      Math.min(startIndex + itemsShownAtOnce + 1, data.length)
    );

    return [startIndex, endIndex, rowHeight];
  }, [data.length, scrollTop]);

  // const handleInputUpdateValueProvider = async (
  //     event: ChangeEvent<HTMLInputElement>,
  //     columnName: keyof InventoryDto,
  //     stock: string
  // ) => {
  //     const isCheckbox = event.target.type === 'checkbox';
  //     const value = event.target.value === '0.00' ? '' : isCheckbox ? event.target.checked : event.target.value;

  //     if (isCheckbox) {
  //         handleUnDebouncedUpdate(value, event, columnName, stock);
  //     } else {
  //         handleInputUpdate(value, event, columnName, stock);
  //     }
  // };

  const handleTextareaUpdateValueProvider = (
    event: ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof InventoryDto,
    stock: string,
    oldValue?: unknown
  ) => {
    const value = event.target.value;

    const oldV = isNullOrUndefined(oldValue) ? "" : oldValue + "";

    if (oldV === value) {
      return;
    }
    if (!confirmingTableUpdate(event)) {
      event.target.value = oldValue + "";
      return;
    }

    handleInputUpdate(value, event, columnName, stock);
  };

  const handleSelectOrTextAreaUpdateValueProvider = (
    event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof InventoryDto,
    stock: string,
    oldValue: string | number
  ) => {
    let value = "";

    if ("value" in event.target) {
      // Has value
      value = event.target.value + "";
    }
    if (
      "type" in event.target &&
      event.target.type === "checkbox" &&
      "checked" in event.target
    ) {
      // has checked
      value = event.target.checked + "";
    }

    const oldV = isNullOrUndefined(oldValue) ? "" : oldValue + "";

    if (oldV === value) {
      return;
    }

    if (!confirmingTableUpdate(event)) {
      return;
    }

    const newValue = event.target.value;

    handleUnDebouncedUpdate(value, event, columnName, stock);
  };

  const handleInputUpdate = debounce(
    async (
      value: string | boolean,
      event:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>,
      columnName: keyof InventoryDto,
      stock: string
    ) => {
      if (!confirmingTableUpdate(event)) {
        return;
      }
      const isCheckbox = event.target.type === "checkbox";

      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

      const copiedTargetRow = { ...targetRow };

      const convertedValue = isCheckbox
        ? value
        : convertFromStringIfBooleanOrNumber(
            value as string,
            getDataTypeFromInventoryDtoPropertyName(
              columnName as keyof InventoryDto
            )
          );

      //@ts-ignore
      copiedTargetRow[columnName] = convertedValue;

      // prop = ;

      // Is debounce 1000ms

      const response = await InventoryService.update(
        convertAllNullToEmptyStringFromObject(copiedTargetRow as InventoryDto)
      );

      setData((oldData) => {
        const toUpdate = [...oldData];
        toUpdate.splice(targetRowIndex, 1, response.data.model);
        return toUpdate;
      });
    },
    DEBOUNCE_WAIT_TIME
  );

  const handleUnDebouncedUpdate = async (
    value: string | boolean,
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof InventoryDto,
    stock: string
  ) => {
    if (!confirmingTableUpdate(event)) {
      return;
    }
    const isCheckbox = event.target.type === "checkbox";

    const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

    const copiedTargetRow = { ...targetRow };

    const convertedValue = isCheckbox
      ? value
      : convertFromStringIfBooleanOrNumber(
          value as string,
          getDataTypeFromInventoryDtoPropertyName(
            columnName as keyof InventoryDto
          )
        );

    //@ts-ignore
    copiedTargetRow[columnName] = convertedValue;

    // prop = ;

    // Is debounce 1000ms

    const response = await InventoryService.update(
      convertAllNullToEmptyStringFromObject(copiedTargetRow)
    );

    setData((oldData) => {
      const toUpdate = [...oldData];
      toUpdate.splice(targetRowIndex, 1, response.data.model);
      return toUpdate;
    });
  };

  const convertDefaultCheckbox = (checkboxStringValue: string | boolean) =>
    typeof checkboxStringValue === "boolean"
      ? checkboxStringValue
      : checkboxStringValue.toLowerCase() === "true";

  type testtest = { label: string; value: string };
  const test = [
    {
      label: "taco",
      value: "lucky",
    },
    {
      label: "tomato",
      value: "sdfds",
    },
  ];
  type OptionType = {
    label: string;
    value: string;
    title: string;
  };

  const [wheelTypes, setWheelTypes] = useState<OptionType[]>([]);
  const [rearDoorTypes, setRearDoorTypes] = useState<OptionType[]>([]);
  const [suspensionTypeTypes, setSuspensionTypeTypes] = useState<OptionType[]>(
    []
  );
  const [suspensionStyleTypes, setSuspensionStyleTypes] = useState<
    OptionType[]
  >([]);
  const [floorTypes, setFloorTypes] = useState<OptionType[]>([]);
  const [typeTypes, setTypeTypes] = useState<OptionType[]>([]);
  const [lengthTypes, setLengthTypes] = useState<OptionType[]>([]);
  const [widthTypes, setWidthTypes] = useState<OptionType[]>([]);
  const [heightTypes, setHeightTypes] = useState<OptionType[]>([]);
  const [makeTypes, setMakeTypes] = useState<OptionType[]>([]);
  const [modelTypes, setModelTypes] = useState<OptionType[]>([]);
  const [wheelSizeTypes, setWheelSizeTypes] = useState<OptionType[]>([]);
  const [reeferMakeTypes, setReeferMakeTypes] = useState<OptionType[]>([]);
  const [reeferModelTypes, setReeferModelTypes] = useState<OptionType[]>([]);
  const [cityLocationTypes, setCityLocationTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    const handleUpdatingType = async (
      dbManagedType: DbManagedTypes,
      updateFunc: (types: OptionType[]) => unknown
    ) => {
      const typeInteractionDto: TypeInteractionDto = {
        originalName: "",
        newName: "",
        dbManagedType: dbManagedType,
      };

      const response = await TypeService.GetNameList(typeInteractionDto);

      const types = response.data.map((type) => ({
        label: type,
        value: type,
        title: type,
      }));

      updateFunc(types);
    };

    handleUpdatingType(DbManagedTypes.WheelType, setWheelTypes);
    handleUpdatingType(DbManagedTypes.RearDoorType, setRearDoorTypes);
    handleUpdatingType(DbManagedTypes.SuspensionType, setSuspensionTypeTypes);
    handleUpdatingType(DbManagedTypes.SuspensionStyle, setSuspensionStyleTypes);
    handleUpdatingType(DbManagedTypes.FloorType, setFloorTypes);
    handleUpdatingType(DbManagedTypes.InventoryType, setTypeTypes);
    handleUpdatingType(DbManagedTypes.Length, setLengthTypes);
    handleUpdatingType(DbManagedTypes.Width, setWidthTypes);
    handleUpdatingType(DbManagedTypes.Height, setHeightTypes);
    handleUpdatingType(DbManagedTypes.Make, setMakeTypes);
    handleUpdatingType(DbManagedTypes.Model, setModelTypes);
    handleUpdatingType(DbManagedTypes.WheelSize, setWheelSizeTypes);
    handleUpdatingType(DbManagedTypes.ReeferMake, setReeferMakeTypes);
    handleUpdatingType(DbManagedTypes.ReeferModel, setReeferModelTypes);
    handleUpdatingType(DbManagedTypes.CityLocation, setCityLocationTypes);
  }, []);

  const getTargetRowOrConsoleError = useCallback(
    (stock: string): [number, InventoryDto] => {
      const targetRowIndex = (data as InventoryDto[]).findIndex(
        (dto) => dto["Stock"] === stock + ""
      );
      if (targetRowIndex === -1) {
        const errorMessage = `Inventory Target with stock  ${stock} can't be found!!`;

        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const targetRow = data[targetRowIndex];
      return [targetRowIndex, targetRow as InventoryDto];
    },
    [data]
  );

  const handleUpdateType = useCallback(
    async (
      value: string | number | undefined,
      column: keyof InventoryDto,
      dbManagedType: DbManagedTypes,
      stock: string,
      oldValue: string | number
    ) => {
      const oldV = isNullOrUndefined(oldValue) ? value : oldValue + "";

      if (oldV === value) {
        return;
      }

      if (!confirmingTableUpdate()) {
        return;
      }

      if (isNullOrUndefined(value)) {
        return;
      }

      const [, targetDto] = getTargetRowOrConsoleError(stock);

      const copiedTargetDto = { ...targetDto };

      //@ts-ignore
      copiedTargetDto[column] = value;

      const result = await InventoryService.update(
        convertAllNullToEmptyStringFromObject(copiedTargetDto)
      );

      if (result.data.isSuccessful) {
        callEndpointAgain();
      }

      // setData((oldData) => {
      //     const targetIndex = oldData.findIndex((dto) => (dto as InventoryDto).Stock === stock);
      //     if (targetIndex === -1) {
      //         return oldData;
      //     }

      //     const duplicate = [...oldData];
      //     duplicate.splice(targetIndex, 1, result.data.model);
      //     return duplicate;
      // });

      return result.data.isSuccessful;
    },
    [callEndpointAgain, getTargetRowOrConsoleError]
  );

  const tableDivContainerRef = useRef<HTMLDivElement>(null);

  const INPUT_CLASS_NAME = "inventory-table-input-class-name";

  // Updates elements based on data update
  // useEffect(() => {
  //     if (isNullOrUndefined(tableDivContainerRef.current)) {
  //         console.log('table div element is undefined');
  //         return;
  //     }

  //     const tableDiv = tableDivContainerRef.current;

  //     Array.from(tableDiv.getElementsByClassName(INPUT_CLASS_NAME)).forEach((inputEl) => {
  //         // not null bc of filter above.
  //         const notNullInput = inputEl as HTMLInputElement | HTMLSelectElement;
  //         const [stock, name] = notNullInput.name.split(':');

  //         const target = (data as InventoryDto[]).find((dto) => dto.Stock === stock);

  //         if (isNullOrUndefined(target)) {
  //             const errorMessage = `Attempting to find row with stock ${stock} but can't be found! Please alert web developers`;
  //             console.error(errorMessage);
  //             alert(errorMessage);
  //             return;
  //         }

  //         let value = target[name as keyof InventoryDto];

  //         if (isNullOrUndefined(value) || value === '0.00') {
  //             value = '';
  //         }

  //         const dataType = getDataTypeFromInventoryDtoPropertyName(name as keyof InventoryDto);
  //         if (dataType === DataType.DateTime) {
  //             if (value === '') {
  //                 // Will look like dd/mm/yyyy. Want it to be empty. So we'll set color to transparent when not focused
  //                 (inputEl as HTMLInputElement).classList.add(styles.dateInputEmpty);
  //             } else {
  //                 (inputEl as HTMLInputElement).classList.remove(styles.dateInputEmpty);

  //                 value = convertToInputDateFormat(value as string);
  //             }
  //         }
  //         if (dataType === DataType.Bool) {
  //             value = convertDefaultCheckbox(value as string | boolean);
  //         }

  //         notNullInput.value = value + '';
  //     });
  // }, [data]);

  const stringifyNumberOption = 4;

  const handleCopyFilterDebugInfoToClipboard = () => {
    let stringToShow = "";

    const dictOfThingsToPrint = {
      "General Options": JSON.stringify(
        computedCombinedFilters,
        null,
        stringifyNumberOption
      ),
      "Global Filter Storage": JSON.stringify(
        globalFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "User Filter Storage": JSON.stringify(
        userFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "Selected Global Filter Names": JSON.stringify(
        selectedGlobalFilterNames,
        null,
        stringifyNumberOption
      ),
      "Selected  User Filter Names": JSON.stringify(
        selectedUserFilterNames,
        null,
        stringifyNumberOption
      ),
      "Computed Selected Global Filters": JSON.stringify(
        computedSelectedGlobalFilters,
        null,
        stringifyNumberOption
      ),
      "Computed Selected  User Filters": JSON.stringify(
        computedSelectedUserFilters,
        null,
        stringifyNumberOption
      ),
      "Local Stroage": JSON.stringify(
        { ...localStorage },
        null,
        stringifyNumberOption
      ),
    };

    Object.entries(dictOfThingsToPrint).forEach(([key, value]) => {
      stringToShow += "\n";
      stringToShow += `~~~~${key}~~~~\n`;
      stringToShow += value;
    });

    window.navigator.clipboard.writeText(stringToShow);
  };

  const handleSelectOnChangeUpdatingRows = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    colSize: number
  ) => {
    const textArea = e.target as HTMLTextAreaElement;
    const value = e.target.value;

    const quotient = value.length / colSize;

    const rowsToSet = Math.floor(quotient) + 1;

    if (textArea.rows !== rowsToSet) {
      textArea.rows = rowsToSet;
    }
  };

  // Idea go through each value of the column and determine the biggest width. and then perhaps
  // set the inputs to width 100% unless they match the biggest width. I'm not sure.

  const generateInputStylesAndUpdateBiggestInputWidth = (
    valueToLookAt: string,
    columnName: string
  ) => {
    let width = 0;
    if (
      isNotNullNorUndefined(valueToLookAt) &&
      valueToLookAt !== "" &&
      valueToLookAt !== "null"
    ) {
      width = valueToLookAt.length;
    }
    return { width: width + CH_OFFSET + "ch", minWidth: MIN_WIDTH_INPUT };
  };

  const roles = useContext(RoleContext);

  // NEED TO ITERATE THROUGH

  const savedFunctionsToUpdateData = useMemo(
    () => ({
      "Stock": (
        property: keyof InventoryDto,
        Stock: string | number,
        stock: string
      ) => {
        return (
          <td
            className={`${styles.tData} ${styles.stockTdContainer}`}
            key={`${stock}-${property}`}
            onMouseUp={(e) => {
              if (e.button === 1) {
                if ((e.target as Element).tagName === "A") {
                  // should be handled by the anchor tag itself
                  return;
                }
                e.preventDefault();
                const link = `/inventory/edit/${IdentifierType.Stock}/${Stock}`;

                window.open(link, "_blank");
                e.stopPropagation();
                return;
              }
            }}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const link = `/inventory/edit/${IdentifierType.Stock}/${Stock}`;

              if (e.ctrlKey) {
                window.open(link, "_blank");
                e.stopPropagation();
                return;
              }

              if (e.shiftKey) {
                window.open(link);
                e.stopPropagation();
                return;
              }
              // Will implement the state and bringing back to search results later..
              history.push({
                pathname: `/inventory/edit/${IdentifierType.Stock}/${Stock}`,
                state: {
                  selectedGlobalFilterNames,
                  selectedUserFilterNames,
                  globalFiltersStorage,
                  userFiltersStorage,
                  customFilters,
                  ignoredFilterIds,
                } as LocationType,
              });
            }}
          >
            <NavLink
              // onClick={() => {
              // history.replace(location.pathname, { test: 'hello' });
              // }}
              to={{
                pathname: `/inventory/edit/${IdentifierType.Stock}/${Stock}`,
                // Will implement the state and bringing back to search results later..

                state: {
                  selectedGlobalFilterNames,
                  selectedUserFilterNames,
                  globalFiltersStorage,
                  userFiltersStorage,
                  customFilters,
                  ignoredFilterIds,
                } as LocationType,
              }}
            >
              {Stock}
            </NavLink>
          </td>
        );
      },
      "VIN": (
        property: keyof InventoryDto,
        VIN: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          VIN + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              // const tableDataEl = e.target as HTMLTableCellElement;
              // if (tableDataEl.tagName !== 'TD') {
              //     return;
              // }
              // const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;
              // inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                VIN + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              type="text"
              // onChange={(e) => handleInputUpdateValueProvider(e, 'VIN', stock)}

              defaultValue={VIN}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  VIN,
                  () => (e.target.value = VIN + "")
                );
              }}
            />
          </td>
        );
      },
      "Number": (
        property: keyof InventoryDto,
        Number: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Number + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              name={`${stock}:${property}`}
              cols={10}
              rows={(Number + "").length < 10 ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              onChange={(e) => handleSelectOnChangeUpdatingRows(e, 10)}
              defaultValue={Number}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Number,
                  () => (e.target.value = Number + "")
                );
              }}
            />
          </td>
        );
      },
      "Color": (
        property: keyof InventoryDto,
        Color: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Color + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={SMALL_COLS_SIZE}
              rows={(Color + "").length < SMALL_COLS_SIZE ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              name={`${stock}:${property}`}
              defaultValue={Color}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Color,
                  () => (e.target.value = Color + "")
                );
              }}
            />
          </td>
        );
      },
      "Sales Person": (
        property: keyof InventoryDto,
        SalesPerson: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          SalesPerson + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              readOnly={true}
              cols={10}
              rows={(SalesPerson + "").length < 10 ? 1 : 2}
              className={`${10} ${styles.textArea}`}
              name={`${stock}:${property}`}
              defaultValue={SalesPerson}
            />
          </td>
        );
      },
      "Deposit": (
        property: keyof InventoryDto,
        Deposit: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Deposit + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              // style={generateInputStylesAndUpdateBiggestInputWidth(Deposit + '', property)}
              readOnly={true}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              value={Deposit}
              // onBlur={(e) => handleNewUpdaterFunction(e.target.value, property, stock, Deposit)}
              // onChange={(e) => handleInputUpdateValueProvider(e, 'Deposit', stock)}
            />
          </td>
        );
      },
      "Spec": (
        property: keyof InventoryDto,
        Spec: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Spec + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={7}
              rows={(Spec + "").length < 7 ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              name={`${stock}:${property}`}
              onChange={(e) => handleSelectOnChangeUpdatingRows(e, 7)}
              defaultValue={Spec}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Spec,
                  () => (e.target.value = Spec + "")
                );
              }}
            />
          </td>
        );
      },
      "Customer": (
        property: keyof InventoryDto,
        Customer: string | number,
        stock: string
      ) => {
        const styleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Customer + "",
          property
        );

        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={NORMAL_COLS_SIZE}
              rows={(Customer + "").length < NORMAL_COLS_SIZE ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              name={`${stock}:${property}`}
              defaultValue={Customer}
              readOnly={true}
            />
          </td>
        );
      },
      "Price": (
        property: keyof InventoryDto,
        Price: string | number,
        stock: string
      ) => {
        const priceDisplayValue = "$" + CommaAdderToNumber(Price as number);

        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={{
                ...generateInputStylesAndUpdateBiggestInputWidth(
                  priceDisplayValue,
                  property
                ),
              }}
              onFocus={(e) => {
                (e.target as HTMLInputElement).value = Price + "";
              }}
              className={`${INPUT_CLASS_NAME}`}
              name={`${stock}:${property}`}
              defaultValue={priceDisplayValue}
              onBlur={async (e) => {
                const inputSame = await handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Price,
                  () => (e.target.value = priceDisplayValue),
                  (value) => {
                    e.target.value = "$" + CommaAdderToNumber(+value);
                  }
                );

                if (inputSame) {
                  e.target.value = priceDisplayValue;
                }
              }}
              // onChange={(e) => handleInputUpdateValueProvider(e, 'Price', stock)}
            />
          </td>
        );
      },
      "Comp Date": (
        property: keyof InventoryDto,
        CompDate: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          CompDate + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(CompDate) ||
                              CompDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(CompDate)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  CompDate,
                  () => {
                    e.target.value = convertToInputDateFormat(CompDate);
                  }
                );

                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
              // onChange={(e) => handleInputUpdateValueProvider(e, 'Comp Date', stock)}
            />
          </td>
        );
      },
      "Inspection Date": (
        property: keyof InventoryDto,
        InspectionDate: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          InspectionDate + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(InspectionDate) ||
                              InspectionDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(InspectionDate)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  InspectionDate,
                  () => {
                    e.target.value = convertToInputDateFormat(InspectionDate);
                  }
                );

                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
              // onChange={(e) => handleInputUpdateValueProvider(e, 'Inspection Date', stock)}
            />
          </td>
        );
      },
      "Received Date": (
        property: keyof InventoryDto,
        ReceivedDate: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReceivedDate + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(ReceivedDate) ||
                              ReceivedDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(ReceivedDate)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  ReceivedDate,
                  () => {
                    e.target.value = convertToInputDateFormat(ReceivedDate);
                  }
                );
                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
            />
          </td>
        );
      },

      "Advertise": (
        property: keyof InventoryDto,
        Advertise: string | boolean,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Advertise + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              type="checkbox"
              checked={convertDefaultCheckbox(Advertise)}
              onChange={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  !cTB(Advertise),
                  property,
                  stock,
                  cTB(Advertise)
                );
              }}
            />
          </td>
        );
      },
      "DOT Status": (
        property: keyof InventoryDto,
        DOTStatus: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          DOTStatus + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={10}
              rows={(DOTStatus + "").length < 10 ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              name={`${stock}:${property}`}
              defaultValue={DOTStatus}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  DOTStatus,
                  () => (e.target.value = DOTStatus + "")
                );
              }}
            />
          </td>
        );
      },
      "Decal Id": (
        property: keyof InventoryDto,
        DecalId: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          DecalId + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                DecalId + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={DecalId}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  DecalId,
                  () => (e.target.value = DecalId + "")
                );
              }}
            />
          </td>
        );
      },
      "Check Number": (
        property: keyof InventoryDto,
        CheckNumber: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          CheckNumber + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                CheckNumber + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={CheckNumber}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  CheckNumber,
                  () => (e.target.value = CheckNumber + "")
                );
              }}
            />
          </td>
        );
      },
      "City Location": (
        property: keyof InventoryDto,
        CityLocation: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          CityLocation + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div
              className={styles.asyncSelectDiv}
              title={CityLocation + ""}
              style={{ width: "12rem" }}
            >
              <Select
                styles={{ ...reactSelectStylesForTable() }}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  cityLocationTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.CityLocation,
                    stock,
                    CityLocation + ""
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: CityLocation + "",
                  value: CityLocation + "",
                  title: CityLocation + "",
                }}
                name="test"
              />
            </div>
          </td>
        );
      },

      "Cost": (
        property: keyof InventoryDto,
        Cost: string | number,
        stock: string
      ) => {
        const priceDisplayValue = "$" + CommaAdderToNumber(Cost as number);

        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={{
                ...generateInputStylesAndUpdateBiggestInputWidth(
                  priceDisplayValue,
                  property
                ),
              }}
              onFocus={(e) => {
                (e.target as HTMLInputElement).value = Cost + "";
              }}
              className={`${INPUT_CLASS_NAME}`}
              name={`${stock}:${property}`}
              defaultValue={priceDisplayValue}
              onBlur={async (e) => {
                const inputSame = await handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Cost,
                  () => (e.target.value = priceDisplayValue),
                  (value) => {
                    e.target.value = "$" + CommaAdderToNumber(+value);
                  }
                );

                if (inputSame) {
                  e.target.value = priceDisplayValue;
                }
              }}
              // onChange={(e) => handleInputUpdateValueProvider(e, 'Price', stock)}
            />
          </td>
        );
      },

      "Type": (
        property: keyof InventoryDto,
        Type: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Type + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={Type + ""}>
              <Select
                styles={reactSelectStylesForTable()}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  typeTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) => {
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.InventoryType,
                    stock,
                    Type
                  );
                }}
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                // defaultValue={{
                //     label: Type + '',
                //     value: Type + '',
                //     title: Type + '',
                // }}

                value={{ label: Type + "", value: Type + "", title: Type + "" }}
              />
            </div>
          </td>
        );
      },
      "Sub Type": (
        property: keyof InventoryDto,
        SubType: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          SubType + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <select
              disabled={true}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              value={SubType}
              // onBlur={(e) => handleNewUpdaterFunction(e.target.value, property, stock, SubType)}
              // onChange={(e) => handleNewUpdaterFunction(e.target.value, property, stock, SubType)}
            >
              {Object.entries(InventorySubType)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option key={value} label={key} value={value}>
                    {key}
                  </option>
                ))}
            </select>
          </td>
        );
      },
      "Year": (
        property: keyof InventoryDto,
        Year: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Year + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                Year + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              // onChange={(e) => handleInputUpdateValueProvider(e, 'Year', stock)}
              defaultValue={Year}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Year,
                  () => (e.target.value = Year + "")
                );
              }}
            />
          </td>
        );
      },
      "Make": (
        property: keyof InventoryDto,
        Make: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Make + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={Make + ""}>
              <Select
                styles={reactSelectStylesForTable()}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  makeTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.Make,
                    stock,
                    Make
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: Make + "",
                  value: Make + "",
                  title: Make + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Model": (
        property: keyof InventoryDto,
        Model: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Model + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={Model + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  modelTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.Model,
                    stock,
                    Model
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: Model + "",
                  value: Model + "",
                  title: Model + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Condition": (
        property: keyof InventoryDto,
        Condition: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Condition + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <select
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={Condition}
              onChange={
                (e) =>
                  handleNewUpdaterFunction(
                    RecordType.Inventory,
                    e.target.value,
                    property,
                    stock,
                    Condition,
                    () => (e.target.value = Condition + "")
                  )
                // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
              }
            >
              {Object.entries(InventoryCondition)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option key={value} label={key} value={value}>
                    {key}
                  </option>
                ))}
            </select>
          </td>
        );
      },
      "Status": (
        property: keyof InventoryDto,
        Status: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Status + "",
          property
        );
        return (
          <td
            className={`${styles.tData} ${newTableStyles}`}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <select
              className={INPUT_CLASS_NAME}
              disabled={
                !canUserAccessAdminSecurityWithGivenRoles(roles) &&
                Status === InventoryStatus.Sold
              }
              name={`${stock}:${property}`}
              defaultValue={Status}
              onChange={
                (e) =>
                  handleNewUpdaterFunction(
                    RecordType.Inventory,
                    e.target.value,
                    property,
                    stock,
                    Status,
                    () => (e.target.value = Status + "")
                  )
                // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
              }
              // onChange={(e) => handleSelectOrTextAreaUpdateValueProvider(e, 'Status', stock, )}
            >
              {Object.entries(InventoryStatus)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option
                    key={value}
                    disabled={key === "Sold"}
                    label={key}
                    value={value}
                  >
                    {key}
                  </option>
                ))}
            </select>
          </td>
        );
      },
      "L": (
        property: keyof InventoryDto,
        L: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          L + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div
              className={`${styles.asyncSelectDiv} ${styles.measurement}`}
              title={L + ""}
            >
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  lengthTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.Length,
                    stock,
                    L
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: L + "",
                  value: L + "",
                  title: L + "",
                }}
              />
            </div>
          </td>
        );
      },
      "W": (
        property: keyof InventoryDto,
        W: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          W + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div
              className={`${styles.asyncSelectDiv} ${styles.measurement}`}
              title={W + ""}
            >
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={widthTypes}
                // loadOptions={
                //     async () => widthTypes
                //     // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                // }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.Width,
                    stock,
                    W
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: W + "",
                  value: W + "",
                  title: W + "",
                }}
              />
            </div>
          </td>
        );
      },
      "H": (
        property: keyof InventoryDto,
        H: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          H + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div
              className={`${styles.asyncSelectDiv} ${styles.measurement}`}
              title={H + ""}
            >
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  heightTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.Height,
                    stock,
                    H
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: H + "",
                  value: H + "",
                  title: H + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Wheel Sz": (
        property: keyof InventoryDto,
        WheelSz: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          WheelSz + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={WheelSz + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  wheelSizeTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.WheelSize,
                    stock,
                    WheelSz
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: WheelSz + "",
                  value: WheelSz + "",
                  title: WheelSz + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Wheel Type": (
        property: keyof InventoryDto,
        WheelType: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          WheelType + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={WheelType + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  wheelTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.WheelType,
                    stock,
                    WheelType
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                // filterOption={(option, search) => {
                //     // return true;
                //     return option.value.includes(search.toLowerCase()) || search === '';
                // }}
                value={{
                  label: WheelType + "",
                  value: WheelType + "",
                  title: WheelType + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Axles": (
        property: keyof InventoryDto,
        Axles: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Axles + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                Axles + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={Axles}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Axles,
                  () => (e.target.value = Axles + "")
                );
              }}
            />
          </td>
        );
      },
      "Suspension Type": (
        property: keyof InventoryDto,
        SuspensionType: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          SuspensionType + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={SuspensionType + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  suspensionTypeTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.SuspensionType,
                    stock,
                    SuspensionType
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: SuspensionType + "",
                  value: SuspensionType + "",
                  title: SuspensionType + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Suspension Style": (
        property: keyof InventoryDto,
        SuspensionStyle: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          SuspensionStyle + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={SuspensionStyle + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  suspensionStyleTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.SuspensionStyle,
                    stock,
                    SuspensionStyle
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: SuspensionStyle + "",
                  value: SuspensionStyle + "",
                  title: SuspensionStyle + "",
                }}
              />
            </div>
          </td>
        );
      },
      "KP": (
        property: keyof InventoryDto,
        KP: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          KP + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={SMALL_COLS_SIZE}
              rows={(KP + "").length < NORMAL_ROWS_SIZE ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              name={`${stock}:${property}`}
              defaultValue={KP}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  KP,
                  () => (e.target.value = KP + "")
                );
              }}
            />
          </td>
        );
      },
      "Unit Hours": (
        property: keyof InventoryDto,
        UnitHours: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          UnitHours + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                UnitHours + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={UnitHours}
              onBlur={(e) =>
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  UnitHours
                )
              }
            />
          </td>
        );
      },
      "Entered In CDK": (
        property: keyof InventoryDto,
        EnteredInCDK: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          EnteredInCDK + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(EnteredInCDK) ||
                              EnteredInCDK.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(EnteredInCDK)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  EnteredInCDK,
                  () => {
                    e.target.value = convertToInputDateFormat(EnteredInCDK);
                  }
                );

                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
            />
          </td>
        );
      },
      "Floor Type": (
        property: keyof InventoryDto,
        FloorType: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          FloorType + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={FloorType + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  floorTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.FloorType,
                    stock,
                    FloorType
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: FloorType + "",
                  value: FloorType + "",
                  title: FloorType + "",
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    width:
                      state.selectProps.menuPlacement === "bottom" &&
                      state.selectProps.menuPortalTarget
                        ? state.selectProps.menuPortalTarget.getBoundingClientRect()
                            .width + "px"
                        : "100%",
                  }),
                }}
              />
            </div>
          </td>
        );
      },
      "Rear Door Type": (
        property: keyof InventoryDto,
        RearDoorType: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          RearDoorType + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={RearDoorType + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  rearDoorTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.RearDoorType,
                    stock,
                    RearDoorType
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: RearDoorType + "",
                  value: RearDoorType + "",
                  title: RearDoorType + "",
                }}
              />
            </div>
          </td>
        );
      },

      "Inventory Title": (
        property: keyof InventoryDto,
        InventoryTitle: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          InventoryTitle + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                InventoryTitle + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={InventoryTitle}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  InventoryTitle,
                  () => (e.target.value = InventoryTitle + "")
                );
              }}
            />
          </td>
        );
      },
      "Temp Tag Expiration": (
        property: keyof InventoryDto,
        TempTagExpiration: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          TempTagExpiration + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(TempTagExpiration) ||
                              TempTagExpiration.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(TempTagExpiration)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  TempTagExpiration,
                  () => {
                    e.target.value =
                      convertToInputDateFormat(TempTagExpiration);
                  }
                );

                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
            />
          </td>
        );
      },
      "Side Doors": (
        property: keyof InventoryDto,
        SideDoors: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          SideDoors + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                SideDoors + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={SideDoors}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  SideDoors,
                  () => (e.target.value = SideDoors + "")
                );
              }}
            />
          </td>
        );
      },
      "Photo Status": (
        property: keyof InventoryDto,
        PhotoStatus: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          PhotoStatus + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <select
              style={{ width: "6rem" }}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={PhotoStatus}
              onChange={
                (e) =>
                  handleNewUpdaterFunction(
                    RecordType.Inventory,
                    e.target.value,
                    property,
                    stock,
                    PhotoStatus,
                    () => (e.target.value = PhotoStatus + "")
                  )
                // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
              }
            >
              {Object.entries(PhotoStatusEnum)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <option key={value} label={key} value={value}>
                    {key}
                  </option>
                ))}
            </select>
          </td>
        );
      },
      "Ordered Date": (
        property: keyof InventoryDto,
        OrderedDate: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          OrderedDate + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME}
                            ${
                              isNullOrUndefined(OrderedDate) ||
                              OrderedDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(OrderedDate)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  OrderedDate,
                  () => {
                    e.target.value = convertToInputDateFormat(OrderedDate);
                  }
                );

                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
            />
          </td>
        );
      },
      "Reefer Engine Year": (
        property: keyof InventoryDto,
        ReeferEngineYear: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReeferEngineYear + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                ReeferEngineYear + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={ReeferEngineYear}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  ReeferEngineYear,
                  () => (e.target.value = ReeferEngineYear + "")
                );
              }}
            />
          </td>
        );
      },
      "Reefer Make": (
        property: keyof InventoryDto,
        ReeferMake: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReeferMake + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={ReeferMake + ""}>
              <Select
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  reeferMakeTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.ReeferMake,
                    stock,
                    ReeferMake
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: ReeferMake + "",
                  value: ReeferMake + "",
                  title: ReeferMake + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Reefer Model": (
        property: keyof InventoryDto,
        ReeferModel: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReeferModel + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {/* NEEDS SYNC SELECT */}
            <div className={styles.asyncSelectDiv} title={ReeferModel + ""}>
              <Select
                styles={reactSelectStylesForTable()}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                options={
                  reeferModelTypes
                  // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
                }
                getOptionLabel={(option) => (option as testtest).label}
                getOptionValue={(option) => (option as testtest).value}
                onChange={(e) =>
                  handleUpdateType(
                    e?.value,
                    property,
                    DbManagedTypes.ReeferModel,
                    stock,
                    ReeferModel
                  )
                }
                filterOption={(option, inputValue) => {
                  const { value } = option;

                  const result = value
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                  return result;
                  // return true;
                }}
                value={{
                  label: ReeferModel + "",
                  value: ReeferModel + "",
                  title: ReeferModel + "",
                }}
              />
            </div>
          </td>
        );
      },
      "Reefer Desc": (
        property: keyof InventoryDto,
        ReeferDesc: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReeferDesc + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={NORMAL_COLS_SIZE}
              rows={(ReeferDesc + "").length < NORMAL_COLS_SIZE ? 1 : 2}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              name={`${stock}:${property}`}
              defaultValue={ReeferDesc}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  ReeferDesc,
                  () => (e.target.value = ReeferDesc + "")
                );
              }}
            />
          </td>
        );
      },
      "Reefer Serial Number": (
        property: keyof InventoryDto,
        ReeferSerialNumber: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReeferSerialNumber + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                ReeferSerialNumber + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={ReeferSerialNumber}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  ReeferSerialNumber,
                  () => (e.target.value = ReeferSerialNumber + "")
                );
              }}
            />
          </td>
        );
      },
      "Reefer Year": (
        property: keyof InventoryDto,
        ReeferYear: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          ReeferYear + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                ReeferYear + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              name={`${stock}:${property}`}
              defaultValue={ReeferYear}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  ReeferYear,
                  () => (e.target.value = ReeferYear + "")
                );
              }}
            />
          </td>
        );
      },
      "Notes": (
        property: keyof InventoryDto,
        Notes: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          Notes + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <textarea
              cols={NORMAL_COLS_SIZE}
              rows={(Notes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
              // style={{ ...generateInputStyles(Notes + ''), ...{ maxHeight: '2px' } }}
              className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
              onChange={(e) =>
                handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
              }
              name={`${stock}:${property}`}
              defaultValue={Notes}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  Notes,
                  () => (e.target.value = Notes + "")
                );
              }}
            />
          </td>
        );
      },

      "Has Photos": (
        property: keyof InventoryDto,
        HasPhotos: string | boolean,
        stock: string
      ) => {
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {(() => {
              if (isNullOrUndefined(HasPhotos)) {
                // If it's null, show something like "Unknown"
                const unknownText = "Unknown";
                return (
                  <input
                    style={generateInputStylesAndUpdateBiggestInputWidth(
                      unknownText + "",
                      property
                    )}
                    className={INPUT_CLASS_NAME}
                    name={`${stock}:${property}`}
                    type="text"
                    // onChange={(e) => handleInputUpdateValueProvider(e, 'VIN', stock)}
                    value={unknownText}
                  />
                );
              }
              return (
                <input
                  className={`${INPUT_CLASS_NAME} ${styles.testAllDelivered}`}
                  name={`${stock}:${property}`}
                  readOnly={true}
                  // disabled={true}
                  type="checkbox"
                  checked={convertDefaultCheckbox(HasPhotos)}
                />
              );
            })()}
          </td>
        );
      },

      "Split Deal": (
        property: keyof InventoryDto,
        SplitDeal: string | boolean,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          SplitDeal + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            {(() => {
              const hasAccess = canUserAccessAdminSecurityWithGivenRoles(roles);
              return (
                <input
                  className={`${INPUT_CLASS_NAME} ${
                    hasAccess ? "" : styles.testAllDelivered
                  }`}
                  name={`${stock}:${property}`}
                  disabled={!hasAccess}
                  type="checkbox"
                  checked={convertDefaultCheckbox(SplitDeal)}
                  onChange={(e) =>
                    handleNewUpdaterFunction(
                      RecordType.Inventory,
                      !cTB(SplitDeal),
                      property,
                      stock,
                      cTB(SplitDeal)
                    )
                  }
                />
              );
            })()}
          </td>
        );
      },

      "Invoice Number": (
        property: keyof InventoryDto,
        InvoiceNumber: string | number,
        stock: string
      ) => {
        return (
          <td
            className={`${styles.tData} ${styles.stockTdContainer}`}
            key={`${stock}-${property}`}
            onMouseUp={(e) => {
              if (
                InvoiceNumber + "".trim() === "" ||
                isNullOrUndefined(InvoiceNumber)
              ) {
                return;
              }

              if (e.button === 1) {
                if ((e.target as Element).tagName === "A") {
                  // should be handled by the anchor tag itself
                  return;
                }
                e.preventDefault();
                const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

                window.open(link, "_blank");
                e.stopPropagation();
                return;
              }
            }}
            onClick={(e) => {
              if (
                InvoiceNumber + "".trim() === "" ||
                isNullOrUndefined(InvoiceNumber)
              ) {
                return;
              }
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }
              const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

              if (e.ctrlKey) {
                window.open(link, "_blank");
                e.stopPropagation();
                return;
              }

              if (e.shiftKey) {
                window.open(link);
                e.stopPropagation();
                return;
              }
              // Will implement the state and bringing back to search results later..
              history.push({
                pathname: `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`,
                state: {
                  selectedGlobalFilterNames,
                  selectedUserFilterNames,
                  globalFiltersStorage,
                  userFiltersStorage,
                  customFilters,
                  ignoredFilterIds,
                } as LocationType,
              });
            }}
          >
            {InvoiceNumber + "".trim() === "" ||
            isNullOrUndefined(InvoiceNumber) ? (
              <></>
            ) : (
              <NavLink
                // onClick={() => {
                // history.replace(location.pathname, { test: 'hello' });
                // }}
                to={{
                  pathname: `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`,
                  // Will implement the state and bringing back to search results later..

                  state: {
                    selectedGlobalFilterNames,
                    selectedUserFilterNames,
                    globalFiltersStorage,
                    userFiltersStorage,
                    customFilters,
                    ignoredFilterIds,
                  } as LocationType,
                }}
              >
                {InvoiceNumber}
              </NavLink>
            )}
          </td>
        );
      },
      "Yard Stall": (
        property: keyof InventoryDto,
        YardStall: string | number,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          YardStall + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              style={generateInputStylesAndUpdateBiggestInputWidth(
                YardStall + "",
                property
              )}
              className={INPUT_CLASS_NAME}
              readOnly={true}
              name={`${stock}:${property}`}
              defaultValue={YardStall}
            />
          </td>
        );
      },
      "Misc Date": (
        property: keyof InventoryDto,
        MiscDate: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          MiscDate + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(MiscDate) ||
                              MiscDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(MiscDate)}
              onFocus={(e) => (e.target.style.opacity = "1")}
              onBlur={(e) => {
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  MiscDate,
                  () => {
                    e.target.value = convertToInputDateFormat(MiscDate);
                  }
                );

                if (
                  e.target.value.trim() === "" ||
                  isNullOrUndefined(e.target.value)
                ) {
                  e.target.style.opacity = "0";
                }
              }}
            />
          </td>
        );
      },
      "Delivered Date": (
        property: keyof InventoryDto,
        DeliveredDate: string,
        stock: string
      ) => {
        const sytleObject = generateInputStylesAndUpdateBiggestInputWidth(
          DeliveredDate + "",
          property
        );
        return (
          <td
            className={styles.tData}
            key={`${stock}-${property}`}
            onClick={(e) => {
              const tableDataEl = e.target as HTMLTableCellElement;
              if (tableDataEl.tagName !== "TD") {
                return;
              }

              const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

              inputBelow.focus();
            }}
          >
            <input
              readOnly={true}
              disabled={true}
              className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(DeliveredDate) ||
                              DeliveredDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
              style={{ pointerEvents: "none" }}
              name={`${stock}:${property}`}
              type="date"
              defaultValue={convertToInputDateFormat(DeliveredDate)}
              onBlur={(e) =>
                handleNewUpdaterFunction(
                  RecordType.Inventory,
                  e.target.value,
                  property,
                  stock,
                  DeliveredDate,
                  () => {
                    e.target.value = convertToInputDateFormat(DeliveredDate);
                  }
                )
              }
            />
          </td>
        );
      },
    }),
    [
      cityLocationTypes,
      customFilters,
      floorTypes,
      globalFiltersStorage,
      handleNewUpdaterFunction,
      handleUpdateType,
      heightTypes,
      history,
      ignoredFilterIds,
      lengthTypes,
      makeTypes,
      modelTypes,
      rearDoorTypes,
      reeferMakeTypes,
      reeferModelTypes,
      roles,
      selectedGlobalFilterNames,
      selectedUserFilterNames,
      suspensionStyleTypes,
      suspensionTypeTypes,
      typeTypes,
      userFiltersStorage,
      wheelSizeTypes,
      wheelTypes,
      widthTypes,
    ]
  );

  const runner = useCallback(
    (dtoData: InventoryDto): React.ReactElement[] => {
      if (isNullOrUndefined(computedCombinedFilters)) {
        return [<></>];
      }

      const updatedValues = computedCombinedFilters.orderedColumnsToShow
        .filter((column) => isNotNullNorUndefined(column) && column !== "")
        .map((column) => {
          let value = dtoData[column as keyof InventoryDto];

          if (isNullOrUndefined(value)) {
            value = "";
          }
          const target =
            savedFunctionsToUpdateData[
              column as keyof typeof savedFunctionsToUpdateData
            ];

          if (typeof target !== "function" || isNullOrUndefined(target)) {
            console.error(
              `Attempting to access: ${column} in Dto but doesn't exist!`
            );
            throw new Error(
              `Attempting to access: ${column} in Dto but doesn't exist!`
            );
          }

          if (typeof value === "boolean") {
            // if boolean value converting to string
            value = value + "";
          }

          const result = target(
            column as keyof InventoryDto,
            value as string,
            dtoData.Stock
          );
          return result;
        })
        .filter((column) => isNotNullNorUndefined(column));

      const withSelectionColumn = [
        <td
          className={`${
            selectingRowMode === "unselected"
              ? styles.selectionColumnHidden
              : ""
          } ${styles.tdSelectionColumn}`}
          key="selection-column"
          onClick={(e) => {
            if ((e.target as Element).tagName !== "TD") {
              return;
            }
            const tdElement = e.target as HTMLTableCellElement;

            const inputBelow = tdElement.childNodes[0] as HTMLInputElement;
            if (isRowIdSelected(dtoData.Stock)) {
              unselectRow(dtoData.Stock);
            } else {
              registerSelection(dtoData.Stock);
            }
            inputBelow.focus();
          }}
        >
          <input
            type="checkbox"
            style={{ transform: "scale(1.5)" }}
            checked={isRowIdSelected(dtoData.Stock)}
            onChange={(e) => {
              if (e.target.checked) {
                registerSelection(dtoData.Stock);
              } else {
                unselectRow(dtoData.Stock);
              }
            }}
          />
        </td>,
        ...updatedValues,
      ];

      return withSelectionColumn as JSX.Element[];
    },
    [
      computedCombinedFilters,
      isRowIdSelected,
      selectingRowMode,
      registerSelection,
      savedFunctionsToUpdateData,
      unselectRow,
    ]
  );

  const handleGenerateTableParts = useCallback(() => {
    let tableHead: React.ReactElement | null = null;

    const [
      rowsStartIndex,
      rowsEndIndex,
      rowHeight,
      beforeDivHeight,
      afterDivHeight,
    ] = calculateVirtualizedRows();
    const [columnsStartIndex, columnsEndIndex] = calculateVirtualizedColumns();

    // const visibleData = data.splice(startIndex, endIndex); Don't need this. Can compare indexs in the map method

    const beforeDiv = (
      <tr key={"before-div"}>
        <td>
          <div style={{ height: beforeDivHeight }}></div>
        </td>
      </tr>
    );
    const afterDiv = (
      <tr key={"after-div"}>
        <td>
          <div style={{ height: afterDivHeight }}></div>
        </td>
      </tr>
    );

    const rows = (data as InventoryDto[]).map((dto, index) => {
      // Doing it this way to limit the amount of iteration done
      let headers: React.ReactElement[] | null = null;
      if (index === 0) {
        headers = generateTableHeaders(dto);
        tableHead = (
          <thead className={styles.tHead}>
            <tr>{headers}</tr>
          </thead>
        );
      }

      if (index < rowsStartIndex || index >= rowsEndIndex) {
        return null;
      }

      // if (index < rowsStartIndex || index >= rowsEndIndex) {
      //     // Shouldn't be visible
      //     console.log("Handling something that shouldn't be visible!");
      //     return (
      //         <tr key={dto.Stock}>
      //             <div style={{ height: rowHeight }}></div>
      //         </tr>
      //     );
      // }

      const toFill = runner(dto);

      return (
        <tr
          style={{ height: rowHeight }}
          key={dto.Stock}
          className={`${
            index % 2 === 0 ? styles.highlighted : styles.notHighlighted
          } ${styles.tRow}`}
        >
          {toFill}
          {/* <InventoryTableRow
                        dtoData={dto}
                        orderedColumnsToShow={
                            savedGeneratedGeneralOptions.current?.columnsToShow as (keyof InventoryDto)[] | undefined
                        }
                    /> */}
        </tr>
      );
    });
    const filteredRows = rows.filter((element) =>
      isNotNullNorUndefined(element)
    );

    return (
      <>
        {tableHead}
        <tbody ref={tBodyRef}>{[beforeDiv, filteredRows, afterDiv]}</tbody>
      </>
    );
  }, [
    calculateVirtualizedColumns,
    calculateVirtualizedRows,
    data,
    generateTableHeaders,
    runner,
  ]);

  // const test = (dataDto: InventoryDto) => {
  //     if (isNullOrUndefined(savedGeneratedGeneralOptions.current)) {
  //         return;
  //     }
  //     const columnsToShowList = splitByComma(savedGeneratedGeneralOptions.current.columnsToShow);

  //     const tableDataList = columnsToShowList.map((column) => {
  //         return return (
  {
    /* <td key={column}>{dataDto[column as keyof InventoryDto]}</td>) */
  }
  //     });

  //     return tableDataList;
  // };

  // const iteration = () => {
  //     if (isNullOrUndefined(savedGeneratedGeneralOptions.current)) {
  //         return;
  //     }

  //     for (let i = 0; i < savedGeneratedGeneralOptions.current.sortOptions.itemsPerPage ?? 50; i++) {
  //         const inventoryDto = data[i];
  //         test(inventoryDto);
  //     }
  // };

  useEffect(() => {
    const ionElement = document.getElementById(
      ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID
    );

    if (isNullOrUndefined(ionElement)) {
      console.error("ION ELEENT IS NULL RETURNING");
      return;
    }

    const handler = function () {
      const nonTableParts = Array.from(
        document.getElementsByClassName("nonTableParts")
      );
      if (isNullOrUndefined(nonTableParts) || !Array.isArray(nonTableParts)) {
        console.error("Non table part IS NULL! RETURNING");
        return;
      }
      nonTableParts.forEach((part) => {
        (part as HTMLDivElement).style.left = ionElement.scrollLeft + "px";
      });
    };

    ionElement.addEventListener("scroll", () => {
      window.requestAnimationFrame(handler);
    });
  }, []);

  // Don't need this as this is handled in StateManager

  // useEffect(() => {
  //     const handleInitialGeneralOptions = async () => {
  //         if (validLocationState) {
  //             handleEndpointCall({
  //                 endpoint: endpoint,
  //                 generalOptions: generalOptions,
  //             });
  //             return; // Leaving updating general options to
  //         }
  //         // calls the endpoint initially

  //         const filter = getUnfilteredGeneralOptions(FilterType.Global, 'Main');

  //         // Assign the correct function to endpointToCall
  //         const endpointToCall = getCorrespondingQueriedTableMethod(recordType);

  //         const {
  //             data: {
  //                 model: { options, model },
  //                 isSuccessful,
  //             },
  //         } = await endpointToCall(filter);

  //         if (!isSuccessful) {
  //             console.log('Unssuccesful retreive of global filter. Returning.');
  //             return;
  //         }

  //         handleEndpointCall({
  //             endpoint: endpoint,
  //             generalOptions: { ...options, filterType: FilterType.Custom, filterName: '' },
  //         });
  //     };

  //     handleInitialGeneralOptions();
  // }, []);

  const exportEnumProcessor = (inventoryDto: InventoryDto) => {
    const updatedObj: any = { ...inventoryDto };

    EnumInventoryProperties.forEach((property) => {
      const targetEnum = getEnumFromInventoryDtoPropertyName(property);
      if (isNullOrUndefined(targetEnum)) {
        console.error(
          "Target enum is undfined/null when trying to process enum values!"
        );
        return;
      }

      const value = inventoryDto[property];

      updatedObj[property] = targetEnum[value as number];
    });

    return updatedObj;
  };

  const convertLongDateToShortDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const printProcessor = (inventoryDto: InventoryDto) => {
    const updatedObj: any = { ...inventoryDto };

    Object.entries(inventoryDto).forEach(([property, value]) => {
      const dataType = getDataTypeFromInventoryDtoPropertyName(
        property as keyof InventoryDto
      );

      switch (dataType) {
        case DataType.DateTime: {
          updatedObj[property as keyof InventoryDto] =
            convertToInputDateFormat(value);
          break;
        }
        case DataType.Enum: {
          const targetEnum = getEnumFromInventoryDtoPropertyName(
            property as keyof InventoryDto
          );
          if (isNullOrUndefined(targetEnum)) {
            console.error(
              "Target Enum was not found. Please add definition in getEnumFromInventoryDtoPropertyName"
            );
            return;
          }

          updatedObj[property as keyof InventoryDto] = targetEnum[value];
        }
      }
    });
    return updatedObj as InventoryDto;
  };

  return (
    <>
      <div
        className={styles.InventoryTableContainer}
        data-testid="InventoryTable"
      >
        <Layout className={`${styles.nonTablePart} nonTableParts`}>
          <div
            className={`${styles.nonTablePart} nonTableParts`}
            // id="non-table-part-of-page"
          >
            {/*               {!process.env.NODE_ENV ||
              (process.env.NODE_ENV === 'development' && (
                <button onClick={handleTransfer}>transfer</button>
              ))}

              */}

            <QueriedTableGlobalUserFilterList
              userFiltersStorage={userFiltersStorage}
              globalFiltersStorage={globalFiltersStorage}
              handleGlobalFilterButtonClick={handleGlobalFilterButtonClick}
              isFilterSelected={isFilterSelected}
              computedCombinedFilters={computedCombinedFilters}
              handleUserFilterButtonClick={handleUserFilterButtonClick}
            />

            <QueriedTableToolbar<InventoryDto>
              endpoint={endpoint}
              handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
              handleUserFilterButtonClick={handleUserFilterButtonClick}
              model={model}
              useCustomEndpoint={useCustomEndpoint}
              setUseCustomEndpoint={setUseCustomEndpoint}
              recordType={recordType}
              isFilterSelected={isFilterSelected}
              handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
              handleDeleteGlobalFilter={handleDeleteGlobalFilter}
              handleDeleteUserFilter={handleDeleteUserFilter}
              setOverwritingProvidedData={setOverwritingProvidedData}
              dtoType={dtoType}
              handleClear={handleClearCancel}
              data={data as InventoryDto[]}
              computedCombinedFilters={computedCombinedFilters}
              handleUpdatingItemsPerPage={handleUpdatingItemsPerPage}
              setSortFilters={setSortFilters}
              handleSetCustomFiltersValueFilters={
                handleSetCustomFiltersValueFilters
              }
              unhidableColumns={["Stock"] as (keyof InventoryDto)[]}
              setColumnVisibility={setColumnVisibility}
              fieldsThatAreCustomTypes={{
                "Wheel Type": wheelTypes,
                "Rear Door Type": rearDoorTypes,
                "Suspension Type": suspensionTypeTypes,
                "Suspension Style": suspensionStyleTypes,
                "Floor Type": floorTypes,
                "Type": typeTypes,
                "L": lengthTypes,
                "W": widthTypes,
                "H": heightTypes,
                "Make": makeTypes,
                "Model": modelTypes,
                "Wheel Sz": wheelSizeTypes,
                "Reefer Make": reeferMakeTypes,
                "Reefer Model": reeferModelTypes,
                "City Location": cityLocationTypes,
              }}
              exportEnumProcessor={exportEnumProcessor}
              enumProperties={EnumInventoryProperties}
              printProcessor={printProcessor}
              getAsyncSelectGetMethodFromPropertyName={() => undefined}
              filterKeyArrayByExcludedColumns={
                filterInventoryKeyArrayByExcludedColumns
              }
              selectedUserFilterNames={selectedUserFilterNames}
              selectedGlobalFilterNames={selectedGlobalFilterNames}
              computedSelectedGlobalFilters={computedSelectedGlobalFilters}
              computedSelectedUserFilters={computedSelectedUserFilters}
              customFilters={customFilters}
              unselectGlobalFilter={unselectGlobalFilter}
              unselectUserFilter={unselectUserFilter}
              ignoreFilter={ignoreFilter}
              unignoreFilter={unignoreFilter}
              isFilterIgnored={isFilterIgnored}
              combinedFilterTargetProperties={combinedFilterTargetProperties}
              updateSearchTerm={updateSearchTerm}
              globalFilterStorage={globalFiltersStorage}
              userFilterStorage={userFiltersStorage}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </div>

          <div
            className={`${styles.itemsPerPageAndCopyTableContainer} ${styles.nonTablePart} nonTableParts`}
          >
            <div className={`${styles.totalItemsSpan} `}>
              <span>
                Total Items: {computedCombinedFilters.sortOptions.totalItems}
              </span>
            </div>
            <CopyTableStylesButtons<InventoryDto>
              selectingRowMode={selectingRowMode}
              unselectAllRows={unselectAllRows}
              selectAllRows={selectAllRows}
              cancelSelecting={cancelSelecting}
              stopSelectingAndCopyTableWithStyles={
                stopSelectingAndCopyTableWithStyles
              }
              stopSelectingAndEmailAdvertisements={
                stopSelectingAndEmailAdvertisements
              }
              startSelecting={startSelecting}
              data={data as InventoryDto[]}
              identifierProperty={"Stock"}
              inventoryPage={true}
            />
          </div>

          <div ref={tableDivContainerRef} style={{ marginTop: "1rem" }}>
            <table className={styles.table}>{handleGenerateTableParts()}</table>
          </div>

          <div
            className={`${styles.nonTablePart} nonTableParts`}
            style={{ marginBottom: "1rem" }}
          >
            <QueriedTablePaginationOptions
              computedCombinedFilters={computedCombinedFilters}
              updateCurrentPageNumber={updateCurrentPageNumber}
              setIsLoading={setIsLoading}
            />
          </div>

          {/*
//                    <Card
//                        endpoint={() => MetricService.getMetrics(RecordType.Inventory)}
//                        icon={CardIconTypes.Square}
//                        hideOnTabletSize={true}
//                    />
          //
          //                    
       */}
        </Layout>
      </div>
    </>
  );
};

export default InventoryTable;
