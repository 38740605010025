export class YardMapDto {
    Id = '';

    'Yard Stall' = '';

    'Stock #' = '';

    'Service Notes' = '';

    VIN = '';

    'Inventory Number' = '';

    Customer = '';

    removePrevious = false;

    constructor(obj?: YardMapDto) {
        this.Id = obj?.Id || '';
        this['Yard Stall'] = obj?.['Yard Stall'] || '';
        this['Stock #'] = obj?.['Stock #'] || '';
        this['Service Notes'] = obj?.['Service Notes'] || '';
        this.VIN = obj?.VIN || '';
        this['Inventory Number'] = obj?.['Inventory Number'] || '';
        this.Customer = obj?.Customer || '';
        // this['Last Updated'] = obj?.['Last Updated'] || '';
        this['removePrevious'] = obj?.['removePrevious'] || false;
    }
}

export const AllYardMapKeysArray = ['Yard Stall', 'Stock #', 'Service Notes', 'VIN', 'Inventory Number', 'Customer'];
