import { useEffect, useMemo, useRef } from 'react';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { convertToInputDateFormat } from '../../utils/DateUtils';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import {
    SpecializedTableStandardHeaderStyle,
    SpecializedTableStandardIdentifierColumnStyle,
    SpecializedTableStandardTableDataStyle,
    SpecializedTableStandardTableTagStyle,
    SpecializedTableStandardTextWrapper,
} from '../../utils/SpecializedTableStandardStylesAndElements';

// Idea go through each value of the column and determine the biggest width. and then perhaps
// set the inputs to width 100% unless they match the biggest width. I'm not sure.

const savedFunctionsToUpdateData = {
    'Invoice Number': (
        property: keyof SalesOrderDto,
        InvoiceNumber: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td
            style={{
                ...SpecializedTableStandardTableDataStyle(index),
                ...SpecializedTableStandardIdentifierColumnStyle,
            }}
            key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{InvoiceNumber}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Ordered Date': (property: keyof SalesOrderDto, OrderedDate: string, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {convertToInputDateFormat(OrderedDate)}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Docs Signed': (property: keyof SalesOrderDto, DocsSigned: string, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {convertToInputDateFormat(DocsSigned)}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Check Number': (
        property: keyof SalesOrderDto,
        CheckNumber: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{CheckNumber}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    Deposit: (property: keyof SalesOrderDto, Deposit: string | number, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{Deposit}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    Status: (property: keyof SalesOrderDto, Status: string | number, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{Status}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Stock Numbers': (property: keyof SalesOrderDto, StockNumbers: string, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{StockNumbers}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Sales Person': (
        property: keyof SalesOrderDto,
        SalesPerson: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{SalesPerson}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Customer Name': (
        property: keyof SalesOrderDto,
        StartingCustomerName: string | number,
        invoiceNumber: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
                <SpecializedTableStandardTextWrapper>{StartingCustomerName}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Customer #': (
        property: keyof SalesOrderDto,
        CustomerNumber: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{invoiceNumber}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Title Work Completed': (
        property: keyof SalesOrderDto,
        TitleWorkCompleted: string,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {convertToInputDateFormat(TitleWorkCompleted)}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),
    NAPP: (property: keyof SalesOrderDto, NAPP: string | boolean, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {NAPP === true ? 'True' : 'False'}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),
    Lienholder: (property: keyof SalesOrderDto, Lienholder: string | number, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{Lienholder}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    Notes: (property: keyof SalesOrderDto, Notes: string | number, invoiceNumber: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{Notes}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Misc Tracking': (
        property: keyof SalesOrderDto,
        MiscTracking: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{MiscTracking}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Proof Of Payment Received': (
        property: keyof SalesOrderDto,
        ProofOfPaymentReceived: string,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {convertToInputDateFormat(ProofOfPaymentReceived)}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Back Office Notes': (
        property: keyof SalesOrderDto,
        BackOfficeNotes: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{BackOfficeNotes}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Split Deal': (
        property: keyof SalesOrderDto,
        SplitDeal: string | boolean,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {SplitDeal === true ? 'True' : 'False'}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),
    'All Delivered': (
        property: keyof SalesOrderDto,
        AllDelivered: string | boolean,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>
                {AllDelivered === true ? 'True' : 'False'}
            </SpecializedTableStandardTextWrapper>
        </td>
    ),

    'Split Sales Person': (
        property: keyof SalesOrderDto,
        SplitSalesPerson: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{SplitSalesPerson}</SpecializedTableStandardTextWrapper>
        </td>
    ),
    'Delivered Count': (
        property: keyof SalesOrderDto,
        DeliveredCount: string | number,
        invoiceNumber: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${invoiceNumber}-${property}`}>
            <SpecializedTableStandardTextWrapper>{DeliveredCount}</SpecializedTableStandardTextWrapper>
        </td>
    ),
};

interface SalesOrderSpecalizedTableRenderProps {
    data: SalesOrderDto[];
    selectedRecords: string[];
    invisibleContainerDiv: HTMLDivElement;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
}

const SalesOrderSpecalizedTableRender = ({
    data,
    selectedRecords,
    invisibleContainerDiv,
    computedCombinedFilters,
}: SalesOrderSpecalizedTableRenderProps) => {
    const filteredData = useMemo(() => {
        return data.filter((dto) => selectedRecords.includes(dto['Invoice Number']));
    }, [data, selectedRecords]);

    const tableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        const handleCopy = async () => {
            if (isNullOrUndefined(tableRef.current)) {
                makeSimpleToast('Error while copying styles', 'Table div is undefined or null');
                console.error('Error while copying styles, table div is undefined or null');
                return;
            }

            try {
                // Copy the selected content to the clipboard using Clipboard API
                navigator.clipboard
                    .write([
                        new ClipboardItem({
                            'text/html': new Blob([tableRef.current.outerHTML], { type: 'text/html' }),
                        }),
                    ])

                    .then(() => {
                        console.log('Table copied to clipboard successfully!');

                        makeSimpleToast(
                            'Successfully copied specialized table!',
                            "You've copied the specialized version of the table for email."
                        );
                    })
                    .catch((err) => {
                        console.error('Unable to copy table to clipboard:', err);
                        makeSimpleToast(
                            'Error while trying to copy styles.',
                            'Page not focused. Please try again and try to keep the page focused for at least a second.'
                        );
                    });
            } catch (error) {
                console.error('Clipboard API is not supported in this browser:', error);
            }

            invisibleContainerDiv.remove();
        };
        handleCopy();
    }, [invisibleContainerDiv]);

    return (
        <div data-testid="YardMapSpecializedTableRender">
            <table style={SpecializedTableStandardTableTagStyle} ref={tableRef}>
                <thead>
                    <tr>
                        {computedCombinedFilters.orderedColumnsToShow.map((column) => (
                            <th
                                style={{
                                    ...SpecializedTableStandardHeaderStyle,
                                    ...(column.toLowerCase() === 'invoice number'
                                        ? SpecializedTableStandardIdentifierColumnStyle
                                        : {}),
                                }}
                                key={column}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((dataDto, rowIndex) => {
                        return (
                            <tr key={dataDto['Invoice Number']}>
                                {computedCombinedFilters.orderedColumnsToShow
                                    .filter((columnName) => columnName.toLowerCase() !== 'deliveries')
                                    .map((columnName) => {
                                        return savedFunctionsToUpdateData[
                                            columnName as keyof typeof savedFunctionsToUpdateData
                                        ](
                                            columnName as keyof SalesOrderDto,
                                            // Might need to revisit
                                            dataDto[columnName as keyof SalesOrderDto] as any,
                                            dataDto['Invoice Number'],
                                            rowIndex
                                        );
                                    })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SalesOrderSpecalizedTableRender;
