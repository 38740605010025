import { isNullOrUndefined } from './NullOrUndefined';

export const convertAllNullToEmptyStringFromObjectListUtil = <T extends object>(data: T[]): T[] => {
    const updatedList: T[] = [];

    data.forEach((item) => {
        const updatedObject = convertAllNullToEmptyStringFromObject(item);
        updatedList.push(updatedObject as T);
    });

    return updatedList;
};

export const convertAllNullToEmptyStringFromObject = <T extends object>(object: T): T => {
    const updatedObject: Partial<T> = {};

    Object.entries(object).forEach(([key, value]) => {
        let valueToSet = value;

        if (isNullOrUndefined(value)) {
            valueToSet = '';
        }

        updatedObject[key as keyof T] = valueToSet;
    });

    return updatedObject as T;
};
