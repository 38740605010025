export class ImportSheetDto {
    Stock: string;
    Number: string;
    VIN: string;
    Spec: string;
    'Comp Date': string;
    'Inspection Date': string;
    'Received Date': string;
    'Advertise': string;
    'DOT Status': string;
    'Decal Id': string;
    'Yard Stall': string;
    'City Location': string;
    'Misc Date': string;
    Cost: string;
    Price: string;
    Type: string;
    Year: string;
    Make: string;
    Model: string;
    Color: string;
    Condition: string;
    Status: string;
    L: string;
    W: string;
    H: string;
    'Wheel Sz': string;
    'Wheel Type': string;
    Axles: string;
    'Suspension Type': string;
    'Suspension Style': string;
    KP: string;
    'Floor Type': string;
    'Rear Door Type': string;
    'Side Doors': string;
    'Reefer Serial Number': string;
    'Reefer Engine Year': string;
    'Reefer Make': string;
    'Reefer Model': string;
    'Unit Hours': string;
    'Reefer Year': string;
    'Reefer Desc': string;
    'Entered In CDK': string;

    'Inventory Title': string;
    'Temp Tag Expiration': string;
    'Ordered Date': string;
    Notes: string;
    'Photo Status': string;
    'Split Deal': string;
    'Check Number'?: string;

    constructor(obj: ImportSheetDto) {
        this.Stock = obj?.Stock ?? '';
        this.Number = obj?.Number ?? '';
        this.VIN = obj?.VIN ?? '';
        this.Spec = obj?.Spec ?? '';
        this['Comp Date'] = obj?.['Comp Date'] ?? '';
        this['Inspection Date'] = obj?.['Inspection Date'] ?? '';
        this['Received Date'] = obj?.['Received Date'] ?? '';
        this['Advertise'] = obj?.['Advertise'] ?? '';
        this['DOT Status'] = obj?.['DOT Status'] ?? '';
        this['Decal Id'] = obj?.['Decal Id'] ?? '';
        this['Yard Stall'] = obj?.['Yard Stall'] ?? '';
        this['City Location'] = obj?.['City Location'] ?? '';
        this['Misc Date'] = obj?.['Misc Date'] ?? '';
        this.Cost = obj?.Cost ?? '';
        this.Price = obj?.Price ?? '';
        this.Type = obj?.Type ?? '';
        this.Year = obj?.Year ?? '';
        this.Make = obj?.Make ?? '';
        this.Model = obj?.Model ?? '';
        this.Color = obj?.Color ?? '';
        this.Condition = obj?.Condition ?? '';
        this.Status = obj?.Status ?? '';
        this.L = obj?.L ?? '';
        this.W = obj?.W ?? '';
        this.H = obj?.H ?? '';
        this['Wheel Sz'] = obj?.['Wheel Sz'] ?? '';
        this['Wheel Type'] = obj?.['Wheel Type'] ?? '';
        this.Axles = obj?.Axles ?? '';
        this['Suspension Type'] = obj?.['Suspension Type'] ?? '';
        this['Suspension Style'] = obj?.['Suspension Style'] ?? '';
        this.KP = obj?.KP ?? '';
        this['Floor Type'] = obj?.['Floor Type'] ?? '';
        this['Rear Door Type'] = obj?.['Rear Door Type'] ?? '';
        this['Side Doors'] = obj?.['Side Doors'] ?? '';
        this['Reefer Serial Number'] = obj?.['Reefer Serial Number'] ?? '';
        this['Reefer Engine Year'] = obj?.['Reefer Engine Year'] ?? '';
        this['Reefer Make'] = obj?.['Reefer Make'] ?? '';
        this['Reefer Model'] = obj?.['Reefer Model'] ?? '';
        this['Unit Hours'] = obj?.['Unit Hours'] ?? '';
        this['Reefer Year'] = obj?.['Reefer Year'] ?? '';
        this['Reefer Desc'] = obj?.['Reefer Desc'] ?? '';
        this['Entered In CDK'] = obj?.['Entered In CDK'] ?? '';
        this['Inventory Title'] = obj?.['Inventory Title'] ?? '';
        this['Temp Tag Expiration'] = obj?.['Temp Tag Expiration'] ?? '';
        this['Ordered Date'] = obj?.['Ordered Date'] ?? '';
        this.Notes = obj?.Notes ?? '';
        this['Photo Status'] = obj?.['Photo Status'] ?? '';
        this['Split Deal'] = obj?.['Split Deal'] ?? '';
        this['Check Number'] = obj['Check Number'] ?? '';
    }
}
