import {
    IonAccordion,
    IonButton,
    IonCol,
    IonGrid,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import CollapsibleSection from '../../../components/CollapsibleSection/CollapsibleSection';
import GeneralToolTip from '../../../components/GeneralToolTip/GeneralToolTip';
import ImportReportModal from '../../../components/Import/ImportReport';
import {
    ComparisonOperatorInfo,
    getAllComparisonOperatorInfosThatIncludeDataTypes,
    ParameterizedCategory,
} from '../../../models/ComparisonOperator';
import { ImportHistoryDto, ImportOverview } from '../../../models/Dtos/importDto';
import { InventoryDto } from '../../../models/Dtos/InventoryDtos/InventoryDto';
import { DynamicFilter } from '../../../models/Dtos/OptionsDtos/DynamicFilter';
import { GeneralOptions } from '../../../models/Dtos/OptionsDtos/GeneralOptions';
import { DataType, FilterType } from '../../../models/Global';
import { InventoryService } from '../../../utils/apiServices';
import { minMax } from '../../../utils/MinMaxUtil';

export const ImportHistoryManager = () => {
    const [importHistory, setImportHistory] = useState<ImportHistoryDto[]>([]);
    const [historyOptions, setHistoryOptions] = useState<GeneralOptions>(new GeneralOptions());
    const [showImportReportModal, setShowImportReportModal] = useState<boolean>(false);
    const [importDetail, setImportDetail] = useState<ImportOverview | undefined>(undefined);

    // ********** TO DO List **********
    // Verify Working
    // Check Styling
    // Do Stuff for when model is not successful
    // Filtering using General Options - can be simplified

    /* State for saving date filter information */
    const [dateValue, setDateValue] = useState<string>();
    const [dateComparisonOperator, setDateComparisonOpeartor] = useState<ComparisonOperatorInfo>();

    useEffect(() => {
        const getEmployeeNames = async () => {
            // TO have response from employee name stuff

            setEmployeeNames(['TEST', 'TEST2']);
        };

        getEmployeeNames();
    }, []);

    /* State for employee names */
    const [employeeNames, setEmployeeNames] = useState<string[]>([]);
    const [selectedEmployeeName, setSelectedEmployeeName] = useState<string>();

    const getImport = () => {
        InventoryService.GetHistory(historyOptions)
            .then(({ data }) => {
                if (data.isSuccessful) {
                    setImportHistory(data.model);
                } else {
                    // Do Stuff
                }
            })
            .catch((err) => console.error(err));
    };

    const getImportDetails = (id: number) => {
        InventoryService.GetImportReport(id)
            .then(({ data }) => {
                if (data.isSuccessful && data.model != undefined && data.model != null) {
                    setImportDetail(data.model);
                    setShowImportReportModal(true);
                } else {
                    // Do Stuff
                }
            })
            .catch((err) => console.error(err));
    };

    const reverseImport = (id: number) => {
        InventoryService.ReverseImport(id)
            .then(({ data }) => {
                if (data.isSuccessful) {
                    // Do Stuff
                } else {
                    // Do Stuff
                }
            })
            .catch((err) => console.error(err));
    };

    const [buttonNumbers, setButtonNumbers] = useState<number[]>([1, 2, 3, 4, 5]);

    useEffect(() => {
        getImport();

        if (historyOptions.sortOptions.pageNumber <= 2) {
            setButtonNumbers([1, 2, 3, 4, 5]);
        } else {
            setButtonNumbers([
                historyOptions.sortOptions.pageNumber - 2,
                historyOptions.sortOptions.pageNumber - 1,
                historyOptions.sortOptions.pageNumber,
                historyOptions.sortOptions.pageNumber + 1,
                historyOptions.sortOptions.pageNumber + 2,
            ]);
        }
    }, [historyOptions]);

    const updatePageNumber = (value: number) => {
        // const optionUpdate = historyOptions;
        // optionUpdate.sortOptions.pageNumber = value;
        // setHistoryOptions(optionUpdate);

        const copy: GeneralOptions = { ...historyOptions };
        copy.sortOptions.pageNumber = value;
        setHistoryOptions(copy);
    };

    const model = new InventoryDto();

    const handleDateFilter = async () => {
        if (dateComparisonOperator == undefined) {
            alert('You must fill in a comparison operator');
            return;
        }

        if (
            dateComparisonOperator.parametizedCategory == ParameterizedCategory.DateType &&
            (dateValue == null || dateValue == '')
        ) {
            alert('You must fill in a date value!');
        }
        const generalOptions: GeneralOptions = new GeneralOptions();
        const filterOption: DynamicFilter = {
            propertyName: 'Import Date',
            value: dateValue == undefined ? '' : dateValue,
            comparisonOperator: dateComparisonOperator.enumValue,
            comparisonType: DataType.DateTime,
        };

        generalOptions.filterOptions = [filterOption];

        generalOptions.filterType = FilterType.Custom;

        const response = await InventoryService.GetHistory(generalOptions);
        const model = response.data.model;
        setImportHistory(model);
    };

    const handleEmployeeFilter = async () => {
        if (dateComparisonOperator == undefined) {
            alert('You must fill in a comparison operator');
            return;
        }

        if (
            dateComparisonOperator.parametizedCategory == ParameterizedCategory.DateType &&
            (dateValue == null || dateValue == '')
        ) {
            alert('You must fill in a date value!');
        }
        const generalOptions: GeneralOptions = new GeneralOptions();
        const filterOption: DynamicFilter = {
            propertyName: 'Import Date',
            value: dateValue == undefined ? '' : dateValue,
            comparisonOperator: dateComparisonOperator.enumValue,
            comparisonType: DataType.DateTime,
        };

        generalOptions.filterOptions = [filterOption];

        generalOptions.filterType = FilterType.Custom;

        const response = await InventoryService.GetHistory(generalOptions);
        const model = response.data.model;
        setImportHistory(model);
    };

    const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

    return (
        <>
            {/* Import Date Filter Dropdown */}
            <IonGrid>
                <IonRow style={{ position: 'relative', zIndex: '100' }}>
                    <IonCol style={{ position: 'relative', zIndex: '100' }}>
                        <p>What can I do here?</p>
                        <GeneralToolTip content={['You can view import history for inventory']} />
                    </IonCol>
                    <IonCol style={{ position: 'relative', zIndex: '100' }}>
                        <p>How do I filter results by date?</p>
                        <GeneralToolTip
                            content={[
                                'To filter by date click ComparisonOperator field',
                                'and fill it out. Then depending on the comparison',
                                'operator there may be a field for a date.',
                                'Fill out the date filed if applicatable and then',
                                'click the filter button.',
                            ]}
                        />
                    </IonCol>
                    <IonCol style={{ position: 'relative', zIndex: '100' }}>
                        <p>How do I filter results by employee?</p>
                        <GeneralToolTip
                            content={[
                                'To filter by employee click Employee Name Field and ',
                                'select an employee.',
                                'Once selected hit the Filter button',
                            ]}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonHeader>Filter Date</IonHeader>
                </IonRow>
                <IonRow>
                    <IonCol style={{ justifyContent: 'start' }}>
                        <IonItem>
                            <IonLabel>Comparison Operator</IonLabel>
                            <IonSelect
                                value={dateComparisonOperator}
                                onIonChange={(e) => {
                                    setDateComparisonOpeartor(e.detail.value);
                                }}>
                                {getAllComparisonOperatorInfosThatIncludeDataTypes(DataType.DateTime).map(
                                    ({ enumValue, displayName }: ComparisonOperatorInfo) => {
                                        return (
                                            <React.Fragment key={enumValue}>
                                                <IonSelectOption key={enumValue} value={enumValue}>
                                                    {displayName}
                                                </IonSelectOption>
                                            </React.Fragment>
                                        );
                                    }
                                )}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel>Import Date Filter</IonLabel>
                            <IonInput
                                type="date"
                                {...minMax}
                                value={dateValue}
                                disabled={
                                    dateComparisonOperator != undefined
                                        ? dateComparisonOperator.parametizedCategory ===
                                              ParameterizedCategory.DateType ||
                                          dateComparisonOperator.parametizedCategory ===
                                              ParameterizedCategory.NoParameters
                                        : true
                                }
                                onIonChange={(e) => {
                                    if (e.detail.value == undefined) {
                                        console.warn('Select value in Date Filter is null!');
                                        return;
                                    }
                                    setDateValue(e.detail.value);
                                }}
                            />
                        </IonItem>
                        <IonItem>
                            <IonButton onClick={handleDateFilter}>Filter</IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>

            {/* Employee Filter Dropdown */}

            <IonGrid>
                <IonRow>
                    <IonHeader>Filter Employee</IonHeader>
                </IonRow>
                <IonRow>
                    <IonCol style={{ justifyContent: 'start' }}>
                        <IonItem>
                            <IonLabel>Employee Name</IonLabel>
                            <IonSelect
                                value={selectedEmployeeName}
                                onIonChange={(e) => {
                                    setSelectedEmployeeName(e.detail.value);
                                }}>
                                {employeeNames.map((employeeName) => (
                                    <IonSelectOption key={employeeName} value={employeeName}>
                                        {employeeName}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonButton onClick={handleEmployeeFilter}>Filter</IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>

            {/* Import Reversed Filter Dropdown */}
            {/* Total Imported Filter Dropdown */}
            {importHistory.length > 0 && (
                <CollapsibleSection<ImportHistoryDto>
                    records={importHistory}
                    identifier={'id'}
                    labelStrip={(history: ImportHistoryDto): JSX.Element => {
                        return (
                            <span>{`Employee: ${history.Employee} Import Date: ${history['Import Date']} Reversed: ${history.Reversed} Total Import: ${history['Total Import']}`}</span>
                        );
                    }}
                    content={(history: ImportHistoryDto): JSX.Element => {
                        return (
                            <>
                                <CollapsibleSection
                                    records={[history]}
                                    identifier={'id'}
                                    labelStrip={() => <span>Details</span>}
                                    content={(history) => (
                                        <>
                                            <IonItem>
                                                <IonLabel>Import Date</IonLabel>
                                                <IonLabel>{history['Import Date']}</IonLabel>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel>Employee</IonLabel>
                                                <IonLabel>{history.Employee}</IonLabel>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel>Import Reversed?</IonLabel>
                                                <IonLabel>{history.Reversed ? 'True' : 'False'}</IonLabel>
                                            </IonItem>
                                            <IonItem>
                                                <IonLabel>Total Imported</IonLabel>
                                                <IonLabel>{history['Total Import']}</IonLabel>
                                            </IonItem>
                                            <IonItem>
                                                <IonButton onClick={(e) => getImportDetails(history.id)}>
                                                    View Details
                                                </IonButton>
                                            </IonItem>
                                            <IonItem>
                                                <IonButton
                                                    disabled={history.Reversed}
                                                    onClick={(e) => reverseImport(history.id)}>
                                                    Reverse Import
                                                </IonButton>
                                            </IonItem>
                                        </>
                                    )}
                                    startingValue={undefined}
                                    focusedEntry={null}
                                    setFocusedEntry={function (value: React.SetStateAction<string | null>): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                />

                                <IonAccordion></IonAccordion>
                            </>
                        );
                    }}
                    startingValue={undefined}
                    focusedEntry={focusedEntry}
                    setFocusedEntry={setFocusedEntry}
                />
            )}

            <IonRow>
                <IonButton onClick={(e) => updatePageNumber(historyOptions.sortOptions.pageNumber - 1)}>
                    Previous
                </IonButton>
                <IonButton
                    color={historyOptions.sortOptions.pageNumber == 1 ? 'success' : 'primary'}
                    onClick={(e) => updatePageNumber(buttonNumbers[0])}>
                    {buttonNumbers[0]}
                </IonButton>
                <IonButton
                    color={historyOptions.sortOptions.pageNumber == 2 ? 'success' : 'primary'}
                    onClick={(e) => updatePageNumber(buttonNumbers[1])}>
                    {buttonNumbers[1]}
                </IonButton>
                <IonButton
                    color={historyOptions.sortOptions.pageNumber > 2 ? 'success' : 'primary'}
                    onClick={(e) => updatePageNumber(buttonNumbers[2])}>
                    {buttonNumbers[2]}
                </IonButton>
                <IonButton color={'primary'} onClick={(e) => updatePageNumber(buttonNumbers[3])}>
                    {buttonNumbers[3]}
                </IonButton>
                <IonButton color={'primary'} onClick={(e) => updatePageNumber(buttonNumbers[4])}>
                    {buttonNumbers[4]}
                </IonButton>
                <IonButton
                    color={'primary'}
                    onClick={(e) => updatePageNumber(historyOptions.sortOptions.pageNumber + 1)}>
                    Next
                </IonButton>
            </IonRow>

            {/* This change just makes it so that it doesn't create a react component until the data is ready*/}
            {/* {importDetail && ( */}
            <ImportReportModal
                data={importDetail}
                showReportModal={showImportReportModal}
                setShowReportModal={setShowImportReportModal}
            />
            {/* )} */}
        </>
    );
};
