import SalesOrderTable from '../../components/NewTableApproach/SalesOrderTable/SalesOrderTable';
import QueriedTableSortFilterStateManager, {
    QueriedTableSortFilterStateManagerExportProps,
} from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { GeneralOptions } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { SortOptions } from '../../models/Dtos/OptionsDtos/SortOptions';
import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { DtoType, RecordType } from '../../models/Global';
import { SalesOrderService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { EndpointResponseType } from '../../utils/RecordTypeUtils';

interface SalesOrderQueriedTablePageProps {
    scrollTop: number;
}

const SalesOrderQueriedTablePage = ({ scrollTop }: SalesOrderQueriedTablePageProps) => {
    return (
        // <Layout>
        <QueriedTableSortFilterStateManager<SalesOrderDto>
            scrollTop={scrollTop}
            identifier={'Invoice Number'}
            identifierType={IdentifierType.Invoice}
            toolbar={true}
            endpoint={
                SalesOrderService.getSalesOrderList as (
                    options: GeneralOptions | SortOptions
                ) => Promise<EndpointResponseType>
            }
            tabbed={true}
            justSortOptions={false}
            model={new SalesOrderDto()}
            recordType={RecordType.SalesOrder}
            dtoType={DtoType.SalesOrder}>
            {(exportProps: QueriedTableSortFilterStateManagerExportProps) => <SalesOrderTable {...exportProps} />}
        </QueriedTableSortFilterStateManager>
        // </Layout>
    );
};
export default SalesOrderQueriedTablePage;
