import React, { Dispatch, SetStateAction } from 'react';
import { createPortal } from 'react-dom';
import styles from './FullScreenModal.module.scss';

import { IonButton } from '@ionic/react';

interface FullScreenModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  children: JSX.Element;
}

const fullScreenModalId = 'fullscreen-modal-custom';

const FullScreenModal: React.FC<FullScreenModalProps> = ({ showModal, setShowModal, children }) => {
  const Content = ({ onClose }: { onClose: () => void }) => {
    return <div className={styles.modal}>{children}</div>;
  };

  return (
    <>
      <IonButton
        onClick={() => {
          console.log("Clicked the Ion Button!")
          setShowModal(true);
        }}>
        Show Images
      </IonButton>
      {showModal && createPortal(<Content onClose={() => setShowModal(false)} />, document.getElementById('full-screen-modal')!)}
    </>

    // <>
    //     <button onClick={() => setShowModal(true)}>Show Images</button>
    //     <h1>ShowModal: {showModal ? 'True' : 'False'}</h1>

    //     {showModal && createPortal(<Content />, document.body)}
    // </>
  );
};

export default FullScreenModal;
