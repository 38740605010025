import { useState } from 'react';
import { MoreGenericResult } from '../../error-handling-util/error-handling-util';
import { PRE_DEFINED_ERROR_MESSAGES_DICT } from '../../error-handling-util/UTGenericErrorBase';
import {
    addGeneratedIdIfNoIdOrAddSpecifiedId,
    addGeneratedIdIfNoIdOrAddSpecifiedIdToAllObjectsInArray,
    GeneratedIdType,
    KEYED_TYPE_IDENTIFIER_PROPERTY_NAME,
} from '../../utils/KeyIdUtils';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';

export type UniqueIdHookUpdaterFunction<T extends object> =
    | ((currentObjState: GeneratedIdType<T> | T) => GeneratedIdType<T>)
    | ((currentObjState: GeneratedIdType<T> | T) => T);

export const useDynamicallyGeneratedUniqueIdArrayState = <T extends object>(initialValue: T[]) => {
    const [arrayState, setArrayState] = useState<GeneratedIdType<T>[]>(
        addGeneratedIdIfNoIdOrAddSpecifiedIdToAllObjectsInArray(initialValue)
    );

    const shallowEqual = (object1: T, object2: T) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            // For scrit type-checking. Should always be true.
            if (key in object1 && key in object2) {
                const t = object1 as { [K in typeof key]: unknown };
                const t2 = object2 as { [K in typeof key]: unknown };

                if (t[key] !== t2[key]) {
                    return false;
                }
            }
        }
        return true;
    };

    type AddObjSuccessType = { data: GeneratedIdType<T>[]; item: GeneratedIdType<T> };
    type AddObjErrorType = { providedIdOrObject: string | GeneratedIdType<T> | T | null | undefined; message: string };
    const addObj = (obj: T | GeneratedIdType<T>): Promise<MoreGenericResult<AddObjSuccessType, AddObjErrorType>> => {
        return new Promise<MoreGenericResult<AddObjSuccessType, AddObjErrorType>>((resolve, reject) => {
            if (obj === null) {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsNull,
                        providedIdOrObject: obj,
                    },
                });
                return;
            }

            if (obj === undefined) {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsUndefined,
                        providedIdOrObject: obj,
                    },
                });
                return;
            }

            if (typeof obj !== 'object') {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.mustPassAnObjectParameter,
                        providedIdOrObject: obj,
                    },
                });
                return;
            }

            const ensuredObjWithId = addGeneratedIdIfNoIdOrAddSpecifiedId(obj);

            setArrayState((arrayState) => {
                const checkedArray = arrayState.reduce<GeneratedIdType<T>[]>((result, item) => {
                    if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in item)) {
                        // Doesn't contain id
                        const itemWithId = addGeneratedIdIfNoIdOrAddSpecifiedId({
                            ...(item as GeneratedIdType<T>),
                        });
                        result.push(itemWithId as GeneratedIdType<T>);
                        return result;
                    }
                    if (
                        item[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] ===
                        (ensuredObjWithId as GeneratedIdType<T>)[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]
                    ) {
                        // if the list already contains a obj with the given id, I think we should not do anything and instead console an error.

                        resolve({
                            success: false,
                            error: {
                                providedIdOrObject: obj,
                                message: PRE_DEFINED_ERROR_MESSAGES_DICT.containsObjAlreadyWhileAdding,
                            },
                        });
                        result.push(item);
                        return result;
                    }
                    result.push(item);
                    return result;
                }, []);
                checkedArray.push(ensuredObjWithId as GeneratedIdType<T>);

                resolve({
                    success: true,
                    data: {
                        item: ensuredObjWithId,
                        data: checkedArray,
                    },
                });
                return checkedArray;
            });
        });
    };

    type RemoveObjSuccessType = { data: GeneratedIdType<T>[]; item: GeneratedIdType<T> };
    type RemoveObjErrorType = {
        providedIdOrObject: string | GeneratedIdType<T> | T | null | undefined;
        message: string;
    };
    const removeObj = (
        objOrGeneratedId: T | GeneratedIdType<T> | string
    ): Promise<MoreGenericResult<RemoveObjSuccessType, RemoveObjErrorType>> => {
        return new Promise<MoreGenericResult<RemoveObjSuccessType, RemoveObjErrorType>>((resolve, reject) => {
            if (objOrGeneratedId === null) {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsNull,
                        providedIdOrObject: objOrGeneratedId,
                    },
                });
                return;
            }

            if (objOrGeneratedId === undefined) {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsUndefined,
                        providedIdOrObject: objOrGeneratedId,
                    },
                });
                return;
            }

            // comeback to implement undefined check

            if (typeof objOrGeneratedId === 'object') {
                const obj = objOrGeneratedId as T | GeneratedIdType<T>;

                if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in obj)) {
                    // Passed object doesn't have an id. Return error object
                    resolve({
                        success: false,
                        error: {
                            providedIdOrObject: obj,
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.passedInObjectNeedsAnId,
                        },
                    });
                    return;
                }

                //TODO UPDATE TO HAVE RESOLVE IN HERE
                setArrayState((oldArray) => {
                    let removedItem: GeneratedIdType<T> | undefined;
                    const t = removedItem;
                    const updatedArray = oldArray.reduce<GeneratedIdType<T>[]>((result, objInArray) => {
                        if (
                            objInArray[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === obj[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]
                        ) {
                            // Found it. Return without adding it to result to effectively remove it
                            // Update variable to indicate we found it.

                            removedItem = objInArray;
                            return result;
                        }
                        result.push(objInArray);
                        return result;
                    }, []);

                    if (isNotNullNorUndefined(removedItem)) {
                        resolve({
                            success: true,
                            data: { data: updatedArray, item: removedItem },
                            // result: updatedArray,
                            // item: removedItem,
                        });
                    } else {
                        // Didn't find it. Return error obj

                        resolve({
                            success: false,
                            error: {
                                providedIdOrObject: obj,
                                message: PRE_DEFINED_ERROR_MESSAGES_DICT.objDoesntExistWithId,
                            },
                        });
                    }

                    return updatedArray;
                    // return [...oldArray.filter((objInArray) => shallowEqual(objInArray, obj))];
                });
                return;
            }

            const targetGeneratedId = objOrGeneratedId + '';

            setArrayState((oldArray) => {
                let removedItem: GeneratedIdType<T> | undefined;
                const t = removedItem;
                const updatedArray = oldArray.reduce<GeneratedIdType<T>[]>((result, objInArray) => {
                    if (objInArray[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === targetGeneratedId) {
                        // Found it. Return without adding it to result to effectively remove it
                        // Update variable to indicate we found it.

                        removedItem = objInArray;
                        return result;
                    }
                    result.push(objInArray);
                    return result;
                }, []);

                if (isNotNullNorUndefined(removedItem)) {
                    resolve({
                        success: true,
                        data: { data: updatedArray, item: removedItem },
                    });
                } else {
                    // Didn't find it. Return error obj

                    resolve({
                        success: false,
                        error: {
                            providedIdOrObject: objOrGeneratedId,
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.objDoesntExistWithId,
                        },
                    });
                }

                return updatedArray;
            });
        });
    };

    const getObjById = (generatedId: string, arrayStateParameter?: GeneratedIdType<T>[]) => {
        // This allows us to use this method even when we have a arrayState that's more updated
        if (isNullOrUndefined(arrayStateParameter)) {
            return arrayState.find((obj) => obj[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === generatedId);
        }

        return arrayStateParameter.find((obj) => obj[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === generatedId);
    };

    /*

    *************************

    HELPER FUNCTIONS FOR UPDATE OBJ METHOD    

    ************************* 
    */

    const updateArrayItemReducerMethod = (
        idToUpdate: string,
        updatedObj: GeneratedIdType<T>,
        result: GeneratedIdType<T>[],
        item: GeneratedIdType<T>
    ) => {
        // This is a id check to make sure items we're looking through have ids
        // If they don't contain id, add one
        if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in item)) {
            // Doesn't contain id

            const itemWithId = addGeneratedIdIfNoIdOrAddSpecifiedId({
                ...(item as GeneratedIdType<T>),
            });

            result.push(itemWithId);
            return result;
        }

        // This is where we use the updated obj if the generated id of the item matches the target generateId parameter
        if (item[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === idToUpdate) {
            result.push(updatedObj);
            return result;
        }

        // If none of the conditions above apply, much be an item not interested in. Leave item alone
        result.push(item);
        return result;
    };

    const updateArrayStateMethodForObjectOnly = (
        generatedId: string,
        resolve: (
            value:
                | MoreGenericResult<UpdateObjSuccessType, UpdateObjErrorType>
                | PromiseLike<MoreGenericResult<UpdateObjSuccessType, UpdateObjErrorType>>
        ) => void,
        object: GeneratedIdType<T>,
        arrayState: GeneratedIdType<T>[]
    ) => {
        const targetObj = getObjById(generatedId, arrayState);

        if (isNullOrUndefined(targetObj)) {
            // If we can't find a obj with provided Id, log error and don't update state by returning.
            resolve({
                success: false,
                error: {
                    providedIdOrObject: object,
                    message: PRE_DEFINED_ERROR_MESSAGES_DICT.objDoesntExistWithId,
                },
            });
            return arrayState;
        }

        // Time to make sure the object that was returned has id. And if not add the id from before

        const updatedArray = arrayState.reduce<GeneratedIdType<T>[]>(
            updateArrayItemReducerMethod.bind(null, generatedId, object),
            []
        );

        resolve({
            success: true,
            data: {
                data: updatedArray,
                item: object,
            },
        });

        return updatedArray;
    };

    const updateArrayStateMethodForIdAndUpdaterFunction = (
        generatedId: string,
        resolve: (
            value:
                | MoreGenericResult<UpdateObjSuccessType, UpdateObjErrorType>
                | PromiseLike<MoreGenericResult<UpdateObjSuccessType, UpdateObjErrorType>>
        ) => void,
        updaterFunctionAndIdTuple: [string, UniqueIdHookUpdaterFunction<T>],
        arrayState: GeneratedIdType<T>[]
    ) => {
        const targetObj = getObjById(generatedId, arrayState);

        if (isNullOrUndefined(targetObj)) {
            // If we can't find a obj with provided Id, log error and don't update state by returning.
            resolve({
                success: false,
                error: {
                    providedIdOrObject: updaterFunctionAndIdTuple,
                    message: PRE_DEFINED_ERROR_MESSAGES_DICT.objDoesntExistWithId,
                },
            });
            return arrayState;
        }

        const [, updaterFunction] = updaterFunctionAndIdTuple;

        const updatedFromConsumerOfOHook = updaterFunction(targetObj);

        // Time to make sure the object that was returned has id. And if not add the id from before

        const ultimatelyObjWithId = addGeneratedIdIfNoIdOrAddSpecifiedId(updatedFromConsumerOfOHook, generatedId);

        const updatedArray = arrayState.reduce<GeneratedIdType<T>[]>(
            updateArrayItemReducerMethod.bind(null, generatedId, ultimatelyObjWithId as GeneratedIdType<T>),
            []
        );

        resolve({
            success: true,
            data: {
                data: updatedArray,
                item: updaterFunctionAndIdTuple,
            },
        });

        return updatedArray;
    };

    /*

    Have an idea.

    - Allos the user to either provide an object directly or have access to passing in a function that takes in 
    updated arrayState and then modifies it how they want and then returns an object.

    - In this way the user/consumer of this hook can either provide an object or get an updated version of the arrayState to modify, but the actual modification
    is done internally where we can have code to ensure the arrayState always has elements with ID's

    ** NOTE ** For this function I use (var as GeneratedIdType<T>) multiple times. For some reason if I don't typescript doesn't think the item is an object.
        even though the type I'm casting to appears to be the same
    
     */

    type UpdateObjSuccessType = {
        data: GeneratedIdType<T>[];
        item: [string, UniqueIdHookUpdaterFunction<T>] | GeneratedIdType<T> | T;
    };
    type UpdateObjErrorType = {
        providedIdOrObject: [string, UniqueIdHookUpdaterFunction<T>] | GeneratedIdType<T> | T | null | undefined;
        message: string;
    };
    const updateObj = (
        updaterFunctionAndIdTupleOrObj: [string, UniqueIdHookUpdaterFunction<T>] | GeneratedIdType<T> | T
    ): Promise<MoreGenericResult<UpdateObjSuccessType, UpdateObjErrorType>> => {
        return new Promise<MoreGenericResult<UpdateObjSuccessType, UpdateObjErrorType>>((resolve, reject) => {
            if (updaterFunctionAndIdTupleOrObj === null) {
                resolve({
                    success: false,
                    error: {
                        providedIdOrObject: null,
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsNull,
                    },
                });
                return;
            }

            if (updaterFunctionAndIdTupleOrObj === undefined) {
                resolve({
                    success: false,
                    error: {
                        providedIdOrObject: undefined,
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsUndefined,
                    },
                });
                return;
            }

            if (Array.isArray(updaterFunctionAndIdTupleOrObj)) {
                // This is an updater function

                const [generatedId, updaterFunction] = updaterFunctionAndIdTupleOrObj;
                setArrayState(
                    updateArrayStateMethodForIdAndUpdaterFunction.bind(
                        null,
                        generatedId,
                        resolve,
                        updaterFunctionAndIdTupleOrObj
                    )
                );

                return;
            }

            if (typeof updaterFunctionAndIdTupleOrObj !== 'object') {
                // Invalid non-array non-object value
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.mustPassAnObjectOrArrayParameter,
                        providedIdOrObject: updaterFunctionAndIdTupleOrObj,
                    },
                });
                return;
            }

            // This is a updatedObj
            const updatedObj = updaterFunctionAndIdTupleOrObj as GeneratedIdType<T> | T;

            if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in updatedObj)) {
                // ultimatelyObjWithId = addGeneratedIdIfNoIdOrAddSpecifiedId(ultimatelyObjWithId);
                resolve({
                    success: false,
                    error: {
                        providedIdOrObject: updatedObj,
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.passedInObjectNeedsAnId,
                    },
                });
                return;

                // Doesn't contain id. return error
            }

            const verifiedObjWithId = updatedObj as GeneratedIdType<T>;

            // So in this case consumer just passed in object with id. We wil use the same updateArrayStateMethod
            // function that supports updaterfunctions and we'll just pass an updater function that simply returns
            // the provided obj
            setArrayState(
                updateArrayStateMethodForObjectOnly.bind(
                    null,
                    verifiedObjWithId[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                    resolve,
                    verifiedObjWithId
                )
            );
        });
    };

    /*

    I'm adding this function because the sort manager has a unique case the the filter manager didn't where it needs to be able to swap order of items
    Because of this unique case, I've decided I will add a fairly specific function that handles that use-case because I don't want to try and make a setArrayState
    type of method since from my head it sounds like it'd be complex trying to ensure dynamic ids at that point and additionally it would much likely take much longer to implement

    */

    type SwapOrderObjSuccessType = {
        data: GeneratedIdType<T>[];
        items: [GeneratedIdType<T> | string, GeneratedIdType<T> | string];
    };
    type SwapOrderObjErrorType = {
        providedIdsOrObjects: [
            GeneratedIdType<T> | string | null | undefined,
            GeneratedIdType<T> | string | null | undefined
        ];
        message: string;
    };

    const swapOrder = (
        firstItemParameter: string | GeneratedIdType<T>,
        secondItemParameter: string | GeneratedIdType<T>
    ): Promise<MoreGenericResult<SwapOrderObjSuccessType, SwapOrderObjErrorType>> => {
        return new Promise<MoreGenericResult<SwapOrderObjSuccessType, SwapOrderObjErrorType>>((resolve, reject) => {
            if (firstItemParameter === null) {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsNull,
                        providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                    },
                });
                return;
            }

            if (firstItemParameter === undefined) {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.parameterIsUndefined,
                        providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                    },
                });
                return;
            }

            let fisrtParameterId: undefined | string = undefined;

            if (typeof firstItemParameter === 'string') {
                fisrtParameterId = firstItemParameter;

                // Didn't realize typeof null is object. Need to add a check here
            } else if (typeof firstItemParameter === 'object') {
                if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in firstItemParameter)) {
                    // No id in the provided object. Return error object
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.passedInObjectNeedsAnId,
                            providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                        },
                    });
                    return;
                }

                fisrtParameterId = firstItemParameter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME];
            } else {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.mayNotPassNonObjectNonStringValues,
                        providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                    },
                });
                return;
            }

            let secondParameterId: undefined | string = undefined;

            if (typeof secondItemParameter === 'string') {
                secondParameterId = secondItemParameter;
            } else if (typeof secondItemParameter === 'object') {
                if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in secondItemParameter)) {
                    // No id in the provided object. Return error object
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.passedInObjectNeedsAnId,
                            providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                        },
                    });
                    return;
                }

                secondParameterId = secondItemParameter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME];
            } else {
                resolve({
                    success: false,
                    error: {
                        message: PRE_DEFINED_ERROR_MESSAGES_DICT.mayNotPassNonObjectNonStringValues,
                        providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                    },
                });
                return;
            }

            setArrayState((arrayState) => {
                let firstParamFoundIndex = -1;
                let secondParamFoundIndex = -1;
                let errorOccured = false;

                const copiedArray = arrayState.map<GeneratedIdType<T>>((item, index) => {
                    if (errorOccured) {
                        return item;
                    }
                    if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in item)) {
                        // This says there's an error that has occured and to not modify the array anymore
                        // Return error Obj
                        errorOccured = true;
                        resolve({
                            success: false,
                            error: {
                                message: PRE_DEFINED_ERROR_MESSAGES_DICT.itemInArrayDoesNotHaveId,
                                providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                            },
                        });
                        return item;
                    }

                    const itemId = item[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME];

                    if (itemId === fisrtParameterId) {
                        firstParamFoundIndex = index;
                    }

                    if (itemId === secondParameterId) {
                        secondParamFoundIndex = index;
                    }
                    return item;
                });

                if (firstParamFoundIndex === -1 || secondParamFoundIndex === -1) {
                    // Couldn't find one of them
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.oneOfProvidedParametersIdsWasNotFoundInList,
                            providedIdsOrObjects: [firstItemParameter, secondItemParameter],
                        },
                    });
                    return arrayState;
                }

                // With indexs, create a new array, swap items, and then return new array

                const temporaryFirstItem = copiedArray[firstParamFoundIndex];
                copiedArray[firstParamFoundIndex] = copiedArray[secondParamFoundIndex];
                copiedArray[secondParamFoundIndex] = temporaryFirstItem;

                resolve({
                    success: true,
                    data: { data: copiedArray, items: [firstItemParameter, secondItemParameter] },
                });

                return copiedArray;
            });
        });
    };

    type MoveItemUpObjSuccessType = {
        data: GeneratedIdType<T>[];
        item: GeneratedIdType<T> | string;
    };
    type MoveItemUpObjErrorType = {
        providedIdOrObject: GeneratedIdType<T> | string | null | undefined;
        message: string;
    };
    const shiftItemUp = (
        idOrObject: string | GeneratedIdType<T>
    ): Promise<MoreGenericResult<MoveItemUpObjSuccessType, MoveItemUpObjErrorType>> => {
        return new Promise<MoreGenericResult<MoveItemUpObjSuccessType, MoveItemUpObjErrorType>>((resolve, reject) => {
            let targetId: undefined | string = undefined;

            if (typeof idOrObject === 'string') {
                targetId = idOrObject;
            } else if (typeof idOrObject === 'object') {
                if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in idOrObject)) {
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.passedInObjectNeedsAnId,
                            providedIdOrObject: idOrObject,
                        },
                    });
                    return;
                }

                targetId = idOrObject[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME];
            }

            setArrayState((arrayState) => {
                let errorOccured = false;

                let itemIndex = -1;

                const copiedArray = arrayState.reduce<GeneratedIdType<T>[]>((result, item, index) => {
                    if (errorOccured) {
                        result.push(item);
                        return result;
                    }

                    if (item[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === targetId) {
                        if (index === 0) {
                            // Attempting to move up but it's the closest to the top.
                            resolve({
                                success: false,
                                error: {
                                    message: PRE_DEFINED_ERROR_MESSAGES_DICT.itemIsAlreadyAsHighAsPossible,
                                    providedIdOrObject: idOrObject,
                                },
                            });
                            errorOccured = true;
                            result.push(item);
                            return result;
                        }

                        itemIndex = index;
                    }

                    result.push(item);
                    return result;
                }, []);

                if (itemIndex === -1) {
                    // Error
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.objDoesntExistWithId,
                            providedIdOrObject: idOrObject,
                        },
                    });

                    return arrayState;
                }

                const tempItemAboveTargetId = copiedArray[itemIndex - 1];
                copiedArray[itemIndex - 1] = copiedArray[itemIndex];
                copiedArray[itemIndex] = tempItemAboveTargetId;

                resolve({ success: true, data: { data: copiedArray, item: idOrObject } });
                return copiedArray;
            });
        });
    };

    type MoveItemDownObjSuccessType = {
        data: GeneratedIdType<T>[];
        item: GeneratedIdType<T> | string;
    };
    type MoveItemDownObjErrorType = {
        providedIdOrObject: GeneratedIdType<T> | string | null | undefined;
        message: string;
    };

    const shiftItemDown = (
        idOrObject: string | GeneratedIdType<T>
    ): Promise<MoreGenericResult<MoveItemDownObjSuccessType, MoveItemDownObjErrorType>> => {
        return new Promise<MoreGenericResult<MoveItemUpObjSuccessType, MoveItemUpObjErrorType>>((resolve, reject) => {
            let targetId: undefined | string = undefined;

            if (typeof idOrObject === 'string') {
                targetId = idOrObject;
            } else if (typeof idOrObject === 'object') {
                if (!(KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in idOrObject)) {
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.passedInObjectNeedsAnId,
                            providedIdOrObject: idOrObject,
                        },
                    });
                    return;
                }

                targetId = idOrObject[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME];
            }

            setArrayState((arrayState) => {
                let errorOccured = false;

                let itemIndex = -1;

                const copiedArray = arrayState.reduce<GeneratedIdType<T>[]>((result, item, index) => {
                    if (errorOccured) {
                        result.push(item);
                        return result;
                    }

                    if (item[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] === targetId) {
                        if (index === arrayState.length - 1) {
                            // Attempting to move down but it's the closest to the top.
                            resolve({
                                success: false,
                                error: {
                                    message: PRE_DEFINED_ERROR_MESSAGES_DICT.itemIsAlreadyAsLowAsPossible,
                                    providedIdOrObject: idOrObject,
                                },
                            });
                            errorOccured = true;
                            result.push(item);
                            return result;
                        }

                        itemIndex = index;
                    }

                    result.push(item);
                    return result;
                }, []);

                if (itemIndex === -1) {
                    // Error
                    resolve({
                        success: false,
                        error: {
                            message: PRE_DEFINED_ERROR_MESSAGES_DICT.objDoesntExistWithId,
                            providedIdOrObject: idOrObject,
                        },
                    });

                    return arrayState;
                }

                const tempItemAboveTargetId = copiedArray[itemIndex + 1];
                copiedArray[itemIndex + 1] = copiedArray[itemIndex];
                copiedArray[itemIndex] = tempItemAboveTargetId;

                resolve({ success: true, data: { data: copiedArray, item: idOrObject } });
                return copiedArray;
            });
        });
    };

    //When these are used, there's no way to force the user/consumer of these methods to write code to handle if successful is false unfortunately.
    // And even if I was using normal 'throw' statements compared to returning error objects it would have the same limitation.
    // I will put a comment here hoping that if you're going to use these methods you handle the case where the result's susccess value is false and act accordingly.
    return { arrayState, addObj, updateObj, removeObj, swapOrder, shiftItemUp, shiftItemDown };
};
