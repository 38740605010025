import { StringDto } from '../../models/Dtos/GeneralDtos/StringDto';
import { EmployeeService } from '../../utils/apiServices';
import { AsyncSelectLoadOptionsReturnType } from './SearchUtils';

interface Option {
    label: string;
    value: string;
}

export const SearchForEmployee = (name: string): Promise<AsyncSelectLoadOptionsReturnType<Option>> => {
    const promise: Promise<AsyncSelectLoadOptionsReturnType<Option>> = new Promise(async (resolve, reject) => {
        try {
            const response = await EmployeeService.SearchByName(new StringDto(name));
            const data = response.data;

            const mappedData = data.model.map((employee) => {
                return { label: employee.name, value: employee.name };
            });
            // update for type specific later
            resolve(mappedData);
        } catch (err) {
            reject('There was an error while attempting to get customer name');
        }
    });
    return promise;

    // .then(({ data }) => {
    //     const employeeNameArray = new Array();
    //     data.forEach((x: any) => employeeNameArray.push(x.Name));
    //     // update for type specific later
    //     return employeeNameArray;
    // })
    // .catch((err) => console.error(err));
};
