import { jsPDF } from 'jspdf';
import React, { useCallback, useEffect, useRef } from 'react';
import UtilityTrailerLogo from '../../assets/utility-trailer-logo.png';
import rodPdfConfig from '../../config/delivery-pdf-information.json';
import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';

type ShowDeliveryPdfPropsType = {
    toggle: boolean;
    pdfTargetedRecord: DeliveryDto | undefined;
    customerName: string;
};

const DEFAULT_ROUNDED_RECT_ROUND_AMOUNT = 5;

const ShowDeliveryPdf: React.FC<ShowDeliveryPdfPropsType> = ({ toggle, pdfTargetedRecord, customerName }) => {
    const PAGE_WIDTH_IN_MILIMETERS = 210;
    const PAGE_HEIGHT_IN_MILIMETERS = 297;

    const skippedMountUE = useRef<boolean>(false);

    const handleSurroundElementsByHorizontalCoordinates = (
        doc: jsPDF,
        y: number,
        height: number,
        red: number,
        green: number,
        blue: number,
        isRounded = false
    ) => {
        const xOffset = 5;

        doc.setFillColor(red, green, blue);
        if (isRounded) {
            doc.roundedRect(
                xOffset,
                y,
                PAGE_WIDTH_IN_MILIMETERS - xOffset * 2,
                height,
                DEFAULT_ROUNDED_RECT_ROUND_AMOUNT,
                DEFAULT_ROUNDED_RECT_ROUND_AMOUNT,
                'F'
            );
        } else {
            doc.rect(xOffset, y, PAGE_WIDTH_IN_MILIMETERS - xOffset * 2, height, 'F');
        }
    };

    const DEFAULT_WORD_WRAP_WIDTH = 60;

    const wrapWord = (doc: jsPDF, x: number, y: number, maxCharacters: number, value: string, limit?: number) => {
        const words = value.split(' ').filter((item) => item !== '');
        let combiningString = '';

        let valueLines = words.reduce<string[]>((result, word, index) => {
            const inchesToMM = 72 / 25.6;
            const milimeters = (doc.getStringUnitWidth(combiningString + ' ' + word) * 16) / inchesToMM;

            if (milimeters < PAGE_WIDTH_IN_MILIMETERS - 15) {
                combiningString += ' ' + word;

                if (words.length === 1) {
                    result.push(combiningString.trimStart());
                } else if (index === words.length - 1) {
                    result.push(combiningString.trimStart() + ' ' + word);
                }

                return result;
            }

            // if (combiningString === '') {
            //     // This will happen if there's only one word
            //     result.push(word);
            //     return result;
            // }

            result.push(combiningString.trimStart());
            combiningString = word;
            return result;
        }, []);

        let totalYModification = 0;
        if (isNotNullNorUndefined(limit)) {
            valueLines = valueLines.reduce<string[]>((result, item, i) => {
                if (i === limit - 1) {
                    result.push(item.slice(0, -3) + '...');
                } else if (i < limit - 1) {
                    result.push(item);
                }
                return result;
            }, []);
        }

        valueLines.forEach((line, index) => {
            const yModifier = index * 5;
            doc.text(line, x, y + yModifier);
            totalYModification = yModifier + 5;
        });

        return totalYModification === 0 ? 5 : totalYModification;
    };

    const populateFirstPage = useCallback(
        (doc: jsPDF, pdfTargetedRecord: DeliveryDto, utLogo: string) => {
            let currentLine = 20;

            // handleSurroundElementsByHorizontalCoordinates(doc, 30, 35, 240, 128, 128, true);
            // handleSurroundElementsByHorizontalCoordinates(doc, 70, 220, 41, 128, 186, true);
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12);

            const now = new Date();
            doc.text('Delivery', PAGE_WIDTH_IN_MILIMETERS / 2, currentLine, { align: 'center' });
            doc.text(now.toLocaleDateString(), PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, { align: 'right' }); // 110
            doc.addImage(utLogo, 'PNG', 9, currentLine - 3, 68.85, 24);
            currentLine += 10;

            doc.text(`${rodPdfConfig.company['name']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, { align: 'right' }); // 40
            currentLine += 5;

            doc.text(`${rodPdfConfig.company['address'][0]}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 40
            currentLine += 5;
            doc.text(`${rodPdfConfig.company['address'][1]}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 40
            currentLine += 5;
            doc.text(`${rodPdfConfig.company['phone']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50

            currentLine += 10;
            doc.rect(0, currentLine - 8, PAGE_WIDTH_IN_MILIMETERS, 1, 'F');

            //@ts-ignore
            doc.setFont(undefined, 'bold');

            doc.setTextColor(0, 0, 0);

            doc.setFontSize(16);
            doc.text('Carrier Info', 10, currentLine);
            doc.setFontSize(12);
            currentLine += 10;

            doc.text(`Carrier Name:`, 10, currentLine);

            doc.text(`Carrier Truck Number:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Carrier Name']}`, 10, currentLine);

            doc.text(`${pdfTargetedRecord['Carrier Truck Number']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;

            doc.text(`Carrier Phone:`, 10, currentLine);

            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Carrier Phone']}`, 10, currentLine);

            currentLine += 5;

            //@ts-ignore
            doc.setFont(undefined, 'bold');
            doc.text(`Driver First Name:`, 10, currentLine);

            doc.text(`Driver Last Name:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Driver First Name']}`, 10, currentLine);

            doc.text(`${pdfTargetedRecord['Driver Last Name']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;
            doc.rect(10, currentLine - 2, PAGE_WIDTH_IN_MILIMETERS - 20, 1, 'F');
            currentLine += 5;
            // doc.text(
            //     `=====================================================================================================`,
            //     10,
            //     currentLine
            // );

            doc.setFontSize(16);

            doc.text('Inventory Info', 10, currentLine);
            doc.setFontSize(12);

            currentLine += 10;
            doc.text(`Stock:`, 10, currentLine);

            doc.text(`Year:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            currentLine += 5;

            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord.Stock}`, 10, currentLine);
            doc.text(`${pdfTargetedRecord.Year}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;
            doc.text(`Inventory Type:`, 10, currentLine);

            doc.text(`Make:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Inventory Type']}`, 10, currentLine);

            doc.text(`${pdfTargetedRecord['Make']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');

            currentLine += 5;

            doc.text(`Vin:`, 10, currentLine);
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord.VIN}`, 10, currentLine);
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            doc.setTextColor(0, 0, 0);

            currentLine += 5;
            doc.rect(10, currentLine - 2, PAGE_WIDTH_IN_MILIMETERS - 20, 1, 'F');
            currentLine += 5;

            doc.setFontSize(16);
            doc.text(`Address Info:`, 10, currentLine);
            doc.setFontSize(12);

            currentLine += 10;

            doc.text(`Address:`, 10, currentLine);

            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');

            currentLine += wrapWord(doc, 10, currentLine, DEFAULT_WORD_WRAP_WIDTH, pdfTargetedRecord.Address ?? '');

            //@ts-ignore
            doc.setFont(undefined, 'bold');

            doc.text(`City:`, 10, currentLine);

            doc.text(`State:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['City']}`, 10, currentLine);

            doc.text(`${pdfTargetedRecord['State']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;
            doc.text(`Zip:`, 10, currentLine);

            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Zip']}`, 10, currentLine);

            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;

            doc.rect(10, currentLine - 2, PAGE_WIDTH_IN_MILIMETERS - 20, 1, 'F');
            // doc.text(
            //     `=====================================================================================================`,
            //     10,
            //     currentLine
            // );
            currentLine += 5;

            doc.setFontSize(16);
            doc.text('Customer Name:', 10, currentLine);
            doc.setFontSize(12);

            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${customerName}`, 10, currentLine);

            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;
            doc.rect(10, currentLine - 2, PAGE_WIDTH_IN_MILIMETERS - 20, 1, 'F');
            currentLine += 5;

            doc.setFontSize(16);
            doc.text(`Tracking Info:`, 10, currentLine);
            doc.setFontSize(12);

            currentLine += 10;

            doc.text(`Permit Number:`, 10, currentLine);

            doc.text(`Receipt Number:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Permit Number']}`, 10, currentLine);

            doc.text(`${pdfTargetedRecord['Receipt Number']}`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
                align: 'right',
            }); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;

            doc.text(`Delivered Date:`, 10, currentLine);

            // doc.text(`Accounting Status:`, PAGE_WIDTH_IN_MILIMETERS - 10, currentLine, {
            //     align: 'right',
            // }); // 50
            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Delivered Date']}`, 10, currentLine);

            // doc.text(
            //     `${AccountingStatus[pdfTargetedRecord['Accounting Status'] ?? 1]}`,
            //     PAGE_WIDTH_IN_MILIMETERS - 10,
            //     currentLine,
            //     {
            //         align: 'right',
            //     }
            // ); // 50
            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;

            doc.text(`Temp Tag Expiration:`, 10, currentLine);

            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            doc.text(`${pdfTargetedRecord['Temp Tag Expiration']}`, 10, currentLine);

            //@ts-ignore
            doc.setFont(undefined, 'bold');
            currentLine += 5;

            doc.text(`Notes:`, 10, currentLine);

            currentLine += 5;
            //@ts-ignore
            doc.setFont(undefined, 'normal');
            currentLine += wrapWord(
                doc,
                10,
                currentLine,
                DEFAULT_WORD_WRAP_WIDTH,
                pdfTargetedRecord['Notes - D'] ?? '',
                5
            );

            //@ts-ignore
            doc.setFont(undefined, 'bold');
            doc.setTextColor(0, 0, 0);
        },
        [customerName]
    );

    const imageBase64 = useRef<string | ArrayBuffer | null>();

    const loadbase64Image = () => {
        const result = new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();

            fetch(UtilityTrailerLogo)
                .then((response) => response.blob())
                .then((blob) => {
                    fileReader.readAsDataURL(blob);
                })
                .catch((error) => {
                    console.error('Error loading image:', error);
                    reject();
                });

            fileReader.onload = () => {
                imageBase64.current = fileReader.result;
                resolve(fileReader.result as string);
            };
        });
        return result;
    };

    useEffect(() => {
        if (!skippedMountUE.current) {
            skippedMountUE.current = true;
            return;
        }

        if (isNullOrUndefined(pdfTargetedRecord)) {
            return;
        }

        (async () => {
            const doc = new jsPDF();
            doc.setFillColor(255, 0, 0);

            const utLogo = await loadbase64Image();

            const processedRecord = Object.entries(pdfTargetedRecord).reduce((accumulator, [property, value]) => {
                let updatedValue = value;

                if (isNullOrUndefined(value)) {
                    updatedValue = '';
                }

                return { ...accumulator, [property]: updatedValue };
            }, {}) as DeliveryDto;

            populateFirstPage(doc, processedRecord, utLogo);

            doc.save(`delivery-${pdfTargetedRecord.Stock}.pdf`);
        })();
    }, [pdfTargetedRecord, populateFirstPage]);

    return <div></div>;
};

export default ShowDeliveryPdf;
