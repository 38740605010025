import { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import styles from './SimpleAccordion.module.scss';

interface SimpleAccordionProps<T extends object> {
    generateInnerContent: (value: T, index: number) => ReactElement;
    generateLabel: (value: T, index: number) => ReactElement | string;
    dataList: T[];
    identifierKey: keyof T;
    initialSelectedIdentifierValue?: string;
}

const SimpleAccordion = <T extends object>({
    generateInnerContent,
    generateLabel,
    dataList,
    identifierKey,
    initialSelectedIdentifierValue,
}: SimpleAccordionProps<T>) => {
    const [selectedIdentifierValue, setSelectedIdentifierValue] = useState<string | null>(
        // the ?? will basically check if the value to the left is null or undefined. If it its null/undefined it'll use the value on the right. Otherwise it will use the value on the left.
        // This state only accepts null and I want it that way. So we basically say, if the left of the ?? thing is null or undefined, just make it null. Otherwise, use it
        initialSelectedIdentifierValue ?? null
    );

    const selectedRef = useRef<HTMLDivElement>(null);

    const handleAccordionClick = (e: MouseEvent, data: T, identifierValue: string) => {
        if (identifierValue === selectedIdentifierValue) {
            setSelectedIdentifierValue(null);
            return;
        }

        setSelectedIdentifierValue(identifierValue);
    };

    useEffect(() => {
        if (
            isNullOrUndefined(selectedIdentifierValue) ||
            isNullOrUndefined(selectedRef) ||
            isNullOrUndefined(selectedRef.current)
        ) {
            return;
        }

        // const el = document.getElementById(selectedIdentifierValue);

        const el = selectedRef.current;

        const intervalId = setInterval(() => {
            if (isNullOrUndefined(selectedRef.current) || selectedRef.current.clientHeight === 0) {
                // This means it's not rendered yet.
                return;
            }

            selectedRef.current.scrollIntoView(true);
            clearInterval(intervalId);
        }, 500);
    }, []);

    return (
        <div className={styles.accordionGroup} data-testid="SimpleAccordion">
            {dataList.map((data, index) => {
                const identifierValue = data[identifierKey] + '';

                return (
                    <div
                        ref={selectedIdentifierValue === identifierValue ? selectedRef : undefined}
                        key={identifierValue}
                        className={styles.accordion}
                        id={identifierValue}>
                        <button
                            type="button"
                            className={`${styles.accordionButton} ${
                                selectedIdentifierValue === identifierValue ? styles.active : styles.nonactive
                            }`}
                            onClick={(e) => handleAccordionClick(e, data, identifierValue)}>
                            <div className={styles.labelContainer}> {generateLabel(data, index)}</div>
                        </button>
                        {selectedIdentifierValue === identifierValue ? (
                            <div className={`${styles.panel}`}>{generateInnerContent(data, index)}</div>
                        ) : (
                            <></>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SimpleAccordion;
