import { isNotNullNorUndefined, isNullOrUndefined } from './NullOrUndefined';

let previousDivEl: HTMLDivElement | null = null;

let timeoutId: number | null = null;

const makeSimpleToast = (header = 'Error:', message = 'Default Message', duration = 7000, position = 'bottom') => {
    if (isNotNullNorUndefined(previousDivEl)) {
        previousDivEl.remove();
    }

    if (isNotNullNorUndefined(timeoutId)) {
        clearTimeout(timeoutId);
    }

    const body = `
        <div class="toast-header">
            <strong class="me-auto">${header}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
            ${message}
        </div>
    `;

    const div = document.createElement('div');

    div.classList.add('toast');
    div.setAttribute('role', 'alert');
    div.setAttribute('aria-live', 'assertive');

    div.setAttribute('aria-atomic', 'true');

    div.style.display = 'block';

    div.style.position = 'fixed';

    div.style.right = '0';
    div.style.bottom = '0';

    div.style.zIndex = '9999999';

    div.innerHTML = body;

    const divEl = document.body.appendChild(div) as HTMLDivElement;

    previousDivEl = divEl;

    timeoutId = window.setTimeout(() => {
        if (isNullOrUndefined(previousDivEl)) {
            return;
        }

        previousDivEl.remove();
        return;
    }, duration);
};

export default makeSimpleToast;
