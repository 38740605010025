import { CSSObjectWithLabel } from 'react-select';

export const reactSelectStylesForTable = () => ({
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    border: 'none',
    backgroundColor: 'transparent',
  }),

  // container: (
  //     baseStyles: CSSObjectWithLabel,

  //     state: any
  // ) => ({
  //     ...baseStyles,
  //     backgroundColor: 'white',
  // }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    backgroundColor: 'white',

  }),
});
