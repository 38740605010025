import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useFilterSortModal } from '../../hooks/useFilterSortModal/useFilterSortModal';
import AdminAuditDto from '../../models/AuditModels/AdminAuditDto';
import AuditDetail from '../../models/AuditModels/AuditDetail';
import GetAuditDto from '../../models/AuditModels/GetAuditDto';
import { AuditType, DtoType } from '../../models/Global';
import { AuditService } from '../../utils/apiServices';
import { POSSIBLE_AUDIT_LOG_TABLE_NAMES, POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE } from '../../utils/AuditLogTableNameUtil';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import AuditLogCollapsibleContent from '../AuditLogQueriedTable/AuditLogCollapsibleContent/AuditLogCollapsibleContent';
import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './AdminPanelAuditLog.module.scss';

const AdminPanelAuditLog = () => {
    const {
        modalToReturn,
        dto: genericDto,
        setDto,
        setShowModal,
    } = useFilterSortModal(DtoType.AdminAuditDto, new GetAuditDto(), new AdminAuditDto(), 'filters', 'options');
    const dto = genericDto as GetAuditDto;

    const [auditLogDtoArray, setAuditLogDtoArray] = useState<AdminAuditDto[]>([]);

    type ShowingModalDictionary = {
        [key: string]: boolean;
    };

    const [showAdminAuditLogDetailsModalDictionary, setShowAdminAuditLogDetailsModalDictionary] =
        useState<ShowingModalDictionary>({});

    const handleUpdatingTable = () => {
        // Update Table
        AuditService.GetAuditForAdmin(dto).then(({ data: { model: auditLogDtoArray } }) => {
            setAuditLogDtoArray(auditLogDtoArray);
        });
    };

    useEffect(() => {
        // Update Table
        AuditService.GetAuditForAdmin(dto).then(({ data: { model: auditLogDtoArray } }) => {
            setAuditLogDtoArray(auditLogDtoArray);
        });
    }, [dto]);

    const updateShowModalDict = (changedDate: string, toShowModal: boolean) => {
        setShowAdminAuditLogDetailsModalDictionary((modalDict) => {
            return { ...modalDict, [changedDate]: toShowModal };
        });
    };

    const updateTableName = (tableName: POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE) => {
        setDto((oldDto) => {
            return {
                ...oldDto,
                tableName,
                options: {
                    ...oldDto.options,
                    pageNumber: 1,
                },
            };
        });
    };

    const updateAuditType = (auditType: AuditType) => {
        setDto((oldDto) => {
            return {
                ...oldDto,
                auditType,
                options: {
                    ...oldDto.options,
                    pageNumber: 1,
                },
            };
        });
    };

    const updatePageNumber = (pageNumber: number) => {
        setDto((oldDto) => {
            return {
                ...oldDto,
                options: {
                    ...oldDto.options,
                    pageNumber,
                },
            };
        });
    };

    const handlePreviousButtonHit = () => {
        setDto((oldDto) => {
            return {
                ...oldDto,
                options: {
                    ...oldDto.options,
                    pageNumber: oldDto.options.pageNumber - 1,
                },
            };
        });
    };

    const handleNextButtonHit = () => {
        setDto((oldDto) => {
            return {
                ...oldDto,
                options: {
                    ...oldDto.options,
                    pageNumber: oldDto.options.pageNumber + 1,
                },
            };
        });
    };

    const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

    return (
        <div className={styles.AdminPanelAuditLogContainer} data-testid="AdminPanelAuditLog">
            {modalToReturn}
            <IonButton
                size="small"
                className={`${styles.button} ${styles.addNewButton}`}
                onClick={() => setShowModal(true)}
                color="add-new-button">
                Manage Custom Filters
            </IonButton>
            <IonItem>
                <IonLabel position="stacked" className={styles.larger_label}>
                    Table Name
                </IonLabel>
                <IonSelect
                    value={dto.tableName}
                    onIonChange={(e) => {
                        const value = e.detail.value + '';
                        if (value === undefined || value === null) {
                            return;
                        }

                        if (
                            isNullOrUndefined(
                                POSSIBLE_AUDIT_LOG_TABLE_NAMES.find(
                                    (item) => item.toLowerCase() === value.toLowerCase()
                                )
                            )
                        ) {
                            alert('You entered an invalid table name!');
                            return;
                        }

                        updateTableName(value as POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE);
                    }}>
                    {POSSIBLE_AUDIT_LOG_TABLE_NAMES.map((name) => {
                        return (
                            <IonSelectOption key={name} value={name}>
                                {name}
                            </IonSelectOption>
                        );
                    })}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel position="stacked" className={styles.larger_label}>
                    Audit Type
                </IonLabel>
                <IonSelect
                    value={dto.auditType}
                    onIonChange={(e) => {
                        const value = e.detail.value + '';
                        if (isNullOrUndefined(value)) {
                            return;
                        }

                        updateAuditType(e.detail.value);
                    }}>
                    {Object.entries(AuditType)
                        .filter(([key, value]) => isNaN(parseInt(key)))
                        .map(([key, value]) => (
                            <IonSelectOption value={value} key={value}>
                                {key}
                            </IonSelectOption>
                        ))}
                </IonSelect>
            </IonItem>

            <IonGrid>
                <IonRow>
                    <IonCol>Changed Date</IonCol>
                    <IonCol>Employee</IonCol>
                    <IonCol>Show Details</IonCol>
                </IonRow>
                {isNotNullNorUndefined(auditLogDtoArray) &&
                    auditLogDtoArray.length > 0 &&
                    auditLogDtoArray.map((auditLogDto) => (
                        <IonRow key={auditLogDto.changedDate}>
                            <IonCol>
                                <IonInput
                                    readonly={true}
                                    style={{ textAlign: 'center' }}
                                    value={auditLogDto.changedDate}
                                />
                            </IonCol>
                            {/* <IonCol>
                                <IonLabel>Details</IonLabel>
                                <IonLabel>{auditLogDto.details}</IonLabel>
                            </IonCol> */}
                            <IonCol>
                                <IonInput
                                    readonly={true}
                                    style={{ textAlign: 'center' }}
                                    value={auditLogDto.employee}
                                />
                            </IonCol>
                            <IonCol>
                                {/* <AuditLogContainer
                                    tableName={dto.tableName as POSSIBLE_AUDIT_LOG_TABLE_NAMES_TYPE}
                                    primaryKey={dto.primaryKey}
                                    isAdmin={false}
                                    booleanToggleToIndicateToUpdateAuditLog={false}
                                    useModal={true}
                                /> */}
                                <>
                                    <IonModal
                                        // style={{ position: 'relative', zIndex: '9999' }}
                                        isOpen={
                                            showAdminAuditLogDetailsModalDictionary[auditLogDto.changedDate] === true
                                        }
                                        onDidDismiss={() => updateShowModalDict(auditLogDto.changedDate, false)}>
                                        <ModalCloseButton
                                            setModalHideFunction={updateShowModalDict.bind(
                                                null,
                                                auditLogDto.changedDate,
                                                false
                                            )}
                                        />
                                        <CollapsibleSection<AuditDetail>
                                            records={auditLogDto.details}
                                            identifier={'columnName'}
                                            labelStrip={(record) => <div>{record.columnName}</div>}
                                            // This props for this element will be provided later
                                            // Adding autogenerated properties for now
                                            content={() => (
                                                <AuditLogCollapsibleContent record={new AuditDetail()} index={0} />
                                            )}
                                            startingValue={undefined}
                                            focusedEntry={focusedEntry}
                                            setFocusedEntry={setFocusedEntry}
                                        />
                                    </IonModal>
                                </>
                                <IonButton onClick={() => updateShowModalDict(auditLogDto.changedDate, true)}>
                                    Show Audit Button
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    ))}
            </IonGrid>
            <div className={styles.pagination_container}>
                <IonButton disabled={dto.options.pageNumber <= 1} onClick={() => handlePreviousButtonHit()}>
                    Previous
                </IonButton>
                <IonInput
                    value={dto.options.pageNumber}
                    onIonChange={(e) => isNotNullNorUndefined(e.detail.value) && updatePageNumber(+e.detail.value)}
                    debounce={1000}
                    style={{ textAlign: 'center' }}
                />
                <IonButton onClick={() => handleNextButtonHit()}>Next</IonButton>
            </div>
            {/* <IonButton onClick={handleUpdatingTable}>Test Get Audit Logs</IonButton> */}
        </div>
    );
};

export default AdminPanelAuditLog;
