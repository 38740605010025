import { IonChip, IonCol, IonIcon, IonModal, IonRow } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';
import { useState } from 'react';
import GeneralToolTip from '../../components/GeneralToolTip/GeneralToolTip';
import ModalCloseButton from '../../components/ModalCloseButton/ModalCloseButton';
import SortManagerHookComp from '../../components/SortManagerHookComp/SortManagerHookComp';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { DynamicFilter } from '../../models/Dtos/OptionsDtos/DynamicFilter';
import { DynamicSort } from '../../models/Dtos/OptionsDtos/DynamicSort';
import { DtoType } from '../../models/Global';
import { GeneratedIdType, removeGeneratedId } from '../../utils/KeyIdUtils';
import InventoryFilterManagerHookComp from './InventoryFilterManagerHookComp/InventoryFilterManagerHookComp';
import styles from './useFilterSortModal.module.scss';

export const useFilterSortModal = <T, K extends object>(
    dtoType: DtoType,
    initialValue: T,
    modelToFilterSort: K,
    filtersProperty: keyof T,
    sortsProperty: keyof T
) => {
    //addGeneratedIdIfNoIdOrAddSpecifiedId
    //ObjWithGeneratedID<
    /* We may want to return two things

     * #1 We may want to return a modal that the consumer can choose

     * where to put it on their page

     * #2 We may want to return an updated DTO version

     * #3 We may want to return the show modal and methods for updating it
     */

    const [dto, setDto] = useState<T>(initialValue);

    const [showModal, setShowModal] = useState<boolean>(false);

    const [showingFilterManager, setShowingFilterManager] = useState<boolean>(true);

    const [valueFilters, setValueFilters] = useState<GeneratedIdType<DynamicFilter>[]>([]);

    const handleSetCustomFiltersValueFilters = (dynamicFilters: GeneratedIdType<DynamicFilter>[]) => {
        const dynamicFiltersWithGeneratedIdsRemoved = dynamicFilters.map((dynamicFilter) =>
            removeGeneratedId(dynamicFilter)
        );

        setDto((oldDto) => {
            return { ...oldDto, [filtersProperty]: dynamicFiltersWithGeneratedIdsRemoved };
        });
    };

    const setSortFilters = (sortFilters: DynamicSort[]) => {
        setDto((oldDto) => {
            return { ...oldDto, options: sortFilters };
        });
    };

    const getTargetFilterManagerHookCompBasedOnDtoType = (dtoType: DtoType) => {
        const getPropsObject = <T,>() => ({
            valueFilters,
            handleSetCustomFiltersValueFilters,
            model: modelToFilterSort as unknown as T,
            setShowModal,
            dtoType,
        });

        switch (dtoType) {
            case DtoType.Inventory: {
                return <InventoryFilterManagerHookComp {...getPropsObject<InventoryDto>()} />;
            }
            // case DtoType.AccountingTodo: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            // case DtoType.AdminAuditDto: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            // case DtoType.Delivery: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            // case DtoType.RecordAuditDto: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            // case DtoType.RecordOfTransfer: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            // case DtoType.SalesOrder: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            // case DtoType.YardMap: {
            //     //TODO: ADD FILTER MANAGER HOOK COMP
            //     return;
            // }
            default: {
                return <InventoryFilterManagerHookComp {...getPropsObject<InventoryDto>()} />;
            }
        }
    };

    const handleCloseModal = () => {
        //update state and then setShowModalFalse
        //and then setshowmodal flase.
    };

    const modalToReturn = (
        <>
            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <ModalCloseButton setModalHideFunction={() => setShowModal(false)} />
                <IonRow style={{ backgroundColor: '#002b70', marginLeft: '0px', marginRight: '0px' }}>
                    <IonCol style={{ position: 'relative', zIndex: '101' }}>
                        <GeneralToolTip content={['Opens filter control', 'Create and Remove Temporary Filters']}>
                            <IonChip
                                className={styles.firstChip}
                                onClick={() => {
                                    setShowingFilterManager(true);
                                }}>
                                Filters
                                <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                            </IonChip>
                        </GeneralToolTip>
                    </IonCol>
                    <IonCol style={{ position: 'relative', zIndex: '100' }}>
                        <GeneralToolTip content={['Opens sorting control', 'Sort columns and set sorting priority']}>
                            <IonChip
                                className={styles.secondChip}
                                onClick={() => {
                                    setShowingFilterManager(false);
                                }}>
                                Sorting
                                <IonIcon icon={informationCircleOutline} style={{ color: 'black' }}></IonIcon>
                            </IonChip>
                        </GeneralToolTip>
                    </IonCol>
                </IonRow>
                {showingFilterManager ? (
                    getTargetFilterManagerHookCompBasedOnDtoType(dtoType)
                ) : (
                    <SortManagerHookComp
                        setSortFilters={setSortFilters}
                        setShowModal={setShowModal}
                        propertyNames={Object.keys(modelToFilterSort)}
                    />
                )}
            </IonModal>
        </>
    );

    return { modalToReturn, dto, setDto, showModal, setShowModal };
};
