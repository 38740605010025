import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';

const excludeFromColumnsToShow: readonly (keyof DeliveryDto)[] = [
    'Id',
    'Created Date',
    'Yard Loc ID',
    'DOT Status',
] as const;

type KeyOfDeliveryArray = (keyof DeliveryDto)[];

export const filterDeliveryKeyArrayByExcludedColumns = (array: KeyOfDeliveryArray) => {
    const filteredArray = array.filter(filterDeliveryColumnMethod);
    return filteredArray;
};

export const isDeliveryPropertyExcluded = (property: keyof DeliveryDto) => {
    return excludeFromColumnsToShow.includes(property);
};

export const filterDeliveryColumnMethod = (column: string) => {
    return !excludeFromColumnsToShow.includes(column as keyof DeliveryDto);
};
