import InventoryTable from '../../components/NewTableApproach/InventoryTable/InventoryTable';
import QueriedTableSortFilterStateManager from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { GeneralOptions } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { SortOptions } from '../../models/Dtos/OptionsDtos/SortOptions';
import { DtoType, RecordType } from '../../models/Global';
import { InventoryService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { EndpointResponseType } from '../../utils/RecordTypeUtils';

interface InventoryPageQueriedTableProps {
    scrollTop: number;
}

export const InventoryPageQueriedTable = ({ scrollTop }: InventoryPageQueriedTableProps) => {
    return (
        <>
            <QueriedTableSortFilterStateManager<InventoryDto>
                scrollTop={scrollTop}
                identifier={'Stock'}
                identifierType={IdentifierType.Stock}
                toolbar={true}
                endpoint={
                    InventoryService.getInventory as (
                        options: GeneralOptions | SortOptions
                    ) => Promise<EndpointResponseType>
                }
                tabbed={true}
                justSortOptions={false}
                model={new InventoryDto()}
                recordType={RecordType.Inventory}
                dtoType={DtoType.Inventory}>
                {(exportProps) => <InventoryTable {...exportProps} />}
            </QueriedTableSortFilterStateManager>
        </>
    );
};
