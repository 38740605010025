import { DynamicFilter } from '../Dtos/OptionsDtos/DynamicFilter';
import { SortOptions } from '../Dtos/OptionsDtos/SortOptions';
import { AuditType } from '../Global';

class GetAuditDto {
    primaryKey: string = '';
    tableName: string = 'Inventory';
    auditType: AuditType = AuditType.None;

    /*
     * The backend has the filter property marked as required so even though one endpoint
     * doesn't use the field we still need to initialize it to be able to send a request
     * to both endpoints
     */
    filters: DynamicFilter[] = [];

    options: SortOptions = new SortOptions();

    constructor(obj?: GetAuditDto) {
        this.primaryKey = obj?.primaryKey ?? '';
        this.tableName = obj?.tableName ?? 'Inventory';
        this.auditType = obj?.auditType ?? AuditType.Update;
        this.filters = obj?.filters ?? [];
        this.options = obj?.options ?? new SortOptions();
    }
}

export default GetAuditDto;
