import { IonInputCustomEvent, IonSelectCustomEvent } from '@ionic/core';
import { IonCheckboxCustomEvent } from '@ionic/core/dist/types/components';
import {
    CheckboxChangeEventDetail,
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonTextarea,
    IonTitle,
    SelectChangeEventDetail,
} from '@ionic/react';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ConditionalLinkWrapper from '../../../components/ConditionalLinkWrapper/ConditionalLinkWrapper';
import GeneralToolTip from '../../../components/GeneralToolTip/GeneralToolTip';
import { RoleContext } from '../../../components/RoleContext/RoleContextComp';
import { EventTargetType, PossibleParameterTypes } from '../../../hooks/ObjectHook';
import { UrlPhotoIdWrapper } from '../../../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
import { DeliveryDto } from '../../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { IdentifierType } from '../../../utils/identifierType';
import { minMax } from '../../../utils/MinMaxUtil';
import { isNotNullNorUndefinedNorEmptyString, isNullOrUndefined } from '../../../utils/NullOrUndefined';
import { MakeMethodsOptional, RemoveMethods } from '../../../utils/TypeUtils';
import styles from './Delivery.module.scss';

type DeliveryProps = {
    record: DeliveryDto;
    updateDelivery: (record: DeliveryDto) => void;
    index: number;
    focusToggle?: string | null;
    resetFocusToggle?: () => void;
    booleanToggleToIndicateToUpdateAuditLog: boolean;
    photoUrls: UrlPhotoIdWrapper[];
    deleteDelivery: (id: string, stock: string) => void;
    lastDelivery: boolean;
};

type DeliveryObjectHookBindProps<T extends object> = {
    salesOrder: MakeMethodsOptional<SalesOrderDto>;
    setSalesOrder: React.Dispatch<React.SetStateAction<MakeMethodsOptional<SalesOrderDto>>>;
    bindIonSalesOrderSelect: (
        pathOrProperty: PossibleParameterTypes<T>,
        callBack?:
            | ((value: any) => {
                  [k: string]: any;
                  [k: number]: any;
                  [k: symbol]: any;
              })
            | undefined
    ) => {
        value: any;
        onIonChange: (event: IonSelectCustomEvent<SelectChangeEventDetail<any>>) => void;
    };

    bindIonSalesOrder: (pathOrProperty: PossibleParameterTypes<T>) => {
        // value: any;
        onIonInput: <Type extends EventTargetType>(event: Type) => any;
    };
    bindSalesOrderReactAsyncSelect: <K extends keyof T>(
        property: K,
        callBack?: Function | undefined
    ) => {
        value: any;
        onChange?: any;
    };
    bindIonSalesOrderDate: (pathOrProperty: PossibleParameterTypes<T>) => {
        value: any;
        onIonBlur: <Type extends EventTargetType>(event: IonInputCustomEvent<FocusEvent>) => void;
    };
    bindIonSalesOrderCheck: (pathOrProperty: PossibleParameterTypes<T>) => {
        checked: boolean;
        onIonChange: (e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => void;
    };

    updateSalesOrderProperty: <K extends keyof T>(
        property: K
    ) => <V extends T[K]>(data: V, callback?: ((arg0: T) => any) | undefined) => void;
};

export const Delivery: React.FC<DeliveryProps & DeliveryObjectHookBindProps<RemoveMethods<SalesOrderDto>>> = ({
    record: delivery,
    updateDelivery,
    focusToggle,
    resetFocusToggle,
    index,
    bindIonSalesOrderSelect,
    bindIonSalesOrder,
    bindSalesOrderReactAsyncSelect,
    bindIonSalesOrderDate,
    bindIonSalesOrderCheck,
    updateSalesOrderProperty,
    booleanToggleToIndicateToUpdateAuditLog,
    photoUrls,
    deleteDelivery,
    lastDelivery,
}) => {
    const history = useHistory();

    const [returnNothing, setReturnNothing] = useState<boolean>(false);

    const firstActiveInput = useRef<HTMLIonInputElement>(null);

    const roles = useContext(RoleContext);

    // const allFieldsReadOnly = !canUserAccessAccountingSecurityWithGivenRoles(roles);
    const allFieldsReadOnly = false;

    const [add, setAdd] = useState<string>(delivery.Address ?? '');

    // testing stuff

    const addressInputTestRef = useRef<HTMLIonInputElement | null>(null);

    const testF = useCallback((e: Event) => {
        const value = (e.target as HTMLInputElement).value;

        const parent = (e.target as HTMLInputElement).parentElement as HTMLIonInputElement;

        // alert('test' + value);
        setAdd(value + '');
    }, []);

    // useEffect(() => {
    //     const input = document.getElementById('testtestinput');

    //     if (isNullOrUndefined(input)) {
    //         return;
    //     }

    //     const test = testF;

    //     input.addEventListener('input', test);

    //     return () => {
    //         const secondInput = document.getElementById('testtestinput');

    //         if (isNullOrUndefined(secondInput)) {
    //             console.log('input null');
    //             return;
    //         }

    //         secondInput.removeEventListener('input', test);

    //         const t = input === secondInput;
    //         console.log('');
    //     };
    // }, []);

    if (returnNothing) {
        return <></>;
    }

    // 'Carrier Name': string; // not listed yet

    const handleRedirectToInventory = (stock: string) => {
        history.push({
            pathname: `/inventory/edit/${IdentifierType.Stock}/${stock}`,
        });
    };

    const handleRedirectToYardStall = (yardStall: string) => {
        history.push({
            pathname: `/yard-location/edit/${IdentifierType.YardStall}/${yardStall}`,
        });
    };

    const surroundDeleteButtonJSXWithGeneralToolTipIfLastDelivery = (lastDelivery: boolean) => {
        const deleteButtonJSX = (
            <IonButton
                disabled={lastDelivery}
                color="danger"
                onClick={() => deleteDelivery(delivery.Id, delivery.Stock)}>
                Delete
            </IonButton>
        );

        if (lastDelivery) {
            return (
                <GeneralToolTip content="You may not delete the last reminaing stock number from a sales order!">
                    {deleteButtonJSX}
                </GeneralToolTip>
            );
        } else {
            return deleteButtonJSX;
        }
    };
    const handleCallToDeliver = async (delivery: DeliveryDto) => {
        // const response = await DeliveryService.deliverRecord(delivery.Id);
        // const data = response.data.model;
        // updateDelivery(data);
    };

    return (
        <>
            <IonTitle>Delivery # {delivery != undefined ? delivery.Stock : ''}</IonTitle>

            <IonRow>
                <IonCol style={{ position: 'relative', zIndex: '2' }}>
                    {surroundDeleteButtonJSXWithGeneralToolTipIfLastDelivery(lastDelivery)}
                </IonCol>
            </IonRow>
            <IonRow className={styles.inputRow}>
                <IonCol className={styles.inputCol} sizeXl={'4'} sizeLg={'4'} sizeMd={'4'} sizeSm={'4'} sizeXs="4">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Stock Number</IonLabel>
                        <ConditionalLinkWrapper
                            to={`/inventory/edit/${IdentifierType.Stock}/${delivery.Stock}`}
                            conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(delivery.Stock)}>
                            <IonInput
                                className={`${styles.stockNumberInput}`}
                                // disabled={allFieldsReadOnly}
                                {...bindIonSalesOrder({
                                    path: 'Deliveries[#].Stock',
                                    pathCorrespondingArrayAccessIndicesArray: [index],
                                })}
                                type="text"
                                readonly
                            />
                        </ConditionalLinkWrapper>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} sizeXl={'3'} sizeLg={'3'} sizeMd={'3'} sizeSm={'4'} sizeXs="4">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Delivered Date</IonLabel>
                        <IonInput
                            className={`${
                                isNullOrUndefined(delivery['Delivered Date']) ||
                                delivery['Delivered Date'].trim() === ''
                                    ? styles.dateInputEmpty
                                    : ''
                            }`}
                            onIonFocus={(e) => (e.target.style.opacity = '1')}
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrderDate({
                                path: 'Deliveries[#].Delivered Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="date"
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>
                <IonCol
                    className={`${styles.inputCol} ${styles.yardLocInput}`}
                    sizeXl={'5'}
                    sizeLg={'5'}
                    sizeMd="5"
                    sizeSm="4"
                    sizeXs="4">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Yard Loc</IonLabel>
                        <ConditionalLinkWrapper
                            to={`/yard-location/edit/${IdentifierType.YardStall}/${delivery['Yard Loc']}`}
                            conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(delivery['Yard Loc'])}>
                            <IonInput
                                disabled={allFieldsReadOnly}
                                readonly
                                {...bindIonSalesOrder({
                                    path: 'Deliveries[#].Yard Loc',
                                    pathCorrespondingArrayAccessIndicesArray: [index],
                                })}
                                type="text"
                            />
                        </ConditionalLinkWrapper>
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol} sizeXl={'3'} sizeLg={'3'} sizeMd={'12'} sizeSm="12" sizeXs="12">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">VIN</IonLabel>
                        <IonInput
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].VIN',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                            disabled={true}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} sizeXl={'1'} sizeLg={'1'} sizeMd={'2'} sizeSm="2" sizeXs="2">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Year</IonLabel>
                        <IonInput
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Year',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            disabled={true}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} sizeXl={'4'} sizeLg={'4'} sizeMd={'5'} sizeSm={'5'} sizeXs="5">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Make</IonLabel>
                        <IonInput
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Make',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            disabled={true}
                            type="text"
                        />
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol} sizeXl={'4'} sizeLg={'4'} sizeMd={'5'} sizeSm={'5'} sizeXs="5">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Inventory Type</IonLabel>
                        <IonInput
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Inventory Type',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                            disabled={true}
                        />
                    </IonItem>
                </IonCol>
                <IonCol
                    size={'12'}
                    className={`${styles.inputCol} ${styles.notesInputCol}`}
                    sizeLg={'12'}
                    sizeMd={'12'}
                    sizeSm="12"
                    sizeXl="12">
                    <IonItem fill="solid">
                        <IonLabel position={'stacked'}>Notes</IonLabel>
                        <IonTextarea
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Notes - D',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol} sizeXl={'6'} sizeLg={'6'} sizeMd={'6'} sizeSm="6" sizeXs="6">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Temp Tag Expiration</IonLabel>
                        <IonInput
                            type="date"
                            className={`${
                                isNullOrUndefined(delivery['Temp Tag Expiration']) ||
                                delivery['Temp Tag Expiration'].trim() === ''
                                    ? styles.dateInputEmpty
                                    : ''
                            }`}
                            onIonFocus={(e) => (e.target.style.opacity = '1')}
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrderDate({
                                path: 'Deliveries[#].Temp Tag Expiration',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol} sizeXl={'6'} sizeLg={'6'} sizeMd={'6'} sizeSm="6" sizeXs="6">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Permit Number</IonLabel>
                        <IonInput
                            ref={firstActiveInput}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Permit Number',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            disabled={allFieldsReadOnly}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol
                    className={styles.inputCol}
                    size={'12'}
                    sizeXl={'12'}
                    sizeLg={'12'}
                    sizeMd={'12'}
                    sizeSm="12"
                    sizeXs="12">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Receipt Number</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Receipt Number',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol} sizeXl={'4'} sizeLg={'4'} sizeMd={'4'} sizeSm={'4'} sizeXs="4">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Carrier Name</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Carrier Name',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} sizeXl={'4'} sizeLg={'4'} sizeMd={'4'} sizeSm={'4'} sizeXs="4">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Carrier Phone</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Carrier Phone',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} sizeXl={'4'} sizeLg={'4'} sizeMd={'4'} sizeSm={'4'} sizeXs="4">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Carrier Truck Number</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Carrier Truck Number',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} size={'6'} sizeLg="6" sizeXl="6" sizeMd="6" sizeSm="6" sizeXs="6">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Driver First Name</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Driver First Name',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} size={'6'} sizeLg="6" sizeXl="6" sizeMd="6" sizeSm="6" sizeXs="6">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Driver Last Name</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Driver Last Name',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                {(() => {
                    // const stuff = {
                    //     onIonBlur: function (e: IonInputCustomEvent<FocusEvent>) {
                    //         console.log('TEST');
                    //     },
                    // };
                    const test = delivery.Address;

                    return (
                        <IonCol
                            size={'12'}
                            className={styles.inputCol}
                            sizeXl={'12'}
                            sizeLg="12"
                            sizeMd="12"
                            sizeSm="12"
                            sizeXs="12">
                            <IonItem fill="solid">
                                <IonLabel position="stacked">Address</IonLabel>
                                <IonInput
                                    id="testtestinput"
                                    disabled={allFieldsReadOnly}
                                    // value={delivery.Address}
                                    {...bindIonSalesOrder({
                                        path: 'Deliveries[#].Address',
                                        pathCorrespondingArrayAccessIndicesArray: [index],
                                    })}
                                    type="text"
                                />
                            </IonItem>
                        </IonCol>
                    );
                })()}
                <IonCol className={styles.inputCol} sizeXl={'5'} sizeLg="5" sizeMd="4" sizeSm="9" sizeXs="9">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">City</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].City',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol} sizeXl={'2'} sizeLg="2" sizeMd="3" sizeSm="3" sizeXs="3">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">State</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].State',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol} sizeXl={'2'} sizeLg="2" sizeMd="3" sizeSm="8" sizeXs="8">
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Zip</IonLabel>
                        <IonInput
                            disabled={allFieldsReadOnly}
                            {...bindIonSalesOrder({
                                path: 'Deliveries[#].Zip',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            type="text"
                            //
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
        </>
    );
};
