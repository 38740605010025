import cloneDeep from 'lodash.clonedeep';
import { AllAccountingTodoKeysArray } from '../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { AllInventoryKeysArray } from '../models/Dtos/InventoryDtos/InventoryDto';
import { AllRecordOfTransferKeysArray } from '../models/Dtos/RecordOfTransferDtos/RecordOfTransferDto';
import { AllDeliveryKeysArray } from '../models/Dtos/SalesOrderDtos/DeliveryDto';
import { AllSalesOrderKeysArray } from '../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { AllYardMapKeysArray } from '../models/Dtos/YardMapDtos/YardMapDto';
import { RecordType } from '../models/Global';
import { getColumnsToIgnoreFromRecordType } from './ColumnsToIgnoreMapper';

export const MakeANewArrayEnsuringAllColumnsArePresentForProvidedRecordType = (
    array: string[],
    recordType: RecordType
) => {
    let allColumnsForRecordType = AllColumnsRecordTypeMapper(recordType);

    const toIgnoreFunc = getColumnsToIgnoreFromRecordType(recordType);

    allColumnsForRecordType = toIgnoreFunc(allColumnsForRecordType);

    const updatedList = cloneDeep(array);

    allColumnsForRecordType.forEach((columnName) => {
        const matchingStringInArray = array.includes(columnName);

        if (matchingStringInArray) {
            // if it's in the array, don't do anything. Just leave it aone.
            return;
        }

        // It's not in array, add it.

        updatedList.push(columnName);

        return updatedList;
    });

    return updatedList;
};

const AllColumnsRecordTypeMapper = (recordType: RecordType) => {
    let allColumns: null | string[] = null;

    switch (recordType) {
        case RecordType.Inventory: {
            allColumns = AllInventoryKeysArray;
            break;
        }
        case RecordType.SalesOrder: {
            allColumns = AllSalesOrderKeysArray;
            break;
        }
        case RecordType.Delivery: {
            allColumns = AllDeliveryKeysArray;
            break;
        }
        case RecordType.YardMap: {
            allColumns = AllYardMapKeysArray;
            break;
        }
        case RecordType.AccountingTodo: {
            allColumns = AllAccountingTodoKeysArray;
            break;
        }
        case RecordType.RecordOfTransfer: {
            allColumns = AllRecordOfTransferKeysArray;
            break;
        }
        default: {
            allColumns = AllInventoryKeysArray;
            break;
        }
    }
    return allColumns;
};

export default AllColumnsRecordTypeMapper;
