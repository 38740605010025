import { ChangeEvent } from 'react';
import { isNotNullNorUndefined } from './NullOrUndefined';

// Making event undefined for now because might not need it
const confirmingTableUpdate = (event?: Event | ChangeEvent) => {
    const reallyWantTo = window.confirm('Do you really want to update this field?');

    if (!reallyWantTo && isNotNullNorUndefined(event)) {
        event.preventDefault();
    }

    return reallyWantTo;
};

export default confirmingTableUpdate;
