import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { DataType } from '../../models/Global';

export const getDataTypeFromDeliveryPropertyName = (property: keyof DeliveryDto): DataType => {
    switch (property) {
        case 'Stock': {
            return DataType.String;
        }

        case 'Inventory Number': {
            return DataType.String;
        }

        case 'VIN': {
            return DataType.String;
        }

        case 'Spec': {
            return DataType.String;
        }

        case 'Price': {
            return DataType.Int;
        }

        case 'Comp Date': {
            return DataType.DateTime;
        }

        case 'Inspection Date': {
            return DataType.DateTime;
        }

        case 'Received Date': {
            return DataType.DateTime;
        }

        case 'Advertise': {
            return DataType.Bool;
        }

        case 'DOT Status': {
            return DataType.Bool;
        }

        case 'Decal Id': {
            return DataType.String;
        }

        case 'City Location': {
            return DataType.String;
        }

        case 'Misc Date': {
            return DataType.DateTime;
        }

        case 'Cost': {
            return DataType.Int;
        }

        case 'Sub Type': {
            return DataType.Enum;
        }

        case 'Inventory Type': {
            return DataType.String;
        }

        case 'Year': {
            return DataType.Int;
        }

        case 'Make': {
            return DataType.String;
        }

        case 'Model': {
            return DataType.String;
        }

        case 'Color': {
            return DataType.String;
        }

        case 'Condition': {
            return DataType.Enum;
        }

        case 'Status - I': {
            return DataType.Enum;
        }

        case 'L': {
            return DataType.String;
        }

        case 'W': {
            return DataType.String;
        }

        case 'H': {
            return DataType.String;
        }

        case 'Wheel Sz': {
            return DataType.String;
        }

        case 'Wheel Type': {
            return DataType.String;
        }

        case 'Axles': {
            return DataType.Int;
        }

        case 'Suspension Type': {
            return DataType.String;
        }

        case 'Suspension Style': {
            return DataType.String;
        }

        case 'KP': {
            return DataType.String;
        }

        case 'Floor Type': {
            return DataType.String;
        }

        case 'Rear Door Type': {
            return DataType.String;
        }

        case 'Side Doors': {
            return DataType.String;
        }

        case 'Reefer Serial Number': {
            return DataType.String;
        }

        case 'Reefer Engine Year': {
            return DataType.Int;
        }

        case 'Reefer Make': {
            return DataType.String;
        }

        case 'Reefer Model': {
            return DataType.String;
        }

        case 'Unit Hours': {
            return DataType.Int;
        }

        case 'Reefer Year': {
            return DataType.Int;
        }

        case 'Reefer Desc': {
            return DataType.String;
        }

        case 'Entered In CDK': {
            return DataType.DateTime;
        }

        case 'Temp Tag Expiration': {
            return DataType.DateTime;
        }

        case 'Ordered Date - I': {
            return DataType.DateTime;
        }

        case 'Notes - I': {
            return DataType.String;
        }

        case 'Photo Status': {
            return DataType.Enum;
        }

        case 'Split Deal - I': {
            return DataType.Bool;
        }

        case 'Invoice Number': {
            return DataType.String;
        }

        case 'Ordered Date - S': {
            return DataType.DateTime;
        }

        case 'Customer Name': {
            return DataType.String;
        }

        case 'Sales Person': {
            return DataType.String;
        }

        case 'Delivered Count': {
            return DataType.String;
        }

        case 'Notes - S': {
            return DataType.String;
        }

        case 'Deposit': {
            return DataType.Int;
        }

        case 'Customer #': {
            return DataType.String;
        }

        case 'Lienholder': {
            return DataType.String;
        }

        case 'Proof Of Payment Received': {
            return DataType.DateTime;
        }

        case 'Check Number - I': {
            return DataType.String;
        }

        case 'Check Number - S': {
            return DataType.String;
        }

        case 'Title Work Completed': {
            return DataType.DateTime;
        }

        case 'NAPP': {
            return DataType.Bool;
        }

        case 'Docs Signed': {
            return DataType.DateTime;
        }

        case 'Misc Tracking': {
            return DataType.String;
        }

        case 'All Delivered': {
            return DataType.Bool;
        }

        case 'Split Deal - S': {
            return DataType.Bool;
        }

        case 'Split Sales Person': {
            return DataType.String;
        }

        case 'Status - S': {
            return DataType.Enum;
        }

        case 'Back Office Notes': {
            return DataType.String;
        }

        case 'Id': {
            return DataType.String;
        }

        case 'Delivered Date': {
            return DataType.DateTime;
        }

        case 'Permit Number': {
            return DataType.String;
        }

        case 'Carrier Phone': {
            return DataType.String;
        }

        case 'Address': {
            return DataType.String;
        }

        case 'City': {
            return DataType.String;
        }

        case 'State': {
            return DataType.String;
        }

        case 'Zip': {
            return DataType.String;
        }

        case 'Carrier Name': {
            return DataType.String;
        }

        case 'Carrier Truck Number': {
            return DataType.String;
        }

        case 'Driver First Name': {
            return DataType.String;
        }

        case 'Driver Last Name': {
            return DataType.String;
        }

        case 'Notes - D': {
            return DataType.String;
        }

        case 'Accounting Status': {
            return DataType.Enum;
        }

        case 'Accounting Date': {
            return DataType.DateTime;
        }

        case 'Receipt Number': {
            return DataType.String;
        }

        case 'Yard Loc': {
            return DataType.String;
        }

        case 'Yard Loc ID': {
            return DataType.Int;
        }

        case 'Created Date': {
            return DataType.DateTime;
        }

        // case 'Stock': {
        //     return DataType.String;
        // }
        // case 'Delivered Date': {
        //     return DataType.DateTime;
        // }
        // case 'Permit Number': {
        //     return DataType.String;
        // }
        // case 'Make': {
        //     return DataType.String;
        // }
        // case 'Inventory Type': {
        //     return DataType.String;
        // }
        // case 'Year': {
        //     return DataType.Int;
        // }
        // case 'VIN': {
        //     return DataType.String;
        // }
        // case 'Temp Tag Expiration': {
        //     return DataType.DateTime;
        // }
        // case 'Carrier Phone': {
        //     return DataType.String;
        // }
        // case 'Address': {
        //     return DataType.String;
        // }
        // case 'City': {
        //     return DataType.String;
        // }
        // case 'State': {
        //     return DataType.String;
        // }
        // case 'Zip': {
        //     return DataType.String;
        // }
        // case 'Carrier Name': {
        //     return DataType.String;
        // }
        // case 'Carrier Truck Number': {
        //     return DataType.String;
        // }
        // case 'Driver First Name': {
        //     return DataType.String;
        // }
        // case 'Driver Last Name': {
        //     return DataType.String;
        // }
        // case 'Notes': {
        //     return DataType.String;
        // }
        // case 'Accounting Status': {
        //     return DataType.Enum;
        // }
        // case 'Accounting Date': {
        //     return DataType.DateTime;
        // }
        // case 'Receipt Number': {
        //     return DataType.String;
        // }
        // case 'Split Deal': {
        //     return DataType.Bool;
        // }
        // case 'Invoice Number': {
        //     return DataType.String;
        // }
        // case 'Yard Loc': {
        //     return DataType.String;
        // }
        // case 'Yard Loc ID': {
        //     return DataType.Int;
        // }
    }
};
