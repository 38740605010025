import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { DeliveryDto } from '../../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { SalesOrderService } from '../../../utils/apiServices';
import { IdentifierType } from '../../../utils/identifierType';
import { RemoveMethods } from '../../../utils/TypeUtils';

type InputApproachPropType = {
    salesOrderId: string;
    salesOrder: RemoveMethods<SalesOrderDto>;
    updateDeliveries: (delivery: DeliveryDto[]) => void;
    allFieldsReadOnly: boolean;
};

const InputAppraoch: React.FC<InputApproachPropType> = ({
    salesOrderId,
    updateDeliveries,
    salesOrder,
    allFieldsReadOnly,
}) => {
    const [startingStockNumber, setStartingStockNumber] = useState<string>('');
    const [stockNumberQuantity, setStockNumberQuantity] = useState<number>(0);

    const history = useHistory();

    const createRecordFromInputAppraoch = async (salesOrder: any) => {
        const arrayOfStockNumbers: string[] = [];

        for (let i = +startingStockNumber; i < +startingStockNumber + stockNumberQuantity; i++) {
            arrayOfStockNumbers.push(i + '');
        }

        const updatedSalesOrder = {
            ...salesOrder,
            Deliveries: arrayOfStockNumbers.map<DeliveryDto>((stockNumber) => {
                const deliveryDto = new DeliveryDto();

                deliveryDto.Stock = stockNumber + '';
                return deliveryDto;
            }),
            'Stock Numbers': arrayOfStockNumbers,
        };

        const data = (await SalesOrderService.createSalesOrder(updatedSalesOrder)).data;
        return data;
    };

    const handleSubmit = async (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        if (e == undefined) {
            return;
        }
        if (salesOrderId === '' || salesOrderId === '0' || salesOrderId == undefined) {
            const creatingResponse = await createRecordFromInputAppraoch(salesOrder);

            if (!creatingResponse.isSuccessful) {
                alert(creatingResponse.messageForUser);
                return;
            } else {
                history.push(`/sales-order/edit/${IdentifierType.Invoice}/${creatingResponse.model['Invoice Number']}`);
            }
        } else {
            const response = await SalesOrderService.addMultipleSalesOrders(
                salesOrderId,
                startingStockNumber,
                stockNumberQuantity
            );

            updateDeliveries(response.data.model);
        }
    };

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Starting Stock Number</IonLabel>
                            <IonInput
                                value={startingStockNumber}
                                type={'number'}
                                disabled={allFieldsReadOnly}
                                onIonChange={(e) => {
                                    if (e.detail == undefined || e.detail.value == undefined) {
                                        return;
                                    }

                                    setStartingStockNumber(e.detail.value + '');
                                }}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Stock Number Quantity</IonLabel>
                            <IonInput
                                value={stockNumberQuantity}
                                type={'number'}
                                disabled={allFieldsReadOnly}
                                onIonChange={(e) => {
                                    if (e.detail == undefined || e.detail.value == undefined) {
                                        return;
                                    }

                                    setStockNumberQuantity(+e.detail.value);
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonButton disabled={allFieldsReadOnly} onClick={handleSubmit}>
                            Add Delivery
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
};

export default InputAppraoch;
