import styles from './AccountingTodoSpecializedTableRender.module.scss';

import { useEffect, useMemo, useRef } from 'react';
import { ReturnAccountingToDoDto } from '../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { InventoryCondition } from '../../models/Global';
import { convertToInputDateFormat } from '../../utils/DateUtils';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import {
    SpecializedTableStandardHeaderStyle,
    SpecializedTableStandardIdentifierColumnStyle,
    SpecializedTableStandardTableDataStyle,
    SpecializedTableStandardTableTagStyle,
} from '../../utils/SpecializedTableStandardStylesAndElements';
import { CH_OFFSET, MIN_WIDTH_INPUT } from '../NewTableApproach/GeneralReference';

// Idea go through each value of the column and determine the biggest width. and then perhaps
// set the inputs to width 100% unless they match the biggest width. I'm not sure.

const generateInputStylesAndUpdateBiggestInputWidth = (valueToLookAt: string, columnName: string) => {
    let width = 0;
    if (isNotNullNorUndefined(valueToLookAt) && valueToLookAt !== '' && valueToLookAt !== 'null') {
        width = valueToLookAt.length;
    }
    return { width: width + CH_OFFSET + 'ch', minWidth: MIN_WIDTH_INPUT };
};
const savedFunctionsToUpdateData = {
    Stock: (property: keyof ReturnAccountingToDoDto, Stock: string | number, stock: string, index: number) => (
        <td
            style={{
                ...SpecializedTableStandardTableDataStyle(index),
                ...SpecializedTableStandardIdentifierColumnStyle,
            }}
            key={`${stock}-${property}`}>
            <p>{Stock}</p>
        </td>
    ),
    'Invoice Number': (
        property: keyof ReturnAccountingToDoDto,
        InvoiceNumber: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{InvoiceNumber}</p>
        </td>
    ),

    'Customer Number': (
        property: keyof ReturnAccountingToDoDto,
        Number: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Number}</p>
        </td>
    ),

    'Customer Name': (
        property: keyof ReturnAccountingToDoDto,
        CustomerName: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{CustomerName}</p>
        </td>
    ),
    'Sales Order Notes': (
        property: keyof ReturnAccountingToDoDto,
        Notes: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Notes}</p>
        </td>
    ),
    'Title Work Completed': (
        property: keyof ReturnAccountingToDoDto,
        TitleWorkCompleted: string,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{convertToInputDateFormat(TitleWorkCompleted)}</p>
        </td>
    ),
    'Delivered Date': (
        property: keyof ReturnAccountingToDoDto,
        DeliveredDate: string,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{convertToInputDateFormat(DeliveredDate)}</p>
        </td>
    ),
    'Sales Person': (
        property: keyof ReturnAccountingToDoDto,
        SalesPerson: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{SalesPerson}</p>
        </td>
    ),
    Make: (property: keyof ReturnAccountingToDoDto, Make: string | number, stock: string, index: number) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Make}</p>
        </td>
    ),
    'Inventory Type': (
        property: keyof ReturnAccountingToDoDto,
        Type: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{Type}</p>
        </td>
    ),
    'Inventory Condition': (
        property: keyof ReturnAccountingToDoDto,
        Condition: string | number,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{InventoryCondition[Condition as number]}</p>
        </td>
    ),

    'Split Deal': (
        property: keyof ReturnAccountingToDoDto,
        SplitDeal: string | boolean,
        stock: string,
        index: number
    ) => (
        <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
            <p>{SplitDeal === true ? 'True' : 'False'}</p>
        </td>
    ),
};

interface AccountingTodoSpecalizedTableRenderProps {
    data: ReturnAccountingToDoDto[];
    selectedRecords: string[];
    invisibleContainerDiv: HTMLDivElement;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
}

const AccountingTodoSpecalizedTableRender = ({
    data,
    selectedRecords,
    invisibleContainerDiv,
    computedCombinedFilters,
}: AccountingTodoSpecalizedTableRenderProps) => {
    const filteredData = useMemo(() => {
        return data.filter((dto) => selectedRecords.includes(dto.Stock));
    }, [data, selectedRecords]);

    const tableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        const handleCopy = async () => {
            if (isNullOrUndefined(tableRef.current)) {
                makeSimpleToast('Error while copying styles', 'Table div is undefined or null');
                console.error('Error while copying styles, table div is undefined or null');
                return;
            }

            try {
                // Copy the selected content to the clipboard using Clipboard API
                navigator.clipboard
                    .write([
                        new ClipboardItem({
                            'text/html': new Blob([tableRef.current.outerHTML], { type: 'text/html' }),
                        }),
                    ])

                    .then(() => {
                        console.log('Table copied to clipboard successfully!');

                        makeSimpleToast(
                            'Successfully copied specialized table!',
                            "You've copied the specialized version of the table for email."
                        );
                    })
                    .catch((err) => {
                        console.error('Unable to copy table to clipboard:', err);
                        makeSimpleToast(
                            'Error while trying to copy styles.',
                            'Page not focused. Please try again and try to keep the page focused for at least a second.'
                        );
                    });
            } catch (error) {
                console.error('Clipboard API is not supported in this browser:', error);
            }

            invisibleContainerDiv.remove();
        };
        handleCopy();
    }, [invisibleContainerDiv]);

    return (
        <div
            className={styles.AccountingTodoSpecializedTableRenderContainer}
            data-testid="AccountingTodoSpecializedTableRender">
            <table style={SpecializedTableStandardTableTagStyle} ref={tableRef}>
                <thead>
                    <tr>
                        {computedCombinedFilters.orderedColumnsToShow
                            .filter(
                                (column) => column.toLowerCase() !== 'select' && column.toLowerCase() !== 'complete'
                            )
                            .map((column) => (
                                <th
                                    style={{
                                        ...SpecializedTableStandardHeaderStyle,
                                        ...(column.toLowerCase() === 'stock'
                                            ? SpecializedTableStandardIdentifierColumnStyle
                                            : {}),
                                    }}
                                    key={column}>
                                    {column}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((dataDto, rowIndex) => {
                        return (
                            <tr key={dataDto.Stock}>
                                {computedCombinedFilters.orderedColumnsToShow
                                    .filter(
                                        (name) => name.toLowerCase() !== 'select' && name.toLowerCase() !== 'complete'
                                    )
                                    .map((columnName) => {
                                        return savedFunctionsToUpdateData[
                                            columnName as keyof typeof savedFunctionsToUpdateData
                                        ](
                                            columnName as keyof ReturnAccountingToDoDto,
                                            // Might need to revisit
                                            dataDto[columnName as keyof ReturnAccountingToDoDto] as any,
                                            dataDto.Stock,
                                            rowIndex
                                        );
                                    })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default AccountingTodoSpecalizedTableRender;
