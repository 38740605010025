import {
    IonButton,
    IonCol,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import GeneralToolTip from '../../../components/GeneralToolTip/GeneralToolTip';
import { useObject } from '../../../hooks/ObjectHook';
import { ReturnMessageOnlyDto } from '../../../models/Dtos/GeneralDtos/ReturnMessageOnlyDto';
import { TypeInteractionDto } from '../../../models/Dtos/TypeInteractionDto';
import { TypeService } from '../../../utils/apiServices';

export enum DbManagedTypes {
    WheelType,
    RearDoorType,
    SuspensionType,
    SuspensionStyle,
    FloorType,
    InventoryType,
    Length,
    Width,
    Height,
    Make,
    Model,
    WheelSize,
    ReeferMake,
    ReeferModel,
    CityLocation,
}

export enum DbManagedTypesActions {
    CreateTemporary,
    CreatePermanent,
    UpdateName,
    ChangeTypeToPermanent,
    ChangeTypeAndDelete,
}

enum InputsToDisplay {
    SingleInput,
    DoubleSelect,
    SingleSelect,
    SelectWithInput,
}

type InputsToDisplayMap = {
    [key in keyof typeof DbManagedTypesActions]: InputsToDisplay;
};

const INPUTS_TO_DISPLAY_MAP: InputsToDisplayMap = {
    CreateTemporary: InputsToDisplay.SingleInput, //         Original: <NameOfNewTempType>,  NewName: None
    CreatePermanent: InputsToDisplay.SingleInput, //         Original: <NameOfNewPermType>,  NewName: None
    UpdateName: InputsToDisplay.SelectWithInput, //          Original: <NameOfExisitingType>,   NewName: <NewNameToApply>
    ChangeTypeToPermanent: InputsToDisplay.SingleSelect, //  Original: None, NewName: <NameOfTypeToUpgradeToPermanent>
    ChangeTypeAndDelete: InputsToDisplay.DoubleSelect, //    Original: <TypeToBeDeleted>, NewName: <TypeToChangeTo>
};

export const TypeManager = () => {
    // Actions
    // Type
    // Submit

    const {
        value: dto,
        setValue: setDto,
        bindIonSelectProperty: bindSelectDto,
        bindIonInputProperty: bindInputDto,
        updateProperty: updateDto,
    } = useObject<TypeInteractionDto>(new TypeInteractionDto());

    const [numberOfInputsNeeded, setNumberOfInputsNeeded] = useState<number>(0);

    const [selectedAction, setSelectedAction] = useState<DbManagedTypesActions>();

    const [permanentTypeList, setPermanentTypeList] = useState<string[]>([]);
    const [nonPermanentTypeList, setNonPermanentTypeList] = useState<string[]>([]);

    const [showPermanentListInputOne, setShowPermanentListInputOne] = useState<boolean>(false);
    const [showPermanentListInputTwo, setShowPermanentListInputTwo] = useState<boolean>(true);

    const handleSubmit = async () => {
        let functionToCall: (dto: TypeInteractionDto) => Promise<AxiosResponse<ReturnMessageOnlyDto, any>>;

        switch (selectedAction) {
            case DbManagedTypesActions.CreateTemporary:
                functionToCall = TypeService.CreateNonPermanent;
                break;
            case DbManagedTypesActions.CreatePermanent:
                functionToCall = TypeService.CreatePermanent;
                break;
            case DbManagedTypesActions.UpdateName:
                functionToCall = TypeService.UpdateName;
                break;

            case DbManagedTypesActions.ChangeTypeToPermanent:
                functionToCall = TypeService.ChangeToPermanent;
                break;
            case DbManagedTypesActions.ChangeTypeAndDelete:
                functionToCall = TypeService.ChangeAndDelete;
                break;
            default:
                functionToCall = TypeService.CreateNonPermanent;
        }

        const response = await functionToCall(dto);
    };

    useEffect(() => {
        // if any changes set values to empty string. Helps ensure correct logic and avoid mistakes

        // Using a setDto instead of two update Property Dto's as the second call to update property will have stale
        // Information regarding the first update and will overwrite it with it's old value
        setDto({ ...dto, newName: '', originalName: '' });

        // updateDto('newName')('');
        // updateDto('originalName')('');
    }, [dto.dbManagedType, selectedAction]);

    useEffect(() => {
        const handlePopulatingLists = async () => {
            if (dto.dbManagedType != undefined) {
                const permTypesResponse = await TypeService.GetPermanentTypes(dto);
                const permTypes = permTypesResponse.data.model;
                setPermanentTypeList(permTypes);

                const nonPermTypesResponse = await TypeService.GetNonPermanentTypes(dto);
                const nonPermTypes = nonPermTypesResponse.data.model;
                setNonPermanentTypeList(nonPermTypes);

                // call get input for permanent and nonpermanent type and pass back dto
            } else {
                setPermanentTypeList([]);
                setNonPermanentTypeList([]);
            }
        };
        handlePopulatingLists();
    }, [dto.dbManagedType]);

    useEffect(() => {
        if (selectedAction == undefined) {
            return;
        }

        if (selectedAction != DbManagedTypesActions.ChangeTypeAndDelete) {
            setNumberOfInputsNeeded(1);
        } else setNumberOfInputsNeeded(2);
    }, [selectedAction]);

    const enumHelper = (value: any): value is keyof typeof DbManagedTypesActions => {
        return DbManagedTypesActions[value] != undefined;
    };

    const doesActionUseInputsToDisplaySetup = (
        inputsToDisplay: InputsToDisplay,
        action: DbManagedTypesActions | undefined = selectedAction
    ) => {
        const isActionValidEnumString = enumHelper(action);

        if (!isActionValidEnumString) {
            console.error('Provided action in doesActionUseINputsTODisplaySetup does not match enum!');
            return;
        }
        const enumStringName = DbManagedTypesActions[action];

        const response =
            INPUTS_TO_DISPLAY_MAP[enumStringName as keyof typeof DbManagedTypesActions] === inputsToDisplay;

        return response;
    };

    return (
        <IonGrid>
            <form>
                {/*
                    CreateTemporary,
    CreatePermanent,
    UpdateName,
    ChangeTypeToPermanent,
    ChangeTypeAndDelete,
                
                 */}
                <IonRow style={{ position: 'relative', zIndex: 100 }}>
                    <IonCol>
                        <p>Temporary Vs Permanent Type</p>
                        <GeneralToolTip
                            content={[
                                'A temporary type is one that is created either manually',
                                "or when an import's value is different than the previous types",
                                "These are basically types that aren't stored already",
                                'If they were that way because of a spelling mistake you can replace that type',
                                'With a new name.',
                            ]}
                        />
                    </IonCol>
                    <IonCol>
                        <p>What can I do here?</p>
                        <GeneralToolTip
                            content={[
                                'You can create/update temporary and permanent types.',
                                'You can also replace a temporary type to an existing permanent type',
                            ]}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Select Type</IonLabel>
                            <IonSelect placeholder="" onIonChange={(e) => updateDto('dbManagedType')(e.target.value)}>
                                {Object.entries(DbManagedTypes)
                                    .filter(([key, value]) => isNaN(parseInt(key)))
                                    .map(([key, value]) => (
                                        <IonSelectOption key={key} value={value}>
                                            {key}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem>
                            <IonLabel>Select Action</IonLabel>
                            <IonSelect placeholder="" onIonChange={(e) => setSelectedAction(e.target.value)}>
                                {Object.entries(DbManagedTypesActions)
                                    .filter(([key, value]) => isNaN(parseInt(key)))
                                    .map(([key, value]) => (
                                        <IonSelectOption key={key} value={value}>
                                            {key}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    {/* Original Name - Select Version */}
                    {selectedAction == undefined
                        ? true
                        : (doesActionUseInputsToDisplaySetup(InputsToDisplay.DoubleSelect) ||
                              doesActionUseInputsToDisplaySetup(InputsToDisplay.SelectWithInput)) && (
                              <IonCol>
                                  <IonItem>
                                      <IonButton
                                          type="button"
                                          color={showPermanentListInputOne ? 'primary' : 'secondary'}
                                          onClick={() => setShowPermanentListInputOne(!showPermanentListInputOne)}>
                                          {showPermanentListInputOne
                                              ? 'Showing Permanent Types'
                                              : 'Showing Non-Permanent Types'}
                                      </IonButton>
                                  </IonItem>
                                  <IonItem>
                                      <IonLabel>Original Name</IonLabel>
                                      {showPermanentListInputOne ? (
                                          <IonSelect {...bindSelectDto('originalName')}>
                                              {permanentTypeList.map((typeName) => (
                                                  <IonSelectOption key={typeName} value={typeName}>
                                                      {typeName}
                                                  </IonSelectOption>
                                              ))}
                                          </IonSelect>
                                      ) : (
                                          <IonSelect {...bindSelectDto('originalName')}>
                                              {nonPermanentTypeList.map((typeName) => (
                                                  <IonSelectOption key={typeName} value={typeName}>
                                                      {typeName}
                                                  </IonSelectOption>
                                              ))}
                                          </IonSelect>
                                      )}
                                  </IonItem>
                              </IonCol>
                          )}
                    {/* End Original Name - Select Verion */}

                    {/* Original Name - Input Version */}

                    {selectedAction == undefined
                        ? true
                        : doesActionUseInputsToDisplaySetup(InputsToDisplay.SingleInput) && (
                              <IonCol>
                                  <IonItem>
                                      <IonLabel>Original Name</IonLabel>
                                      <IonInput type="text" {...bindInputDto('originalName')} />
                                  </IonItem>
                              </IonCol>
                          )}
                    {/* End Original Name - Input Version */}

                    {/* New Name - Select Version */}

                    {selectedAction == undefined
                        ? true
                        : (doesActionUseInputsToDisplaySetup(InputsToDisplay.SingleSelect) ||
                              doesActionUseInputsToDisplaySetup(InputsToDisplay.DoubleSelect)) && (
                              <IonCol>
                                  {selectedAction != DbManagedTypesActions.ChangeTypeToPermanent && (
                                      <IonItem>
                                          <IonButton
                                              type="button"
                                              color={showPermanentListInputTwo ? 'primary' : 'secondary'}
                                              onClick={() => setShowPermanentListInputTwo(!showPermanentListInputTwo)}>
                                              {showPermanentListInputTwo
                                                  ? 'Showing Permanent Types'
                                                  : 'Showing Non-Permanent Types'}
                                          </IonButton>
                                      </IonItem>
                                  )}
                                  <IonItem>
                                      <IonLabel>New Name</IonLabel>
                                      {showPermanentListInputTwo &&
                                      selectedAction != DbManagedTypesActions.ChangeTypeToPermanent ? (
                                          <IonSelect {...bindSelectDto('newName')}>
                                              {permanentTypeList.map((typeName) => (
                                                  <IonSelectOption key={typeName} value={typeName}>
                                                      {typeName}
                                                  </IonSelectOption>
                                              ))}
                                          </IonSelect>
                                      ) : (
                                          <IonSelect {...bindSelectDto('newName')}>
                                              {nonPermanentTypeList.map((typeName) => (
                                                  <IonSelectOption key={typeName} value={typeName}>
                                                      {typeName}
                                                  </IonSelectOption>
                                              ))}
                                          </IonSelect>
                                      )}
                                  </IonItem>
                              </IonCol>
                          )}
                    {/* End New Name - Select Version */}
                    {/* New Name - Input Version */}
                    {selectedAction == undefined
                        ? true
                        : doesActionUseInputsToDisplaySetup(InputsToDisplay.SelectWithInput) && (
                              <IonCol>
                                  <IonItem>
                                      <IonLabel>New Name</IonLabel>
                                      <IonInput type="text" {...bindInputDto('newName')} />
                                  </IonItem>
                              </IonCol>
                          )}
                    {/* End New Name - Input Version */}
                </IonRow>
                {/* <IonRow hidden={numberOfInputsNeeded == 1 ? false : true}>
                    <IonCol>
                        <IonItem>
                            <IonLabel>New Type Name</IonLabel>
                            <IonInput type="text" {...bindInputDto('newName')} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow hidden={numberOfInputsNeeded == 1 ? false : true}>
                    <IonCol>
                        <IonItem>
                            <IonLabel>New Type Name</IonLabel>
                            <IonInput type="text" {...bindInputDto('newName')} />
                        </IonItem>
                    </IonCol>
                </IonRow> */}
                <IonRow>
                    <IonCol>
                        <IonButton onClick={handleSubmit}>Submit</IonButton>
                    </IonCol>
                </IonRow>
            </form>
        </IonGrid>
    );
};
