import {
    IonButton,
    IonCol,
    IonHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonTextarea,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import React, { useState } from 'react';
import { ImportOverview, ImportReport, ReportDetail } from '../../models/Dtos/importDto';
import { InventoryService } from '../../utils/apiServices';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import CollapsibleSection from '../CollapsibleSection/CollapsibleSection';
import './ImportReport.css';

export type PropType = {
    data?: ImportOverview;
    showReportModal: boolean;
    setShowReportModal: (showReportModal: boolean) => void;
};

const ImportReportModal: React.FC<PropType> = ({ data, showReportModal, setShowReportModal }) => {
    const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

    if (isNullOrUndefined(data)) {
        console.info('No data was provided to ImportReportModal. Skipping Rendering');
        return <div></div>;
    }

    const reportOverview = data;

    const closeModal = (): void => {
        setShowReportModal(false);
    };
    const reverseImport = () => {
        InventoryService.ReverseImport(reportOverview.id)
            .then(({ data }) => {
                if (data.isSuccessful) {
                    // Display message
                    closeModal();
                } else {
                    // Display Failure message
                }
            })
            .catch((err) => console.error(err));
    };

    // return !showReportModal ? (
    //     <div></div>
    // ) : (

    return (
        <IonModal isOpen={showReportModal} onIonModalDidDismiss={() => setShowReportModal(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Report Overview</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonHeader>Totals</IonHeader>
            <IonRow>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Created</IonLabel>
                        <IonTextarea readonly={true} value={reportOverview.Created + ''} />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Updated</IonLabel>
                        <IonTextarea readonly={true} value={reportOverview.Updated + ''} />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Failed</IonLabel>
                        <IonTextarea readonly={true} value={reportOverview.Failed + ''} />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Needing Review</IonLabel>
                        <IonTextarea readonly={true} value={reportOverview['Review Required'] + ''} />
                    </IonItem>
                </IonCol>
                <IonCol>
                    <IonItem>
                        <IonLabel position="stacked">Grand Total</IonLabel>
                        <IonTextarea readonly={true} value={reportOverview['Grand Total'] + ''} />
                    </IonItem>
                </IonCol>
            </IonRow>
            <div style={{ overflowY: 'scroll' }}>
                <CollapsibleSection<ImportReport>
                    records={reportOverview.Reports}
                    identifier={'Stock'}
                    labelStrip={function (record: ImportReport): JSX.Element {
                        return (
                            <span>{`Stock # ${record.Stock} Failure: ${record.Failure} Total Errors: ${record['Total Errors']} Total Flagged Entries ${record['Total Flagged Entries']}`}</span>
                        );
                    }}
                    content={function (report: ImportReport): JSX.Element {
                        return (
                            <div>
                                <IonItem>
                                    <IonLabel>Stock</IonLabel>
                                    <IonTextarea readonly={true} value={report.Stock} />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Failure</IonLabel>
                                    <IonTextarea readonly={true} value={report.Failure + ''} />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Reason For Failure</IonLabel>
                                    <IonTextarea readonly={true} value={report['Failure Reason']} />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Total Errors</IonLabel>
                                    <IonTextarea readonly={true} value={report['Total Errors'] + ''} />
                                </IonItem>
                                <IonItem>
                                    <IonLabel>Total Flagged Entries</IonLabel>
                                    <IonTextarea readonly={true} value={report['Total Flagged Entries'] + ''} />
                                </IonItem>
                                {report.Details.length > 0 ? (
                                    <div>
                                        <CollapsibleSection<ReportDetail>
                                            records={report.Details}
                                            identifier={'Column'}
                                            labelStrip={function (record: ReportDetail): JSX.Element {
                                                return <span>{`Col: ${record.Column}`}</span>;
                                            }}
                                            content={function (detail: ReportDetail): JSX.Element {
                                                return (
                                                    <div>
                                                        <IonItem>
                                                            <IonLabel>Column</IonLabel>
                                                            <IonTextarea readonly={true} value={detail.Column} />
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>Flag Reason</IonLabel>
                                                            <IonTextarea readonly={true} value={detail.Reason} />
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>User Entered</IonLabel>
                                                            <IonTextarea readonly={true} value={detail['User Input']} />
                                                        </IonItem>
                                                        <IonItem>
                                                            <IonLabel>System Input</IonLabel>
                                                            <IonTextarea
                                                                readonly={true}
                                                                value={detail['System Entered']}
                                                            />
                                                        </IonItem>
                                                    </div>
                                                );
                                            }}
                                            startingValue={undefined}
                                            focusedEntry={focusedEntry}
                                            setFocusedEntry={setFocusedEntry}
                                        />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        );
                    }}
                    startingValue={undefined}
                    focusedEntry={focusedEntry}
                    setFocusedEntry={setFocusedEntry}
                />
            </div>
            <IonRow>
                <IonCol>
                    <IonButton onClick={reverseImport}>Reverse Import</IonButton>
                </IonCol>
                <IonCol>
                    <IonButton onClick={closeModal}>Close Report</IonButton>
                </IonCol>
            </IonRow>
        </IonModal>
    );
};

export default ImportReportModal;
