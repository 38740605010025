import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import GeneralToolTip from '../../../../components/GeneralToolTip/GeneralToolTip';
import { SearchForCustomer } from '../../../../components/Search';
import { StringDto } from '../../../../models/Dtos/GeneralDtos/StringDto';
import { TypeInteractionDto } from '../../../../models/Dtos/TypeInteractionDto';
import { CustomerService } from '../../../../utils/apiServices';
import { DbManagedTypes } from '../TypeManager';
import styles from './CustomerManager.module.scss';

interface CustomerManagerProps { }

enum ActionToTakeOnCustomer {
  UpdateName,
  UpdateIdentifier,
  ChangeCustomerAndDelete,
  Delete,
}

const CustomerManager: FC<CustomerManagerProps> = () => {
  const [customerName, setCustomerName] = useState<string>();
  const [customerNumber, setCustomerNumber] = useState<string>();
  const [customerId, setCustomerId] = useState<number>();

  const [currentActionToTakeOnCustomer, setCurrentActionToTakeOnCustomer] = useState<ActionToTakeOnCustomer>(
    ActionToTakeOnCustomer.UpdateName
  );

  const [ignoreNextCall, setIgnoreNextCall] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (ignoreNextCall) {
      setIgnoreNextCall(false);
      return;
    }
    if (customerName == 'Create Customer') {
      setShowModal(true);
      setCustomerName('');
      setIgnoreNextCall(true);
    }
  }, [customerName]);

  const handleModalClick = async () => {
    if (customerName == undefined) {
      alert('You must imput a customer name first!');
      return;
    }

    if (customerNumber == undefined) {
      throw new Error('Customer number is undefined!');
    }

    const response = await CustomerService.CreateCustomer({
      id: 0,
      name: customerName,
      identifier: customerNumber,
    });

    const data = response.data;

    setCustomerNumber(data.model.identifier);
    setCustomerName(data.model.name);

    // For some reason doing two update properties the second one uses
    // Stale information

    // updateSalesOrderProperty('Customer #')(data.model.identifier);
    // updateSalesOrderProperty('Customer Name')(data.model.name);

    setShowModal(false);
  };

  // Update Name State
  const [updateName, setUpdateName] = useState<string>();
  const handleSubmitUpdateName = async () => {
    if (updateName == undefined || updateName === '') {
      alert('Please ensure you have specified a update name!');
      return;
    }

    if (customerId == undefined) {
      throw new Error('Customer id is currently  undefined or null!!');
    }

    const response = await CustomerService.UpdateName(customerId, updateName);
  };

  // Update Identifier State
  const [updateIdentifier, setUpdateIdentifier] = useState<string>();
  const handleSubmitUpdateIdentifier = async () => {
    if (updateIdentifier == undefined || updateIdentifier === '') {
      alert('Please ensure you have specified an update name!');
      return;
    }

    if (customerId == undefined) {
      throw new Error('Customer id is currently undefined or null!');
    }

    const response = await CustomerService.UpdateIdentifier(customerId, updateIdentifier);
  };

  // Change Customer and Delete State
  const [customerNameToChangeTo, setCustomerNameToChangeTo] = useState<string>();
  const [customerNumberToChangeTo, setCustomerNumberToChangeTo] = useState<string>();
  const handleSubmitChangeCustomerAndDelete = async () => {
    if (customerNameToChangeTo == undefined || customerNameToChangeTo === '') {
      alert('Please ensure you have specified a Customer to change to!');
      return;
    }

    if (customerName == undefined || customerName === '') {
      alert('Please ensure you have selected a customer to modify');
      return;
    }

    if (customerId == undefined) {
      throw new Error('Customer id is currently undefined or null!');
    }

    const typeInteractionDto: TypeInteractionDto = {
      originalName: customerName,
      newName: customerNameToChangeTo,
      dbManagedType: DbManagedTypes.FloorType, // this doesn't matter backend won't look at it.
    };

    const resposne = await CustomerService.ChangeAndDelete(typeInteractionDto);
  };

  // Delete method

  const handleDelete = async () => {
    if (customerId == undefined) {
      alert('Ensure you have selected a customer to delete!');
      return;
    }

    const resposne = await CustomerService.Delete(customerId);
  };

  return (
    <div className={styles.CustomerManager} data-testid="CustomerManager">
      <IonGrid style={{ position: 'relative', zIndex: '9', overflowX: 'hidden', overflowY: 'auto' }}>
        <form>
          {/*

                        UpdateName,
                        UpdateIdentifier,
                        ChangeCustomerAndDelete,
                        Delete,
                    
                     */}
          <IonRow>
            <IonCol style={{ position: 'relative', zIndex: '100' }}>
              <p>What can I do here?</p>
              <GeneralToolTip
                content={[
                  'Update Customer Name',
                  'Update Customer Identifier',
                  'Change Customer1 into Customer2 and delete Customer1',
                  'Delete Customer',
                ]}
              />
            </IonCol>
            <IonCol style={{ position: 'relative', zIndex: '99' }}>
              <p>How to modify customer?</p>
              <GeneralToolTip
                content={[
                  'First click on the input under customer name,',
                  'then type in letters to search for a customer.',
                  'Click on the customer you want to modify and then,',
                  'Click on the input near Select Action and choose',
                  'an action to perform on your customer.',
                  'You will be given fields to fill out which',
                  'correspond with the action you chose.',
                ]}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <div style={{ position: 'relative', zIndex: '9' }}>
                <IonLabel position="stacked" className="form-check-label">
                  {currentActionToTakeOnCustomer ? 'Customer To Delete' : 'Customer Name'}
                </IonLabel>
                <AsyncSelect
                  // @ts-ignore
                  value={{ label: customerName, value: customerName }}
                  onChange={async (e) => {
                    if (e == undefined) {
                      return;
                    }
                    const castedInput = e as unknown as { label: string; value: string };

                    const response = await CustomerService.SearchByName(
                      new StringDto(castedInput.label)
                    );

                    // If only CreateCustomer is returned don't pass any more data
                    if (response.data.model.length == 1) {
                      setCustomerName(response.data.model[0].name);
                      return {};
                    }

                    // Grabbing the second in the list because the first one is always
                    // the 'Create Customer' Option

                    const targetCustomer = response.data.model[1];

                    setCustomerName(castedInput.label);

                    setCustomerNumber(targetCustomer.identifier);
                    setCustomerId(targetCustomer.id);
                  }}
                  className={styles.asyncSelectCustom}
                  loadOptions={SearchForCustomer}
                  // cacheOptions
                  closeMenuOnSelect
                  placeholder={'Search by Customer Name'}
                />
              </div>
              <IonItem>
                <IonLabel position="stacked">Customer #</IonLabel>
                <IonInput disabled={true} type="text" value={customerNumber} />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Select Action</IonLabel>
                <IonSelect
                  placeholder=""
                  value={currentActionToTakeOnCustomer}
                  onIonChange={(e) => setCurrentActionToTakeOnCustomer(+e.detail.value)}>
                  {Object.entries(ActionToTakeOnCustomer)
                    .filter(([key, value]) => isNaN(parseInt(key)))
                    .map(([key, value]) => (
                      <IonSelectOption key={key} value={value}>
                        {key}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>

          {currentActionToTakeOnCustomer === ActionToTakeOnCustomer.UpdateName && (
            <>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>New Name</IonLabel>
                    <IonInput
                      value={updateName}
                      onIonChange={(e) => setUpdateName(e.detail.value ?? '')}
                      type="text"
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton onClick={() => handleSubmitUpdateName()}>Update Name</IonButton>
                </IonCol>
              </IonRow>
            </>
          )}

          {currentActionToTakeOnCustomer === ActionToTakeOnCustomer.UpdateIdentifier && (
            <>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel>New Identifier</IonLabel>
                    <IonTextarea
                      value={updateIdentifier}
                      onIonChange={(e) => setUpdateIdentifier(e.detail.value ?? '')}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton onClick={() => handleSubmitUpdateIdentifier()}>
                    Update Identifier
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          )}

          {currentActionToTakeOnCustomer === ActionToTakeOnCustomer.ChangeCustomerAndDelete && (
            <>
              <IonRow>
                <IonCol>
                  <div style={{ position: 'relative', zIndex: '9' }}>
                    <IonLabel position="stacked" className="form-check-label">
                      Customer To Keep
                    </IonLabel>
                    <AsyncSelect
                      // @ts-ignore
                      value={{ label: customerNameToChangeTo, value: customerNameToChangeTo }}
                      onChange={async (e) => {
                        if (e == undefined) {
                          return;
                        }
                        const castedInput = e as unknown as { label: string; value: string };

                        const response = await CustomerService.SearchByName(
                          new StringDto(castedInput.label)
                        );

                        // If only CreateCustomer is returned don't pass any more data
                        if (response.data.model.length == 1) {
                          return {};
                        }

                        // Grabbing the second in the list because the first one is always
                        // the 'Create Customer' Option

                        const targetCustomer = response.data.model[1];

                        setCustomerNameToChangeTo(castedInput.label);
                        setCustomerNumberToChangeTo(targetCustomer.identifier);
                      }}
                      className={styles.asyncSelectCustom}
                      loadOptions={SearchForCustomer}
                      // cacheOptions
                      closeMenuOnSelect
                      placeholder={'Search by Customer Name'}
                    />
                  </div>
                  <IonItem>
                    <IonLabel position="stacked">Customer #</IonLabel>
                    <IonInput disabled={true} type="text" value={customerNumberToChangeTo} />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton onClick={() => handleSubmitChangeCustomerAndDelete()}>
                    Change and Delete
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          )}

          {currentActionToTakeOnCustomer === ActionToTakeOnCustomer.Delete && (
            <IonRow>
              <IonCol>
                <IonButton onClick={() => handleDelete()}>Delete</IonButton>
              </IonCol>
            </IonRow>
          )}
        </form>
      </IonGrid>

      <IonModal isOpen={showModal} onIonModalDidDismiss={() => setShowModal(false)}>
        <IonItem>
          <IonLabel>Customer Identifier</IonLabel>
          <IonInput
            value={customerNumber}
            onIonChange={(e) => {
              if (e.detail.value == undefined) {
                return;
              }

              setCustomerNumber(e.detail.value);
            }}
          />
        </IonItem>
        <IonItem>
          <IonLabel>Customer Name</IonLabel>
          <IonInput
            value={customerName}
            onIonChange={(e) => {
              if (e.detail.value == undefined) {
                return;
              }

              setCustomerName(e.detail.value);
            }}
          />
        </IonItem>

        <IonButton type="submit" onClick={handleModalClick}>
          Submit Button
        </IonButton>
      </IonModal>
    </div>
  );
};

export default CustomerManager;
