import { IonInputCustomEvent, IonSelectCustomEvent } from '@ionic/core';
import { IonCheckboxCustomEvent } from '@ionic/core/dist/types/components';
import {
    CheckboxChangeEventDetail,
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    SelectChangeEventDetail,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import AsyncSelect from 'react-select/async';
import ConditionalLinkWrapper from '../../components/ConditionalLinkWrapper/ConditionalLinkWrapper';
import { getFactoryFunctionForDBManagedType } from '../../components/Search/SearchGeneralType';
import { EventTargetType, PossibleParameterTypes } from '../../hooks/ObjectHook';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { TypeInteractionDto } from '../../models/Dtos/TypeInteractionDto';
import { YardLocationDetailsWithInventoryDto } from '../../models/Dtos/YardMapDtos/YardLocationDetailsWithInventoryDto';
import { InventoryCondition, InventoryStatus, InventorySubType, PhotoStatus } from '../../models/Global';
import { TypeService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { minMax } from '../../utils/MinMaxUtil';
import { isNotNullNorUndefinedNorEmptyString } from '../../utils/NullOrUndefined';
import { RemoveMethods } from '../../utils/TypeUtils';
import { DbManagedTypes } from '../AdminPanel/components/TypeManager';
import styles from './InventoryDetails.module.scss';
/*

bindIonYardMapProperty
bindIonYardMapDate
bindIonYardMapCheck
bindYardMapAsyncSelect

*/
type InventoryProps = {
    inventory: InventoryDto;
    index: number;
    updateInventory: (record: InventoryDto) => void;
    focusToggle?: string | null;
    resetFocusToggle?: () => void;
    removeInventoryFromList: (stockNumber: string) => void;
};

type InventoryObjectHookBindProps<T extends object> = {
    bindIonYardMapProperty: (pathOrProperty: PossibleParameterTypes<T>) => {
        // value: any;
        onIonInput: <Type extends EventTargetType>(event: Type) => any;
    };
    bindIonYardMapDate: (pathOrProperty: PossibleParameterTypes<T>) => {
        value: any;
        onIonBlur: <Type extends EventTargetType>(event: IonInputCustomEvent<FocusEvent>) => void;
    };

    bindIonYardMapCheck: (pathOrProperty: PossibleParameterTypes<T>) => {
        checked: boolean;
        onIonChange: (e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => void;
    };

    bindIonYardMapSelect: (
        pathOrProperty: PossibleParameterTypes<T>,
        callBack?:
            | ((value: any) => {
                  [k: string]: any;
                  [k: number]: any;
                  [k: symbol]: any;
              })
            | undefined
    ) => {
        value: any;
        onIonChange: (event: IonSelectCustomEvent<SelectChangeEventDetail<any>>) => void;
    };
    bindIonYardMapCurrency: (
        pathOrProperty: PossibleParameterTypes<T>,
        disabled?: boolean
    ) => {
        value: any;
        className: string;
        onIonBlur: (event: IonInputCustomEvent<FocusEvent>) => void;
    };
    bindYardMapAsyncSelect: <K extends keyof T>(
        property: K,
        callBack?: Function | undefined
    ) => {
        value: any;
        onChange?: any;
    };
};

// For types
let inventoryTypesUpdated = false;
let wheelTypesUpdated = false;
let suspensionTypesUpdated = false;
let suspensionStyleTypesUpdated = false;
let floorTypesUpdated = false;
let rearDoorTypesUpdated = false;
let heightTypesUpdated = false;
let lengthTypesUpdated = false;
let widthTypesUpdated = false;
let wheelSizeTypesUpdated = false;
let makeTypesUpdated = false;
let modelTypesUpdated = false;
let reeferMakeTypesUpdated = false;
let reeferModelTypesUpdated = false;

const ARE_INPUTS_DISABLED = true;

const InventoryDetails = ({
    inventory,
    updateInventory: updateDelivery,
    focusToggle,
    resetFocusToggle,
    removeInventoryFromList,
    index,
    bindIonYardMapProperty,
    bindIonYardMapDate,
    bindIonYardMapCheck,
    bindIonYardMapSelect,
    bindIonYardMapCurrency,
    bindYardMapAsyncSelect,
}: InventoryProps & InventoryObjectHookBindProps<RemoveMethods<YardLocationDetailsWithInventoryDto>>) => {
    /*

bindIonYardMapProperty
bindIonYardMapDate
bindIonYardMapCheck
bindYardMapAsyncSelect

    */

    const history = useHistory();

    const [returnNothing, setReturnNothing] = useState<boolean>(false);

    const [passedUseEffectFocusToggle, setPassedUseEffectFocusToggle] = useState<boolean>(false);

    const firstActiveInput = useRef<HTMLIonInputElement>(null);

    // State for types

    const updateMethods: (() => Promise<void>)[] = [];

    //Inventory Type
    const [inventoryTypes, setInventoryTypes] = useState<string[]>([]);

    const updateInventoryTypes = async () => {
        if (inventoryTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.InventoryType));
        setInventoryTypes(response.data);
        inventoryTypesUpdated = true;
    };
    updateMethods.push(updateInventoryTypes);

    // Wheel Type

    const [wheelTypes, setWheelTypes] = useState<string[]>([]);

    const updateWheelTypes = async () => {
        if (wheelTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.WheelType));
        setWheelTypes(response.data);
        wheelTypesUpdated = true;
    };
    updateMethods.push(updateWheelTypes);

    // Suspension Type

    const [suspensionTypes, setSuspensionTypes] = useState<string[]>([]);

    const updateSuspensionTypes = async () => {
        if (suspensionTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.SuspensionType));
        setSuspensionTypes(response.data);
        suspensionTypesUpdated = true;
    };
    updateMethods.push(updateSuspensionTypes);

    // Suspension Style

    const [suspensionStyleTypes, setSuspensionStyleTypes] = useState<string[]>([]);

    const updateSuspensionStyleTypes = async () => {
        if (suspensionTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.SuspensionStyle));
        setSuspensionStyleTypes(response.data);
        suspensionStyleTypesUpdated = true;
    };
    updateMethods.push(updateSuspensionStyleTypes);

    // Floor Type

    const [floorTypes, setFloorTypes] = useState<string[]>([]);

    const updateFloorTypes = async () => {
        if (floorTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.FloorType));
        setFloorTypes(response.data);
        floorTypesUpdated = true;
    };
    updateMethods.push(updateFloorTypes);

    // Rear Door Type

    const [rearDoorTypes, setRearDoorTypes] = useState<string[]>([]);

    const updateRearDoorTypes = async () => {
        if (rearDoorTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.RearDoorType));
        setRearDoorTypes(response.data);
        rearDoorTypesUpdated = true;
    };
    updateMethods.push(updateRearDoorTypes);

    // height Types
    const [heightTypes, setHeightTypes] = useState<string[]>([]);
    const updateHeightTypes = async () => {
        if (heightTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Height));
        setHeightTypes(response.data);
        heightTypesUpdated = true;
    };
    updateMethods.push(updateHeightTypes);

    // length types
    const [lengthTypes, setLengthTypes] = useState<string[]>([]);
    const updateLengthTypes = async () => {
        if (lengthTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Length));
        setLengthTypes(response.data);
        lengthTypesUpdated = true;
    };
    updateMethods.push(updateLengthTypes);

    // width types
    const [widthTypes, setWidthTypes] = useState<string[]>([]);
    const updateWidthTypes = async () => {
        if (widthTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Width));
        setWidthTypes(response.data);
        widthTypesUpdated = true;
    };
    updateMethods.push(updateWidthTypes);

    // width types
    const [wheelSizeTypes, setWheelSizeTypes] = useState<string[]>([]);
    const updateWheelSizeTypes = async () => {
        if (wheelSizeTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.WheelSize));
        setWheelSizeTypes(response.data);
        wheelSizeTypesUpdated = true;
    };
    updateMethods.push(updateWheelSizeTypes);

    // make types
    const [makeTypes, setMakeTypes] = useState<string[]>([]);
    const updateMakeTypes = async () => {
        if (makeTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Make));
        setMakeTypes(response.data);
        makeTypesUpdated = true;
    };
    updateMethods.push(updateMakeTypes);

    // model types
    const [modelTypes, setModelTypes] = useState<string[]>([]);
    const updateModelTypes = async () => {
        if (modelTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.Model));
        setModelTypes(response.data);
        modelTypesUpdated = true;
    };
    updateMethods.push(updateModelTypes);

    // Reefer make types
    const [reeferMakeTypes, setReeferMakeTypes] = useState<string[]>([]);
    const updateReeferMakeTypes = async () => {
        if (reeferMakeTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.ReeferMake));
        setReeferMakeTypes(response.data);
        reeferMakeTypesUpdated = true;
    };
    updateMethods.push(updateReeferMakeTypes);

    // Reefer model types
    const [reeferModelTypes, setReeferModelTypes] = useState<string[]>([]);
    const updateReeferModelTypes = async () => {
        if (reeferModelTypesUpdated) {
            // Doesn't call again if already set
            return;
        }

        const response = await TypeService.GetNameList(new TypeInteractionDto('', '', DbManagedTypes.ReeferModel));
        setReeferModelTypes(response.data);
        reeferModelTypesUpdated = true;
    };
    updateMethods.push(updateReeferModelTypes);

    useEffect(() => {
        updateMethods.forEach((updateMethod) => {
            updateMethod();
        });
    }, []);

    useEffect(() => {
        if (inventory == undefined) {
            return;
        }

        if (+inventory.Stock <= 0) {
            setReturnNothing(true);
        }
    }, [inventory]);

    useEffect(() => {
        if (!passedUseEffectFocusToggle) {
            setPassedUseEffectFocusToggle(true);
            return;
        }

        if (focusToggle == null) {
            return;
        }

        if (focusToggle !== inventory.Stock + '') {
            return;
        }

        if (!firstActiveInput.current) {
            return;
        }

        setTimeout(() => {
            //@ts-ignore
            firstActiveInput.current.setFocus();
            if (resetFocusToggle != undefined) {
                resetFocusToggle();
            }
        }, 5);
    }, [focusToggle]);

    if (returnNothing) {
        return <></>;
    }

    const handleDelete = () => {
        if (inventory == undefined || inventory.Stock === '') {
            throw new Error(
                'Record is undefined but pressing delete button! Delete Button should be hidden if record is undefined or stock is undefined!'
            );
        }
        removeInventoryFromList(inventory.Stock);
    };

    // 'Carrier Name': string; // not listed yet

    const handleRedirectToInventory = (stock: string) => {
        history.push({
            pathname: `/inventory/edit/${IdentifierType.Stock}/${stock}`,
        });
    };

    const zIndexCountdownNumber = 500;

    const asyncSelectLabelInlineStyleObject = { color: ARE_INPUTS_DISABLED ? '#adadad' : '' };

    return (
        <>
            <IonTitle>Inventory Stock # {inventory != undefined ? inventory.Stock : ''}</IonTitle>
            {inventory != undefined && +inventory.Stock != 0 && (
                <IonButton style={{ paddingInline: '20px' }} color="danger" onClick={() => handleDelete()}>
                    Remove
                </IonButton>
            )}
            <div style={{ display: 'flex', flexWrap: 'wrap' }} className={styles.inputRow}>
                {/* Start of Inventory Identifiers */}
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" disabled={false}>
                        <IonLabel position="stacked">Stock</IonLabel>
                        <ConditionalLinkWrapper
                            to={`/inventory/edit/${IdentifierType.Stock}/${inventory.Stock}`}
                            conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(inventory.Stock)}>
                            <IonInput
                                className={styles.redirectInputs}
                                style={{ color: 'blue', fontWeight: '550', pointerEvents: 'all' }}
                                // disabled={ARE_INPUTS_DISABLED}
                                // readonly={ARE_INPUTS_DISABLED}
                                disabled={true}
                                type="number"
                                clearOnEdit
                                value={inventory.Stock}
                            />
                        </ConditionalLinkWrapper>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" disabled={false}>
                        <IonLabel position="stacked">Invoice Number</IonLabel>
                        <ConditionalLinkWrapper
                            to={`/sales-order/edit/${IdentifierType.Stock}/${inventory.Stock}`}
                            conditionToRedirectOnClick={isNotNullNorUndefinedNorEmptyString(inventory.Stock)}>
                            <IonInput
                                className={styles.redirectInputs}
                                style={{ color: 'blue', fontWeight: '550', pointerEvents: 'all' }}
                                // disabled={ARE_INPUTS_DISABLED}
                                // readonly={ARE_INPUTS_DISABLED}
                                disabled={true}
                                type="number"
                                clearOnEdit
                                value={inventory['Invoice Number']}
                            />
                        </ConditionalLinkWrapper>
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Condition</IonLabel>
                        <IonSelect
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapSelect({
                                path: 'Inventory[#].Condition',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}>
                            {Object.entries(InventoryCondition)
                                .filter(([key, value]) => isNaN(parseInt(key)))
                                .map(([key, value]) => (
                                    <IonSelectOption value={value} key={value}>
                                        {key}
                                    </IonSelectOption>
                                ))}
                        </IonSelect>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Status</IonLabel>
                        <IonSelect
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapSelect({
                                path: 'Inventory[#].Status',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}>
                            {Object.entries(InventoryStatus)
                                .filter(([key, value]) => isNaN(parseInt(key)))
                                .map(([key, value]) => (
                                    <IonSelectOption value={value} key={value}>
                                        {key}
                                    </IonSelectOption>
                                ))}
                        </IonSelect>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '13rem' }}>
                        <IonLabel position="stacked">VIN</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].VIN',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '13rem' }}>
                        <IonLabel position="stacked">Color</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Color',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '12rem' }}>
                        <IonLabel position="stacked">Spec</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Spec',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '12rem' }}>
                        <IonLabel position="stacked">Customer</IonLabel>
                        <IonInput type="text" value={inventory.Customer} disabled />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Number</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="number"
                            clearOnEdit
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Number',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '7rem' }}>
                        <IonLabel position="stacked">Price</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Price',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                {/*End of  Inventory Identifiers*/}
                {/* Start Inventory Tracking Group */}
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Comp Date</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="date"
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Comp Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Inspection Date</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="date"
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Inspection Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Received Date</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="date"
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Received Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>

                {/*DOT REPAIR COMPLETE AND DOT REPAIR STATUS SHOULD BE TOGETHER */}
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Advertise</IonLabel>
                        <IonCheckbox
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapCheck({
                                path: 'Inventory[#].Advertise',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '10remg' }}>
                        <IonLabel position="stacked">DOT Status</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].DOT Status',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '8rem' }}>
                        <IonLabel position="stacked">Decal Id</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Decal Id',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                City Location
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['City Location'], value: inventory['City Location'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.CityLocation)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                {/* <IonItem fill="solid">
                        <IonLabel position="stacked">City Location</IonLabel>
                        <IonInput
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].City Location',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem> */}
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Misc Date</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="date"
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Misc Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Delivered Date</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="date"
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Delivered Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '7rem' }}>
                        <IonLabel position="stacked">Cost</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Cost',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>

                {/* End of Inventory Tracking Group */}
                {/* Start of Inventory Sepcs */}
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel
                                style={asyncSelectLabelInlineStyleObject}
                                // style={{ color: 'red' }}
                                position="stacked">
                                Model
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['Model'], value: inventory['Model'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.Model)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Make
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['Make'], value: inventory['Make'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.Make)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '10rem' }}>
                        <IonLabel position="stacked">Sub Type</IonLabel>
                        <IonInput
                            type="text"
                            value={
                                inventory == undefined
                                    ? ''
                                    : inventory['Sub Type'] == undefined
                                    ? ''
                                    : InventorySubType[inventory['Sub Type']]
                            }
                            disabled={true}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '5rem' }}>
                        <IonLabel position="stacked">Year</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="number"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Year',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Length
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['L'], value: inventory['L'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.Length)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Width
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['W'], value: inventory['W'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.Width)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Height
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['H'], value: inventory['H'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.Height)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '13rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Type
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['Type'], value: inventory['Type'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.InventoryType)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Wheel Sz
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['Wheel Sz'], value: inventory['Wheel Sz'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.WheelSize)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Split Deal</IonLabel>
                        <IonCheckbox
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapCheck({
                                path: 'Inventory[#].Split Deal',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Wheel Type
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['Wheel Type'], value: inventory['Wheel Type'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.WheelType)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Axles</IonLabel>
                        <IonInput
                            type="number"
                            clearOnEdit
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Axles',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Suspension Type
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{ label: inventory['Suspension Type'], value: inventory['Suspension Type'] }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.SuspensionType)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Suspension Style
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{
                                        label: inventory['Suspension Style'],
                                        value: inventory['Suspension Style'],
                                    }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.SuspensionStyle)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '2rem' }}>
                        <IonLabel position="stacked">King Pin</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].KP',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Floor Type
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{
                                        label: inventory['Floor Type'],
                                        value: inventory['Floor Type'],
                                    }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.FloorType)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Rear Door Type
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{
                                        label: inventory['Rear Door Type'],
                                        value: inventory['Rear Door Type'],
                                    }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.RearDoorType)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol size={'12'} className={`${styles.inputCol} ${styles.notesInputCol}`}>
                    <IonItem fill="solid">
                        <IonLabel position={'stacked'}>Notes</IonLabel>
                        <IonTextarea
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Notes',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                {/* End of Inventory Specs*/}
                {/* Start of Unit Spec's */}
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Reefer Serial Number</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Reefer Serial Number',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Reefer Engine Year</IonLabel>
                        <IonInput
                            type="number"
                            clearOnEdit
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Reefer Engine Year',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Reefer Year</IonLabel>
                        <IonInput
                            disabled={ARE_INPUTS_DISABLED}
                            type="number"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Reefer Year',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Reefer Make
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{
                                        label: inventory['Reefer Make'],
                                        value: inventory['Reefer Make'],
                                    }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.ReeferMake)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={`${styles.inputCol} ${styles.asyncSelectColOverriteBottomBorder}`}>
                    <IonItem
                        fill="solid"
                        className={`${styles.asyncSelectInputContainer} `}
                        style={{ minWidth: '15rem' }}>
                        <div style={{ width: '100%' }}>
                            <IonLabel style={asyncSelectLabelInlineStyleObject} position="stacked">
                                Reefer Model
                            </IonLabel>
                            <div style={{ marginTop: '7px', width: '100%' }}>
                                <AsyncSelect
                                    isDisabled={ARE_INPUTS_DISABLED}
                                    // cacheOptions={true}
                                    value={{
                                        label: inventory['Reefer Model'],
                                        value: inventory['Reefer Model'],
                                    }}
                                    cacheOptions={true}
                                    className={styles.fullWidthAsyncSelect}
                                    filterOption={(filterOption, inputValue) => {
                                        return filterOption.label.toLowerCase().includes(inputValue.toLowerCase());
                                    }}
                                    isSearchable={true}
                                    defaultOptions={true}
                                    loadOptions={getFactoryFunctionForDBManagedType(DbManagedTypes.ReeferModel)}
                                />
                            </div>
                        </div>
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Unit Hours</IonLabel>
                        <IonInput
                            type="number"
                            disabled={ARE_INPUTS_DISABLED}
                            clearOnEdit
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Unit Hours',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol size={'12'} className={`${styles.inputCol} ${styles.notesInputCol}`}>
                    <IonItem fill="solid">
                        <IonLabel position={'stacked'}>Reefer Desc</IonLabel>
                        <IonTextarea
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Reefer Desc',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Ordered Date</IonLabel>
                        <IonInput
                            type="date"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Ordered Date',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '7rem' }}>
                        <IonLabel position="stacked">Deposit</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Deposit',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '10rem' }}>
                        <IonLabel position="stacked">Sales Person</IonLabel>
                        <IonInput
                            disabled={true}
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Sales Person',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Check Number</IonLabel>
                        <IonInput
                            disabled={true}
                            type="text"
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Check Number',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                {/* End of Unit Spec's */}
                {/* Start Admin Group */}
                {/* Could not find corresponding items for Last Modified Date, Invoice #, Sales Order Notes, Customer #, or Paid Date */}
                {/* Additionally not including stock # here as it's already on the page  */}
                {/* End Admin Group */}
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Temp Tag Expiration</IonLabel>
                        <IonInput
                            type="date"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Temp Tag Expiration',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                            {...minMax}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Side Doors</IonLabel>
                        <IonInput
                            type="text"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapProperty({
                                path: 'Inventory[#].Side Doors',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Entered In CDK</IonLabel>
                        <IonInput
                            type="date"
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapDate({
                                path: 'Inventory[#].Entered In CDK',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid" style={{ minWidth: '15rem' }}>
                        <IonLabel position="stacked">Photo Status</IonLabel>
                        <IonSelect
                            disabled={ARE_INPUTS_DISABLED}
                            {...bindIonYardMapSelect({
                                path: 'Inventory[#].Photo Status',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}>
                            {Object.entries(PhotoStatus)
                                .filter(([key, value]) => isNaN(parseInt(key)))
                                .map(([key, value]) => (
                                    <IonSelectOption value={value} key={value}>
                                        {key}
                                    </IonSelectOption>
                                ))}
                        </IonSelect>
                    </IonItem>
                </IonCol>

                <IonCol className={styles.inputCol}>
                    <IonItem fill="solid">
                        <IonLabel position="stacked">Has Photos</IonLabel>
                        <IonCheckbox
                            disabled={true}
                            {...bindIonYardMapCheck({
                                path: 'Inventory[#].Has Photos',
                                pathCorrespondingArrayAccessIndicesArray: [index],
                            })}
                        />
                    </IonItem>
                </IonCol>
                {/*DOT REPAIR COMPLETE AND DOT REPAIR STATUS SHOULD BE TOGETHER */}
            </div>
        </>
    );
};
export default InventoryDetails;
