export const CommaAdderToNumber = (inputNumber: number) => {
    const inputNumberString = inputNumber.toString();

    let countToNextComma = 3;

    const groupsOfString: string[] = [];

    let combiningString = '';

    for (let i = inputNumberString.length - 1; i >= 0; i--) {
        const numCharacter = inputNumberString[i];

        if (countToNextComma <= 0) {
            // should place comma here

            groupsOfString.push(combiningString);
            combiningString = numCharacter;
            countToNextComma = 2;
        } else {
            combiningString = numCharacter + combiningString;

            countToNextComma--;
        }
    }

    if (combiningString.trim() !== '') {
        groupsOfString.push(combiningString);
    }

    return groupsOfString.reverse().join(',');
};
