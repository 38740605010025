import React from 'react';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { FilterType } from '../../../../models/Global';
import { UserGlobalFilterStorageType } from '../../../../models/UserGlobalFIlterStorageType';
import {
    isNotNullNorUndefined,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyString,
} from '../../../../utils/NullOrUndefined';
import QueriedTableFilterButton from '../QueriedTableFilterButton/QueriedTableFilterButton';
import styles from './QueriedTableFilterList.module.scss';

type QueriedTableFilterlistProps = {
    ['data-testid']?: string;
    handleUserFilterButtonClick: (userFilterName: string) => Promise<void>;

    isFilterSelected: ({ name, filterType }: Omit<UserGlobalFilterStorageType, 'globalFilterRow'>) => boolean;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;

    userFiltersStorage: UserGlobalFilterSpecificGeneralOption[];
};

const QueriedTableFilterList: React.FC<QueriedTableFilterlistProps> = ({
    'data-testid': testId,
    handleUserFilterButtonClick,
    isFilterSelected,
    computedCombinedFilters,
    userFiltersStorage,
}) => {
    return (
        <div className={styles.filters_container} data-testid={testId}>
            {isNotNullNorUndefined(userFiltersStorage) &&
                Array.isArray(userFiltersStorage) &&
                userFiltersStorage.map((userFilter, index) => {
                    if (isNullOrUndefined(userFilter)) return undefined;
                    return (
                        <QueriedTableFilterButton
                            color={'filters-light'}
                            button={styles.button}
                            filter_options_button={styles.filter_options_button}
                            key={userFilter.filterName}
                            last_button={index === userFiltersStorage.length - 1 ? styles.last_button : undefined}
                            selected={isFilterSelected({ name: userFilter.filterName, filterType: FilterType.User })}
                            filterName={userFilter.filterName}
                            onClick={() => {
                                handleUserFilterButtonClick(userFilter.filterName);
                            }}
                            index={index}>
                            {isNullOrUndefinedOrEmptyString(userFilter.filterName) ? '<Empty>' : userFilter.filterName}
                        </QueriedTableFilterButton>
                    );
                })}
        </div>
    );
};
export default QueriedTableFilterList;
