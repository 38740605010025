import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { SalesOrderStatus } from '../../models/Global';

export const getEnumFromSalesOrderDtoPropertyName = (propertyName: keyof SalesOrderDto) => {
    switch (propertyName) {
        case 'Status': {
            return SalesOrderStatus;
        }
        default: {
            return undefined;
        }
    }
};

export const EnumSalesOrderProperties = ['Status'] as (keyof SalesOrderDto)[];
