import { ReturnAccountingToDoDto } from '../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';

const excludeFromColumnsToShow: readonly (keyof ReturnAccountingToDoDto)[] = [] as const;

type KeyOfSalesOrderArray = (keyof ReturnAccountingToDoDto)[];

export const filterAccountingTodoKeyArrayByExcludedColumns = (array: KeyOfSalesOrderArray) => {
    const filteredArray = array.filter(filterAccountingTodoColumnMethod);
    return filteredArray;
};

export const isAccountingTodoPropertyExcluded = (property: keyof ReturnAccountingToDoDto) => {
    return excludeFromColumnsToShow.includes(property);
};

export const filterAccountingTodoColumnMethod = (column: string) => {
    return !excludeFromColumnsToShow.includes(column as keyof ReturnAccountingToDoDto);
};
