import { useMsal } from '@azure/msal-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { loginApiRequest, TENANT_ID } from '../../utils/AuthConfig';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import styles from './RoleContextComp.module.scss';

interface RoleContextProps {
    children: ReactNode;
}

// Can update if changed in the future;
const SALES_ROLE_NAME = 'User';
const ACCOUNTING_ROLE_NAME = 'Accounting';
const ADMIN_ROLE_NAME = 'Admin';

export const canUserAccessAdminSecurityWithGivenRoles = (roles?: string[]) =>
    isNullOrUndefined(roles) ? false : roles.includes(ADMIN_ROLE_NAME);

export const canUserAccessAccountingSecurityWithGivenRoles = (roles?: string[]) => {
    return isNullOrUndefined(roles) ? false : roles.includes(ACCOUNTING_ROLE_NAME) || roles.includes(ADMIN_ROLE_NAME);
};

export const canUserAccessSalesSecurityWithGivenRoles = (roles?: string[]) => {
    // True because default
    return true;
};

export const RoleContext = React.createContext<string[]>([]);

const RoleContextComp = ({ children }: RoleContextProps) => {
    const { instance, accounts } = useMsal();

    const [roles, setRoles] = useState<string[]>([]);

    // Possible ROles:
    /*
     * user
     * admin
     * accounting
     */

    useEffect(() => {
        const getRoles = async () => {
            // Need to get valid access token.

            let accessToken: string | null = null;

            // Get active account from instance

            let targetActiveAccount = instance.getActiveAccount();
            instance.setActiveAccount(targetActiveAccount);

            // If no active account let's try to login or something similar

            if (isNullOrUndefined(targetActiveAccount)) {
                const targetAccount = accounts.find((account) => account.tenantId === TENANT_ID);

                if (isNullOrUndefined(targetAccount)) {
                    // If this happens the user isn't logged in. This shouldn't happen.
                    // But if it does we'll redirect user to get logged in
                    instance.loginRedirect({ ...loginApiRequest });
                    return;
                }

                instance.setActiveAccount(targetAccount);
                targetActiveAccount = targetAccount;
            }

            // Get Access token from aquire token silently.

            const response = await instance.acquireTokenSilent({
                ...loginApiRequest,
                account: targetActiveAccount,
            });

            accessToken = response.accessToken;

            // Decode access token

            const parts = accessToken.split('.');

            const decoded = window.atob(parts[1]);

            const obj = JSON.parse(decoded);

            const roles = obj['roles'];

            setRoles(roles);
        };
        getRoles();
    }, [instance, accounts]);

    return (
        <div className={styles.RoleContextContainer} data-testid="RoleContext">
            <RoleContext.Provider value={roles}>{children}</RoleContext.Provider>
        </div>
    );
};

export default RoleContextComp;
