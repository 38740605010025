import { IonIcon } from "@ionic/react";

import debounce from "debounce";
import {
  caretDownOutline,
  caretUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import Select from "react-select";

import AsyncSelect from "react-select/async";
import { StringDto } from "../../../models/Dtos/GeneralDtos/StringDto";
import { InventoryDto } from "../../../models/Dtos/InventoryDtos/InventoryDto";
import { DeliveryDto } from "../../../models/Dtos/SalesOrderDtos/DeliveryDto";
import { TypeInteractionDto } from "../../../models/Dtos/TypeInteractionDto";
import {
  AccountingStatus,
  DataType,
  InventoryCondition,
  InventoryStatus,
  InventorySubType,
  PhotoStatus,
  RecordType,
  SalesOrderStatus,
} from "../../../models/Global";
import { DbManagedTypes } from "../../../pages/AdminPanel/components/TypeManager";
import { Layout } from "../../../pages/Layout";
import {
  CustomerService,
  DeliveryService,
  InventoryService,
  SalesOrderService,
  TypeService,
} from "../../../utils/apiServices";
import { cTB } from "../../../utils/BooleanStringHelper";
import {
  filterDeliveryColumnMethod,
  filterDeliveryKeyArrayByExcludedColumns,
  isDeliveryPropertyExcluded,
} from "../../../utils/ColumnsToShowFilterByDtoType/DeliveryColumnsToShowFilters";
import { CommaAdderToNumber } from "../../../utils/CommaAdderToNumber";
import confirmingTableUpdate from "../../../utils/ConfirmingTableUpdate";
import { convertAllNullToEmptyStringFromObject } from "../../../utils/ConvertAllNullToEmptyStringUtil";
import { convertFromStringIfBooleanOrNumber } from "../../../utils/DataTypeConversionFromString/DataTypeConversionFromString";
import { getDataTypeFromDeliveryPropertyName } from "../../../utils/DataTypeMappersByDtoType/DeliveryDtoDataTypeMapper";
import { convertToInputDateFormat } from "../../../utils/DateUtils";
import { DeliveryDtoToInventoryDto } from "../../../utils/DeliveryDtoToInventoryDto";
import {
  EnumDeliveryProperties,
  getEnumFromDeliveryDtoPropertyName,
} from "../../../utils/EnumMappersByDtoType/DeliveryDtoEnumMapper";
import { IdentifierType } from "../../../utils/identifierType";
import makeSimpleToast from "../../../utils/MakeSimpleToast";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../../../utils/NullOrUndefined";
import PageNameGenerator from "../../../utils/PageNameGenerator";
import { AllPossibleReturnTypes } from "../../../utils/RecordTypeUtils";
import CopyTableStylesButtons from "../../CopyTableStylesButtons/CopyTableStylesButtons";
import {
  canUserAccessAdminSecurityWithGivenRoles,
  RoleContext,
} from "../../RoleContext/RoleContextComp";
import { SearchForCustomer, SearchForEmployee } from "../../Search";
import { ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID } from "../../Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer";
import QueriedTablePaginationOptions from "../../Theme/QueriedTable/QueriedTablePaginationOptions/QueriedTablePaginationOptions";
import {
  LocationType,
  QueriedTableSortFilterStateManagerExportProps,
} from "../../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import QueriedTableTabList from "../../Theme/QueriedTable/QueriedTableTabList/QueriedTableGlobalUserFilterList";
import QueriedTableToolbar from "../../Theme/QueriedTable/QueriedTableToolbar/QueriedTableToolbar";
import {
  CH_OFFSET,
  MIN_WIDTH_INPUT,
  NORMAL_COLS_SIZE,
  SMALL_COLS_SIZE,
} from "../GeneralReference";
import styles from "./DeliveryTable.module.scss";

type DeliveryTableProps = QueriedTableSortFilterStateManagerExportProps;

const DeliveryTable = ({
  endpoint,
  toolbar,
  identifier,
  identifierType,
  handleSetCustomFiltersValueFilters,
  handleCreateUpdateUserFilter,
  justSortOptions,
  overwritingProvidedData,
  model,
  useCustomEndpoint,
  setUseCustomEndpoint,
  recordType,
  handleClearCancel,
  isFilterSelected,
  handleCreateUpdateGlobalFilter,
  handleDeleteGlobalFilter,
  handleDeleteUserFilter,
  handleUpdatingItemsPerPage,
  setOverwritingProvidedData,
  setSortFilters,
  dtoType,
  tabbed,
  handleGlobalFilterButtonClick,
  data,
  setData,
  computedCombinedFilters,
  handleUserFilterButtonClick,
  setItemsPerPage,
  DEBOUNCE_WAIT_TIME,
  handleChangeSorting,
  handleOrderColumnToLeft,
  handleOrderColumnToRight,
  updateCurrentPageNumber,
  setColumnVisibility,
  selectingRowMode,
  unselectAllRows,
  selectAllRows,
  cancelSelecting,
  stopSelectingAndCopyTableWithStyles,
  stopSelectingAndEmailAdvertisements,
  startSelecting,
  isRowIdSelected,
  unselectRow,
  registerSelection,
  selectedGlobalFilterNames,
  selectedUserFilterNames,
  computedSelectedGlobalFilters,
  computedSelectedUserFilters,
  userFiltersStorage,
  globalFiltersStorage,
  customFilters,
  unselectGlobalFilter,
  unselectUserFilter,
  ignoreFilter,
  unignoreFilter,
  isFilterIgnored,
  combinedFilterTargetProperties,
  updateSearchTerm,
  handleNewUpdaterFunction,
  ignoredFilterIds,
  callEndpointAgain,

  isLoading,
  setIsLoading,
}: DeliveryTableProps) => {
  const history = useHistory();

  const roles = useContext(RoleContext);

  const DELIVERY_PAGE_TITLE = PageNameGenerator("Delivery");

  useEffect(() => {
    if (document.title !== DELIVERY_PAGE_TITLE)
      document.title = DELIVERY_PAGE_TITLE;
  }, []);

  const [wheelTypes, setWheelTypes] = useState<OptionType[]>([]);
  const [rearDoorTypes, setRearDoorTypes] = useState<OptionType[]>([]);
  const [suspensionTypeTypes, setSuspensionTypeTypes] = useState<OptionType[]>(
    []
  );
  const [suspensionStyleTypes, setSuspensionStyleTypes] = useState<
    OptionType[]
  >([]);
  const [floorTypes, setFloorTypes] = useState<OptionType[]>([]);
  const [typeTypes, setTypeTypes] = useState<OptionType[]>([]);
  const [lengthTypes, setLengthTypes] = useState<OptionType[]>([]);
  const [widthTypes, setWidthTypes] = useState<OptionType[]>([]);
  const [heightTypes, setHeightTypes] = useState<OptionType[]>([]);
  const [makeTypes, setMakeTypes] = useState<OptionType[]>([]);
  const [modelTypes, setModelTypes] = useState<OptionType[]>([]);
  const [wheelSizeTypes, setWheelSizeTypes] = useState<OptionType[]>([]);
  const [reeferMakeTypes, setReeferMakeTypes] = useState<OptionType[]>([]);
  const [reeferModelTypes, setReeferModelTypes] = useState<OptionType[]>([]);
  const [cityLocationTypes, setCityLocationTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    const handleUpdatingType = async (
      dbManagedType: DbManagedTypes,
      updateFunc: (types: OptionType[]) => unknown
    ) => {
      const typeInteractionDto: TypeInteractionDto = {
        originalName: "",
        newName: "",
        dbManagedType: dbManagedType,
      };

      const response = await TypeService.GetNameList(typeInteractionDto);

      const types = response.data.map((type) => ({
        label: type,
        value: type,
        title: type,
      }));

      updateFunc(types);
    };

    console.time("Type-Stuff");
    handleUpdatingType(DbManagedTypes.WheelType, setWheelTypes);
    handleUpdatingType(DbManagedTypes.RearDoorType, setRearDoorTypes);
    handleUpdatingType(DbManagedTypes.SuspensionType, setSuspensionTypeTypes);
    handleUpdatingType(DbManagedTypes.SuspensionStyle, setSuspensionStyleTypes);
    handleUpdatingType(DbManagedTypes.FloorType, setFloorTypes);
    handleUpdatingType(DbManagedTypes.InventoryType, setTypeTypes);
    handleUpdatingType(DbManagedTypes.Length, setLengthTypes);
    handleUpdatingType(DbManagedTypes.Width, setWidthTypes);
    handleUpdatingType(DbManagedTypes.Height, setHeightTypes);
    handleUpdatingType(DbManagedTypes.Make, setMakeTypes);
    handleUpdatingType(DbManagedTypes.Model, setModelTypes);
    handleUpdatingType(DbManagedTypes.WheelSize, setWheelSizeTypes);
    handleUpdatingType(DbManagedTypes.ReeferMake, setReeferMakeTypes);
    handleUpdatingType(DbManagedTypes.ReeferModel, setReeferModelTypes);
    handleUpdatingType(DbManagedTypes.CityLocation, setCityLocationTypes);
    console.timeEnd("Type-Stuff");
  }, []);

  const getSortForColumn = useCallback(
    (property: string) => {
      const targetSort = computedCombinedFilters.sortOptions.sortBy.find(
        (item) => item.propertyName === property
      );

      if (isNullOrUndefined(targetSort)) {
        return undefined;
      }

      return targetSort.isAscending;
    },
    [computedCombinedFilters.sortOptions.sortBy]
  );

  const generateTableHeaders = useCallback(
    (dto: DeliveryDto) => {
      if (isNullOrUndefined(dto)) {
        return null;
      }

      let headers: React.ReactElement[];

      const generateThMapMethod = (property: string, index: number) => (
        <th key={property}>
          <div className={styles.tableHeaderButtonWrapper}>
            <span className={styles.headerSpanContainer}>
              <div style={{ display: "block" }}>
                <button
                  onClick={(e) => handleChangeSorting(property)}
                  className={styles.headerButton}
                >
                  {property}
                  {isNullOrUndefined(getSortForColumn(property)) ? (
                    <></>
                  ) : getSortForColumn(property) ? (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretUpOutline}
                    />
                  ) : (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretDownOutline}
                    />
                  )}
                </button>
              </div>
              {index > 0 && (
                <button
                  onClick={(e) =>
                    handleOrderColumnToLeft(
                      property as keyof AllPossibleReturnTypes
                    )
                  }
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.leftColumnButton}`}
                    icon={chevronBackOutline}
                  />
                </button>
              )}
              {index <
                computedCombinedFilters.orderedColumnsToShow.length - 1 && (
                <button
                  onClick={(e) =>
                    handleOrderColumnToRight(
                      property as keyof AllPossibleReturnTypes
                    )
                  }
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.rightColumnButton}`}
                    icon={chevronForwardOutline}
                  />
                </button>
              )}
            </span>
          </div>
        </th>
      );
      const selectionColumn = (
        <th
          className={
            selectingRowMode === "unselected"
              ? styles.selectionColumnHidden
              : ""
          }
          style={{ backgroundColor: "white" }}
          key="selection-column"
        >
          To Copy
        </th>
      );

      if (
        isNullOrUndefined(computedCombinedFilters) ||
        !Array.isArray(computedCombinedFilters.orderedColumnsToShow) ||
        computedCombinedFilters.orderedColumnsToShow[0] === ""
      ) {
        // Get keys  of first entry to get headers.
        headers = [
          selectionColumn,
          ...Object.keys(dto)
            .filter(filterDeliveryColumnMethod)
            .map(generateThMapMethod),
        ];
      } else {
        // Use columnsToShow from savedGeneratedGeneralOptions to get headers.

        // REAPLCE HERE WITH STUF

        headers = [
          selectionColumn,
          ...computedCombinedFilters.orderedColumnsToShow
            .filter(filterDeliveryColumnMethod)
            .map(generateThMapMethod),
        ];
      }
      return headers;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      computedCombinedFilters,
      getSortForColumn,
      handleChangeSorting,
      handleOrderColumnToLeft,
      handleOrderColumnToRight,
      selectingRowMode,
    ]
  );

  const handleGenerateTableParts = () => {
    let tableHead: React.ReactElement | null = null;

    const rows = (data as DeliveryDto[]).map((dto, index) => {
      // Doing it this way to limit the amount of iteration done
      let headers: React.ReactElement[] | null = null;
      if (index === 0) {
        headers = generateTableHeaders(dto);
        tableHead = (
          <thead className={styles.tHead}>
            <tr>{headers}</tr>
          </thead>
        );
      }

      return (
        <tr
          key={dto["Stock"]}
          className={`${
            index % 2 === 0 ? styles.highlighted : styles.notHighlighted
          } ${styles.tRow}`}
        >
          {runner(dto)}
          {/* <InventoryTableRow
                        dtoData={dto}
                        orderedColumnsToShow={
                            savedGeneratedGeneralOptions.current?.columnsToShow as (keyof InventoryDto)[] | undefined
                        }
                    /> */}
        </tr>
      );
    });

    console.timeEnd();

    return (
      <>
        {tableHead}
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleInputUpdateValueProvider = (
    event: ChangeEvent<HTMLInputElement>,
    columnName: keyof DeliveryDto,
    stock: string
  ) => {
    const isCheckbox = event.target.type === "checkbox";
    const value = isCheckbox ? event.target.checked : event.target.value;

    if (isCheckbox) {
      handleUnDebouncedUpdate(value, event, columnName, stock);
    } else {
      handleInputUpdate(value, event, columnName, stock);
    }
  };

  const handleTextareaUpdateValueProvider = (
    event: ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof DeliveryDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleInputUpdate(value, event, columnName, stock);
  };

  const handleSelectOrTextAreaUpdateValueProvider = (
    event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof DeliveryDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleUnDebouncedUpdate(value, event, columnName, stock);
  };

  type Option = {
    label: string;
    value: string;
  };

  const [showCreateCustomerModal, setShowCreateCustomerModal] =
    useState<boolean>(false);
  const [customerIdentifier, setCustomerIdentifier] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [
    stockNumberSelectedForCreateCustomer,
    setStockNumberSelectedForCreateCustomer,
  ] = useState<string>();

  const getTargetRowOrConsoleError = useCallback(
    (stock: string): [number, DeliveryDto] => {
      const targetRowIndex = (data as DeliveryDto[]).findIndex(
        (dto) => dto["Stock"] === stock
      );
      if (targetRowIndex === -1) {
        const errorMessage =
          "Target row of stock number" + stock + " can't be found!!";

        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const targetRow = data[targetRowIndex] as DeliveryDto;
      return [targetRowIndex, targetRow];
    },
    [data]
  );

  const handleCustomerUpdate = useCallback(
    async (
      invoiceNumber: string | number,
      customerName: string,
      customerNumber: string
    ) => {
      // prop = ;

      // Is debounce 1000ms

      const salesOrderDetailResponse =
        await SalesOrderService.getSalesOrderDetails(invoiceNumber);

      if (!salesOrderDetailResponse.data.isSuccessful) {
        makeSimpleToast(
          "Error while getting sales order from invoice number: " +
            invoiceNumber
        );
        return;
      }

      const salesOrderDto = salesOrderDetailResponse.data.model;

      salesOrderDto["Customer Name"] = customerName;
      salesOrderDto["Customer #"] = customerNumber;

      const response = await SalesOrderService.updateSalesOrderFromQueriedTable(
        convertAllNullToEmptyStringFromObject(salesOrderDto)
      );
      callEndpointAgain();
    },
    [callEndpointAgain]
  );

  const handleInputUpdate = debounce(
    async (
      value: string | boolean,
      event:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>,
      columnName: keyof DeliveryDto,
      stock: string
    ) => {
      if (!confirmingTableUpdate(event)) {
        return;
      }
      const isCheckbox = event.target.type === "checkbox";

      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

      const copiedTargetRow = { ...targetRow };

      const convertedValue = isCheckbox
        ? value
        : convertFromStringIfBooleanOrNumber(
            value as string,
            getDataTypeFromDeliveryPropertyName(columnName as keyof DeliveryDto)
          );

      //@ts-ignore
      copiedTargetRow[columnName] = convertedValue;

      // prop = ;

      // Is debounce 1000ms

      const response = await DeliveryService.updateDeliveryForTable(
        convertAllNullToEmptyStringFromObject(copiedTargetRow)
      );

      setData((oldData) => {
        const toUpdate = [...oldData];
        toUpdate.splice(targetRowIndex, 1, copiedTargetRow);
        return toUpdate;
      });
    },
    DEBOUNCE_WAIT_TIME
  );

  const handleUnDebouncedUpdate = async (
    value: string | boolean,
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof DeliveryDto,
    stock: string
  ) => {
    if (!confirmingTableUpdate(event)) {
      return;
    }
    const isCheckbox = event.target.type === "checkbox";

    const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

    const copiedTargetRow = { ...targetRow };

    const convertedValue = isCheckbox
      ? value
      : convertFromStringIfBooleanOrNumber(
          value as string,
          getDataTypeFromDeliveryPropertyName(columnName as keyof DeliveryDto)
        );

    //@ts-ignore
    copiedTargetRow[columnName] = convertedValue;

    // prop = ;

    // Is debounce 1000ms

    const response = await DeliveryService.updateDeliveryForTable(
      convertAllNullToEmptyStringFromObject(copiedTargetRow)
    );

    setData((oldData) => {
      const toUpdate = [...oldData];
      // Using copiedTargetRow because it This doesn' give back dto
      toUpdate.splice(targetRowIndex, 1, copiedTargetRow);
      return toUpdate;
    });
  };
  const employeeList = useRef<{ label: string; name: string }[]>();

  const stringifyNumberOption = 4;
  const handleCopyFilterDebugInfoToClipboard = () => {
    let stringToShow = "";

    const dictOfThingsToPrint = {
      "General Options": JSON.stringify(
        computedCombinedFilters,
        null,
        stringifyNumberOption
      ),
      "Global Filter Storage": JSON.stringify(
        globalFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "User Filter Storage": JSON.stringify(
        userFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "Selected Global Filter Names": JSON.stringify(
        selectedGlobalFilterNames,
        null,
        stringifyNumberOption
      ),
      "Selected  User Filter Names": JSON.stringify(
        selectedUserFilterNames,
        null,
        stringifyNumberOption
      ),
      "Computed Selected Global Filters": JSON.stringify(
        computedSelectedGlobalFilters,
        null,
        stringifyNumberOption
      ),
      "Computed Selected  User Filters": JSON.stringify(
        computedSelectedUserFilters,
        null,
        stringifyNumberOption
      ),
      "Local Stroage": JSON.stringify(
        { ...localStorage },
        null,
        stringifyNumberOption
      ),
    };

    Object.entries(dictOfThingsToPrint).forEach(([key, value]) => {
      stringToShow += "\n";
      stringToShow += `~~~~${key}~~~~\n`;
      stringToShow += value;
    });

    window.navigator.clipboard.writeText(stringToShow);
  };

  const convertDefaultDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  const convertDefaultCheckbox = (checkboxStringValue: string | boolean) =>
    typeof checkboxStringValue === "boolean"
      ? checkboxStringValue
      : checkboxStringValue.toLowerCase() === "true";

  type testtest = { label: string; value: string };
  type OptionType = {
    label: string;
    value: string;
    title: string;
  };

  // don't think we need this part. All of the types are done above
  // const [typeTypes, setTypeTypes] = useState<OptionType[]>([]);
  // const [makeTypes, setMakeTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    const handleUpdatingType = async (
      dbManagedType: DbManagedTypes,
      updateFunc: (types: OptionType[]) => unknown
    ) => {
      const typeInteractionDto: TypeInteractionDto = {
        originalName: "",
        newName: "",
        dbManagedType: dbManagedType,
      };

      const response = await TypeService.GetNameList(typeInteractionDto);

      const types = response.data.map((type) => ({
        label: type,
        value: type,
        title: type,
      }));

      updateFunc(types);
    };

    handleUpdatingType(DbManagedTypes.InventoryType, setTypeTypes);
    handleUpdatingType(DbManagedTypes.Make, setMakeTypes);
  }, []);

  const handleUpdateType = useCallback(
    async (
      value: string | number | undefined,
      column: keyof DeliveryDto,
      dbManagedType: DbManagedTypes,
      stock: string,
      oldValue: string | number
    ) => {
      const oldV = isNullOrUndefined(oldValue) ? value : oldValue + "";

      if (oldV === value) {
        return;
      }

      if (!confirmingTableUpdate()) {
        return;
      }

      if (isNullOrUndefined(value)) {
        return;
      }

      const [, targetDto] = getTargetRowOrConsoleError(stock);

      const copiedTargetDto = { ...targetDto };

      //@ts-ignore
      copiedTargetDto[column] = value;

      const inventoryDto = DeliveryDtoToInventoryDto(
        copiedTargetDto
      ) as InventoryDto;

      inventoryDto["Inventory Title"] = (model as InventoryDto)[
        "Inventory Title"
      ];

      const result = await InventoryService.update(
        convertAllNullToEmptyStringFromObject(inventoryDto)
      );

      if (result.data.isSuccessful) {
        callEndpointAgain();
      }
    },
    [callEndpointAgain, getTargetRowOrConsoleError, model]
  );

  // const handleUpdateType = async (
  //     value: string | undefined,
  //     column: keyof DeliveryDto,
  //     dbManagedType: DbManagedTypes,
  //     stock: string
  // ) => {
  //     if (isNullOrUndefined(value)) {
  //         return;
  //     }

  //     const [, targetDto] = getTargetRowOrConsoleError(stock);

  //     const copiedTargetDto = { ...targetDto };

  //     //@ts-ignore
  //     copiedTargetDto[column] = value;

  //     const result = await DeliveryService.updateDeliveryForTable(
  //         convertAllNullToEmptyStringFromObject(copiedTargetDto)
  //     );

  //     setData((oldData) => {
  //         const targetIndex = oldData.findIndex((dto) => (dto as DeliveryDto)['Stock'] === stock);
  //         if (targetIndex === -1) {
  //             return oldData;
  //         }

  //         const duplicate = [...oldData];
  //         duplicate.splice(targetIndex, 1, copiedTargetDto);
  //         return duplicate;
  //     });
  // };

  const tableDivContainerRef = useRef<HTMLDivElement>(null);

  const INPUT_CLASS_NAME = "inventory-table-input-class-name";

  // useEffect(() => {
  //     if (isNullOrUndefined(tableDivContainerRef.current)) {
  //         console.log('table div element is undefined');
  //         return;
  //     }

  //     const tableDiv = tableDivContainerRef.current;

  //     Array.from(tableDiv.getElementsByClassName(INPUT_CLASS_NAME)).forEach((inputEl) => {
  //         // not null bc of filter above.
  //         const notNullInput = inputEl as HTMLInputElement | HTMLSelectElement;
  //         const [stock, name] = notNullInput.name.split(':');

  //         const target = (data as DeliveryDto[]).find((dto) => dto['Stock'] === stock);

  //         if (isNullOrUndefined(target)) {
  //             const errorMessage = `Attempting to find row with invoice number ${stock} but can't be found! Please alert web developers`;
  //             console.error(errorMessage);
  //             alert(errorMessage);
  //             return;
  //         }

  //         let value = target[name as keyof DeliveryDto];

  //         if (isNullOrUndefined(value) || value === '0.00') {
  //             value = '';
  //         }
  //         const dataType = getDataTypeFromDeliveryPropertyName(name as keyof DeliveryDto);
  //         if (dataType === DataType.DateTime) {
  //             if (value === '') {
  //                 // Will look like dd/mm/yyyy. Want it to be empty. So we'll set color to transparent when not focused
  //                 (inputEl as HTMLInputElement).classList.add(styles.dateInputEmpty);
  //             } else {
  //                 (inputEl as HTMLInputElement).classList.remove(styles.dateInputEmpty);

  //                 value = convertDefaultDate(value as string);
  //             }
  //         }
  //         if (dataType === DataType.Bool) {
  //             value = convertDefaultCheckbox(value as string | boolean);
  //         }

  //         notNullInput.value = value + '';
  //     });
  // }, [data]);

  const [stockForCurrentlyOpenNoteModal, setStockForCurrentlyOpenNoteModal] =
    useState<string>();

  const handleSelectOnChangeUpdatingRows = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    colSize: number
  ) => {
    const textArea = e.target as HTMLTextAreaElement;
    const value = e.target.value;

    const quotient = value.length / colSize;

    const rowsToSet = Math.floor(quotient) + 1;

    if (textArea.rows !== rowsToSet) {
      textArea.rows = rowsToSet;
    }
  };

  // Idea go through each value of the column and determine the biggest width. and then perhaps
  // set the inputs to width 100% unless they match the biggest width. I'm not sure.

  const generateInputStylesAndUpdateBiggestInputWidth = (
    valueToLookAt: string,
    columnName: string
  ) => {
    let width = 0;
    if (
      isNotNullNorUndefined(valueToLookAt) &&
      valueToLookAt !== "" &&
      valueToLookAt !== "null"
    ) {
      width = valueToLookAt.length;
    }
    return { width: width + CH_OFFSET + "ch", minWidth: MIN_WIDTH_INPUT };
  };

  const savedFunctionsToUpdateData = {
    "Stock": (property: keyof DeliveryDto, Stock: string, stock: string) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onMouseUp={(e) => {
          if (e.button === 1) {
            if ((e.target as Element).tagName === "A") {
              // should be handled by the anchor tag itself
              return;
            }
            e.preventDefault();
            const link = `/inventory/edit/${IdentifierType.Stock}/${Stock}`;

            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }
        }}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const link = `/inventory/edit/${IdentifierType.Stock}/${Stock}`;

          if (e.ctrlKey) {
            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }

          if (e.shiftKey) {
            window.open(link);
            e.stopPropagation();
            return;
          }

          history.push({
            pathname: `/inventory/edit/${IdentifierType.Stock}/${Stock}`,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
              fromDelivery: true,
            } as LocationType,
          });
        }}
      >
        <NavLink
          to={{
            pathname: `/inventory/edit/${IdentifierType.Stock}/${Stock}`,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
              fromDelivery: true,
            } as LocationType,
          }}
        >
          {Stock}
        </NavLink>
      </td>
    ),

    "Delivered Date": (
      property: keyof DeliveryDto,
      DeliveredDate: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(DeliveredDate) ||
                              DeliveredDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={
            convertToInputDateFormat(DeliveredDate)
            // convertDefaultDate(DeliveredDate)
          }
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              DeliveredDate,
              () => {
                e.target.value = convertToInputDateFormat(DeliveredDate);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Permit Number": (
      property: keyof DeliveryDto,
      PermitNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(PermitNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={PermitNumber}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              PermitNumber,
              () => (e.target.value = PermitNumber + "")
            );
          }}
        />
      </td>
    ),
    "Make": (
      property: keyof DeliveryDto,
      Make: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={Make + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              makeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.Make,
                stock,
                Make
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: Make + "",
              value: Make + "",
              title: Make + "",
            }}
          />
        </div>
      </td>
    ),
    "Inventory Type": (
      property: keyof DeliveryDto,
      InventoryType: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={InventoryType + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              typeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) => {
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.InventoryType,
                stock,
                InventoryType
              );
            }}
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            // defaultValue={{
            //     label: Type + '',
            //     value: Type + '',
            //     title: Type + '',
            // }}

            value={{
              label: InventoryType + "",
              value: InventoryType + "",
              title: InventoryType + "",
            }}
          />
        </div>
      </td>
    ),
    "Year": (
      property: keyof DeliveryDto,
      Year: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={generateInputStylesAndUpdateBiggestInputWidth(
            Year + "",
            property
          )}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          // onChange={(e) => handleInputUpdateValueProvider(e, 'Year', stock)}
          defaultValue={Year}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Year,
              () => (e.target.value = Year + "")
            );
          }}
        />
      </td>
    ),
    "VIN": (
      property: keyof DeliveryDto,
      VIN: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={generateInputStylesAndUpdateBiggestInputWidth(
            VIN + "",
            property
          )}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          type="text"
          // onChange={(e) => handleInputUpdateValueProvider(e, 'VIN', stock)}

          defaultValue={VIN}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              VIN,
              () => (e.target.value = VIN + "")
            );
          }}
        />
      </td>
    ),

    "Inventory Title": (
      property: keyof DeliveryDto,
      InventoryTitle: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={generateInputStylesAndUpdateBiggestInputWidth(
            InventoryTitle + "",
            property
          )}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          type="text"
          // onChange={(e) => handleInputUpdateValueProvider(e, 'VIN', stock)}

          defaultValue={InventoryTitle}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              InventoryTitle,
              () => (e.target.value = InventoryTitle + "")
            );
          }}
        />
      </td>
    ),
    "Temp Tag Expiration": (
      property: keyof DeliveryDto,
      TempTagExpiration: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(TempTagExpiration) ||
                              TempTagExpiration.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertDefaultDate(TempTagExpiration)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              TempTagExpiration,
              () => {
                e.target.value = convertToInputDateFormat(TempTagExpiration);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Carrier Phone": (
      property: keyof DeliveryDto,
      CarrierPhone: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (CarrierPhone + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={CarrierPhone}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              CarrierPhone,
              () => (e.target.value = CarrierPhone + "")
            );
          }}
        />
      </td>
    ),
    "Address": (
      property: keyof DeliveryDto,
      Address: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Address + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Address}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Address,
              () => (e.target.value = Address + "")
            );
          }}
        />
      </td>
    ),
    "City": (
      property: keyof DeliveryDto,
      City: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={SMALL_COLS_SIZE}
          rows={(City + "").length < SMALL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) => handleSelectOnChangeUpdatingRows(e, SMALL_COLS_SIZE)}
          defaultValue={City}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              City,
              () => (e.target.value = City + "")
            );
          }}
        />
      </td>
    ),
    "State": (
      property: keyof DeliveryDto,
      State: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={SMALL_COLS_SIZE}
          rows={(State + "").length < SMALL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) => handleSelectOnChangeUpdatingRows(e, SMALL_COLS_SIZE)}
          defaultValue={State}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              State,
              () => (e.target.value = State + "")
            );
          }}
        />
      </td>
    ),
    "Zip": (
      property: keyof DeliveryDto,
      Zip: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (Zip + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={Zip}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Zip,
              () => (e.target.value = Zip + "")
            );
          }}
        />
      </td>
    ),
    "Carrier Name": (
      property: keyof DeliveryDto,
      CarrierName: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(CarrierName + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={CarrierName}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              CarrierName,
              () => (e.target.value = CarrierName + "")
            );
          }}
        />
      </td>
    ),
    "Carrier Truck Number": (
      property: keyof DeliveryDto,
      CarrierTruckNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (CarrierTruckNumber + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={CarrierTruckNumber}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              CarrierTruckNumber,
              () => (e.target.value = CarrierTruckNumber + "")
            );
          }}
        />
      </td>
    ),
    "Driver First Name": (
      property: keyof DeliveryDto,
      DriverFirstName: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (DriverFirstName + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={DriverFirstName}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              DriverFirstName,
              () => (e.target.value = DriverFirstName + "")
            );
          }}
        />
      </td>
    ),
    "Driver Last Name": (
      property: keyof DeliveryDto,
      DriverLastName: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (DriverLastName + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={DriverLastName}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              DriverLastName,
              () => (e.target.value = DriverLastName + "")
            );
          }}
        />
      </td>
    ),
    "Notes": (
      property: keyof DeliveryDto,
      Notes: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Notes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Notes}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Notes,
              () => (e.target.value = Notes + "")
            );
          }}
        />
      </td>
    ),
    "Accounting Status": (
      property: keyof DeliveryDto,
      AccountingS: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={AccountingS}
          onChange={
            (e) =>
              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                AccountingS,
                () => (e.target.value = AccountingS + "")
              )
            // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
          }
        >
          {Object.entries(AccountingStatus)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "Accounting Date": (
      property: keyof DeliveryDto,
      AccountingDate: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(AccountingDate) ||
                              AccountingDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(AccountingDate)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              AccountingDate,
              () => {
                e.target.value = convertToInputDateFormat(AccountingDate);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Receipt Number": (
      property: keyof DeliveryDto,
      ReceiptNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(ReceiptNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={ReceiptNumber}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ReceiptNumber,
              () => (e.target.value = ReceiptNumber + "")
            );
          }}
        />
      </td>
    ),

    "Spec": (
      property: keyof DeliveryDto,
      Spec: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Spec + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Spec}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Spec,
              () => (e.target.value = Spec + "")
            );
          }}
        />
      </td>
    ),

    "Invoice Number": (
      property: keyof DeliveryDto,
      InvoiceNumber: string | number,
      stock: string
    ) => {
      return (
        <td
          className={`${styles.tData}`}
          key={`${stock}-${property}`}
          onMouseUp={(e) => {
            if (
              InvoiceNumber + "".trim() === "" ||
              isNullOrUndefined(InvoiceNumber)
            ) {
              return;
            }

            if (e.button === 1) {
              if ((e.target as Element).tagName === "A") {
                // should be handled by the anchor tag itself
                return;
              }
              e.preventDefault();
              const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

              window.open(link, "_blank");
              e.stopPropagation();
              return;
            }
          }}
          onClick={(e) => {
            if (
              InvoiceNumber + "".trim() === "" ||
              isNullOrUndefined(InvoiceNumber)
            ) {
              return;
            }
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }
            const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

            if (e.ctrlKey) {
              window.open(link, "_blank");
              e.stopPropagation();
              return;
            }

            if (e.shiftKey) {
              window.open(link);
              e.stopPropagation();
              return;
            }
            // Will implement the state and bringing back to search results later..
            history.push({
              pathname: `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`,
              state: {
                selectedGlobalFilterNames,
                selectedUserFilterNames,
                globalFiltersStorage,
                userFiltersStorage,
                customFilters,
                ignoredFilterIds,
              } as LocationType,
            });
          }}
        >
          {InvoiceNumber + "".trim() === "" ||
          isNullOrUndefined(InvoiceNumber) ? (
            <></>
          ) : (
            <NavLink
              // onClick={() => {
              // history.replace(location.pathname, { test: 'hello' });
              // }}
              to={{
                pathname: `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`,
                // Will implement the state and bringing back to search results later..

                state: {
                  selectedGlobalFilterNames,
                  selectedUserFilterNames,
                  globalFiltersStorage,
                  userFiltersStorage,
                  customFilters,
                  ignoredFilterIds,
                } as LocationType,
              }}
            >
              {InvoiceNumber}
            </NavLink>
          )}
        </td>
      );
    },
    "Yard Loc": (
      property: keyof DeliveryDto,
      YardLoc: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        // style={{ color: 'black', width: '8rem' }}
        key={`${stock}-${property}`}
        onMouseUp={(e) => {
          if (e.button === 1) {
            if ((e.target as Element).tagName === "A") {
              // should be handled by the anchor tag itself
              return;
            }
            e.preventDefault();
            const link = `/yard-location/edit/${IdentifierType.YardStall}/${YardLoc}`;

            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }
        }}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const link = `/yard-location/edit/${IdentifierType.YardStall}/${YardLoc}`;

          if (e.ctrlKey) {
            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }

          if (e.shiftKey) {
            window.open(link);
            e.stopPropagation();
            return;
          }

          history.push({
            pathname: `/yard-location/edit/${IdentifierType.YardStall}/${YardLoc}`,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
            } as LocationType,
          });
        }}
      >
        <NavLink
          to={{
            pathname: `/yard-location/edit/${IdentifierType.YardStall}/${YardLoc}`,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
            } as LocationType,
          }}
          style={{ margin: "0 1rem" }}
        >
          {YardLoc}
        </NavLink>
      </td>
    ),

    // 'Inventory Number': (property: keyof DeliveryDto, InventoryNumber: string | number, stock: string) => (
    //     <td
    //         className={styles.tData}
    //         key={`${stock}-${property}`}
    //         onClick={(e) => {
    //             const tableDataEl = e.target as HTMLTableCellElement;
    //             if (tableDataEl.tagName !== 'TD') {
    //                 return;
    //             }

    //             const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

    //             inputBelow.focus();
    //         }}>
    //         <textarea
    //             name={`${stock}:${property}`}
    //             cols={NORMAL_COLS_SIZE}
    //             rows={(InventoryNumber + '').length < NORMAL_COLS_SIZE ? 1 : 2}
    //             className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
    //             onChange={(e) => handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)}
    //             defaultValue={InventoryNumber}
    //             onBlur={(e) => {
    //                 handleNewUpdaterFunction(
    //                     RecordType.Delivery,
    //                     e.target.value,
    //                     property,
    //                     stock,
    //                     InventoryNumber,
    //                     () => (e.target.value = InventoryNumber + '')
    //                 );
    //             }}
    //         />
    //     </td>
    // ),

    "Inventory Number": (
      property: keyof DeliveryDto,
      InventoryNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(InventoryNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={InventoryNumber}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              InventoryNumber,
              () => (e.target.value = InventoryNumber + "")
            )
          }
        />
      </td>
    ),

    "Price": (
      property: keyof DeliveryDto,
      Price: string | number,
      stock: string
    ) => {
      const priceDisplayValue = "$" + CommaAdderToNumber(Price as number);

      return (
        <td
          className={styles.tData}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            style={{
              ...generateInputStylesAndUpdateBiggestInputWidth(
                priceDisplayValue,
                property
              ),
            }}
            onFocus={(e) => {
              (e.target as HTMLInputElement).value = Price + "";
            }}
            className={`${INPUT_CLASS_NAME}`}
            name={`${stock}:${property}`}
            defaultValue={priceDisplayValue}
            onBlur={async (e) => {
              const inputSame = await handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                Price,
                () => (e.target.value = priceDisplayValue),
                (value) => {
                  e.target.value = "$" + CommaAdderToNumber(+value);
                }
              );

              if (inputSame) {
                e.target.value = priceDisplayValue;
              }
            }}
            // onChange={(e) => handleInputUpdateValueProvider(e, 'Price', stock)}
          />
        </td>
      );
    },

    // 'Comp Date': (property: keyof DeliveryDto, CompDate: string | number, stock: string) => (
    //     <td
    //         className={styles.tData}
    //         key={`${stock}-${property}`}
    //         onClick={(e) => {
    //             const tableDataEl = e.target as HTMLTableCellElement;
    //             if (tableDataEl.tagName !== 'TD') {
    //                 return;
    //             }

    //             const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

    //             inputBelow.focus();
    //         }}>
    //         <textarea
    //             name={`${stock}:${property}`}
    //             cols={NORMAL_COLS_SIZE}
    //             rows={(CompDate + '').length < NORMAL_COLS_SIZE ? 1 : 2}
    //             className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
    //             onChange={(e) => handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)}
    //             defaultValue={CompDate}
    //             onBlur={(e) =>
    //                 handleNewUpdaterFunction(
    //                     RecordType.Delivery,
    //                     e.target.value,
    //                     property,
    //                     stock,
    //                     CompDate,
    //                     () => (e.target.value = CompDate + '')
    //                 )
    //             }
    //         />
    //     </td>
    // ),

    "Misc Date": (
      property: keyof DeliveryDto,
      MiscDate: string,
      stock: string
    ) => {
      return (
        <td
          className={styles.tData}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(MiscDate) ||
                              MiscDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
            name={`${stock}:${property}`}
            type="date"
            defaultValue={convertToInputDateFormat(MiscDate)}
            onFocus={(e) => (e.target.style.opacity = "1")}
            onBlur={(e) => {
              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                MiscDate,
                () => {
                  e.target.value = convertToInputDateFormat(MiscDate);
                }
              );
              if (
                e.target.value.trim() === "" ||
                isNullOrUndefined(e.target.value)
              ) {
                e.target.style.opacity = "0";
              }
            }}
          />
        </td>
      );
    },

    "Comp Date": (
      property: keyof DeliveryDto,
      CompDate: string,
      stock: string
    ) => {
      return (
        <td
          className={styles.tData}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(CompDate) ||
                              CompDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
            name={`${stock}:${property}`}
            type="date"
            defaultValue={convertToInputDateFormat(CompDate)}
            onFocus={(e) => (e.target.style.opacity = "1")}
            onBlur={(e) => {
              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                CompDate,
                () => {
                  e.target.value = convertToInputDateFormat(CompDate);
                }
              );

              if (
                e.target.value.trim() === "" ||
                isNullOrUndefined(e.target.value)
              ) {
                e.target.style.opacity = "0";
              }
            }}
          />
        </td>
      );
    },

    "Inspection Date": (
      property: keyof DeliveryDto,
      InspectionDate: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(InspectionDate) ||
                              InspectionDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(InspectionDate)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              InspectionDate,
              () => {
                e.target.value = convertToInputDateFormat(InspectionDate);
              }
            );

            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Received Date": (
      property: keyof DeliveryDto,
      ReceivedDate: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(ReceivedDate) ||
                              ReceivedDate.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(ReceivedDate)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ReceivedDate,
              () => {
                e.target.value = convertToInputDateFormat(ReceivedDate);
              }
            );

            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),

    "Advertise": (
      property: keyof DeliveryDto,
      Advertise: string | boolean,
      stock: string
    ) => {
      return (
        <td
          className={styles.tData}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            className={INPUT_CLASS_NAME}
            name={`${stock}:${property}`}
            type="checkbox"
            checked={convertDefaultCheckbox(Advertise)}
            // onBlur={(e) => handleNewUpdaterFunction(e.target.checked, property, stock, DOTComp)}
            onChange={(e) => {
              handleNewUpdaterFunction(
                RecordType.Delivery,
                !cTB(Advertise),
                property,
                stock,
                cTB(Advertise)
              );
            }}
          />
        </td>
      );
    },

    "DOT Status": (
      property: keyof DeliveryDto,
      DOTStatus: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          cols={10}
          rows={(DOTStatus + "").length < 10 ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          name={`${stock}:${property}`}
          defaultValue={DOTStatus}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              DOTStatus,
              () => (e.target.value = DOTStatus + "")
            );
          }}
        />
      </td>
    ),
    "Decal Id": (
      property: keyof DeliveryDto,
      DecalId: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(DecalId + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={DecalId}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              DecalId,
              () => (e.target.value = DecalId + "")
            )
          }
        />
      </td>
    ),
    "City Location": (
      property: keyof DeliveryDto,
      CityLocation: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={CityLocation + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              cityLocationTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.CityLocation,
                stock,
                CityLocation + ""
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: CityLocation + "",
              value: CityLocation + "",
              title: CityLocation + "",
            }}
            name="test"
          />
        </div>
      </td>
    ),

    "Cost": (
      property: keyof DeliveryDto,
      Cost: string | number,
      stock: string
    ) => {
      const costDisplayValue = "$" + CommaAdderToNumber(Cost as number);

      return (
        <td
          className={styles.tData}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            style={{
              ...generateInputStylesAndUpdateBiggestInputWidth(
                costDisplayValue,
                property
              ),
            }}
            onFocus={(e) => {
              (e.target as HTMLInputElement).value = Cost + "";
            }}
            className={`${INPUT_CLASS_NAME} `}
            name={`${stock}:${property}`}
            defaultValue={costDisplayValue}
            onBlur={async (e) => {
              const inputSame = await handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                Cost,
                () => (e.target.value = costDisplayValue),
                (value) => {
                  e.target.value = "$" + CommaAdderToNumber(+value);
                }
              );

              if (inputSame) {
                e.target.value = costDisplayValue;
              }
            }}
            // onChange={(e) => handleInputUpdateValueProvider(e, 'Price', stock)}
          />
        </td>
      );
    },

    "Sub Type": (
      property: keyof DeliveryDto,
      SubType: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          disabled={true}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          value={SubType}
          // onBlur={(e) => handleNewUpdaterFunction(e.target.value, property, stock, SubType)}
          // onChange={(e) => handleNewUpdaterFunction(e.target.value, property, stock, SubType)}
        >
          {Object.entries(InventorySubType)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "Model": (
      property: keyof DeliveryDto,
      Model: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={Model + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              modelTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.Model,
                stock,
                Model
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: Model + "",
              value: Model + "",
              title: Model + "",
            }}
          />
        </div>
      </td>
    ),
    "Color": (
      property: keyof DeliveryDto,
      Color: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Color + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Color}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Color,
              () => (e.target.value = Color + "")
            )
          }
        />
      </td>
    ),
    "Condition": (
      property: keyof DeliveryDto,
      Condition: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={Condition}
          onChange={
            (e) =>
              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                Condition,
                () => (e.target.value = Condition + "")
              )
            // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
          }
        >
          {Object.entries(InventoryCondition)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "Status - I": (
      property: keyof DeliveryDto,
      StatusI: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          className={INPUT_CLASS_NAME}
          disabled={
            !canUserAccessAdminSecurityWithGivenRoles(roles) &&
            StatusI === InventoryStatus.Sold
          }
          name={`${stock}:${property}`}
          defaultValue={StatusI}
          onChange={
            (e) =>
              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                StatusI,
                () => (e.target.value = StatusI + "")
              )
            // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
          }
          // onChange={(e) => handleSelectOrTextAreaUpdateValueProvider(e, 'Status', stock, )}
        >
          {Object.entries(InventoryStatus)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option
                key={value}
                disabled={
                  key === "Sold" &&
                  !canUserAccessAdminSecurityWithGivenRoles(roles)
                }
                label={key}
                value={value}
              >
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "L": (property: keyof DeliveryDto, L: string | number, stock: string) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div
          className={`${styles.asyncSelectDiv} ${styles.measurement}`}
          title={L + ""}
        >
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              lengthTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.Length,
                stock,
                L
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: L + "",
              value: L + "",
              title: L + "",
            }}
          />
        </div>
      </td>
    ),
    "W": (property: keyof DeliveryDto, W: string | number, stock: string) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div
          className={`${styles.asyncSelectDiv} ${styles.measurement}`}
          title={W + ""}
        >
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={widthTypes}
            // loadOptions={
            //     async () => widthTypes
            //     // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            // }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.Width,
                stock,
                W
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: W + "",
              value: W + "",
              title: W + "",
            }}
          />
        </div>
      </td>
    ),
    "H": (property: keyof DeliveryDto, H: string | number, stock: string) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div
          className={`${styles.asyncSelectDiv} ${styles.measurement}`}
          title={H + ""}
        >
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              heightTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.Height,
                stock,
                H
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: H + "",
              value: H + "",
              title: H + "",
            }}
          />
        </div>
      </td>
    ),
    "Wheel Sz": (
      property: keyof DeliveryDto,
      WheelSz: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={WheelSz + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              wheelSizeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.WheelSize,
                stock,
                WheelSz
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: WheelSz + "",
              value: WheelSz + "",
              title: WheelSz + "",
            }}
          />
        </div>
      </td>
    ),
    "Wheel Type": (
      property: keyof DeliveryDto,
      WheelType: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={WheelType + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              wheelTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.WheelType,
                stock,
                WheelType
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            // filterOption={(option, search) => {
            //     // return true;
            //     return option.value.includes(search.toLowerCase()) || search === '';
            // }}
            value={{
              label: WheelType + "",
              value: WheelType + "",
              title: WheelType + "",
            }}
          />
        </div>
      </td>
    ),
    "Axles": (
      property: keyof DeliveryDto,
      Axles: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Axles + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Axles}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Axles,
              () => (e.target.value = Axles + "")
            )
          }
        />
      </td>
    ),
    "Suspension Type": (
      property: keyof DeliveryDto,
      SuspensionType: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={SuspensionType + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              suspensionTypeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.SuspensionType,
                stock,
                SuspensionType
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: SuspensionType + "",
              value: SuspensionType + "",
              title: SuspensionType + "",
            }}
          />
        </div>
      </td>
    ),
    "Suspension Style": (
      property: keyof DeliveryDto,
      SuspensionStyle: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={SuspensionStyle + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              suspensionStyleTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.SuspensionStyle,
                stock,
                SuspensionStyle
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: SuspensionStyle + "",
              value: SuspensionStyle + "",
              title: SuspensionStyle + "",
            }}
          />
        </div>
      </td>
    ),
    "KP": (property: keyof DeliveryDto, KP: string | number, stock: string) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(KP + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={KP}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              KP,
              () => (e.target.value = KP + "")
            )
          }
        />
      </td>
    ),
    "Floor Type": (
      property: keyof DeliveryDto,
      FloorType: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={FloorType + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              floorTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.FloorType,
                stock,
                FloorType
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: FloorType + "",
              value: FloorType + "",
              title: FloorType + "",
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                width:
                  state.selectProps.menuPlacement === "bottom" &&
                  state.selectProps.menuPortalTarget
                    ? state.selectProps.menuPortalTarget.getBoundingClientRect()
                        .width + "px"
                    : "100%",
              }),
            }}
          />
        </div>
      </td>
    ),
    "Rear Door Type": (
      property: keyof DeliveryDto,
      RearDoorType: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={RearDoorType + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              rearDoorTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.RearDoorType,
                stock,
                RearDoorType
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: RearDoorType + "",
              value: RearDoorType + "",
              title: RearDoorType + "",
            }}
          />
        </div>
      </td>
    ),
    "Side Doors": (
      property: keyof DeliveryDto,
      SideDoors: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(SideDoors + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={SideDoors}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              SideDoors,
              () => (e.target.value = SideDoors + "")
            )
          }
        />
      </td>
    ),
    "Reefer Serial Number": (
      property: keyof DeliveryDto,
      ReeferSerialNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(ReeferSerialNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={ReeferSerialNumber}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ReeferSerialNumber,
              () => (e.target.value = ReeferSerialNumber + "")
            )
          }
        />
      </td>
    ),
    "Reefer Engine Year": (
      property: keyof DeliveryDto,
      ReeferEngineYear: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(ReeferEngineYear + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={ReeferEngineYear}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ReeferEngineYear,
              () => (e.target.value = ReeferEngineYear + "")
            )
          }
        />
      </td>
    ),
    "Reefer Make": (
      property: keyof DeliveryDto,
      ReeferMake: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={ReeferMake + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              reeferMakeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.ReeferMake,
                stock,
                ReeferMake
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: ReeferMake + "",
              value: ReeferMake + "",
              title: ReeferMake + "",
            }}
          />
        </div>
      </td>
    ),
    "Reefer Model": (
      property: keyof DeliveryDto,
      ReeferModel: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={ReeferModel + ""}>
          <Select
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            options={
              reeferModelTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            onChange={(e) =>
              handleUpdateType(
                e?.value,
                property,
                DbManagedTypes.ReeferModel,
                stock,
                ReeferModel
              )
            }
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            value={{
              label: ReeferModel + "",
              value: ReeferModel + "",
              title: ReeferModel + "",
            }}
          />
        </div>
      </td>
    ),
    "Unit Hours": (
      property: keyof DeliveryDto,
      UnitHours: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(UnitHours + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={UnitHours}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              UnitHours,
              () => (e.target.value = UnitHours + "")
            )
          }
        />
      </td>
    ),
    "Reefer Year": (
      property: keyof DeliveryDto,
      ReeferYear: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(ReeferYear + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={ReeferYear}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ReeferYear,
              () => (e.target.value = ReeferYear + "")
            )
          }
        />
      </td>
    ),
    "Reefer Desc": (
      property: keyof DeliveryDto,
      ReeferDesc: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(ReeferDesc + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={ReeferDesc}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ReeferDesc,
              () => (e.target.value = ReeferDesc + "")
            )
          }
        />
      </td>
    ),
    "Entered In CDK": (
      property: keyof DeliveryDto,
      EnteredInCDK: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(EnteredInCDK) ||
                              EnteredInCDK.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(EnteredInCDK)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              EnteredInCDK,
              () => {
                e.target.value = convertToInputDateFormat(EnteredInCDK);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Ordered Date - I": (
      property: keyof DeliveryDto,
      OrderedDateI: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(OrderedDateI) ||
                              OrderedDateI.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(OrderedDateI)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              OrderedDateI,
              () => {
                e.target.value = convertToInputDateFormat(OrderedDateI);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Notes - I": (
      property: keyof DeliveryDto,
      NotesI: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(NotesI + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={NotesI}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              NotesI,
              () => (e.target.value = NotesI + "")
            )
          }
        />
      </td>
    ),
    "Photo Status": (
      property: keyof DeliveryDto,
      PhotoStatusValue: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={PhotoStatusValue}
          onChange={
            (e) =>
              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                PhotoStatusValue,
                () => (e.target.value = PhotoStatusValue + "")
              )
            // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
          }
        >
          {Object.entries(PhotoStatus)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "Split Deal - I": (
      property: keyof DeliveryDto,
      SplitDealI: string | boolean,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          type="checkbox"
          checked={convertDefaultCheckbox(SplitDealI)}
          readOnly={true}
          // onBlur={(e) => handleNewUpdaterFunction(e.target.checked, property, stock, SplitDealI)}
          // onChange={(e) => {
          //     handleNewUpdaterFunction(
          //         RecordType.Delivery,
          //         !cTB(SplitDealI),
          //         property,
          //         stock,
          //         cTB(SplitDealI)
          //     );
          // }}
        />
      </td>
    ),
    "Ordered Date - S": (
      property: keyof DeliveryDto,
      OrderedDateS: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(OrderedDateS) ||
                              OrderedDateS.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(OrderedDateS)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              OrderedDateS,
              () => {
                e.target.value = convertToInputDateFormat(OrderedDateS);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Customer Name": (
      property: keyof DeliveryDto,

      CustomerName: string | number,
      stock: string
    ) => {
      const deliveryDto = getTargetRowOrConsoleError(stock)[1];
      return (
        <td className={styles.tData} key={`${stock}-${property}`}>
          <AsyncSelect
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            className={styles.actualAsyncSelect}
            onChange={async (e) => {
              if (!confirmingTableUpdate()) {
                callEndpointAgain();
                return;
              }
              if (isNullOrUndefined(e)) {
                return;
              }

              if (e.value === "Create Customer") {
                setShowCreateCustomerModal(true);
                setStockNumberSelectedForCreateCustomer(stock);
                return;
              }

              if (isNullOrUndefined(e.value)) {
                makeSimpleToast(
                  "Error!",
                  "Please make sure you put a value for Customer Name"
                );
                return;
              }

              const response = await CustomerService.SearchByName(
                new StringDto(e.value + "")
              );

              // If only CreateCustomer is returned don't pass any more data
              if (response.data.model.length == 1) {
                return {};
              }

              const customerName = e.value + "";

              // Grabbing the second in the list because the first one is always
              // the 'Create Customer' Option
              const customerNumber = response.data.model[1].identifier ?? "";

              // setTimeout(() => {
              //     if (isNotNullNorUndefined(customerNameRef.current)) {
              //         customerNameRef.current.setValue(
              //             { label: customerName, value: customerName },
              //             'select-option'
              //         );
              //     }
              // }, 1000);

              handleCustomerUpdate(
                deliveryDto["Invoice Number"],
                customerName,
                customerNumber
              );
            }}
            loadOptions={SearchForCustomer}
            // defaultValue={{ value: StartingCustomerName + '', label: StartingCustomerName + '' }}
            value={{ label: CustomerName, value: CustomerName } ?? undefined}
            // defaultValue={{ label: StartingCustomerName, value: StartingCustomerName } ?? undefined}
            // cacheOptions
            closeMenuOnSelect
            placeholder={"Search by Customer Name"}
          />
        </td>
      );
    },
    "Sales Person": (
      property: keyof DeliveryDto,
      SalesPerson: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <AsyncSelect
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          className={styles.actualAsyncSelect}
          value={{
            label: SalesPerson,
            value: SalesPerson,
          }}
          loadOptions={
            // SearchForEmployee
            isNullOrUndefined(employeeList) || !Array.isArray(employeeList)
              ? SearchForEmployee
              : async () => employeeList
          }
          cacheOptions={true}
          defaultOptions={true}
          onChange={(e) => {
            if (isNullOrUndefined(e)) {
              return;
            }

            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.value,
              property,
              stock,
              SalesPerson
            );
            // handleUnDebouncedUpdate(e.value, false, property, invoiceNumber);
          }}
        />
      </td>
    ),
    "Delivered Count": (
      property: keyof DeliveryDto,
      DeliveredCount: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          readOnly={true}
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(DeliveredCount + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          defaultValue={DeliveredCount}
        />
      </td>
    ),
    "Notes - S": (
      property: keyof DeliveryDto,
      NotesS: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(NotesS + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={NotesS}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              NotesS,
              () => (e.target.value = NotesS + "")
            )
          }
        />
      </td>
    ),
    "Deposit": (
      property: keyof DeliveryDto,
      Deposit: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          // style={generateInputStylesAndUpdateBiggestInputWidth(Deposit + '', property)}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={Deposit}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Deposit,
              () => (e.target.value = Deposit + "")
            );
          }}
          // onChange={(e) => handleInputUpdateValueProvider(e, 'Deposit', stock)}
        />
      </td>
    ),
    "Customer #": (
      property: keyof DeliveryDto,
      CustomerNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          readOnly={true}
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(CustomerNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          value={CustomerNumber}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              CustomerNumber,
              () => (e.target.value = CustomerNumber + "")
            )
          }
        />
      </td>
    ),
    "Lienholder": (
      property: keyof DeliveryDto,
      Lienholder: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Lienholder + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Lienholder}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              Lienholder,
              () => (e.target.value = Lienholder + "")
            )
          }
        />
      </td>
    ),
    "Proof Of Payment Received": (
      property: keyof DeliveryDto,
      ProofOfPaymentReceived: string,
      stock: string
    ) => (
      <td className={styles.tData} key={`${stock}-${property}`}>
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(ProofOfPaymentReceived) ||
                              ProofOfPaymentReceived.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertDefaultDate(ProofOfPaymentReceived)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              ProofOfPaymentReceived,
              () =>
                (e.target.value =
                  convertDefaultDate(ProofOfPaymentReceived) + "")
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Check Number - I": (
      property: keyof DeliveryDto,
      CheckNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(CheckNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={CheckNumber}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              CheckNumber,
              () => (e.target.value = CheckNumber + "")
            )
          }
        />
      </td>
    ),
    "Check Number - S": (
      property: keyof DeliveryDto,
      CheckNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(CheckNumber + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={CheckNumber}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              CheckNumber,
              () => (e.target.value = CheckNumber + "")
            )
          }
        />
      </td>
    ),
    "Title Work Completed": (
      property: keyof DeliveryDto,
      TitleWorkCompleted: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(TitleWorkCompleted) ||
                              TitleWorkCompleted.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(TitleWorkCompleted)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              TitleWorkCompleted,
              () => {
                e.target.value = convertToInputDateFormat(TitleWorkCompleted);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),

    "NAPP": (
      property: keyof DeliveryDto,
      NAPP: string | boolean,
      stock: string
    ) => {
      return (
        <td
          className={styles.tData}
          key={`${stock}-${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
        >
          <input
            className={INPUT_CLASS_NAME}
            name={`${stock}:${property}`}
            type="checkbox"
            checked={convertDefaultCheckbox(NAPP)}
            // onBlur={(e) => handleNewUpdaterFunction(e.target.checked, property, stock, DOTComp)}
            onChange={(e) => {
              handleNewUpdaterFunction(
                RecordType.Delivery,
                !cTB(NAPP),
                property,
                stock,
                cTB(NAPP)
              );
            }}
          />
        </td>
      );
    },
    "Docs Signed": (
      property: keyof DeliveryDto,
      DocsSigned: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(DocsSigned) ||
                              DocsSigned.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
          name={`${stock}:${property}`}
          type="date"
          defaultValue={convertToInputDateFormat(DocsSigned)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              DocsSigned,
              () => {
                e.target.value = convertToInputDateFormat(DocsSigned);
              }
            );
            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Misc Tracking": (
      property: keyof DeliveryDto,
      MiscTracking: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(MiscTracking + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={MiscTracking}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              MiscTracking,
              () => (e.target.value = MiscTracking + "")
            )
          }
        />
      </td>
    ),
    "All Delivered": (
      property: keyof DeliveryDto,
      AllDelivered: string | boolean,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          type="checkbox"
          readOnly={true}
          checked={convertDefaultCheckbox(AllDelivered)}
          // onBlur={(e) => handleNewUpdaterFunction(e.target.checked, property, stock, DOTComp)}
          // onChange={(e) => {
          //     handleNewUpdaterFunction(
          //         RecordType.Delivery,
          //         !cTB(AllDelivered),
          //         property,
          //         stock,
          //         cTB(AllDelivered)
          //     );
          // }}
        />
      </td>
    ),
    "Split Deal - S": (
      property: keyof DeliveryDto,
      SplitDealS: string | boolean,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          type="checkbox"
          checked={convertDefaultCheckbox(SplitDealS)}
          readOnly={true}
          // onBlur={(e) => handleNewUpdaterFunction(e.target.checked, property, stock, SplitDealS)}
          // onChange={(e) => {
          //     handleNewUpdaterFunction(
          //         RecordType.Delivery,
          //         !cTB(SplitDealS),
          //         property,
          //         stock,
          //         cTB(SplitDealS)
          //     );
          // }}
        />
      </td>
    ),
    "Split Sales Person": (
      property: keyof DeliveryDto,
      SplitSalesPerson: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <>
          <AsyncSelect
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            className={styles.actualAsyncSelect}
            value={{
              label: SplitSalesPerson,
              value: SplitSalesPerson,
            }}
            loadOptions={
              // SearchForEmployee
              isNullOrUndefined(employeeList) || !Array.isArray(employeeList)
                ? SearchForEmployee
                : async () => employeeList
            }
            cacheOptions={true}
            defaultOptions={true}
            onChange={(e) => {
              if (isNullOrUndefined(e)) {
                return;
              }

              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.value,
                property,
                stock,
                SplitSalesPerson
              );

              // handleUnDebouncedUpdate(e.value, false, property, invoiceNumber);
            }}
          />
        </>
      </td>
    ),
    "Status - S": (
      property: keyof DeliveryDto,
      StatusS: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          disabled={!canUserAccessAdminSecurityWithGivenRoles(roles)}
          onChange={
            (e) => {
              if (!canUserAccessAdminSecurityWithGivenRoles(roles)) {
                // SHouldn't really happen. the disble should prevent this onchange from happening.
                return;
              }

              handleNewUpdaterFunction(
                RecordType.Delivery,
                e.target.value,
                property,
                stock,
                StatusS,
                () => (e.target.value = StatusS + "")
              );
            }
            // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
          }
          defaultValue={StatusS}
        >
          {Object.entries(SalesOrderStatus)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "Back Office Notes": (
      property: keyof DeliveryDto,
      BackOfficeNotes: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(BackOfficeNotes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={BackOfficeNotes}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              BackOfficeNotes,
              () => (e.target.value = BackOfficeNotes + "")
            )
          }
        />
      </td>
    ),
    "Notes - D": (
      property: keyof DeliveryDto,
      NotesD: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(NotesD + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={NotesD}
          onBlur={(e) =>
            handleNewUpdaterFunction(
              RecordType.Delivery,
              e.target.value,
              property,
              stock,
              NotesD,
              () => (e.target.value = NotesD + "")
            )
          }
        />
      </td>
    ),
  };
  const runner = (dtoData: DeliveryDto): React.ReactElement[] => {
    if (isNullOrUndefined(computedCombinedFilters)) {
      return [<></>];
    }

    const updatedValues = computedCombinedFilters.orderedColumnsToShow
      .filter(
        (column) =>
          !isDeliveryPropertyExcluded(column as keyof DeliveryDto) &&
          isNotNullNorUndefined(column) &&
          column !== ""
      )
      .filter(filterDeliveryColumnMethod)
      .map((column) => {
        let value = dtoData[column as keyof DeliveryDto];
        if (isNullOrUndefined(value)) {
          value = "";
        }
        const target =
          savedFunctionsToUpdateData[
            column as keyof typeof savedFunctionsToUpdateData
          ];

        if (typeof target !== "function" || isNullOrUndefined(target)) {
          console.error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
          throw new Error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
        }

        if (typeof value === "boolean") {
          // if boolean value converting to string
          value = value + "";
        }

        const result = target(
          column as keyof DeliveryDto,
          value as string,
          dtoData["Stock"]
        );
        return result;
      })
      .filter((column) => isNotNullNorUndefined(column));

    const withSelectionColumn = [
      <td
        className={`${
          selectingRowMode === "unselected" ? styles.selectionColumnHidden : ""
        } ${styles.tdSelectionColumn}`}
        key="selection-column"
        onClick={(e) => {
          if ((e.target as Element).tagName !== "TD") {
            return;
          }
          const tdElement = e.target as HTMLTableCellElement;

          const inputBelow = tdElement.childNodes[0] as HTMLInputElement;
          if (isRowIdSelected(dtoData.Stock)) {
            unselectRow(dtoData.Stock);
          } else {
            registerSelection(dtoData.Stock);
          }
          inputBelow.focus();
        }}
      >
        <input
          type="checkbox"
          style={{ transform: "scale(1.5)" }}
          checked={isRowIdSelected(dtoData.Stock)}
          onChange={(e) => {
            if (e.target.checked) {
              registerSelection(dtoData.Stock);
            } else {
              unselectRow(dtoData.Stock);
            }
          }}
        />
      </td>,
      ...updatedValues,
    ];

    return withSelectionColumn as JSX.Element[];
  };

  useEffect(() => {
    const ionElement = document.getElementById(
      ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID
    );

    if (isNullOrUndefined(ionElement)) {
      console.error("ION ELEENT IS NULL RETURNING");
      return;
    }

    const handler = function () {
      const nonTableParts = Array.from(
        document.getElementsByClassName("nonTableParts")
      );
      if (isNullOrUndefined(nonTableParts) || !Array.isArray(nonTableParts)) {
        console.error("Non table part IS NULL! RETURNING");
        return;
      }
      nonTableParts.forEach((part) => {
        (part as HTMLDivElement).style.left = ionElement.scrollLeft + "px";
      });
    };

    ionElement.addEventListener("scroll", () => {
      window.requestAnimationFrame(handler);
    });
  }, []);

  useEffect(() => {
    // calls the endpoint initially
    // handleEndpointCall({
    //     endpoint: endpoint,
    //     generalOptions: generalOptions,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*

  useEffect(() => {
      // calls the endpoint initially
      handleEndpointCall({
          endpoint: endpoint,
          generalOptions: generalOptions,
      });
  }, [endpoint, generalOptions, handleEndpointCall, ]);

  */

  const renderedOnce = useRef<boolean>(false);

  const exportEnumProcessor = (deliveryDto: DeliveryDto) => {
    const updatedObj: any = { ...deliveryDto };

    EnumDeliveryProperties.forEach((property) => {
      const targetEnum = getEnumFromDeliveryDtoPropertyName(property);
      if (isNullOrUndefined(targetEnum)) {
        console.error(
          "Target enum is undfined/null when trying to process enum values!"
        );
        return;
      }

      const value = deliveryDto[property];

      updatedObj[property] = targetEnum[value as number];
    });

    return updatedObj;
  };

  const convertLongDateToShortDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const printProcessor = (inventoryDto: DeliveryDto) => {
    const updatedObj: any = { ...inventoryDto };

    Object.entries(inventoryDto).forEach(([property, value]) => {
      const dataType = getDataTypeFromDeliveryPropertyName(
        property as keyof DeliveryDto
      );

      switch (dataType) {
        case DataType.DateTime: {
          updatedObj[property as keyof DeliveryDto] =
            convertLongDateToShortDate(value);
          break;
        }
        case DataType.Enum: {
          const targetEnum = getEnumFromDeliveryDtoPropertyName(
            property as keyof DeliveryDto
          );
          if (isNullOrUndefined(targetEnum)) {
            console.error(
              "Target Enum was not found. Please add definition in getEnumFromInventoryDtoPropertyName"
            );
            return;
          }

          updatedObj[property as keyof DeliveryDto] = targetEnum[value];
        }
      }
    });
    return updatedObj as DeliveryDto;
  };

  return (
    <div className={styles.DeliveryTableContainer} data-testid="DeliveryTable">
      <Layout className={`${styles.nonTablePart} nonTableParts`}>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          // id="non-table-part-of-page"
        >
          {tabbed && (
            <QueriedTableTabList
              handleGlobalFilterButtonClick={handleGlobalFilterButtonClick}
              isFilterSelected={isFilterSelected}
              computedCombinedFilters={computedCombinedFilters}
              handleUserFilterButtonClick={handleUserFilterButtonClick}
              userFiltersStorage={userFiltersStorage}
              globalFiltersStorage={globalFiltersStorage}
            />
          )}
          <QueriedTableToolbar<DeliveryDto>
            endpoint={endpoint}
            handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
            handleUserFilterButtonClick={handleUserFilterButtonClick}
            model={model}
            useCustomEndpoint={useCustomEndpoint}
            setUseCustomEndpoint={setUseCustomEndpoint}
            recordType={recordType}
            isFilterSelected={isFilterSelected}
            handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
            handleDeleteGlobalFilter={handleDeleteGlobalFilter}
            handleDeleteUserFilter={handleDeleteUserFilter}
            setOverwritingProvidedData={setOverwritingProvidedData}
            dtoType={dtoType}
            handleClear={handleClearCancel}
            data={data as DeliveryDto[]}
            computedCombinedFilters={computedCombinedFilters}
            handleUpdatingItemsPerPage={handleUpdatingItemsPerPage}
            setSortFilters={setSortFilters}
            handleSetCustomFiltersValueFilters={
              handleSetCustomFiltersValueFilters
            }
            unhidableColumns={["Stock", "Invoice Number"]}
            setColumnVisibility={setColumnVisibility}
            fieldsThatAreCustomTypes={{
              "Inventory Type": typeTypes,
              "Make": makeTypes,
            }}
            exportEnumProcessor={exportEnumProcessor}
            enumProperties={EnumDeliveryProperties}
            printProcessor={printProcessor}
            getAsyncSelectGetMethodFromPropertyName={() => undefined}
            filterKeyArrayByExcludedColumns={
              filterDeliveryKeyArrayByExcludedColumns
            }
            selectedUserFilterNames={selectedUserFilterNames}
            selectedGlobalFilterNames={selectedGlobalFilterNames}
            computedSelectedGlobalFilters={computedSelectedGlobalFilters}
            computedSelectedUserFilters={computedSelectedUserFilters}
            customFilters={customFilters}
            unselectGlobalFilter={unselectGlobalFilter}
            unselectUserFilter={unselectUserFilter}
            ignoreFilter={ignoreFilter}
            unignoreFilter={unignoreFilter}
            isFilterIgnored={isFilterIgnored}
            combinedFilterTargetProperties={combinedFilterTargetProperties}
            updateSearchTerm={updateSearchTerm}
            globalFilterStorage={globalFiltersStorage}
            userFilterStorage={userFiltersStorage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>

        <div
          className={`${styles.itemsPerPageAndCopyTableContainer} ${styles.nonTablePart} nonTableParts`}
        >
          <div className={`${styles.totalItemsSpan} `}>
            <span>
              Total Items: {computedCombinedFilters.sortOptions.totalItems}
            </span>
          </div>
          <CopyTableStylesButtons<DeliveryDto>
            selectingRowMode={selectingRowMode}
            unselectAllRows={unselectAllRows}
            selectAllRows={selectAllRows}
            cancelSelecting={cancelSelecting}
            stopSelectingAndCopyTableWithStyles={
              stopSelectingAndCopyTableWithStyles
            }
            stopSelectingAndEmailAdvertisements={
              stopSelectingAndEmailAdvertisements
            }
            startSelecting={startSelecting}
            data={data as DeliveryDto[]}
            identifierProperty={"Stock"}
            inventoryPage={false}
          />
        </div>
        <div ref={tableDivContainerRef} style={{ marginTop: "1rem" }}>
          <table className={styles.table}>{handleGenerateTableParts()}</table>
        </div>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          style={{ marginBottom: "1rem" }}
        >
          <QueriedTablePaginationOptions
            computedCombinedFilters={computedCombinedFilters}
            updateCurrentPageNumber={updateCurrentPageNumber}
            setIsLoading={setIsLoading}
          />
        </div>
        {/*
                <Card
                    endpoint={() => MetricService.getMetrics(RecordType.Delivery)}
                    icon={CardIconTypes.Square}
                    hideOnTabletSize={true}
                />
                */}
      </Layout>
    </div>
  );
};

export default DeliveryTable;
