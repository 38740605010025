import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type UseCustomLogicOnEditOptionsType<T> = {
    initialValue: T;

    callbackToRun: (value: T) => T;
};

type ResultType<T> = [T, Dispatch<SetStateAction<T>>];

export const useCustomLogicOnEdit = <T,>({
    initialValue,
    callbackToRun,
}: UseCustomLogicOnEditOptionsType<T>): ResultType<T> => {
    const [state, setState] = useState<T>(initialValue);

    const setStateWrapper = useCallback(
        (newValue: T | ((prevState: T) => T)) => {
            if (typeof newValue === 'function') {
                setState((oldState) => {
                    const newState = (newValue as (prevState: T) => T)(oldState);

                    const updatedValue = callbackToRun(newState);

                    return updatedValue;
                });
            } else {
                setState((oldState) => {
                    const updatedValue = callbackToRun(newValue as T);

                    return updatedValue;
                });
            }
        },
        [callbackToRun, setState]
    );

    return [state, setStateWrapper];
};
