import { format } from 'date-fns';
import { isNullOrUndefined } from './NullOrUndefined';
import { addLeadingZeros } from './StringUtils';

export function addMinutes(date: any, minutes: any) {
    return new Date(date.getTime() + minutes * 60000);
}
export const generateDateStringFromDifferentFormatDateString = (dateString: string) => {
    const date = new Date(dateString);
    if (date.toString() === 'Invalid Date') {
        throw new Error('Provided Date String is not a valid date!!');
    }

    return generateDateString(date);
};

export const convertToInputDateFormat = (dateStringValue: string | null | undefined) => {
    if (isNullOrUndefined(dateStringValue) || dateStringValue === '') {
        return '';
    }
    const delivered = new Date(dateStringValue).toLocaleDateString('en');

    const [month, day, year] = delivered.split('/');

    const toReturn = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return toReturn;
};

export const generateDateString = (date: Date): string => {
    if (date == undefined || date.toString() === 'Invalid Date') {
        return '';
    }

    const year = date.getFullYear();
    const month = addLeadingZeros(date.getMonth() + 1, 2); // Adding to month because it starts at 0. Additinally, the 2 means to make it take up 2 characters
    const day = addLeadingZeros(date.getDate(), 2);

    const result = `${year}-${month}-${day}`;
    return result;

    // const convertedLocalTimeString = date.toLocaleDateString();

    // const convertedLocalTimeDate = new Date(convertedLocalTimeString);

    // const dateString = convertedLocalTimeDate.toISOString().substring(0, 10);
    // return dateString;
};

export const convertDateStringToBackendDateString = (dateString: string) => {
    if (dateString == undefined || dateString.toString() === 'Invalid Date') {
        throw new Error('Provided date string in convertDateSTringtoBackendDateString is not valid');
    }

    return convertDateToBackendDateString(new Date(dateString));
};

export const convertDateToBackendDateString = (date: Date) => {
    if (date == undefined || date.toString() === 'Invalid Date') {
        return '';
    }

    const isoDateString = date.toISOString();

    //remove the last 2 characters oZ

    // The format that we recieve from the backend:
    // 2021-02-04T00:00:00

    // The format that .toISOString gives back:
    // 2021-02-04T00:00:000Z

    const finalTime = isoDateString.substring(0, isoDateString.length - 2);
    return finalTime;
};

export const formatDateOrGetEmptyString = <T>(property: keyof T, objWithDate?: T) => {
    return objWithDate != undefined && objWithDate?.[property] != undefined
        ? format(new Date(objWithDate[property] as unknown as string), 'yyyy-MM-dd')
        : '';
};

export const convertBackendDateStringToDateInputDateString = (dateStringFromBackend: string | undefined | null) => {
    if (dateStringFromBackend == undefined) {
        return '';
    }
    let dateStringForDateInput = '';

    if (
        typeof dateStringFromBackend === 'string' &&
        dateStringFromBackend != '' &&
        dateStringFromBackend.includes('T')
    ) {
        dateStringForDateInput = generateDateStringFromDifferentFormatDateString(dateStringFromBackend);
    }

    return dateStringForDateInput;
};
