import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import PrintTableController from '../PrintTableController/PrintTableController';
//@ts-ignore
import styles from './TestPage.module.scss';

interface TestPageProps {}

class test {
    Stock = '1';
    VIN = '5';
    Make = '9';
    Year = '13';
    constructor(Stock: string | number, VIN: string | number, Make: string | number, Year: string | number) {
        this.Stock = Stock + '';
        this.VIN = VIN + '';
        this.Make = Make + '';
        this.Year = Year + '';
    }
}

const TestPage = ({}: TestPageProps) => {
    return (
        <div className={styles.TestPageContainer} data-testid="TestPage">
            {/* <TableToPrint
                data={[new InventoryDto()]}
                identifierProperty={'Stock'}
                columnsToShow={['Stock', 'VIN', 'Make', 'Model', 'Year']}
            /> */}

            <PrintTableController
                data={[new InventoryDto()]}
                identifierProperty={'Stock'}
                columnsToShow={['Stock', 'VIN', 'Make', 'Model', 'Year']}
                triggerJSX={<button>Print</button>}
                hiddenContent={false}
                exportEnumProcessor={(dto) => dto}
                enumProperties={[]}
                printProcessor={(dto) => dto}
            />
        </div>
    );
};

export default TestPage;
