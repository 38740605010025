import { IonCol, IonGrid, IonIcon, IonImg, IonRow } from '@ionic/react';
import { DragEvent, MutableRefObject, useCallback, useRef, useState } from 'react';

import { UrlPhotoIdWrapper } from '../../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
import { PhotoService } from '../../utils/apiServices';

import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import FullScreenModal from '../FullScreenModal/FullScreenModal';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';

import { trashOutline } from 'ionicons/icons';

import { RecordType } from '../../models/Global';
import ChangePhotoOrderIndexAndUpdateAccordingly from '../../utils/ChangePhotoOrderIndexAndUpdateAccordingly';
import styles from './PhotoGallery.module.scss';

/*
 * Realizing this component prob needs more than just photo urls since in order to
 * delete a photo we need the photo url and the photoId. Beacuse of this I'll make some
 * dictionary which will have hte key of url and then the value will be the photo Id.
 * I realize it's kind of weird to have photoId as the value instead of the key, but we
 * can more easily obtain a photo's url compared to id right now.
 */

export interface PhotoGalleryPhotosData {
  [photoUrl: string]: string;
  // photoUrl: photoId
}

interface PhotoGalleryProps {
  photoUrls: UrlPhotoIdWrapper[];
  galleryTitle: string;
  removingUrlFromState: (url: string) => void;
  stock: string;
  recordType: RecordType;
  handleUpdatingPhotosFromPhotoGallery: (photos: UrlPhotoIdWrapper[], stock: string) => void;
}

const PhotoGallery = ({
  photoUrls,
  galleryTitle,
  removingUrlFromState,
  recordType,
  stock,
  handleUpdatingPhotosFromPhotoGallery,
}: PhotoGalleryProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDetailedPhotoIfContainsPhotoUrl, setShowDetailedPhotoIfContainsPhotoUrl] = useState<string>();

  // const [dragging, setDragging] = useState<boolean>(false);

  const handlePhotoDeletion = useCallback(
    (urlToDelete: string, photoId: string, removeFromState = true) => {
      PhotoService.delete(photoId).then((response) => {
        if (response.data.isSuccessful && removeFromState) {
          removingUrlFromState(urlToDelete);
        }
      });
      // const sasToken = process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN;
      // const currentDateObject = new Date();
      // const currentDateString =
      //     currentDateObject.getDay() + '-' + currentDateObject.getMonth() + '-' + currentDateObject.getFullYear();

      // const instance = axios.create();

      // const authorizationHeader = axios.defaults.headers.common['Authorization'];
      // delete instance.defaults.headers.common['Authorization'];

      // instance
      //     .delete<ReturnMessageOnlyDto>(`${urlToDelete}${sasToken}`, {
      //         headers: {
      //             'Access-Control-Allow-Origin': '*',
      //             'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      //             'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
      //             // 'Content-Type': 'image/jpeg',
      //             'x-ms-date': currentDateString,
      //             'x-ms-blob-type': 'BlockBlob',
      //             'x-ms-version': '2021-06-08',
      //         },
      //     })
      //     .then((response) => {
      //     const status = response.status;
      //     if (response.status === 202) {
      //     } else {
      //         makeSimpleToast('Unexpected error happened!');
      //         console.error(response);
      //     }
      // })
      // .catch((e) => {
      //     console.error(e);
      //     makeSimpleToast('Unexpected error happened!');
      //     console.error('Unexpected error happened! ');
      // });
    },
    [removingUrlFromState]
  );

  const handleImageClick = (photoUrl: string) => {
    if (showDetailedPhotoIfContainsPhotoUrl === photoUrl) {
      setShowDetailedPhotoIfContainsPhotoUrl(undefined);
      return;
    }

    setShowDetailedPhotoIfContainsPhotoUrl(photoUrl);
  };

  const leftOrRight: MutableRefObject<'left' | 'right' | undefined> = useRef<'left' | 'right'>();

  const handleGenerateStyleBasedOffAmountOfPhotos = (photoUrls: string[]) => {
    switch (photoUrls.length) {
      case 1:
        return { columnCount: 1 };
      case 2:
        return { columnCount: 2 };
      case 3:
        return { columnCount: 2 };
      case 4:
        return { columnCount: 2 };
      default:
        return {};
    }
  };

  const drag = (ev: DragEvent<HTMLIonColElement>) => {
    // setDragging(true);
    const indexValue = (ev.target as HTMLIonImgElement).getAttribute('data-index-value');

    if (isNullOrUndefined(indexValue)) {
      throw new Error(`Index value of: ${indexValue} is not acceptable!`);
    }

    if (!('id' in ev.target)) {
      // Didn't pass in id.
      throw new Error('Did not find associated ID when dragging!');
    }

    const photoId = ev.target.id + '';

    ev.dataTransfer?.setData('index', indexValue);
    ev.dataTransfer?.setData('id', photoId);

    // ev.dataTransfer?.setData('text', indexValue);
  };

  const endDrop = (ev: DragEvent<HTMLIonColElement>) => {
    // setDragging(false);

    ev.preventDefault();

    const imgChild = ev.target as HTMLIonImgElement;

    if ((ev.target as HTMLElement).tagName === 'ION-COL') {
      return;
    }

    imgChild.style.borderRight = '1px solid white';
    imgChild.style.borderLeft = '1px solid white';
  };

  const dragExit = (ev: DragEvent<HTMLIonColElement>) => {
    ev.preventDefault();

    if ((ev.target as HTMLElement).tagName === 'ION-COL') {
      return;
    }

    const imgChild = ev.target as HTMLIonImgElement;

    imgChild.style.borderRight = '1px solid white';
    imgChild.style.borderLeft = '1px solid white';

    console.log('Test: ' + (ev as unknown as { test: string }).test);
  };

  const dragLeave = (ev: DragEvent<HTMLIonColElement>) => {
    if ((ev.target as HTMLElement).tagName === 'ION-COL') {
      return;
    }
    ev.preventDefault();

    const imgChild = ev.target as HTMLIonImgElement;

    imgChild.style.borderRight = '1px solid white';
    imgChild.style.borderLeft = '1px solid white';
  };

  const allowDrop = (ev: DragEvent<HTMLIonColElement>) => {
    ev.preventDefault();

    console.table({
      targetTagName: (ev.target as HTMLElement).tagName,
      currentTargetTagName: (ev.target as HTMLElement).tagName,
    });

    if ((ev.target as HTMLElement).tagName === 'ION-COL') {
      return;
    }

    // console.dir(ev);

    const {
      x: targetClientX,
      y: targetClientY,
      width: targetClientWidth,
      height: targetClientHeight,
    } = (ev.target as HTMLIonColElement).getBoundingClientRect();

    // console.table({ clientX: ev.clientX, clientY: ev.clientY, pageX: ev.pageX, pageY: ev.pageY });
    // console.table({ x: targetClientX, y: targetClientY });

    const midway = targetClientWidth / 2.0;

    const imgChild = ev.target as HTMLIonImgElement;

    if (targetClientX + midway > ev.clientX) {
      // Left
      // console.log('Left');

      imgChild.style.borderLeft = '1px solid red';

      imgChild.style.borderRight = '1px solid white';
      leftOrRight.current = 'left';
    } else {
      // right

      imgChild.style.borderRight = '1px solid red';

      imgChild.style.borderLeft = '1px solid white';

      leftOrRight.current = 'right';
      // console.log('Right');
    }
  };

  const drop = async (ev: DragEvent<HTMLIonColElement>) => {
    // *** Don't do any extra logic here. Basically just send the photoid and the 0-based Order that the user dropped the item on and if they were on the left side or right side ***

    ev.preventDefault();

    const imgChild = ev.target as HTMLIonImgElement;

    if ((ev.target as HTMLElement).tagName !== 'ION-COL') {
      imgChild.style.borderRight = '1px solid white';
      imgChild.style.borderLeft = '1px solid white';
    }

    const e = ev;
    const d = ev.dataTransfer;

    const photoId = ev.dataTransfer.getData('id');
    const oldPhotoIndex = +ev.dataTransfer.getData('index');
    const targetIndex: number | null | string = (ev.target as HTMLIonImgElement).getAttribute('data-index-value');

    if (targetIndex == null) {
      alert('Error! Inded was target null!');
      return;
    }

    if (oldPhotoIndex === +targetIndex) {
      return;
    }
    const insertBeforeTarget = leftOrRight.current === 'left';

    const updatedPhotos = await ChangePhotoOrderIndexAndUpdateAccordingly(
      photoId,
      +targetIndex,
      insertBeforeTarget
    );
    if (isNullOrUndefined(updatedPhotos)) {
      // Should be handled by make simple toast from response.
    } else {
      //  TODO: Mighht need to fix this to be inside of a setPhotos(() => {})  callback function
      handleUpdatingPhotosFromPhotoGallery(updatedPhotos, stock);
    }
  };

  return (
    <>
      <FullScreenModal showModal={showModal} setShowModal={setShowModal}>
        <>
          <ModalCloseButton
            setModalHideFunction={() => {
              setShowModal(false);
            }}
            content={<h1>Images</h1>}
          />
          <div className={styles.contentContainer}>
            <IonGrid style={{ height: '100%' }}>
              {(() => {
                if (isNullOrUndefined(photoUrls) || photoUrls.length === 0) {
                  return (
                    <IonRow
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        backgroundColor: '#313638',
                        color: 'white',
                      }}>
                      <h1>No images to display.</h1>
                    </IonRow>
                  );
                }
                return (
                  <IonRow style={{ backgroundColor: '#313638', height: '100%' }}>
                    {isNotNullNorUndefined(photoUrls) &&
                      photoUrls.map((urlPhotoIdWrapper, index) => {
                        return (
                          <IonCol
                            draggable={true}
                            // onDragCapture={(ev) => drag(ev)}
                            //
                            key={urlPhotoIdWrapper.photoId}
                            onDragStart={(ev) => drag(ev)}
                            onDragOver={allowDrop}
                            onDragExit={dragExit}
                            onDragLeave={dragLeave}
                            onDragEnd={endDrop}
                            onDrop={(p) => drop(p)}
                            className={styles.imageCol}
                            size="1"
                            style={{ position: 'relative' }}>
                            <IonImg
                              // TODO: Implement a more approptiate soultion for generating alt
                              // Perhps when the user uploads a photo they must provide a 'alt' and use it here.
                              // I'm setting these alts here just to make it so that I can test with
                              // react-testing-library
                              // alt={`testing-image-for-photo-${urlPhotoIdWrapper.photoId}`}
                              data-testid={`testing-image-for-photo-${urlPhotoIdWrapper.photoId}`}
                              data-index-value={index}
                              data-photoId={urlPhotoIdWrapper.photoId}
                              role={'img'}
                              id={urlPhotoIdWrapper.photoId}
                              className={`${styles.singleImage} ${showDetailedPhotoIfContainsPhotoUrl ===
                                urlPhotoIdWrapper.url
                                ? styles.focusedImage
                                : ''
                                }`}
                              onClick={() => handleImageClick(urlPhotoIdWrapper.url)}
                              src={`${urlPhotoIdWrapper.url}${process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN}`}

                            />
                            <IonIcon
                              // slot="start"
                              onClick={(e) => {
                                handlePhotoDeletion(
                                  urlPhotoIdWrapper.url,
                                  urlPhotoIdWrapper.photoId
                                );
                              }}
                              size="large"
                              style={{
                                position: 'absolute',
                                top: '.25rem',
                                right: '.25rem',
                                backgroundColor: 'white',
                                border: '1px solid white',
                                borderRadius: '5rem',
                              }}
                              icon={trashOutline}></IonIcon>
                          </IonCol>


                        );
                      })}
                  </IonRow>
                );
              })()}
            </IonGrid>
          </div>
        </>
      </FullScreenModal>
    </>
  );
};

export default PhotoGallery;
