import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import React, { FC, useEffect, useState } from 'react';
import GeneralToolTip from '../../../../components/GeneralToolTip/GeneralToolTip';
import { useObject } from '../../../../hooks/ObjectHook';
import { MetricOptionsUpdateDto } from '../../../../models/Dtos/GeneralDtos/MetricOptionsUpdateDto';
import { GetGlobalFilterDto } from '../../../../models/Dtos/GetGlobalFilterDto';
import { FilterType, RecordType } from '../../../../models/Global';
import { InventoryService, MetricService } from '../../../../utils/apiServices';
import styles from './MetricDashboardEditor.module.scss';

interface MetricDashboardEditorProps {}

const MetricDashboardEditor: FC<MetricDashboardEditorProps> = () => {
    const [userFilters, setUserFilters] = useState<string[]>([]);
    useEffect(() => {
        InventoryService.getUserFilterNames(RecordType.Inventory).then((response) => {
            setUserFilters(response.data.model);
        });
    }, []);

    // useEffect(() => {
    //     InventoryService.getGlobalFilterNames().then((response) => {
    //         setGlobalFilters(response.data.model);
    //     });
    // }, []);

    const {
        value: slot1,
        bindIonInputProperty: bindSlot1InputProperty,
        bindIonPropertyCheck: bindSlot1CheckProperty,
        updateProperty: updateSlot1Property,
        bindIonSelectProperty: bindSlot1SelectProperty,
    } = useObject<MetricOptionsUpdateDto>(new MetricOptionsUpdateDto(1));

    useEffect(() => {
        updateSlot1Property('filterName')('');
    }, [slot1.filterType]);

    const {
        value: slot2,
        bindIonInputProperty: bindSlot2InputProperty,
        bindIonPropertyCheck: bindSlot2CheckProperty,
        updateProperty: updateSlot2Property,
        bindIonSelectProperty: bindSlot2SelectProperty,
    } = useObject<MetricOptionsUpdateDto>(new MetricOptionsUpdateDto(2));

    useEffect(() => {
        updateSlot2Property('filterName')('');
    }, [slot2.filterType]);

    const {
        value: slot3,
        bindIonInputProperty: bindSlot3InputProperty,
        bindIonPropertyCheck: bindSlot3CheckProperty,
        updateProperty: updateSlot3Property,
        bindIonSelectProperty: bindSlot3SelectProperty,
    } = useObject<MetricOptionsUpdateDto>(new MetricOptionsUpdateDto(3));

    useEffect(() => {
        updateSlot3Property('filterName')('');
    }, [slot3.filterType]);

    const {
        value: slot4,
        bindIonInputProperty: bindSlot4InputProperty,
        bindIonPropertyCheck: bindSlot4CheckProperty,
        updateProperty: updateSlot4Property,
        bindIonSelectProperty: bindSlot4SelectProperty,
    } = useObject<MetricOptionsUpdateDto>(new MetricOptionsUpdateDto(4));

    useEffect(() => {
        updateSlot4Property('filterName')('');
    }, [slot4.filterType]);

    const updateGlobalFiltersForSpecificSlot = async (
        recordType: RecordType,
        toSetFunction: React.Dispatch<React.SetStateAction<GetGlobalFilterDto[]>>
    ) => {
        if (slot1.recordType == undefined) {
            return;
        }

        const response = await InventoryService.getGlobalFilterNames(recordType);
        toSetFunction(response.data.model);
    };

    const [globalFilters1, setGlobalFilters1] = useState<GetGlobalFilterDto[]>([]);

    useEffect(() => {
        updateGlobalFiltersForSpecificSlot(slot1.recordType, setGlobalFilters1);
        updateSlot1Property('filterName')('');
    }, [slot1.recordType]);

    const [globalFilters2, setGlobalFilters2] = useState<GetGlobalFilterDto[]>([]);

    useEffect(() => {
        updateGlobalFiltersForSpecificSlot(slot2.recordType, setGlobalFilters2);
        updateSlot2Property('filterName')('');
    }, [slot2.recordType]);

    const [globalFilters3, setGlobalFilters3] = useState<GetGlobalFilterDto[]>([]);

    useEffect(() => {
        updateGlobalFiltersForSpecificSlot(slot3.recordType, setGlobalFilters3);
        updateSlot3Property('filterName')('');
    }, [slot3.recordType]);

    const [globalFilters4, setGlobalFilters4] = useState<GetGlobalFilterDto[]>([]);

    useEffect(() => {
        updateGlobalFiltersForSpecificSlot(slot4.recordType, setGlobalFilters4);
        updateSlot4Property('filterName')('');
    }, [slot4.recordType]);

    const handleSubmitSlot1 = async () => {
        if (slot1.filterName == undefined || slot1.filterName === '') {
            alert('You most select a Target Fitler!');
            return;
        }
        const response = await MetricService.updateSlotFromFilter(slot1);
    };

    const handleSubmitSlot2 = async () => {
        if (slot2.filterName == undefined || slot2.filterName === '') {
            alert('You most select a Target Fitler!');
            return;
        }
        const response = await MetricService.updateSlotFromFilter(slot2);
    };

    const handleSubmitSlot3 = async () => {
        if (slot3.filterName == undefined || slot3.filterName === '') {
            alert('You most select a Target Fitler!');
            return;
        }

        const response = await MetricService.updateSlotFromFilter(slot3);
    };

    const handleSubmitSlot4 = async () => {
        if (slot4.filterName == undefined || slot4.filterName === '') {
            alert('You most select a Target Fitler!');
            return;
        }
        const response = await MetricService.updateSlotFromFilter(slot4);
    };

    return (
        <div className={styles.MetricDashboardEditor} data-testid="MetricDashboardEditor">
            <IonGrid>
                <IonRow style={{ position: 'relative', zIndex: '100' }}>
                    <IonCol style={{ position: 'relative', zIndex: '100' }}>
                        <p>What can I do here?</p>
                        <GeneralToolTip
                            content={[
                                'You select a user or global filter',
                                'and the results from that filter will be',
                                'counted and displayed on the dashboard page',
                            ]}
                        />
                    </IonCol>
                    <IonCol style={{ position: 'relative', zIndex: '100' }}>
                        <p>How do I add a user/global filter?</p>
                        <GeneralToolTip
                            content={[
                                'First choose which slot you want to modify',
                                'There are 4 slots. Select a slot',
                                'If you want to show metrics for a user filter',
                                "Make sure 'Is User Filter' is checked",
                                'And then simply choose a target user filter',
                                'If you want to show metrics for global filter',
                                'make sure "Is User Filter" is not checked and',
                                "it's the same process but with an additional field",
                                "Click this field and choose the global filter type you'd like to show",
                            ]}
                        />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className={styles.col}>
                        <IonLabel className={styles.labelHeader}>Slot 1</IonLabel>
                        <IonItem>
                            <IonLabel>Is User Filter</IonLabel>
                            <IonCheckbox
                                checked={slot1.filterType === FilterType.User}
                                onIonChange={(e) => {
                                    if (e.detail.checked) {
                                        updateSlot1Property('filterType')(FilterType.User);
                                    } else {
                                        updateSlot1Property('filterType')(FilterType.Global);
                                    }
                                }}
                            />
                        </IonItem>

                        {slot1.filterType === FilterType.Global && (
                            <IonItem>
                                <IonLabel position="stacked">Record Type</IonLabel>
                                <IonSelect {...bindSlot1SelectProperty('recordType')}>
                                    {Object.entries(RecordType)
                                        .filter(([key]) => isNaN(parseInt(key)))
                                        .map(([key, value]) => (
                                            <IonSelectOption value={value} key={value}>
                                                {key}
                                            </IonSelectOption>
                                        ))}
                                </IonSelect>
                            </IonItem>
                        )}

                        <IonItem>
                            <IonLabel position="stacked">Target Fitler</IonLabel>
                            {slot1.filterType === FilterType.User ? (
                                <IonSelect {...bindSlot1SelectProperty('filterName')}>
                                    {userFilters.map((userFilter) => (
                                        <IonSelectOption value={userFilter} key={userFilter}>
                                            {userFilter}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            ) : (
                                <IonSelect {...bindSlot1SelectProperty('filterName')}>
                                    {globalFilters1.map(({ name }) => (
                                        <IonSelectOption value={name} key={name}>
                                            {name}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            )}
                        </IonItem>
                        <IonItem>
                            <IonButton onClick={() => handleSubmitSlot1()} type={'submit'}>
                                Submit
                            </IonButton>
                        </IonItem>
                    </IonCol>

                    <IonCol className={styles.col}>
                        <IonLabel className={styles.labelHeader}>Slot 2</IonLabel>
                        <IonItem>
                            <IonLabel>Is User Filter</IonLabel>
                            <IonCheckbox
                                checked={slot2.filterType === FilterType.User}
                                onIonChange={(e) => {
                                    if (e.detail.checked) {
                                        updateSlot2Property('filterType')(FilterType.User);
                                    } else {
                                        updateSlot2Property('filterType')(FilterType.Global);
                                    }
                                }}
                            />
                        </IonItem>

                        {slot2.filterType === FilterType.Global && (
                            <IonItem>
                                <IonLabel position="stacked">Record Type</IonLabel>
                                <IonSelect {...bindSlot2SelectProperty('recordType')}>
                                    {Object.entries(RecordType)
                                        .filter(([key]) => isNaN(parseInt(key)))
                                        .map(([key, value]) => (
                                            <IonSelectOption value={value} key={value}>
                                                {key}
                                            </IonSelectOption>
                                        ))}
                                </IonSelect>
                            </IonItem>
                        )}

                        <IonItem>
                            <IonLabel position="stacked">Target Fitler</IonLabel>
                            {slot2.filterType === FilterType.User ? (
                                <IonSelect {...bindSlot2SelectProperty('filterName')}>
                                    {userFilters.map((userFilter) => (
                                        <IonSelectOption value={userFilter} key={userFilter}>
                                            {userFilter}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            ) : (
                                <IonSelect {...bindSlot2SelectProperty('filterName')}>
                                    {globalFilters2.map(({ name }) => (
                                        <IonSelectOption value={name} key={name}>
                                            {name}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            )}
                        </IonItem>
                        <IonItem>
                            <IonButton onClick={() => handleSubmitSlot2()} type={'submit'}>
                                Submit
                            </IonButton>
                        </IonItem>
                    </IonCol>

                    <IonCol className={styles.col}>
                        <IonLabel className={styles.labelHeader}>Slot 3</IonLabel>
                        <IonItem>
                            <IonLabel>Is User Filter</IonLabel>
                            <IonCheckbox
                                checked={slot3.filterType === FilterType.User}
                                onIonChange={(e) => {
                                    if (e.detail.checked) {
                                        updateSlot3Property('filterType')(FilterType.User);
                                    } else {
                                        updateSlot3Property('filterType')(FilterType.Global);
                                    }
                                }}
                            />
                        </IonItem>

                        {slot3.filterType === FilterType.Global && (
                            <IonItem>
                                <IonLabel position="stacked">Record Type</IonLabel>
                                <IonSelect {...bindSlot3SelectProperty('recordType')}>
                                    {Object.entries(RecordType)
                                        .filter(([key]) => isNaN(parseInt(key)))
                                        .map(([key, value]) => (
                                            <IonSelectOption value={value} key={value}>
                                                {key}
                                            </IonSelectOption>
                                        ))}
                                </IonSelect>
                            </IonItem>
                        )}

                        <IonItem>
                            <IonLabel position="stacked">Target Fitler</IonLabel>
                            {slot3.filterType === FilterType.User ? (
                                <IonSelect {...bindSlot3SelectProperty('filterName')}>
                                    {userFilters.map((userFilter) => (
                                        <IonSelectOption value={userFilter} key={userFilter}>
                                            {userFilter}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            ) : (
                                <IonSelect {...bindSlot3SelectProperty('filterName')}>
                                    {globalFilters3.map(({ name }) => (
                                        <IonSelectOption value={{ filterName: name }} key={name}>
                                            {name}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            )}
                        </IonItem>
                        <IonItem>
                            <IonButton onClick={() => handleSubmitSlot3()} type={'submit'}>
                                Submit
                            </IonButton>
                        </IonItem>
                    </IonCol>

                    <IonCol className={styles.col}>
                        <IonLabel className={styles.labelHeader}>Slot 4</IonLabel>
                        <IonItem>
                            <IonLabel>Is User Filter</IonLabel>
                            <IonCheckbox
                                checked={slot4.filterType === FilterType.User}
                                onIonChange={(e) => {
                                    if (e.detail.checked) {
                                        updateSlot4Property('filterType')(FilterType.User);
                                    } else {
                                        updateSlot4Property('filterType')(FilterType.Global);
                                    }
                                }}
                            />
                        </IonItem>

                        {slot4.filterType === FilterType.Global && (
                            <IonItem>
                                <IonLabel position="stacked">Record Type</IonLabel>
                                <IonSelect {...bindSlot4SelectProperty('recordType')}>
                                    {Object.entries(RecordType)
                                        .filter(([key]) => isNaN(parseInt(key)))
                                        .map(([key, value]) => (
                                            <IonSelectOption value={value} key={value}>
                                                {key}
                                            </IonSelectOption>
                                        ))}
                                </IonSelect>
                            </IonItem>
                        )}

                        <IonItem>
                            <IonLabel position="stacked">Target Fitler</IonLabel>
                            {slot4.filterType === FilterType.User ? (
                                <IonSelect {...bindSlot4SelectProperty('filterName')}>
                                    {userFilters.map((userFilter) => (
                                        <IonSelectOption value={userFilter} key={userFilter}>
                                            {userFilter}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            ) : (
                                <IonSelect {...bindSlot4SelectProperty('filterName')}>
                                    {globalFilters4.map(({ name }) => (
                                        <IonSelectOption value={name} key={name}>
                                            {name}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            )}
                        </IonItem>
                        <IonItem>
                            <IonButton onClick={() => handleSubmitSlot4()} type={'submit'}>
                                Submit
                            </IonButton>
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    );
};
export default MetricDashboardEditor;
