import { useMsal } from '@azure/msal-react';
import logoutIcon from "../../../icons/svg/logout.svg"
import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonPopover,
  IonRow,
} from '@ionic/react';
import { addCircle, caretDown, menuOutline } from 'ionicons/icons';
import React, { useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import CompanyLogo from '../../../pictures/trailerlistproLogo-noBackground.png';
import { RecordType } from '../../../models/Global';
import { ModelType } from '../../../utils/ModelsSuperClasses/ModelType';
import SearchBarDropDown from '../../SearchBarDropDown/SearchBarDropDown';
import './Header.css';
import styles from './Header.module.scss';
import { isNotNullNorUndefined } from '../../../utils/NullOrUndefined';

import emailAtSignIcon from "../../../icons/svg/email_icon.svg";
import adminSettingsPanel from "../../../icons/svg/admin_panel_settings.svg";
import { canUserAccessAdminSecurityWithGivenRoles, RoleContext } from '../../RoleContext/RoleContextComp';

export const Header: React.FC = () => {
  const history = useHistory();

  const { instance } = useMsal();
  let email = "";

  const roles = useContext(RoleContext);
  const activeAccount = instance.getActiveAccount();

  if (isNotNullNorUndefined(activeAccount)) {
    email = activeAccount.username;
  }
  const hasAccess = canUserAccessAdminSecurityWithGivenRoles(roles);


  console.log(`Has Access: ${hasAccess}`)

  console.log(`Roles: ${roles}`)

  const handleRedirectToHomePage = () => {
    history.push('/');
  };

  const getAllLocalStorageNamesHelper = (prefix: string) => {
    const list = [];

    for (const model in ModelType) {
      if (Number.isNaN(+model)) {
        continue;
      }
      list.push(`${prefix}${ModelType[model]}`);
    }

    for (const record in RecordType) {
      if (Number.isNaN(+record)) {
        continue;
      }
      list.push(`${prefix}${RecordType[record]}`);
    }

    return list;
  };

  const userSpecifiedShownColumnsLocalStorageName = `user-specified-shown-columns-Inventory`;

  const getAllUserSpecifiedShownLocalStorageNames = () => {
    const indexOfLastDash = userSpecifiedShownColumnsLocalStorageName.lastIndexOf('-');

    const prefix = userSpecifiedShownColumnsLocalStorageName.slice(0, indexOfLastDash + 1);

    return getAllLocalStorageNamesHelper(prefix);
  };

  const userSpecifiedHiddenColumnsLocalStorageName = `user-specified-hidden-columns-Inventory`;

  const getAllUserSpecifiedHiddenLocalStorageNames = () => {
    const indexOfLastDash = userSpecifiedHiddenColumnsLocalStorageName.lastIndexOf('-');

    const prefix = userSpecifiedHiddenColumnsLocalStorageName.slice(0, indexOfLastDash + 1);

    return getAllLocalStorageNamesHelper(prefix);
  };

  const columnOrderLocalStorageName = `column-order-Inventory`;

  const getAllColumnOrderLocalStorageNames = () => {
    const indexOfLastDash = columnOrderLocalStorageName.lastIndexOf('-');

    const prefix = columnOrderLocalStorageName.slice(0, indexOfLastDash + 1);

    return getAllLocalStorageNamesHelper(prefix);
  };

  const secondRowSelectedUserOrGlobalFilterGeneralOptionsLocalStorageName = `second-row-user-or-global-filter-general-options-Inventory`;

  const getAllSecondRowGeneralOptionsLocalStorageNames = () => {
    const indexOfLastDash = secondRowSelectedUserOrGlobalFilterGeneralOptionsLocalStorageName.lastIndexOf('-');

    const prefix = secondRowSelectedUserOrGlobalFilterGeneralOptionsLocalStorageName.slice(0, indexOfLastDash + 1);

    return getAllLocalStorageNamesHelper(prefix);
  };

  const firstRowSelectedUserOrGlobalFilterGeneralOptionsLocalStorageName = `first-row-user-or-global-filter-general-options-Inventory`;

  const getAllFirstRowGeneralOptionsLocalStorageNames = () => {
    const indexOfLastDash = firstRowSelectedUserOrGlobalFilterGeneralOptionsLocalStorageName.lastIndexOf('-');

    const prefix = firstRowSelectedUserOrGlobalFilterGeneralOptionsLocalStorageName.slice(0, indexOfLastDash + 1);

    return getAllLocalStorageNamesHelper(prefix);
  };

  const customGeneralOptionsLocalStorageName = `custom-general-options-Inventory`;

  const getAllCustomGeneralOptionsLocalStorageNames = () => {
    const indexOfLastDash = customGeneralOptionsLocalStorageName.lastIndexOf('-');

    const prefix = customGeneralOptionsLocalStorageName.slice(0, indexOfLastDash + 1);

    return getAllLocalStorageNamesHelper(prefix);
  };

  const clearAllQueriedTableLocalStorageItems = () => {
    getAllCustomGeneralOptionsLocalStorageNames().forEach((name) => {
      window.localStorage.removeItem(name);
    });
    getAllFirstRowGeneralOptionsLocalStorageNames().forEach((name) => {
      window.localStorage.removeItem(name);
    });
    getAllSecondRowGeneralOptionsLocalStorageNames().forEach((name) => {
      window.localStorage.removeItem(name);
    });
    getAllColumnOrderLocalStorageNames().forEach((name) => {
      window.localStorage.removeItem(name);
    });
    getAllUserSpecifiedHiddenLocalStorageNames().forEach((name) => {
      window.localStorage.removeItem(name);
    });
    getAllUserSpecifiedShownLocalStorageNames().forEach((name) => {
      window.localStorage.removeItem(name);
    });
  };

  const logout = () => {
    instance.logoutRedirect().then(() => {
      clearAllQueriedTableLocalStorageItems();
    });
  };

  return (
    <IonHeader>
      <div data-testid="header"

        style={{ padding: '0', backgroundColor: '#002b70', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
        <div className={styles.imgWrapper}>
          <IonMenuToggle id="main">
            <a href="#">
              <div className={styles.logoAndMenuIconContainer}>
                <div className={styles.menuIconContainer}>
                  <IonIcon icon={menuOutline} />
                </div>

                <img
                  src={CompanyLogo}
                  style={{ width: '1000', maxWidth: '1000', height: '526', maxHeight: '526' }}
                  alt="Utility Trailer Logo"
                  data-testid="logo"
                  className={styles.logo}
                />
              </div>
            </a>
          </IonMenuToggle>
        </div>
        {/*
          <IonCol size={'12'} sizeSm={'4'} sizeLg={'6'}>
            <SearchBarDropDown data-testid="searchbar" />
          </IonCol>
          */}




        <IonButton
          fill="clear"
          size="small"
          id="account-name"
          data-testid="add-new"
          className={`d-flex justify-content-center ${styles.addNew}`}
          style={{ color: 'white' }}>
          <IonIcon icon={addCircle} className="add" />
          <span style={{ marginLeft: '0.5rem' }}>Add New</span>
        </IonButton>

        {/* <NotificationBellIcon messages={['test', 'test2', 'test3']} /> */}
        {/* <IonAvatar data-testid="profile-pic">
                            <img src={CompanyLogo} />
                        </IonAvatar> */}
        {/* <IonLabel className={styles.nameIonLabel}>{instance.getActiveAccount()?.name ?? ''}</IonLabel> */}
        <IonButton
          fill="clear"
          size="small"
          id="nested-button"
          className="d-flex justify-content-center"
          data-testid="account-name"
          style={{ color: 'white' }}>
          {/* Display Employee Name here if not null*/}
          <IonIcon icon={caretDown} className="caret-down" />
        </IonButton>
        <IonPopover id='header' trigger="nested-button" data-testid="account-options" dismissOnSelect={true}  >

          <div style={{ padding: '5px', borderBottom: '1px solid black', color: 'black', display: 'flex', alignItems: "center", justifyContent: 'flex-start', }}>
            <img src={emailAtSignIcon} style={{ marginRight: '.5rem' }} />
            {email}
          </div>

          <div style={{ padding: '5px', borderBottom: '1px solid black', color: 'black', display: 'flex', alignItems: "center", justifyContent: 'flex-start', }}>

            <img src={adminSettingsPanel} style={{ marginRight: '.5rem' }} />
            {roles === undefined || roles === null || roles.length <= 0 ? (<span>No Roles</span>) : roles.map((r) => (<span key={r}>{r}</span>))}
          </div>

          <a style={{ display: 'flex', flexDirection: 'row', padding: '1rem', paddingLeft: '5px', width: '100%', alignItems: 'center', justifyContent: 'flex-start' }} onClick={logout} className={styles.logoutDiv}>

            <img style={{ marginRight: '.5rem' }} src={logoutIcon} className={styles.logoutDiv} />
            <IonLabel>Logout</IonLabel>
          </a>
        </IonPopover>
        {/* <IonRow>
                    <IonCol>
                        <IonMenuToggle>
                            <IonButton>Toggle Menu</IonButton>
                        </IonMenuToggle>
                    </IonCol>
                </IonRow> */}
      </div>
      <IonPopover trigger="account-name" data-testid="add-options" dismissOnSelect={true}>
        <IonList>
          <div className={styles.add_new_menu_div_container}>
            <NavLink className={styles.add_new_menu_item_anchor_tag} to={'/inventory/create'}>
              <IonLabel className={styles.add_new_menu_item_text}>Inventory</IonLabel>
            </NavLink>
          </div>
          {/* <a >
                                    <IonLabel>Yard Locations</IonLabel>
                                </a> */}
          {/* overwrite bottom border for last item */}
          <div className={styles.add_new_menu_div_container} style={{ border: 'none' }}>
            <NavLink className={styles.add_new_menu_item_anchor_tag} to={'/sales-order/create'}>
              <IonLabel className={styles.add_new_menu_item_text}>Sales Orders</IonLabel>
            </NavLink>
          </div>

          {/* <div className={styles.add_new_menu_div_container} style={{ border: 'none' }}>
                                    <NavLink
                                        className={styles.add_new_menu_item_anchor_tag}
                                        to={'/yard-location-details'}>
                                        <IonLabel className={styles.add_new_menu_item_text}>Add Yard Location</IonLabel>
                                    </NavLink>
                                </div> */}
        </IonList>
      </IonPopover>
    </IonHeader >
  );
};
