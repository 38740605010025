import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { InventoryCondition, InventoryStatus, InventorySubType, PhotoStatus } from '../../models/Global';

/****** UPDATE THIS IF YOU UPDATE THE SWITCH STATEMENT BELOW */
export const getEnumFromInventoryDtoPropertyName = (propertyName: keyof InventoryDto) => {
    switch (propertyName) {
        case 'Sub Type': {
            return InventorySubType;
        }

        case 'Condition': {
            return InventoryCondition;
        }
        case 'Status': {
            return InventoryStatus;
        }
        case 'Photo Status': {
            return PhotoStatus;
        }
        default: {
            return undefined;
        }
    }
};

export const EnumInventoryProperties = ['Sub Type', 'Condition', 'Status', 'Photo Status'] as (keyof InventoryDto)[];
