import React from 'react';
import AuditDetail from '../../../models/AuditModels/AuditDetail';
//@ts-ignore
import styles from './AuditLogCollapsibleContent.module.scss';

interface AuditLogCollapsibleContentProps {
    record: AuditDetail;
    index: number;
}

const AuditLogCollapsibleContent = ({ record, index }: AuditLogCollapsibleContentProps) => {
    return (
        <div className={styles.AuditLogCollapsibleContentContainer} data-testid="AuditLogCollapsibleContent">
            {/* <IonGrid>
                <IonRow>
                    <IonLabel>Old Value:</IonLabel>
                </IonRow>
                <IonRow>
                    <IonLabel>{record.oldValue}</IonLabel>
                </IonRow>
                <IonRow>
                    <IonLabel>New Value:</IonLabel>
                </IonRow>
                <IonRow>
                    <IonLabel>{record.newValue}</IonLabel>
                </IonRow>
            </IonGrid> */}
            <div className={styles.main_header}>Column: {record.columnName}</div>
            <div>
                <div className={styles.sub_header}>Old Value:</div>
                <div>{record.oldValue != undefined && record.oldValue != '' ? record.oldValue : '""'}</div>
            </div>
            <div>
                <div className={styles.sub_header}>New Value:</div>
                <div>{record.newValue != undefined && record.newValue != '' ? record.newValue : '""'}</div>
            </div>
        </div>
    );
};

export default AuditLogCollapsibleContent;
