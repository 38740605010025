import { banOutline, checkmarkOutline, warningOutline } from 'ionicons/icons';

import {
    IonButton,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
} from '@ionic/react';
import { addMinutes } from 'date-fns';
import moment from 'moment';
import React, { useCallback } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Option } from '../../../../../../components/Search/GeneralOption';
import { useDynamicallyGeneratedUniqueIdArrayState } from '../../../../../../hooks/useDynamicallyGeneratedUniqueIdArrayState/useDynamicallyGeneratedUniqueIdArrayState';
import {
    ComparisonOperatorEnumValueBased,
    ComparisonOperatorInfo,
    getAllComparisonOperatorInfosThatIncludeDataTypes,
    getComparisonOpeartorFromEnumValue,
    ParameterizedCategory,
} from '../../../../../../models/ComparisonOperator';
import { ComparisonRange } from '../../../../../../models/Dtos/OptionsDtos/ComparisonRange';
import { DynamicFilter } from '../../../../../../models/Dtos/OptionsDtos/DynamicFilter';
import { DynamicFilterWithSourceInfo } from '../../../../../../models/Dtos/OptionsDtos/DynamicFilterWithSourceInfo';
import {
    GeneralOptions,
    GeneralOptionsColumnsToShowAndColumnOrderAsArray,
} from '../../../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { UserGlobalFilterSpecificGeneralOption } from '../../../../../../models/Dtos/OptionsDtos/UserGlobalFilterSpecificGeneralOption';
import { DataType, DtoType } from '../../../../../../models/Global';
import { getDataTypeByRecordTypeTypeAndPropertyName } from '../../../../../../utils/DataTypeMapperUtil';
import { getEnumByDtoTypeAndPropertyName } from '../../../../../../utils/EnumMapper';
import { getAllStringValuesFromEnum } from '../../../../../../utils/EnumUtil';
import {
    addGeneratedIdIfNoIdOrAddSpecifiedId,
    GeneratedIdType,
    getIdIfItExistsOrReturnsNull,
    KEYED_TYPE_IDENTIFIER_PROPERTY_NAME,
} from '../../../../../../utils/KeyIdUtils';
import makeSimpleToast from '../../../../../../utils/MakeSimpleToast';
import {
    isNotNullNorUndefined,
    isNotNullNorUndefinedNorEmptyString,
    isNullOrUndefined,
} from '../../../../../../utils/NullOrUndefined';
import { AsyncSelectLoadOptionsReturnType } from '../../../../../Search/SearchUtils';
import styles from './FilterManager.module.scss';
import './FilterManager.scss';

interface FilterManagerProps<T> {
    handleSetCustomFiltersValueFilters: (dynamicFilter: DynamicFilter[]) => void;
    dtoType: DtoType;
    model: object;
    closeModal: () => void;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    fieldsThatAreCustomTypes: {
        [key in keyof T]?: { label: string; value: string; title: string }[];
    };
    getAsyncSelectGetMethodFromPropertyName: (
        property: keyof T
    ) => ((name: string) => Promise<AsyncSelectLoadOptionsReturnType<Option>>) | undefined;
    filterKeyArrayByExcludedColumns: (array: (keyof T)[]) => (keyof T)[];
    computedSelectedGlobalFilters: UserGlobalFilterSpecificGeneralOption[];
    computedSelectedUserFilters: UserGlobalFilterSpecificGeneralOption[];
    customFilters: GeneralOptions;
    unselectGlobalFilter: (globalfilterName: string) => boolean;
    unselectUserFilter: (globalfilterName: string) => boolean;
    ignoreFilter: (filterId: string) => boolean;
    unignoreFilter: (filterId: string) => boolean;

    isFilterIgnored: (filterId: string) => boolean;

    combinedFilterTargetProperties: string[];
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterManager = <T,>({
    handleSetCustomFiltersValueFilters,
    model,
    closeModal,
    dtoType,
    computedCombinedFilters,
    fieldsThatAreCustomTypes,
    getAsyncSelectGetMethodFromPropertyName,
    filterKeyArrayByExcludedColumns,

    computedSelectedGlobalFilters,
    computedSelectedUserFilters,
    customFilters,
    unselectGlobalFilter,
    unselectUserFilter,
    ignoreFilter,
    unignoreFilter,
    isFilterIgnored,

    combinedFilterTargetProperties,
    setIsLoading,
}: FilterManagerProps<T>) => {
    const {
        arrayState: internalValueFilters,
        addObj: addObjToInternalValueFilters,
        updateObj: updateObjInInternalValueFilters,
        removeObj: removeObjFromInternalValueFilters,
    } = useDynamicallyGeneratedUniqueIdArrayState<DynamicFilter>(customFilters.filterOptions);

    const handleCreatingNewFilter = () => {
        // handleSetCustomFiltersValueFilters(addGeneratedIdIfNoIdOrAddSpecifiedId(new DynamicFilter()));
        addObjToInternalValueFilters(addGeneratedIdIfNoIdOrAddSpecifiedId(new DynamicFilter())).then((response) => {
            if (!response.success) {
                const errorMesage = response.error.message;

                console.error(errorMesage);
                makeSimpleToast('Error While Creating New Filter:', errorMesage);
            }
        });
    };

    // useEffect(() => {
    //     setInternalValueFilters(valueFilters);
    // }, [JSON.stringify(valueFilters)]);

    const generateInputsForNonCustomTypeFileds = (
        dynamicFilter: GeneratedIdType<DynamicFilter>,
        dtoType: DtoType,
        comparisonOperatorWrapper: ComparisonOperatorInfo & {
            name: string;
        },
        dataType: DataType
    ) => {
        const parameterizedCategory = comparisonOperatorWrapper.parametizedCategory;

        const asyncSelectGetMethod = getAsyncSelectGetMethodFromPropertyName(dynamicFilter.propertyName as keyof T);
        return (
            <>
                {parameterizedCategory === ParameterizedCategory.Value && dataType === DataType.Bool ? (
                    <select
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={
                            dynamicFilter.value === undefined
                                ? undefined
                                : typeof dynamicFilter.value === 'boolean'
                                ? (!!dynamicFilter.value).toString()
                                : dynamicFilter.value
                        }
                        defaultValue={undefined}
                        onChange={(e) => {
                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, value: e.target.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Value:', errorMesage);
                                }
                            });
                        }}>
                        <option value={undefined}></option>
                        <option value={'true'}>True</option>
                        <option value={'false'}>False</option>
                    </select>
                ) : parameterizedCategory === ParameterizedCategory.Value && dataType === DataType.Enum ? (
                    <select
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.value == undefined ? '' : dynamicFilter.value}
                        defaultValue={undefined}
                        onChange={(e) => {
                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, value: e.target.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Value:', errorMesage);
                                }
                            });
                        }}>
                        <option value={undefined}></option>
                        {(() => {
                            const enumObject = getEnumByDtoTypeAndPropertyName(dtoType, dynamicFilter.propertyName);

                            const stringValues = getAllStringValuesFromEnum(enumObject);

                            return stringValues.map((enumValue, index) => {
                                return (
                                    <option key={enumValue} value={enumValue}>
                                        {enumValue}
                                    </option>
                                );
                            });
                        })()}
                    </select>
                ) : (comparisonOperatorWrapper.name.toLowerCase() === 'equal' ||
                      comparisonOperatorWrapper.name.toLowerCase() === 'notequal') &&
                  dataType === DataType.String &&
                  isNotNullNorUndefined(asyncSelectGetMethod) ? (
                    <AsyncSelect
                        className={styles.asyncSelectDiv}
                        value={{
                            label: dynamicFilter.value,
                            value: dynamicFilter.value,
                        }}
                        loadOptions={asyncSelectGetMethod}
                        cacheOptions={true}
                        defaultOptions={true}
                        filterOption={(option) => option.value.toLowerCase() !== 'create customer'}
                        onChange={(option) => {
                            if (isNullOrUndefined(option)) {
                                return;
                            }

                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, value: option.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Value:', errorMesage);
                                }
                            });
                        }}
                    />
                ) : (
                    parameterizedCategory === ParameterizedCategory.Value &&
                    typeof dynamicFilter.value !== 'boolean' && (
                        <input
                            disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                            value={
                                dynamicFilter.value == undefined
                                    ? ''
                                    : dataType === DataType.DateTime
                                    ? moment(dynamicFilter.value).format('YYYY-MM-DD')
                                    : dynamicFilter.value
                            }
                            placeholder="Test"
                            type={dataType !== DataType.DateTime ? 'text' : 'date'}
                            data-parameter="value"
                            onChange={(e) => {
                                const updatedProperties = {} as Partial<DynamicFilter>;
                                if (dataType === DataType.DateTime) {
                                    const newDate = new Date(e.target.value);
                                    if (newDate.toString() === 'Invalid Date') {
                                        updatedProperties['value'] = e.target.value;
                                        return;
                                    }
                                    const convertedDate = addMinutes(newDate, newDate.getTimezoneOffset());

                                    try {
                                        const convertedISO = convertedDate.toISOString();

                                        const toSet = convertedISO.substring(0, convertedISO.length - 14);

                                        if (toSet.charAt(0) === '0') {
                                            updatedProperties['value'] = e.target.value;
                                        } else {
                                            updatedProperties['value'] = toSet;
                                        }
                                    } catch {
                                        updatedProperties['value'] = e.target.value;
                                    }
                                } else {
                                    updatedProperties['value'] = e.target.value;
                                }

                                updateObjInInternalValueFilters([
                                    dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                    (item) => ({ ...item, ...updatedProperties }),
                                ]).then((response) => {
                                    if (!response.success) {
                                        const errorMesage = response.error.message;

                                        console.error(errorMesage);
                                        makeSimpleToast('Error While Updating Filter Properties:', errorMesage);
                                    }
                                });

                                // const year = addLeadingZeros(date.getFullYear(), 4);
                                // const month = addLeadingZeros(date.getMonth() + 1, 2); // Adding to month because it starts at 0. Additinally, the 2 means to make it take up 2 characters
                                // const day = addLeadingZeros(date.getDate(), 2);

                                // const result = `${year}-${month}-${day}`;

                                // setFilterValue(result);
                            }}
                        />
                    )
                )}

                {parameterizedCategory === ParameterizedCategory.StringValue && (
                    <input
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.value == undefined ? '' : (dynamicFilter.value as string)}
                        type={'text'}
                        data-parameter="string-value"
                        onChange={(e) => {
                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, value: e.target.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Value:', errorMesage);
                                }
                            });
                        }}
                    />
                )}

                {parameterizedCategory === ParameterizedCategory.DateTypeAndValue && (
                    <input
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.value == undefined ? '' : (dynamicFilter.value as string)}
                        type="number"
                        data-parameter="dateTypeAndValue-value"
                        onChange={(e) => {
                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, value: e.target.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Value:', errorMesage);
                                }
                            });
                        }}
                    />
                )}

                {(parameterizedCategory === ParameterizedCategory.DateType ||
                    parameterizedCategory === ParameterizedCategory.DateTypeAndValue) && (
                    <select
                        disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                        value={dynamicFilter.comparisonRange == undefined ? '' : dynamicFilter.comparisonRange}
                        placeholder="Date Time Type"
                        data-parameter="dateType"
                        defaultValue={undefined}
                        onChange={(e) => {
                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, comparisonRange: +e.target.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Comparison Range:', errorMesage);
                                }
                            });
                        }}>
                        <option value={undefined}></option>
                        <option value={ComparisonRange.Year}>Year</option>
                        <option value={ComparisonRange.RollingYear}>Rolling Year</option>
                        <option value={ComparisonRange.Quarter}>Quarter</option>
                        <option value={ComparisonRange.Month}>Month</option>
                        <option value={ComparisonRange.Week}>Week</option>
                        <option value={ComparisonRange.Day}>Day</option>
                    </select>
                )}
            </>
        );
    };

    const generateSelectForOperator = (dynamicFilter: GeneratedIdType<DynamicFilter>) => {
        const isPreExisting = dynamicFilter.propertyName !== '' && dynamicFilter.propertyName != null;

        if (isNullOrUndefined(dynamicFilter.propertyName)) {
            console.warn('Dynamic Filter Property Name is null inside of generateSelectForOperator!');
            return;
        }

        const dataType = getDataTypeByRecordTypeTypeAndPropertyName(dtoType, dynamicFilter.propertyName);

        const comparisonOperatorWrapper = getComparisonOpeartorFromEnumValue(dynamicFilter.comparisonOperator);

        const customTypeOptionsReference = fieldsThatAreCustomTypes[dynamicFilter.propertyName as keyof T];

        return (
            <>
                <select
                    disabled={dynamicFilter.propertyName == undefined || dynamicFilter.propertyName === ''}
                    onChange={(e) => {
                        updateObjInInternalValueFilters([
                            dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                            (item) => ({ ...item, comparisonOperator: +e.target.value }),
                        ]).then((response) => {
                            if (!response.success) {
                                const errorMesage = response.error.message;

                                console.error(errorMesage);
                                makeSimpleToast('Error While Updating Filter Comparison Operator:', errorMesage);
                            }
                        });
                    }}
                    value={dynamicFilter.comparisonOperator}>
                    {getAllComparisonOperatorInfosThatIncludeDataTypes(dataType).map((comparisonOperatorWrapper) => {
                        const enumNumber = comparisonOperatorWrapper.enumValue;
                        return (
                            <React.Fragment key={enumNumber}>
                                <option key={enumNumber} value={enumNumber}>
                                    {comparisonOperatorWrapper.displayName}
                                </option>
                            </React.Fragment>
                        );
                    })}
                </select>
                {isNotNullNorUndefined(customTypeOptionsReference) ? (
                    <Select
                        className={styles.asyncSelectDiv}
                        value={{ label: dynamicFilter.value, value: dynamicFilter.value, title: dynamicFilter.value }}
                        filterOption={(option, inputValue) => {
                            const { value } = option;

                            const result = value.toLowerCase().includes(inputValue.toLowerCase());
                            return result;
                            // return true;
                        }}
                        options={customTypeOptionsReference}
                        onChange={(e) => {
                            if (isNullOrUndefined(e)) {
                                return;
                            }

                            updateObjInInternalValueFilters([
                                dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                (item) => ({ ...item, value: e.value }),
                            ]).then((response) => {
                                if (!response.success) {
                                    const errorMesage = response.error.message;

                                    console.error(errorMesage);
                                    makeSimpleToast('Error While Updating Filter Value:', errorMesage);
                                }
                            });
                        }}
                    />
                ) : (
                    generateInputsForNonCustomTypeFileds(dynamicFilter, dtoType, comparisonOperatorWrapper, dataType)
                )}
            </>
        );
    };

    // https://github.com/facebook/react/issues/24391#issuecomment-1107796997
    // Needs to show up after all hooks
    // if (!showFilterModal) {
    //     // Makes it so nothing renders if show filter modal is false
    //     return <></>;
    // }

    const handleGenerateIgnoreControl = useCallback(
        (id: string) => {
            if (isFilterIgnored(id)) {
                // Ignore already

                return (
                    <span className={styles.ignoredControl}>
                        <IonIcon
                            size="large"
                            onClick={() => {
                                unignoreFilter(id);
                            }}
                            icon={banOutline}
                        />
                    </span>
                );
            } else {
                return (
                    <span className={styles.unignoredControl}>
                        <IonIcon
                            size="large"
                            onClick={() => {
                                ignoreFilter(id);
                            }}
                            icon={checkmarkOutline}
                        />
                    </span>
                );
            }
        },
        [ignoreFilter, isFilterIgnored, unignoreFilter]
    );

    const generateFilterList = useCallback(
        (
            title: string,
            computedSelectedFilters: UserGlobalFilterSpecificGeneralOption[],
            unselectFilter: (globalfilterName: string) => boolean,
            classNameForContainer: string
        ) => {
            return (
                <>
                    <IonRow>
                        <IonCol>
                            <IonHeader>{title}</IonHeader>
                        </IonCol>
                    </IonRow>
                    <div className={classNameForContainer}>
                        {computedSelectedFilters.map((filter) => {
                            return (
                                <div
                                    key={filter.filterName}
                                    style={{
                                        boxShadow: '5px 10px 8px #888888',
                                        marginBottom: '1rem',
                                        marginRight: '1rem',
                                        marginLeft: '1rem',
                                    }}>
                                    <IonRow>
                                        <IonCol>
                                            <IonItem>{filter.filterName}</IonItem>
                                        </IonCol>
                                        <IonCol>
                                            <IonButton
                                                onClick={() => {
                                                    unselectFilter(filter.filterName);
                                                }}
                                                color="danger">
                                                Unselect
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    {filter.filterOptions.map((filterOption) => {
                                        const dynamicFilterWithSource = filterOption as DynamicFilterWithSourceInfo;
                                        const id = getIdIfItExistsOrReturnsNull(dynamicFilterWithSource);

                                        if (isNullOrUndefined(id)) {
                                            makeSimpleToast(
                                                'Error',
                                                `While Trying to generate ignore control, ID of ${id} for filter doesn't exist. `
                                            );
                                            console.error(
                                                `While Trying to generate ignore control, ID of ${id} for filter doesn't exist. `
                                            );
                                            return (
                                                <div
                                                    key={`${filter.filterName}-${dynamicFilterWithSource.propertyName}`}>
                                                    Error occured while rendering ignore control...
                                                </div>
                                            );
                                        }

                                        const filterIgnored = isFilterIgnored(id);

                                        return (
                                            <div key={`${filter.filterName}-${dynamicFilterWithSource.propertyName}`}>
                                                <IonRow style={filterIgnored ? { opacity: '0.5' } : {}}>
                                                    <IonCol>
                                                        <div>
                                                            {combinedFilterTargetProperties.filter(
                                                                (x) => x === filterOption.propertyName
                                                            ).length > 1 ? (
                                                                <>
                                                                    <IonIcon
                                                                        size="medium"
                                                                        style={{
                                                                            marginRight: '.5rem',
                                                                            color: '#d9c800',
                                                                            fontSize: '1.25rem',
                                                                        }}
                                                                        icon={warningOutline}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <IonLabel>{dynamicFilterWithSource.propertyName}</IonLabel>
                                                        </div>
                                                    </IonCol>
                                                    <IonCol>
                                                        {
                                                            ComparisonOperatorEnumValueBased[
                                                                dynamicFilterWithSource.comparisonOperator + ''
                                                            ].displayName
                                                        }
                                                    </IonCol>
                                                    {isNotNullNorUndefinedNorEmptyString(
                                                        dynamicFilterWithSource.value
                                                    ) && <IonCol>{dynamicFilterWithSource.value}</IonCol>}

                                                    {isNotNullNorUndefined(dynamicFilterWithSource.comparisonRange) &&
                                                        dynamicFilterWithSource.comparisonType ===
                                                            DataType.DateTime && (
                                                            <IonCol>
                                                                {
                                                                    ComparisonRange[
                                                                        dynamicFilterWithSource.comparisonRange
                                                                    ]
                                                                }
                                                            </IonCol>
                                                        )}

                                                    <IonCol>{handleGenerateIgnoreControl(id)}</IonCol>
                                                </IonRow>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </>
            );
        },
        [combinedFilterTargetProperties, handleGenerateIgnoreControl, isFilterIgnored]
    );

    // Going to let it update immediately.
    // simplier design for me right now.
    // Make come back to make it more consistent

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.FilterSortControlModal} data-testid="FilterSortControlModal">
                    <IonGrid>
                        <>
                            {computedSelectedGlobalFilters.length > 0 &&
                                generateFilterList(
                                    'Global Filters',
                                    computedSelectedGlobalFilters,
                                    unselectGlobalFilter,
                                    styles.globalFilterList
                                )}

                            {computedSelectedUserFilters.length > 0 &&
                                generateFilterList(
                                    'User Filters',
                                    computedSelectedUserFilters,
                                    unselectUserFilter,
                                    styles.userFilterList
                                )}

                            {internalValueFilters.length > 0 && (
                                <IonRow>
                                    <IonCol>
                                        <IonHeader>Custom Filters</IonHeader>
                                    </IonCol>
                                </IonRow>
                            )}

                            {internalValueFilters.map((dynamicFilter) => {
                                let dataType = getDataTypeByRecordTypeTypeAndPropertyName(
                                    dtoType,
                                    dynamicFilter.propertyName
                                );
                                return (
                                    <>
                                        <div
                                            className={styles.customFilterList}
                                            key={dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]}>
                                            <IonRow>
                                                <IonCol>
                                                    <IonItem>
                                                        {combinedFilterTargetProperties.filter(
                                                            (x) => x === dynamicFilter.propertyName
                                                        ).length > 1 ? (
                                                            <>
                                                                <IonIcon
                                                                    size="medium"
                                                                    style={{
                                                                        marginRight: '.5rem',
                                                                        color: '#d9c800',
                                                                        fontSize: '1.25rem',
                                                                    }}
                                                                    icon={warningOutline}
                                                                />
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <IonSelect
                                                            className={styles.propertySelect}
                                                            title={dynamicFilter.propertyName}
                                                            value={dynamicFilter.propertyName}
                                                            onIonChange={(e) => {
                                                                if (e.detail.value === '') {
                                                                    return;
                                                                }

                                                                const updatedDataType =
                                                                    getDataTypeByRecordTypeTypeAndPropertyName(
                                                                        dtoType,
                                                                        e.detail.value
                                                                    );

                                                                updateObjInInternalValueFilters([
                                                                    dynamicFilter[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME],
                                                                    (item) => ({
                                                                        ...item,
                                                                        comparisonType: updatedDataType,
                                                                        propertyName: e.detail.value,
                                                                    }),
                                                                ]).then((response) => {
                                                                    if (!response.success) {
                                                                        const errorMesage = response.error.message;

                                                                        console.error(errorMesage);
                                                                        makeSimpleToast(
                                                                            'Error While Updating Filter Comparison Type and Prop Name:',
                                                                            errorMesage
                                                                        );
                                                                    }
                                                                });

                                                                // handleUpdateFilterProprety(
                                                                //     dynamicFilter,
                                                                //     'comparisonType',
                                                                //     updatedDataType
                                                                // );
                                                                // handleUpdateFilterProprety(
                                                                //     dynamicFilter,
                                                                //     'propertyName',
                                                                //     e.detail.value
                                                                // );
                                                                dataType = updatedDataType;
                                                            }}>
                                                            {filterKeyArrayByExcludedColumns(
                                                                Object.keys(model) as (keyof T)[]
                                                            )
                                                                .sort()
                                                                .map((columnName) => (
                                                                    <IonSelectOption
                                                                        key={columnName}
                                                                        value={columnName}>
                                                                        {columnName}
                                                                    </IonSelectOption>
                                                                ))}
                                                        </IonSelect>
                                                    </IonItem>
                                                </IonCol>
                                                {/* <IonCol>
                                        <select>
                                            {ComparisonOperatorClassWrapper.ComparisonOperatorArray.filter(
                                                (comparisonOperator) => {
                                                    const matchingDataTypes =
                                                        getTargetedDataTypeFromPropertyName(comparisonOperator);

                                                    return matchingDataTypes.includes(dataType);
                                                }
                                            ).map((comparisonOperator: string) => {
                                                const enumNumber =
                                                    ComparisonOperatorClassWrapper.getEnumNumber(comparisonOperator);
                                                return (
                                                    <React.Fragment key={enumNumber}>
                                                        <option key={enumNumber} value={enumNumber}>
                                                            {comparisonOperator}
                                                        </option>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </select>
                                    </IonCol> */}
                                                <IonCol>{generateSelectForOperator(dynamicFilter)}</IonCol>
                                                <IonCol>
                                                    <IonButton
                                                        color={'danger'}
                                                        onClick={async () => {
                                                            const result = await removeObjFromInternalValueFilters(
                                                                dynamicFilter
                                                            );
                                                            if (!result.success) {
                                                                makeSimpleToast(
                                                                    'Error while deleting value filter:',
                                                                    result.error.message
                                                                );
                                                            }
                                                        }}>
                                                        Delete
                                                    </IonButton>
                                                </IonCol>
                                            </IonRow>
                                        </div>
                                    </>
                                );
                            })}
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={handleCreatingNewFilter}>Add Filter</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    </IonGrid>
                </div>
            </div>
            <div>
                <IonRow>
                    <IonCol>
                        <IonButton
                            onClick={() => {
                                const processedFilters = internalValueFilters
                                    .filter((filter) => isNotNullNorUndefinedNorEmptyString(filter.propertyName))

                                    // Removes Id Hopefully
                                    .map(({ 'UT-Key-Unique-Identifier': id, ...rest }) => {
                                        return { ...rest };
                                    });

                                handleSetCustomFiltersValueFilters(processedFilters);
                                setIsLoading(true);
                                closeModal();
                            }}>
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </div>
        </div>
    );
};

export default FilterManager;
