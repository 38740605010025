import cloneDeep from "lodash.clonedeep";
const monthNames = [
  "Jan.", "Feb.", "Mar.", "Apr.", "May", "June",
  "July", "Aug.", "Sept.", "Oct.", "Nov.", "Dec."
];

export const GenerateGraphDataByType = (data: (Record<string, any>)[]) => {


  return data.map(item => {

    const { year, month, new: New, used: Used } = item;

    const clone = { New, Used } as Record<string, any>;

    clone.type = `${monthNames[month - 1]} ${year}`


    return clone;
  })

}


export const KeysExcludeObjectArray = (data: (Record<string, any>)[], toExclude: string) => Object.keys(data).filter(k => k.toLowerCase().trim() !== toExclude.toLowerCase().trim());
