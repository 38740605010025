import { IonButton, IonIcon } from "@ionic/react";
import debounce from "debounce";
import {
  caretDownOutline,
  caretUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { ReturnAccountingToDoDto } from "../../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto";
import { TypeInteractionDto } from "../../../models/Dtos/TypeInteractionDto";
import {
  DataType,
  InventoryCondition,
  RecordType,
} from "../../../models/Global";
import { DbManagedTypes } from "../../../pages/AdminPanel/components/TypeManager";
import { Layout } from "../../../pages/Layout";
import {
  AccountingService,
  SalesOrderService,
  TypeService,
} from "../../../utils/apiServices";
import {
  filterAccountingTodoColumnMethod,
  filterAccountingTodoKeyArrayByExcludedColumns,
  isAccountingTodoPropertyExcluded,
} from "../../../utils/ColumnsToShowFilterByDtoType/AccountingTodoColumnsToShowFilter";
import confirmingTableUpdate from "../../../utils/ConfirmingTableUpdate";
import { convertAllNullToEmptyStringFromObject } from "../../../utils/ConvertAllNullToEmptyStringUtil";
import { convertFromStringIfBooleanOrNumber } from "../../../utils/DataTypeConversionFromString/DataTypeConversionFromString";
import { getDataTypeFromAccountingTodoPropertyName } from "../../../utils/DataTypeMappersByDtoType/AccountingTodoDataMapper";
import { getEnumFromAccountingTodoDtoPropertyName } from "../../../utils/EnumMappersByDtoType/AccountingTodoEnumMapper";
import { IdentifierType } from "../../../utils/identifierType";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../../../utils/NullOrUndefined";
import PageNameGenerator from "../../../utils/PageNameGenerator";
import { AllPossibleReturnTypes } from "../../../utils/RecordTypeUtils";
import CopyTableStylesButtons from "../../CopyTableStylesButtons/CopyTableStylesButtons";
import { ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID } from "../../Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer";
import QueriedTablePaginationOptions from "../../Theme/QueriedTable/QueriedTablePaginationOptions/QueriedTablePaginationOptions";
import {
  LocationType,
  QueriedTableSortFilterStateManagerExportProps,
} from "../../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import QueriedTableTabList from "../../Theme/QueriedTable/QueriedTableTabList/QueriedTableGlobalUserFilterList";
import QueriedTableToolbar from "../../Theme/QueriedTable/QueriedTableToolbar/QueriedTableToolbar";
import { NORMAL_COLS_SIZE } from "../GeneralReference";
import styles from "./AccountingTodoTable.module.scss";

type AccountingTodoTableProps = QueriedTableSortFilterStateManagerExportProps;

const AccountingTodoTable = ({
  endpoint,
  toolbar,
  identifier,
  identifierType,
  handleSetCustomFiltersValueFilters,
  handleCreateUpdateUserFilter,
  justSortOptions,
  overwritingProvidedData,
  model,
  useCustomEndpoint,
  setUseCustomEndpoint,
  recordType,
  handleClearCancel,
  isFilterSelected,
  handleCreateUpdateGlobalFilter,
  handleDeleteGlobalFilter,
  handleDeleteUserFilter,
  handleUpdatingItemsPerPage,
  setOverwritingProvidedData,
  setSortFilters,
  dtoType,
  tabbed,
  handleGlobalFilterButtonClick,
  data,
  setData,
  computedCombinedFilters,
  handleUserFilterButtonClick,
  setItemsPerPage,
  DEBOUNCE_WAIT_TIME,
  handleChangeSorting,
  handleOrderColumnToLeft,
  handleOrderColumnToRight,
  updateCurrentPageNumber,
  setColumnVisibility,
  selectingRowMode,
  unselectAllRows,
  selectAllRows,
  cancelSelecting,
  stopSelectingAndCopyTableWithStyles,
  stopSelectingAndEmailAdvertisements,
  startSelecting,
  isRowIdSelected,
  unselectRow,
  registerSelection,
  selectedGlobalFilterNames,
  selectedUserFilterNames,
  computedSelectedGlobalFilters,
  computedSelectedUserFilters,
  userFiltersStorage,
  globalFiltersStorage,
  setColumnOrder,
  customFilters,
  unselectGlobalFilter,
  unselectUserFilter,
  ignoreFilter,
  unignoreFilter,
  isFilterIgnored,
  combinedFilterTargetProperties,
  updateSearchTerm,
  handleNewUpdaterFunction,

  ignoredFilterIds,
  isLoading,
  setIsLoading,
}: AccountingTodoTableProps) => {
  const history = useHistory();
  const ACCOUNTING_TODO_PAGE_TITLE = PageNameGenerator("Accounting Todo");

  useEffect(() => {
    if (document.title !== ACCOUNTING_TODO_PAGE_TITLE)
      document.title = ACCOUNTING_TODO_PAGE_TITLE;
  }, []);

  const generateTableHeaders = useCallback(
    (dto: ReturnAccountingToDoDto) => {
      if (isNullOrUndefined(dto)) {
        return null;
      }

      let headers: React.ReactElement[];
      const getSortForColumn = (property: string) => {
        const targetSort = computedCombinedFilters.sortOptions.sortBy.find(
          (item) => item.propertyName === property
        );

        if (isNullOrUndefined(targetSort)) {
          return undefined;
        }

        return targetSort.isAscending;
      };

      const generateThMapMethod = (property: string, index: number) => (
        <th key={property}>
          <div className={styles.tableHeaderButtonWrapper}>
            <span className={styles.headerSpanContainer}>
              <div style={{ display: "block" }}>
                <button
                  onClick={(e) => handleChangeSorting(property)}
                  className={styles.headerButton}
                >
                  {property}
                  {isNullOrUndefined(getSortForColumn(property)) ? (
                    <></>
                  ) : getSortForColumn(property) ? (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretUpOutline}
                    />
                  ) : (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretDownOutline}
                    />
                  )}
                </button>
              </div>
              {index > 0 && (
                <button
                  onClick={(e) => {
                    handleOrderColumnToLeft(
                      property as keyof AllPossibleReturnTypes
                    );
                  }}
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.leftColumnButton}`}
                    icon={chevronBackOutline}
                  />
                </button>
              )}
              {index <
                computedCombinedFilters.orderedColumnsToShow.length - 1 && (
                <button
                  onClick={(e) => {
                    handleOrderColumnToRight(
                      property as keyof AllPossibleReturnTypes
                    );
                  }}
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.rightColumnButton}`}
                    icon={chevronForwardOutline}
                  />
                </button>
              )}
            </span>
          </div>
        </th>
      );

      const selectionColumn = (
        <th
          className={
            selectingRowMode === "unselected"
              ? styles.selectionColumnHidden
              : ""
          }
          style={{ backgroundColor: "white" }}
          key="selection-column"
        >
          To Copy
        </th>
      );

      if (
        isNullOrUndefined(computedCombinedFilters) ||
        !Array.isArray(computedCombinedFilters.orderedColumnsToShow) ||
        computedCombinedFilters.orderedColumnsToShow[0] === ""
      ) {
        // Get keys  of first entry to get headers.
        headers = [
          selectionColumn,
          ...Object.keys(dto)
            .filter(filterAccountingTodoColumnMethod)
            .map(generateThMapMethod),
        ];
      } else {
        // Use columnsToShow from savedGeneratedGeneralOptions to get headers.

        // REAPLCE HERE WITH STUF

        headers = [
          selectionColumn,
          ...computedCombinedFilters.orderedColumnsToShow
            .filter(filterAccountingTodoColumnMethod)
            .map(generateThMapMethod),
        ];
      }
      return headers;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      computedCombinedFilters,
      handleChangeSorting,
      handleOrderColumnToLeft,
      handleOrderColumnToRight,
      selectingRowMode,
    ]
  );

  const handleGenerateTableParts = () => {
    let tableHead: React.ReactElement | null = null;

    const rows = (data as ReturnAccountingToDoDto[]).map((dto, index) => {
      // Doing it this way to limit the amount of iteration done
      let headers: React.ReactElement[] | null = null;
      if (index === 0) {
        headers = generateTableHeaders(dto);
        tableHead = (
          <thead className={styles.tHead}>
            <tr>{headers}</tr>
          </thead>
        );
      }

      return (
        <tr
          key={dto["Stock"]}
          className={`${
            index % 2 === 0 ? styles.highlighted : styles.notHighlighted
          } ${styles.tRow}`}
        >
          {runner(dto)}
          {/* <InventoryTableRow
                        dtoData={dto}
                        orderedColumnsToShow={
                            savedGeneratedGeneralOptions.current?.columnsToShow as (keyof InventoryDto)[] | undefined
                        }
                    /> */}
        </tr>
      );
    });

    console.timeEnd();

    return (
      <>
        {tableHead}
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleSelectOrTextAreaUpdateValueProvider = (
    event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof ReturnAccountingToDoDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleUnDebouncedUpdate(value, event, columnName, stock);
  };

  const getTargetRowOrConsoleError = (
    invoiceNumber: string
  ): [number, ReturnAccountingToDoDto] => {
    const targetRowIndex = (data as ReturnAccountingToDoDto[]).findIndex(
      (dto) => dto["Stock"] === invoiceNumber
    );
    if (targetRowIndex === -1) {
      const errorMessage =
        "Target row of invoice number" + invoiceNumber + " can't be found!!";

      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    const targetRow = data[targetRowIndex] as ReturnAccountingToDoDto;
    return [targetRowIndex, targetRow];
  };

  const handleUnDebouncedUpdate = async (
    value: string | boolean,
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof ReturnAccountingToDoDto,
    stock: string
  ) => {
    const isCheckbox = event.target.type === "checkbox";

    const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

    const copiedTargetRow = { ...targetRow };

    const convertedValue = isCheckbox
      ? value
      : convertFromStringIfBooleanOrNumber(
          value as string,
          getDataTypeFromAccountingTodoPropertyName(
            columnName as keyof ReturnAccountingToDoDto
          )
        );

    //@ts-ignore
    copiedTargetRow[columnName] = convertedValue;

    // prop = ;

    // Is debounce 1000ms

    const response = await SalesOrderService.complete(copiedTargetRow.Stock);

    setData((oldData) => {
      const toUpdate = [...oldData];
      toUpdate.splice(targetRowIndex, 1, copiedTargetRow);
      return toUpdate;
    });
  };

  const convertDefaultDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  const convertDefaultCheckbox = (checkboxStringValue: string | boolean) =>
    typeof checkboxStringValue === "boolean"
      ? checkboxStringValue
      : checkboxStringValue.toLowerCase() === "true";

  type testtest = { label: string; value: string };
  type OptionType = {
    label: string;
    value: string;
    title: string;
  };

  const [typeTypes, setTypeTypes] = useState<OptionType[]>([]);
  const [makeTypes, setMakeTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    const handleUpdatingType = async (
      dbManagedType: DbManagedTypes,
      updateFunc: (types: OptionType[]) => unknown
    ) => {
      const typeInteractionDto: TypeInteractionDto = {
        originalName: "",
        newName: "",
        dbManagedType: dbManagedType,
      };

      const response = await TypeService.GetNameList(typeInteractionDto);

      const types = response.data.map((type) => ({
        label: type,
        value: type,
        title: type,
      }));

      updateFunc(types);
    };

    handleUpdatingType(DbManagedTypes.InventoryType, setTypeTypes);
    handleUpdatingType(DbManagedTypes.Make, setMakeTypes);
  }, []);

  const handleUpdateType = async (
    value: string | undefined,
    column: keyof ReturnAccountingToDoDto,
    dbManagedType: DbManagedTypes,
    stock: string
  ) => {
    if (isNullOrUndefined(value)) {
      return;
    }

    const [, targetDto] = getTargetRowOrConsoleError(stock);

    const copiedTargetDto = { ...targetDto };

    //@ts-ignore
    copiedTargetDto[column] = value;

    const result = await SalesOrderService.complete(copiedTargetDto.Stock);

    setData((oldData) => {
      const targetIndex = oldData.findIndex(
        (dto) => (dto as ReturnAccountingToDoDto)["Stock"] === stock
      );
      if (targetIndex === -1) {
        return oldData;
      }

      const duplicate = [...oldData];
      duplicate.splice(targetIndex, 1, copiedTargetDto);
      return duplicate;
    });
  };

  const tableDivContainerRef = useRef<HTMLDivElement>(null);

  const INPUT_CLASS_NAME = "inventory-table-input-class-name";

  // useEffect(() => {
  //     if (isNullOrUndefined(tableDivContainerRef.current)) {
  //         console.log('table div element is undefined');
  //         return;
  //     }

  //     const tableDiv = tableDivContainerRef.current;

  //     Array.from(tableDiv.getElementsByClassName(INPUT_CLASS_NAME)).forEach((inputEl) => {
  //         // not null bc of filter above.
  //         const notNullInput = inputEl as HTMLInputElement | HTMLSelectElement;
  //         const [stock, name] = notNullInput.name.split(':');

  //         const target = (data as ReturnAccountingToDoDto[]).find((dto) => dto['Stock'] === stock);

  //         if (isNullOrUndefined(target)) {
  //             const errorMessage = `Attempting to find row with invoice number ${stock} but can't be found! Please alert web developers`;
  //             console.error(errorMessage);
  //             alert(errorMessage);
  //             return;
  //         }

  //         let value = target[name as keyof ReturnAccountingToDoDto];
  //         if (isNullOrUndefined(value) || value === '0.00') {
  //             value = '';
  //         }

  //         const dataType = getDataTypeFromAccountingTodoPropertyName(name as keyof ReturnAccountingToDoDto);
  //         if (dataType === DataType.DateTime) {
  //             if (value === '') {
  //                 // Will look like dd/mm/yyyy. Want it to be empty. So we'll set color to transparent when not focused
  //                 (inputEl as HTMLInputElement).classList.add(styles.dateInputEmpty);
  //             } else {
  //                 (inputEl as HTMLInputElement).classList.remove(styles.dateInputEmpty);

  //                 const result = convertDefaultDate(value as string);
  //                 value = result;
  //             }
  //         }
  //         if (dataType === DataType.Bool) {
  //             value = convertDefaultCheckbox(value as string | boolean);
  //         }

  //         notNullInput.value = value + '';
  //     });
  // }, [data]);

  const handleSelectOnChangeUpdatingRows = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    colSize: number
  ) => {
    const textArea = e.target as HTMLTextAreaElement;
    const value = e.target.value;

    const quotient = value.length / colSize;

    const rowsToSet = Math.floor(quotient) + 1;

    if (textArea.rows !== rowsToSet) {
      textArea.rows = rowsToSet;
    }
  };

  const [selectedRowsToComplete, setSelectedRowsToComplete] = useState<{
    [key: string]: { checked: boolean; stock: string };
  }>({});

  const complete = async (stock: string) => {
    const response = await SalesOrderService.complete(stock);

    if (response.data.isSuccessful) {
      setData((data) =>
        (data as ReturnAccountingToDoDto[]).filter(
          (item) => item.Stock !== stock
        )
      );
    }
  };

  const handleInputUpdate = debounce(
    async (
      value: string | boolean,
      event:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>,
      columnName: keyof ReturnAccountingToDoDto,
      stock: string
    ) => {
      if (!confirmingTableUpdate(event)) {
        return;
      }
      const isCheckbox = event.target.type === "checkbox";

      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

      const copiedTargetRow = { ...targetRow };

      const convertedValue = isCheckbox
        ? value
        : convertFromStringIfBooleanOrNumber(
            value as string,

            getDataTypeFromAccountingTodoPropertyName(
              columnName as keyof ReturnAccountingToDoDto
            )
          );

      //@ts-ignore
      copiedTargetRow[columnName] = convertedValue;

      // prop = ;

      // Is debounce 1000ms

      const response =
        await AccountingService.updateSalesOrderFromAccountingToDo(
          convertAllNullToEmptyStringFromObject(
            copiedTargetRow as ReturnAccountingToDoDto
          )
        );

      setData((oldData) => {
        const toUpdate = [...oldData];
        toUpdate.splice(targetRowIndex, 1, response.data.model);
        return toUpdate;
      });
    },
    DEBOUNCE_WAIT_TIME
  );

  const handleTextareaUpdateValueProvider = (
    event: ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof ReturnAccountingToDoDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleInputUpdate(value, event, columnName, stock);
  };

  const handleCompleteAllSelected = () => {
    const itemsToRemove: string[] = [];
    Object.entries(selectedRowsToComplete)
      .filter(([key, value]) => value.checked)
      .forEach(async ([_, { stock }]) => {
        const response = await SalesOrderService.complete(stock);

        if (response.data.isSuccessful) {
          itemsToRemove.push(stock);
        }
        setData((data) =>
          (data as ReturnAccountingToDoDto[]).filter(
            (item) => !itemsToRemove.includes(item.Stock)
          )
        );
        setSelectedRowsToComplete({});
      });
  };

  const handleInputUpdateValueProvider = (
    event: ChangeEvent<HTMLInputElement>,
    columnName: keyof ReturnAccountingToDoDto,
    stock: string
  ) => {
    const isCheckbox = event.target.type === "checkbox";
    const value = isCheckbox ? event.target.checked : event.target.value;

    if (isCheckbox) {
      handleUnDebouncedUpdate(value, event, columnName, stock);
    } else {
      handleInputUpdate(value, event, columnName, stock);
    }
  };

  const savedFunctionsToUpdateData = {
    "Select": (
      property: keyof ReturnAccountingToDoDto,
      Select: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          type="checkbox"
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          value={selectedRowsToComplete[stock] + "" ?? "false"}
          onChange={(e) =>
            setSelectedRowsToComplete((oldRef) => ({
              ...oldRef,
              [stock]: { checked: e.target.checked, stock: stock },
            }))
          }
        />
      </td>
    ),
    "Stock": (
      property: keyof ReturnAccountingToDoDto,
      Stock: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onMouseUp={(e) => {
          if (e.button === 1) {
            if ((e.target as Element).tagName === "A") {
              // should be handled by the anchor tag itself
              return;
            }
            e.preventDefault();
            const link = `/inventory/edit/${IdentifierType.Stock}/${Stock}`;

            // window.open(link, '_blank');
            e.stopPropagation();
            return;
          }
        }}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const link = `/inventory/edit/${IdentifierType.Stock}/${Stock}`;

          if (e.ctrlKey) {
            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }

          if (e.shiftKey) {
            window.open(link);
            e.stopPropagation();
            return;
          }

          history.push({
            pathname: link,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
              fromAccountingTodo: true,
            } as LocationType,
          });
        }}
      >
        <NavLink
          to={{
            pathname: `/inventory/edit/${IdentifierType.Stock}/${Stock}`,
            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
              fromAccountingTodo: true,
            } as LocationType,
          }}
        >
          {Stock}
        </NavLink>
      </td>
    ),
    "Invoice Number": (
      property: keyof ReturnAccountingToDoDto,
      InvoiceNumber: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onMouseUp={(e) => {
          if (e.button === 1) {
            if ((e.target as Element).tagName === "A") {
              // should be handled by the anchor tag itself
              return;
            }
            e.preventDefault();
            const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

            // window.open(link, '_blank');
            e.stopPropagation();
            return;
          }
        }}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

          if (e.ctrlKey) {
            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }

          if (e.shiftKey) {
            window.open(link);
            e.stopPropagation();
            return;
          }

          history.push({
            pathname: link,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
              fromAccountingTodo: true,
            } as LocationType,
          });
        }}
      >
        <NavLink
          to={{
            pathname: `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`,
            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
              fromAccountingTodo: true,
            } as LocationType,
          }}
        >
          {InvoiceNumber}
        </NavLink>
      </td>
    ),

    "Customer Number": (
      property: keyof ReturnAccountingToDoDto,
      Number: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          defaultValue={Number}
          cols={NORMAL_COLS_SIZE}
          readOnly={true}
          rows={(Number + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
        />
      </td>
    ),

    "VIN": (
      property: keyof ReturnAccountingToDoDto,
      VIN: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          defaultValue={VIN}
          cols={NORMAL_COLS_SIZE}
          readOnly={true}
          rows={(VIN + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
        />
      </td>
    ),

    "Customer Name": (
      property: keyof ReturnAccountingToDoDto,
      CustomerName: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${stock}:${property}`}
          readOnly={true}
          defaultValue={CustomerName}
          cols={NORMAL_COLS_SIZE}
          rows={(Number + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
        />
      </td>
    ),
    "Sales Order Notes": (
      property: keyof ReturnAccountingToDoDto,
      SalesOrderNotes: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          cols={NORMAL_COLS_SIZE}
          rows={(SalesOrderNotes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          // style={{ ...generateInputStyles(Notes + ''), ...{ maxHeight: '2px' } }}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          name={`${stock}:${property}`}
          defaultValue={SalesOrderNotes}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.AccountingTodo,
              e.target.value,
              property,
              stock,
              SalesOrderNotes,
              () => (e.target.value = SalesOrderNotes + "")
            );
          }}
        />
      </td>
    ),

    "Delivery Notes": (
      property: keyof ReturnAccountingToDoDto,
      DeliveryNotes: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          cols={NORMAL_COLS_SIZE}
          rows={(DeliveryNotes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          // style={{ ...generateInputStyles(Notes + ''), ...{ maxHeight: '2px' } }}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          name={`${stock}:${property}`}
          defaultValue={DeliveryNotes}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.AccountingTodo,
              e.target.value,
              property,
              stock,
              DeliveryNotes,
              () => (e.target.value = DeliveryNotes + "")
            );
          }}
        />
      </td>
    ),
    "Inventory Notes": (
      property: keyof ReturnAccountingToDoDto,
      InventoryNotes: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          cols={NORMAL_COLS_SIZE}
          rows={(InventoryNotes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          // style={{ ...generateInputStyles(Notes + ''), ...{ maxHeight: '2px' } }}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          name={`${stock}:${property}`}
          defaultValue={InventoryNotes}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.AccountingTodo,
              e.target.value,
              property,
              stock,
              InventoryNotes,
              () => (e.target.value = InventoryNotes + "")
            );
          }}
        />
      </td>
    ),

    "Title Work Completed": (
      property: keyof ReturnAccountingToDoDto,
      TitleWorkCompleted: string,
      stock: string
    ) => (
      <td
        className={`${INPUT_CLASS_NAME} 
                            ${
                              isNullOrUndefined(TitleWorkCompleted) ||
                              TitleWorkCompleted.trim() === ""
                                ? styles.dateInputEmpty
                                : ""
                            }`}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          readOnly={true}
          disabled={true}
          type="date"
          className={`${INPUT_CLASS_NAME} ${
            TitleWorkCompleted === "" ? styles.accountingTodoEmptyDate : ""
          }`}
          name={`${stock}:${property}`}
          defaultValue={convertDefaultDate(TitleWorkCompleted)}
          // onChange={(e) => handleInputUpdateValueProvider(e, property, stock)}
        />
      </td>
    ),
    "Delivered Date": (
      property: keyof ReturnAccountingToDoDto,
      DeliveredDate: string,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          type="date"
          readOnly={true}
          disabled={true}
          className={(() => {
            return `${INPUT_CLASS_NAME} ${
              DeliveredDate === "" ? styles.accountingTodoEmptyDate : ""
            }`;
          })()}
          name={`${stock}:${property}`}
          defaultValue={(() => {
            const result = convertDefaultDate(DeliveredDate);
            return result;
          })()}
          // onChange={(e) => handleInputUpdateValueProvider(e, property, stock)}
        />
      </td>
    ),
    "Sales Person": (
      property: keyof ReturnAccountingToDoDto,
      SalesPerson: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          readOnly={true}
          cols={NORMAL_COLS_SIZE}
          rows={(SalesPerson + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          name={`${stock}:${property}`}
          defaultValue={SalesPerson}
        />
      </td>
    ),
    "Make": (
      property: keyof ReturnAccountingToDoDto,
      Make: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={Make + ""}>
          <Select
            isDisabled={true}
            options={
              makeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            // onChange={(e) => handleUpdateType(e?.value, property, DbManagedTypes.Make, stock)}
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            defaultValue={{
              label: Make + "",
              value: Make + "",
              title: Make + "",
            }}
          />
        </div>
      </td>
    ),
    "Inventory Type": (
      property: keyof ReturnAccountingToDoDto,
      Type: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {/* NEEDS SYNC SELECT */}
        <div className={styles.asyncSelectDiv} title={Type + ""}>
          <Select
            isDisabled={true}
            options={
              typeTypes
              // wheelTypes.filter((item) => item.label.includes(inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => (option as testtest).label}
            getOptionValue={(option) => (option as testtest).value}
            // onChange={(e) => handleUpdateType(e?.value, property, DbManagedTypes.InventoryType, stock)}
            filterOption={(option, inputValue) => {
              const { value } = option;

              const result = value
                .toLowerCase()
                .includes(inputValue.toLowerCase());
              return result;
              // return true;
            }}
            defaultValue={{
              label: Type + "",
              value: Type + "",
              title: Type + "",
            }}
          />
        </div>
      </td>
    ),
    "Inventory Condition": (
      property: keyof ReturnAccountingToDoDto,
      Condition: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          disabled={true}
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          defaultValue={Condition}
        >
          {Object.entries(InventoryCondition)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),

    "Split Deal - I": (
      property: keyof ReturnAccountingToDoDto,
      SplitDeal: string | boolean,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          readOnly={true}
          type="checkbox"
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          checked={convertDefaultCheckbox(SplitDeal)}
          onChange={() => {
            return;
          }}
        />
      </td>
    ),

    "Split Deal - S": (
      property: keyof ReturnAccountingToDoDto,
      SplitDealS: string | boolean,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          readOnly={true}
          type="checkbox"
          className={INPUT_CLASS_NAME}
          name={`${stock}:${property}`}
          checked={convertDefaultCheckbox(SplitDealS)}
          onChange={() => {
            return;
          }}
        />
      </td>
    ),

    "Complete": (
      property: keyof ReturnAccountingToDoDto,
      Complete: string | number,
      stock: string
    ) => (
      <td
        className={styles.tData}
        key={`${stock}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <IonButton onClick={() => complete(stock)}>Complete</IonButton>
      </td>
    ),
  };

  const runner = (dtoData: ReturnAccountingToDoDto): React.ReactElement[] => {
    if (isNullOrUndefined(computedCombinedFilters)) {
      return [<></>];
    }

    const updatedValues = computedCombinedFilters.orderedColumnsToShow
      .filter(
        (column) =>
          !isAccountingTodoPropertyExcluded(
            column as keyof ReturnAccountingToDoDto
          ) &&
          isNotNullNorUndefined(column) &&
          column !== ""
      )
      .filter(filterAccountingTodoColumnMethod)
      .map((column) => {
        let value = dtoData[column as keyof ReturnAccountingToDoDto];
        if (isNullOrUndefined(value)) {
          value = "";
        }
        const target =
          savedFunctionsToUpdateData[
            column as keyof typeof savedFunctionsToUpdateData
          ];

        if (typeof target !== "function" || isNullOrUndefined(target)) {
          console.error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
          throw new Error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
        }

        if (typeof value === "boolean") {
          // if boolean value converting to string
          value = value + "";
        }

        const result = target(
          column as keyof ReturnAccountingToDoDto,
          value as string,
          dtoData["Stock"]
        );
        return result;
      })
      .filter((column) => isNotNullNorUndefined(column));

    const withSelectionColumn = [
      <td
        className={`${
          selectingRowMode === "unselected" ? styles.selectionColumnHidden : ""
        } ${styles.tdSelectionColumn}`}
        key="selection-column"
        onClick={(e) => {
          if ((e.target as Element).tagName !== "TD") {
            return;
          }
          const tdElement = e.target as HTMLTableCellElement;

          const inputBelow = tdElement.childNodes[0] as HTMLInputElement;
          if (isRowIdSelected(dtoData.Stock)) {
            unselectRow(dtoData.Stock);
          } else {
            registerSelection(dtoData.Stock);
          }
          inputBelow.focus();
        }}
      >
        <input
          type="checkbox"
          style={{ transform: "scale(1.5)" }}
          checked={isRowIdSelected(dtoData.Stock)}
          onChange={(e) => {
            if (e.target.checked) {
              registerSelection(dtoData.Stock);
            } else {
              unselectRow(dtoData.Stock);
            }
          }}
        />
      </td>,
      ...updatedValues,
    ];
    return withSelectionColumn as JSX.Element[];
  };

  // const test = (dataDto: InventoryDto) => {
  //     if (isNullOrUndefined(savedGeneratedGeneralOptions.current)) {
  //         return;
  //     }
  //     const columnsToShowList = splitByComma(savedGeneratedGeneralOptions.current.columnsToShow);

  //     const tableDataList = columnsToShowList.map((column) => {
  //         return <td className={styles.tData} key={column}>{dataDto[column as keyof InventoryDto]}</td>;
  //     });

  //     return tableDataList;
  // };

  // const iteration = () => {
  //     if (isNullOrUndefined(savedGeneratedGeneralOptions.current)) {
  //         return;
  //     }

  //     for (let i = 0; i < savedGeneratedGeneralOptions.current.sortOptions.itemsPerPage ?? 50; i++) {
  //         const inventoryDto = data[i];
  //         test(inventoryDto);
  //     }
  // };

  useEffect(() => {
    const ionElement = document.getElementById(
      ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID
    );

    if (isNullOrUndefined(ionElement)) {
      console.error("ION ELEENT IS NULL RETURNING");
      return;
    }

    const handler = function () {
      const nonTableParts = Array.from(
        document.getElementsByClassName("nonTableParts")
      );
      if (isNullOrUndefined(nonTableParts) || !Array.isArray(nonTableParts)) {
        console.error("Non table part IS NULL! RETURNING");
        return;
      }
      nonTableParts.forEach((part) => {
        (part as HTMLDivElement).style.left = ionElement.scrollLeft + "px";
      });
    };

    ionElement.addEventListener("scroll", () => {
      window.requestAnimationFrame(handler);
    });

    // const ionSplitRouter = document.getElementById(ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID);

    // const targetDiv = ionSplitRouter;
    // if (isNullOrUndefined(targetDiv)) {
    //     return;
    // }

    // const leftOffset = parseInt($('#non-table-part-of-page').css('left'));

    // const handler = (e: Event) => {
    //     console.log('HANDLING SCROLL EVENT');
    //     //@ts-ignore

    //     const scrollLeft = ionSplitRouter?.scrollLeft ?? 0;
    //     // const scrollLeft = $(this).scrollLeft();
    //     $('#non-table-part-of-page').css({
    //         left: scrollLeft + leftOffset,
    //     });
    // };

    // console.log('REGISTEREING LISTENER');

    // targetDiv.addEventListener('scroll', handler);

    // return () => {
    //     console.log('DEREGISTERING LISTENER');
    //     targetDiv.removeEventListener('scroll', handler);
    // };
  }, []);

  const stringifyNumberOption = 4;

  const handleCopyFilterDebugInfoToClipboard = () => {
    let stringToShow = "";

    const dictOfThingsToPrint = {
      "General Options": JSON.stringify(
        computedCombinedFilters,
        null,
        stringifyNumberOption
      ),
      "Global Filter Storage": JSON.stringify(
        globalFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "User Filter Storage": JSON.stringify(
        userFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "Selected Global Filter Names": JSON.stringify(
        selectedGlobalFilterNames,
        null,
        stringifyNumberOption
      ),
      "Selected  User Filter Names": JSON.stringify(
        selectedUserFilterNames,
        null,
        stringifyNumberOption
      ),
      "Computed Selected Global Filters": JSON.stringify(
        computedSelectedGlobalFilters,
        null,
        stringifyNumberOption
      ),
      "Computed Selected  User Filters": JSON.stringify(
        computedSelectedUserFilters,
        null,
        stringifyNumberOption
      ),
      "Local Stroage": JSON.stringify(
        { ...localStorage },
        null,
        stringifyNumberOption
      ),
    };

    Object.entries(dictOfThingsToPrint).forEach(([key, value]) => {
      stringToShow += "\n";
      stringToShow += `~~~~${key}~~~~\n`;
      stringToShow += value;
    });

    window.navigator.clipboard.writeText(stringToShow);
  };

  const nonTablePart = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // calls the endpoint initially
    // handleEndpointCall({
    //     endpoint: endpoint,
    //     generalOptions: generalOptions,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedOnce = useRef<boolean>(false);

  const convertLongDateToShortDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const printProcessor = (inventoryDto: ReturnAccountingToDoDto) => {
    const updatedObj: any = { ...inventoryDto };

    Object.entries(inventoryDto).forEach(([property, value]) => {
      const dataType = getDataTypeFromAccountingTodoPropertyName(
        property as keyof ReturnAccountingToDoDto
      );

      switch (dataType) {
        case DataType.DateTime: {
          updatedObj[property as keyof ReturnAccountingToDoDto] =
            convertLongDateToShortDate(value);
          break;
        }
        case DataType.Enum: {
          const targetEnum = getEnumFromAccountingTodoDtoPropertyName(
            property as keyof ReturnAccountingToDoDto
          );
          if (isNullOrUndefined(targetEnum)) {
            console.error(
              "Target Enum was not found. Please add definition in getEnumFromInventoryDtoPropertyName"
            );
            return;
          }

          updatedObj[property as keyof ReturnAccountingToDoDto] =
            targetEnum[value];
        }
      }
    });
    return updatedObj as ReturnAccountingToDoDto;
  };

  return (
    <div
      className={styles.AccountingTodoTableContainer}
      data-testid="AccountingTodoTable"
    >
      <Layout className={`${styles.nonTablePart} nonTableParts`}>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          // id="non-table-part-of-page"
          id="header"
          ref={nonTablePart}
        >
          {tabbed && (
            <QueriedTableTabList
              handleGlobalFilterButtonClick={handleGlobalFilterButtonClick}
              isFilterSelected={isFilterSelected}
              computedCombinedFilters={computedCombinedFilters}
              handleUserFilterButtonClick={handleUserFilterButtonClick}
              userFiltersStorage={userFiltersStorage}
              globalFiltersStorage={globalFiltersStorage}
            />
          )}
          <QueriedTableToolbar<ReturnAccountingToDoDto>
            endpoint={endpoint}
            handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
            handleUserFilterButtonClick={handleUserFilterButtonClick}
            model={model}
            useCustomEndpoint={useCustomEndpoint}
            setUseCustomEndpoint={setUseCustomEndpoint}
            recordType={recordType}
            isFilterSelected={isFilterSelected}
            handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
            handleDeleteGlobalFilter={handleDeleteGlobalFilter}
            handleDeleteUserFilter={handleDeleteUserFilter}
            setOverwritingProvidedData={setOverwritingProvidedData}
            dtoType={dtoType}
            handleClear={handleClearCancel}
            data={data as ReturnAccountingToDoDto[]}
            computedCombinedFilters={computedCombinedFilters}
            handleUpdatingItemsPerPage={handleUpdatingItemsPerPage}
            setSortFilters={setSortFilters}
            handleSetCustomFiltersValueFilters={
              handleSetCustomFiltersValueFilters
            }
            unhidableColumns={["Invoice Number"]}
            overridingColumnOrderOnReset={
              "Select, Invoice Number, Stock, Customer Number, Customer Name, Sales Order Notes, Title Work Completed, Delivered Date, Sales Person, Make, Inventory Type, Inventory Condition, Split Deal, Complete"
            }
            setColumnVisibility={setColumnVisibility}
            fieldsThatAreCustomTypes={{}}
            exportEnumProcessor={(dto) => dto}
            enumProperties={[]}
            printProcessor={printProcessor}
            getAsyncSelectGetMethodFromPropertyName={() => undefined}
            filterKeyArrayByExcludedColumns={
              filterAccountingTodoKeyArrayByExcludedColumns
            }
            selectedUserFilterNames={selectedUserFilterNames}
            selectedGlobalFilterNames={selectedGlobalFilterNames}
            computedSelectedGlobalFilters={computedSelectedGlobalFilters}
            computedSelectedUserFilters={computedSelectedUserFilters}
            customFilters={customFilters}
            unselectGlobalFilter={unselectGlobalFilter}
            unselectUserFilter={unselectUserFilter}
            ignoreFilter={ignoreFilter}
            unignoreFilter={unignoreFilter}
            isFilterIgnored={isFilterIgnored}
            combinedFilterTargetProperties={combinedFilterTargetProperties}
            updateSearchTerm={updateSearchTerm}
            globalFilterStorage={globalFiltersStorage}
            userFilterStorage={userFiltersStorage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <IonButton onClick={handleCompleteAllSelected}>
            Complete All Selected
          </IonButton>
        </div>

        <div
          className={`${styles.itemsPerPageAndCopyTableContainer} ${styles.nonTablePart} nonTableParts`}
        >
          <div className={`${styles.totalItemsSpan} `}>
            <span>
              Total Items: {computedCombinedFilters.sortOptions.totalItems}
            </span>
          </div>
          <CopyTableStylesButtons<ReturnAccountingToDoDto>
            selectingRowMode={selectingRowMode}
            unselectAllRows={unselectAllRows}
            selectAllRows={selectAllRows}
            cancelSelecting={cancelSelecting}
            stopSelectingAndCopyTableWithStyles={
              stopSelectingAndCopyTableWithStyles
            }
            stopSelectingAndEmailAdvertisements={
              stopSelectingAndEmailAdvertisements
            }
            startSelecting={startSelecting}
            data={data as ReturnAccountingToDoDto[]}
            identifierProperty={"Stock"}
            inventoryPage={false}
          />
        </div>

        <div ref={tableDivContainerRef} style={{ marginTop: "1rem" }}>
          <table className={styles.table}>{handleGenerateTableParts()}</table>
        </div>

        <div
          className={`${styles.nonTablePart} nonTableParts`}
          style={{ marginBottom: "1rem" }}
        >
          <QueriedTablePaginationOptions
            computedCombinedFilters={computedCombinedFilters}
            updateCurrentPageNumber={updateCurrentPageNumber}
            setIsLoading={setIsLoading}
          />
        </div>
        {/*
                <Card
                    endpoint={() => MetricService.getMetrics(RecordType.SalesOrder)}
                    icon={CardIconTypes.Square}
                    hideOnTabletSize={true}
                />

                */}
      </Layout>
    </div>
  );
};

export default AccountingTodoTable;
