import { DataType } from '../../models/Global';

export const convertToNumberFromString = (value: string) => {
    const converted = +value;

    if (Number.isNaN(converted)) {
        const errorMessage = 'Attempting to convert string to number but is not able to. Value: ' + value;
        alert(errorMessage);
        throw new Error(errorMessage);
    }

    return converted;
};

export const convertToBooleanFromString = (value: string) => {
    const lowerCaseVersion = value.toLowerCase();

    if (lowerCaseVersion === 'true') {
        return true;
    }

    if (lowerCaseVersion === 'false') {
        return false;
    }

    const errorMessage = 'Attempting to convert string to boolean but is not able to. Value: ' + value;
    alert(errorMessage);
    throw new Error(errorMessage);
};

export const convertFromStringIfBooleanOrNumber = <T>(value: string, dataType: DataType) => {
    if (dataType === DataType.Int) {
        return convertToNumberFromString(value);
    }

    if (dataType === DataType.Bool) {
        return convertToBooleanFromString(value);
    }

    return value;
};
