import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { cameraOutline, chevronBackOutline, createOutline, eyeOutline } from 'ionicons/icons';
import ReactToPrint from 'react-to-print';

import { format } from 'date-fns';
import { menu } from 'ionicons/icons';
import cloneDeep from 'lodash.clonedeep';
import React, { createRef, FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { GroupBase, SingleValue, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { utils } from 'xlsx';
import AuditLogContainer from '../../components/AuditLogContainer/AuditLogContainer';
import ShowDeliveryPdf from '../../components/PdfUtils/ShowDeliveryPdf';
import PhotoGallery from '../../components/PhotoGallery/PhotoGallery';
import {
  canUserAccessAccountingSecurityWithGivenRoles,
  canUserAccessAdminSecurityWithGivenRoles,
  RoleContext,
} from '../../components/RoleContext/RoleContextComp';
import { SearchForCustomer, SearchForEmployee, searchForStocksForDelivery } from '../../components/Search';
import { SearchForStocksOption } from '../../components/Search/SearchStockByNumberForDelivery';
import SimpleAccordion from '../../components/SimpleAccordion/SimpleAccordion';
import {
  ID_PARAMETER_NAME,
  ID_TYPE_PARAMETER_NAME,
  UseParamsObject,
} from '../../components/Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer';
import { LocationType } from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { useObject } from '../../hooks/ObjectHook';
import useCamera from '../../hooks/useCamera';
import { StringDto } from '../../models/Dtos/GeneralDtos/StringDto';
import { UrlPhotoIdWrapper } from '../../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
import { DeliveryDto } from '../../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { AccountingStatus, RecordType, SalesOrderStatus } from '../../models/Global';
import { CustomerService, DeliveryService, SalesOrderService } from '../../utils/apiServices';
import { filterSalesOrderKeyArrayByExcludedColumns } from '../../utils/ColumnsToShowFilterByDtoType/SalesOrderColumnsToShowFilter';
import { handleUploadPhoto } from '../../utils/handleUploadPhoto';
import { IdentifierType } from '../../utils/identifierType';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { minMax } from '../../utils/MinMaxUtil';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import { createWorkbook, writeExcelFile } from '../../utils/SpreadsheetUtils/export';
import { RemoveMethods } from '../../utils/TypeUtils';
import { Layout } from '../Layout';
import SalesOrderCondensedView from '../SalesOrderCondensedView/SalesOrderCondensedView';
import { Delivery } from './components/Delivery';
import InputAppraoch from './components/InputApproach';
import './SalesOrders.module.css';
import styles from './SalesOrdersQueriedTable.module.scss';
import PageNameGenerator from '../../utils/PageNameGenerator';

// https://stackoverflow.com/questions/57649904/how-to-use-typescript-with-react-router-and-match#answer-57649994
interface RouterProps {
  // type for `match.params`
  invoiceNumber?: string; // must be type `string` since value comes from the URL
}

type SalesOrderProps = RouteComponentProps<RouterProps>;

export const SalesOrderPage: React.FC<SalesOrderProps> = ({ match }) => {
  const { [ID_PARAMETER_NAME]: identifierNumber, [ID_TYPE_PARAMETER_NAME]: identifierType } =
    useParams<UseParamsObject>();

  const history = useHistory();

  const roles = useContext(RoleContext);

  // const allFieldsReadOnly = !canUserAccessAccountingSecurityWithGivenRoles(roles);
  const allFieldsReadOnly = false;

  const salesOrderCondensedViewPrintComponent = useRef(null);

  const locationObj = useLocation<LocationType | undefined>();

  const [isEditing, setIsEditing] = useState<boolean>(identifierNumber == undefined);

  const initializeDto = useCallback(
    (salesOrderDto: SalesOrderDto) => {
      if (locationObj.pathname.toLowerCase().includes('create')) {
        // Creating, want to default value of ordered date to be todays date.A

        const clonedDto = cloneDeep(salesOrderDto);

        const now = new Date(new Date().toDateString());

        const localDateString = now.toLocaleDateString();

        const [date, time] = localDateString.split('T');

        const [month, day, year] = date.split('/');

        const t = `${year}-${('00' + month).slice(-2)}-${day}T00:00:00`;
        clonedDto['Ordered Date'] = t;
        return clonedDto;
      }
      return salesOrderDto;
    },
    [locationObj.pathname]
  );

  const {
    value: salesOrder,
    setValue: setSalesOrder,
    bindIonSelectProperty: bindIonSalesOrderSelect,
    bindIonInputProperty: bindIonSalesOrder,
    bindReactAsyncSelect: bindSalesOrderReactAsyncSelect,
    // bindIonInputPropertyDecimals: bindIonSalesOrderDecimal,
    bindIonPropertyDate: bindIonSalesOrderDate,
    bindIonPropertyCheck: bindIonSalesOrderCheck,
    bindIonPropertyCurrency: bindIonSalesOrderCurrency,
    updateProperty: updateSalesOrderProperty,
  } = useObject<RemoveMethods<SalesOrderDto>>(initializeDto(new SalesOrderDto()));

  const [deliveryPdfToggle, setDeliveryPdfToggle] = useState<boolean>(false);

  const { getInventoryContainerPhotoUrlsWithSasTokens } = useCamera(RecordType.Delivery);

  const SALES_ORDER_PAGE_TITLE = PageNameGenerator('Sales Order Detail');

  useEffect(() => {
    if (document.title !== SALES_ORDER_PAGE_TITLE) document.title = SALES_ORDER_PAGE_TITLE;
  }, []);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [customerIdentifier, setCustomerIdentifier] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();

  const [ignoreNextCall, setIgnoreNextCall] = useState<boolean>(false);

  const [usingEnteringStockNumberAppraoch, setUsingEnteringStockNumberAppraoch] = useState<boolean>(false);

  const isUpdating = locationObj.pathname.toLowerCase().includes('edit');

  useEffect(() => {
    if (ignoreNextCall) {
      setIgnoreNextCall(false);
      return;
    }
    if (salesOrder['Customer Name'] == 'Create Customer') {
      setShowModal(true);
      updateSalesOrderProperty('Customer Name')('');
      setIgnoreNextCall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreNextCall, salesOrder['Customer Name'], updateSalesOrderProperty]);

  const [photoUrlsInStockDictionary, setPhotoUrlsInStockDictionary] = useState<{
    [stockNumber: string]: UrlPhotoIdWrapper[];
  }>({});
  const handleUpdatingPhotosFromPhotoGallery = (photos: UrlPhotoIdWrapper[], stock: string) => {
    setPhotoUrlsInStockDictionary((oldPhotoDict) => {
      const clone = cloneDeep(oldPhotoDict);
      clone[stock] = photos;
      return clone;
    });
  };

  // Gathers Photos
  const [shouldGatherPhotos, setShouldGatherPhotos] = useState<boolean>(true);

  const handleGatheringPhotos = () => {
    if (salesOrder == undefined || salesOrder.Deliveries == undefined || salesOrder.Deliveries.length === 0) {
      return;
    }
    setShouldGatherPhotos(false);

    const stockNumbers = salesOrder.Deliveries.map((delivery) => delivery.Stock);

    getInventoryContainerPhotoUrlsWithSasTokens(stockNumbers)
      .then((photosWithUrlsInsideStockDictionary) => {
        setPhotoUrlsInStockDictionary(photosWithUrlsInsideStockDictionary);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const changeToEdit = () => {
    setIsEditing(true);
  };

  const changeToView = () => {
    setIsEditing(false);
  };

  if (shouldGatherPhotos) {
    handleGatheringPhotos();
  }

  const formRef = useRef<HTMLFormElement>(null);
  const accordionGroup = useRef<HTMLIonAccordionGroupElement>(null);

  const [focusToggle, setFocusToggle] = useState<string | null>(null);

  const [showAddTrailer, setShowAddTrailer] = useState<boolean>(false);

  const [passedSelectedStockNumberUE, setPassedSelectedStockNumberUE] = useState<boolean>();

  const [addedDeliveries, setAddedDeliveries] = useState<string[]>([]);

  const [accordionGroupValue, setAccordionGroupValue] = useState<string | undefined>(undefined);

  const [pdfTargetedRecord, setPdfTargetedRecord] = useState<DeliveryDto>();

  const targetDiv = useRef(null);

  const [createdNewROS, setCreatedNewRos] = useState<boolean>(false);

  const zIndexRegistry = 999; // This will get reset on each render.

  const uploadPhotosRef = useRef<HTMLInputElement | null>(null);

  /* To Do List:
  handle Response DTO - Shawn
  Set required tags - Should be completed
  Set validation messages - Should be completed
  Test get, create, update
  Set edit permissions based off logged in user role using disabled={}
  CSS - Shawn
  Finish Stock Number logic - Notes in UseEffect below
  */

  const removingUrlFromState = useCallback(
    (url: string) => {
      const clone = cloneDeep(photoUrlsInStockDictionary);

      Object.keys(photoUrlsInStockDictionary).forEach((stock) => {
        const urlPhotoIdWrappers = photoUrlsInStockDictionary[stock];

        const matchingUrlIndex = urlPhotoIdWrappers.findIndex(
          (wrapper) => wrapper.url.toLowerCase() === url.toLowerCase()
        );

        if (matchingUrlIndex >= 0) {
          const toBeSpliced = cloneDeep(urlPhotoIdWrappers);
          toBeSpliced.splice(matchingUrlIndex, 1);

          clone[stock] = toBeSpliced;
        } else {
          clone[stock] = urlPhotoIdWrappers;
        }
      });

      setPhotoUrlsInStockDictionary(clone);
    },
    [photoUrlsInStockDictionary]
  );

  const handleModalClick = async () => {
    if (customerName == undefined) {
      alert('You must imput a customer name first!');
      return;
    }

    if (customerIdentifier == undefined) {
      alert('You must input a customer identifier first!');
      return;
    }

    const response = await CustomerService.CreateCustomer({
      id: 0,
      name: customerName,
      identifier: customerIdentifier,
    });

    const data = response.data;

    setSalesOrder((oldROS) => ({
      ...oldROS,
      'Customer #': data.model.identifier,
      'Customer Name': data.model.name,
    }));

    // For some reason doing two update properties the second one uses
    // Stale information

    // updateSalesOrderProperty('Customer #')(data.model.identifier);
    // updateSalesOrderProperty('Customer Name')(data.model.name);

    setShowModal(false);
  };

  useEffect(() => {
    if (identifierType == IdentifierType.Stock && identifierNumber != undefined) {
      // document.getElementById(identifierNumber + '')?.scrollIntoView(true);
    }
  });

  const updateDelivery = (delivery: DeliveryDto) => {
    if (delivery == undefined) {
      throw new Error('Delivery is undefined on attempt update recore of delivery!');
    }
    const stock = delivery.Stock;

    // const updatedArray = currentArray.map((record) => {
    //     if (record.Stock != stock) {
    //         return record;
    //     }
    //     return delivery;
    // });

    setSalesOrder((ros) => {
      const currentArray = ros['Deliveries'];
      const copy = { ...ros };

      const copyOfROD = ros['Deliveries'];

      let updatedAray: DeliveryDto[];

      if (currentArray.find((rod) => rod.Stock == delivery.Stock)) {
        updatedAray = copyOfROD.map((rod) => {
          if (rod.Stock === delivery.Stock) {
            return delivery;
          }
          return rod;
        });
      } else {
        updatedAray = [...copyOfROD, delivery];
      }

      copy['Deliveries'] = updatedAray;

      return copy;
    });
  };

  // useEffect(() => {
  //     if (!passedSelectedStockNumberUE) {
  //         setPassedSelectedStockNumberUE(true);
  //         return;
  //     }
  //     // verify it is only sending back whole stock numbers
  //     // Then call database to fetch inventory and add another DeliveryDto --- may not need to call backend, ignore until after testing.
  //     if (selectedStockNumberOption != undefined) {
  //         const previousDeliveries = salesOrder['Deliveries'];

  //         const newDelivery = new DeliveryDto();
  //         newDelivery.Stock = +selectedStockNumberOption.value;

  //         updateSalesOrderProperty('Deliveries')([...previousDeliveries, newDelivery]);
  //         setShowAddTrailer(false);
  //     } else {
  //         alert('Unhandled Error in UseEffect for SelectedStockNumber: ' + { selectedStockNumberOption });
  //     }

  //     // Need to also setShowAddTrailer(false);
  // }, [selectedStockNumberOption]);

  const addDelivery = (delivery: DeliveryDto) => {
    const result = addedDeliveries.find((recordStockNumber) => recordStockNumber == delivery.Stock + '');

    if (result != undefined) {
      alert('Cannot use a stock number that has already been previously added!');
      return;
    }

    const previousDeliveries = salesOrder['Deliveries'];

    setSalesOrder((ros) => {
      const copy = { ...ros };

      const copyOfROD = ros['Deliveries'];

      let updatedAray: DeliveryDto[];

      if (previousDeliveries.find((rod) => rod.Stock == delivery.Stock)) {
        updatedAray = copyOfROD.map((rod) => {
          if (rod.Stock === delivery.Stock) {
            return delivery;
          }

          return rod;
        });
      } else {
        updatedAray = [...copyOfROD, delivery];
      }

      copy['Deliveries'] = updatedAray;

      return copy;
    });

    setAddedDeliveries((addedROD) => {
      return [...addedROD, delivery.Stock + ''];
    });

    setShowAddTrailer(false);
  };

  const handleNewDeliveryLogic = (record: RemoveMethods<SalesOrderDto>) => {
    const updatedRecord = { ...record };
    updatedRecord['All Delivered'] = false;
    updatedRecord['Misc Tracking'] = '';
    updatedRecord['Proof Of Payment Received'] = null;
    updatedRecord['Title Work Completed'] = null;
    updatedRecord.Status = SalesOrderStatus.Active;

    return updatedRecord;
  };

  const handleAccountingLogic = (record: RemoveMethods<SalesOrderDto>) => {
    const updatedRecord = { ...record } as RemoveMethods<SalesOrderDto>;

    if (record['Title Work Completed'] != null) {
      updatedRecord['Deliveries'] = updatedRecord['Deliveries'].map((delivery) => {
        if (delivery['Accounting Status'] == AccountingStatus.Completed) {
          return delivery;
        }

        return { ...delivery, 'Accounting Status': AccountingStatus.ToDo } as DeliveryDto;
      });
    } else {
      updatedRecord['Deliveries'] = updatedRecord['Deliveries'].map((delivery) => {
        if (delivery['Delivered Date'] == null || delivery['Accounting Status'] == AccountingStatus.Completed) {
          return delivery;
        }
        return { ...delivery, 'Accounting Status': AccountingStatus.ToDo } as DeliveryDto;
      });
    }
    return updatedRecord;
  };

  const handleAllInventoryDelivered = async (record: RemoveMethods<SalesOrderDto>) => {
    const response = await DeliveryService.DeliverAll(record['Invoice Number']);

    if (response.data.isSuccessful) {
      setSalesOrder(response.data.model);
    }
  };

  const [passedUE, setPassedUE] = useState<boolean>(false);
  const [ignoreNextOne, setIgnoreNextOne] = useState<boolean>(false);

  // useEffect(() => {
  //     if (!passedUE) {
  //         setPassedUE(true);
  //         return;
  //     }

  //     if (ignoreNextOne) {
  //         setIgnoreNextOne(false);
  //         return;
  //     }

  //     setSalesOrder((oldRos) => {
  //         let toReturn = handleAccountingLogic(oldRos);

  //         toReturn = handleAllInventoryDelivered(toReturn);

  //         return toReturn;
  //     });
  //     setIgnoreNextOne(true);
  // }, [salesOrder]);
  type InvoiceNumberErrorReasons = 'AlreadyExists' | 'NoValue';
  const [invoiceNumberValidationObject, setinvoiceNumberValidationObject] = useState<{
    valid: boolean;
    errorReason?: InvoiceNumberErrorReasons;
  }>({
    valid: true,
  });

  const handleChange = async (selectedOption: SingleValue<SearchForStocksOption>) => {
    // verify it is only sending back whole stock numbers
    // Then call database to fetch inventory and add another DeliveryDto --- may not need to call backend, ignore until after testing.
    if (selectedOption != undefined) {
      const result = addedDeliveries.find(
        (recordStockNumber) => recordStockNumber + '' === selectedOption?.label
      );

      if (result != undefined) {
        alert(
          `Cannot use a stock number that has already been previously added! Stock number ${selectedOption.label} already has a delivery`
        );
        return;
      }

      const newDelivery = new DeliveryDto();
      newDelivery.Stock = selectedOption.value;

      // updateSalesOrderProperty('Deliveries')([...previousDeliveries, newDelivery]);

      setSalesOrder((oldROS) => {
        const previousDeliveries = oldROS['Deliveries'];
        const updatedRODs = previousDeliveries == undefined ? [] : [...previousDeliveries, newDelivery];

        const updatedROS = { ...oldROS };
        updatedROS['Deliveries'] = updatedRODs;

        //Unsure if this can be improved upon
        //Extra updating with basically the same information.
        // If there's a way to update based off of the endpoint response
        // Rather than returning updatedROS, that'd be great.

        if (isUpdating) {
          // Calls the update one
          SalesOrderService.updateSalesOrder(updatedROS).then((response) => {
            setSalesOrder(response.data.model);
          });
        } else {
          // Calls the Create one
          updatedROS['Stock Numbers'] = updatedROS.Deliveries.map((delivery) => delivery.Stock);
          SalesOrderService.createSalesOrder(updatedROS).then((response) => {
            // setSalesOrder(response.data.model);
            // setCreatedNewRos(true);
            history.push(`/sales-order/edit/${IdentifierType.Invoice}/${salesOrder['Invoice Number']}`);
          });
        }

        return handleNewDeliveryLogic(updatedROS);
      });
    } else {
      alert('Unhandled Error in UseEffect for SelectedStockNumber: ' + { selectedOption });
    }
  };

  const handleMessagePlaceholder = () => {
    // alert('test');
  };

  const getRecord = useCallback(() => {
    if (identifierNumber == undefined) {
      return;
    }

    if (identifierType == IdentifierType.Invoice) {
      SalesOrderService.getSalesOrderDetails(identifierNumber)
        .then(({ data }) => {
          if (data.isSuccessful) {
            setSalesOrder(new SalesOrderDto(data.model));
          } else handleMessagePlaceholder();
        })
        .catch((err) => console.error(err));
    } else if (identifierType == IdentifierType.Stock) {
      SalesOrderService.getByStockNumber(identifierNumber + '')
        .then(({ data }) => {
          if (data.isSuccessful) {
            setSalesOrder(new SalesOrderDto(data.model));
          } else handleMessagePlaceholder();
        })
        .catch((err) => console.error(err));
    }
  }, [identifierNumber, identifierType, setSalesOrder]);

  useEffect(() => {
    // getting Sales Order if edit/view mode
    if (isUpdating) {
      getRecord();
    }
  }, [getRecord, isUpdating]);

  useEffect(() => {
    getRecord();
  }, [identifierNumber, identifierType, getRecord]);

  const [booleanToggleToIndicateToUpdateAuditLog, setBooleanToggleToIndicateToUpdateAuditLog] =
    useState<boolean>(false);

  const updateRecord = useCallback(
    async (modifiedSalesOrder: SalesOrderDto, pressingCtrl: boolean, dontRedirect = false) => {
      // Making it any because it's a modified version of Sales Order and Idk how to make this currently in typescript

      try {
        const data = (await SalesOrderService.updateSalesOrder(modifiedSalesOrder)).data;

        if (data.isSuccessful) {
          if (dontRedirect) {
            setSalesOrder(data.model);
            return data;
          }

          if (!pressingCtrl) {
            // Not pressing ctrl

            const pathname = '/sales-order';

            const state = {};

            history.push(pathname, state);
            return data;
          }

          // Pressing Ctrl

          setSalesOrder(data.model);

          setIsEditing(false);
        }

        return data;
      } catch (err: any) {
        console.error(err);
      }
    },
    [history, setSalesOrder]
  );

  const createRecord = useCallback(
    async (modifiedSalesOrder: any, pressingCtrl: boolean, dontRedirect = false) => {
      try {
        const data = (await SalesOrderService.createSalesOrder(modifiedSalesOrder)).data;
        if (data.isSuccessful) {
          if (dontRedirect) {
            setSalesOrder(data.model);
            return data;
          }

          if (!pressingCtrl) {
            // Not pressing Ctrl

            const pathname = '/sales-order';

            const state = {};

            history.push(pathname, state);
            return data;
          }

          // Pressing Ctrl

          setSalesOrder(data.model);

          setIsEditing(false);
        }
        return data;
      } catch (err: any) {
        console.error(err);
      }
    },
    [history, setSalesOrder]
  );

  // const convertOptionTypesToStrings = () => {
  //     const duplicateSalesOrder = { ...salesOrder };

  //     const salesPerson = duplicateSalesOrder['Sales Person'];
  //     if (salesPerson != undefined && salesPerson != '') {
  //         duplicateSalesOrder['Sales Person'] = (salesPerson as unknown as OptionsType).label;
  //     }

  //     const customerName = duplicateSalesOrder['Customer Name'];
  //     if (customerName != undefined && customerName != '') {
  //         duplicateSalesOrder['Customer Name'] = (customerName as unknown as OptionsType).label;
  //     }

  //     return duplicateSalesOrder;

  //     // We can do direct modification here because we only need this to be
  // };

  const handleSubmit = useCallback(
    async (e: FormEvent | undefined, fromExportPdf = false) => {
      const pressingCtrl = keyDownState.current?.['Control' as string] ?? false;
      if (isNotNullNorUndefined(e)) e.preventDefault();

      // const modifiedSalesOrder = convertOptionTypesToStrings();

      if (!formRef.current?.checkValidity()) {
        formRef.current?.setAttribute('class', 'was-validated');
      } else {
        salesOrder['Stock Numbers'] = salesOrder['Deliveries'].map((delivery) => delivery.Stock);

        if (salesOrder['Stock Numbers'].length < 1 && !usingEnteringStockNumberAppraoch) {
          alert('Cannot create Sales Order without at least 1 Stock Number');
          return;
        }

        const { valid, errorReason } = invoiceNumberValidationObject;

        if (!valid) {
          if (errorReason === 'AlreadyExists') {
            alert(
              `Sales Order with invoice number ${salesOrder['Invoice Number']} already exists! Please choose a different one!`
            );
            return;
          }
          alert(`Please provide a valid number for Invoice Number!`);
        }

        if (createdNewROS || identifierNumber != undefined) {
          return await updateRecord(salesOrder, pressingCtrl, fromExportPdf);
        } else {
          return await createRecord(salesOrder, pressingCtrl, fromExportPdf);
        }
      }
    },
    [
      createRecord,
      createdNewROS,
      identifierNumber,
      invoiceNumberValidationObject,
      salesOrder,
      updateRecord,
      usingEnteringStockNumberAppraoch,
    ]
  );

  // Stocks: string; ***************************** Mopre work needed
  // NAPP: string; ********* Review

  // const handleSubmission = async () => {
  //     salesOrder['Sales Person'] = (salesOrder['Sales Person'] as unknown as OptionsType).label;
  //     salesOrder['Customer Name'] = (salesOrder['Customer Name'] as unknown as OptionsType).label;

  //     const response = await SalesOrderService.updateSalesOrder(salesOrder);

  //     const data = response.data;
  //     // const model = data.model;
  // };

  const handleDebug = () => {
    if (salesOrder == undefined) return <></>;

    const entries = Object.entries(salesOrder);

    const finalArray = entries.map(([key, value]) => {
      // Removes methods from the keys
      if (value instanceof Function || Array.isArray(value)) {
        return false;
      }

      return (
        <div key={key}>
          <h1>{key}</h1>
          <h2>{value}</h2>
        </div>
      );
    });

    return finalArray;
  };

  const updateDeliveries = (delivery: DeliveryDto[]) => {
    setSalesOrder((oldState) => {
      return { ...oldState, Deliveries: delivery };
    });
  };

  const handleDeactivateRecord = async () => {
    const invoiceNumber = salesOrder['Invoice Number'];
    const theyReallyWantToDelete = await window.confirm('Are you sure you want to deactivate this?');
    if (!theyReallyWantToDelete) {
      return;
    }

    try {
      const response = await SalesOrderService.deactivate(invoiceNumber);
      if (response.data.isSuccessful) {
        history.push(`/sales-order`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const records = salesOrder['Deliveries'].sort((a, b) => +b.Stock - +a.Stock);

  const buttons = records.map((record) => {
    return createRef<HTMLIonIconElement>();
  });

  type MapFromIconReferenceToDeliveryRecord = {
    [stock: string]: DeliveryDto;
  };

  const [currentlySelectedDeliveryRecordStockNumber, setCurrentlySelectedDeliveryRecordStockNumber] = useState<
    string | null
  >(null);

  const nativeEvent = useRef<MouseEvent | null>(null);

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    if (isNullOrUndefined(buttons) || !Array.isArray(buttons) || buttons.length <= 0) {
      return;
    }

    const buttonsVariable = buttons.map((button) => button.current);

    // name={`${record.Stock}-buttons-icon`}

    const handleOnClick = (event: MouseEvent) => {
      const icon = event.target as HTMLIonIconElement;
      const id = icon.id;
      if (isNullOrUndefined(id)) {
        console.error('Id is null/undef while attempting to get reference for delivery on button icon click');
        return;
      }
      nativeEvent.current = event;
      const deliveryStock = id.split('-')[0];
      setCurrentlySelectedDeliveryRecordStockNumber(deliveryStock);
      // event.stopPropagation();
      return;
    };
    buttons.forEach((buttonRef) => {
      if (isNullOrUndefined(buttonRef.current)) {
        console.error('button ref is null while iterating through! Please check!');
        return;
      }
      buttonRef.current.addEventListener('click', handleOnClick);
    });
    return () => {
      if (isNullOrUndefined(buttonsVariable) || !Array.isArray(buttonsVariable) || buttonsVariable.length <= 0) {
        return;
      }
      buttonsVariable.forEach((buttonRef) => {
        if (isNullOrUndefined(buttonRef)) {
          console.error('button ref is null while iterating through! Please check!');
          return;
        }
        buttonRef.removeEventListener('click', handleOnClick);
      });
    };
  }, [buttons, isEditing]);

  const deleteDelivery = async (id: string, stock: string) => {
    const reallyWantToDelete = window.confirm(`Do you really want to delete delivery with stock ${stock}`);
    if (!reallyWantToDelete) {
      return;
    }
    const response = await DeliveryService.removeDelivery(id);

    if (response.data.isSuccessful) {
      setSalesOrder((oldSalesOrder) => ({
        ...oldSalesOrder,
        Deliveries: oldSalesOrder.Deliveries.filter(
          (delivery) => delivery.Stock.toLowerCase() !== stock.toLowerCase()
        ),
      }));
    }
    // getRecord();
  };

  const handleExportSalesOrderSpreadsheet = () => {
    const salesOrderWithoutDelivery = { ...salesOrder } as Partial<SalesOrderDto>;
    delete salesOrderWithoutDelivery['Deliveries'];

    const workBook = createWorkbook([salesOrderWithoutDelivery], 'Sales Order');
    const deliveriesWorkSheet = utils.json_to_sheet(salesOrder['Deliveries']);
    utils.book_append_sheet(workBook, deliveriesWorkSheet, 'Deliveries');
    writeExcelFile(workBook, salesOrder['Invoice Number'] + '');
  };

  // const customerNamesWrapper = (name: string) => {
  //     const promise: ReturnType = new Promise(async (resolve, reject) => {
  //         SearchForCustomer(name)
  //             .then((response) => {
  //                 const salesOrderCustomerName = salesOrder['Customer Name'];
  //                 if (salesOrderCustomerName) {
  //                     const newOption = { label: salesOrderCustomerName, value: salesOrderCustomerName };
  //                     //@ts-ignore
  //                     resolve([...response, newOption]);
  //                 }
  //             })
  //             .catch((e) => {
  //                 reject(`There was an error while attempting to get custgomer name: ${e}`);
  //             });
  //     });
  //     return promise;
  // };

  const asyncSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
    control: (styles, state) => ({
      ...styles,

      backgroundColor: '#F2F2F2',
      '&:hover': {
        backgroundColor: '#e6e6e6',
      },
    }),
    option: (styles, state) => ({
      ...styles,
      color: 'black',
      backgroundColor: state.isFocused ? '#e6e6e6' : '#F2F2F2',
      '&:hover': {
        backgroundColor: '#e6e6e6',
      },
    }),
  };

  const keyDownState = useRef<{ [key: string]: boolean | undefined }>({});

  useEffect(() => {
    const onKeyUpHandler = (e: KeyboardEvent) => {
      const keyDownStateCopy = cloneDeep(keyDownState.current);
      keyDownStateCopy[e.key] = false;
      keyDownState.current = keyDownStateCopy;
    };
    const onKeyDownHandler = (e: KeyboardEvent) => {
      const keyDownStateCopy = cloneDeep(keyDownState.current);
      keyDownStateCopy[e.key] = true;
      keyDownState.current = keyDownStateCopy;
    };

    window.addEventListener('keyup', onKeyUpHandler);

    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('keyup', onKeyUpHandler);
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, []);

  const updateDeliveryAndSubmit = useCallback(
    async (record: DeliveryDto) => {
      salesOrder['Stock Numbers'] = salesOrder['Deliveries'].map((delivery) => delivery.Stock);

      if (salesOrder['Stock Numbers'].length < 1 && !usingEnteringStockNumberAppraoch) {
        alert('Cannot create Sales Order without at least 1 Stock Number');
        return;
      }

      // const updatedRecord = record['Delivered Date'] =

      const nowDateString = new Date().toLocaleDateString();
      const tempArray = nowDateString.split('/');
      const nowDateStringArray = [tempArray[2], tempArray[0], tempArray[1]];

      const newRecord = { ...record };

      newRecord['Delivered Date'] = nowDateStringArray.join('-');
      const updatedDeliveriesList = salesOrder.Deliveries.reduce<DeliveryDto[]>((result, item) => {
        if (newRecord.Stock === item.Stock) {
          result.push(newRecord);
          return result;
        }
        result.push(item);
        return result;
      }, []);

      const updatedSalesOrder = { ...salesOrder };
      updatedSalesOrder.Deliveries = updatedDeliveriesList;

      const response = await updateRecord(updatedSalesOrder, false, true); // Setting pressingCtrl to false here since we don't want to give them the option of the ctrl functionality here.

      if (response?.isSuccessful) {
        setSalesOrder(response.model);
      }
    },
    [salesOrder, setSalesOrder, updateRecord, usingEnteringStockNumberAppraoch]
  );

  const getCorrectFolderStringPathToSaveToAzureBasedOffOfPhotoTypeAndStock = (
    recordType: RecordType,
    stockNumber: string
  ) => {
    switch (recordType) {
      case RecordType.Delivery:
        return `inventory/${stockNumber}/inventoryDelivered`;
      case RecordType.Inventory:
        return `inventory/${stockNumber}/inventory`;
      case RecordType.RecordOfTransfer:
        return `inventory/${stockNumber}/transfer`;
      default:
        console.error(`Couldn't find matching Record Type for: ${recordType}! Using Inventory type for now`);
        return `inventory/${stockNumber}/inventory`;
    }
  };

  const handleDeliveryLabelStrip = useCallback(
    (record: DeliveryDto, index: number) => {
      const stockNumber = record.Stock != null && +record.Stock != 0 ? `Stock # ${record.Stock}` : '';
      const make = record.Make != null && record.Make != '' ? `Make ${record.Make}` : '';
      const year = record.Year != null && record.Year != 0 ? `Year ${record.Year}` : '';
      const vin = record.VIN != null && record.VIN != '' ? `Vin ${record.VIN}` : '';

      format(new Date(2014, 1, 11), 'MM/dd/yyyy');

      const delivered =
        record['Delivered Date'] != null && record['Delivered Date'] != ''
          ? `Delivered Date ${new Date(record['Delivered Date']).toLocaleDateString('en')}`
          : '';

      // const handleDeliveryPicturesButtonClick = async (stockNumber: string) => {
      //     const url = await takeDeliveryPhotoAndSaveToBackend(stockNumber);
      //     setPhotoUrlsInStockDictionary((oldDict) => {
      //         const wrapperToAdd = {url, };
      //         const oldStockNumberDictValue = oldDict[stockNumber];
      //         if (
      //             oldStockNumberDictValue !== null &&
      //             oldStockNumberDictValue !== undefined &&
      //             Array.isArray(oldStockNumberDictValue)
      //         ) {
      //             wrapperToAdd.push(...oldStockNumberDictValue);
      //         }
      //         return { ...oldDict, [stockNumber]: wrapperToAdd };
      //     });
      //     // (document.getElementById('testst') as HTMLImageElement).src = url;
      // };

      const photoUrls = photoUrlsInStockDictionary[record.Stock];

      return (
        <>
          <div className={styles.labelContainer}>
            <IonIcon
              ref={buttons[index]}
              icon={menu}
              className={styles.deliveryModalButton}
              id={`${record.Stock}-buttons-icon`}
              onClick={(e) => e.stopPropagation()}

            /*
                Don't put onClick here. It will be ignored because 
                I have added a native onClick handler for this ionIcon button
                in order to prevent the ionCollapsibleSection from being toggled.
                Scroll up to the onClick handler in the useEffect for these buttons to change onClick 
                functoinality.
                
                More clarification:

                1. StopPagination doesn't work with react events
                 - React events are processed after all other native events have been handled thus there's no way to 
                 stop pagination from a lower element as react handles it from the higher element.
                2. I've setup a native event with stop Propagation to Prevent the IonCollapsible section from toggling
                 - I've added a native event with stopPropagation to prevent the ionCollapsible section form toggling with tihs button is clicked
                3. All onClick events add on this element will be ignored because of the native event handler  I have
                 - The native onclick handler will run first and call the 'stop propagation' method.
                 - This will prevent calling to the higher elements which means react won't get to process it
                 - So even though we have an onClick on this element and stopPropagation is only meant to prevent
                 sending the event to the parents, because the onClick is using React's Syntehtic system it won't see
                 the mouse click because react handles it some parent element. I believe normally in Just plain javascript
                 if you were to add an on click on this element twice both of them would execute even if one of them
                 runs stopPropagation

            */
            />
            <IonPopover
              event={{
                target: nativeEvent.current?.target,
                clientX: nativeEvent.current?.clientX,
                clientY: nativeEvent.current?.clientY,
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              isOpen={record.Stock === currentlySelectedDeliveryRecordStockNumber}
              onIonPopoverDidDismiss={() => {
                setCurrentlySelectedDeliveryRecordStockNumber(null);
              }}
            // className={styles.popoverStyles}
            >
              <IonRow>
                <IonCol>
                  <IonButton
                    size="small"
                    onClick={async (e) => {
                      const data = await handleSubmit(undefined, true);

                      if (isNullOrUndefined(data)) {
                        console.error('DATA IS NULL FOR SOME REASON INSIDE OF EXPORT METHOD');
                        return;
                      }

                      const targetDelivery = data.model.Deliveries.find(
                        (delivery) => delivery.Stock === record.Stock
                      );

                      if (isNullOrUndefined(targetDelivery)) {
                        console.error("Can't find delivery after trying to export!!");
                        return;
                      }

                      setPdfTargetedRecord(targetDelivery);
                      setDeliveryPdfToggle(!deliveryPdfToggle);

                      // setCurrentlySelectedDeliveryRecordStockNumber(null);
                    }}>
                    Export PDF
                  </IonButton>
                </IonCol>

                <input
                  type="file"
                  title=" "
                  multiple
                  onChange={(e) => {
                    if (isNullOrUndefined(e.target.files)) {
                      // Shouldn't really happen.
                      makeSimpleToast('Please provide photos!');
                      return;
                    }

                    handleUploadPhoto(
                      e.target.files,
                      null,
                      record.Stock,
                      RecordType.Delivery,
                      false,
                      (photoCreationDto) => {
                        setPhotoUrlsInStockDictionary((stockDictionary) => {
                          const clonedStockDictionary = cloneDeep(stockDictionary);

                          clonedStockDictionary[record.Stock] =
                            photoCreationDto.currentPhotosList;

                          return clonedStockDictionary;
                        });

                        makeSimpleToast(
                          `Photos that were unable to be uploaded. ${photoCreationDto.unsuccessfulPhotoUploadAttempts.map(
                            (x) => x.attemptedUrl
                          )}`
                        );
                      }
                    ).then((photos) => {
                      if (isNullOrUndefined(photos)) {
                        return;
                      }
                      setPhotoUrlsInStockDictionary((old) => {
                        const cloned = cloneDeep(old);

                        const updated = { ...cloned, [record.Stock]: photos };
                        return updated;
                      });
                    });

                    e.target.value = '';
                  }}
                  ref={uploadPhotosRef}
                  style={{ display: 'none' }}

                // const folderToUploadTo = getCorrectFolderStringPathToSaveToAzureBasedOffOfPhotoTypeAndStock(
                //     recordType,
                //     stock
                // );
                />

                <IonCol>
                  <IonButton
                    onClick={() => {
                      uploadPhotosRef.current?.click();

                      // setCurrentlySelectedDeliveryRecordStockNumber(null);
                    }}>
                    <IonIcon icon={cameraOutline} />
                    Delivery
                  </IonButton>
                </IonCol>
                {photoUrls != undefined && (
                  <IonCol>
                    {/* <div onClick={() => setCurrentlySelectedDeliveryRecordStockNumber(null)}> */}
                    <PhotoGallery
                      photoUrls={photoUrls}
                      galleryTitle={'Delivery Photos'}
                      removingUrlFromState={removingUrlFromState}
                      stock={record.Stock}
                      recordType={RecordType.Delivery}
                      handleUpdatingPhotosFromPhotoGallery={handleUpdatingPhotosFromPhotoGallery}
                    />
                    {/* </div> */}
                  </IonCol>
                )}
                {record.Id != undefined && +record.Id != 0 && (
                  <IonCol>
                    {/* <div */}
                    {/* onClick={() => { */}
                    {/* setCurrentlySelectedDeliveryRecordStockNumber(null); */}
                    {/* }}> */}
                    <AuditLogContainer
                      tableName="Deliveries"
                      primaryKey={record.Id + ''}
                      isAdmin={false}
                      useModal={true}
                      booleanToggleToIndicateToUpdateAuditLog={
                        booleanToggleToIndicateToUpdateAuditLog
                      }
                      filterKeyArrayByExcludedColumns={filterSalesOrderKeyArrayByExcludedColumns}
                    />
                    {/* </div> */}
                  </IonCol>
                )}
              </IonRow>
            </IonPopover>

            {/* <IonCol>
                            <IonButton
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                disabled={
                                    record.Id === '' ||
                                    record.Id === '0' ||
                                    (record['Delivered Date'] !== null && record['Delivered Date'] !== '')
                                }
                                size="small"
                                // onClick={() => handleCallToDeliver(delivery)}
                            >
                                Deliver
                            </IonButton> */}
            {/* </IonCol> */}

            <div className={styles.labelContentContainer}>
              <IonCol>
                <IonButton
                  onClick={(event) => {
                    event.stopPropagation();
                    updateDeliveryAndSubmit(record);
                  }}
                  disabled={
                    record.Id === '' ||
                    record.Id === '0' ||
                    (record['Delivered Date'] !== null && record['Delivered Date'] !== '')
                  }
                  size="small"
                // onClick={() => handleCallToDeliver(delivery)}
                >
                  Deliver
                </IonButton>
              </IonCol>
              <span>{`ROD ${stockNumber}  ${make}  ${year} ${vin}  ${delivered}`}</span>
            </div>
          </div>
        </>
      );
    },
    [
      booleanToggleToIndicateToUpdateAuditLog,
      buttons,
      currentlySelectedDeliveryRecordStockNumber,
      deliveryPdfToggle,
      handleSubmit,
      photoUrlsInStockDictionary,
      removingUrlFromState,
      updateDeliveryAndSubmit,
    ]
  );

  const [focusedEntry, setFocusedEntry] = useState<string | null>(null);

  return (
    <Layout>
      <IonHeader>
        <IonToolbar>
          {isNotNullNorUndefined(locationObj) && isNotNullNorUndefined(locationObj) && (
            // eslint-disable-next-line react/jsx-no-undef
            <NavLink
              to={{
                pathname: locationObj.state?.fromDelivery
                  ? '/delivery'
                  : locationObj.state?.fromAccountingTodo
                    ? '/accountingtodo'
                    : '/sales-order',
                state: locationObj.state ?? {},
              }}
              className={styles.backToSearchResultsLink}>
              <IonIcon className={styles.backToSearchResultsLinkIcon} icon={chevronBackOutline} />
              Back to search results
            </NavLink>
          )}
          <IonTitle>{isUpdating ? 'Update' : 'Create'} Sales Order</IonTitle>
        </IonToolbar>
      </IonHeader>

      {isEditing ? (
        <div className={styles.gridContainer}>
          <IonGrid>
            <form onSubmit={handleSubmit} ref={formRef} data-group="form">
              <IonRow>
                <IonCol>
                  <IonButton onClick={changeToView}>
                    <IonIcon icon={eyeOutline} />
                    View
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton onClick={() => handleExportSalesOrderSpreadsheet()}>
                    Export Sales Order
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow
                style={{ position: 'relative', zIndex: '1000' }}
              // sytle={{ position: 'relative', zIndex: '500' }}
              >
                <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                  <IonItem
                    fill="solid"
                    className={`${invoiceNumberValidationObject ? '' : 'ion-invalid'}`}>
                    <IonLabel position="stacked">Invoice Number</IonLabel>
                    <IonInput
                      disabled={allFieldsReadOnly || isUpdating}
                      type="text"
                      {...bindIonSalesOrder<string>('Invoice Number', async (value) => {
                        if (value == undefined) {
                          setinvoiceNumberValidationObject({
                            valid: false,
                            errorReason: 'NoValue',
                          });
                        }

                        const response = await SalesOrderService.getSalesOrderDetails(value);
                        if (response.data.isSuccessful) {
                          setinvoiceNumberValidationObject({
                            valid: false,
                            errorReason: 'AlreadyExists',
                          });
                          return;
                        }

                        setinvoiceNumberValidationObject({ valid: true });
                      })}
                      // className=""
                      required
                    />
                    <IonNote slot="error">Invoice Number is already in use!</IonNote>
                  </IonItem>

                  <div className="invalid-feedback">Invoice Number is required</div>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Status</IonLabel>
                    <IonSelect
                      style={{ width: '100%', maxWidth: '100%' }}
                      {...bindIonSalesOrderSelect('Status')}
                      // disabled={!canUserAccessAdminSecurityWithGivenRoles(roles)}>
                      disabled={
                        allFieldsReadOnly || !canUserAccessAdminSecurityWithGivenRoles(roles)
                      }>
                      {/* <IonSelectOption value="">Select Status</IonSelectOption> */}
                      {Object.entries(SalesOrderStatus)
                        .filter(([key, value]) => isNaN(parseInt(key)))
                        .map(([key, value]) => (
                          <IonSelectOption value={value} key={value}>
                            {key}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Misc Tracking</IonLabel>
                    <IonInput
                      disabled={allFieldsReadOnly}
                      {...bindIonSalesOrder('Misc Tracking')}
                      className=""
                    />
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Check Number</IonLabel>
                    <IonInput
                      disabled={allFieldsReadOnly}
                      type="text"
                      {...bindIonSalesOrder('Check Number')}
                    />
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Deposit</IonLabel>
                    <IonInput
                      disabled={allFieldsReadOnly}
                      type="text"
                      clearOnEdit
                      {...bindIonSalesOrder('Deposit')}
                    />
                  </IonItem>

                  {/* 
                        Deposit
                        <IonInput
                            type="number"
                            step=".01"
                            min={0}
                            {...bindIonSalesOrderDecimal('Deposit')}
                            className=""
                        />
                         */}
                  {/* 
                            Status
                            <IonSelect {...bindIonSalesOrderSelect('Status')} className="">
                                <IonSelectOption value="">Select Status</IonSelectOption>
                                {Object.entries(SalesOrderStatus)
                                    .filter(([key, value]) => isNaN(parseInt(key)))
                                    .map(([key, value]) => (
                                        <IonSelectOption value={value} key={value}>
                                            {key}
                                        </IonSelectOption>
                                    ))}
                            </IonSelect>
                         */}

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Split Deal</IonLabel>
                    {/* Needs this class. Styles from global.scss */}
                    <div className="checkbox-container">
                      <IonCheckbox
                        disabled={allFieldsReadOnly}
                        {...bindIonSalesOrderCheck('Split Deal')}
                      />
                    </div>
                  </IonItem>
                  {/* Needs this class for styles from global.scss */}
                  <div
                    className={`async-select-container ${styles.asyncSelectInputContainer}`}
                    style={{ position: 'relative', zIndex: '11' }}>
                    <IonLabel position="stacked">Sales Person</IonLabel>
                    {/* Needs this class. Styles from global.scss */}
                    <div
                      className={`async-select-container-${allFieldsReadOnly ? 'disabled' : ''}`}>
                      <AsyncSelect
                        styles={asyncSelectStyles}
                        isDisabled={allFieldsReadOnly}
                        // name="Sales person"
                        {...bindSalesOrderReactAsyncSelect('Sales Person')}
                        loadOptions={SearchForEmployee}
                        // cacheOptions
                        closeMenuOnSelect
                        placeholder={'Search by Employee Name'}
                        className={styles.asyncSelectCustom}
                      />
                    </div>
                  </div>

                  {salesOrder['Split Deal'] &&
                    (() => {
                      return (
                        <div
                          className={styles.asyncSelectInputContainer}
                          style={{ position: 'relative', zIndex: '10' }}>
                          <IonLabel position="stacked">Split Sales Person</IonLabel>
                          <AsyncSelect
                            styles={asyncSelectStyles}
                            isDisabled={allFieldsReadOnly}
                            // name="Sales person"
                            {...bindSalesOrderReactAsyncSelect('Split Sales Person')}
                            loadOptions={SearchForEmployee}
                            // cacheOptions
                            closeMenuOnSelect
                            // value={{
                            //     label: salesOrder['Split Sales Person'],
                            //     value: salesOrder['Split Sales Person'],
                            // }}
                            placeholder={'Search by Employee Name'}
                            className={styles.asyncSelectCustom}
                          />
                        </div>
                      );
                    })()}

                  <div
                    className={`async-select-container ${styles.asyncSelectInputContainer}`}
                    style={{ position: 'relative', zIndex: '9' }}>
                    <IonLabel position="stacked" className="form-check-label">
                      Customer Name
                    </IonLabel>
                    <div
                      className={`async-select-container-${allFieldsReadOnly ? 'disabled' : ''}`}>
                      <AsyncSelect
                        styles={asyncSelectStyles}
                        isDisabled={allFieldsReadOnly}
                        {...bindSalesOrderReactAsyncSelect(
                          'Customer Name',
                          async (newValue: any) => {
                            const response = await CustomerService.SearchByName(
                              new StringDto(newValue.label)
                            );

                            // If only CreateCustomer is returned don't pass any more data
                            if (response.data.model.length == 1) {
                              return {};
                            }

                            // Grabbing the second in the list because the first one is always
                            // the 'Create Customer' Option
                            const customerNumber = response.data.model[1].identifier ?? '';

                            return { 'Customer #': customerNumber };
                          }
                        )}
                        className={styles.asyncSelectCustom}
                        loadOptions={SearchForCustomer}
                        // cacheOptions
                        closeMenuOnSelect
                        placeholder={'Search by Customer Name'}
                      />
                    </div>
                  </div>
                  <IonItem fill="solid">
                    <IonLabel position="stacked">Customer #</IonLabel>
                    <IonInput disabled={true} type="text" {...bindIonSalesOrder('Customer #')} />
                  </IonItem>
                  <IonItem fill="solid">
                    <IonLabel position="stacked">All Delivered</IonLabel>
                    <div className="checkbox-container">
                      <IonCheckbox
                        disabled={
                          salesOrder['All Delivered'] || !isUpdating || allFieldsReadOnly
                        }
                        onClick={() => {
                          handleAllInventoryDelivered(salesOrder);
                        }}
                        {...bindIonSalesOrderCheck('All Delivered')}
                      />
                    </div>
                  </IonItem>
                </IonCol>
                <IonCol style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* </IonItem> */}
                  {/* <input
                                type="text"
                                list="Customers"
                                placeholder="Enter Customer Name"
                                onChange={(e) => searchForStocksForDelivery(e.target.value?.toString())}
                            />
                            <datalist id="Customers">{dataList()}</datalist> */}

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Lienholder</IonLabel>
                    <IonInput
                      disabled={allFieldsReadOnly}
                      type="text"
                      {...bindIonSalesOrder('Lienholder')}
                      className=""
                    />
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Notes</IonLabel>
                    <IonTextarea
                      disabled={allFieldsReadOnly}
                      {...bindIonSalesOrder('Notes')}
                      className=""
                    />
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Ordered Date</IonLabel>
                    <IonInput
                      className={`${isNullOrUndefined(salesOrder['Ordered Date']) ||
                        salesOrder['Ordered Date'].trim() === ''
                        ? styles.dateInputEmpty
                        : ''
                        }`}
                      onIonFocus={(e) => (e.target.style.opacity = '1')}
                      disabled={allFieldsReadOnly}
                      type="date"
                      {...minMax}
                      {...bindIonSalesOrderDate('Ordered Date')}
                    />
                  </IonItem>
                  <IonItem fill="solid">
                    <IonLabel position="stacked">Docs Signed</IonLabel>
                    <IonInput
                      className={`${isNullOrUndefined(salesOrder['Docs Signed']) ||
                        salesOrder['Docs Signed'].trim() === ''
                        ? styles.dateInputEmpty
                        : ''
                        }`}
                      onIonFocus={(e) => (e.target.style.opacity = '1')}
                      disabled={allFieldsReadOnly}
                      {...bindIonSalesOrderDate('Docs Signed')}
                      type="date"
                    />
                  </IonItem>

                  {/* <div className="form-group col">
                            <label>Temp Tag Expiration</label>
                            <input type="text" {...bindSalesOrder('Temp Tag Expiration')} className="" />
                        </div> */}

                  <IonItem fill="solid">
                    <IonLabel position="stacked">Proof Of Payment Received</IonLabel>
                    <IonInput
                      className={`${isNullOrUndefined(salesOrder['Proof Of Payment Received']) ||
                        salesOrder['Proof Of Payment Received'].trim() === ''
                        ? styles.dateInputEmpty
                        : ''
                        }`}
                      onIonFocus={(e) => (e.target.style.opacity = '1')}
                      disabled={allFieldsReadOnly}
                      type="date"
                      {...bindIonSalesOrderDate('Proof Of Payment Received')}
                      {...minMax}
                    // className=""
                    />
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked" className="form-check-label">
                      Title Work Completed
                    </IonLabel>
                    <IonInput
                      className={`${isNullOrUndefined(salesOrder['Title Work Completed']) ||
                        salesOrder['Title Work Completed'].trim() === ''
                        ? styles.dateInputEmpty
                        : ''
                        }`}
                      onIonFocus={(e) => (e.target.style.opacity = '1')}
                      disabled={allFieldsReadOnly}
                      type="date"
                      {...bindIonSalesOrderDate('Title Work Completed')}
                      {...minMax}
                    />
                  </IonItem>

                  <IonItem fill="solid">
                    <IonLabel position="stacked">NAPP</IonLabel>
                    <div className="checkbox-container">
                      <IonCheckbox
                        disabled={allFieldsReadOnly}
                        {...bindIonSalesOrderCheck('NAPP')}
                      />
                    </div>
                  </IonItem>
                  <IonItem fill="solid">
                    <IonLabel position="stacked">Delivered Count</IonLabel>
                    <IonInput
                      type="text"
                      disabled={true}
                      {...bindIonSalesOrder('Delivered Count')}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>

              <hr />
              <IonRow>
                <IonCol>
                  <IonButton disabled={allFieldsReadOnly} type="submit">
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
              <hr />
              <>
                <div style={{ marginBottom: '1rem' }}>
                  <SimpleAccordion<DeliveryDto>
                    generateInnerContent={(data, index) => (
                      //@ts-ignore
                      <Delivery
                        key={data.Stock}
                        record={data}
                        updateDelivery={updateDelivery}
                        index={index}
                        booleanToggleToIndicateToUpdateAuditLog={
                          booleanToggleToIndicateToUpdateAuditLog
                        }
                        photoUrls={photoUrlsInStockDictionary[data.Stock]}
                        deleteDelivery={deleteDelivery}
                        lastDelivery={salesOrder.Deliveries.length === 1}
                        salesOrder={salesOrder}
                        setSalesOrder={setSalesOrder}
                        bindIonSalesOrderSelect={bindIonSalesOrderSelect}
                        bindIonSalesOrder={bindIonSalesOrder}
                        bindSalesOrderReactAsyncSelect={bindSalesOrderReactAsyncSelect}
                        bindIonSalesOrderDate={bindIonSalesOrderDate}
                        bindIonSalesOrderCheck={bindIonSalesOrderCheck}
                        updateSalesOrderProperty={updateSalesOrderProperty}></Delivery>
                    )}
                    dataList={salesOrder.Deliveries}
                    identifierKey={'Stock'}
                    generateLabel={handleDeliveryLabelStrip}
                    initialSelectedIdentifierValue={focusedEntry ?? undefined}></SimpleAccordion>
                </div>
                {/* {salesOrder['Deliveries'] != undefined && salesOrder['Deliveries'].length > 0 ? (
                                    <CollapsibleSection
                                        focusedEntry={focusedEntry}
                                        setFocusedEntry={setFocusedEntry}
                                        records={records}
                                        identifier={'Stock'}
                                        labelStrip={handleDeliveryLabelStrip}
                                        content={(record) => (
                                            //@ts-ignore
                                            <Delivery
                                                record={record}
                                                updateDelivery={updateDelivery}
                                                salesOrder={salesOrder}
                                                setSalesOrder={setSalesOrder}
                                                bindIonSalesOrderSelect={bindIonSalesOrderSelect}
                                                bindIonSalesOrder={bindIonSalesOrder}
                                                bindSalesOrderReactAsyncSelect={bindSalesOrderReactAsyncSelect}
                                                bindIonSalesOrderDate={bindIonSalesOrderDate}
                                                bindIonSalesOrderCheck={bindIonSalesOrderCheck}
                                                updateSalesOrderProperty={updateSalesOrderProperty}
                                                booleanToggleToIndicateToUpdateAuditLog={
                                                    booleanToggleToIndicateToUpdateAuditLog
                                                }
                                                index={0}
                                                photoUrls={photoUrlsInStockDictionary[record.Stock]}
                                                deleteDelivery={deleteDelivery}
                                                lastDelivery={salesOrder.Deliveries.length === 1}
                                            />
                                        )}
                                        startingValue={identifierNumber}
                                    />
                                ) : (
                                    <></>
                                )} */}
              </>
              <div className={styles.space}></div>

              <IonItem fill="solid">
                <IonLabel position="stacked">Input Approach</IonLabel>
                <IonCheckbox
                  disabled={allFieldsReadOnly}
                  checked={usingEnteringStockNumberAppraoch}
                  className="form-check-input"
                  onIonChange={(e) =>
                    setUsingEnteringStockNumberAppraoch(e.detail.checked)
                  }></IonCheckbox>
              </IonItem>

              {usingEnteringStockNumberAppraoch ? (
                <InputAppraoch
                  salesOrder={salesOrder}
                  allFieldsReadOnly={allFieldsReadOnly}
                  salesOrderId={salesOrder.Id}
                  updateDeliveries={updateDeliveries}
                />
              ) : (
                <IonRow>
                  <IonCol
                    size={'12'}
                  // hidden={!showAddTrailer}
                  >
                    {/*// Need to do reverse logic here*/}
                    <div style={{ width: '100%' }} className={styles.asyncSelectInputContainer}>
                      <IonLabel position="stacked">Add Delivery</IonLabel>
                      <AsyncSelect
                        styles={asyncSelectStyles}
                        isDisabled={allFieldsReadOnly}
                        loadOptions={searchForStocksForDelivery}
                        value={{ label: '', value: '' }}
                        className={`${styles.asyncSelectCustom} ${styles.fullWidthAsyncSelect}`}
                        /*
            Having this value word here basically locks the input to not save any
            values. This makes it so that when the user tries to add another delivery
            after adding a previous one the input field will be blank. In contrast without 
            this value property in this case the input field will have the stock number previously 
            put into it. But what does work is on the onchange function. Basically if we
            try to change the value the handleChange will run. But the actual input value
            will stay the same.
            When the handleChange runs it takes the value it tried to change and sets it as the
            stock number for the new delivery.

            Typescript doesn't seem to like this so I've added @ts-ignore to the onchange and load
            options that otherwise would've been showing errors

            TL;DR

            The benefit of having this value here is that after the user has added one record of
            delivery, they will show this input and it will already be blank instead of having
            the stock number from the previous delivery creation.

            */
                        onChange={handleChange}
                        // cacheOptions
                        closeMenuOnSelect
                        placeholder={'Search by Stock Number'}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              )}

              <IonRow>
                <IonCol>
                  <IonButton disabled={allFieldsReadOnly} type="submit">
                    Submit
                  </IonButton>
                </IonCol>
                {canUserAccessAccountingSecurityWithGivenRoles(roles) && (
                  <IonCol>
                    <IonButton
                      disabled={allFieldsReadOnly}
                      type="button"
                      color="danger"
                      onClick={handleDeactivateRecord}
                      className={styles.deleteButton}>
                      Deactivate
                    </IonButton>
                  </IonCol>
                )}
              </IonRow>
            </form>
          </IonGrid>
        </div>
      ) : (
        <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton onClick={changeToEdit}>
                  <IonIcon icon={createOutline} />
                  Edit
                </IonButton>
              </IonCol>
              {/* {photos != undefined && (
                                <IonCol>
                                    <PhotoGallery photoUrls={photos} galleryTitle={'Inventory Photos'} />
                                </IonCol>
                            )} */}
              <IonCol>
                <div>
                  <ReactToPrint
                    bodyClass={'test'}
                    copyStyles={false}
                    trigger={() => <IonButton>Print</IonButton>}
                    content={() => {
                      const element = salesOrderCondensedViewPrintComponent.current;

                      if (isNotNullNorUndefined(element)) {
                        return element;
                      }
                      const el = document.createElement('p');
                      el.innerText = 'Test taco';
                      el.style.color = 'black';
                      return el;
                    }}
                  />
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <SalesOrderCondensedView
            setIsEditing={setIsEditing}
            salesOrder={salesOrder}
            setFocusedEntry={setFocusedEntry}
            ref={salesOrderCondensedViewPrintComponent}
            testStyle={styles.test}
          />
        </>
      )}

      <ShowDeliveryPdf
        pdfTargetedRecord={pdfTargetedRecord}
        toggle={deliveryPdfToggle}
        customerName={salesOrder['Customer Name']}
      />
      <IonModal isOpen={showModal} onIonModalDidDismiss={() => setShowModal(false)}>
        <IonItem fill="solid">
          <IonLabel>Customer Identifier</IonLabel>
          <IonInput
            value={customerIdentifier}
            onIonChange={(e) => {
              if (e.detail.value == undefined) {
                return;
              }

              setCustomerIdentifier(e.detail.value);
            }}
          />
        </IonItem>
        <IonItem fill="solid">
          <IonLabel>Customer Name</IonLabel>
          <IonInput
            value={customerName}
            onIonChange={(e) => {
              if (e.detail.value == undefined) {
                return;
              }

              setCustomerName(e.detail.value);
            }}
          />
        </IonItem>

        <IonButton type="submit" onClick={handleModalClick}>
          Submit Button
        </IonButton>
      </IonModal>
      {salesOrder.Id != undefined && salesOrder.Id != undefined && salesOrder.Id !== '' && salesOrder.Id !== '0' && (
        <>
          <AuditLogContainer
            tableName="SalesOrder"
            primaryKey={salesOrder['Invoice Number'] + ''}
            isAdmin={false}
            booleanToggleToIndicateToUpdateAuditLog={booleanToggleToIndicateToUpdateAuditLog}
            filterKeyArrayByExcludedColumns={filterSalesOrderKeyArrayByExcludedColumns}
          />
        </>
      )}
    </Layout>
  );
};
