import { YardMapService } from '../../utils/apiServices';
import { AsyncSelectLoadOptionsReturnType } from './SearchUtils';

interface Option {
    label: string;
    value: string;
}

export const GetYardLocationNames = (): Promise<AsyncSelectLoadOptionsReturnType<Option>> => {
    const promise: Promise<AsyncSelectLoadOptionsReturnType<Option>> = new Promise(async (resolve, reject) => {
        try {
            const response = await YardMapService.GetYardLocationNames();
            const data = response.data;

            const mappedData = data.model.map((yardStallName) => {
                return { label: yardStallName, value: yardStallName };
            });
            // update for type specific later
            resolve(mappedData);
        } catch (err) {
            reject('There was an error while attempting to get yard stall names');
        }
    });
    return promise;
};
