import { useMsal } from '@azure/msal-react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import React from 'react';
import Card, { CardIconTypes } from '../../components/Cards/Card';
import GeneralToolTip from '../../components/GeneralToolTip/GeneralToolTip';
import { RecordType } from '../../models/Global';
import { InventoryService, MetricService } from '../../utils/apiServices';
import { loginApiRequest } from '../../utils/AuthConfig';
import { Layout } from '../Layout';
import styles from './Dashboard.module.scss';

defineCustomElements(window);

export const DashboardPage: React.FC = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginApiRequest).catch((e: any) => { });
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  const resposne = InventoryService.Testing();

  const handling = async () => {
    const toast = await (window as any).toastController.create({
      message: 'Hello World!',
      duration: 1500,
      position: 'bottom',
    });

    await toast.present();
  };

  return (
    // <IonPage>
    <Layout>
      <h1 className={styles.contentTitle}>Dashboard</h1>
      {/*
            <Card
                endpoint={() => MetricService.getMetrics(RecordType.Inventory)}
                icon={CardIconTypes.Square}
                hideOnTabletSize={true}
            />
            */}
      {/* <QueriedTableTabContainer
                identifier={'Stock'}
                identifierType={IdentifierType.Stock}
                toolbar={true}
                endpoint={InventoryService.getInventory}
                tabbed={true}
                justSortOptions={false}
                model={new InventoryDto()}
                recordType={RecordType.Inventory}
                // MAY NEED TO CHANGE THIS ENUM DICTIONARY TYPE FROM INVENTORY TO A DASHBOARD ONE OR REMOVE THIS SECTION LATER
            /> */}
      <GeneralToolTip content={['Test', 'Test2']} />
    </Layout>
    // </IonPage>
  );
};
