import AccountingTodoTable from '../../components/NewTableApproach/AccountingTodoTable/AccountingTodoTable';
import QueriedTableSortFilterStateManager, {
    QueriedTableSortFilterStateManagerExportProps,
} from '../../components/Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager';
import { ReturnAccountingToDoDto } from '../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { GeneralOptions } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { SortOptions } from '../../models/Dtos/OptionsDtos/SortOptions';
import { DtoType, RecordType } from '../../models/Global';
import { AccountingService } from '../../utils/apiServices';
import { IdentifierType } from '../../utils/identifierType';
import { EndpointResponseType } from '../../utils/RecordTypeUtils';

interface AccountingTodoQueriedTableProps {
    scrollTop: number;
}

export const AccountingTodoQueriedTable = ({ scrollTop }: AccountingTodoQueriedTableProps) => {
    return (
        <QueriedTableSortFilterStateManager
            scrollTop={scrollTop}
            identifier={'Stock'}
            identifierType={IdentifierType.Stock}
            toolbar={true}
            endpoint={
                AccountingService.getAccountingToDo as (
                    options: GeneralOptions | SortOptions
                ) => Promise<EndpointResponseType>
            }
            tabbed={true}
            justSortOptions={false}
            model={new ReturnAccountingToDoDto()}
            recordType={RecordType.AccountingTodo}
            dtoType={DtoType.AccountingTodo}>
            {(exportProps: QueriedTableSortFilterStateManagerExportProps) => <AccountingTodoTable {...exportProps} />}
        </QueriedTableSortFilterStateManager>
    );
};
