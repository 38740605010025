import { StringDto } from '../../models/Dtos/GeneralDtos/StringDto';
import { InventoryService } from '../../utils/apiServices';
import { SearchForStocksOption } from './SearchStockByNumberForDelivery';
import { AsyncSelectLoadOptionsReturnType } from './SearchUtils';

// Using exported type form SearchStockByNumberForDelivery

export const searchForStocks = async (
    stockNumber: string
): Promise<AsyncSelectLoadOptionsReturnType<SearchForStocksOption>> => {
    const response = await InventoryService.SearchByStockForYardLocation(new StringDto(stockNumber.trim()));
    const messageDto = response.data;
    const data = messageDto.model;

    const stockNumberArray = data.map<SearchForStocksOption>((x) => {
        return { label: x + '', value: x };
    });

    return stockNumberArray;
};
