import format from 'date-fns/format';
import { SalesOrderStatus } from '../../Global';
import { DeliveryDto } from './DeliveryDto';

// type SalesOrderDtoWOMethods = {
//     [K in keyof SalesOrderDto as K extends 'getModelType' ? never :
//     K extends 'getEnumValuesFromPropertyName' ? never :
//     K extends 'getEnumStringValueFromPropertyNameAndValue' ? never : ]: SalesOrderDto[K];
// };

export class SalesOrderDto {
    Id = '';

    'Invoice Number' = '';
    'Ordered Date': string | null = format(new Date(Date.now()), 'yyyy-MM-dd');

    'Docs Signed': string | null = null;

    'Check Number' = '';

    Deposit = '0.00';

    Status: SalesOrderStatus = SalesOrderStatus.Active;

    'Stock Numbers': string[] = [];

    'Sales Person' = '';

    'Customer Name' = '';

    'Customer #' = '';

    'Title Work Completed': string | null = null;

    NAPP = false;

    Lienholder = '';

    Notes = '';

    // @returnRecordPropertySaveDataType(DataType.String)
    // 'Temp Tag Expiration': string;

    'Misc Tracking' = '';

    'Proof Of Payment Received': string | null = '';

    'Back Office Notes' = '';

    'Split Deal' = false;

    'All Delivered' = false;

    'Split Sales Person' = '';

    'Delivered Count' = '';

    // Do not show on  Queried Table
    'Deliveries': Array<DeliveryDto> = [];

    constructor(obj?: SalesOrderDto) {
        this.Id = obj?.Id || '';
        this['Invoice Number'] = obj?.['Invoice Number'] ?? '';
        this['Ordered Date'] = obj?.['Ordered Date'] ?? null;
        this['Docs Signed'] = obj?.['Docs Signed'] ?? null;
        this['Check Number'] = obj?.['Check Number'] ?? '';
        this.Deposit = obj?.Deposit || '0.00';
        this.Status = obj?.Status ?? SalesOrderStatus.Active;

        this['Stock Numbers'] = obj?.['Stock Numbers'] ?? [];
        this['Deliveries'] = obj?.Deliveries ?? [];
        this['Sales Person'] = obj?.['Sales Person'] ?? '';
        this['Customer Name'] = obj?.['Customer Name'] ?? '';
        this['Customer #'] = obj?.['Customer #'] ?? '';
        this['Title Work Completed'] = obj?.['Title Work Completed'] ?? null;
        this['Proof Of Payment Received'] = obj?.['Proof Of Payment Received'] ?? '';
        this.NAPP = obj?.NAPP ? obj.NAPP : false;
        this.Lienholder = obj?.Lienholder || '';
        this.Notes = obj?.Notes || '';
        // this['Temp Tag Expiration'] = obj ? obj['Temp Tag Expiration'] : '';
        this['Misc Tracking'] = obj?.['Misc Tracking'] ?? '';
        this['Back Office Notes'] = obj?.['Back Office Notes'] ?? '';
        this['Split Deal'] = obj?.['Split Deal'] ?? false;

        this['All Delivered'] = obj?.['All Delivered'] ?? false;
        this['Delivered Count'] = obj?.['Delivered Count'] ?? '';
        this['Split Sales Person'] = obj?.['Split Sales Person'] ?? '';
    }
}

export const AllSalesOrderKeysArray = [
    'Invoice Number',
    'Ordered Date',

    'Docs Signed',

    'Check Number',

    'Deposit',

    'Status',

    'Stock Numbers',

    'Sales Person',

    'Customer Name',

    'Customer #',

    'Title Work Completed',

    'NAPP',

    'Lienholder',

    'Notes',

    'Misc Tracking',

    'Proof Of Payment Received',

    'Back Office Notes',

    'Split Deal',

    'All Delivered',

    'Split Sales Person',

    'Delivered Count',
];
