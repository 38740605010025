import React, { ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';
import { isNotNullNorUndefined } from '../../utils/NullOrUndefined';
import styles from './CompWrapperInfoToolTip.module.scss';

const DEFAULT_PLACE = 'top';

interface CompWrapperInfoToolTipProps {
    children: ReactNode;
    htmlContent: string;
    place?: 'top' | 'left' | 'right' | 'bottom';
    id: string;
    SHOW_DELAY_IN_MILISECONDS: number;
    HIDE_DELAY_IN_MILISECONDS: number;
    align?: 'left' | 'right' | 'center';
}

/**
 * This component is not to be used by itself. The proper way to use it
 * is to use the GeneralToolTip component which will render this component
 * @param param0
 * @returns
 */
const CompWrapperInfoToolTip = ({
    children,
    htmlContent,
    place,
    id,
    SHOW_DELAY_IN_MILISECONDS,
    HIDE_DELAY_IN_MILISECONDS,
}: CompWrapperInfoToolTipProps) => {
    return (
        <>
            <div id={id} className={styles.CompWrapperInfoToolTip} data-testid="CompWrapperInfoToolTip">
                {children}
            </div>
            <Tooltip
                anchorId={id}
                // content={content}
                place={isNotNullNorUndefined(place) ? place : DEFAULT_PLACE}
                html={htmlContent}
                delayShow={SHOW_DELAY_IN_MILISECONDS}
                delayHide={HIDE_DELAY_IN_MILISECONDS}
            />
        </>
    );
};

export default CompWrapperInfoToolTip;
