import { UrlPhotoIdWrapper } from '../models/Dtos/PhotoDtos/UrlPhotoIdWrapper';
import { PhotoService } from './apiServices';

const ChangePhotoOrderIndexAndUpdateAccordingly = async (
    photoId: string,
    targetIndexToChangeTo: number,
    insertBeforeTarget: boolean
): Promise<UrlPhotoIdWrapper[] | null> => {
    const response = await PhotoService.changePhotoOrderIndexAndUpdateAccordingly(
        photoId,
        targetIndexToChangeTo,
        insertBeforeTarget
    );

    if (response.status >= 200 && response.status < 300) {
        // Okay
        return response.data.model;
    }
    return null;
};
export default ChangePhotoOrderIndexAndUpdateAccordingly;
// const returnUpdatedArray = (
//     firstIndex: number,
//     secondIndex: number,
//     photos: UrlPhotoIdWrapper[]
// ): [UrlPhotoIdWrapper, UrlPhotoIdWrapper, UrlPhotoIdWrapper[]] => {
//     const copy = cloneDeep(photos);

//     const firstValue = copy[firstIndex];
//     const secondValue = copy[secondIndex];

//     let foundFirstIndex = false;
//     let foundSecondIndex = false;

//     const updatedArray = photos.map((photoWrapper, i) => {
//         if (i === firstIndex) {
//             foundFirstIndex = true;
//             return secondValue;
//         }

//         if (i === secondIndex) {
//             foundSecondIndex = true;
//             return firstValue;
//         }
//         return photoWrapper;
//     });

//     if (!foundFirstIndex) {
//         // didn't find index!
//         throw new Error(`Provided first index of: ${firstIndex} was not discovered! May be too big of an index!`);
//     }
//     if (!foundSecondIndex) {
//         // didn't find index!
//         throw new Error(`Provided second index of: ${firstIndex} was not discovered! May be too big of an index!`);
//     }

//     return [firstValue, secondValue, updatedArray];
// };

// const deletePreviuosUrlAzureStorageAndRemoveFromPhotoTable = async (photoWrapper: UrlPhotoIdWrapper, order: number) => {
//     const urlWithSas = `${photoWrapper.url}${process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN}`;
//     const currentDateObject = new Date();
//     const currentDateString =
//         currentDateObject.getDay() + '-' + currentDateObject.getMonth() + '-' + currentDateObject.getFullYear();

//     const instance = axios.create();

//     const authorizationHeader = axios.defaults.headers.common['Authorization'];
//     delete instance.defaults.headers.common['Authorization'];

//     const beginningOfFileName = urlWithSas.lastIndexOf('/') + 1;
//     const endOfFileName = urlWithSas.lastIndexOf('.');
//     const startOfSAS = urlWithSas.lastIndexOf('?');

//     const fileName = urlWithSas.substring(beginningOfFileName, endOfFileName);
//     const fileExtension = urlWithSas.substring(endOfFileName, startOfSAS);
//     const beforeFileName = urlWithSas.substring(0, beginningOfFileName);
//     // const sasToken = urlWithSas.substring(startOfSAS);
//     const sasToken =
//         '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=3024-01-25T02:32:45Z&st=2024-01-24T18:32:45Z&spr=https&sig=XdRUbhUxlaWOKrL%2BiGkOiAWoBPbWgUochuDZ9m9w%2B6w%3D';

//     const photoWithOrderNumber = `${beforeFileName}${fileName}-${order}${fileExtension}${sasToken}`;
//     // const photoWithOrderNumber = `${beforeFileName}${fileName}-${order}${fileExtension}${sasToken}`;

//     const photoWithoutOrderNumber = `${beforeFileName}${fileName}${fileExtension}${sasToken}`;

//     // Try to delete by fileName with order number.

//     // If the file name can't be found, try without the order number.
//     const response = await instance
//         .delete<ReturnMessageOnlyDto>(`${photoWithOrderNumber}`, {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//                 'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
//                 // 'Content-Type': 'image/jpeg',
//                 'x-ms-date': currentDateString,
//                 'x-ms-blob-type': 'BlockBlob',
//                 'x-ms-version': '2021-06-08',
//             },
//         })
//         .catch(async (e) => {
//             // console.error(e);
//             // makeSimpleToast('Unexpected error happened!');
//             // console.error('Unexpected error happened! ');

//             // Didn't work with orderNumber, try with normal name
//             const response = await instance.delete<ReturnMessageOnlyDto>(`${photoWithoutOrderNumber}`, {
//                 headers: {
//                     'Access-Control-Allow-Origin': '*',
//                     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//                     'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
//                     // 'Content-Type': 'image/jpeg',
//                     'x-ms-date': currentDateString,
//                     'x-ms-blob-type': 'BlockBlob',
//                     'x-ms-version': '2021-06-08',
//                 },
//             });
//             return response;
//         });

//     const status = response.status;
//     if (response.status === 202) {
//         await PhotoService.delete(photoWrapper.photoId);
//     } else {
//         makeSimpleToast('Unexpected error happened!');
//         console.error(response);
//         throw new Error('Non 202 successful result from deleting photo from azure storage! ');
//     }
// };

// const createNewUrlAndPhotoEntry = async (photoWrapper: UrlPhotoIdWrapper, order: number) => {
//     // Need to make sure when creating a photo, if our webapi rejects, we need to remove it from azure too.
//     const currentDateObject = new Date();
//     const currentDateString =
//         currentDateObject.getDay() + '-' + currentDateObject.getMonth() + '-' + currentDateObject.getFullYear();

//     const instance = axios.create();

//     const authorizationHeader = axios.defaults.headers.common['Authorization'];
//     delete instance.defaults.headers.common['Authorization'];

//     const urlWithSas = `${photoWrapper.url}${process.env.REACT_APP_INVENTORY_CONTAINER_SAS_TOKEN}`;

//     const beginningOfFileName = urlWithSas.lastIndexOf('/') + 1;
//     const endOfFileName = urlWithSas.lastIndexOf('.');
//     const startOfSAS = urlWithSas.lastIndexOf('?');

//     const fileName = urlWithSas.substring(beginningOfFileName, endOfFileName);
//     const fileExtension = urlWithSas.substring(endOfFileName, startOfSAS);
//     const beforeFileName = urlWithSas.substring(0, beginningOfFileName);
//     // const sasToken = urlWithSas.substring(startOfSAS);
//     const sasToken =
//         '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=3024-01-25T02:32:45Z&st=2024-01-24T18:32:45Z&spr=https&sig=XdRUbhUxlaWOKrL%2BiGkOiAWoBPbWgUochuDZ9m9w%2B6w%3D';

//     const photoWithOrderNumber = `${beforeFileName}${fileName}-${order}${fileExtension}${sasToken}`;

//     const photoWithoutOrderNumber = `${beforeFileName}${fileName}${fileExtension}${sasToken}`;

//     // Getting Photo Data

//     let photoResponse = await fetch(photoWithOrderNumber, { method: 'GET' });
//     let photoData: null | Blob = null;

//     if (!photoResponse.ok) {
//         // Not okay Most likely 404 error
//         photoResponse = await fetch(photoWithoutOrderNumber, { method: 'GET' });
//     }

//     try {
//         photoData = await photoResponse.blob();
//     } catch (e) {
//         makeSimpleToast(`Unable to process photo data!: ${e}`);
//         console.log(`Unable to process photo data!: ${e}`);
//         console.error(`Unable to process photo data!`, e);
//         return;
//     }

//     if (photoData === null) {
//         makeSimpleToast(`Unable to process photo data!`);
//         console.log(`Unable to process photo data!`);
//         console.error(`Unable to process photo data!`);
//         return;
//     }

//     const azureResponse = await instance
//         .put(photoWithOrderNumber, photoData, {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//                 'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
//                 'Content-Type': 'image/png',
//                 'x-ms-date': currentDateString,
//                 'x-ms-blob-type': 'BlockBlob',
//                 'x-ms-version': '2021-06-08',
//             },
//         })
//         .catch((e) => {
//             makeSimpleToast(`Unable to upload photo to azure!: ${azureResponse}`);
//             console.log(`Unable to upload photo to azure!: ${azureResponse}`);
//             console.error(`Unable to upload photo to azure!`, azureResponse);
//         });

//     //
//     // const urlDecoded = decodeURIComponent(photoData);

//     // const photoData = await fetch(photoWrapper.url, { method: 'Get' });

//     // const photoWithOrderNumber = `${beforeFileName}${fileName}-${order}${fileExtension}${sasToken}`;

//     //     await instance
//     //         .put<ReturnMessageOnlyDto>(photoWithOrderNumber, {
//     //             headers: {
//     //                 'Access-Control-Allow-Origin': '*',
//     //                 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
//     //                 'Access-Control-Allow-Headers': 'Origin, Content-Type, x-ms-*',
//     //                 // 'Content-Type': 'image/jpeg',
//     //                 'x-ms-date': currentDateString,
//     //                 'x-ms-blob-type': 'BlockBlob',
//     //                 'x-ms-version': '2021-06-08',
//     //             },
//     //         })
//     //         .then((response) => {
//     //             const status = response.status;
//     //             if (response.status === 202) {
//     //                 /*

//     //                 TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

//     //                 Need to implement the below cvode for creating photo on webapi

//     // */
//     //                 //PhotoService.create({url: photoWrapper});
//     //             } else {
//     //                 makeSimpleToast('Unexpected error happened!');
//     //                 console.error(response);
//     //                 throw new Error('Non 202 successful result from deleting photo from azure storage! ');
//     //             }
//     //         })
//     //         .catch((e) => {
//     //             // console.error(e);
//     //             // makeSimpleToast('Unexpected error happened!');
//     //             // console.error('Unexpected error happened! ');
//     //             throw new Error('Error when trying to delete photo!');
//     //         });
// };

// const DragAndDropSwap = async (
//     firstIndex: number,
//     secondIndex: number,
//     photos: UrlPhotoIdWrapper[]
// ): Promise<UrlPhotoIdWrapper[]> => {
//     const [firstValue, secondValue, updatedArray] = returnUpdatedArray(firstIndex, secondIndex, photos);

//     try {
//         await createNewUrlAndPhotoEntry(firstValue, secondIndex + 1);
//     } catch (e) {
//         console.error(e);
//         makeSimpleToast(`Unexpected error happened: ${e}`);
//         console.error(`Unexpected error happened! ${e}`);
//     }

//     try {
//         createNewUrlAndPhotoEntry(secondValue, firstIndex + 1);
//     } catch (e) {
//         console.error(e);
//         makeSimpleToast(`Unexpected error happened: ${e}`);
//         console.error(`Unexpected error happened! ${e}`);
//     }

//     try {
//         // Seems to be working good
//         await deletePreviuosUrlAzureStorageAndRemoveFromPhotoTable(firstValue, firstIndex + 1);
//     } catch (e) {
//         console.error(e);
//         makeSimpleToast(`Unexpected error happened: ${e}`);
//         console.error(`Unexpected error happened! ${e}`);
//     }

//     try {
//         // Seems to be working good
//         await deletePreviuosUrlAzureStorageAndRemoveFromPhotoTable(secondValue, secondIndex + 1);
//     } catch (e) {
//         console.error(e);
//         makeSimpleToast(`Unexpected error happened: ${e}`);
//         console.error(`Unexpected error happened! ${e}`);
//     }

//     return updatedArray;
// };
// export default DragAndDropSwap;
