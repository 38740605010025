import { ReturnAccountingToDoDto } from '../../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { DataType } from '../../models/Global';

export const getDataTypeFromAccountingTodoPropertyName = (property: keyof ReturnAccountingToDoDto): DataType => {
    switch (property) {
        case 'Select': {
            return DataType.String;
        }
        case 'Invoice Number': {
            return DataType.String;
        }
        case 'Stock': {
            return DataType.String;
        }
        case 'Customer Number': {
            return DataType.String;
        }
        case 'Customer Name': {
            return DataType.String;
        }
        case 'Sales Order Notes': {
            return DataType.String;
        }
        case 'Title Work Completed': {
            return DataType.DateTime;
        }
        case 'Delivered Date': {
            return DataType.DateTime;
        }
        case 'Sales Person': {
            return DataType.String;
        }
        case 'Make': {
            return DataType.String;
        }
        case 'Inventory Type': {
            return DataType.String;
        }
        case 'Inventory Condition': {
            return DataType.Enum;
        }
        case 'Delivery Notes': {
            return DataType.String;
        }

        case 'VIN': {
            return DataType.String;
        }

        case 'Inventory Notes': {
            return DataType.String;
        }
        case 'Split Deal - I': {
            return DataType.Bool;
        }

        case 'Split Deal - S': {
            return DataType.Bool;
        }
        default: {
            return DataType.String;
        }
    }
};
