import { useEffect, useMemo, useRef } from 'react';
import { InventoryDto } from '../../models/Dtos/InventoryDtos/InventoryDto';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../models/Dtos/OptionsDtos/GeneralOptions';
import { InventoryCondition, InventoryStatus, InventorySubType, PhotoStatus } from '../../models/Global';
import { convertToInputDateFormat } from '../../utils/DateUtils';
import makeSimpleToast from '../../utils/MakeSimpleToast';
import { isNotNullNorUndefined, isNullOrUndefined } from '../../utils/NullOrUndefined';
import {
    SpecializedTableStandardHeaderStyle,
    SpecializedTableStandardIdentifierColumnStyle,
    SpecializedTableStandardTableDataStyle,
    SpecializedTableStandardTableTagStyle,
    SpecializedTableStandardTextAreaWrapper,
    SpecializedTableStandardTextWrapper,
} from '../../utils/SpecializedTableStandardStylesAndElements';
import { CH_OFFSET, MIN_WIDTH_INPUT } from '../NewTableApproach/GeneralReference';

// Idea go through each value of the column and determine the biggest width. and then perhaps
// set the inputs to width 100% unless they match the biggest width. I'm not sure.

const generateInputStylesAndUpdateBiggestInputWidth = (valueToLookAt: string, columnName: string) => {
    let width = 0;
    if (isNotNullNorUndefined(valueToLookAt) && valueToLookAt !== '' && valueToLookAt !== 'null') {
        width = valueToLookAt.length;
    }
    return { width: width + CH_OFFSET + 'ch', minWidth: MIN_WIDTH_INPUT };
};

const savedFunctionsToUpdateData = {
    Stock: (property: keyof InventoryDto, Stock: string | number, stock: string, index: number) => {
        return (
            <td
                style={
                    {
                        ...SpecializedTableStandardTableDataStyle(index),
                        ...SpecializedTableStandardIdentifierColumnStyle,
                    }
                    // { backgroundColor: 'red' }
                }
                key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Stock}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    VIN: (property: keyof InventoryDto, VIN: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{VIN}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Number: (property: keyof InventoryDto, Number: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Number}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Color: (property: keyof InventoryDto, Color: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Color}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Sales Person': (property: keyof InventoryDto, SalesPerson: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{SalesPerson}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Deposit: (property: keyof InventoryDto, Deposit: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Deposit}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Spec: (property: keyof InventoryDto, Spec: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Spec}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Customer: (property: keyof InventoryDto, Customer: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Customer}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Price: (property: keyof InventoryDto, Price: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Price}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Comp Date': (property: keyof InventoryDto, CompDate: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(CompDate)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Inspection Date': (property: keyof InventoryDto, InspectionDate: string | null, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(InspectionDate)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Received Date': (property: keyof InventoryDto, ReceivedDate: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(ReceivedDate)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },

    Advertise: (property: keyof InventoryDto, Advertise: string | boolean, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {Advertise === true ? 'True' : 'False'}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'DOT Status': (property: keyof InventoryDto, DOTStatus: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{DOTStatus}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Decal Id': (property: keyof InventoryDto, DecalId: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{DecalId}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Check Number': (property: keyof InventoryDto, CheckNumber: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{CheckNumber}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'City Location': (property: keyof InventoryDto, CityLocation: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{CityLocation}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Cost: (property: keyof InventoryDto, Cost: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Cost}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Type: (property: keyof InventoryDto, Type: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Type}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Sub Type': (property: keyof InventoryDto, SubType: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {InventorySubType[SubType as number]}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Year: (property: keyof InventoryDto, Year: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Year}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Make: (property: keyof InventoryDto, Make: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Make}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Model: (property: keyof InventoryDto, Model: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Model}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Condition: (property: keyof InventoryDto, Condition: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {InventoryCondition[Condition as number]}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Status: (property: keyof InventoryDto, Status: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {InventoryStatus[Status as number]}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    L: (property: keyof InventoryDto, L: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{L}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    W: (property: keyof InventoryDto, W: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{W}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    H: (property: keyof InventoryDto, H: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{H}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Wheel Sz': (property: keyof InventoryDto, WheelSz: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{WheelSz}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Wheel Type': (property: keyof InventoryDto, WheelType: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{WheelType}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Axles: (property: keyof InventoryDto, Axles: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Axles}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Suspension Type': (
        property: keyof InventoryDto,
        SuspensionType: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{SuspensionType}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Suspension Style': (
        property: keyof InventoryDto,
        SuspensionStyle: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{SuspensionStyle}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    KP: (property: keyof InventoryDto, KP: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{KP}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Unit Hours': (property: keyof InventoryDto, UnitHours: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{UnitHours}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Entered In CDK': (property: keyof InventoryDto, EnteredInCDK: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(EnteredInCDK)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Floor Type': (property: keyof InventoryDto, FloorType: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{FloorType}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Rear Door Type': (property: keyof InventoryDto, RearDoorType: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{RearDoorType}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },

    'Inventory Title': (
        property: keyof InventoryDto,
        InventoryTitle: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{InventoryTitle}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Temp Tag Expiration': (property: keyof InventoryDto, TempTagExpiration: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(TempTagExpiration)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Side Doors': (property: keyof InventoryDto, SideDoors: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{SideDoors}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Photo Status': (property: keyof InventoryDto, PhotoStatusParam: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {PhotoStatus[PhotoStatusParam as number]}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Ordered Date': (property: keyof InventoryDto, OrderedDate: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(OrderedDate)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Reefer Engine Year': (
        property: keyof InventoryDto,
        ReeferEngineYear: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{ReeferEngineYear}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Reefer Make': (property: keyof InventoryDto, ReeferMake: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{ReeferMake}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Reefer Model': (property: keyof InventoryDto, ReeferModel: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{ReeferModel}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Reefer Desc': (property: keyof InventoryDto, ReeferDesc: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextAreaWrapper>{ReeferDesc}</SpecializedTableStandardTextAreaWrapper>
            </td>
        );
    },
    'Reefer Serial Number': (
        property: keyof InventoryDto,
        ReeferSerialNumber: string | number,
        stock: string,
        index: number
    ) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{ReeferSerialNumber}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Reefer Year': (property: keyof InventoryDto, ReeferYear: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{ReeferYear}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    Notes: (property: keyof InventoryDto, Notes: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{Notes}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Split Deal': (property: keyof InventoryDto, SplitDeal: string | boolean, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {SplitDeal === true ? 'True' : 'False'}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Invoice Number': (property: keyof InventoryDto, InvoiceNumber: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{InvoiceNumber}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Yard Stall': (property: keyof InventoryDto, YardStall: string | number, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>{YardStall}</SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Misc Date': (property: keyof InventoryDto, MiscDate: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(MiscDate)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
    'Delivered Date': (property: keyof InventoryDto, DeliveredDate: string, stock: string, index: number) => {
        return (
            <td style={SpecializedTableStandardTableDataStyle(index)} key={`${stock}-${property}`}>
                <SpecializedTableStandardTextWrapper>
                    {convertToInputDateFormat(DeliveredDate)}
                </SpecializedTableStandardTextWrapper>
            </td>
        );
    },
};

interface InventorySpecalizedTableRenderProps {
    data: InventoryDto[];
    selectedRecords: string[];
    invisibleContainerDiv: HTMLDivElement;
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
}

const InventorySpecalizedTableRender = ({
    data,
    selectedRecords,
    invisibleContainerDiv,
    computedCombinedFilters,
}: InventorySpecalizedTableRenderProps) => {
    const filteredData = useMemo(() => {
        return data.filter((dto) => selectedRecords.includes(dto.Stock));
    }, [data, selectedRecords]);

    const tableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        const handleCopy = async () => {
            // setTimeout(() => {
            if (isNullOrUndefined(tableRef.current)) {
                makeSimpleToast('Error while copying styles', 'Table div is undefined or null');
                console.error('Error while copying styles, table div is undefined or null');
                return;
            }

            try {
                // Copy the selected content to the clipboard using Clipboard API
                navigator.clipboard
                    .write([
                        new ClipboardItem({
                            'text/html': new Blob([tableRef.current.outerHTML], { type: 'text/html' }),
                        }),
                    ])
                    .then(() => {
                        console.log('Table copied to clipboard successfully!');

                        makeSimpleToast(
                            'Successfully copied specialized table!',
                            "You've copied the specialized version of the table for email."
                        );
                    })
                    .catch((err) => {
                        console.error('Unable to copy table to clipboard:', err);
                        makeSimpleToast(
                            'Error while trying to copy styles.',
                            'Page not focused. Please try again and try to keep the page focused for at least a second.'
                        );
                    });
            } catch (error) {
                console.error('Clipboard API is not supported in this browser:', error);
            }
            invisibleContainerDiv.remove();
            // }, 60000);
        };
        handleCopy();
    }, [invisibleContainerDiv]);

    return (
        <div data-testid="InventorySpecalizedTableRender">
            <table ref={tableRef} style={SpecializedTableStandardTableTagStyle}>
                <thead>
                    <tr>
                        {computedCombinedFilters.orderedColumnsToShow.map((column) => (
                            <th
                                style={{
                                    ...SpecializedTableStandardHeaderStyle,
                                    ...(column.toLowerCase() === 'stock'
                                        ? SpecializedTableStandardIdentifierColumnStyle
                                        : {}),
                                }}
                                key={column}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((dataDto, rowIndex) => {
                        return (
                            <tr key={dataDto.Stock}>
                                {computedCombinedFilters.orderedColumnsToShow.map((columnName) => {
                                    return savedFunctionsToUpdateData[
                                        columnName as keyof typeof savedFunctionsToUpdateData
                                    ](
                                        columnName as keyof InventoryDto,
                                        // Might need to revisit
                                        dataDto[columnName as keyof InventoryDto] as any,
                                        dataDto.Stock,
                                        rowIndex
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default InventorySpecalizedTableRender;
