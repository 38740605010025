import { IonModal, IonSpinner } from '@ionic/react';
import React from 'react';
import { isNotNullNorUndefined } from '../../utils/NullOrUndefined';
import './LoaderOverlay.css';
import styles from './LoaderOverlay.module.scss';

type LoaderOverlayProps = {
  isVisible: boolean;
  progressText?: string;
};

const LoaderOverlay: React.FC<LoaderOverlayProps> = ({ isVisible, progressText }) => {
  const item = document.querySelector('ion-router-outlet');

  if (isNotNullNorUndefined(item)) {
    item.style.overflow = isVisible ? 'hidden' : 'scroll';
  }

  return (
    <IonModal isOpen={isVisible}>
      <div className={styles.loaderDiv}>
        <h1 className={styles.loadingHeader}>Loading...</h1>
        <IonSpinner className={styles.ionSpinner} name="lines-sharp-small" />
        {isNotNullNorUndefined(progressText) && <span className={styles.progressText}>{progressText}</span>}
      </div>
    </IonModal>
  );
};

export default LoaderOverlay;
