import { IonAccordion, IonButton, IonItem, IonLabel, IonRow } from '@ionic/react';
import { useEffect, useState } from 'react';
import CollapsibleSection from '../../../components/CollapsibleSection/CollapsibleSection';
import { GeneralOptions } from '../../../models/Dtos/OptionsDtos/GeneralOptions';
import { YardMapHistoryDto } from '../../../models/Dtos/YardMapDtos/YardMapHistoryDto';
import { YardMapService } from '../../../utils/apiServices';

export const YardMapHistoryManager = () => {
    // ********** TO DO List **********
    // Verify Working
    // Check Styling
    // Do Stuff for when model is not successful
    // Filtering using General Options - can be simplified

    const [history, setHistory] = useState<YardMapHistoryDto[]>([]);
    const [options, setOptions] = useState<GeneralOptions>(new GeneralOptions());

    const [buttonNumbers, setButtonNumbers] = useState<number[]>([1, 2, 3, 4, 5]);

    const getHistory = () => {
        YardMapService.getHistory(options).then(({ data }) => {
            if (data.isSuccessful) {
                setHistory(data.model.model);
            } else {
                const messageToSend = `There was an error while processing a request:\n${data.messageForUser}`;

                alert(messageToSend);
            }
        });
    };

    useEffect(() => {
        getHistory();

        if (options.sortOptions.pageNumber <= 2) {
            setButtonNumbers([1, 2, 3, 4, 5]);
        } else {
            setButtonNumbers([
                options.sortOptions.pageNumber - 2,
                options.sortOptions.pageNumber - 1,
                options.sortOptions.pageNumber,
                options.sortOptions.pageNumber + 1,
                options.sortOptions.pageNumber + 2,
            ]);
        }
    }, [options]);

    const updatePageNumber = (value: number) => {
        // const optionUpdate = historyOptions;
        // optionUpdate.sortOptions.pageNumber = value;
        // setHistoryOptions(optionUpdate);

        const copy: GeneralOptions = { ...options };
        copy.sortOptions.pageNumber = value;
        setOptions(copy);
    };

    useEffect(() => {
        getHistory();
    }, []);

    return (
        <>
            <CollapsibleSection<YardMapHistoryDto[]>
                records={[history]}
                labelStrip={() => <span>{`Yard Map History Info`}</span>}
                content={(history) => (
                    <span>
                        {history.length > 0
                            ? history.map((record) => {
                                  return (
                                      <IonAccordion key={record.stockNumber}>
                                          <IonItem>
                                              <IonLabel>Stock</IonLabel>
                                              <IonLabel>{record.stockNumber}</IonLabel>
                                          </IonItem>
                                          <IonItem>
                                              <IonLabel>Employee</IonLabel>
                                              <IonLabel>{record.lastUpdatedBy}</IonLabel>
                                          </IonItem>
                                          <IonItem>
                                              <IonLabel>Date</IonLabel>
                                              <IonLabel>{record.lastUpdated}</IonLabel>
                                          </IonItem>
                                          <IonItem>
                                              <IonLabel>Current Stall</IonLabel>
                                              <IonLabel>{record.currentLocation}</IonLabel>
                                          </IonItem>
                                          <IonItem>
                                              <IonLabel>Previous Stall</IonLabel>
                                              <IonLabel>{record.previousLocation}</IonLabel>
                                          </IonItem>
                                      </IonAccordion>
                                  );
                              })
                            : ''}
                    </span>
                )}
                startingValue={undefined}
                //@ts-ignore
                identifier={'0'}
            />

            <IonRow>
                <IonButton onClick={(e) => updatePageNumber(options.sortOptions.pageNumber - 1)}>Previous</IonButton>
                <IonButton
                    color={options.sortOptions.pageNumber == 1 ? 'success' : 'primary'}
                    onClick={(e) => updatePageNumber(buttonNumbers[0])}>
                    {buttonNumbers[0]}
                </IonButton>
                <IonButton
                    color={options.sortOptions.pageNumber == 2 ? 'success' : 'primary'}
                    onClick={(e) => updatePageNumber(buttonNumbers[1])}>
                    {buttonNumbers[1]}
                </IonButton>
                <IonButton
                    color={options.sortOptions.pageNumber > 2 ? 'success' : 'primary'}
                    onClick={(e) => updatePageNumber(buttonNumbers[2])}>
                    {buttonNumbers[2]}
                </IonButton>
                <IonButton color={'primary'} onClick={(e) => updatePageNumber(buttonNumbers[3])}>
                    {buttonNumbers[3]}
                </IonButton>
                <IonButton color={'primary'} onClick={(e) => updatePageNumber(buttonNumbers[4])}>
                    {buttonNumbers[4]}
                </IonButton>
                <IonButton color={'primary'} onClick={(e) => updatePageNumber(options.sortOptions.pageNumber + 1)}>
                    Next
                </IonButton>
            </IonRow>
        </>
    );
};
