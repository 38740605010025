import { ComparisonOperatorEnum } from '../../ComparisonOperator';
import { DataType } from '../../Global';
import { ComparisonRange } from './ComparisonRange';

export class DynamicFilter {
    propertyName = '';
    value = '';
    comparisonOperator: ComparisonOperatorEnum = ComparisonOperatorEnum.Equal;
    comparisonRange?: ComparisonRange = ComparisonRange.Day;
    comparisonType: DataType = DataType.String;

    constructor(obj?: DynamicFilter) {
        this.propertyName = obj?.propertyName ?? '';
        this.value = obj?.value ?? '';
        this.comparisonOperator = obj?.comparisonOperator ?? ComparisonOperatorEnum.Equal;
        this.comparisonRange = obj?.comparisonRange ?? ComparisonRange.Day;
        this.comparisonType = obj?.comparisonType ?? DataType.String;
    }
}

export const areDynamicFiltersTheSame = (firstFilter: DynamicFilter, secondFilter: DynamicFilter) => {
    const propName = firstFilter.propertyName === secondFilter.propertyName;
    const value = firstFilter.value === secondFilter.value;
    const comparisonOperator = firstFilter.comparisonOperator === secondFilter.comparisonOperator;
    const comparisonRange = firstFilter.comparisonRange === secondFilter.comparisonRange;
    const comparisonType = firstFilter.comparisonType === secondFilter.comparisonType;

    return propName && value && comparisonOperator && comparisonRange && comparisonType;
};
