import styles from './MsalRedirectorNoEnvComp.module.scss';

interface MsalRedirectorNoEnvCompProps {}

const MsalRedirectorNoEnvComp = ({}: MsalRedirectorNoEnvCompProps) => {
    return (
        <div className={styles.MsalRedirectorNoEnvCompContainer} data-testid="MsalRedirectorNoEnvComp">
            <h1>An environment variable is missing. Please reach out to web developer to resolve investigate.</h1>
        </div>
    );
};

export default MsalRedirectorNoEnvComp;
