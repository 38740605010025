import { IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import { isNullOrUndefined } from '../../../../utils/NullOrUndefined';
import { addStylesToElement } from '../../../../utils/StyleUtil';
import styles from './QueriedTableFilterButton.module.scss';

type QueriedTableFilterButtonProps = {
    color: string;
    button: string;
    filter_options_button: string;
    selected: boolean;
    last_button?: string;
    ['data-testid']?: string;
    onClick: () => void;
    index: number;
    children: React.ReactNode;
    filterName: string;
};

const QueriedTableFilterButton: React.FC<QueriedTableFilterButtonProps> = ({
    color,
    button,
    filter_options_button,
    'data-testid': dataTestId,
    selected,
    last_button,
    onClick,
    index,
    children,
    filterName,
}) => {
    const buttonStyles = {
        'font-weight': '700',
        'text-shadow': selected ? 'none' : '1px 1px 5px black',
    };

    useEffect(() => {
        const ionButton = document.getElementById(`queried-table-filter-button-${filterName}`) as HTMLIonButtonElement;

        if (isNullOrUndefined(ionButton)) {
            return;
        }

        const button = ionButton.shadowRoot?.querySelector('button');

        if (isNullOrUndefined(button)) {
            return;
        }

        addStylesToElement(button.style, buttonStyles);
        const t = button.classList;
        // button.style.display = 'none';
    }, []);

    return (
        <IonButton
            id={`queried-table-filter-button-${filterName}`}
            // color={`filters-light`}
            className={`${styles.filterButton} ${selected ? styles.selectedFilter : ''}`}
            data-testid={dataTestId}
            fill="outline"
            onClick={() => {
                onClick();
            }}>
            {children}
        </IonButton>
    );
};

export default QueriedTableFilterButton;
