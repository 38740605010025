import { ReturnAccountingToDoDto } from '../models/DeliveryAndTransferDtos/ReturnAccountingToDoDto';
import { InventoryDto } from '../models/Dtos/InventoryDtos/InventoryDto';
import { DeliveryDto } from '../models/Dtos/SalesOrderDtos/DeliveryDto';
import { SalesOrderDto } from '../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { DtoType } from '../models/Global';
import { getEnumFromAccountingTodoDtoPropertyName } from './EnumMappersByDtoType/AccountingTodoEnumMapper';
import { getEnumFromDeliveryDtoPropertyName } from './EnumMappersByDtoType/DeliveryDtoEnumMapper';
import { getEnumFromInventoryDtoPropertyName } from './EnumMappersByDtoType/InventoryDtoEnumMapper';
import { getEnumFromSalesOrderDtoPropertyName } from './EnumMappersByDtoType/SalesOrderDtoEnumMapper';

export const getEnumByDtoTypeAndPropertyName = (dtoType: DtoType, propertyName: string) => {
    switch (dtoType) {
        case DtoType.Inventory: {
            return getEnumFromInventoryDtoPropertyName(propertyName as keyof InventoryDto);
        }
        case DtoType.SalesOrder: {
            return getEnumFromSalesOrderDtoPropertyName(propertyName as keyof SalesOrderDto);
        }
        case DtoType.Delivery: {
            return getEnumFromDeliveryDtoPropertyName(propertyName as keyof DeliveryDto);
        }
        case DtoType.AccountingTodo: {
            return getEnumFromAccountingTodoDtoPropertyName(propertyName as keyof ReturnAccountingToDoDto);
        }
        default: {
            return getEnumFromInventoryDtoPropertyName(propertyName as keyof InventoryDto);
        }
    }
};
