export const MIN = '1970-01-01';
export const MAX = `${new Date().getFullYear() + 3}-01-01`;

export const minMax = () => ({
    min: MIN,
    max: MAX,
});

export const isDateValidAndHandleIfNot = (dateString: string): boolean => {
    const providedDate = new Date(dateString);
    const minDate = new Date(MIN);
    const maxDate = new Date(MAX);

    if (providedDate > maxDate) {
        alert(`The date you provided of ${dateString} is greater than the max date of ${MAX}`);
        return false;
    }
    if (providedDate < minDate) {
        alert(`The date you provided of ${dateString} is less than the min date of ${MIN}`);
        return false;
    }

    return true;
};
