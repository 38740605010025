import React, { ReactNode, useState } from 'react';
import uniqid from 'uniqid';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';
import CompWrapperInfoToolTip from '../CompWrapperInfoToolTip/CompWrapperInfoToolTip';
import InfoToolTip from '../InfoIonIconToolTip/InfoIonIconToolTip';
import styles from './GeneralToolTip.module.scss';

interface GeneralToolTipProps {
    content: string | string[];
    children?: ReactNode;
}

interface InfoToolTipOptionalProps {
    width?: string;
    height?: string;
    align?: 'left' | 'right' | 'center';
}

const COMP_WRAPPER_INFO_TOOLTIP_PREFIX = 'comp-wrapper-info-tooltip-';
const INFO_TOOL_TIP_UNIQID_PREFIX = 'info-tooltip-';

const SHOW_DELAY_IN_MILISECONDS = 500;
const HIDE_DELAY_IN_MILISECONDS = 500;

const GeneralToolTip = ({
    content,
    children,
    width,
    height,
    align,
}: GeneralToolTipProps & InfoToolTipOptionalProps) => {
    const isInfoTooltip = isNullOrUndefined(children);
    // We'll automatically use InfoToolTip if there are no children.
    // Othwerise we'll use the wrapper

    const targetPrefix = isInfoTooltip ? INFO_TOOL_TIP_UNIQID_PREFIX : COMP_WRAPPER_INFO_TOOLTIP_PREFIX;

    const [id] = useState<string>(uniqid(targetPrefix));

    const generateHtmlForContent = (content: string | string[]) => {
        let generatedHTML = '';
        if (Array.isArray(content)) {
            content.forEach((piece) => {
                generatedHTML += `<div style='text-align: center;position: relative;z-index: 500'>${piece}</div>`;
            });

            return generatedHTML;
        }

        generatedHTML = `<div style='style='text-align: center;position: relative;z-index: 500''>${content}</div>`;

        return generatedHTML;
    };

    return (
        <div className={styles.GeneralToolTip} data-testid="GeneralToolTip">
            {isInfoTooltip ? (
                <InfoToolTip
                    htmlContent={generateHtmlForContent(content)}
                    id={id}
                    place={'bottom'}
                    width={width}
                    height={height}
                    SHOW_DELAY_IN_MILISECONDS={SHOW_DELAY_IN_MILISECONDS}
                    HIDE_DELAY_IN_MILISECONDS={HIDE_DELAY_IN_MILISECONDS}
                    align={align}
                />
            ) : (
                <CompWrapperInfoToolTip
                    htmlContent={generateHtmlForContent(content)}
                    place={'bottom'}
                    id={id}
                    SHOW_DELAY_IN_MILISECONDS={SHOW_DELAY_IN_MILISECONDS}
                    HIDE_DELAY_IN_MILISECONDS={HIDE_DELAY_IN_MILISECONDS}>
                    {children}
                </CompWrapperInfoToolTip>
            )}
        </div>
    );
};

export default GeneralToolTip;
