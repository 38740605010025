import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
} from "@ionic/react";
import debounce from "debounce";
import {
  alertCircleOutline,
  caretDownOutline,
  caretUpOutline,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { StringDto } from "../../../models/Dtos/GeneralDtos/StringDto";
import { SalesOrderDto } from "../../../models/Dtos/SalesOrderDtos/SalesOrderDto";
import { TypeInteractionDto } from "../../../models/Dtos/TypeInteractionDto";
import { DataType, RecordType, SalesOrderStatus } from "../../../models/Global";
import { DbManagedTypes } from "../../../pages/AdminPanel/components/TypeManager";
import { Layout } from "../../../pages/Layout";
import {
  CustomerService,
  SalesOrderService,
  TypeService,
} from "../../../utils/apiServices";
import { getAsyncSelectGetMethodFromSalesOrderPropertyName } from "../../../utils/AsyncSelectTypeMappersByDtoType/SalesOrderAsyncSelectTypeMapper";
import { cTB } from "../../../utils/BooleanStringHelper";
import {
  filterSalesOrderColumnMethod,
  filterSalesOrderKeyArrayByExcludedColumns,
  isSalesOrderPropertyExcluded,
} from "../../../utils/ColumnsToShowFilterByDtoType/SalesOrderColumnsToShowFilter";
import confirmingTableUpdate from "../../../utils/ConfirmingTableUpdate";
import { convertAllNullToEmptyStringFromObject } from "../../../utils/ConvertAllNullToEmptyStringUtil";
import { convertFromStringIfBooleanOrNumber } from "../../../utils/DataTypeConversionFromString/DataTypeConversionFromString";
import { getDataTypeFromSalesOrderPropertyName } from "../../../utils/DataTypeMappersByDtoType/SalesOrderDtoDataTypeMapper";
import {
  EnumSalesOrderProperties,
  getEnumFromSalesOrderDtoPropertyName,
} from "../../../utils/EnumMappersByDtoType/SalesOrderDtoEnumMapper";
import { IdentifierType } from "../../../utils/identifierType";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../../../utils/NullOrUndefined";
import PageNameGenerator from "../../../utils/PageNameGenerator";
import { reactSelectStylesForTable } from "../../../utils/ReactSelectStylesForTable";
import { AllPossibleReturnTypes } from "../../../utils/RecordTypeUtils";
import CopyTableStylesButtons from "../../CopyTableStylesButtons/CopyTableStylesButtons";
import GeneralToolTip from "../../GeneralToolTip/GeneralToolTip";
import {
  canUserAccessAdminSecurityWithGivenRoles,
  RoleContext,
} from "../../RoleContext/RoleContextComp";
import { SearchForCustomer, SearchForEmployee } from "../../Search";
import { ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID } from "../../Theme/IonSplitPaneStateContainer/IonSplitPaneStateContainer";
import QueriedTablePaginationOptions from "../../Theme/QueriedTable/QueriedTablePaginationOptions/QueriedTablePaginationOptions";
import {
  LocationType,
  QueriedTableSortFilterStateManagerExportProps,
} from "../../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import QueriedTableTabList from "../../Theme/QueriedTable/QueriedTableTabList/QueriedTableGlobalUserFilterList";
import QueriedTableToolbar from "../../Theme/QueriedTable/QueriedTableToolbar/QueriedTableToolbar";
import { CH_OFFSET, NORMAL_COLS_SIZE } from "../GeneralReference";
import styles from "./SalesOrderTable.module.scss";
type SalesOrderTableProps = QueriedTableSortFilterStateManagerExportProps;

const SalesOrderTable = ({
  endpoint,
  toolbar,
  identifier,
  identifierType,
  handleSetCustomFiltersValueFilters,
  handleCreateUpdateUserFilter,
  justSortOptions,
  overwritingProvidedData,
  model,
  useCustomEndpoint,
  setUseCustomEndpoint,
  recordType,
  handleClearCancel,
  isFilterSelected,
  handleCreateUpdateGlobalFilter,
  handleDeleteGlobalFilter,
  handleDeleteUserFilter,
  handleUpdatingItemsPerPage,
  setOverwritingProvidedData,
  setSortFilters,
  dtoType,
  tabbed,
  handleGlobalFilterButtonClick,
  data,
  setData,
  computedCombinedFilters,
  handleUserFilterButtonClick,
  setItemsPerPage,
  DEBOUNCE_WAIT_TIME,
  handleChangeSorting,
  handleOrderColumnToLeft,
  handleOrderColumnToRight,
  updateCurrentPageNumber,
  setColumnVisibility,
  selectingRowMode,
  unselectAllRows,
  selectAllRows,
  cancelSelecting,
  stopSelectingAndCopyTableWithStyles,
  stopSelectingAndEmailAdvertisements,
  startSelecting,
  isRowIdSelected,
  unselectRow,
  registerSelection,
  selectedGlobalFilterNames,
  selectedUserFilterNames,
  computedSelectedGlobalFilters,
  computedSelectedUserFilters,
  userFiltersStorage,
  globalFiltersStorage,
  customFilters,
  unselectGlobalFilter,
  unselectUserFilter,
  ignoreFilter,
  unignoreFilter,
  isFilterIgnored,
  combinedFilterTargetProperties,
  updateSearchTerm,
  handleNewUpdaterFunction,
  ignoredFilterIds,
  isLoading,
  setIsLoading,
}: SalesOrderTableProps) => {
  const history = useHistory();

  const SALES_ORDER_PAGE_TITLE = PageNameGenerator("Sales Order");

  useEffect(() => {
    if (document.title !== SALES_ORDER_PAGE_TITLE)
      document.title = SALES_ORDER_PAGE_TITLE;
  }, []);
  const getSortForColumn = useCallback(
    (property: string) => {
      const targetSort = computedCombinedFilters.sortOptions.sortBy.find(
        (item) => item.propertyName === property
      );

      if (isNullOrUndefined(targetSort)) {
        return undefined;
      }

      return targetSort.isAscending;
    },
    [computedCombinedFilters.sortOptions.sortBy]
  );

  const generateTableHeaders = useCallback(
    (dto: SalesOrderDto) => {
      if (isNullOrUndefined(dto)) {
        return null;
      }

      let headers: React.ReactElement[];

      const generateThMapMethod = (property: string, index: number) => (
        <th key={property}>
          <div className={styles.tableHeaderButtonWrapper}>
            <span
              className={styles.headerSpanContainer}
              style={
                property.toLowerCase() === "split sales person"
                  ? { display: "flex" }
                  : {}
              }
            >
              {property.toLowerCase() === "split sales person" && (
                <GeneralToolTip
                  content={[
                    "Can't add Split Sales Person",
                    "unless 'Split Deal' is checked/true!",
                  ]}
                >
                  <IonIcon size="large" icon={alertCircleOutline} />
                </GeneralToolTip>
              )}
              <div style={{ display: "block" }}>
                <button
                  onClick={(e) => handleChangeSorting(property)}
                  className={styles.headerButton}
                >
                  {property}
                  {isNullOrUndefined(getSortForColumn(property)) ? (
                    <></>
                  ) : getSortForColumn(property) ? (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretUpOutline}
                    />
                  ) : (
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.sortIcon}`}
                      icon={caretDownOutline}
                    />
                  )}
                </button>
              </div>
              {index > 0 && (
                <button
                  onClick={(e) =>
                    handleOrderColumnToLeft(
                      property as keyof AllPossibleReturnTypes
                    )
                  }
                  className={`${styles.headerButton} ${styles.changeOrderButton}`}
                >
                  <IonIcon
                    className={`${styles.columnChangeOrderIcon} ${styles.leftColumnButton}`}
                    icon={chevronBackOutline}
                  />
                </button>
              )}
              {index <
                computedCombinedFilters.orderedColumnsToShow.length - 1 && (
                  <button
                    onClick={(e) =>
                      handleOrderColumnToRight(
                        property as keyof AllPossibleReturnTypes
                      )
                    }
                    className={`${styles.headerButton} ${styles.changeOrderButton}`}
                  >
                    <IonIcon
                      className={`${styles.columnChangeOrderIcon} ${styles.rightColumnButton}`}
                      icon={chevronForwardOutline}
                    />
                  </button>
                )}
            </span>
          </div>
        </th>
      );

      const selectionColumn = (
        <th
          className={
            selectingRowMode === "unselected"
              ? styles.selectionColumnHidden
              : ""
          }
          style={{ backgroundColor: "white" }}
          key="selection-column"
        >
          To Copy
        </th>
      );

      if (
        isNullOrUndefined(computedCombinedFilters) ||
        !Array.isArray(computedCombinedFilters.orderedColumnsToShow) ||
        computedCombinedFilters.orderedColumnsToShow[0] === ""
      ) {
        // Get keys  of first entry to get headers.
        headers = [
          selectionColumn,
          ...Object.keys(dto)
            .filter(filterSalesOrderColumnMethod)
            .map(generateThMapMethod),
        ];
      } else {
        // Use columnsToShow from savedGeneratedGeneralOptions to get headers.

        // REAPLCE HERE WITH STUF

        headers = [
          selectionColumn,
          ...computedCombinedFilters.orderedColumnsToShow
            .filter(filterSalesOrderColumnMethod)
            .map(generateThMapMethod),
        ];
      }
      return headers;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      computedCombinedFilters,
      getSortForColumn,
      handleChangeSorting,
      handleOrderColumnToLeft,
      handleOrderColumnToRight,
      selectingRowMode,
    ]
  );

  const stringifyNumberOption = 4;

  const handleCopyFilterDebugInfoToClipboard = () => {
    let stringToShow = "";

    const dictOfThingsToPrint = {
      "General Options": JSON.stringify(
        computedCombinedFilters,
        null,
        stringifyNumberOption
      ),
      "Global Filter Storage": JSON.stringify(
        globalFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "User Filter Storage": JSON.stringify(
        userFiltersStorage,
        null,
        stringifyNumberOption
      ),
      "Selected Global Filter Names": JSON.stringify(
        selectedGlobalFilterNames,
        null,
        stringifyNumberOption
      ),
      "Selected  User Filter Names": JSON.stringify(
        selectedUserFilterNames,
        null,
        stringifyNumberOption
      ),
      "Computed Selected Global Filters": JSON.stringify(
        computedSelectedGlobalFilters,
        null,
        stringifyNumberOption
      ),
      "Computed Selected  User Filters": JSON.stringify(
        computedSelectedUserFilters,
        null,
        stringifyNumberOption
      ),
      "Local Stroage": JSON.stringify(
        { ...localStorage },
        null,
        stringifyNumberOption
      ),
    };

    Object.entries(dictOfThingsToPrint).forEach(([key, value]) => {
      stringToShow += "\n";
      stringToShow += `~~~~${key}~~~~\n`;
      stringToShow += value;
    });

    window.navigator.clipboard.writeText(stringToShow);
  };

  const handleGenerateTableParts = () => {
    let tableHead: React.ReactElement | null = null;

    const rows = (data as SalesOrderDto[]).map((dto, index) => {
      // Doing it this way to limit the amount of iteration done
      let headers: React.ReactElement[] | null = null;
      if (index === 0) {
        headers = generateTableHeaders(dto);
        tableHead = (
          <thead className={styles.tHead}>
            <tr>{headers}</tr>
          </thead>
        );
      }

      return (
        <tr
          key={dto["Invoice Number"]}
          className={`${index % 2 === 0 ? styles.highlighted : styles.notHighlighted
            } ${styles.tRow}`}
        >
          {runner(dto)}
          {/* <InventoryTableRow
                        dtoData={dto}
                        orderedColumnsToShow={
                            savedGeneratedGeneralOptions.current?.columnsToShow as (keyof InventoryDto)[] | undefined
                        }
                    /> */}
        </tr>
      );
    });

    console.timeEnd();

    return (
      <>
        {tableHead}
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleInputUpdateValueProvider = (
    event: ChangeEvent<HTMLInputElement>,
    columnName: keyof SalesOrderDto,
    stock: string
  ) => {
    const isCheckbox = event.target.type === "checkbox";
    const value = isCheckbox ? event.target.checked : event.target.value;

    if (isCheckbox) {
      handleUnDebouncedUpdate(value, isCheckbox, columnName, stock);
    } else {
      handleInputUpdate(value, event, columnName, stock);
    }
  };

  const handleTextareaUpdateValueProvider = (
    event: ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof SalesOrderDto,
    stock: string
  ) => {
    const value = event.target.value;

    handleInputUpdate(value, event, columnName, stock);
  };

  const handleSelectOrTextAreaUpdateValueProvider = (
    event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>,
    columnName: keyof SalesOrderDto,
    stock: string
  ) => {
    if (!confirmingTableUpdate(event)) {
      return;
    }

    const value = event.target.value;

    handleUnDebouncedUpdate(value, false, columnName, stock);
  };

  const getTargetRowOrConsoleError = (
    invoiceNumber: string
  ): [number, SalesOrderDto] => {
    const targetRowIndex = (data as SalesOrderDto[]).findIndex(
      (dto) => dto["Invoice Number"] === invoiceNumber
    );
    if (targetRowIndex === -1) {
      const errorMessage =
        "Target row of invoice number" + invoiceNumber + " can't be found!!";

      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    const targetRow = data[targetRowIndex] as SalesOrderDto;
    return [targetRowIndex, targetRow];
  };

  const handleInputUpdate = debounce(
    async (
      value: string | boolean,
      event:
        | ChangeEvent<HTMLInputElement>
        | ChangeEvent<HTMLSelectElement>
        | ChangeEvent<HTMLTextAreaElement>,
      columnName: keyof SalesOrderDto,
      stock: string
    ) => {
      if (!confirmingTableUpdate(event)) {
        return;
      }
      const isCheckbox = event.target.type === "checkbox";

      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

      const copiedTargetRow = { ...targetRow };

      const convertedValue = isCheckbox
        ? value
        : convertFromStringIfBooleanOrNumber(
          value as string,
          getDataTypeFromSalesOrderPropertyName(
            columnName as keyof SalesOrderDto
          )
        );

      //@ts-ignore
      copiedTargetRow[columnName] = convertedValue;

      // prop = ;

      // Is debounce 1000ms

      const response = await SalesOrderService.updateSalesOrderFromQueriedTable(
        convertAllNullToEmptyStringFromObject(copiedTargetRow)
      );

      setData((oldData) => {
        const toUpdate = [...oldData];
        toUpdate.splice(targetRowIndex, 1, response.data.model);
        return toUpdate;
      });
    },
    DEBOUNCE_WAIT_TIME
  );

  const handleUnDebouncedUpdate = async (
    value: string | boolean | number,
    isCheckbox: boolean,
    columnName: keyof SalesOrderDto,
    stock: string
  ) => {
    if (!confirmingTableUpdate()) {
      return;
    }

    const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(stock);

    const copiedTargetRow = { ...targetRow };

    const convertedValue = isCheckbox
      ? value
      : convertFromStringIfBooleanOrNumber(
        value as string,
        getDataTypeFromSalesOrderPropertyName(
          columnName as keyof SalesOrderDto
        )
      );

    //@ts-ignore
    copiedTargetRow[columnName] = convertedValue;

    // prop = ;

    // Is debounce 1000ms

    const response = await SalesOrderService.updateSalesOrderFromQueriedTable(
      convertAllNullToEmptyStringFromObject(copiedTargetRow)
    );

    setData((oldData) => {
      const toUpdate = [...oldData];
      toUpdate.splice(targetRowIndex, 1, response.data.model);
      return toUpdate;
    });
  };

  const handleNonDebouncedNoDataModificationUpdateEndpoint = useCallback(
    async (invoiceNumber: string, salesOrderDto: SalesOrderDto) => {
      // prop = ;

      // Is debounce 1000ms

      const response = await SalesOrderService.updateSalesOrderFromQueriedTable(
        convertAllNullToEmptyStringFromObject(salesOrderDto)
      );

      setData((oldData) => {
        const toUpdate = [...oldData];
        const [targetRowIndex, targetRow] =
          getTargetRowOrConsoleError(invoiceNumber);
        toUpdate.splice(targetRowIndex, 1, response.data.model);
        return toUpdate;
      });
    },
    [getTargetRowOrConsoleError, setData]
  );

  const convertDefaultDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  const convertDefaultCheckbox = (checkboxStringValue: string | boolean) =>
    typeof checkboxStringValue === "boolean"
      ? checkboxStringValue
      : checkboxStringValue.toLowerCase() === "true";

  type testtest = { label: string; value: string };
  type OptionType = {
    label: string;
    value: string;
    title: string;
  };

  const [typeTypes, setTypeTypes] = useState<OptionType[]>([]);

  useEffect(() => {
    const handleUpdatingType = async (
      dbManagedType: DbManagedTypes,
      updateFunc: (types: OptionType[]) => unknown
    ) => {
      const typeInteractionDto: TypeInteractionDto = {
        originalName: "",
        newName: "",
        dbManagedType: dbManagedType,
      };

      const response = await TypeService.GetNameList(typeInteractionDto);

      const types = response.data.map((type) => ({
        label: type,
        value: type,
        title: type,
      }));

      updateFunc(types);
    };

    handleUpdatingType(DbManagedTypes.InventoryType, setTypeTypes);
  }, []);

  const handleUpdateType = async (
    value: string | undefined,
    column: keyof SalesOrderDto,
    dbManagedType: DbManagedTypes,
    invoiceNumber: string
  ) => {
    if (isNullOrUndefined(value)) {
      return;
    }

    const [, targetDto] = getTargetRowOrConsoleError(invoiceNumber);

    const copiedTargetDto = { ...targetDto };

    //@ts-ignore
    copiedTargetDto[column] = value;

    const result = await SalesOrderService.updateSalesOrderFromQueriedTable(
      convertAllNullToEmptyStringFromObject(copiedTargetDto)
    );

    setData((oldData) => {
      const targetIndex = oldData.findIndex(
        (dto) => (dto as SalesOrderDto)["Invoice Number"] === invoiceNumber
      );
      if (targetIndex === -1) {
        return oldData;
      }

      const duplicate = [...oldData];
      duplicate.splice(targetIndex, 1, result.data.model);
      return duplicate;
    });
  };

  const tableDivContainerRef = useRef<HTMLDivElement>(null);

  const INPUT_CLASS_NAME = "inventory-table-input-class-name";

  // useEffect(() => {
  //     if (isNullOrUndefined(tableDivContainerRef.current)) {
  //         console.log('table div element is undefined');
  //         return;
  //     }

  //     const tableDiv = tableDivContainerRef.current;

  //     Array.from(tableDiv.getElementsByClassName(INPUT_CLASS_NAME)).forEach((inputEl) => {
  //         // not null bc of filter above.
  //         const notNullInput = inputEl as HTMLInputElement | HTMLSelectElement;
  //         const [invoiceNumber, name] = notNullInput.name.split(':');

  //         const target = (data as SalesOrderDto[]).find((dto) => dto['Invoice Number'] === invoiceNumber);

  //         if (isNullOrUndefined(target)) {
  //             const errorMessage = `Attempting to find row with invoice number ${invoiceNumber} but can't be found! Please alert web developers`;
  //             console.error(errorMessage);
  //             alert(errorMessage);
  //             return;
  //         }

  //         let value = target[name as keyof SalesOrderDto];
  //         if (isNullOrUndefined(value) || value === '0.00') {
  //             value = '';
  //         }

  //         const dataType = getDataTypeFromSalesOrderPropertyName(name as keyof SalesOrderDto);
  //         if (dataType === DataType.DateTime) {
  //             if (value === '') {
  //                 // Will look like dd/mm/yyyy. Want it to be empty. So we'll set color to transparent when not focused
  //                 (inputEl as HTMLInputElement).classList.add(styles.dateInputEmpty);
  //             } else {
  //                 (inputEl as HTMLInputElement).classList.remove(styles.dateInputEmpty);

  //                 value = convertDefaultDate(value as string);
  //             }
  //         }
  //         if (dataType === DataType.Bool) {
  //             value = convertDefaultCheckbox(value as string | boolean);
  //         }

  //         notNullInput.value = value + '';
  //     });
  // }, [data]);

  const employeeList = useRef<{ label: string; name: string }[]>();

  const handleSelectOnChangeUpdatingRows = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    colSize: number
  ) => {
    const textArea = e.target as HTMLTextAreaElement;
    const value = e.target.value;

    const quotient = value.length / colSize;

    const rowsToSet = Math.floor(quotient) + 1;

    if (textArea.rows !== rowsToSet) {
      textArea.rows = rowsToSet;
    }
  };

  const [showCreateCustomerModal, setShowCreateCustomerModal] =
    useState<boolean>(false);
  const [customerIdentifier, setCustomerIdentifier] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [
    invoiceNumberSelectedForCreateCustomer,
    setInvoiceNumberSelectedForCreateCustomer,
  ] = useState<string>();

  type Option = {
    label: string;
    value: string;
  };

  const handleModalClick = async () => {
    if (isNullOrUndefined(invoiceNumberSelectedForCreateCustomer)) {
      console.error(
        "Invoice Number Selected For Create Customer is null but handleModalClick is running!"
      );
      return;
    }
    if (customerName == undefined) {
      alert("You must imput a customer name first!");
      return;
    }

    if (customerIdentifier == undefined) {
      alert("You must input a customer identifier first!");
      return;
    }

    const createCustomerResponse = await CustomerService.CreateCustomer({
      id: 0,
      name: customerName,
      identifier: customerIdentifier,
    });

    const data = createCustomerResponse.data;

    if (isNullOrUndefined(invoiceNumberSelectedForCreateCustomer)) {
      throw new Error(
        "Stock for currently open modal is undefined! Make sure it's set before this"
      );
    }

    // prop = ;

    // Is debounce 1000ms
    const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(
      invoiceNumberSelectedForCreateCustomer
    );

    const copiedTargetRow = { ...targetRow };

    copiedTargetRow["Customer #"] = customerIdentifier;
    copiedTargetRow["Customer Name"] = customerName;

    const updateTableResponse =
      await SalesOrderService.updateSalesOrderFromQueriedTable(
        convertAllNullToEmptyStringFromObject(copiedTargetRow)
      );

    setData((oldData) => {
      const toUpdate = [...oldData];
      const [targetRowIndex, targetRow] = getTargetRowOrConsoleError(
        invoiceNumberSelectedForCreateCustomer
      );

      const copiedTargetRow = { ...targetRow };

      copiedTargetRow["Customer #"] = customerIdentifier;
      copiedTargetRow["Customer Name"] = customerName;
      toUpdate.splice(targetRowIndex, 1, copiedTargetRow);
      setInvoiceNumberSelectedForCreateCustomer(undefined);
      return toUpdate;
    });

    // setSalesOrder((oldROS) => ({
    //     ...oldROS,
    //     'Customer #': data.model.identifier,
    //     'Customer Name': data.model.name,
    // }));

    // For some reason doing two update properties the second one uses
    // Stale information

    // updateSalesOrderProperty('Customer #')(data.model.identifier);
    // updateSalesOrderProperty('Customer Name')(data.model.name);

    setShowCreateCustomerModal(false);
  };

  const roles = useContext(RoleContext);

  const savedFunctionsToUpdateData = {
    "Invoice Number": (
      property: keyof SalesOrderDto,
      InvoiceNumber: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={`${styles.tData} ${styles.invoiceTdContainer}`}
        key={`${invoiceNumber}-${property}`}
        onMouseUp={(e) => {
          if (e.button === 1) {
            if ((e.target as Element).tagName === "A") {
              // should be handled by the anchor tag itself
              return;
            }
            e.preventDefault();
            const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }
        }}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const link = `/sales-order/edit/${IdentifierType.Invoice}/${InvoiceNumber}`;

          if (e.ctrlKey) {
            window.open(link, "_blank");
            e.stopPropagation();
            return;
          }

          if (e.shiftKey) {
            window.open(link);
            e.stopPropagation();
            return;
          }

          history.push({
            pathname: `/sales-order/edit/${IdentifierType.Invoice}/${invoiceNumber}`,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
            } as LocationType,
          });
        }}
      >
        <NavLink
          to={{
            pathname: `/sales-order/edit/${IdentifierType.Invoice}/${invoiceNumber}`,

            state: {
              selectedGlobalFilterNames,
              selectedUserFilterNames,
              globalFiltersStorage,
              userFiltersStorage,
              customFilters,
              ignoredFilterIds,
            } as LocationType,
          }}
        >
          {invoiceNumber}
        </NavLink>
      </td>
    ),
    "Ordered Date": (
      property: keyof SalesOrderDto,
      OrderedDate: string,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${isNullOrUndefined(OrderedDate) ||
              OrderedDate.trim() === ""
              ? styles.dateInputEmpty
              : ""
            }`}
          name={`${invoiceNumber}:${property}`}
          type="date"
          defaultValue={convertDefaultDate(OrderedDate)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              OrderedDate,
              () => (e.target.value = convertDefaultDate(OrderedDate) + "")
            );

            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Docs Signed": (
      property: keyof SalesOrderDto,
      DocsSigned: string,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${isNullOrUndefined(DocsSigned) ||
              DocsSigned.trim() === ""
              ? styles.dateInputEmpty
              : ""
            }`}
          name={`${invoiceNumber}:${property}`}
          type="date"
          defaultValue={convertDefaultDate(DocsSigned)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              DocsSigned,
              () => (e.target.value = convertDefaultDate(DocsSigned) + "")
            );

            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Check Number": (
      property: keyof SalesOrderDto,
      CheckNumber: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (CheckNumber + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${invoiceNumber}:${property}`}
          defaultValue={CheckNumber}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              CheckNumber,
              () => (e.target.value = CheckNumber + "")
            );
          }}
        />
      </td>
    ),
    "Deposit": (
      property: keyof SalesOrderDto,
      Deposit: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (Deposit + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${invoiceNumber}:${property}`}
          defaultValue={Deposit}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              Deposit,
              () => (e.target.value = Deposit + "")
            );
          }}
        />
      </td>
    ),
    "Status": (
      property: keyof SalesOrderDto,
      Status: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <select
          className={INPUT_CLASS_NAME}
          name={`${invoiceNumber}:${property}`}
          disabled={!canUserAccessAdminSecurityWithGivenRoles(roles)}
          onChange={
            (e) => {
              if (!canUserAccessAdminSecurityWithGivenRoles(roles)) {
                // SHouldn't really happen. the disble should prevent this onchange from happening.
                return;
              }

              handleNewUpdaterFunction(
                RecordType.SalesOrder,
                e.target.value,
                property,
                invoiceNumber,
                Status,
                () => (e.target.value = Status + "")
              );
            }
            // handleSelectOrTextAreaUpdateValueProvider(e, 'Condition', stock)
          }
          defaultValue={Status}
        >
          {Object.entries(SalesOrderStatus)
            .filter(([key]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <option key={value} label={key} value={value}>
                {key}
              </option>
            ))}
        </select>
      </td>
    ),
    "Stock Numbers": (
      property: keyof SalesOrderDto,
      StockNumbers: string,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${invoiceNumber}:${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
          readOnly={true}
          defaultValue={StockNumbers}
          cols={NORMAL_COLS_SIZE}
          rows={(StockNumbers + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          onBlur={(e) => {
            handleTextareaUpdateValueProvider(e, property, invoiceNumber);
          }}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
        />
      </td>
    ),
    "Sales Person": (
      property: keyof SalesOrderDto,
      SalesPerson: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <AsyncSelect
          styles={reactSelectStylesForTable()}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          className={styles.actualAsyncSelect}
          value={{
            label: SalesPerson + "",
            value: SalesPerson + "",
          }}
          loadOptions={
            // SearchForEmployee
            isNullOrUndefined(employeeList) || !Array.isArray(employeeList)
              ? SearchForEmployee
              : async () => employeeList
          }
          cacheOptions={true}
          defaultOptions={true}
          onChange={(e) => {
            if (isNullOrUndefined(e)) {
              return;
            }

            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.value,
              property,
              invoiceNumber,
              SalesPerson
            );
            // handleUnDebouncedUpdate(e.value, false, property, invoiceNumber);
          }}
        />
      </td>
    ),
    "Customer Name": (
      property: keyof SalesOrderDto,
      CustomerName: string | number,
      invoiceNumber: string
    ) => {
      const customerName =
        getTargetRowOrConsoleError(invoiceNumber)[1]["Customer Name"];
      return (
        <td className={styles.tData} key={`${invoiceNumber}-${property}`}>
          <AsyncSelect
            // styles={reactSelectStylesForTable()}
            styles={reactSelectStylesForTable()}
            // styles={{container: () => ({})}}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            className={styles.actualAsyncSelect}
            onChange={async (e) => {
              if (!confirmingTableUpdate()) {
                return;
              }
              if (isNullOrUndefined(e)) {
                return;
              }

              if (e.value === "Create Customer") {
                setShowCreateCustomerModal(true);
                setInvoiceNumberSelectedForCreateCustomer(invoiceNumber);
                return;
              }

              const response = await CustomerService.SearchByName(
                new StringDto(e.value + "")
              );

              // If only CreateCustomer is returned don't pass any more data
              if (response.data.model.length == 1) {
                return {};
              }

              const customerName = e.value + "";

              // Grabbing the second in the list because the first one is always
              // the 'Create Customer' Option
              const customerNumber = response.data.model[1].identifier ?? "";

              const [targetRowIndex, targetRow] =
                getTargetRowOrConsoleError(invoiceNumber);
              const copiedTargetRow = { ...targetRow };

              copiedTargetRow["Customer Name"] = customerName;
              copiedTargetRow["Customer #"] = customerNumber;

              // setTimeout(() => {
              //     if (isNotNullNorUndefined(customerNameRef.current)) {
              //         customerNameRef.current.setValue(
              //             { label: customerName, value: customerName },
              //             'select-option'
              //         );
              //     }
              // }, 1000);

              handleNonDebouncedNoDataModificationUpdateEndpoint(
                invoiceNumber,
                copiedTargetRow
              );
            }}
            loadOptions={SearchForCustomer}
            // defaultValue={{ value: StartingCustomerName + '', label: StartingCustomerName + '' }}
            value={{ label: CustomerName + "", value: CustomerName + "" }}
            // value={{ label: customerName, value: customerName } ?? undefined}
            // cacheOptions
            closeMenuOnSelect
            placeholder={"Search by Customer Name"}
          />
        </td>
      );
    },
    "Customer #": (
      property: keyof SalesOrderDto,
      CustomerNumber: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          readOnly={true}
          style={{ width: (CustomerNumber + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${invoiceNumber}:${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
          value={CustomerNumber}
        />
      </td>
    ),
    "Title Work Completed": (
      property: keyof SalesOrderDto,
      TitleWorkCompleted: string,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${isNullOrUndefined(TitleWorkCompleted) ||
              TitleWorkCompleted.trim() === ""
              ? styles.dateInputEmpty
              : ""
            }`}
          name={`${invoiceNumber}:${property}`}
          type="date"
          defaultValue={convertDefaultDate(TitleWorkCompleted)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              TitleWorkCompleted,
              () =>
                (e.target.value = convertDefaultDate(TitleWorkCompleted) + "")
            );

            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "NAPP": (
      property: keyof SalesOrderDto,
      NAPP: string | boolean,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (NAPP + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${invoiceNumber}:${property}`}
          type="checkbox"
          checked={convertDefaultCheckbox(NAPP)}
          onChange={(e) =>
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              !cTB(NAPP),
              property,
              invoiceNumber,
              cTB(NAPP)
            )
          }
        />
      </td>
    ),
    "Lienholder": (
      property: keyof SalesOrderDto,
      Lienholder: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${invoiceNumber}:${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
          cols={NORMAL_COLS_SIZE}
          rows={(Lienholder + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Lienholder}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              Lienholder,
              () => (e.target.value = Lienholder + "")
            );
          }}
        />
      </td>
    ),
    "Notes": (
      property: keyof SalesOrderDto,
      Notes: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${invoiceNumber}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(Notes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={Notes}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              Notes,
              () => (e.target.value = Notes + "")
            );
          }}
        />
      </td>
    ),
    "Misc Tracking": (
      property: keyof SalesOrderDto,
      MiscTracking: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${invoiceNumber}:${property}`}
          cols={NORMAL_COLS_SIZE}
          rows={(MiscTracking + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={MiscTracking}
          disabled={true}
        />
      </td>
    ),
    "Proof Of Payment Received": (
      property: keyof SalesOrderDto,
      ProofOfPaymentReceived: string,
      invoiceNumber: string
    ) => (
      <td className={styles.tData} key={`${invoiceNumber}-${property}`}>
        <input
          className={`${INPUT_CLASS_NAME} 
                            ${isNullOrUndefined(ProofOfPaymentReceived) ||
              ProofOfPaymentReceived.trim() === ""
              ? styles.dateInputEmpty
              : ""
            }`}
          name={`${invoiceNumber}:${property}`}
          type="date"
          defaultValue={convertDefaultDate(ProofOfPaymentReceived)}
          onFocus={(e) => (e.target.style.opacity = "1")}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              ProofOfPaymentReceived,
              () =>
              (e.target.value =
                convertDefaultDate(ProofOfPaymentReceived) + "")
            );

            if (
              e.target.value.trim() === "" ||
              isNullOrUndefined(e.target.value)
            ) {
              e.target.style.opacity = "0";
            }
          }}
        />
      </td>
    ),
    "Back Office Notes": (
      property: keyof SalesOrderDto,
      BackOfficeNotes: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <textarea
          name={`${invoiceNumber}:${property}`}
          onClick={(e) => {
            const tableDataEl = e.target as HTMLTableCellElement;
            if (tableDataEl.tagName !== "TD") {
              return;
            }

            const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

            inputBelow.focus();
          }}
          cols={NORMAL_COLS_SIZE}
          rows={(BackOfficeNotes + "").length < NORMAL_COLS_SIZE ? 1 : 2}
          className={`${INPUT_CLASS_NAME} ${styles.textArea}`}
          onChange={(e) =>
            handleSelectOnChangeUpdatingRows(e, NORMAL_COLS_SIZE)
          }
          defaultValue={BackOfficeNotes}
          onBlur={(e) => {
            handleNewUpdaterFunction(
              RecordType.SalesOrder,
              e.target.value,
              property,
              invoiceNumber,
              BackOfficeNotes,
              () => (e.target.value = BackOfficeNotes + "")
            );
          }}
        />
      </td>
    ),
    "Split Deal": (
      property: keyof SalesOrderDto,
      SplitDeal: string | boolean,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {(() => {
          // const hasAccess = canUserAccessAdminSecurityWithGivenRoles(roles);
          return (
            <input
              // className={`${INPUT_CLASS_NAME} ${hasAccess ? '' : styles.testAllDelivered}`}
              readOnly={true}
              className={`${INPUT_CLASS_NAME}`}
              name={`${invoiceNumber}:${property}`}
              type="checkbox"
              checked={convertDefaultCheckbox(SplitDeal)}
              onChange={(e) =>
                handleNewUpdaterFunction(
                  RecordType.SalesOrder,
                  !cTB(SplitDeal),
                  property,
                  invoiceNumber,
                  cTB(SplitDeal)
                )
              }
            />
          );
        })()}
      </td>
    ),
    "All Delivered": (
      property: keyof SalesOrderDto,
      AllDelivered: string | boolean,
      invoiceNumber: string
    ) => (
      <td
        className={`${styles.tData}`}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        {(() => {
          const hasAccess = canUserAccessAdminSecurityWithGivenRoles(roles);

          return (
            <input
              disabled={true}
              // readOnly={true}
              // disabled={true}
              className={`${INPUT_CLASS_NAME} ${styles.testAllDelivered
                // + ' ' +
                // (convertDefaultCheckbox(AllDelivered) ? styles.trueCheckbox : styles.falseCheckbox)
                }`}
              name={`${invoiceNumber}:${property}`}
              type="checkbox"
              defaultChecked={convertDefaultCheckbox(AllDelivered)}
            />
          );
        })()}
      </td>
    ),

    "Split Sales Person": (
      property: keyof SalesOrderDto,
      SplitSalesPerson: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <>
          <AsyncSelect
            styles={reactSelectStylesForTable()}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            className={styles.actualAsyncSelect}
            value={{
              label: SplitSalesPerson,
              value: SplitSalesPerson,
            }}
            loadOptions={
              // SearchForEmployee
              isNullOrUndefined(employeeList) || !Array.isArray(employeeList)
                ? SearchForEmployee
                : async () => employeeList
            }
            cacheOptions={true}
            defaultOptions={true}
            onChange={(e) => {
              if (isNullOrUndefined(e)) {
                return;
              }

              handleNewUpdaterFunction(
                RecordType.SalesOrder,
                e.value,
                property,
                invoiceNumber,
                SplitSalesPerson
              );

              // handleUnDebouncedUpdate(e.value, false, property, invoiceNumber);
            }}
          />
        </>
      </td>
    ),
    "Delivered Count": (
      property: keyof SalesOrderDto,
      DeliveredCount: string | number,
      invoiceNumber: string
    ) => (
      <td
        className={styles.tData}
        key={`${invoiceNumber}-${property}`}
        onClick={(e) => {
          const tableDataEl = e.target as HTMLTableCellElement;
          if (tableDataEl.tagName !== "TD") {
            return;
          }

          const inputBelow = tableDataEl.childNodes[0] as HTMLInputElement;

          inputBelow.focus();
        }}
      >
        <input
          style={{ width: (DeliveredCount + "").length + CH_OFFSET + "ch" }}
          className={INPUT_CLASS_NAME}
          name={`${invoiceNumber}:${property}`}
          readOnly={true}
          defaultValue={DeliveredCount}
        />
      </td>
    ),
  };
  const runner = (dtoData: SalesOrderDto): React.ReactElement[] => {
    if (isNullOrUndefined(computedCombinedFilters)) {
      return [<></>];
    }

    const updatedValues = computedCombinedFilters.orderedColumnsToShow
      .filter(
        (column) =>
          !isSalesOrderPropertyExcluded(column as keyof SalesOrderDto) &&
          isNotNullNorUndefined(column) &&
          column !== ""
      )
      .filter(filterSalesOrderColumnMethod)
      .map((column) => {
        let value = dtoData[column as keyof SalesOrderDto];
        if (isNullOrUndefined(value)) {
          value = "";
        }
        const target =
          savedFunctionsToUpdateData[
          column as keyof typeof savedFunctionsToUpdateData
          ];

        if (typeof target !== "function" || isNullOrUndefined(target)) {
          console.error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
          throw new Error(
            `Attempting to access: ${column} in Dto but doesn't exist!`
          );
        }

        if (typeof value === "boolean") {
          // if boolean value converting to string
          value = value + "";
        }

        const result = target(
          column as keyof SalesOrderDto,
          value as string,
          dtoData["Invoice Number"]
        );
        return result;
      })
      .filter((column) => isNotNullNorUndefined(column));

    const withSelectionColumn = [
      <td
        className={`${selectingRowMode === "unselected" ? styles.selectionColumnHidden : ""
          } ${styles.tdSelectionColumn}`}
        key="selection-column"
        onClick={(e) => {
          if ((e.target as Element).tagName !== "TD") {
            return;
          }
          const tdElement = e.target as HTMLTableCellElement;

          const inputBelow = tdElement.childNodes[0] as HTMLInputElement;
          if (isRowIdSelected(dtoData["Invoice Number"])) {
            unselectRow(dtoData["Invoice Number"]);
          } else {
            registerSelection(dtoData["Invoice Number"]);
          }
          inputBelow.focus();
        }}
      >
        <input
          type="checkbox"
          style={{ transform: "scale(1.5)" }}
          checked={isRowIdSelected(dtoData["Invoice Number"])}
          onChange={(e) => {
            if (e.target.checked) {
              registerSelection(dtoData["Invoice Number"]);
            } else {
              unselectRow(dtoData["Invoice Number"]);
            }
          }}
        />
      </td>,
      ...updatedValues,
    ];

    return withSelectionColumn as JSX.Element[];
  };

  // const test = (dataDto: InventoryDto) => {
  //     if (isNullOrUndefined(savedGeneratedGeneralOptions.current)) {
  //         return;
  //     }
  //     const columnsToShowList = splitByComma(savedGeneratedGeneralOptions.current.columnsToShow);

  //     const tableDataList = columnsToShowList.map((column) => {
  //         return <td className={styles.tData} key={column}>{dataDto[column as keyof InventoryDto]}</td>;
  //     });

  //     return tableDataList;
  // };

  // const iteration = () => {
  //     if (isNullOrUndefined(savedGeneratedGeneralOptions.current)) {
  //         return;
  //     }

  //     for (let i = 0; i < savedGeneratedGeneralOptions.current.sortOptions.itemsPerPage ?? 50; i++) {
  //         const inventoryDto = data[i];
  //         test(inventoryDto);
  //     }
  // };

  useEffect(() => {
    const ionElement = document.getElementById(
      ION_SPLIT_PANE_STATE_CONTAINER_ROUTER_OUTLET_ID
    );

    if (isNullOrUndefined(ionElement)) {
      console.error("ION ELEENT IS NULL RETURNING");
      return;
    }

    const handler = function() {
      const nonTableParts = Array.from(
        document.getElementsByClassName("nonTableParts")
      );
      if (isNullOrUndefined(nonTableParts) || !Array.isArray(nonTableParts)) {
        console.error("Non table part IS NULL! RETURNING");
        return;
      }
      nonTableParts.forEach((part) => {
        (part as HTMLDivElement).style.left = ionElement.scrollLeft + "px";
      });
    };

    ionElement.addEventListener("scroll", () => {
      window.requestAnimationFrame(handler);
    });
  }, []);

  const nonTablePart = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // calls the endpoint initially
    // handleEndpointCall({
    //     endpoint: endpoint,
    //     generalOptions: generalOptions,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderedOnce = useRef<boolean>(false);

  const exportEnumProcessor = (salesOrder: SalesOrderDto) => {
    const updatedObj: any = { ...salesOrder };

    EnumSalesOrderProperties.forEach((property) => {
      const targetEnum = getEnumFromSalesOrderDtoPropertyName(property);
      if (isNullOrUndefined(targetEnum)) {
        console.error(
          "Target enum is undfined/null when trying to process enum values!"
        );
        return;
      }

      const value = salesOrder[property];

      updatedObj[property] = targetEnum[value as number];
    });

    return updatedObj;
  };

  const convertLongDateToShortDate = (dateStringValue: string) => {
    if (dateStringValue === "") {
      return dateStringValue;
    }
    const date = new Date(dateStringValue);
    const formattedDate = date.toLocaleDateString();
    return formattedDate;
  };

  const printProcessor = (inventoryDto: SalesOrderDto) => {
    const updatedObj: any = { ...inventoryDto };

    Object.entries(inventoryDto).forEach(([property, value]) => {
      const dataType = getDataTypeFromSalesOrderPropertyName(
        property as keyof SalesOrderDto
      );

      switch (dataType) {
        case DataType.DateTime: {
          updatedObj[property as keyof SalesOrderDto] =
            convertLongDateToShortDate(value);
          break;
        }
        case DataType.Enum: {
          const targetEnum = getEnumFromSalesOrderDtoPropertyName(
            property as keyof SalesOrderDto
          );
          if (isNullOrUndefined(targetEnum)) {
            console.error(
              "Target Enum was not found. Please add definition in getEnumFromInventoryDtoPropertyName"
            );
            return;
          }

          updatedObj[property as keyof SalesOrderDto] = targetEnum[value];
        }
      }
    });
    return updatedObj as SalesOrderDto;
  };

  return (
    <div
      className={styles.SalesOrderTableContainer}
      data-testid="SalesOrderTable"
    >
      <Layout className={`${styles.nonTablePart} nonTableParts`}>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          // id="non-table-part-of-page"
          id="header"
          ref={nonTablePart}
        >
          {tabbed && (
            <QueriedTableTabList
              handleGlobalFilterButtonClick={handleGlobalFilterButtonClick}
              isFilterSelected={isFilterSelected}
              computedCombinedFilters={computedCombinedFilters}
              handleUserFilterButtonClick={handleUserFilterButtonClick}
              userFiltersStorage={userFiltersStorage}
              globalFiltersStorage={globalFiltersStorage}
            />
          )}
          <QueriedTableToolbar<SalesOrderDto>
            endpoint={endpoint}
            handleCreateUpdateUserFilter={handleCreateUpdateUserFilter}
            handleUserFilterButtonClick={handleUserFilterButtonClick}
            model={model}
            useCustomEndpoint={useCustomEndpoint}
            setUseCustomEndpoint={setUseCustomEndpoint}
            recordType={recordType}
            isFilterSelected={isFilterSelected}
            handleCreateUpdateGlobalFilter={handleCreateUpdateGlobalFilter}
            handleDeleteGlobalFilter={handleDeleteGlobalFilter}
            handleDeleteUserFilter={handleDeleteUserFilter}
            setOverwritingProvidedData={setOverwritingProvidedData}
            dtoType={dtoType}
            handleClear={handleClearCancel}
            data={data as SalesOrderDto[]}
            computedCombinedFilters={computedCombinedFilters}
            handleUpdatingItemsPerPage={handleUpdatingItemsPerPage}
            setSortFilters={setSortFilters}
            handleSetCustomFiltersValueFilters={
              handleSetCustomFiltersValueFilters
            }
            unhidableColumns={["Invoice Number"]}
            setColumnVisibility={setColumnVisibility}
            fieldsThatAreCustomTypes={{}}
            exportEnumProcessor={exportEnumProcessor}
            enumProperties={EnumSalesOrderProperties}
            printProcessor={printProcessor}
            getAsyncSelectGetMethodFromPropertyName={getAsyncSelectGetMethodFromSalesOrderPropertyName.bind(
              null,
              true
            )}
            filterKeyArrayByExcludedColumns={
              filterSalesOrderKeyArrayByExcludedColumns
            }
            selectedUserFilterNames={selectedUserFilterNames}
            selectedGlobalFilterNames={selectedGlobalFilterNames}
            computedSelectedGlobalFilters={computedSelectedGlobalFilters}
            computedSelectedUserFilters={computedSelectedUserFilters}
            customFilters={customFilters}
            unselectGlobalFilter={unselectGlobalFilter}
            unselectUserFilter={unselectGlobalFilter}
            ignoreFilter={ignoreFilter}
            unignoreFilter={unignoreFilter}
            isFilterIgnored={isFilterIgnored}
            combinedFilterTargetProperties={combinedFilterTargetProperties}
            updateSearchTerm={updateSearchTerm}
            globalFilterStorage={globalFiltersStorage}
            userFilterStorage={userFiltersStorage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>

        <div
          className={`${styles.itemsPerPageAndCopyTableContainer} ${styles.nonTablePart} nonTableParts`}
        >
          <div className={`${styles.totalItemsSpan} `}>
            <span>
              Total Items: {computedCombinedFilters.sortOptions.totalItems}
            </span>
          </div>
          <CopyTableStylesButtons<SalesOrderDto>
            selectingRowMode={selectingRowMode}
            unselectAllRows={unselectAllRows}
            selectAllRows={selectAllRows}
            cancelSelecting={cancelSelecting}
            stopSelectingAndCopyTableWithStyles={
              stopSelectingAndCopyTableWithStyles
            }
            stopSelectingAndEmailAdvertisements={
              stopSelectingAndEmailAdvertisements
            }
            startSelecting={startSelecting}
            data={data as SalesOrderDto[]}
            identifierProperty={"Invoice Number"}
            inventoryPage={false}
          />
        </div>
        <div ref={tableDivContainerRef} style={{ marginTop: "1rem" }}>
          <table className={styles.table}>{handleGenerateTableParts()}</table>
        </div>
        <div
          className={`${styles.nonTablePart} nonTableParts`}
          style={{ marginBottom: "1rem" }}
        >
          <QueriedTablePaginationOptions
            computedCombinedFilters={computedCombinedFilters}
            updateCurrentPageNumber={updateCurrentPageNumber}
            setIsLoading={setIsLoading}
          />
        </div>
        <IonModal
          isOpen={showCreateCustomerModal}
          onIonModalDidDismiss={() => setShowCreateCustomerModal(false)}
        >
          <IonItem>
            <IonLabel>Customer Identifier</IonLabel>
            <IonInput
              value={customerIdentifier}
              onIonChange={(e) => {
                if (e.detail.value == undefined) {
                  return;
                }

                setCustomerIdentifier(e.detail.value);
              }}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Customer Name</IonLabel>
            <IonInput
              value={customerName}
              onIonChange={(e) => {
                if (e.detail.value == undefined) {
                  return;
                }

                setCustomerName(e.detail.value);
              }}
            />
          </IonItem>

          <IonButton type="submit" onClick={() => handleModalClick()}>
            Submit Button
          </IonButton>
        </IonModal>
        {/* 
                <Card
                    endpoint={() => MetricService.getMetrics(RecordType.SalesOrder)}
                    icon={CardIconTypes.Square}
                    hideOnTabletSize={true}
                />
        */}
      </Layout>
    </div>
  );
};

export default SalesOrderTable;
