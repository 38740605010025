import { GeneratedIdType } from '../../../utils/KeyIdUtils';
import { FilterType } from '../../Global';
import { GetGlobalFilterType } from '../GetGlobalFilterDto';
import { DynamicFilter } from './DynamicFilter';
import { DynamicFilterWithSourceInfo } from './DynamicFilterWithSourceInfo';
import { GeneralOptions } from './GeneralOptions';
import { SortOptionsWithoutPaginationItems } from './SortOptionsWithoutPaginationItems';

export class UserGlobalFilterSpecificGeneralOption {
    filterOptions:
        | DynamicFilter[]
        | GeneratedIdType<DynamicFilter>[]
        | DynamicFilterWithSourceInfo[]
        | GeneratedIdType<DynamicFilterWithSourceInfo>[] = [];
    sortOptions: SortOptionsWithoutPaginationItems = new SortOptionsWithoutPaginationItems();
    columnsToShow = '';
    columnOrder = '';
    filterType: FilterType = FilterType.Custom;
    // isFilterUpdate = false; // not on global/user
    filterName = '';
    globalFilterRow?: GetGlobalFilterType;
    // searchTerm = ''; // not on global/user

    constructor(obj?: GeneralOptions, globalFilterRow?: GetGlobalFilterType) {
        this.filterOptions = obj?.filterOptions ? obj.filterOptions : [];
        this.sortOptions = obj?.sortOptions ? obj.sortOptions : new SortOptionsWithoutPaginationItems();
        this.columnOrder = obj?.columnOrder ? obj.columnOrder : '';
        this.columnsToShow = obj?.columnsToShow ? obj.columnsToShow : '';
        this.filterType = obj?.filterType ? obj.filterType : FilterType.Custom;
        // this.isFilterUpdate = obj?.isFilterUpdate ? obj.isFilterUpdate : false;
        this.filterName = obj?.filterName ? obj.filterName : '';
        this.globalFilterRow = globalFilterRow;

        // this.searchTerm = obj?.searchTerm ? obj.searchTerm : '';
    }
}
