import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/react';
import cloneDeep from 'lodash.clonedeep';
import { useState } from 'react';
import { GeneralOptionsColumnsToShowAndColumnOrderAsArray } from '../../../../../../models/Dtos/OptionsDtos/GeneralOptions';
import { isNullOrUndefined } from '../../../../../../utils/NullOrUndefined';
import styles from './ColumnVisibilityManager.module.scss';

interface ColumnVisibilityManagerProps<T extends object> {
    computedCombinedFilters: GeneralOptionsColumnsToShowAndColumnOrderAsArray;
    unhidableColumns: string[];
    closeModal: () => void;
    setColumnVisibility: (updatedColumnsToShowList: string[]) => void;
    data: T[] | undefined;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ColumnVisibilityManager = <T extends object>({
    setColumnVisibility,
    computedCombinedFilters,
    unhidableColumns,
    closeModal,
    data,
    setIsLoading,
}: ColumnVisibilityManagerProps<T>) => {
    const [internalShownColumns, setInternalShownColumns] = useState<string[]>(
        computedCombinedFilters.orderedColumnsToShow
    );

    const handleInvertStatusOfColumn = (columnName: string, hideOrShow: 'Hide' | 'Show') => {
        if (hideOrShow === 'Hide') {
            setInternalShownColumns((oldList) => oldList.filter((item) => item !== columnName));
        } else {
            setInternalShownColumns((oldList) => [...oldList, columnName]);
        }
        // setShouldCallQueriedTableEndpoint(true);
    };

    const handleSubmit = () => {
        setColumnVisibility(internalShownColumns);
        // internalShownColumns.forEach((item) => updateColumnVisible(item, true));
        closeModal();
        setIsLoading(true);
    };

    const dataUndefined = isNullOrUndefined(data);

    // Need to find different way to get default columns.
    const columnOrder = computedCombinedFilters.columnOrder;

    const filteredColumnOrder = columnOrder;
    unhidableColumns.forEach((column) => {
        if (!filteredColumnOrder.includes(column)) {
            filteredColumnOrder.push(column);
        }
    });

    const handleShowAll = () => {
        setInternalShownColumns(cloneDeep(filteredColumnOrder).sort());
    };

    const handleHideAll = () => {
        setInternalShownColumns([...unhidableColumns]);
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className={styles.ColumnVisibilityManagerContainer} data-testid="ColumnVisibilityManager">
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <h1 className={styles.header}>Column Name</h1>
                        </IonCol>
                        <IonCol>
                            <h1>Visibility</h1>
                        </IonCol>
                    </IonRow>
                    <IonRow style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        <IonCol>
                            <IonButton onClick={() => handleShowAll()}>Show All</IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => handleHideAll()}>Hide All</IonButton>
                        </IonCol>
                    </IonRow>
                    {/* DON'T HAVE ORDER OF COLUMNS SET UP YET. Using default columns to show. */}
                    {cloneDeep(filteredColumnOrder)
                        .sort()
                        .map((columnName) => {
                            //TODO: COME BACK AND MAKE THIS DYNAIMC
                            const isCurrentColumnVisible = internalShownColumns.includes(columnName);

                            return (
                                <IonRow key={columnName}>
                                    <IonCol>
                                        <p>{columnName}</p>
                                    </IonCol>
                                    <IonCol>
                                        <p className={isCurrentColumnVisible ? styles.shownLabel : styles.hiddenLabel}>
                                            {unhidableColumns.includes(columnName)
                                                ? 'Always Shown'
                                                : isCurrentColumnVisible
                                                ? 'Shown'
                                                : 'Hidden'}
                                        </p>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton
                                            disabled={unhidableColumns.includes(columnName)}
                                            onClick={() => {
                                                handleInvertStatusOfColumn(
                                                    columnName,
                                                    isCurrentColumnVisible ? 'Hide' : 'Show'
                                                );
                                            }}>
                                            {isCurrentColumnVisible ? 'Hide' : 'Show'}
                                        </IonButton>
                                    </IonCol>
                                </IonRow>
                            );
                        })}
                    <IonRow style={{ paddingBottom: '5rem' }}>
                        <IonCol>
                            <IonButton
                                onClick={() => {
                                    handleSubmit();
                                }}>
                                Submit
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    display: 'flex',
                    width: '100vw',
                    justifyContent: 'center',
                    paddingTop: '.5rem',
                    backgroundColor: 'white',
                }}>
                <IonButton
                    onClick={() => {
                        handleSubmit();
                    }}>
                    Submit
                </IonButton>
            </div>
        </div>
    );
};

export default ColumnVisibilityManager;
