import uniqid from 'uniqid';
import { isNullOrUndefined } from './NullOrUndefined';

export const KEYED_TYPE_IDENTIFIER_PROPERTY_NAME = 'UT-Key-Unique-Identifier';

export type GeneratedIdType<T extends object> = T & {
    [KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]: string;
};

export const addGeneratedIdIfNoIdOrAddSpecifiedId = <T extends object>(
    obj: T,
    specifiedId?: string
): GeneratedIdType<T> => {
    if (doesObjectHaveGeneratedId(obj) && isNullOrUndefined(specifiedId)) {
        // Since it already has id, assuming because the ID is very specific it's been added by us
        // Thus it should have all of the properties defined below
        return obj;
    }
    const objCopy = { ...obj };

    // old configuratoin. Didn't work because cloning doesn't copy non-enumberal fields.
    // Object.defineProperty(objCopy, KEYED_TYPE_IDENTIFIER_PROPERTY_NAME, {
    //     value: specifiedId ?? uniqid.process(),
    //     configurable: true,
    //     enumerable: false,
    //     writable: false,
    // });

    Object.defineProperty(objCopy, KEYED_TYPE_IDENTIFIER_PROPERTY_NAME, {
        value: specifiedId ?? uniqid.process(),
        configurable: true,
        enumerable: true,
        writable: false,
    });

    return objCopy as GeneratedIdType<T>;
};

export const removeGeneratedId = <T extends object>(obj: GeneratedIdType<T>): T => {
    const { [KEYED_TYPE_IDENTIFIER_PROPERTY_NAME]: _, ...rest } = obj;
    return rest as T;
};

export const doesObjectHaveGeneratedId = <T extends object>(obj: T): obj is GeneratedIdType<T> =>
    KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in obj;

export const addGeneratedIdIfNoIdOrAddSpecifiedIdToAllObjectsInArray = <T extends object>(objArray: T[]) =>
    objArray.map((obj) => addGeneratedIdIfNoIdOrAddSpecifiedId(obj));

export const getIdIfItExistsOrReturnsNull = <T extends object>(object: T) => {
    if (KEYED_TYPE_IDENTIFIER_PROPERTY_NAME in object) {
        // it's in object. let's get it.
        return object[KEYED_TYPE_IDENTIFIER_PROPERTY_NAME] as string;
    }

    return null;
};
