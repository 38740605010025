import { RecordType } from '../models/Global';
import { filterAccountingTodoKeyArrayByExcludedColumns } from './ColumnsToShowFilterByDtoType/AccountingTodoColumnsToShowFilter';
import { filterDeliveryKeyArrayByExcludedColumns } from './ColumnsToShowFilterByDtoType/DeliveryColumnsToShowFilters';
import { filterInventoryKeyArrayByExcludedColumns } from './ColumnsToShowFilterByDtoType/InventoryColumnsToShowFilter';
import { filterSalesOrderKeyArrayByExcludedColumns } from './ColumnsToShowFilterByDtoType/SalesOrderColumnsToShowFilter';
import { filterYardMapKeyArrayByExcludedColumns } from './ColumnsToShowFilterByDtoType/YardMapColumnToShowFilters';
export const getColumnsToIgnoreFromRecordType = (recordType: RecordType): ((array: unknown) => string[]) => {
    switch (recordType) {
        case RecordType.Inventory: {
            return filterInventoryKeyArrayByExcludedColumns as (array: unknown) => string[];
        }
        case RecordType.SalesOrder: {
            return filterSalesOrderKeyArrayByExcludedColumns as (array: unknown) => string[];
        }

        case RecordType.Delivery: {
            return filterDeliveryKeyArrayByExcludedColumns as (array: unknown) => string[];
        }

        case RecordType.YardMap: {
            return filterYardMapKeyArrayByExcludedColumns as (array: unknown) => string[];
        }
        case RecordType.AccountingTodo: {
            return filterAccountingTodoKeyArrayByExcludedColumns as (array: unknown) => string[];
        }

        case RecordType.RecordOfTransfer: {
            return (array: unknown) => array as string[];
        }
    }
};
