import { IonInputCustomEvent, IonSelectCustomEvent } from '@ionic/core';
import { IonCheckboxCustomEvent } from '@ionic/core/dist/types/components';
import { CheckboxChangeEventDetail, SelectChangeEventDetail } from '@ionic/react';
import { ChangeEvent, Context, useContext, useDebugValue, useState } from 'react';
import { SingleValue } from 'react-select';
import uniqid from 'uniqid';
import { generateDateStringFromDifferentFormatDateString } from '../utils/DateUtils';
import { isDateValidAndHandleIfNot } from '../utils/MinMaxUtil';
import NestedKeyOf from '../utils/NestedKeyOf';
import { isNullOrUndefined } from '../utils/NullOrUndefined';
import styles from './ObjectHook.module.scss';

type OptionType = { [key: string]: any };

export type EventTargetType = { target: { name: string; value?: any } };
export type CheckBoxEventTargetType = EventTargetType & { target: { checked: boolean } };

interface WithPathParameterType<T extends object> {
    path: NestedKeyOf<T>;
    pathCorrespondingArrayAccessIndicesArray: number[];
}

type WithPropertyNameParameterTypes<T extends object> = keyof T;

export type PossibleParameterTypes<T extends object> = WithPathParameterType<T> | WithPropertyNameParameterTypes<T>;

export const useObject = <T extends object>(initialValue: T) => {
    const [value, setValue] = useState<T>(initialValue);

    useDebugValue(value);

    return {
        value,
        setValue,
        bindSelectProperty: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (newValue: SingleValue<string> | SingleValue<number>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    newValue,
                })),
        }),
        bindIonInputProperty: <ValueType>(
            pathOrProperty: PossibleParameterTypes<T>,
            validationCallBack?: (newValue: ValueType) => void
        ) => {
            //@ts-ignore
            const result = getValueWithPathOrProperty(pathOrProperty, value);
            const currentValue: any = result == undefined ? undefined : result[1];
            const id = uniqid();
            return {
                onIonInput: <Type extends EventTargetType>(event: Type) => {
                    // const targetEl = document.getElementById(id) as HTMLIonInputElement | undefined;
                    // if (isNullOrUndefined(targetEl)) {
                    //     console.log('TARGET EL IS UNDEFINED!');
                    //     return;
                    // }

                    // const targetElValue = targetEl.value;
                    // console.log('TARGET EL VALUE: ' + targetElValue);

                    // const value = event.target.value;
                    // //@ts-ignore
                    // const currentTargetValue = event.currentTarget.value;
                    const parsedValue =
                        typeof currentValue === 'number'
                            ? event.target.value === ''
                                ? ''
                                : parseInt(event.target.value)
                            : event.target.value;

                    if (validationCallBack !== undefined && validationCallBack !== null) {
                        validationCallBack(parsedValue);
                    }
                    //@ts-ignore
                    return updateValueWithPathOrProperty(setValue, pathOrProperty, value, parsedValue);
                },
                value: currentValue,
                // placeholder: currentValue,
                id: id,
            };
        },
        // bindIonTextAreaProperty: <K extends keyof T>(property: K) => ({
        //     value: value[property],
        //     name: property,
        //     onIonBlur: <Type extends EventTargetType>(event: Type) => {
        //         setValue({
        //             ...value,
        //             [event.target.name]: event.target.value,
        //         });
        //     },
        // }),
        bindIonSelectProperty: (
            pathOrProperty: PossibleParameterTypes<T>,
            callBack?: (value: any) => { [k: string | number | symbol]: any }
        ) => {
            //@ts-ignore
            const result = getValueWithPathOrProperty(pathOrProperty, value);
            const currentValue: any = result == undefined ? undefined : result[1];
            return {
                value: currentValue,
                onIonChange: (event: IonSelectCustomEvent<SelectChangeEventDetail<any>>) => {
                    if (callBack) {
                        const valuesToUpdate = callBack(event.detail.value);

                        // Going to assume this implemntation will work.

                        setValue((oldValue) => {
                            return { ...oldValue, valuesToUpdate };
                        });

                        updateValueWithPathOrProperty(setValue, pathOrProperty, value, event.detail.value);
                    } else {
                        updateValueWithPathOrProperty(setValue, pathOrProperty, value, event.detail.value);
                    }
                },
            };
        },
        bindIonSelectMultipleProperty: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onIonChange: (event: IonSelectCustomEvent<SelectChangeEventDetail<any>>) => {
                const options = event.detail.value as keyof T;

                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: options,
                }));
            },
        }),
        bindIonTextAreaProperty: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onIonBlur: <Type extends EventTargetType>(event: Type) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]:
                        // @ts-ignore
                        typeof value[property] === 'number' ? parseInt(event.target.value) || 0 : event.target.value,
                })),
        }),
        // bindIonInputPropertyDecimals: <K extends keyof T>(property: K) => ({
        //     value: value[property],
        //     name: property,
        //     onChange: (event: ChangeEvent<HTMLInputElement>) =>
        //         setValue({
        //             ...value,
        //             [event.target.name]:
        //                 // @ts-ignore
        //                 typeof value[property] === 'number' ? parseInt(event.target.value) || 0 : event.target.value,
        //         }),
        // }),
        bindIonSelectPropertyDecimals: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onIonBlur: <Type extends EventTargetType>(event: Type) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]:
                        // @ts-ignore
                        typeof value[property] === 'number' ? parseInt(event.target.value) || 0 : event.target.value,
                })),
        }),
        bindIonTextAreaPropertyDecimals: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onIonBlur: <Type extends EventTargetType>(event: Type) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]:
                        typeof value[property] === 'number' ? parseInt(event.target.value!) || 0 : event.target.value,
                })),
        }),
        // needs testing and HTMLIonInputElement may not be correct
        bindIonPropertyCheck: (pathOrProperty: PossibleParameterTypes<T>) => {
            //@ts-ignore
            const result = getValueWithPathOrProperty(pathOrProperty, value);
            const currentValue: any = result == undefined ? undefined : result[1];
            return {
                checked: !!currentValue ? true : false,
                onIonChange: (e: IonCheckboxCustomEvent<CheckboxChangeEventDetail<any>>) => {
                    updateValueWithPathOrProperty(setValue, pathOrProperty, value, e.target.checked);

                    return;
                },
                // ...handleAccurateOnBlurForCheckBoxes((newCheckedValue: boolean) => {
                //     // setValue((oldValue) => ({ ...oldValue, [pathOrProperty]: newCheckedValue }));

                //     updateValueWithPathOrProperty(setValue, pathOrProperty, value, newCheckedValue);
                // }),
            };
        },
        bindIonPropertyDate: (pathOrProperty: PossibleParameterTypes<T>) => {
            //@ts-ignore
            const result = getValueWithPathOrProperty(pathOrProperty, value);
            const currentValue: any = result == undefined ? undefined : result[1];

            let processingValue: any = currentValue;
            if (typeof processingValue === 'string' && processingValue != '' && processingValue.includes('T')) {
                // Debugging INformation
                try {
                    processingValue = generateDateStringFromDifferentFormatDateString(processingValue as string);
                } catch {
                    console.error(`SALES ORDER DATE ISSUE FOR ${JSON.stringify(pathOrProperty)}`);
                }
            }

            return {
                value: processingValue,
                // value: value[property],
                onIonBlur: <Type extends EventTargetType>(event: IonInputCustomEvent<FocusEvent>) => {
                    if (
                        isNullOrUndefined(event.target.value)
                            ? true
                            : (event.target.value + '').trim() === '' || isNullOrUndefined(event.target.value)
                    ) {
                        event.target.style.opacity = '0';
                    }

                    if (event.target.value == undefined) {
                        updateValueWithPathOrProperty(setValue, pathOrProperty, value, event.target.value);
                        return;
                    }

                    if (!isDateValidAndHandleIfNot(event.target.value + '')) {
                        event.preventDefault();
                        return;
                    }

                    // const date = convertDateStringToBackendDateString(event.target.value + '');

                    updateValueWithPathOrProperty(setValue, pathOrProperty, value, event.target.value);
                },
            };
        },
        bindIonPropertyPhone: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onIonBlur: <Type extends EventTargetType>(event: Type) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.value
                        ?.toString()
                        .replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3'),
                })),
        }),

        bindIonPropertyCurrency: (pathOrProperty: PossibleParameterTypes<T>, disabled = false) => {
            //@ts-ignore
            const result = getValueWithPathOrProperty(pathOrProperty, value);
            const currentValue: any = result == undefined ? undefined : result[1];

            let combinedStyles = styles.currencyInput;

            if (disabled) {
                combinedStyles += ` ${styles.disabledCurrenyField}`;
            }

            return {
                value: currentValue,
                className: combinedStyles,
                // value: value[property],
                onIonBlur: (event: IonInputCustomEvent<FocusEvent>) => {
                    if (event.target.value === undefined || event.target.value === null) {
                        updateValueWithPathOrProperty(setValue, pathOrProperty, value, '$0.00');
                        return;
                    }
                    const targetValue = event.target.value + '';

                    const makeSureThereIsALeadingNumber = targetValue.replace(/^\.(.*$)/, '0.$1');

                    const truncate = makeSureThereIsALeadingNumber.replace(/\.(.{2}).*/, '.$1');

                    const makeSureAtLeastTwoDigitsAfterTheDecimal = !truncate.includes('.')
                        ? truncate + '.00'
                        : truncate;

                    const addLeadingZeroIfOnlyOneDigitAfterDecimal = makeSureAtLeastTwoDigitsAfterTheDecimal.replace(
                        /\.([0-9])$/,
                        '.$10'
                    );

                    const removeNonNumbers = addLeadingZeroIfOnlyOneDigitAfterDecimal.replace(/[^0-9.]/g, '');

                    const replacedVersion = updateValueWithPathOrProperty(
                        setValue,
                        pathOrProperty,
                        value,
                        removeNonNumbers
                    );
                },
            };
        },

        bindProperty: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
                setValue((oldValue) => ({ ...oldValue, [property]: event.target.value })),
        }),
        bindPropertyUppercase: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
                setValue((oldValue) => ({ ...oldValue, [property]: event.target.value.toUpperCase() })),
        }),
        bindPropertyDecimals: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]:
                        typeof value[property] === 'number' ? parseFloat(event.target.value) : event.target.value,
                })),
        }),
        bindPropertyCheck: <K extends keyof T>(property: K) => ({
            checked: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.checked,
                })),
        }),
        bindPropertyDate: <K extends keyof T>(property: K) => {
            let processedValue = value[property] as unknown as string;
            if (processedValue != undefined && processedValue != '') {
                if (typeof property !== 'string' && typeof property !== 'number') {
                    throw new Error('Property Type is not a string nor a number! Could be a symbol. Object Hook');
                }

                if (typeof value[property] !== 'string') {
                    throw new Error('Value of Property of Type Date is not a string!!');
                }

                if (processedValue.includes('T')) {
                    processedValue = processedValue.split('T')[0];
                }
            }
            return {
                value: processedValue == undefined ? '' : processedValue,
                name: property,
                onChange: (event: ChangeEvent<HTMLInputElement>) =>
                    setValue((oldValue) => ({
                        ...oldValue,
                        [property]: event.target.value.split('T')[0],
                    })),
            };
        },
        bindPropertyPhone: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.value.replace(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                        '($1) $2-$3'
                    ),
                })),
        }),
        bindPropertySSN: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.value.replace(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{4})$/,
                        '$1-$2-$3'
                    ),
                })),
        }),
        updateProperty: <K extends keyof T>(property: K) => {
            const temp = value[property];
            return <V extends typeof temp>(data: V, callback?: (arg0: T) => any) => {
                setValue((oldValue) => {
                    const objToSet = { ...oldValue, [property]: data };
                    return objToSet;
                });
                // setValue((prevValue) => {
                //     const newValue = { ...prevValue, [property]: data };
                //     callback && callback(newValue);
                //     return newValue;
                // });
            };
        },
        bindPropertySelectMultiple: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLSelectElement>) => {
                const options = event.target.options;
                const selectedValue: string[] = [];
                for (let i = 0, l = options.length; i < l; i++) {
                    if (options[i].selected) {
                        selectedValue.push(options[i].value);
                    }
                }

                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: selectedValue,
                }));
            },
        }),

        // Uses the custom reactasyncselect onchange method
        bindReactAsyncSelect: <K extends keyof T>(property: K, callBack?: Function) => {
            const objectWithValue: { value: any; onChange?: any } = {
                value:
                    // typeof value[property] === 'string'
                    // ?
                    { label: value[property], value: value[property] },
                onChange: async (newValue: any) => {
                    if (callBack) {
                        const valuesToUpdate = await callBack(newValue);
                        setValue((oldValue) => {
                            const toUpdate = { ...oldValue, ...valuesToUpdate, [property]: newValue.value };
                            return toUpdate;
                        });
                    } else {
                        setValue((oldValue) => ({ ...oldValue, [property]: newValue.value }));
                    }
                },
                // : value[property]
            };

            return objectWithValue;
        },

        removeProperty: <K extends keyof T>(property: K) => {
            const objectCopy = { ...value };
        },
        reset: () => setValue(initialValue),
    };
};

export const useContextObject = <T extends object>(context: Context<[T, (arg0: T | ((arg0: T) => T)) => void]>) => {
    const [value, setValue] = useContext(context);

    return {
        value,
        setValue,
        bindProperty: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]:
                        typeof value[property] === 'number' ? parseInt(event.target.value) || 1 : event.target.value,
                })),
        }),
        bindPropertyCheck: <K extends keyof T>(property: K) => ({
            checked: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.checked,
                })),
        }),
        bindPropertyDate: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.value.split('T')[0],
                })),
        }),
        bindPropertyPhone: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.value.replace(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
                        '($1) $2-$3'
                    ),
                })),
        }),
        bindPropertySSN: <K extends keyof T>(property: K) => ({
            value: value[property],
            name: property,
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
                setValue((oldValue) => ({
                    ...oldValue,
                    [property]: event.target.value.replace(
                        /^\(?([0-9]{3})\)?[-. ]?([0-9]{2})[-. ]?([0-9]{4})$/,
                        '$1-$2-$3'
                    ),
                })),
        }),
        updateProperty: <K extends keyof T>(property: K) => {
            const temp = value[property];
            return <V extends typeof temp>(data: V, callback?: (arg0: T) => any) => {
                setValue((prevValue: T) => {
                    const newValue: T = { ...prevValue, [property]: data };
                    callback && callback(newValue);
                    return newValue;
                });
            };
        },
    };
};

const handleAccurateOnBlurForCheckBoxes = <T extends (newCheckedValue: boolean, ...args: any[]) => any>(
    callback: T
) => {
    let allowBlurCall = true;
    return {
        onBlur: <Type extends CheckBoxEventTargetType>(event: Type) => {
            if (!allowBlurCall) {
                return;
            }

            callback(event.target.checked);
        },
        onMouseEnter: () => (allowBlurCall = false),
        onMouseLeave: () => (allowBlurCall = true),
    };
};

const getValueWithPathOrProperty = <T extends object>(
    pathOrProprety: PossibleParameterTypes<T>,
    objToGetValueFrom: T
) => {
    if (typeof pathOrProprety === 'string') {
        const property = pathOrProprety as WithPropertyNameParameterTypes<T>;
        return [property, objToGetValueFrom[property]];
    } else {
        const { path, pathCorrespondingArrayAccessIndicesArray } = pathOrProprety as WithPathParameterType<T>;
        return getPropertyAndValueByPath(objToGetValueFrom, path, pathCorrespondingArrayAccessIndicesArray);
    }
};

/**
 *
 * @param startingObj This object will be drilled into using the 'path' parameter. Path should
 * be generated from NestedKeyOf.tsx to ensure it matches correctly with starting Obj
 *
 * @param path This string should be generated from NestedKeyOf. This path represents object
 * notation but additionally handles arrays in a specific way. Example if I wanted to access the second
 * element in an array to called test inside of an object called foobar here is the path I'd use: foobar.test[#]. And then for the 'pathCorrespondingArrayAccessIndicesArray' param
 * I would pass in [1]. This would replace the [#] logically with [1]. The first occurence of [#] is matched up with the first
 * element of the array. If there's a second occurence of [#] it's matched with the second element of the array and etc.
 *
 * @param pathCorrespondingArrayAccessIndicesArray This array is optional. It is only required when you are navigating through an array
 * using the 'path' parameter. The order of this array corresponds to the order of occurrences of the '[#]' token inside of the path.
 * Basically instead of putting numbers inside of the path parameter for accessing arrays you simply
 * using this array to store the numbers corresponding with the [#]'s in the path. The reason
 * that the path uses '[#]' rather than a direct number ex: '[1]' is because this way we can
 * generate the valid paths possible for a given object. In typescript, it's not able to
 * generate paths for objects unless they are marked with 'as const' and with previous
 * experimentation wouldn't have worked with our project. To allow typescript
 * to generate 'path's' for objects we use the '[#]' for paths and then
 * pass in the number to replace the [#]
 * @returns  Returns a tuple with the first element being the exact path generated from the
 * provided path parameter with all uses of [#] replaced by the exact number used. I believe
 * this will be required when state which is nested within an object.
 */
export const getPropertyAndValueByPath = <T>(
    startingObj: any,
    path: string,
    pathCorrespondingArrayAccessIndicesArray: number[] = []
): [string, T | null] => {
    // Example Path:
    // Deliveries.[#].Carrier Phone

    const hasArrayAccesorPattern = /\[[#]+\]/;

    const pathSegments = path.split('.');

    if (pathSegments.length == 1) {
        if (hasArrayAccesorPattern.test(pathSegments[0])) {
            // This means it's one property deep and has an array accessor
            if (pathCorrespondingArrayAccessIndicesArray.length === 0) {
                throw new Error(
                    'Incorrect usage of Array!! Expected array to have single integer because the path uses an array accessor'
                );
            }

            const objToNavigate = { ...startingObj };
            if (!('hasOwnProperty' in objToNavigate)) {
                throw new Error(
                    `navigtated to object doesn't have 'hasOwnProprety' field but path requires accessing a path. Object: ${JSON.stringify(
                        objToNavigate
                    )}, path: ${path}`
                );
            }

            const match = hasArrayAccesorPattern.exec(pathSegments[0]);

            if (match == null) {
                throw new Error('There is a problem with the regex logic here. Please review!!');
            }

            const pathWithoutAccessor = pathSegments[0].slice(0, match.index);

            //@ts-ignore
            if (!objToNavigate!.hasOwnProperty(pathWithoutAccessor)) {
                console.error("path doesn't exist on object");
                return ['', null];
            }

            //@ts-ignore
            const intermediateThing = objToNavigate[pathWithoutAccessor];

            if (!('hasOwnProperty' in intermediateThing)) {
                throw new Error(
                    `navigtated to object doesn't have 'hasOwnProprety' field but path requires accessing a path. Object: ${JSON.stringify(
                        intermediateThing
                    )}, path: ${path}`
                );
            }
            //@ts-ignore
            const indexToUse = pathCorrespondingArrayAccessIndicesArray[0];
            if (!intermediateThing.hasOwnProperty(indexToUse)) {
                console.error("path doesn't exist on intermediate object");
                return ['', null];
            }
            const targetValue = intermediateThing[indexToUse];
            return [`${pathWithoutAccessor}${indexToUse}`, targetValue];
        }
        // this means it's one property deep
        // Return the one specified property
        return [path, startingObj[path] as T];
    }

    let objectToNavigateThrough = startingObj;
    let pathCorrespondingArrayAccessIndicesArrayIndex = 0;

    let resultingFullPath = '';

    let ranIntoNullProblem = false;
    pathSegments.forEach((propertyPartialPath) => {
        //tes[#][#]

        if (ranIntoNullProblem) {
            return;
        }

        if (!propertyPartialPath.endsWith('[#]')) {
            if (objectToNavigateThrough == undefined) {
                ranIntoNullProblem = true;
                return;
            }

            objectToNavigateThrough = objectToNavigateThrough[propertyPartialPath];
            resultingFullPath += `.${propertyPartialPath}`;
            return;
        }

        // Example:
        // "Sales[#]"

        // Need to also support this type of usage:
        // "Sales[#][#]"

        let updatingPartialPath = propertyPartialPath;

        while (updatingPartialPath.indexOf('[#]') != -1) {
            const indexOfNumberSymbol = updatingPartialPath.indexOf('[#]');

            if (indexOfNumberSymbol > 0) {
                const pathBeforeSymbol = updatingPartialPath.substring(0, indexOfNumberSymbol);

                objectToNavigateThrough = objectToNavigateThrough[pathBeforeSymbol]; // Ensure you navigate with the path first. ANd then we will nagivate by provided index

                resultingFullPath += `.${pathBeforeSymbol}`;
            }

            const toUseIndex =
                pathCorrespondingArrayAccessIndicesArray[pathCorrespondingArrayAccessIndicesArrayIndex++];

            objectToNavigateThrough = objectToNavigateThrough[toUseIndex];

            resultingFullPath += `[${toUseIndex}]`;

            updatingPartialPath = updatingPartialPath.substring(indexOfNumberSymbol + 3);
        }
    });

    if (ranIntoNullProblem) {
        return [resultingFullPath, null];
    }

    resultingFullPath = resultingFullPath.substring(1); // Makes it so it doesn't include the starting .

    return [resultingFullPath, objectToNavigateThrough as unknown as T];
};

const updateValueWithPathOrProperty = <T extends object>(
    setStateFunction: React.Dispatch<React.SetStateAction<T>>,
    pathOrProprety: PossibleParameterTypes<T>,
    objToModify: T,
    value: any
) => {
    if (typeof pathOrProprety === 'string') {
        const property = pathOrProprety as WithPropertyNameParameterTypes<T>;

        setStateFunction((oldState) => {
            return { ...oldState, [property]: value };
        });

        return { ...objToModify, [property]: value };
    } else {
        const { path, pathCorrespondingArrayAccessIndicesArray } = pathOrProprety as WithPathParameterType<T>;
        return updateStateObjectWithPathAndValue(
            setStateFunction,
            path,
            value,
            pathCorrespondingArrayAccessIndicesArray
        );
    }
};

export const updateStateObjectWithPathAndValue = <T extends object>(
    setStateFunction: React.Dispatch<React.SetStateAction<T>>,
    path: string,
    value: any,
    pathCorrespondingArrayAccessIndicesArray: number[]
) => {
    setStateFunction((oldState) => {
        // Need to turn of type checking so we can try
        // to programatically access properties of this.
        // Additionall we will be using checks such as
        // 'something' in obj to ensure that it has it.

        // {taco: est'test'}
        const castedOldState = { ...oldState } as any;

        const pathSegments = path.split('.');

        //tes[#][#]

        // Have to use this rather than .endsWith
        // because endsWith doesn't support regex
        // searching where we need to be able to
        // match any number between the '[' and
        // the ']'

        //https://regexkit.com/javascript-regex
        const hasArrayAccesorPattern = /\[[#]+\]/;

        if (pathSegments.length == 1) {
            if (hasArrayAccesorPattern.test(pathSegments[0])) {
                // This means it's one property deep and has an array accessor
                if (pathCorrespondingArrayAccessIndicesArray.length === 0) {
                    throw new Error(
                        'Incorrect usage of Array!! Expected array to have single integer because the path uses an array accessor'
                    );
                }

                const objToNavigate = { ...oldState };
                if (!('hasOwnProperty' in objToNavigate)) {
                    throw new Error(
                        `navigtated to object doesn't have 'hasOwnProprety' field but path requires accessing a path. Object: ${JSON.stringify(
                            objToNavigate
                        )}, path: ${path}`
                    );
                }

                const match = hasArrayAccesorPattern.exec(pathSegments[0]);

                if (match == null) {
                    throw new Error('There is a problem with the regex logic here. Please review!!');
                }

                const pathWithoutAccessor = pathSegments[0].slice(0, match.index);

                //@ts-ignore
                if (!objToNavigate!.hasOwnProperty(pathWithoutAccessor)) {
                    console.error("path doesn't exist on object");
                    return { ...oldState };
                }
                //@ts-ignore
                const intermediateThing = objToNavigate[pathWithoutAccessor];

                if (!('hasOwnProperty' in intermediateThing)) {
                    throw new Error(
                        `navigtated to object doesn't have 'hasOwnProprety' field but path requires accessing a path. Object: ${JSON.stringify(
                            intermediateThing
                        )}, path: ${path}`
                    );
                }

                const indexToUse = pathCorrespondingArrayAccessIndicesArray[0];

                //@ts-ignore
                if (!intermediateThing!.hasOwnProperty(indexToUse)) {
                    console.error("path doesn't exist on object");
                    return { ...oldState };
                }

                intermediateThing[indexToUse] = value;
                return objToNavigate;
            }

            // this means it's one property deep
            // Return the one specified property
            return { ...oldState, [path]: value };
        }

        let objectToNavigateThrough = castedOldState;

        let ranIntoNullProblem = false;
        let currentArrayIndex = 0;
        pathSegments.forEach((propertyPartialPath, index) => {
            if (ranIntoNullProblem) {
                return;
            }

            if (!hasArrayAccesorPattern.test(propertyPartialPath)) {
                // Means that propertyPartialPath does NOT have a array
                // accesor inside of it.

                if (objectToNavigateThrough[propertyPartialPath] === undefined) {
                    // This means that the specified path for this doesn't match
                    // the object

                    ranIntoNullProblem = true;

                    // throw new Error(
                    //     `Path segment ${propertyPartialPath} does not exist in current target object:  ${JSON.stringify(
                    //         objectToNavigateThrough
                    //     )}`
                    // );
                }

                if (index === pathSegments.length - 1) {
                    // This is the last path.
                    objectToNavigateThrough[propertyPartialPath] = value;
                    return;
                }

                objectToNavigateThrough = objectToNavigateThrough[propertyPartialPath];
                return;
            }

            // Example:
            // "Sales[#]"

            // Need to also support this type of usage:
            // "Sales[#][#]"

            let updatingPartialPath = propertyPartialPath;

            let isTrue = hasArrayAccesorPattern.test(updatingPartialPath);

            while (isTrue) {
                // We  know it has at least one Array accessor pattern

                //https://blog.devgenius.io/how-to-find-the-position-index-of-a-regex-match-result-in-javascript-2e4dda3df8a0#e7e1
                const match = hasArrayAccesorPattern.exec(updatingPartialPath);
                if (match == undefined || match.index == undefined) {
                    throw new Error(
                        `Error in Logic in ObjectHook hook! Logic says there is an array accessor but couldn't match it with regex expression. Double check logic to  ensure they are the consistent`
                    );
                }
                const indexOfNumberSymbol = match.index;

                const pathBeforeSymbol = updatingPartialPath.substring(0, indexOfNumberSymbol);

                const symbolPortionIndex = pathCorrespondingArrayAccessIndicesArray[currentArrayIndex++]; // removes brackets and gets index

                if (objectToNavigateThrough[pathBeforeSymbol] === undefined) {
                    // This means that the specified path for this doesn't match
                    // the object
                    // throw new Error(
                    //     `Path segment ${propertyPartialPath} does not exist in current target object:  ${JSON.stringify(
                    //         objectToNavigateThrough
                    //     )}`
                    // );
                    ranIntoNullProblem = true;
                }

                objectToNavigateThrough = objectToNavigateThrough[pathBeforeSymbol]; // Ensure you navigate with the path first. ANd then we will nagivate by provided index

                objectToNavigateThrough = objectToNavigateThrough[symbolPortionIndex];

                updatingPartialPath = updatingPartialPath.substring(indexOfNumberSymbol + 3);
                isTrue = hasArrayAccesorPattern.test(updatingPartialPath);
            }

            if (index === pathSegments.length - 1) {
                // This is the last path.
                objectToNavigateThrough = value;
            }
        });

        return castedOldState;
    });
};

// export const updateStateObjectWithPathAndValue = <T extends object>(
//     setStateFunction: React.Dispatch<React.SetStateAction<T>>,
//     completePath: string,
//     value: any
// ) => {
//     setStateFunction((oldState) => {
//         // Need to turn of type checking so we can try
//         // to programatically access properties of this.
//         // Additionall we will be using checks such as
//         // 'something' in obj to ensure that it has it.

//         // {taco: est'test'}
//         const castedOldState = { ...oldState } as any;

//         const pathSegments = completePath.split('.');

//         if (pathSegments.length == 1) {
//             // this means it's one property deep
//             // Return the one specified property
//             return { ...oldState, [completePath]: value };
//         }

//         let objectToNavigateThrough = castedOldState;

//         let ranIntoNullProblem = false;
//         pathSegments.forEach((propertyPartialPath, index) => {
//             if (ranIntoNullProblem) {
//                 return;
//             }

//             //tes[#][#]

//             // Have to use this rather than .endsWith
//             // because endsWith doesn't support regex
//             // searching where we need to be able to
//             // match any number between the '[' and
//             // the ']'

//             //https://regexkit.com/javascript-regex
//             var hasArrayAccesorPattern = /\[[0-9]+\]/;

//             if (!hasArrayAccesorPattern.test(propertyPartialPath)) {
//                 // Means that propertyPartialPath does NOT have a array
//                 // accesor inside of it. Keep in mind in this function the
//                 // array accessor will have an actual number inside of it
//                 // with any number of digis.

//                 if (objectToNavigateThrough[propertyPartialPath] === undefined) {
//                     // This means that the specified path for this doesn't match
//                     // the object

//                     ranIntoNullProblem = true;

//                     // throw new Error(
//                     //     `Path segment ${propertyPartialPath} does not exist in current target object:  ${JSON.stringify(
//                     //         objectToNavigateThrough
//                     //     )}`
//                     // );
//                 }

//                 if (index === pathSegments.length - 1) {
//                     // This is the last path.
//                     objectToNavigateThrough[propertyPartialPath] = value;
//                     return;
//                 }

//                 objectToNavigateThrough = objectToNavigateThrough[propertyPartialPath];
//                 return;
//             }

//             // Example:
//             // "Sales[#]"

//             // Need to also support this type of usage:
//             // "Sales[#][#]"

//             let updatingPartialPath = propertyPartialPath;

//             let isTrue = hasArrayAccesorPattern.test(updatingPartialPath);

//             while (isTrue) {
//                 // We  know it has at least one Array accessor pattern

//                 //https://blog.devgenius.io/how-to-find-the-position-index-of-a-regex-match-result-in-javascript-2e4dda3df8a0#e7e1
//                 const match = hasArrayAccesorPattern.exec(updatingPartialPath);
//                 if (match == undefined || match.index == undefined) {
//                     throw new Error(
//                         `Error in Logic in ObjectHook hook! Logic says there is an array accessor but couldn't match it with regex expression. Double check logic to  ensure they are the consistent`
//                     );
//                 }
//                 const indexOfNumberSymbol = match.index;

//                 const pathBeforeSymbol = updatingPartialPath.substring(0, indexOfNumberSymbol);

//                 const symbolPortionIndex = match[0].replace(/\[/g, '').replace(/\]/g, ''); // removes brackets and gets index

//                 if (objectToNavigateThrough[pathBeforeSymbol] === undefined) {
//                     // This means that the specified path for this doesn't match
//                     // the object
//                     // throw new Error(
//                     //     `Path segment ${propertyPartialPath} does not exist in current target object:  ${JSON.stringify(
//                     //         objectToNavigateThrough
//                     //     )}`
//                     // );
//                     ranIntoNullProblem = true;
//                 }

//                 objectToNavigateThrough = objectToNavigateThrough[pathBeforeSymbol]; // Ensure you navigate with the path first. ANd then we will nagivate by provided index

//                 objectToNavigateThrough = objectToNavigateThrough[symbolPortionIndex];

//                 updatingPartialPath = updatingPartialPath.substring(indexOfNumberSymbol + 3);
//                 isTrue = hasArrayAccesorPattern.test(updatingPartialPath);
//             }

//             if (index === pathSegments.length - 1) {
//                 // This is the last path.
//                 objectToNavigateThrough = value;
//             }
//         });

//         return castedOldState;
//     });
// };
