import React from 'react';
import { isNullOrUndefined } from '../../utils/NullOrUndefined';

//@ts-ignore
const TableToPrint = React.forwardRef(function TableToPrint({ columnsToShow, data, identifierProperty }, ref) {
    const processedColumnsToShow = React.useMemo(() => {
        if (isNullOrUndefined(columnsToShow)) {
            return;
        }

        if (Array.isArray(columnsToShow)) {
            return columnsToShow;
        } else {
            if (columnsToShow === '') {
                return;
            }

            return columnsToShow;
        }
    }, [columnsToShow]);

    // indicates columns to show. If it's an empty array that means show all of them

    const shouldPropertyBeVisible = (property) => {
        if (processedColumnsToShow.length <= 0) {
            return true;
        }

        return processedColumnsToShow.includes(property);
    };

    //     const stuff = `\
    //   @page {\ size: landscape;\ }\
    // `;

    return (
        <div ref={ref}>
            {/* <style type="text/css" media="print">
                {stuff}
            </style> */}
            <div
                style={{ position: 'static', margin: '2.5rem 2.5rem 0 2.5rem', overflow: 'auto' }}
                // className={styles.PrintTableContainer}
                data-testid="PrintTable">
                <table
                    style={{
                        tableLayout: 'auto',
                        width: '100%',
                        borderCollapse: 'collapse',
                    }}
                    // className={styles.tableStyles}
                >
                    <thead
                        style={{
                            backgroundColor: '#2980ba',
                            color: 'white',
                        }}
                        // className={styles.tableHead}
                    >
                        <tr>
                            {Array.isArray(data) && data.length > 0 ? (
                                processedColumnsToShow.map((property) => (
                                    <th
                                        key={property}
                                        style={{
                                            padding: '0 10px',
                                            letterSpacing: '0.05rem',

                                            fontWeight: '600',
                                            border: '1px solid black',
                                        }}
                                        // className={styles.tableHeader}
                                    >
                                        {property}
                                    </th>
                                ))
                            ) : (
                                <td>
                                    <h1>No table data present!</h1>
                                </td>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {data.map((dto, index) => (
                            <tr
                                key={`${dto[identifierProperty]}-row`}
                                {...(index + (1 % 2) == 0
                                    ? {
                                          style: {
                                              backgroundColor: '#c3c2c2',
                                          },
                                      }
                                    : {
                                          style: {
                                              //   backgroundColor: '#f5f5f5',
                                          },
                                      })}
                                // className={styles.tableRow}
                            >
                                {processedColumnsToShow.map((property) => {
                                    const value = dto[property];
                                    return (
                                        <td
                                            key={`${dto[identifierProperty]}-${property}`}
                                            style={{
                                                color: '#505050',
                                                letterSpacing: '0.07rem',
                                                padding: '5px',
                                                fontSize: '1rem',
                                                fontWeight: '500',
                                                border: '1px solid black',
                                            }}
                                            // className={styles.tableData}
                                        >
                                            {value + ''}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* <style type="text/css" media="print">
                {'\
  @page { size: landscape; }\
'}
            </style> */}
            </div>
        </div>
    );
});

export default TableToPrint;
