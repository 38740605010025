import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';
import { DataType } from '../../models/Global';

export const getDataTypeFromSalesOrderPropertyName = (property: keyof SalesOrderDto): DataType => {
    switch (property) {
        case 'Invoice Number': {
            return DataType.String;
        }
        case 'Ordered Date': {
            return DataType.DateTime;
        }

        // Will leave as string for now but needs ot be set as date
        case 'Docs Signed': {
            return DataType.DateTime;
        }
        case 'Check Number': {
            return DataType.String;
        }
        case 'Deposit': {
            return DataType.String;
        }
        case 'Status': {
            return DataType.Enum;
        }
        case 'Stock Numbers': {
            return DataType.String;
        }
        case 'Sales Person': {
            return DataType.String;
        }
        case 'Customer Name': {
            return DataType.String;
        }
        case 'Customer #': {
            return DataType.String;
        }
        case 'Title Work Completed': {
            return DataType.DateTime;
        }
        case 'NAPP': {
            return DataType.Bool;
        }
        case 'Lienholder': {
            return DataType.String;
        }
        case 'Notes': {
            return DataType.String;
        }
        case 'Misc Tracking': {
            return DataType.String;
        }
        case 'Proof Of Payment Received': {
            return DataType.DateTime;
        }
        case 'Back Office Notes': {
            return DataType.String;
        }
        case 'Split Deal': {
            return DataType.Bool;
        }
        case 'All Delivered': {
            return DataType.Bool;
        }
        case 'Split Sales Person': {
            return DataType.String;
        }
        case 'Delivered Count': {
            return DataType.String;
        }
    }

    return DataType.String;
};
