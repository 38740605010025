import { IonAlert, IonButton, IonIcon } from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";

import {
  addCircleOutline,
  copyOutline,
  ellipseOutline,
  mailOutline,
  trashBinOutline,
} from "ionicons/icons";
import { useState } from "react";
import makeSimpleToast from "../../utils/MakeSimpleToast";
import { isNullOrUndefined } from "../../utils/NullOrUndefined";
import { SelectingMode } from "../Theme/QueriedTable/QueriedTableSortFilterStateManager/QueriedTableSortFilterStateManager";
import styles from "./CopyTableStylesButtons.module.scss";

interface CopyTableStylesButtonsProps<T extends object> {
  selectingRowMode: SelectingMode;
  unselectAllRows: () => void;
  selectAllRows: (ids: string[]) => void;
  cancelSelecting: () => void;
  stopSelectingAndCopyTableWithStyles: () => void;
  stopSelectingAndEmailAdvertisements: (email: string) => void;
  startSelecting: (
    selectingMode: Exclude<SelectingMode, "unselected">,
    ids?: string[]
  ) => void;
  identifierProperty: keyof T;
  data: T[];
  inventoryPage: boolean;
}

const CopyTableStylesButtons = <T extends object>({
  selectingRowMode,
  unselectAllRows,
  selectAllRows,
  cancelSelecting,
  stopSelectingAndCopyTableWithStyles,
  stopSelectingAndEmailAdvertisements,
  startSelecting,
  identifierProperty,
  data,
  inventoryPage,
}: CopyTableStylesButtonsProps<T>) => {
  const [showingAlert, setShowingAlert] = useState<boolean>(false);

  const [emailAddressToSendTo, setEmailAddressToSendTo] = useState<
    string | undefined
  >();

  const getIdentifierValue = (dto: T) => {
    if (!(identifierProperty in dto)) {
      makeSimpleToast(
        "Error while copying styles",
        "Identifier Property is not found on object!"
      );
      console.error(
        "Error while copying styles, identifier property is not found on object"
      );
      return "";
    }

    const identifierValue = dto[identifierProperty as keyof T];

    if (typeof identifierValue !== "string") {
      makeSimpleToast(
        "Error while copying styles",
        "value of identifier property is not a string!"
      );
      console.error(
        "Error while copying styles: value of identifier property is not a string!"
      );
      return "";
    }

    return identifierValue;
  };

  return (
    <div
      className={`${selectingRowMode === "unselected"
          ? styles.copyButtonMainContinerNotExpanded
          : styles.copyButtonMainContinerExpanded
        }`}
    >
      <div
        className={`${styles.copyButtonContainer} ${selectingRowMode === "unselected"
            ? styles.copyButtonContainerNotDisplayed
            : ""
          }`}
      >
        <IonButton
          onClick={() => {
            unselectAllRows();
          }}
        >
          <IonIcon color="white" icon={ellipseOutline} />
        </IonButton>
      </div>
      <div
        className={`${styles.copyButtonContainer} ${selectingRowMode === "unselected"
            ? styles.copyButtonContainerNotDisplayed
            : ""
          }`}
      >
        <IonButton
          onClick={() => {
            selectAllRows(data.map(getIdentifierValue));
          }}
        >
          <IonIcon color="white" icon={addCircleOutline} />
        </IonButton>
      </div>
      <div
        className={`${styles.copyButtonContainer} ${selectingRowMode === "unselected"
            ? styles.copyButtonContainerNotDisplayed
            : ""
          }`}
      >
        <IonButton
          style={{ color: "#ff7f7f" }}
          onClick={() => {
            cancelSelecting();
          }}
        >
          <IonIcon color="color" icon={trashBinOutline} />
        </IonButton>
      </div>

      <div
        className={`${styles.copyButtonContainer}
        ${selectingRowMode === "email"
            ? styles.copyButtonContainerNotDisplayed
            : ""
          }`}
      >
        <IonButton
          onClick={() => {
            if (selectingRowMode === "unselected") {
              startSelecting("copy", data.map(getIdentifierValue));
            } else if (selectingRowMode === "copy") {
              // Do other stuff
              stopSelectingAndCopyTableWithStyles();
            }
          }}
        >
          <IonIcon
            className={
              selectingRowMode
                ? styles.copyButtonIconSelected
                : styles.copyButtonIcon
            }
            icon={copyOutline}
          />
        </IonButton>
      </div>

      <div
        className={`${styles.copyButtonContainer}
        ${selectingRowMode === "copy" || inventoryPage === false
            ? styles.copyButtonContainerNotDisplayed
            : ""
          }`}
      >
        <IonButton
          onClick={() => {
            if (selectingRowMode === "unselected") {
              setShowingAlert(true);
              const emailToSendAdTo = prompt(
                "What email would you like to send the advertisements to?"
              );

              if (isNullOrUndefined(emailToSendAdTo)) {
                alert("Please insert an email address");
                return;
              }

              startSelecting("email", data.map(getIdentifierValue));

              setEmailAddressToSendTo(emailToSendAdTo);
            } else if (selectingRowMode === "email") {
              if (isNullOrUndefined(emailAddressToSendTo)) {
                alert("No Email was provided!");
                return;
              }

              // Do other stuff
              stopSelectingAndEmailAdvertisements(emailAddressToSendTo);
            }
          }}
        >
          <IonIcon
            className={
              selectingRowMode
                ? styles.copyButtonIconSelected
                : styles.copyButtonIcon
            }
            icon={mailOutline}
          />
        </IonButton>
      </div>
    </div>
  );
};

export default CopyTableStylesButtons;
