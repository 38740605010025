import { DynamicSort } from './DynamicSort';

export class SortOptions {
    pageNumber = 1;
    itemsPerPage = 50;
    totalItems?: number = 0;
    totalPages?: number = 0;
    sortBy: DynamicSort[] = [];

    constructor(obj?: Partial<SortOptions>) {
        this.pageNumber = obj?.pageNumber ?? 1;
        this.itemsPerPage = obj?.itemsPerPage ?? 50;
        this.totalItems = obj?.totalItems ?? 0;
        this.totalPages = obj?.totalPages ?? 0;
        this.sortBy = obj?.sortBy ?? [];
    }
}
