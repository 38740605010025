import { SearchForCustomer, SearchForEmployee } from '../../components/Search';
import { SalesOrderDto } from '../../models/Dtos/SalesOrderDtos/SalesOrderDto';

export const getAsyncSelectGetMethodFromSalesOrderPropertyName = (
    returnUndefinedIfCantMatch = false,
    property: keyof SalesOrderDto
) => {
    switch (property) {
        case 'Customer Name': {
            return SearchForCustomer;
        }
        case 'Sales Person': {
            return SearchForEmployee;
        }

        case 'Split Sales Person': {
            return SearchForEmployee;
        }
    }
    if (returnUndefinedIfCantMatch) {
        return undefined;
    }
    return async () => [];
};
